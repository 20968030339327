import {
  useCheckExistingOwnership,
  useGetPartOwners,
  useGetRepresentativeOwnerships,
  useRemoveAllPartOwners,
} from '@apis';
import Confirm from '@components/Confirm';
import Content from '@components/ContentBlock/Content';
import InfoBadge from '@components/InfoBadge';
import Step from '@components/Step';
import { AuthenticatedUser } from '@generated/account/src';
import {
  GetOwnershipExistenceStatusServiceTypeEnum,
  Ownership,
  OwnershipExistenceResponse,
  OwnershipTransfer,
} from '@generated/ownership-transfers/src';
import useNumericParams from '@hooks/useNumericParams';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import Message from '@primitives/Message';
import { Small } from '@primitives/Typography';
import {
  CircleInfo as InfoCircle,
  TriangleExclamation as ExclamationTriangle,
} from '@styled-icons/fa-solid';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import AddPartOwners from './PartOwnersAdd';
import PartOwnersConfirm from './PartOwnersConfirm';

interface Props {
  user: AuthenticatedUser;
  setShowPartOwnersForm: (v: boolean) => void;
  setMessage: (message: string) => void;
  transfer: OwnershipTransfer;
  isLeasing?: boolean;
}

interface StatusMessageProps {
  status: OwnershipExistenceResponse;
  transfer: OwnershipTransfer;
  ownerships: Ownership[];
  isLeasing: boolean;
}

const isValidToProceed = (
  statusCode: number | undefined,
  ownerships: Ownership[],
  transfer: OwnershipTransfer,
  ownershipIds: number[],
): boolean => {
  const representativeId = transfer.buyerRepresentative.id;
  const oldOwnerId = transfer.horse.ownershipId;
  // Still loading
  if (statusCode === undefined || !ownerships) {
    return false;
  }

  if (statusCode === 0) {
    return true;
  }

  if (statusCode !== 2) {
    return false;
  }

  if (ownershipIds.includes(oldOwnerId)) {
    return false;
  }

  const ownershipIsBanned = !ownerships.some(o =>
    ownershipIds.includes(o.ownershipId),
  );

  const existingOwnerships = ownerships.filter(o =>
    ownershipIds.includes(o.ownershipId),
  );

  // Esnure there is no existing ownership with other representative
  return (
    !ownershipIsBanned &&
    !existingOwnerships.every(o => o.representativeId !== representativeId)
  );
};

const StatusMessage = ({
  status,
  ownerships,
  transfer,
  isLeasing,
}: StatusMessageProps): JSX.Element => {
  if (!ownerships) {
    return null;
  }

  const representativeName = transfer.buyerRepresentative.name;
  const oldOwnerId = transfer.horse.ownershipId;

  const getOwnerShip = (id: number): Ownership =>
    ownerships?.find(ownership => ownership.ownershipId === id);

  // Existing ownership
  if (status.status.code === 2) {
    const ownership = getOwnerShip(status.ownershipIds[0]);
    const existingOwnerships = status.ownershipIds.map(id => getOwnerShip(id));

    if (status.ownershipIds.includes(oldOwnerId)) {
      return (
        <Message variant="errorBox">
          <>
            Företrädare och valda delägare motsvarar ägarskap{' '}
            <b>{ownership?.ownershipName}</b>.{' '}
            {isLeasing
              ? 'Hyrande ägarskap får inte vara samma som för hästens ägare.'
              : 'Säljare och köpare får inte vara samma.'}
          </>
        </Message>
      );
    }

    if (ownerships && !ownership) {
      return (
        <Message variant="errorBox">
          Företrädaren och valda delägare motsvarar ett ägarskap som inte går
          att använda.
        </Message>
      );
    }

    if (!ownership) {
      return null;
    }

    if (
      !isValidToProceed(
        status.status.code,
        ownerships,
        transfer,
        status.ownershipIds,
      )
    ) {
      return (
        <Message variant="errorBox">
          <>
            Företrädaren och valda delägare motsvarar ägarskap{' '}
            <b>{ownership?.ownershipName}</b>. För att använda det ägarskapet
            måste
            {isLeasing ? ' leasingen ' : ' ägarskiftet '}
            göras till företrädare <b>{ownership?.representativeName}</b> eller
            att <b>{representativeName}</b> registreras som företrädare för
            ägarskapet.
            <b></b>
          </>
        </Message>
      );
    } else {
      return (
        <InfoBadge
          message={
            <>
              Företrädare och valda delägare motsvarar det befintliga ägarskapet{' '}
              {existingOwnerships.map((o, i) => (
                <React.Fragment key={o.ownershipId}>
                  <b>{o.ownershipName}</b>
                  {o.ownerBlocked && (
                    <Box as="span" color="red" ml="1">
                      (Spärrat)
                    </Box>
                  )}
                  {i < existingOwnerships.length - 1 && ', '}
                </React.Fragment>
              ))}
            </>
          }
          color="blue"
          icon={InfoCircle}
        />
      );
    }
  }
  // New ownership
  if (status.status.code === 0) {
    return (
      <InfoBadge
        message={
          <>
            Företrädare och valda delägare kommer att initiera ett nytt ägarskap
          </>
        }
        color="blue"
        icon={InfoCircle}
      />
    );
  }

  if (status.status.code === 5) {
    return (
      <Message variant="errorBox">
        <>Delägaren är redan företrädare.</>
      </Message>
    );
  }

  return null;
};

const PartOwnersForm = ({
  user,
  setShowPartOwnersForm,
  setMessage,
  transfer,
  isLeasing = false,
}: Props): JSX.Element => {
  const serviceType = (
    isLeasing ? 'LEASING' : 'OWNERSHIP_TRANSFERS'
  ) as GetOwnershipExistenceStatusServiceTypeEnum;

  const { transferId } = useNumericParams<{ transferId: number }>();

  const { refresh } = useGetPartOwners(user.licenseId, transferId);

  const { data: ownerships } = useGetRepresentativeOwnerships({
    licenseId: user.licenseId,
  });

  const {
    fetch: checkExistenceStatus,
    data: status,
    loading: loadingStatus,
  } = useCheckExistingOwnership(user.licenseId, serviceType);

  const { fetch: removeAll } = useRemoveAllPartOwners(
    user.licenseId,
    serviceType,
  );

  const [showConfirmContractModal, setShowConfirmContractModal] =
    useState(false);

  const [showConfirmSelectOtherModal, setShowConfirmSelectOtherModal] =
    useState(false);

  const history = useHistory();
  const [isCanceling, setIsCanceling] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onCancel = useCallback(async () => {
    setIsCanceling(true);

    await removeAll({
      transferId,
    });

    await refresh();

    setShowPartOwnersForm(false);
    setIsCanceling(false);
  }, [refresh, removeAll, setShowPartOwnersForm, transferId]);

  const onSave = useCallback(() => {
    // This doesn't realy save anything
    setIsSaving(true);
    setTimeout(() => {
      setIsSaving(false);
      history.push(
        isLeasing
          ? '/minasidor/leasing/pagande?message=save'
          : '/minasidor/agarskiften/pagande?message=save',
      );
    }, 400);
  }, [history, isLeasing]);

  const checkExistenceStatusCallback = useCallback(async () => {
    await checkExistenceStatus({ transferId });
  }, [checkExistenceStatus, transferId]);

  useEffect(() => {
    checkExistenceStatusCallback();
  }, [transferId, checkExistenceStatusCallback]);

  const ownershipName =
    status?.ownershipIds &&
    ownerships
      ?.filter(ownership => status.ownershipIds.includes(ownership.ownershipId))
      .map(ownership => ownership.ownershipName)
      .join(', ');

  return (
    <>
      <Confirm
        size="small"
        colorScheme="primary"
        closeModal={() => setShowConfirmSelectOtherModal(false)}
        open={showConfirmSelectOtherModal}
        action="Ja"
        onAction={onCancel}
        title="Välj annat ägarskap"
        description={
          isLeasing
            ? 'Är du säker på att du vill ta bort sparade hyrestagare för detta leasingavtal och välja ett annat ägarskap?'
            : 'Är du säker på att du vill ta bort sparade delägare för detta ägarskifte och välja ett annat ägarskap?'
        }
      />
      {showConfirmContractModal && (
        <PartOwnersConfirm
          onClose={async () => {
            setShowConfirmContractModal(false);
          }}
          transfer={transfer}
          user={user}
          ownershipName={ownershipName}
          isLeasing={isLeasing}
        />
      )}
      <Step
        header={isLeasing ? 'Ange hyrestagare' : 'Ange delägare i ägarskap'}
        noTriangle
      >
        <Box verticalSpacing={3}>
          <Small fontSize="notes" color="dim-gray">
            <Content
              slug={
                isLeasing
                  ? 'leasing-paborjade-leasingarenden-ange-hyrestagare-agarskap'
                  : 'agarskiften-pagaende-agarskiften-ange-delagare-agarskap'
              }
            />
          </Small>
          <AddPartOwners
            isLeasing={isLeasing}
            licenseId={user.licenseId}
            transferId={transferId}
            setMessage={setMessage}
            whenAdded={checkExistenceStatusCallback}
            representative={transfer.buyerRepresentative.name}
          />
        </Box>
      </Step>

      {status && !loadingStatus && (
        <StatusMessage
          status={status}
          transfer={transfer}
          ownerships={ownerships}
          isLeasing={isLeasing}
        />
      )}

      {transfer.horse.inStartList && (
        <InfoBadge
          message={`Observera att ägarskap måste ha hästägardress när hästen ska starta.`}
          color="red"
          icon={ExclamationTriangle}
        />
      )}

      <Box
        horizontalSpacing={[0, , 3]}
        justifyContent="flex-start"
        verticalSpacing={[3, , 0]}
        flexDirection={['column', , 'row']}
      >
        <Button
          width={[1, , 'auto']}
          onClick={() => {
            status?.status.code === 0
              ? setShowConfirmSelectOtherModal(true)
              : onCancel();
          }}
          colorScheme="secondaryAlt"
          loading={isCanceling}
        >
          Välj annat ägarskap
        </Button>
        <Button
          width={[1, , 'auto']}
          onClick={onSave}
          loading={isSaving}
          colorScheme="secondary"
        >
          {isLeasing ? 'Spara hyrestagare' : 'Spara delägare'}
        </Button>
        <Button
          width={[1, , 'auto']}
          onClick={() => {
            setShowConfirmContractModal(true);
          }}
          colorScheme="save"
          disabled={
            !status ||
            !isValidToProceed(
              status.status.code,
              ownerships,
              transfer,
              status.ownershipIds,
            )
          }
        >
          {isLeasing ? 'Registrera leasingavtal' : 'Registrera ägarskifte'}
        </Button>
      </Box>
    </>
  );
};

export default PartOwnersForm;

import { useBankIdTranferOwnership } from '@apis';
import Alert from '@components/Alert';
import BankID from '@features/BankID';
import { Horse, Person } from '@generated/ownership-transfers/src';
import Box from '@primitives/Box';
import { TriangleExclamation as ExclamationTriangle } from '@styled-icons/fa-solid';
import React, { useState } from 'react';

interface BankIdFormProps {
  date: string;
  person: Person;
  horse: Horse;
  leasing?: boolean;
}

interface Props extends BankIdFormProps {
  onClose: () => void;
}

const BankIdForm = ({
  date,
  person,
  horse,
  leasing,
}: BankIdFormProps): JSX.Element => {
  const { autoStartToken, result, retry, errorMessage, qrCodePattern } =
    useBankIdTranferOwnership({
      horseId: horse.horseId,
      transferDate: date,
      ownershipId: horse.ownershipId,
      buyerRepresentativeId: person.id,
      serviceType: leasing ? 'LEASING' : 'OWNERSHIP_TRANSFERS',
    });

  return (
    <BankID
      autoStartToken={autoStartToken}
      qrCodePattern={qrCodePattern}
      error={result && result !== 'success'}
      errorMessage={errorMessage}
      retry={retry}
    />
  );
};

const BankIdSubmit = ({
  onClose,
  leasing = false,
  ...props
}: Props): JSX.Element => {
  const [bankId, setBankId] = useState(false);

  return (
    <Alert
      open
      size="small"
      onAction={() => {
        if (!bankId) {
          setBankId(true);
        }
      }}
      icon={ExclamationTriangle}
      colorScheme="primary"
      iconProps={{
        size: 12,
      }}
      wideMobileButtons={true}
      wideButtons={true}
      description={
        leasing ? (
          <>
            <p>
              Är du säker på att du vill påbörja leasing för hästen{' '}
              <b>{props.horse.horseName}</b>?
            </p>
            <p>
              Hyrestagare:
              <br />
              <b>{props.person.name}</b>
            </p>
            <p>
              Leasingdatum:
              <br />
              <b>{props.date}</b>
            </p>
          </>
        ) : (
          <>
            <p>
              Är du säker på att du vill påbörja ägarskifte för hästen{' '}
              <b>{props.horse.horseName}</b>?
            </p>
            <p>
              Köpare:
              <br />
              <b>{props.person.name}</b>
            </p>
            <p>
              Försäljningsdatum:
              <br />
              <b>{props.date}</b>
            </p>
          </>
        )
      }
      title={leasing ? 'Påbörja leasing?' : 'Påbörja ägarskifte?'}
      closeModal={onClose}
      action={leasing ? 'Skicka till hyrestagaren' : 'Skicka till köparen'}
      cancel="Avbryt"
      showFooter={!bankId}
      showHeader={!bankId}
    >
      {bankId && (
        <Box p={4}>
          <BankIdForm leasing={leasing} {...props} />
        </Box>
      )}
    </Alert>
  );
};

export default BankIdSubmit;

// tslint:disable
/**
 * Web API Auth Service
 * Service for supporting user authentication (issuing JWTs) for accessing secured resources.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    CredentialsAuthentication,
    CredentialsAuthenticationFromJSON,
    CredentialsAuthenticationToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    UserCredentials,
    UserCredentialsFromJSON,
    UserCredentialsToJSON,
} from '../models';

export interface AuthenticateCredentialsRequest {
    userCredentials: UserCredentials;
    useCookie?: boolean;
}

export interface RefreshAuthenticationRequest {
    authorization?: string;
    apiTravsportSe?: string;
    accept?: RefreshAuthenticationAcceptEnum;
}

export interface RevokeCredentialsRequest {
    userId: number;
    authorization?: string;
    apiTravsportSe?: string;
}


/**
 * Authenticate with user credentials (username/password)
 */
function authenticateCredentialsRaw<T>(requestParameters: AuthenticateCredentialsRequest, requestConfig: runtime.TypedQueryConfig<T, CredentialsAuthentication> = {}): QueryConfig<T> {
    if (requestParameters.userCredentials === null || requestParameters.userCredentials === undefined) {
        throw new runtime.RequiredError('userCredentials','Required parameter requestParameters.userCredentials was null or undefined when calling authenticateCredentials.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters.useCookie !== undefined && requestParameters.useCookie !== null) {
        headerParameters['Use-Cookie'] = String(requestParameters.useCookie);
    }


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/authenticate/credentials`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || UserCredentialsToJSON(requestParameters.userCredentials),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CredentialsAuthenticationFromJSON(body), text);
    }

    return config;
}

/**
* Authenticate with user credentials (username/password)
*/
export function authenticateCredentials<T>(requestParameters: AuthenticateCredentialsRequest, requestConfig?: runtime.TypedQueryConfig<T, CredentialsAuthentication>): QueryConfig<T> {
    return authenticateCredentialsRaw(requestParameters, requestConfig);
}

/**
 * Refresh authentication - Generate a new token, exclusive refresh of either cookie or header auth
 */
function refreshAuthenticationRaw<T>(requestParameters: RefreshAuthenticationRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
        headerParameters['Authorization'] = String(requestParameters.authorization);
    }

    if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
        headerParameters['Accept'] = String(requestParameters.accept);
    }


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/authenticate/refresh`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Refresh authentication - Generate a new token, exclusive refresh of either cookie or header auth
*/
export function refreshAuthentication<T>(requestParameters: RefreshAuthenticationRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return refreshAuthenticationRaw(requestParameters, requestConfig);
}

/**
 * Revoke credentials for a given user
 */
function revokeCredentialsRaw<T>(requestParameters: RevokeCredentialsRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
        throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling revokeCredentials.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
        headerParameters['authorization'] = String(requestParameters.authorization);
    }


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/authenticate/revoke/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Revoke credentials for a given user
*/
export function revokeCredentials<T>(requestParameters: RevokeCredentialsRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return revokeCredentialsRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum RefreshAuthenticationAcceptEnum {
    APPLICATION_JSON = 'application/json'
}

enum FilterValueEnum {
  HorseName = 'horseName',
  HorseId = 'horseId',
  RepresentativeName = 'representativeName',
  RepresentativeId = 'representativeId',
  Date = 'transactionDate',
  Product = 'productDescription',
  Amount = 'amount',
  Details = 'details',
  Total = 'total',
}

export default FilterValueEnum;

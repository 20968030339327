import useIsMobile from '@hooks/useIsMobile';
import Box from '@primitives/Box';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Table = styled.table<{ columns: number }>`
  && {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;

    td {
      font-size: ${props => props.theme.fontSizes.text}px;
      line-height: 17px;
      padding: 7px 4px 6px;
      border-bottom: none !important;
      vertical-align: top;
    }

    th {
      text-align: left;
      font-size: ${props => props.theme.fontSizes.notes}px;
      min-height: 25px;
      line-height: 16px;
      font-weight: bold;
      padding: 3px 4px 5px;
      border-bottom: 1px solid ${props => props.theme.colors.gray3};
    }
    th.noLine {
      border-bottom: none;
      padding: 0px;
    }
  }
`;

export interface Item {
  label: string;
  value?: ReactNode;
  grow?: boolean;
  wrapHyphen?: boolean;
}

interface FlexProps {
  values: Item[];
  forceColumns?: number;
  flagRemoveLine?: boolean;
  useTopMargin?: boolean;
}

export const InfoTable = ({
  values,
  forceColumns,
  flagRemoveLine = false,
  useTopMargin = false,
}: FlexProps): JSX.Element => {
  const isMobile = useIsMobile();
  const columns = forceColumns || (isMobile ? 2 : 3);

  let index = 0;

  const chunks = values
    .filter(item => item.value)
    .reduce((acc, item) => {
      if (!acc[index]) {
        acc[index] = [];
      }

      acc[index].push(item);

      if ((isMobile && item.grow) || acc[index].length === columns) {
        index++;
      }

      return acc;
    }, []);

  return (
    <Box verticalSpacing={4} mt={useTopMargin ? 4 : 0}>
      {chunks.map((chunk, i) => (
        <Table key={i} columns={columns}>
          <thead>
            <tr>
              {chunk.map((item, i) => {
                const colSpan =
                  i === chunk.length - 1 ? columns - chunk.length + 1 : 1;
                return (
                  <Box
                    as="th"
                    key={i}
                    colSpan={colSpan}
                    width={colSpan / columns}
                    className={!item.label && flagRemoveLine ? 'noLine' : ''}
                  >
                    {item.label}
                  </Box>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {chunk.map((item, i) => {
                const colSpan =
                  i === chunk.length - 1 ? columns - chunk.length + 1 : 1;
                return (
                  <Box
                    as="td"
                    key={i}
                    colSpan={colSpan}
                    width={colSpan / columns}
                    sx={
                      item.wrapHyphen && {
                        '&&': { whiteSpace: 'normal', hyphens: 'auto' },
                      }
                    }
                  >
                    {item.value}
                  </Box>
                );
              })}
            </tr>
          </tbody>
        </Table>
      ))}
    </Box>
  );
};

export default InfoTable;

import { getSavedChartFilters } from '@generated/user/src';

import { SavedFilter } from '../../generated/user/src/models/SavedFilter';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = SavedFilter[] | undefined;
export interface Entities {
  savedChartFilters?: { [key: string]: Result };
}

const useGetSavedChartFilters = (
  categoryId: number,
  licenseId: number,
): ApiResponse<Result, Entities> => {
  const key = `${categoryId}-${licenseId}`;

  return useApi({
    query: getSavedChartFilters<Entities>(
      {
        licenseId,
        categoryId,
      },
      {
        queryKey: `savedChartFilters:${key}`,
        transform: responseBody => ({
          savedChartFilters: { [key]: responseBody },
        }),
        update: {
          savedChartFilters: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities.savedChartFilters?.[key],
  });
};

export default useGetSavedChartFilters;

import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import {
  getOwnershipHorses,
  SportActorHorse,
} from '../../generated/sportactors/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = SportActorHorse[] | undefined;

export interface Entities {
  ownerHorses?: {
    [id: number]: Result;
  };
}

const getQuery = (ownerId: number): QueryConfig<Entities> =>
  getOwnershipHorses<Entities>(
    {
      ownerId,
    },
    {
      transform: responseBody => ({
        ownerHorses: {
          [ownerId]: responseBody,
        },
      }),
      update: {
        ownerHorses: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetHorse = (ownerId: number): ApiResponse<Result, Entities> => {
  const query = getQuery(ownerId);
  const selector = (state: State): Result =>
    state.entities?.ownerHorses?.[ownerId];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetHorse;

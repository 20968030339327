// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    HorseStatistic,
    HorseStatisticFromJSON,
    HorseStatisticToJSON,
} from './';

/**
 * 
 * @export
 * @interface HorseStatistics
 */
export interface HorseStatistics  {
    /**
     * 
     * @type {string}
     * @memberof HorseStatistics
     */
    winningRate: string;
    /**
     * 
     * @type {string}
     * @memberof HorseStatistics
     */
    placementRate: string;
    /**
     * 
     * @type {string}
     * @memberof HorseStatistics
     */
    points: string;
    /**
     * 
     * @type {string}
     * @memberof HorseStatistics
     */
    prizeMoneyMessage?: string;
    /**
     * Message for explaining the results.
     * @type {string}
     * @memberof HorseStatistics
     */
    startMessage?: string;
    /**
     * 
     * @type {Array<HorseStatistic>}
     * @memberof HorseStatistics
     */
    statistics: Array<HorseStatistic>;
}

export function HorseStatisticsFromJSON(json: any): HorseStatistics {
    return {
        'winningRate': json['winningRate'],
        'placementRate': json['placementRate'],
        'points': json['points'],
        'prizeMoneyMessage': !exists(json, 'prizeMoneyMessage') ? undefined : json['prizeMoneyMessage'],
        'startMessage': !exists(json, 'startMessage') ? undefined : json['startMessage'],
        'statistics': (json['statistics'] as Array<any>).map(HorseStatisticFromJSON),
    };
}

export function HorseStatisticsToJSON(value?: HorseStatistics): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'winningRate': value.winningRate,
        'placementRate': value.placementRate,
        'points': value.points,
        'prizeMoneyMessage': value.prizeMoneyMessage,
        'startMessage': value.startMessage,
        'statistics': (value.statistics as Array<any>).map(HorseStatisticToJSON),
    };
}



// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContentPageResourceMeta
 */
export interface ContentPageResourceMeta  {
    /**
     * 
     * @type {string}
     * @memberof ContentPageResourceMeta
     */
    slug?: string;
    /**
     * 
     * @type {Date}
     * @memberof ContentPageResourceMeta
     */
    lastPublishedAt?: Date;
}

export function ContentPageResourceMetaFromJSON(json: any): ContentPageResourceMeta {
    return {
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'lastPublishedAt': !exists(json, 'last_published_at') ? undefined : new Date(json['last_published_at']),
    };
}

export function ContentPageResourceMetaToJSON(value?: ContentPageResourceMeta): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slug': value.slug,
        'last_published_at': value.lastPublishedAt === undefined ? undefined : value.lastPublishedAt.toISOString(),
    };
}



interface Menus {
  menu: MenuItem[];
}

export interface ShortcutMenuSection {
  title: string;
  shortcuts: { label: string; href: string }[];
}

export interface MenuItem {
  href?: string;
  label: string;
  iconString?: string;
  subMenu?: MenuItem[];
  shortcutMenu?: ShortcutMenuSection[];
}

const menus: Menus = {
  menu: [
    {
      label: 'Tävling',
      href: '/tavling',
      iconString: 'trophy',
      subMenu: [
        { label: 'Tävlingskalender', href: '/tavling/tavlingskalender' },
        { label: 'Startlistor', href: '/tavling/startlistor' },
        { label: 'Resultat', href: `/tavling/resultat/` },
        { label: 'Insatslopp', href: '/tavling/insatslopp' },
        {
          label: 'Sök proposition',
          href: '/tavling/propositioner/',
        },
        { label: 'Negativa dopningsprov', href: '/tavling/dopningsprov' },
      ],
    },
    {
      label: 'Topplistor',
      href: '/topplistor',
      iconString: 'trophy',
      subMenu: [
        { label: '10 i topp', href: '/topplistor/10-i-topp' },
        { label: 'Hästar', href: '/topplistor/hastar' },
        { label: 'Ryttare', href: '/topplistor/ryttare' },
        { label: 'Tränare', href: '/topplistor/tranare' },
        { label: 'Ägare', href: '/topplistor/agare' },
        { label: 'Uppfödare', href: '/topplistor/uppfodare' },
        { label: 'Avel', href: '/topplistor/avel' },
      ],
    },
    {
      label: 'Sportinfo',
      href: '/sportinfo',
      iconString: 'info',
      subMenu: [
        {
          label: 'Hästar',
          href: '/sportinfo/hastar',
        },
        {
          label: 'Tränare/ryttare',
          href: '/sportinfo/licensinnehavare',
        },
        {
          label: 'Ägare',
          href: '/sportinfo/agare',
        },
        {
          label: 'Uppfödare',
          href: '/sportinfo/uppfodare',
        },
      ],
    },
    {
      label: 'Avel',
      href: '/avel',
      iconString: 'horse',
      subMenu: [
        { label: 'Stambok', href: '/avel/stambok' },
        {
          label: 'Ut- och införda hästar',
          href: '/avel/ut-och-inforda-hastar',
        },
      ],
    },
    {
      label: 'Tjänster & Blanketter',
      href: '/tjanster',
      iconString: 'info',
    },
  ],
};

export default menus;

// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * All horse vaccinations. Only for owner/trainers own horse.
 * @export
 * @interface Vaccination
 */
export interface Vaccination  {
    /**
     * The name of the vaccination.
     * @type {string}
     * @memberof Vaccination
     */
    name: string;
    /**
     * The code.
     * @type {string}
     * @memberof Vaccination
     */
    code: string;
    /**
     * The description.
     * @type {string}
     * @memberof Vaccination
     */
    description: string;
    /**
     * The date.
     * @type {Date}
     * @memberof Vaccination
     */
    date: Date;
    /**
     * Vaccination valid to date.
     * @type {Date}
     * @memberof Vaccination
     */
    validTo: Date;
}

export function VaccinationFromJSON(json: any): Vaccination {
    return {
        'name': json['name'],
        'code': json['code'],
        'description': json['description'],
        'date': parseDateTime(json['date']),
        'validTo': parseDateTime(json['validTo']),
    };
}

export function VaccinationToJSON(value?: Vaccination): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'code': value.code,
        'description': value.description,
        'date': value.date.toLocalDate(),
        'validTo': value.validTo.toLocalDate(),
    };
}



import {
  searchOwnershipByLabel,
  SearchOwnershipByLabelRequest,
  SportActorSearchReference,
} from '@generated/sportactors/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  owners?: {
    [key: string]: SportActorSearchReference[];
  };
}

const useSearchOwners = (
  request: SearchOwnershipByLabelRequest,
): ApiResponse<SportActorSearchReference[] | undefined, Entities> => {
  // 1. Query
  const query = searchOwnershipByLabel<Entities>(request, {
    transform: responseBody => ({
      owners: {
        [JSON.stringify(request)]: responseBody,
      },
    }),
    update: {
      owners: (prev, next): typeof next => ({ ...prev, ...next }),
    },
  });

  // 2. Selecctor
  const selector = (state: State): SportActorSearchReference[] | undefined =>
    state.entities.owners?.[JSON.stringify(request)];

  return useApi<SportActorSearchReference[] | undefined>({
    query,
    selector,
  });
};

export default useSearchOwners;

import { Flex } from '@primitives/Box';
import { Text } from '@primitives/Typography';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  outline: none;
  width: 38px;
  height: 20px;
  &:input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  margin-right: 8px;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  border-radius: 34px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.colors.gray2};
  transition: 0.2s;
  &:before {
    z-index: 2;
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    border: 1px solid #c7c4c1;
    border-radius: 50%;
    background-color: white;
    transition: 0.2s;
  }
`;

const Input = styled.input.attrs({ type: 'checkbox' })`
  width: 0;
  margin-left: 20px;
  &:checked + ${Slider}:before {
    transform: translateX(20px);
  }
  z-index: 2;
  pointer-events: none;
`;

const Cross = styled.div`
  position: absolute;
  z-index: 1;
  right: 12px;
  top: 4px;
  width: 0px;
  height: 0px;
  opacity: 0.7;
  &:before {
    position: absolute;
    left: 0px;
    content: ' ';
    height: 12px;
    width: 1px;
    background-color: black;
    transform: rotate(45deg);
  }
  &:after {
    position: absolute;
    left: 0px;
    content: ' ';
    height: 12px;
    width: 1px;
    background-color: black;
    transform: rotate(-45deg);
  }
  pointer-events: none;
`;

type params = Parameters<typeof Input>;

type Props = params[0] &
  React.InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
  };

function Switch({ label, ...props }: Props): JSX.Element {
  return (
    <Flex as="label">
      <Wrapper>
        <Input {...props} />
        <Slider />
        <Cross />
      </Wrapper>
      <Text as="span" color="gray5" fontWeight="normal">
        {label}
      </Text>
    </Flex>
  );
}

export default Switch;

import {
  getGenderSellections,
  getSireLocations,
  getSireTypes,
  getStudbookYears,
  Selection,
  TypedQueryConfig,
} from '@generated/breedings/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Data = {
  [categoryId: string]: Selection;
};

export interface Entities {
  sireTypes?: Data;
  studbookYears?: Data;
  studbookGenders?: Data;
  sireLocation?: Data;
}

type QueryFunction = <T = Entities>(
  requestParameters: { categoryid: string },
  requestConfig?: TypedQueryConfig<T, Selection>,
) => QueryConfig<T>;

type Values = Selection | undefined;

const hook =
  (propName: keyof Entities, func: QueryFunction) =>
  (categoryid: string): ApiResponse<Values, Entities> => {
    const query = func(
      { categoryid },
      {
        transform: responseBody => ({
          [propName]: {
            [categoryid]: responseBody,
          },
        }),
        update: {
          [propName]: (prev, next) => ({
            ...prev,
            ...next,
          }),
        },
      },
    );

    const selector = (state: State): Values =>
      state.entities?.[propName]?.[categoryid];

    return useApi<Values>({
      query,
      selector,
    });
  };

export const useGetSireTypes = hook('sireTypes', getSireTypes);
export const useGetSireLocation = hook('sireLocation', getSireLocations);
export const useGetStudbookGenders = hook(
  'studbookGenders',
  getGenderSellections,
);
export const useGetStudbookYears = hook('studbookYears', getStudbookYears);

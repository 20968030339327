// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    LinkableHorse,
    LinkableHorseFromJSON,
    LinkableHorseToJSON,
    LinkableLicenseHolder,
    LinkableLicenseHolderFromJSON,
    LinkableLicenseHolderToJSON,
} from './';

/**
 * 
 * @export
 * @interface RaceResultRow
 */
export interface RaceResultRow  {
    /**
     * 
     * @type {string}
     * @memberof RaceResultRow
     */
    placementDisplay: string;
    /**
     * The number in the program. Note thatprogram number and start position might differ
     * @type {string}
     * @memberof RaceResultRow
     */
    programNumber: string;
    /**
     * 
     * @type {LinkableHorse}
     * @memberof RaceResultRow
     */
    horse?: LinkableHorse;
    /**
     * 
     * @type {LinkableLicenseHolder}
     * @memberof RaceResultRow
     */
    driver?: LinkableLicenseHolder;
    /**
     * 
     * @type {LinkableLicenseHolder}
     * @memberof RaceResultRow
     */
    trainer?: LinkableLicenseHolder;
    /**
     * Only available for regular races (before 2019-01-01)
     * @type {string}
     * @memberof RaceResultRow
     */
    odds?: string;
    /**
     * Only available for regular races (before 2019-01-01)
     * @type {string}
     * @memberof RaceResultRow
     */
    oddsPlats?: string;
    /**
     * 
     * @type {string}
     * @memberof RaceResultRow
     */
    weight: string;
    /**
     * 
     * @type {string}
     * @memberof RaceResultRow
     */
    time: string;
    /**
     * 
     * @type {string}
     * @memberof RaceResultRow
     */
    maxHCP: string;
    /**
     * 
     * @type {string}
     * @memberof RaceResultRow
     */
    reason?: string;
    /**
     * 
     * @type {number}
     * @memberof RaceResultRow
     */
    prizeMoney: number;
    /**
     * 
     * @type {number}
     * @memberof RaceResultRow
     */
    bonus: number;
}

export function RaceResultRowFromJSON(json: any): RaceResultRow {
    return {
        'placementDisplay': json['placementDisplay'],
        'programNumber': json['programNumber'],
        'horse': !exists(json, 'horse') ? undefined : LinkableHorseFromJSON(json['horse']),
        'driver': !exists(json, 'driver') ? undefined : LinkableLicenseHolderFromJSON(json['driver']),
        'trainer': !exists(json, 'trainer') ? undefined : LinkableLicenseHolderFromJSON(json['trainer']),
        'odds': !exists(json, 'odds') ? undefined : json['odds'],
        'oddsPlats': !exists(json, 'oddsPlats') ? undefined : json['oddsPlats'],
        'weight': json['weight'],
        'time': json['time'],
        'maxHCP': json['maxHCP'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'prizeMoney': json['prizeMoney'],
        'bonus': json['bonus'],
    };
}

export function RaceResultRowToJSON(value?: RaceResultRow): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'placementDisplay': value.placementDisplay,
        'programNumber': value.programNumber,
        'horse': LinkableHorseToJSON(value.horse),
        'driver': LinkableLicenseHolderToJSON(value.driver),
        'trainer': LinkableLicenseHolderToJSON(value.trainer),
        'odds': value.odds,
        'oddsPlats': value.oddsPlats,
        'weight': value.weight,
        'time': value.time,
        'maxHCP': value.maxHCP,
        'reason': value.reason,
        'prizeMoney': value.prizeMoney,
        'bonus': value.bonus,
    };
}



// tslint:disable
/**
 * Web API Account Service
 * Service for perform account operations, such as creating accounts or recovering accounts.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    BankIdCollectedAccountCreation,
    BankIdCollectedAccountCreationFromJSON,
    BankIdCollectedAccountCreationToJSON,
    BankIdUserAccount,
    BankIdUserAccountFromJSON,
    BankIdUserAccountToJSON,
    BankIdUserAuthentication,
    BankIdUserAuthenticationFromJSON,
    BankIdUserAuthenticationToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface CancelRequest {
    orderReference: string;
}

export interface CollectRequest {
    orderReference: string;
    useCookie?: boolean;
}

export interface CollectWithAnimatedQrCodeRequest {
    orderReference: string;
    qrTime: number;
    useCookie?: boolean;
}

export interface InitializeRequest {
    bankIdUserAccount: BankIdUserAccount;
}


/**
 * Cancels an ongoing Bank ID account creation. Fails if there is no existing account creation with {orderReference}.
 * Cancel an ongoing Bank ID account creation
 */
function cancelRaw<T>(requestParameters: CancelRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.orderReference === null || requestParameters.orderReference === undefined) {
        throw new runtime.RequiredError('orderReference','Required parameter requestParameters.orderReference was null or undefined when calling cancel.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/accounts/bankid/cancel/{orderReference}`.replace(`{${"orderReference"}}`, encodeURIComponent(String(requestParameters.orderReference))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Cancels an ongoing Bank ID account creation. Fails if there is no existing account creation with {orderReference}.
* Cancel an ongoing Bank ID account creation
*/
export function cancel<T>(requestParameters: CancelRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return cancelRaw(requestParameters, requestConfig);
}

/**
 * When COMPLETE, creates the new user account in the sport system.
 * Collects the signing of an ongoing BankID order
 */
function collectRaw<T>(requestParameters: CollectRequest, requestConfig: runtime.TypedQueryConfig<T, BankIdCollectedAccountCreation> = {}): QueryConfig<T> {
    if (requestParameters.orderReference === null || requestParameters.orderReference === undefined) {
        throw new runtime.RequiredError('orderReference','Required parameter requestParameters.orderReference was null or undefined when calling collect.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    if (requestParameters.useCookie !== undefined && requestParameters.useCookie !== null) {
        headerParameters['Use-Cookie'] = String(requestParameters.useCookie);
    }


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/accounts/bankid/collect/{orderReference}`.replace(`{${"orderReference"}}`, encodeURIComponent(String(requestParameters.orderReference))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(BankIdCollectedAccountCreationFromJSON(body), text);
    }

    return config;
}

/**
* When COMPLETE, creates the new user account in the sport system.
* Collects the signing of an ongoing BankID order
*/
export function collect<T>(requestParameters: CollectRequest, requestConfig?: runtime.TypedQueryConfig<T, BankIdCollectedAccountCreation>): QueryConfig<T> {
    return collectRaw(requestParameters, requestConfig);
}

/**
 * When COMPLETE, creates the new user account in the sport system.
 * Collects the signing of an ongoing BankID order with animated qr code pattern.
 */
function collectWithAnimatedQrCodeRaw<T>(requestParameters: CollectWithAnimatedQrCodeRequest, requestConfig: runtime.TypedQueryConfig<T, BankIdCollectedAccountCreation> = {}): QueryConfig<T> {
    if (requestParameters.orderReference === null || requestParameters.orderReference === undefined) {
        throw new runtime.RequiredError('orderReference','Required parameter requestParameters.orderReference was null or undefined when calling collectWithAnimatedQrCode.');
    }

    if (requestParameters.qrTime === null || requestParameters.qrTime === undefined) {
        throw new runtime.RequiredError('qrTime','Required parameter requestParameters.qrTime was null or undefined when calling collectWithAnimatedQrCode.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    if (requestParameters.useCookie !== undefined && requestParameters.useCookie !== null) {
        headerParameters['Use-Cookie'] = String(requestParameters.useCookie);
    }


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/accounts/bankid/collect/{orderReference}/{qrTime}`.replace(`{${"orderReference"}}`, encodeURIComponent(String(requestParameters.orderReference))).replace(`{${"qrTime"}}`, encodeURIComponent(String(requestParameters.qrTime))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(BankIdCollectedAccountCreationFromJSON(body), text);
    }

    return config;
}

/**
* When COMPLETE, creates the new user account in the sport system.
* Collects the signing of an ongoing BankID order with animated qr code pattern.
*/
export function collectWithAnimatedQrCode<T>(requestParameters: CollectWithAnimatedQrCodeRequest, requestConfig?: runtime.TypedQueryConfig<T, BankIdCollectedAccountCreation>): QueryConfig<T> {
    return collectWithAnimatedQrCodeRaw(requestParameters, requestConfig);
}

/**
 * The initialize must be followed by one or more subsequent collect requests to collect and commit the creation of the account, provided of course that the authentication against BankID completes successfully.
 * Initializes the creation of a new user account using BankID.
 */
function initializeRaw<T>(requestParameters: InitializeRequest, requestConfig: runtime.TypedQueryConfig<T, BankIdUserAuthentication> = {}): QueryConfig<T> {
    if (requestParameters.bankIdUserAccount === null || requestParameters.bankIdUserAccount === undefined) {
        throw new runtime.RequiredError('bankIdUserAccount','Required parameter requestParameters.bankIdUserAccount was null or undefined when calling initialize.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/accounts/bankid/initialize`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || BankIdUserAccountToJSON(requestParameters.bankIdUserAccount),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(BankIdUserAuthenticationFromJSON(body), text);
    }

    return config;
}

/**
* The initialize must be followed by one or more subsequent collect requests to collect and commit the creation of the account, provided of course that the authentication against BankID completes successfully.
* Initializes the creation of a new user account using BankID.
*/
export function initialize<T>(requestParameters: InitializeRequest, requestConfig?: runtime.TypedQueryConfig<T, BankIdUserAuthentication>): QueryConfig<T> {
    return initializeRaw(requestParameters, requestConfig);
}


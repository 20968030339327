import { Entities } from '@apis';
import { unregisterMounting } from '@generated/licenseholders/src';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../utils/types';
import { useApiMutateWithoutData } from '../utils/useApiMutate';

const useUnRegisterMounting = (
  licenseId: number,
): MutationResponseWithoutData<Entities, number> =>
  useApiMutateWithoutData({
    queryFunc: (raceDayId: number): QueryConfig<Entities> =>
      unregisterMounting({
        licenseId,
        raceDayId,
      }),
    queryKey: 'unregisterMounting',
  });

export default useUnRegisterMounting;

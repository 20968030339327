import getToday from '@utils/getToday';
import * as Yup from 'yup';

import SelectedHorses from './type/SelectedHorses';

const validationSchema = Yup.object().shape({
  date: Yup.date()
    .required('Obligatorisk')
    .typeError('Ange ett datum')
    .max(getToday(), 'Ej senare än dagens datum'),
  vaccine: Yup.string().required('Obligatorisk'),
  approveAgreement: Yup.boolean().oneOf(
    [true],
    'Du måste godkänna vaccinationsvillkoren',
  ),
  selectedHorses: Yup.object().test({
    name: 'atLeastOneSelectedHorse',
    message: 'Du måste välja minst en häst',
    test: function (selectedHorses: SelectedHorses) {
      for (const horseId in selectedHorses) {
        if (selectedHorses[horseId]) {
          return true;
        }
      }
      return false;
    },
  }),
});

export default validationSchema;

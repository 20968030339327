import { Arrow } from '@primitives/Arrow';
import Box from '@primitives/Box';
import { Toggle } from '@primitives/Button';
import Icon from '@primitives/Icon';
import { H4 } from '@primitives/Typography';
import React, { ReactNode } from 'react';
import { StyledIcon } from 'styled-icons/types';

type BoxParameters = Parameters<typeof Box>;

type Props = {
  open: boolean;
  children: ReactNode;
} & BoxParameters[0];

const Wrapper = ({ children, open, ...props }: Props): JSX.Element => (
  <Box
    borderBottomStyle="solid"
    borderBottomColor="gray3"
    borderBottomWidth={open ? 1 : 0}
    backgroundColor={open ? 'light-blue' : 'inherit'}
    paddingTop={1}
    paddingBottom={open ? 4 : 1}
    paddingLeft={2}
    paddingRight={2}
    {...props}
  >
    {children}
  </Box>
);

type ToggleWrapperProps = {
  children: ReactNode;
  headline: ReactNode;
  open: boolean;
  tight?: boolean;
  disabled?: boolean;
  icon?: StyledIcon;
  onToggle: (open: boolean) => void;
} & BoxParameters[0];

export const WrapperWithToggle = ({
  children,
  headline,
  open,
  tight = false,
  disabled = false,
  icon,
  onToggle,
  ...props
}: ToggleWrapperProps): JSX.Element => {
  const header = (
    <>
      {icon && <Icon as={icon} color="deep-blue" size={16} mr={2} mb="-2px" />}
      <H4
        as="h2"
        color="gray6"
        mb={tight ? 1 : 2}
        mt={1}
        fontWeight="bold"
        width="fit-content"
      >
        {headline}
      </H4>
    </>
  );
  return (
    <Wrapper open={open} {...props}>
      {disabled ? (
        <Box mt={tight ? 0 : '3px'} mb={tight ? 0 : '10px'}>
          {header}
        </Box>
      ) : (
        <Toggle
          onClick={() => onToggle(!open)}
          mt={tight ? 0 : '3px'}
          mb={tight ? 0 : '10px'}
        >
          {header}
          <Arrow
            ml="auto"
            up={open}
            size={16}
            position="relative"
            bottom={tight ? '-1px' : '2px'}
          />
        </Toggle>
      )}

      {children}
    </Wrapper>
  );
};

export default Wrapper;

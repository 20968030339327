import {
  useDeletePreSelectedDriver,
  useGetPreSelectedDrivers,
  useInsertPreSelectedDriver,
  useSearch,
} from '@apis';
import Autocomplete from '@components/Autocomplete';
import DataGrid from '@components/DataGrid';
import FormLayout, { FormItem } from '@components/FormLayout';
import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/authenticate/src';
import {
  SearchResultItem,
  SearchSportInfoTypeEnum,
} from '@generated/content/src';
import { Driver } from '@generated/user/src';
import withUser from '@hoc/withUser';
import { dataGridRemoveButtonMixin, RemoveButton } from '@primitives/Button';
import Error from '@primitives/Error';
import Icon from '@primitives/Icon';
import { Text } from '@primitives/Typography';
import { User } from '@styled-icons/fa-solid';
import formatDriver from '@utils/formatDriver';
import { licenseholder } from '@utils/links';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CellProps } from 'react-table';

interface Props {
  user: AuthenticatedUser;
}

function PreSelectedDrivers({ user }: Props): JSX.Element {
  const [query, setQuery] = useState('');
  const [state, setState] = useState('');

  const { data } = useSearch({
    query,
    type: SearchSportInfoTypeEnum.LicenseHolder,
    onlyValid: true,
    onlyRiders: true,
  });

  const { action } = useInsertPreSelectedDriver(user.licenseId);
  const { action: remove } = useDeletePreSelectedDriver(user.licenseId);

  const {
    data: drivers,
    error,
    status,
    refresh,
  } = useGetPreSelectedDrivers(user.licenseId);

  if (status && status != 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  return (
    <PageLayout
      headline="Förvalda ryttare"
      singleCard
      form={
        <FormLayout
          as="form"
          boxScheme="onlyDesktop"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <FormItem>
            <Autocomplete<SearchResultItem>
              label="Sök ryttare"
              suggestions={data?.results || []}
              onSuggestionsFetchRequested={arg => {
                setQuery(arg.value);
              }}
              onSuggestionsClearRequested={() => {
                setQuery('');
              }}
              getSuggestionValue={suggestion => suggestion.name}
              onSuggestionSelected={async (e, { suggestion }) => {
                const { status } = await action({
                  id: suggestion.id,
                  name: suggestion.name,
                  sortOrder: 0,
                  suspended: false,
                });
                if (status === 201) {
                  await refresh();
                }
              }}
              renderSuggestion={item => (
                <Text fontSize={['lead', , 'text']} color="deep-blue">
                  <Icon size={12} as={User} mr={2} />
                  {`${item.name} (${item.licenseType})`}
                </Text>
              )}
              inputProps={{
                placeholder: 'Skriv 2 eller fler tecken',
                value: state,
                onChange: (e, { newValue }) => {
                  setState(newValue);
                },
              }}
            />
          </FormItem>
        </FormLayout>
      }
    >
      <DataGrid
        title={`Du har ${drivers?.length || 0} förvalda ryttare`}
        data={(drivers || []).sort((a, b) => a.name.localeCompare(b.name))}
        columns={[
          {
            Header: 'Namn',
            accessor: 'name',
            Cell: ({ row: { original: row } }) => (
              <Link to={licenseholder(row.id)}>{formatDriver(row)}</Link>
            ),
          },
          {
            Header: 'Ta bort',
            accessor: 'id',

            Cell: ({ value }: CellProps<Driver, number>) => (
              <RemoveButton
                onClick={async () => {
                  await remove(value);

                  await refresh();
                }}
              />
            ),
            ...dataGridRemoveButtonMixin,
          },
        ]}
        noHover
      />
    </PageLayout>
  );
}

export default withUser(PreSelectedDrivers, { onlyAuthenticated: true });

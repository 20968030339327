import Icon from '@primitives/Icon';
import { CircleNotch } from '@styled-icons/fa-solid';
import React, { ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';
import { typography, TypographyProps } from 'styled-system';

import { base, BaseProps } from '../base';
import { colorScheme } from './variants';

export { default as CloseButtom } from './Close';
export { default as ButtonLink } from './Link';
export { dataGridRemoveButtonMixin, default as RemoveButton } from './Remove';

interface ButtonProps extends BaseProps, TypographyProps {
  colorScheme?: 'primary' | 'secondary' | 'save' | 'delete' | 'bookmark';
  block?: boolean;
  loading?: boolean;
}

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledButton = styled.button<ButtonProps>`
  outline: none;
  transition: all 250ms ease-in-out;
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
  appearance: none;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  font-size: 13px;
  line-height: 1;
  height: 32px;
  position: relative;
  white-space: nowrap;

  ${p => p.block && 'width: 100%;'}

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  padding: ${p => (p.loading ? '8px 30px 8px 12px' : '8px 20px')};
  transition: padding 0.3s ease;

  .loading {
    position: absolute;
    right: 11px;
    top: 9px;
    animation: ${rotation} 1.5s linear infinite;
  }

  ${colorScheme};
  ${base(typography)};
`;

type StyledButtonParameters = Parameters<typeof StyledButton>;

export type Props = StyledButtonParameters[0] & {
  loading?: boolean;
  children: ReactNode;
};

export default function Button({
  children,
  icon,
  loading,
  ...props
}: Props): JSX.Element {
  return (
    <StyledButton loading={loading === true ? 1 : 0} {...props}>
      {icon && <Icon className="button-icon" as={icon} mr={2} size={14} />}
      {children}
      {loading && <CircleNotch className="loading" size="14" />}
    </StyledButton>
  );
}

Button.defaultProps = {
  colorScheme: 'primary',
  type: 'button',
};

export const Toggle = styled.button<BaseProps>`
  ${base()}
  display: flex;
  width: 100%;
  align-items: center;
  color: ${props => props.theme.colors.blue};
`;

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    EnrollmentHorse,
    EnrollmentHorseFromJSON,
    EnrollmentHorseToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnrollmentProposition
 */
export interface EnrollmentProposition  {
    /**
     * 
     * @type {number}
     * @memberof EnrollmentProposition
     */
    propositionId: number;
    /**
     * 
     * @type {number}
     * @memberof EnrollmentProposition
     */
    propositionNumber: number;
    /**
     * 
     * @type {string}
     * @memberof EnrollmentProposition
     */
    propositionText: string;
    /**
     * 
     * @type {number}
     * @memberof EnrollmentProposition
     */
    distance: number;
    /**
     * 
     * @type {number}
     * @memberof EnrollmentProposition
     */
    totalSum: number;
    /**
     * 
     * @type {string}
     * @memberof EnrollmentProposition
     */
    surface: string;
    /**
     * 
     * @type {string}
     * @memberof EnrollmentProposition
     */
    raceName: string;
    /**
     * 
     * @type {Array<EnrollmentHorse>}
     * @memberof EnrollmentProposition
     */
    enrollmentHorses: Array<EnrollmentHorse>;
}

export function EnrollmentPropositionFromJSON(json: any): EnrollmentProposition {
    return {
        'propositionId': json['propositionId'],
        'propositionNumber': json['propositionNumber'],
        'propositionText': json['propositionText'],
        'distance': json['distance'],
        'totalSum': json['totalSum'],
        'surface': json['surface'],
        'raceName': json['raceName'],
        'enrollmentHorses': (json['enrollmentHorses'] as Array<any>).map(EnrollmentHorseFromJSON),
    };
}

export function EnrollmentPropositionToJSON(value?: EnrollmentProposition): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'propositionId': value.propositionId,
        'propositionNumber': value.propositionNumber,
        'propositionText': value.propositionText,
        'distance': value.distance,
        'totalSum': value.totalSum,
        'surface': value.surface,
        'raceName': value.raceName,
        'enrollmentHorses': (value.enrollmentHorses as Array<any>).map(EnrollmentHorseToJSON),
    };
}



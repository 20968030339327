import { selectSearchFormOpen } from '@main/state/slice';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function useSearchIsOpen(): boolean {
  const open = useSelector(selectSearchFormOpen);
  const { pathname } = useLocation();

  return open && pathname !== '/search';
}

import { getBreederStatistics, Statistics } from '@generated/sportactors/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type BreederStatistics = Statistics[] | undefined;

export interface Entities {
  breederStatistics?: {
    [id: number]: BreederStatistics;
  };
}

const useGetBreederStatistics = (
  breederId: number,
): ApiResponse<BreederStatistics, Entities> => {
  // 1. Query
  const query = getBreederStatistics<Entities>(
    {
      breederId,
    },
    {
      transform: responseBody => ({
        breederStatistics: {
          [breederId]: responseBody,
        },
      }),
      update: {
        breederStatistics: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  // 2. Selecctor
  const selector = (state: State): BreederStatistics =>
    state.entities?.breederStatistics?.[breederId];

  return useApi<BreederStatistics>({
    query,
    selector,
  });
};

export default useGetBreederStatistics;

// tslint:disable
/**
 * Web API Charts Service
 * Service for getting info about the different charts.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ChartCategory,
    ChartCategoryFromJSON,
    ChartCategoryToJSON,
    ChartType,
    ChartTypeFromJSON,
    ChartTypeToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Selection,
    SelectionFromJSON,
    SelectionToJSON,
} from '../models';

export interface ListChartTypesRequest {
    categoryId: number;
}

export interface ListSelectionsRequest {
    categoryId: number;
    typeId: number;
}


/**
 * List the chart categories.
 */
function listChartCategoriesRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Array<ChartCategory>> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/charts/categories`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(ChartCategoryFromJSON), text);
    }

    return config;
}

/**
* List the chart categories.
*/
export function listChartCategories<T>( requestConfig?: runtime.TypedQueryConfig<T, Array<ChartCategory>>): QueryConfig<T> {
    return listChartCategoriesRaw( requestConfig);
}

/**
 * List the chart types for a given category id.
 */
function listChartTypesRaw<T>(requestParameters: ListChartTypesRequest, requestConfig: runtime.TypedQueryConfig<T, Array<ChartType>> = {}): QueryConfig<T> {
    if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
        throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling listChartTypes.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/charts/categories/{categoryId}/types`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(ChartTypeFromJSON), text);
    }

    return config;
}

/**
* List the chart types for a given category id.
*/
export function listChartTypes<T>(requestParameters: ListChartTypesRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<ChartType>>): QueryConfig<T> {
    return listChartTypesRaw(requestParameters, requestConfig);
}

/**
 * List the different selections for a given chart type. 
 */
function listSelectionsRaw<T>(requestParameters: ListSelectionsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Selection>> = {}): QueryConfig<T> {
    if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
        throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling listSelections.');
    }

    if (requestParameters.typeId === null || requestParameters.typeId === undefined) {
        throw new runtime.RequiredError('typeId','Required parameter requestParameters.typeId was null or undefined when calling listSelections.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/charts/categories/{categoryId}/types/{typeId}/selections`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))).replace(`{${"typeId"}}`, encodeURIComponent(String(requestParameters.typeId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(SelectionFromJSON), text);
    }

    return config;
}

/**
* List the different selections for a given chart type. 
*/
export function listSelections<T>(requestParameters: ListSelectionsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Selection>>): QueryConfig<T> {
    return listSelectionsRaw(requestParameters, requestConfig);
}


// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Shareholder
 */
export interface Shareholder  {
    /**
     * Owner id
     * @type {number}
     * @memberof Shareholder
     */
    id: number;
    /**
     * Owner\'s shares
     * @type {number}
     * @memberof Shareholder
     */
    shares: number;
    /**
     * True if the owner is a representative. Note that this is just a GET helper, it will be ignored when updating
     * @type {boolean}
     * @memberof Shareholder
     */
    representative: boolean;
    /**
     * Shareholder full name (first and last name). Note that this is just a GET helper, it will be ignored when updating
     * @type {string}
     * @memberof Shareholder
     */
    fullName: string;
}

export function ShareholderFromJSON(json: any): Shareholder {
    return {
        'id': json['id'],
        'shares': json['shares'],
        'representative': json['representative'],
        'fullName': json['fullName'],
    };
}

export function ShareholderToJSON(value?: Shareholder): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'shares': value.shares,
        'representative': value.representative,
        'fullName': value.fullName,
    };
}



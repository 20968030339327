import {
  EmailResponseMessage,
  startDeclaredDetailEmailReceipt,
} from '@generated/propositions/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

export interface Entities {
  startDeclaredDetailEmailReceipt?: EmailResponseMessage;
}

const useStartDeclaredDetailEmailReceipt = (
  propositionId: number,
  horseId: number,
): MutationResponse<
  Entities['startDeclaredDetailEmailReceipt'],
  Entities,
  unknown
> => {
  const queryFunc = (): QueryConfig<Entities> =>
    startDeclaredDetailEmailReceipt({
      propositionId,
      horseId,
    });
  return useApiMutate<Entities['startDeclaredDetailEmailReceipt'], unknown>({
    queryFunc,
    selector: (state: State) => state.entities.startDeclaredDetailEmailReceipt,
    queryKey: 'startDeclaredDetailEmailReceipt',
  });
};

export default useStartDeclaredDetailEmailReceipt;

// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface BreederHorse
 */
export interface BreederHorse  {
    /**
     * 
     * @type {number}
     * @memberof BreederHorse
     */
    horseId: number;
    /**
     * 
     * @type {string}
     * @memberof BreederHorse
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof BreederHorse
     */
    bredWithMultipleStallions: boolean;
    /**
     * 
     * @type {number}
     * @memberof BreederHorse
     */
    mareYearOfDeath?: number;
}

export function BreederHorseFromJSON(json: any): BreederHorse {
    return {
        'horseId': json['horseId'],
        'name': json['name'],
        'bredWithMultipleStallions': json['bredWithMultipleStallions'],
        'mareYearOfDeath': !exists(json, 'mareYearOfDeath') ? undefined : json['mareYearOfDeath'],
    };
}

export function BreederHorseToJSON(value?: BreederHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'name': value.name,
        'bredWithMultipleStallions': value.bredWithMultipleStallions,
        'mareYearOfDeath': value.mareYearOfDeath,
    };
}



// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Error map mapping the error codes to the respective invalid id numbers.
 * @export
 * @interface Person
 */
export interface Person  {
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    personType?: PersonPersonTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    id?: number;
}

export function PersonFromJSON(json: any): Person {
    return {
        'personType': !exists(json, 'personType') ? undefined : json['personType'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function PersonToJSON(value?: Person): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'personType': value.personType,
        'name': value.name,
        'id': value.id,
    };
}

/**
* @export
* @enum {string}
*/
export enum PersonPersonTypeEnum {
    PHYSICAL = 'PHYSICAL',
    LEGAL = 'LEGAL'
}



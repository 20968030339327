// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface ChartType
 */
export interface ChartType  {
    /**
     * 
     * @type {number}
     * @memberof ChartType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ChartType
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ChartType
     */
    searchParameter?: string;
    /**
     * 
     * @type {string}
     * @memberof ChartType
     */
    code?: string;
}

export function ChartTypeFromJSON(json: any): ChartType {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'searchParameter': !exists(json, 'searchParameter') ? undefined : json['searchParameter'],
        'code': !exists(json, 'code') ? undefined : json['code'],
    };
}

export function ChartTypeToJSON(value?: ChartType): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'searchParameter': value.searchParameter,
        'code': value.code,
    };
}



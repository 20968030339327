// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Remark, only for trainers own list.
 * @export
 * @interface Remark
 */
export interface Remark  {
    /**
     * Horse latest start with track code and date e.g. BP-200505
     * @type {string}
     * @memberof Remark
     */
    latestStart?: string;
    /**
     * How long vaccine is valid
     * @type {string}
     * @memberof Remark
     */
    vaccine?: string;
    /**
     * Info if ownership representative is blocked
     * @type {string}
     * @memberof Remark
     */
    blocked?: string;
}

export function RemarkFromJSON(json: any): Remark {
    return {
        'latestStart': !exists(json, 'latestStart') ? undefined : json['latestStart'],
        'vaccine': !exists(json, 'vaccine') ? undefined : json['vaccine'],
        'blocked': !exists(json, 'blocked') ? undefined : json['blocked'],
    };
}

export function RemarkToJSON(value?: Remark): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'latestStart': value.latestStart,
        'vaccine': value.vaccine,
        'blocked': value.blocked,
    };
}



import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import {
  getOwnershipBasicInformation,
  SportActorBasicInformation,
} from '../../generated/sportactors/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = SportActorBasicInformation | undefined;

export interface Entities {
  owner?: {
    [id: number]: Result;
  };
}

const getQuery = (ownerId: number): QueryConfig<Entities> =>
  getOwnershipBasicInformation<Entities>(
    {
      ownerId,
    },
    {
      transform: responseBody => ({
        owner: {
          [ownerId]: responseBody,
        },
      }),
      update: {
        owner: (prev, next): typeof next => ({ ...prev, ...next }),
      },
    },
  );

const useGetOwner = (ownerId: number): ApiResponse<Result, Entities> => {
  const query = getQuery(ownerId);
  const selector = (state: State): Result => state.entities?.owner?.[ownerId];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetOwner;

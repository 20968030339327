import { TextArea as PrimitiveTextarea } from '@primitives/Form';
import CSS from 'csstype';
import { FieldHookConfig, useField } from 'formik';
import React, { InputHTMLAttributes } from 'react';

import Error from './Error';

type Props = {
  label?: string;
  sx?: CSS.Properties;
} & FieldHookConfig<string> &
  InputHTMLAttributes<HTMLTextAreaElement>;

export default function TextArea({ label, sx, ...props }: Props): JSX.Element {
  const [field, meta] = useField<string>({
    ...props,
  });

  const inputProps = {
    ...field,
    sx,
    placeholder: props?.placeholder,
    disabled: props?.disabled,
    className: meta.touched && meta.error ? 'errorInput' : undefined,
  };

  return (
    <>
      {label && <label htmlFor={field.name}>{label}</label>}
      <PrimitiveTextarea {...inputProps} />

      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </>
  );
}

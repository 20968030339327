import { Select as PrimitiveSelect } from '@primitives/Form';
import { useField } from 'formik';
import React, { ChangeEventHandler } from 'react';

import Error from './Error';

export type SelectOption = { disabled?: boolean; value: string; label: string };

type Props = {
  label: string;
  name: string;
  options: SelectOption[];
  width?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
};

export function Select({
  label,
  options,
  width,
  name,
  required = false,
  onChange,
  ...props
}: Props): JSX.Element {
  const [field, meta] = useField({ type: 'select', name });
  return (
    <>
      <PrimitiveSelect
        {...field}
        {...props}
        onChange={onChange ?? field.onChange}
        label={label}
        width={width}
        required={required}
        className={meta.touched && meta.error ? 'errorInput' : null}
        options={options}
      />
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </>
  );
}

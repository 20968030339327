import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QueryConfig, querySelectors, requestAsync } from 'redux-query';

import { State } from '../../main/store';
import { Entities } from '..';
import setBaseUrl, { apiTypes } from './setBaseUrl';
import { LazyApiResponse } from './types';
import useApiCommon from './useApiCommon';

interface Props<TQuery, TSelector> {
  query: TQuery;
  selector: TSelector;
  basePath?: apiTypes;
}

const useApiLazy = <
  TResp,
  TQuery extends QueryConfig = QueryConfig,
  TSelector extends (state: State) => TResp = (state: State) => TResp,
>({
  query: queryConfig,
  selector,
  basePath = 'webapi',
}: Props<TQuery, TSelector>): LazyApiResponse<TResp, Entities> => {
  // Add absolute api base url to the query
  const query = setBaseUrl(queryConfig, basePath);

  const { error, data, errorCode } = useApiCommon<TResp>({
    query,
    selector,
  });

  const isFinished = useSelector((state: State) =>
    querySelectors.isFinished(state.queries, query),
  );

  const status = useSelector((state: State) =>
    querySelectors.status(state.queries, query),
  );

  const dispatch = useDispatch();

  const action = useCallback(() => {
    dispatch(
      requestAsync({
        ...query,
      }),
    );
  }, [dispatch, query]);

  return {
    action,
    data,
    loading: !isFinished || (!data && !error),
    status,
    error,
    errorCode,
  };
};

export default useApiLazy;

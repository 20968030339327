// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface OffspringHorse
 */
export interface OffspringHorse  {
    /**
     * 
     * @type {number}
     * @memberof OffspringHorse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OffspringHorse
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof OffspringHorse
     */
    linkable: boolean;
}

export function OffspringHorseFromJSON(json: any): OffspringHorse {
    return {
        'id': json['id'],
        'name': json['name'],
        'linkable': json['linkable'],
    };
}

export function OffspringHorseToJSON(value?: OffspringHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'linkable': value.linkable,
    };
}



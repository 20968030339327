import { useGetEnrollmentsForSpecificRaceDay } from '@apis';
import RegDateHasPassedAlert from '@components/RegDateHasPassedAlert';
import { AuthenticatedUser } from '@generated/account/src';
import theme from '@main/theme';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import { ActionLink } from '@primitives/Link';
import Loading from '@primitives/Loading';
import { Lead, Text } from '@primitives/Typography';
import { thousandSeparator } from '@utils/thousandSeparator';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Header from './utils/Header';

const LI = styled.li`
  color: ${props => props.theme.colors['dim-gray']};
  content: '•';
`;

interface Props {
  user: AuthenticatedUser;
}

const RaceDay = ({ user }: Props): JSX.Element => {
  const { raceDayId } = useParams<{ raceDayId?: string }>();

  const { data, loading, error, status, refresh } =
    useGetEnrollmentsForSpecificRaceDay(
      user.licenseId,
      parseInt(raceDayId || '0'),
    );

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  if (raceDayId === undefined) {
    return <Error>Tävlingsdag saknas</Error>;
  }

  const registrationDateHasPassed =
    new Date() > data.latestStartRegistrationDate;

  return (
    <>
      <Header
        trackName={data.trackName}
        raceDate={data.raceDate}
        registrationDate={data.registrationDate}
        changeWeightDate={data.changeWeightDate}
        latestStartRegistrationDate={data.latestStartRegistrationDate}
        headline="Ny startanmälan"
        backLink="/minasidor/startanmalan"
      >
        <>
          {!data.propositions.length && (
            <Text>Du har inga hästar att startanmäla till aktuell dag.</Text>
          )}

          {data.propositions.length > 0 && !registrationDateHasPassed && (
            <RegDateHasPassedAlert
              date={data.latestStartRegistrationDate}
              refresh={refresh}
              title="Tiden för startanmälan har löpt ut"
              description="Därmed kan startanmälan ej längre genomföras."
            />
          )}

          {data.propositions.length > 0 &&
            data.propositions.map((item, index) => (
              <Box key={index} pb={6}>
                <Box display="flex" pl={1}>
                  <Lead color="deep-blue">
                    <b>
                      Prop {item.propositionNumber} {item.raceName}
                    </b>{' '}
                    {thousandSeparator(item.totalSum)} kr {item.distance}{' '}
                    {item.surface}
                  </Lead>
                </Box>
                <Box
                  pb={3}
                  borderBottom={`1px solid ${theme.colors.gray3}`}
                ></Box>
                {item.enrollmentHorses.map((enrolledHorse, index) => (
                  <Box
                    key={index}
                    borderBottom={`1px solid ${theme.colors.gray3}`}
                    p={[2, null, 1]}
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    sx={{
                      lineHeight: '37px',
                    }}
                  >
                    <Box
                      pl={1}
                      width={['55%', null, '35%']}
                      mt="auto"
                      mb="auto"
                    >
                      <Text
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {enrolledHorse.horseName}
                      </Text>
                    </Box>
                    <Box display="flex" width={[null, null, '25%']}>
                      <ActionLink
                        to={`/minasidor/startanmalan/${enrolledHorse.horseId}/${item.propositionId}/registrera`}
                      >
                        Startanmäl
                      </ActionLink>
                    </Box>
                    <Box
                      display="flex"
                      mt={[
                        enrolledHorse.numberOfEnrollments > 0 ? 2 : '0px',
                        null,
                        '0px',
                      ]}
                      pl={[1, null, '0px']}
                      width={['100%', null, '40%']}
                    >
                      {enrolledHorse.numberOfEnrollments > 0 && (
                        <>
                          <LI />
                          <Text ml="-8px" mb="auto" mt="auto" color="dim-gray">
                            Startanmäld i annan proposition{' '}
                          </Text>
                        </>
                      )}
                      <Text></Text>
                    </Box>
                  </Box>
                ))}
              </Box>
            ))}
        </>
      </Header>
    </>
  );
};

export default RaceDay;

import {
  ComingStart,
  getComingStartsByOwnerships,
} from '@generated/sportactors/src';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = ComingStart[] | undefined;

export interface Entities {
  comingStartsByOwnerships?: {
    [id: number]: Result;
  };
}

const useGetOwner = (ownerId: number): ApiResponse<Result, Entities> =>
  useApi<Result>({
    query: getComingStartsByOwnerships<Entities>(
      {
        ownerId,
      },
      {
        transform: responseBody => ({
          comingStartsByOwnerships: {
            [ownerId]: responseBody,
          },
        }),
        update: {
          comingStartsByOwnerships: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities?.comingStartsByOwnerships?.[ownerId],
  });

export default useGetOwner;

import FadedContent from '@components/ContentBlock/Faded';
import { AuthenticatedUser } from '@generated/account/src';
import Box from '@primitives/Box';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import NewLeasingForm from './NewLeasingForm';
import RepresentativeHorsesList from './RepresentativeHorsesList';

interface Props {
  user: AuthenticatedUser;
}

const NewLeasing = ({ user }: Props): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:horseId(\\d+)`}>
        <NewLeasingForm user={user} />
      </Route>
      <Route>
        <Box verticalSpacing={4} my={4}>
          <FadedContent slug="leasing-anmal-leasing" />
          <RepresentativeHorsesList user={user} />
        </Box>
      </Route>
    </Switch>
  );
};

export default NewLeasing;

import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import {
  getSearchSelections,
  Selection,
} from '../../generated/licenseholders/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  searchSelections?: Selection[];
}

const getQuery = (): QueryConfig<Entities> =>
  getSearchSelections<Entities>({
    transform: responseBody => ({
      searchSelections: responseBody,
    }),
    update: {
      searchSelections: (prev, next): typeof next => next,
    },
  });

const useGetLicenseHolderSearchSelections = (): ApiResponse<
  Entities['searchSelections'],
  Entities
> => {
  const query = getQuery();

  const selector = (state: State): Entities['searchSelections'] =>
    state.entities.searchSelections;

  return useApi<Entities['searchSelections']>({
    query,
    selector,
  });
};

export default useGetLicenseHolderSearchSelections;

import { changePassword, ChangePasswordRequest } from '@generated/user/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';
import { Entities } from './useGetUser';

type ActionParams = ChangePasswordRequest['changePasswordCredentials'];

const useChangePassword = (
  licenseId: number,
): MutationResponse<Entities['user'], Entities, ActionParams> => {
  const queryFunc = (
    changePasswordCredentials: ActionParams,
  ): QueryConfig<Entities> =>
    changePassword({
      licenseId,
      changePasswordCredentials,
    });

  return useApiMutate<Entities['user'], ActionParams>({
    queryFunc,
    selector: (state: State) => state.entities.user,
    queryKey: 'changePassword',
  });
};

export default useChangePassword;

import { useGetCoveringList, useSaveCovering, useUpdateCovering } from '@apis';
import Content from '@components/ContentBlock/Content';
import DirtyFormWarning from '@components/DirtyFormWarning';
import { Checkbox } from '@components/FormikComponents';
import Step from '@components/Step';
import { Covering } from '@generated/breedings/src';
import Box from '@primitives/Box';
import Button, { ButtonLink } from '@primitives/Button';
import { SimpleList } from '@primitives/List';
import Message from '@primitives/Message';
import { Small } from '@primitives/Typography';
import Refresh from '@utils/Refresh';
import { isToday } from 'date-fns';
import { isPast, isThisYear } from 'date-fns/esm';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';

import CoveringDatePicker from './CoveringDatePicker';
import CoveringDatesTable from './CoveringDatesTable';
import SearchMare from './CoveringFormSearchMare';
import MissingMareForm from './MissingMareForm';

export interface FormState extends Partial<Covering> {
  mareId?: number;
}

interface BaseProps {
  studId: number;
  coveringId: 'new' | number;
}

interface Props extends BaseProps {
  coverings: Covering[];
  refresh: Refresh;
}

const CoveringSchema = Yup.object().shape({
  confirm: Yup.bool()
    .oneOf([true], 'Du måste godkänna villkoren')
    .required('Du måste godkänna villkoren'),
  pregnant: Yup.bool().notRequired(),
  coveringDates: Yup.array()
    .of(
      Yup.string().test(
        'is-valid-date',
        'Ett ogiltigt betäckningsdatum har angets. Datum för språnget måste vara innevarande år och kan inte vara i framtiden.',
        value => {
          const date = new Date(value);
          return (isPast(date) || isToday(date)) && isThisYear(date);
        },
      ),
    )
    .min(1, 'Minst ett språng måste anges.')
    .required('Minst ett språng måste anges.'),
  mareId: Yup.number().required('Inget sto har valts.'),
});

function CoveringForm({
  studId,
  coveringId,
  coverings,
  refresh,
}: Props): JSX.Element {
  const existing =
    coverings.find(item => item.id === coveringId) || ({} as Partial<Covering>);

  const initialValues = {
    pregnant: false,
    coveringDates: [],
    mareId: existing?.mare?.id,
    confirm: false,
    ...existing,
  };

  const { action: insert } = useSaveCovering(studId);
  const { action: update } = useUpdateCovering(studId);

  const [openMissingMareForm, setOpenMissingMareForm] = useState(false);

  const { path } = useRouteMatch();
  const history = useHistory();

  return (
    <>
      <MissingMareForm
        studId={studId}
        onClose={() => {
          setOpenMissingMareForm(false);
        }}
        open={openMissingMareForm}
      />
      <Formik<FormState>
        initialValues={initialValues}
        validationSchema={CoveringSchema}
        validate={values => {
          if (
            coverings.some(
              item =>
                item.mare.id === values.mareId &&
                values.mareId !== existing?.mare?.id,
            )
          ) {
            return {
              mareId: 'Stoet finns redan med i språngrullan!',
            };
          }
        }}
        onSubmit={async (
          values,
          { setSubmitting, setFieldError, resetForm },
        ) => {
          let resp;

          if (coveringId === 'new') {
            resp = await insert(values as Covering);
          } else {
            resp = await update({ id: coveringId, ...values } as Covering);
          }

          if (resp.status < 400) {
            await resetForm({});
            await refresh();
            history.push(generatePath(path, { studId: studId.toString() }));
          } else {
            setFieldError('all', resp.body?.localizedErrorMessage || resp.text);
          }

          setSubmitting(false);
        }}
      >
        {({ errors, isSubmitting, dirty, touched }) => {
          const errorList = Object.keys(errors)
            .filter(key => touched[key])
            .map(key => errors[key].toString());

          return (
            <Form>
              <DirtyFormWarning />
              <Box verticalSpacing={5}>
                <Step header="1. När utfördes betäckningen?">
                  <Small color="dim-gray">
                    Lägg till ett datum i taget, du kan lägga till flera.
                  </Small>
                  <Box maxWidth="300px" py={3}>
                    <CoveringDatePicker name="coveringDates" noError />
                  </Box>
                  <CoveringDatesTable />
                </Step>
                <Step header="2. Vilket sto betäcktes?">
                  <Small color="dim-gray">
                    Sök efter ett sto,{' '}
                    <ButtonLink
                      onClick={() => {
                        setOpenMissingMareForm(true);
                      }}
                    >
                      klicka här
                    </ButtonLink>{' '}
                    om det saknas i söklistan.
                  </Small>
                  <SearchMare />
                </Step>
                <Box verticalSpacing={2} display="inline-block">
                  <Checkbox label="Dräktig (ej obligatorisk)" name="pregnant" />
                  <Checkbox
                    label="Jag godkänner villkoren"
                    name="confirm"
                    noError
                  />
                  <Content
                    slug="betackningsvillkor"
                    fontSize={['micro', , 'notes']}
                    as={Small}
                    mt={0}
                    pl={[0, , 5]}
                  />
                </Box>

                {errorList.length > 0 && (
                  <Message variant="errorBox">
                    <SimpleList items={errorList} />
                  </Message>
                )}

                <Box horizontalSpacing={3} display="flex">
                  <Button
                    type="button"
                    colorScheme="secondary"
                    onClick={() => {
                      history.push(
                        generatePath(path, { studId: studId.toString() }),
                      );
                    }}
                  >
                    Avbryt
                  </Button>
                  <Button
                    type="submit"
                    loading={isSubmitting}
                    disabled={!dirty}
                  >
                    Spara
                  </Button>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default function CoveringFormWithData({
  studId,
  coveringId,
}: BaseProps): JSX.Element {
  const { data: coverings, refresh } = useGetCoveringList(studId);

  if (!coverings) {
    return null;
  }

  return (
    <CoveringForm
      coverings={coverings}
      studId={studId}
      coveringId={coveringId}
      refresh={refresh}
    />
  );
}

// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    GenderGroup,
    GenderGroupFromJSON,
    GenderGroupToJSON,
    OffspringHorseMothersFather,
    OffspringHorseMothersFatherFromJSON,
    OffspringHorseMothersFatherToJSON,
} from './';

/**
 * Values that might be useful for client side filtering of an offspring view
 * @export
 * @interface OffspringViewFilterValues
 */
export interface OffspringViewFilterValues  {
    /**
     * All offspring years, exclusive for current offspring view
     * @type {Array<string>}
     * @memberof OffspringViewFilterValues
     */
    offspringVintages?: Array<string>;
    /**
     * All offspring grandpas on mother\'s side, exclusive for current offspring view
     * @type {Array<OffspringHorseMothersFather>}
     * @memberof OffspringViewFilterValues
     */
    grandpasOnMothersSide?: Array<OffspringHorseMothersFather>;
    /**
     * All possible genders on a horse, NOT exclusive for this current view
     * @type {Array<GenderGroup>}
     * @memberof OffspringViewFilterValues
     */
    genderGroups?: Array<GenderGroup>;
}

export function OffspringViewFilterValuesFromJSON(json: any): OffspringViewFilterValues {
    return {
        'offspringVintages': !exists(json, 'offspringVintages') ? undefined : json['offspringVintages'],
        'grandpasOnMothersSide': !exists(json, 'grandpasOnMothersSide') ? undefined : (json['grandpasOnMothersSide'] as Array<any>).map(OffspringHorseMothersFatherFromJSON),
        'genderGroups': !exists(json, 'genderGroups') ? undefined : (json['genderGroups'] as Array<any>).map(GenderGroupFromJSON),
    };
}

export function OffspringViewFilterValuesToJSON(value?: OffspringViewFilterValues): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'offspringVintages': value.offspringVintages,
        'grandpasOnMothersSide': value.grandpasOnMothersSide === undefined ? undefined : (value.grandpasOnMothersSide as Array<any>).map(OffspringHorseMothersFatherToJSON),
        'genderGroups': value.genderGroups === undefined ? undefined : (value.genderGroups as Array<any>).map(GenderGroupToJSON),
    };
}



import {
  getRepresentativeHorses,
  GetRepresentativeHorsesServiceTypeEnum,
  Horse,
} from '../../generated/ownership-transfers/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  representativeHorses?: {
    [key: number]: Horse[];
  };
}

const useGetRepresentativeHorses = (
  licenseId,
  serviceType = GetRepresentativeHorsesServiceTypeEnum.OWNERSHIP_TRANSFERS,
): ApiResponse<Horse[], Entities> =>
  useApi<Horse[]>({
    query: getRepresentativeHorses<Entities>(
      { licenseId, serviceType },
      {
        queryKey: `representativeHorses:${licenseId}:${serviceType}`,
        transform: responseBody => ({
          representativeHorses: {
            [licenseId]: responseBody,
          },
        }),
        update: {
          representativeHorses: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities?.representativeHorses?.[licenseId],
  });

export default useGetRepresentativeHorses;

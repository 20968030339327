import { useGetDriverTravelFees } from '@apis';
import DataGrid from '@components/DataGrid';
import { DriverTravelFee } from '@generated/tds/src';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import { formatNumber } from '@utils/formatting';
import Refresh from '@utils/Refresh';
import React, { useEffect } from 'react';
import { CellProps, Column } from 'react-table';

import DeleteFee from './DeleteFee';

const columns = (
  licenseId: number,
  refresh: Refresh,
): Column<DriverTravelFee>[] => [
  {
    accessor: 'trackName',
    Header: 'Bana',
  },
  {
    accessor: 'amount',
    Header: 'Arvode',
    Cell: ({
      value,
    }: CellProps<DriverTravelFee, DriverTravelFee['amount']>) => (
      <>{formatNumber(value)}</>
    ),
  },
  {
    accessor: 'trackId',
    alignment: 'center',
    Header: 'Ta bort',
    Cell: ({
      value,
    }: CellProps<DriverTravelFee, DriverTravelFee['trackId']>) => (
      <DeleteFee licenseId={licenseId} refresh={refresh} trackId={value} />
    ),
  },
];

interface Props {
  licenseId: number;
  refreshList: number;
}

export default function List({ licenseId, refreshList }: Props): JSX.Element {
  const { data, loading, error, status, refresh } =
    useGetDriverTravelFees(licenseId);

  useEffect(() => {
    if (refreshList) {
      refresh();
    }
  }, [refreshList, refresh]);

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading && !data) {
    return <Loading />;
  }

  return data.length > 0 ? (
    <DataGrid
      mt={5}
      title={`Du har ${data.length} registrera${
        data.length > 1 ? 'de' : 't'
      } researvorde${data.length > 1 ? 'n' : ''}:`}
      columns={columns(licenseId, refresh)}
      data={data}
    />
  ) : null;
}

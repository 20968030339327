import { Horse } from '@generated/ownership-transfers/src';
import Box from '@primitives/Box';
import { Link } from '@primitives/Link';
import { SimpleList } from '@primitives/List';
import { horse } from '@utils/links';
import React from 'react';
import { CellProps } from 'react-table';

interface RemarksProps {
  item: Horse;
  list?: boolean;
}

const Remarks = ({ item, list = false }: RemarksProps): JSX.Element => {
  const remarks = [];

  if (item.horseBarred) {
    remarks.push('Hästen är spärrad');
  }

  if (item.permanentStartBan) {
    remarks.push('Hästen har permanent startförbud');
  }

  if (item.inStartList) {
    remarks.push(
      <>
        Hästen är <Link to={horse(item.horseId, '/resultat')}>startanmäld</Link>
      </>,
    );
  }

  if (item.inStakeRace) {
    remarks.push(
      <>
        Hästen är{' '}
        <Link to={horse(item.horseId, '/historik')}>anmäld i insatslopp</Link>
      </>,
    );
  }

  return remarks.length > 0 ? (
    list ? (
      <SimpleList items={remarks} fontSize="small" />
    ) : (
      <>
        {remarks.map((v, i) => (
          <Box key={i} as="span">
            {v}
            {remarks.length > i + 1 && ', '}
          </Box>
        ))}
      </>
    )
  ) : null;
};

export const hasRemarks = (horse: Horse): boolean =>
  horse.horseBarred ||
  horse.permanentStartBan ||
  horse.inStartList ||
  horse.inStakeRace;

export const RemarksCell = ({ row }: CellProps<Horse>): JSX.Element => {
  const { original: item } = row;
  return <Remarks item={item} list />;
};

export default Remarks;

// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    HorseHorseGender,
    HorseHorseGenderFromJSON,
    HorseHorseGenderToJSON,
} from './';

/**
 * 
 * @export
 * @interface Horse
 */
export interface Horse  {
    /**
     * 
     * @type {number}
     * @memberof Horse
     */
    horseId: number;
    /**
     * 
     * @type {string}
     * @memberof Horse
     */
    horseName: string;
    /**
     * 
     * @type {string}
     * @memberof Horse
     */
    horseRegistrationNumber: string;
    /**
     * 
     * @type {number}
     * @memberof Horse
     */
    horseAge: number;
    /**
     * 
     * @type {HorseHorseGender}
     * @memberof Horse
     */
    horseGender: HorseHorseGender;
    /**
     * 
     * @type {string}
     * @memberof Horse
     */
    ownershipName: string;
    /**
     * 
     * @type {number}
     * @memberof Horse
     */
    sellerRepresentativeId: number;
    /**
     * 
     * @type {number}
     * @memberof Horse
     */
    ownershipId: number;
    /**
     * 
     * @type {Date}
     * @memberof Horse
     */
    earliestTransferDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Horse
     */
    horseBarred: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Horse
     */
    permanentStartBan: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Horse
     */
    inStartList: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Horse
     */
    inStakeRace: boolean;
}

export function HorseFromJSON(json: any): Horse {
    return {
        'horseId': json['horseId'],
        'horseName': json['horseName'],
        'horseRegistrationNumber': json['horseRegistrationNumber'],
        'horseAge': json['horseAge'],
        'horseGender': HorseHorseGenderFromJSON(json['horseGender']),
        'ownershipName': json['ownershipName'],
        'sellerRepresentativeId': json['sellerRepresentativeId'],
        'ownershipId': json['ownershipId'],
        'earliestTransferDate': parseDateTime(json['earliestTransferDate']),
        'horseBarred': json['horseBarred'],
        'permanentStartBan': json['permanentStartBan'],
        'inStartList': json['inStartList'],
        'inStakeRace': json['inStakeRace'],
    };
}

export function HorseToJSON(value?: Horse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'horseName': value.horseName,
        'horseRegistrationNumber': value.horseRegistrationNumber,
        'horseAge': value.horseAge,
        'horseGender': HorseHorseGenderToJSON(value.horseGender),
        'ownershipName': value.ownershipName,
        'sellerRepresentativeId': value.sellerRepresentativeId,
        'ownershipId': value.ownershipId,
        'earliestTransferDate': value.earliestTransferDate.toLocalDate(),
        'horseBarred': value.horseBarred,
        'permanentStartBan': value.permanentStartBan,
        'inStartList': value.inStartList,
        'inStakeRace': value.inStakeRace,
    };
}



// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewsResourceMeta
 */
export interface NewsResourceMeta  {
    /**
     * 
     * @type {string}
     * @memberof NewsResourceMeta
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof NewsResourceMeta
     */
    htmlUrl: string;
    /**
     * 
     * @type {Date}
     * @memberof NewsResourceMeta
     */
    firstPublishedAt: Date;
}

export function NewsResourceMetaFromJSON(json: any): NewsResourceMeta {
    return {
        'slug': json['slug'],
        'htmlUrl': json['html_url'],
        'firstPublishedAt': new Date(json['first_published_at']),
    };
}

export function NewsResourceMetaToJSON(value?: NewsResourceMeta): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slug': value.slug,
        'html_url': value.htmlUrl,
        'first_published_at': value.firstPublishedAt.toISOString(),
    };
}



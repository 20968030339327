import roleEnum from '@utils/roleEnum';

export const TDS_ROLES = [roleEnum.Tds, roleEnum.TdsDriver];
export const OWNER_ROLES = [roleEnum.Partner, roleEnum.Representative];
export const OWNERSHIP_TRANSFER_ROLES = [
  roleEnum.Foretredare,
  roleEnum.Delegare,
  roleEnum.TempForetredare,
  roleEnum.TempDelegare,
];

export const CHANGE_PASSWORD_PATH = '/minasidor/installningar/losen';
export const CHANGE_SETTINGS_PATH = '/minasidor/installningar/';

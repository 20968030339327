import config from '@config';
import Box, { Flex } from '@primitives/Box';
import { H1, Lead, Text } from '@primitives/Typography';
import React from 'react';
import styled from 'styled-components';

import theme from './theme';

const FooterBg = styled(Box)`
  transform: scale(1, 1.25);

  background: repeating-conic-gradient(
      from 45deg,
      #2e3d71 0% 25%,
      transparent 0% 50%
    )
    50% 0px;

  background-size: 50px 50px;
`;

const ourLinks = [
  {
    label: 'svenskgalopp.se',
    href: config.publicWebUrl,
    noNewTab: true,
  },
  {
    label: 'galopptips.se',
    href: 'https://www.galopptips.se',
  },
  {
    label: 'horseracingsweden.com',
    href: 'https://www.horseracingsweden.com',
    noNewTab: true,
  },
];

const ourTracks = [
  {
    label: 'Bro Park',
    href: 'https://www.svenskgalopp.se/banor-evenemang/bro-park/',
    noNewTab: true,
  },
  {
    label: 'Jägersro Galopp',
    href: 'https://www.svenskgalopp.se/banor-evenemang/jagersro-galopp/',
    noNewTab: true,
  },
  {
    label: 'Göteborg Galopp',
    href: 'https://www.svenskgalopp.se/banor-evenemang/goteborg-galopp/',
    noNewTab: true,
  },
];

const infoLinks = [
  {
    label: 'Användarvillkor',
    href: 'https://app.svenskgalopp.se/anvandarvillkor',
    noNewTab: true,
  },

  {
    label: 'Integritetspolicy',
    href: 'https://www.svenskgalopp.se/integritetspolicy',
    noNewTab: true,
  },

  {
    label: 'Cookies',
    href: 'https://www.svenskgalopp.se/cookies',
    noNewTab: true,
  },

  {
    label: 'Regelverk',
    href: 'https://www.svenskgalopp.se/verktyg/regelverk/',
    noNewTab: true,
  },
  {
    label: 'Tjänster & Blanketter',
    href: '/tjanster',
    noNewTab: true,
  },
];

const socialLinks = [
  {
    label: 'Facebook',
    href: 'https://www.facebook.com/svenskgalopp/',
  },
  {
    label: 'LinkedIn',
    href: 'https://www.linkedin.com/company/svensk-galopp/',
  },
  {
    label: 'Youtube',
    href: 'https://www.youtube.com/c/SvenskGaloppAB',
  },
  {
    label: 'X / Twitter',
    href: 'https://twitter.com/svenskgalopp',
  },
  {
    label: 'Instagram',
    href: 'https://www.instagram.com/svenskgalopp/',
  },
];

const contactLinks = [
  {
    label: '08-466 86 00',
    href: 'tel:084668600',
    noNewTab: true,
  },
  {
    label: 'info@svenskgalopp.se',
    href: 'mailto:info@svenskgalopp.se',
    noNewTab: true,
  },
  {
    label: 'Kontaktuppgifter',
    href: 'https://www.svenskgalopp.se/om-oss/kontakta-oss',
    noNewTab: true,
  },
];

const TextItem = styled(Text).attrs({
  color: 'light-blue',
  lineHeight: '22px',
  display: 'block',
})``;

export default function Footer(): JSX.Element {
  return (
    <Box
      className="hidden-print"
      as="footer"
      mt={4}
      width={1}
      bg="deep-blue"
      pb="100px"
      position="relative"
      overflow="hidden"
      sx={{ flexShrink: 0 }}
    >
      <FooterBg
        width={1}
        position="absolute"
        top="100px"
        left="0"
        bottom="0"
        right="0"
      />

      <Box position="relative" color="light-blue">
        <H1
          as="h2"
          textAlign="center"
          color="light-blue"
          py={5}
          fontSize={theme.fontSizes.h2}
          mb={5}
          display={['none', , 'block']}
        >
          Detta är en webbplats från Svensk Galopp
        </H1>
        <Flex
          mx="auto"
          width={1}
          maxWidth="1100px"
          px={4}
          justifyContent="stretch"
          sx={{
            textAlign: ['center', , 'left'],
            alignItems: ['center', , 'flex-start'],
            flexDirection: ['column', , 'row'],
          }}
        >
          <Box flex="1" mb={[6, , 0]}>
            <Box
              mt={['60px', , 0]}
              width="90px"
              height="auto"
              as="img"
              src="https://www.svenskgalopp.se/static/img/sg_logo_white.png"
            />
          </Box>
          <Box flex="1" mb={[6, , 0]} pr={[0, , 2]}>
            <Lead mb={3} color="white">
              Kontakta oss
            </Lead>

            {contactLinks.map(item => (
              <TextItem
                key={item.label}
                as="a"
                href={item.href}
                target={item.noNewTab ? '_self' : '_blank'}
                rel="noopener noreferrer"
              >
                {item.label}
              </TextItem>
            ))}
          </Box>
          <Box flex="1" mb={[6, , 0]}>
            <Lead mb={3} color="white">
              Information
            </Lead>

            {infoLinks.map(item => (
              <TextItem
                key={item.label}
                as="a"
                href={item.href}
                target={item.noNewTab ? '_self' : '_blank'}
                rel="noopener noreferrer"
              >
                {item.label}
              </TextItem>
            ))}
          </Box>
          <Box flex="1" maxWidth={110} mb={[6, , 0]}>
            <Lead mb={3} color="white">
              Följ oss
            </Lead>

            {socialLinks.map(item => (
              <TextItem
                key={item.label}
                as="a"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.label}
              </TextItem>
            ))}
          </Box>
          <Box flex="1" minWidth={160} mb={[6, , 0]}>
            <Lead mb={3} color="white">
              Våra webbplatser
            </Lead>

            {ourLinks.map(item => (
              <TextItem
                key={item.label}
                as="a"
                href={item.href}
                target={item.noNewTab ? '_self' : '_blank'}
                rel="noopener noreferrer"
              >
                {item.label}
              </TextItem>
            ))}
          </Box>
          <Box flex="1">
            <Lead mb={3} color="white">
              Våra banor
            </Lead>

            {ourTracks.map(item => (
              <TextItem
                key={item.label}
                as="a"
                href={item.href}
                target={item.noNewTab ? '_self' : '_blank'}
                rel="noopener noreferrer"
              >
                {item.label}
              </TextItem>
            ))}
          </Box>
        </Flex>
      </Box>
      <Box
        bg="#E1EEF7"
        color="#233369"
        height="40px"
        position="absolute"
        bottom="0"
        width={1}
        sx={{ textAlign: 'center', lineHeight: '40px' }}
      >
        © Svensk Galopp {new Date().getFullYear()}
      </Box>
    </Box>
  );
}

import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import {
  getOwnershipFutureInvoiceTransactions,
  OwnerTransactions,
} from '../../generated/sportactors/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = OwnerTransactions[] | undefined;

export interface Entities {
  ownershipFutureInvoiceTransactions?: {
    [ownerId: number]: Result;
  };
}

const getQuery = (ownerId: number): QueryConfig<Entities> =>
  getOwnershipFutureInvoiceTransactions<Entities>(
    {
      ownerId,
    },
    {
      force: true,
      transform: responseBody => ({
        ownershipFutureInvoiceTransactions: {
          [ownerId]: responseBody,
        },
      }),
      update: {
        ownershipFutureInvoiceTransactions: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetOwnershipFutureInvoiceTransactions = (
  ownerId: number,
): ApiResponse<Result, Entities> => {
  const query = getQuery(ownerId);
  const selector = (state: State): Result =>
    state.entities?.ownershipFutureInvoiceTransactions?.[ownerId];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetOwnershipFutureInvoiceTransactions;

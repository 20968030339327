import { useGetVaccines } from '@apis';
import Confirm from '@components/Confirm';
import DirtyFormWarning from '@components/DirtyFormWarning';
import { Checkbox, DatePicker, Select } from '@components/FormikComponents';
import { FormItem } from '@components/FormLayout';
import Step from '@components/Step';
import Box, { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import List, { LI } from '@primitives/List';
import Loading from '@primitives/Loading';
import Message from '@primitives/Message';
import { Text } from '@primitives/Typography';
import { dateToUtc, formatTz } from '@utils/dateUtils';
import getToday from '@utils/getToday';
import { Form, Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { ValidationError } from 'yup';

import Horses from './Horses';
import PreCheckHorses from './PreCheckHorses';
import RegistrationResult from './RegistrationResult';
import SaveRegistration from './SaveRegistration';
import Task from './utils/enum/Task';
import getIdsFromSelectedHorses from './utils/getIdsFromSelectedHorses';
import getNamesFromSelectedHorses from './utils/getNamesFromSelectedHorses';
import FormState from './utils/type/FormState';
import PreCheckData from './utils/type/PreCheckData';
import SaveRegistrationData from './utils/type/SaveRegistrationData';
import SelectedHorses from './utils/type/SelectedHorses';
import SetFieldValue from './utils/type/SetFieldValue';
import validationSchema from './utils/validationSchema';

interface Props {
  licenseId: number;
}

export default function NewVaccination({ licenseId }: Props): JSX.Element {
  const { data, loading, status, error } = useGetVaccines();
  const [task, setTask] = useState(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [refreshHorses, setRefreshHorses] = useState<number>(0);
  const [preCheckData, setPreCheckData] = useState<PreCheckData>(null);
  const [saveRegistrationData, setSaveRegistrationData] =
    useState<SaveRegistrationData>(null);
  const formLoading =
    (preCheckData && preCheckData.loading) ||
    (saveRegistrationData && saveRegistrationData.loading);

  const closeModal = useCallback(() => {
    setTask(null);
    setPreCheckData(null);
    setSaveRegistrationData(null);
  }, [setTask, setPreCheckData, setSaveRegistrationData]);

  const initialValues: FormState = {
    all: '',
    approveAgreement: false,
    date: getToday(),
    vaccine: '',
    selectedHorses: {},
  };

  const options = useMemo(
    () =>
      data
        ? [
            { label: 'Välj vaccin', value: '' },
            ...data.map(vaccine => ({
              label: vaccine.name,
              value: vaccine.id.toString(),
            })),
          ]
        : null,
    [data],
  );

  const warnings = useMemo(() => {
    if (preCheckData && preCheckData.data) {
      const result = preCheckData.data.filter(horse => horse.vaccineWarning);
      if (result.length) {
        return result;
      }
    }
    return null;
  }, [preCheckData]);

  const setSelectedHorse = useCallback(
    (
      horseId: number,
      horseName: string,
      setFieldValue: SetFieldValue,
      selectedHorses,
    ) => {
      setFieldValue('selectedHorses', {
        ...selectedHorses,
        [horseId]: horseName,
      });
    },
    [],
  );

  if (loading || !data) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  return (
    <Formik<FormState>
      initialValues={initialValues}
      validate={async values => {
        const errors: Partial<{
          all: string;
          approveAgreement: string;
          date: Date;
          vaccine: string;
          selectedHorses: SelectedHorses;
        }> = {};

        try {
          await validationSchema.validate(values);
        } catch (error) {
          if (error instanceof ValidationError) {
            errors[error.path] = error.message;
            if (submitted) {
              errors.all =
                'Formuläret innehåller felaktiga värden. Gå igenom formuläret på nytt.';
            }
          }
        }

        return errors;
      }}
      onSubmit={() => {
        setTask(Task.RunPreCheck);
      }}
    >
      {({ errors, values, setFieldValue, touched, resetForm }) => {
        const selectedHorseIds = getIdsFromSelectedHorses(
          values.selectedHorses,
        );
        const selectedHorseNames = getNamesFromSelectedHorses(
          values.selectedHorses,
        );
        const selectedVaccine = data.find(
          item => item.id.toString() === values.vaccine,
        );

        return (
          <Box>
            {saveRegistrationData && !saveRegistrationData.loading && (
              <RegistrationResult
                error={saveRegistrationData.error}
                resetForm={resetForm}
                setRefreshHorses={setRefreshHorses}
                setSaveRegistrationData={setSaveRegistrationData}
                setSubmitted={setSubmitted}
              />
            )}
            {task === Task.RunPreCheck && (
              <PreCheckHorses
                licenseId={licenseId}
                vaccinationdate={dateToUtc(values.date)}
                vaccineid={parseInt(values.vaccine)}
                horseids={getIdsFromSelectedHorses(values.selectedHorses)}
                setPreCheckData={setPreCheckData}
              />
            )}
            {task === Task.SaveRegistration && (
              <SaveRegistration
                licenseId={licenseId}
                vaccinationDate={dateToUtc(values.date)}
                vaccineId={parseInt(values.vaccine)}
                horseIds={getIdsFromSelectedHorses(values.selectedHorses)}
                setSaveRegistrationData={setSaveRegistrationData}
              />
            )}
            {preCheckData && preCheckData.data && (
              <Confirm
                title="Vill du spara?"
                description={`Är du säker på att du vill spara vaccinatione${
                  selectedHorseIds.length > 1 ? 'rna' : 'n'
                }?`}
                size="medium"
                closeModal={closeModal}
                colorScheme="primary"
                error={preCheckData.error}
                loading={preCheckData.loading}
                onAction={() => {
                  setPreCheckData(null);
                  setTask(Task.SaveRegistration);
                }}
                open={true}
              >
                <Box mx={4} mb={4}>
                  <Text mb={4} as="div">
                    Vaccin <b>{selectedVaccine.name}</b> med datum{' '}
                    <Text
                      as="span"
                      fontWeight="bold"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {formatTz(values.date, 'yyyy-MM-dd')}
                    </Text>{' '}
                    för följande häst
                    {selectedHorseIds.length > 1 ? 'ar' : ''}:
                  </Text>
                  <List>
                    {selectedHorseNames.map(horseName => (
                      <LI key={horseName}>{horseName}</LI>
                    ))}
                  </List>
                  {warnings && (
                    <>
                      <Text as="div" fontWeight="bold" mt={4}>
                        Följande häst{warnings.length > 1 ? 'ar' : ''} har
                        {warnings.length > 1 ? '' : ' en'} varning
                        {warnings.length > 1 ? 'ar' : ''}:
                      </Text>
                      <List>
                        {warnings.map(warning => (
                          <LI key={warning.horseId}>
                            <Box mt={4}>
                              {warning.horseDisplayName}:{' '}
                              {warning.vaccineWarningComment}
                            </Box>
                          </LI>
                        ))}
                      </List>
                    </>
                  )}
                </Box>
              </Confirm>
            )}
            <Form>
              <DirtyFormWarning />
              <Step
                header="1. När utfördes vaccinationen?"
                slug="vaccinationer-ny-vaccination-punkt-1"
              >
                <FormItem maxWidth="200px" pl={0}>
                  <DatePicker
                    disabled={formLoading}
                    maxDate={getToday()}
                    name="date"
                    showMonthYearDropdown={true}
                  />
                </FormItem>
              </Step>
              <Step header="2. Vilket vaccin användes?" mt={5}>
                <FormItem width={1} maxWidth="200px" pl={0}>
                  <Select
                    disabled={formLoading}
                    name="vaccine"
                    label=""
                    options={options}
                  />
                </FormItem>
              </Step>
              <Step
                header="3. Vilka hästar fick vaccinet?"
                slug="vaccinationer-ny-vaccination-punkt-3"
                mt={5}
              >
                <FormItem width={1} pl={0} mb={0} p={0} pb={0}>
                  <Horses
                    disabled={formLoading}
                    licenseId={licenseId}
                    date={values.date}
                    selectedHorses={values.selectedHorses}
                    setFieldValue={setFieldValue}
                    setSelectedHorse={setSelectedHorse}
                    refreshHorses={refreshHorses}
                  />
                  {touched.selectedHorses && errors.selectedHorses && (
                    <Message variant="errorBox" mt={4}>
                      <>{errors.selectedHorses}</>
                    </Message>
                  )}
                </FormItem>
              </Step>
              <FormItem
                maxWidth="270px"
                pl={0}
                mt={6}
                ml={[4, null, 0]}
                noLabel
              >
                <Checkbox
                  disabled={formLoading}
                  name="approveAgreement"
                  label={
                    <>
                      Jag godkänner{' '}
                      <a
                        href={'/minasidor/vaccinationer/villkor'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        vaccinationsvillkoren
                      </a>
                    </>
                  }
                ></Checkbox>
              </FormItem>
              <FormItem pl={0} mt={4} ml={[4, null, 0]} noLabel>
                <Flex
                  flexDirection={['column', null, 'row']}
                  alignItems={['start', null, 'center']}
                  justifyContent="left"
                  width="100%"
                >
                  <Button
                    type="submit"
                    disabled={formLoading}
                    loading={formLoading}
                    onClick={() => setSubmitted(true)}
                    mr={[0, null, 2]}
                    mb={[2, null, 0]}
                  >
                    Registrera vaccinationer
                  </Button>
                </Flex>
              </FormItem>
              {errors.all && <Message variant="errorBox">{errors.all}</Message>}
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
}

import useNumericParams from '@hooks/useNumericParams';
import useQuery from '@hooks/useQuery';

interface ReturnValues {
  year: number;
  month: number;
  track: string;
  showAll: boolean;
  onlyRacedays: boolean;
}
export default function useParamValues(): ReturnValues {
  const date = new Date();
  const { month = date.getMonth() + 1, year = date.getFullYear() } =
    useNumericParams();

  const {
    track = 'alla',
    showAll = false,
    onlyRacedays = false,
  } = useQuery<{
    track: string;
    showAll: boolean;
    onlyRacedays: boolean;
  }>(true);

  return { year, month, track, showAll, onlyRacedays };
}

import { Text } from '@primitives/Typography';
import React, { ReactNode } from 'react';

interface Props {
  header: string;
  value: ReactNode;
}

const HorseDetail = ({ header, value }: Props): JSX.Element => (
  <Text
    fontSize="small"
    lineHeight={[1.2, null, 1.5]}
    padding={['5px 0 2px 0', 0, 0]}
    pl={[0, null, 4]}
    sx={{
      whiteSpace: 'normal',
    }}
  >
    <strong>{header}:</strong> {value ? value : null}
  </Text>
);

export default HorseDetail;

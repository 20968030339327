import Box, { Item } from '@primitives/Box';
import React, { ReactNode } from 'react';

export interface SidebarLayoutSimpleGridProps {
  children: ReactNode;
  leftSidebar?: ReactNode;
}

export function SidebarLayoutSimpleGrid({
  children,
  leftSidebar,
}: SidebarLayoutSimpleGridProps): JSX.Element {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridTemplateRows: '1fr',
      }}
      py={[0, , 2]}
      maxWidth="xlargeMaxWidth"
      mx="auto"
    >
      {leftSidebar && (
        <Item
          p={[, , 7.5]}
          sx={{ gridArea: '1 / 1 / 2 / 2' }}
          width={1}
          height="fit-content"
          minWidth="260px"
          className="hidden-print"
        >
          {leftSidebar}
        </Item>
      )}
      <Item
        width={[1, null, 1]}
        py={7.5}
        pl={[0, , 7.5]}
        pr={0}
        sx={{ gridArea: ['1 / 1 / 2 / 5', , '1 / 2 / 2 / 5'] }}
      >
        <Box maxWidth="100%" mt={-7.5}>
          {children}
        </Box>
      </Item>
    </Box>
  );
}

import { useGetRepresentativeHorses } from '@apis';
import Content from '@components/ContentBlock/Content';
import FadedContent from '@components/ContentBlock/Faded';
import Step from '@components/Step';
import { AuthenticatedUser } from '@generated/account/src';
import { Person } from '@generated/ownership-transfers/src';
import Box, { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import { Checkbox, Input } from '@primitives/Form';
import { BackLink, Link } from '@primitives/Link';
import Pulse from '@primitives/Loading';
import Message from '@primitives/Message';
import { H2, Label, Small } from '@primitives/Typography';
import { formatTz } from '@utils/dateUtils';
import { endOfToday, startOfDay } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { Prompt } from 'react-router-dom';

import BankIdSubmit from '../../OwnershipTransfers/BankIdSubmit';
import SelectPerson from '../../OwnershipTransfers/SelectPerson';
import { SimpleTransferHorseInfo } from '../../OwnershipTransfers/TransferHorseInfo';

interface Props {
  user: AuthenticatedUser;
}

const NewTransferForm = ({ user }: Props): JSX.Element => {
  const { horseId } = useParams<{ horseId: string }>();
  const { data, loading, error } = useGetRepresentativeHorses(user.licenseId);

  const [date, setDate] = useState('');
  const [approved, setApproved] = useState(false);
  const [message, setMessage] = useState<string>();
  const [person, setPerson] = useState<Person>();
  const [bankIdSubmit, setBankIdSubmit] = useState(false);

  const onSubmit = useCallback(() => {
    setBankIdSubmit(true);
  }, []);

  const onPersonChange = useCallback(p => {
    setPerson(p);
  }, []);

  if (loading) {
    return <Pulse />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  const horse = data.find(item => item.horseId === parseInt(horseId));
  if (!horse) {
    return <Error>Leasingavtal för den aktuella hästen hittades inte.</Error>;
  }
  const selectedDate = date ? startOfDay(new Date(date)) : null;
  const earliestTransfer = startOfDay(horse.earliestTransferDate);

  const dateIsValid =
    selectedDate &&
    selectedDate >= earliestTransfer &&
    selectedDate < endOfToday();

  const formIsValid = approved && horse && person && dateIsValid;

  return (
    <>
      <Prompt
        when={
          !bankIdSubmit && (approved || person != null || selectedDate != null)
        }
        message="Du har osparade ändringar, säkert att du vill lämna sidan?"
      />
      {bankIdSubmit && (
        <BankIdSubmit
          date={date}
          horse={horse}
          person={person}
          onClose={() => {
            setBankIdSubmit(false);
          }}
          leasing
        />
      )}
      <Box verticalSpacing={5}>
        {message && <Message variant="successBox">{message}</Message>}
        <Box>
          <BackLink to="/minasidor/leasing/anmal">Byt häst</BackLink>
        </Box>

        <FadedContent slug="leasing-anmal-leasing-ange-uppgifter" />

        <H2>Ange uppgifter för leasingavtal</H2>

        <SimpleTransferHorseInfo horse={horse} />

        <Step header="1. Vem är företrädare för hyrestagaren?">
          <Small color="dim-gray">
            <Content slug="leasing-anmal-leasing-hyrestagare" />
          </Small>
          <SelectPerson
            onChange={onPersonChange}
            value={person}
            setMessage={setMessage}
            header="Företrädare för hyrestagaren"
            isLeasing
          />
        </Step>
        <Step header="2. När startade leasingen?">
          <Small color="dim-gray">
            <Content slug="leasing-anmal-leasing-datum" />
          </Small>
          <Flex justifyContent="flex-start" mt={4}>
            <Label fontSize="small" mr={4}>
              Ange datum
            </Label>
            <Input
              type="date"
              name="date"
              onChange={e => {
                const { value } = e.target;
                setDate(value);
              }}
              value={date}
              pattern="\d{4}-\d{2}-\d{2}"
              width="auto"
              min={formatTz(horse.earliestTransferDate, 'yyyy-MM-dd')}
              max={formatTz(new Date(), 'yyyy-MM-dd')}
            />
          </Flex>

          {selectedDate && selectedDate < earliestTransfer && (
            <Message variant="errorBox" mt={3}>
              Vänligen ange ett senare leasingdatum.
            </Message>
          )}

          {selectedDate && selectedDate > endOfToday() && (
            <Message variant="errorBox" mt={3}>
              Det är inte tillåtet att ange ett leasingdatum i framtiden.
            </Message>
          )}
        </Step>
        <Box>
          <Checkbox
            checked={approved}
            onChange={e => {
              const { checked } = e.target;
              setApproved(checked);
            }}
            label="Jag har informerat hyrestagaren om hästens tävlingsengagemang (inkl.
              insatslopp) i Sverige och utomlands"
          />
        </Box>

        <Box
          horizontalSpacing={[0, 0, 2]}
          justifyContent="flex-start"
          verticalSpacing={[3, , 0]}
          flexDirection={['column', , 'row']}
        >
          <Button
            width={['100%', , 'auto']}
            as={Link}
            colorScheme="secondaryAlt"
            to="/minasidor/leasing/anmal"
          >
            Avbryt
          </Button>
          <Button
            width={['100%', , 'auto']}
            onClick={onSubmit}
            disabled={!formIsValid}
            colorScheme="save"
          >
            Skicka till hyrestagaren
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default NewTransferForm;

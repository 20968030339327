// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Mare
 */
export interface Mare  {
    /**
     * 
     * @type {number}
     * @memberof Mare
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Mare
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Mare
     */
    registrationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Mare
     */
    ownerName: string;
    /**
     * Information about the import of the mare, if any
     * @type {string}
     * @memberof Mare
     */
    importText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Mare
     */
    linkable: boolean;
    /**
     * Two letter code of the registration country (ISO 3166-1 alpha-2)
     * @type {string}
     * @memberof Mare
     */
    registrationCountry?: string;
}

export function MareFromJSON(json: any): Mare {
    return {
        'id': json['id'],
        'name': json['name'],
        'registrationNumber': json['registrationNumber'],
        'ownerName': json['ownerName'],
        'importText': !exists(json, 'importText') ? undefined : json['importText'],
        'linkable': json['linkable'],
        'registrationCountry': !exists(json, 'registrationCountry') ? undefined : json['registrationCountry'],
    };
}

export function MareToJSON(value?: Mare): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'registrationNumber': value.registrationNumber,
        'ownerName': value.ownerName,
        'importText': value.importText,
        'linkable': value.linkable,
        'registrationCountry': value.registrationCountry,
    };
}



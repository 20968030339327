import styled from 'styled-components';
import { color } from 'styled-system';

import Link from './Link';

export const Trail = styled(Link)`
  ${color}
  display: inline-flex;
  align-items: center;
  height: ${props => props.theme.sizes.subNav}px;
  margin-right: 24px;
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes['pageHeader']}px;
  text-decoration: none;
`;

const SubNavLink = styled(Link)`
  ${color}
  text-decoration: none;
  height: ${props => props.theme.sizes.subNav}px;
  font-size: ${props => props.theme.fontSizes['pageHeader']}px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 2px;
  padding-right: 2px;

  :after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    content: '';
    border-bottom: solid 3px ${props => props.theme.colors['deep-blue']};
    transform: scaleX(0);
  }

  &.active {
    &:after {
      transform: scaleX(1);
      transition: transform 0.15s ease;
    }
  }
`;

Trail.defaultProps = {
  color: 'gray5',
};

SubNavLink.defaultProps = {
  color: 'gray5',
};

export default SubNavLink;

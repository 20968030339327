// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface BasicRegistrationStatus
 */
export interface BasicRegistrationStatus  {
    /**
     * 
     * @type {boolean}
     * @memberof BasicRegistrationStatus
     */
    dead: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasicRegistrationStatus
     */
    yearOfDeath?: string;
    /**
     * 
     * @type {Date}
     * @memberof BasicRegistrationStatus
     */
    foodProductionUnlistedDate?: Date;
}

export function BasicRegistrationStatusFromJSON(json: any): BasicRegistrationStatus {
    return {
        'dead': json['dead'],
        'yearOfDeath': !exists(json, 'yearOfDeath') ? undefined : json['yearOfDeath'],
        'foodProductionUnlistedDate': !exists(json, 'foodProductionUnlistedDate') ? undefined : parseDateTime(json['foodProductionUnlistedDate']),
    };
}

export function BasicRegistrationStatusToJSON(value?: BasicRegistrationStatus): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'dead': value.dead,
        'yearOfDeath': value.yearOfDeath,
        'foodProductionUnlistedDate': value.foodProductionUnlistedDate === undefined ? undefined : value.foodProductionUnlistedDate.toLocalDate(),
    };
}



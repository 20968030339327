import { Enrollment, getEnrollments } from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Results = Enrollment[] | undefined;

export interface Entities {
  enrollmentsForTrainer?: {
    [key: string]: Results;
  };
}

export const getQueryKey = (licenseId: number): string =>
  `enrollmentsForTrainer-${licenseId}`;

const useGetEnrollmentsForTrainer = (
  licenseId: number,
): ApiResponse<Results, Entities> => {
  const key = getQueryKey(licenseId);
  const query = getEnrollments<Entities>(
    {
      licenseId,
    },
    {
      force: true,
      queryKey: key,
      transform: responseBody => ({
        enrollmentsForTrainer: {
          [key]: responseBody,
        },
      }),
      update: {
        enrollmentsForTrainer: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Results =>
    state.entities?.enrollmentsForTrainer?.[key];

  return useApi<Results>({
    query,
    selector,
  });
};

export default useGetEnrollmentsForTrainer;

// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Track,
    TrackFromJSON,
    TrackToJSON,
} from './';

/**
 * 
 * @export
 * @interface RaceDay
 */
export interface RaceDay  {
    /**
     * 
     * @type {Date}
     * @memberof RaceDay
     */
    showOnDate: Date;
    /**
     * 
     * @type {number}
     * @memberof RaceDay
     */
    prio?: number;
    /**
     * 
     * @type {string}
     * @memberof RaceDay
     */
    typ?: RaceDayTypEnum;
    /**
     * 
     * @type {number}
     * @memberof RaceDay
     */
    raceDayId?: number;
    /**
     * 
     * @type {Track}
     * @memberof RaceDay
     */
    track?: Track;
    /**
     * 
     * @type {Date}
     * @memberof RaceDay
     */
    raceDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof RaceDay
     */
    resultFinished?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RaceDay
     */
    startListExists?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof RaceDay
     */
    timeForFirstStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RaceDay
     */
    entryDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RaceDay
     */
    weightDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RaceDay
     */
    startEntryDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof RaceDay
     */
    depositRaceId?: number;
    /**
     * 
     * @type {string}
     * @memberof RaceDay
     */
    depositRaceName?: string;
    /**
     * 
     * @type {string}
     * @memberof RaceDay
     */
    depositRaceYear?: string;
    /**
     * 
     * @type {string}
     * @memberof RaceDay
     */
    depositRaceDepositNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RaceDay
     */
    propositionDone?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RaceDay
     */
    entryDone?: boolean;
    /**
     * When true, indicates that a race card can be downloaded for this race day
     * @type {boolean}
     * @memberof RaceDay
     */
    raceCardExists?: boolean;
}

export function RaceDayFromJSON(json: any): RaceDay {
    return {
        'showOnDate': parseDateTime(json['showOnDate']),
        'prio': !exists(json, 'prio') ? undefined : json['prio'],
        'typ': !exists(json, 'typ') ? undefined : json['typ'],
        'raceDayId': !exists(json, 'raceDayId') ? undefined : json['raceDayId'],
        'track': !exists(json, 'track') ? undefined : TrackFromJSON(json['track']),
        'raceDate': !exists(json, 'raceDate') ? undefined : parseDateTime(json['raceDate']),
        'resultFinished': !exists(json, 'resultFinished') ? undefined : json['resultFinished'],
        'startListExists': !exists(json, 'startListExists') ? undefined : json['startListExists'],
        'timeForFirstStart': !exists(json, 'timeForFirstStart') ? undefined : parseDateTime(json['timeForFirstStart']),
        'entryDate': !exists(json, 'entryDate') ? undefined : parseDateTime(json['entryDate']),
        'weightDate': !exists(json, 'weightDate') ? undefined : parseDateTime(json['weightDate']),
        'startEntryDate': !exists(json, 'startEntryDate') ? undefined : parseDateTime(json['startEntryDate']),
        'depositRaceId': !exists(json, 'depositRaceId') ? undefined : json['depositRaceId'],
        'depositRaceName': !exists(json, 'depositRaceName') ? undefined : json['depositRaceName'],
        'depositRaceYear': !exists(json, 'depositRaceYear') ? undefined : json['depositRaceYear'],
        'depositRaceDepositNumber': !exists(json, 'depositRaceDepositNumber') ? undefined : json['depositRaceDepositNumber'],
        'propositionDone': !exists(json, 'propositionDone') ? undefined : json['propositionDone'],
        'entryDone': !exists(json, 'entryDone') ? undefined : json['entryDone'],
        'raceCardExists': !exists(json, 'raceCardExists') ? undefined : json['raceCardExists'],
    };
}

export function RaceDayToJSON(value?: RaceDay): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'showOnDate': value.showOnDate.toLocalDate(),
        'prio': value.prio,
        'typ': value.typ,
        'raceDayId': value.raceDayId,
        'track': TrackToJSON(value.track),
        'raceDate': value.raceDate === undefined ? undefined : value.raceDate.toLocalDate(),
        'resultFinished': value.resultFinished,
        'startListExists': value.startListExists,
        'timeForFirstStart': value.timeForFirstStart === undefined ? undefined : value.timeForFirstStart.toISOString(),
        'entryDate': value.entryDate === undefined ? undefined : value.entryDate.toISOString(),
        'weightDate': value.weightDate === undefined ? undefined : value.weightDate.toISOString(),
        'startEntryDate': value.startEntryDate === undefined ? undefined : value.startEntryDate.toISOString(),
        'depositRaceId': value.depositRaceId,
        'depositRaceName': value.depositRaceName,
        'depositRaceYear': value.depositRaceYear,
        'depositRaceDepositNumber': value.depositRaceDepositNumber,
        'propositionDone': value.propositionDone,
        'entryDone': value.entryDone,
        'raceCardExists': value.raceCardExists,
    };
}

/**
* @export
* @enum {string}
*/
export enum RaceDayTypEnum {
    ENTRY = 'ENTRY',
    STARTENTRY = 'STARTENTRY',
    RACE = 'RACE',
    DEPOSIT = 'DEPOSIT'
}



// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Representative
 */
export interface Representative  {
    /**
     * 
     * @type {number}
     * @memberof Representative
     */
    id: number;
    /**
     * Legal (J) or physical (F) person
     * @type {string}
     * @memberof Representative
     */
    personType: RepresentativePersonTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    name: string;
}

export function RepresentativeFromJSON(json: any): Representative {
    return {
        'id': json['id'],
        'personType': json['personType'],
        'name': json['name'],
    };
}

export function RepresentativeToJSON(value?: Representative): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'personType': value.personType,
        'name': value.name,
    };
}

/**
* @export
* @enum {string}
*/
export enum RepresentativePersonTypeEnum {
    PHYSICAL = 'PHYSICAL',
    LEGAL = 'LEGAL'
}



// tslint:disable
/**
 * Web API Charts Service
 * Service for getting info about the different charts.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ChartEntry,
    ChartEntryFromJSON,
    ChartEntryToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface GetChartRequest {
    categoryId: number;
    typeId: number;
    breed: string;
    firstPrice: string;
    list: string;
    returnNumberOfEntries: string;
    trackSurface: string;
    year: string;
    age?: string;
    ageFrom?: string;
    ageTo?: string;
    breederStatus?: string;
    gender?: string;
    homeTrack?: string;
    licenseCountry?: string;
    licenseType?: string;
    raceOnTrack?: string;
}


/**
 * Get the top chart given category and type.
 */
function getChartRaw<T>(requestParameters: GetChartRequest, requestConfig: runtime.TypedQueryConfig<T, Array<ChartEntry>> = {}): QueryConfig<T> {
    if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
        throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getChart.');
    }

    if (requestParameters.typeId === null || requestParameters.typeId === undefined) {
        throw new runtime.RequiredError('typeId','Required parameter requestParameters.typeId was null or undefined when calling getChart.');
    }

    if (requestParameters.breed === null || requestParameters.breed === undefined) {
        throw new runtime.RequiredError('breed','Required parameter requestParameters.breed was null or undefined when calling getChart.');
    }

    if (requestParameters.firstPrice === null || requestParameters.firstPrice === undefined) {
        throw new runtime.RequiredError('firstPrice','Required parameter requestParameters.firstPrice was null or undefined when calling getChart.');
    }

    if (requestParameters.list === null || requestParameters.list === undefined) {
        throw new runtime.RequiredError('list','Required parameter requestParameters.list was null or undefined when calling getChart.');
    }

    if (requestParameters.returnNumberOfEntries === null || requestParameters.returnNumberOfEntries === undefined) {
        throw new runtime.RequiredError('returnNumberOfEntries','Required parameter requestParameters.returnNumberOfEntries was null or undefined when calling getChart.');
    }

    if (requestParameters.trackSurface === null || requestParameters.trackSurface === undefined) {
        throw new runtime.RequiredError('trackSurface','Required parameter requestParameters.trackSurface was null or undefined when calling getChart.');
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
        throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getChart.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.age !== undefined) {
        queryParameters['age'] = requestParameters.age;
    }


    if (requestParameters.ageFrom !== undefined) {
        queryParameters['ageFrom'] = requestParameters.ageFrom;
    }


    if (requestParameters.ageTo !== undefined) {
        queryParameters['ageTo'] = requestParameters.ageTo;
    }


    if (requestParameters.breed !== undefined) {
        queryParameters['breed'] = requestParameters.breed;
    }


    if (requestParameters.breederStatus !== undefined) {
        queryParameters['breederStatus'] = requestParameters.breederStatus;
    }


    if (requestParameters.firstPrice !== undefined) {
        queryParameters['firstPrice'] = requestParameters.firstPrice;
    }


    if (requestParameters.gender !== undefined) {
        queryParameters['gender'] = requestParameters.gender;
    }


    if (requestParameters.homeTrack !== undefined) {
        queryParameters['homeTrack'] = requestParameters.homeTrack;
    }


    if (requestParameters.licenseCountry !== undefined) {
        queryParameters['licenseCountry'] = requestParameters.licenseCountry;
    }


    if (requestParameters.licenseType !== undefined) {
        queryParameters['licenseType'] = requestParameters.licenseType;
    }


    if (requestParameters.list !== undefined) {
        queryParameters['list'] = requestParameters.list;
    }


    if (requestParameters.raceOnTrack !== undefined) {
        queryParameters['raceOnTrack'] = requestParameters.raceOnTrack;
    }


    if (requestParameters.returnNumberOfEntries !== undefined) {
        queryParameters['returnNumberOfEntries'] = requestParameters.returnNumberOfEntries;
    }


    if (requestParameters.trackSurface !== undefined) {
        queryParameters['trackSurface'] = requestParameters.trackSurface;
    }


    if (requestParameters.year !== undefined) {
        queryParameters['year'] = requestParameters.year;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/charts/categories/{categoryId}/types/{typeId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))).replace(`{${"typeId"}}`, encodeURIComponent(String(requestParameters.typeId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(ChartEntryFromJSON), text);
    }

    return config;
}

/**
* Get the top chart given category and type.
*/
export function getChart<T>(requestParameters: GetChartRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<ChartEntry>>): QueryConfig<T> {
    return getChartRaw(requestParameters, requestConfig);
}


import { useGetValidDrivers } from '@apis';
import Autocomplete from '@components/Autocomplete';
import { Driver } from '@generated/propositions/src';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import Icon from '@primitives/Icon';
import { User } from '@styled-icons/fa-solid';
import formatDriver from '@utils/formatDriver';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { SuggestionsFetchRequestedParams } from 'react-autosuggest';
import styled from 'styled-components';

import { FormState } from '..';

export const AutocompleteDrriverName = styled.div`
  display: inline-block;
`;

export const AutocompleteDriverInfo = styled.div`
  font-size: 14px;
  display: inline-block;
`;

interface Props {
  propositionId: number;
}

const ValidDrivers = ({ propositionId }: Props): JSX.Element => {
  const [value, setValue] = useState('');

  const [suggestions, setSuggestions] = useState([]);

  const { values, setFieldValue } = useFormikContext<FormState>();

  const { data, loading, status } = useGetValidDrivers(propositionId);

  const getSuggestions = (value): Driver[] => {
    const inputValue = value.trim().toLowerCase();

    return inputValue.length < 2
      ? []
      : data
          .filter(lang => {
            const firstName = lang.name.split(' ').slice(0, -1).join(' ');
            const lastName = lang.name.split(' ').slice(-1).join(' ');

            return (
              firstName.toLowerCase().startsWith(inputValue) ||
              lastName.toLowerCase().startsWith(inputValue) ||
              lang.name.toLowerCase().startsWith(inputValue)
            );
          })
          .filter(
            item => !values.drivers.some(driver => driver.id === item.id),
          );
  };

  const onChange = (event, { newValue }): void => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({
    value,
  }: SuggestionsFetchRequestedParams): void => {
    setSuggestions(getSuggestions(value));
  };

  const inputProps = {
    placeholder: 'Skriv minst 2 tecken',
    value,
    onChange,
  };

  if (status && status !== 200) {
    return (
      <Error>
        {status} {status}
      </Error>
    );
  }

  if (loading || !data) {
    return (
      <Box width="100%">
        <Autocomplete
          label="Sök ryttare"
          maxWidth="100%"
          minWidth="100%"
          loading={true}
          inputProps={inputProps}
        />
      </Box>
    );
  }

  return (
    <Box width="100%">
      {data.length > 0 && (
        <Autocomplete<Driver>
          label="Sök ryttare"
          maxWidth="100%"
          minWidth="100%"
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested} // Called when need to update suggestions
          onSuggestionsClearRequested={() => setSuggestions([])} // Called when clear suggestions
          getSuggestionValue={suggestion => formatDriver(suggestion)}
          onSuggestionSelected={(e, { suggestion }) => {
            if (!suggestion.suspended) {
              setFieldValue(
                'drivers',
                [
                  ...values.drivers,
                  {
                    ...suggestion,
                    mountingPromise: false,
                    sortOrder: values.drivers.length + 1,
                  },
                ],
                true,
              );
            } else {
              //TODO kanske nån respons att peresonen är avstängd?
            }
          }}
          // Populate dropdown with result from the input search
          renderSuggestion={suggestion => (
            <>
              <AutocompleteDrriverName className="AutocompleteDefaultStyle">
                <Icon size={12} color="deep-blue" as={User} mr={2} />
                {`${formatDriver(suggestion)
                  .split(' ')
                  .slice(0, 2)
                  .join(' ')} `}

                <AutocompleteDriverInfo className={'AutocompleteDefaultStyle'}>
                  {formatDriver(suggestion).split(' ').slice(2).length > 0 &&
                    `| ` +
                      `${formatDriver(suggestion)
                        .split(' ')
                        .slice(2)
                        .join(' ')}`}
                </AutocompleteDriverInfo>
              </AutocompleteDrriverName>
            </>
          )}
          inputProps={inputProps}
        />
      )}
    </Box>
  );
};

export default ValidDrivers;

// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    BasicUserInformation,
    BasicUserInformationFromJSON,
    BasicUserInformationToJSON,
    ChangeableUserInformation,
    ChangeableUserInformationFromJSON,
    ChangeableUserInformationToJSON,
    TDSInformation,
    TDSInformationFromJSON,
    TDSInformationToJSON,
} from './';

/**
 * 
 * @export
 * @interface PersonalInformation
 */
export interface PersonalInformation  {
    /**
     * 
     * @type {BasicUserInformation}
     * @memberof PersonalInformation
     */
    basicUserInformation: BasicUserInformation;
    /**
     * 
     * @type {TDSInformation}
     * @memberof PersonalInformation
     */
    tdsInformation?: TDSInformation;
    /**
     * 
     * @type {ChangeableUserInformation}
     * @memberof PersonalInformation
     */
    changeableUserInformation: ChangeableUserInformation;
}

export function PersonalInformationFromJSON(json: any): PersonalInformation {
    return {
        'basicUserInformation': BasicUserInformationFromJSON(json['basicUserInformation']),
        'tdsInformation': !exists(json, 'tdsInformation') ? undefined : TDSInformationFromJSON(json['tdsInformation']),
        'changeableUserInformation': ChangeableUserInformationFromJSON(json['changeableUserInformation']),
    };
}

export function PersonalInformationToJSON(value?: PersonalInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'basicUserInformation': BasicUserInformationToJSON(value.basicUserInformation),
        'tdsInformation': TDSInformationToJSON(value.tdsInformation),
        'changeableUserInformation': ChangeableUserInformationToJSON(value.changeableUserInformation),
    };
}



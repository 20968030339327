// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * The start that the products may be applied to
 * @export
 * @interface Start
 */
export interface Start  {
    /**
     * The license id of the user
     * @type {number}
     * @memberof Start
     */
    licenseId: number;
    /**
     * Trainer start id
     * @type {number}
     * @memberof Start
     */
    id: number;
    /**
     * If the horse is A trained or not
     * @type {boolean}
     * @memberof Start
     */
    atrainedHorse: boolean;
    /**
     * Horse id
     * @type {number}
     * @memberof Start
     */
    horseId: number;
    /**
     * Horse name
     * @type {string}
     * @memberof Start
     */
    horseName: string;
    /**
     * Owner id
     * @type {number}
     * @memberof Start
     */
    ownershipId?: number;
    /**
     * Representative id
     * @type {number}
     * @memberof Start
     */
    representativeId: number;
    /**
     * Representative name
     * @type {string}
     * @memberof Start
     */
    representativeName: string;
    /**
     * Trainer id (used for registering driver start costs, A-trained horses)
     * @type {number}
     * @memberof Start
     */
    trainerId?: number;
    /**
     * Trainer name (used for registering driver start costs, A-trained horses)
     * @type {string}
     * @memberof Start
     */
    trainerName?: string;
    /**
     * Trainer company name (used for registering driver start costs, A-trained horses)
     * @type {string}
     * @memberof Start
     */
    trainerCompanyName?: string;
    /**
     * Race day id
     * @type {number}
     * @memberof Start
     */
    raceDayId: number;
    /**
     * Race date
     * @type {Date}
     * @memberof Start
     */
    raceDate: Date;
    /**
     * Race id
     * @type {number}
     * @memberof Start
     */
    raceId: number;
    /**
     * Race number
     * @type {number}
     * @memberof Start
     */
    raceNumber: number;
    /**
     * Track id
     * @type {string}
     * @memberof Start
     */
    trackId: string;
    /**
     * Track code
     * @type {string}
     * @memberof Start
     */
    trackCode: string;
    /**
     * Track name
     * @type {string}
     * @memberof Start
     */
    trackName: string;
    /**
     * Track surface (also includes the concept of gallop obstacle tracks)
     * @type {string}
     * @memberof Start
     */
    trackSurface?: string;
    /**
     * Placement in the race
     * @type {string}
     * @memberof Start
     */
    placement: string;
    /**
     * Prize money
     * @type {number}
     * @memberof Start
     */
    prizeMoney: number;
    /**
     * Withdrawn horse (struken)
     * @type {boolean}
     * @memberof Start
     */
    withdrawn: boolean;
    /**
     * True then rider commission applicable to pay amount but withdrawn also should be true
     * @type {boolean}
     * @memberof Start
     */
    riderFeeApplicable: boolean;
}

export function StartFromJSON(json: any): Start {
    return {
        'licenseId': json['licenseId'],
        'id': json['id'],
        'atrainedHorse': json['atrainedHorse'],
        'horseId': json['horseId'],
        'horseName': json['horseName'],
        'ownershipId': !exists(json, 'ownershipId') ? undefined : json['ownershipId'],
        'representativeId': json['representativeId'],
        'representativeName': json['representativeName'],
        'trainerId': !exists(json, 'trainerId') ? undefined : json['trainerId'],
        'trainerName': !exists(json, 'trainerName') ? undefined : json['trainerName'],
        'trainerCompanyName': !exists(json, 'trainerCompanyName') ? undefined : json['trainerCompanyName'],
        'raceDayId': json['raceDayId'],
        'raceDate': parseDateTime(json['raceDate']),
        'raceId': json['raceId'],
        'raceNumber': json['raceNumber'],
        'trackId': json['trackId'],
        'trackCode': json['trackCode'],
        'trackName': json['trackName'],
        'trackSurface': !exists(json, 'trackSurface') ? undefined : json['trackSurface'],
        'placement': json['placement'],
        'prizeMoney': json['prizeMoney'],
        'withdrawn': json['withdrawn'],
        'riderFeeApplicable': json['riderFeeApplicable'],
    };
}

export function StartToJSON(value?: Start): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseId': value.licenseId,
        'id': value.id,
        'atrainedHorse': value.atrainedHorse,
        'horseId': value.horseId,
        'horseName': value.horseName,
        'ownershipId': value.ownershipId,
        'representativeId': value.representativeId,
        'representativeName': value.representativeName,
        'trainerId': value.trainerId,
        'trainerName': value.trainerName,
        'trainerCompanyName': value.trainerCompanyName,
        'raceDayId': value.raceDayId,
        'raceDate': value.raceDate.toLocalDate(),
        'raceId': value.raceId,
        'raceNumber': value.raceNumber,
        'trackId': value.trackId,
        'trackCode': value.trackCode,
        'trackName': value.trackName,
        'trackSurface': value.trackSurface,
        'placement': value.placement,
        'prizeMoney': value.prizeMoney,
        'withdrawn': value.withdrawn,
        'riderFeeApplicable': value.riderFeeApplicable,
    };
}



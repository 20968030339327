import config from '@config';
import { getRaceDay, RaceDayResource } from '@generated/content/src';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  raceDayMetaData?: {
    [raceDayId: number]: RaceDayResource;
  };
}

const useGetRaceDayMetaData = (
  raceDayId: number,
): ApiResponse<RaceDayResource, Entities> => {
  const base = getRaceDay<Entities>(
    {
      id: raceDayId,
    },
    {
      transform: responseBody => ({
        raceDayMetaData: {
          [raceDayId]: responseBody,
        },
      }),
      update: {
        raceDayMetaData: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  return useApi<RaceDayResource>({
    query: {
      ...base,
      queryKey: `getRaceDayMetaData:${raceDayId}`,
      url: config.isProduction
        ? base.url
        : base.url.replace('/api/', '/api-test/'),
    },
    selector: state => state.entities?.raceDayMetaData?.[raceDayId],
    basePath: 'contentapi',
  });
};

export default useGetRaceDayMetaData;

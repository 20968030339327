import DataGrid from '@components/DataGrid';
import { TD } from '@components/ReactTable';
import { Transaction } from '@generated/tds/src';
import { formatTz } from '@utils/dateUtils';
import { formatNumber } from '@utils/formatting';
import React, { useMemo } from 'react';
import { CellProps, Column } from 'react-table';

import FilterLabelEnum from '../utils/enum/FilterLabel';
import FilterValueEnum from '../utils/enum/FilterValue';
import GroupedTotalRows from '../utils/interface/GroupedTotalRows';
import TotalRow from '../utils/interface/TotalRow';

const columns = (header: FilterLabelEnum): Column<TotalRow>[] => [
  {
    accessor: 'header',
    Header: header,
    Cell: ({ value }: CellProps<TotalRow, TotalRow['header']>) => (
      <>{value instanceof Date ? formatTz(value, 'yyyy-MM-dd') : value}</>
    ),
  },
  {
    accessor: 'amount',
    Header: 'Belopp',
    Cell: ({ value }) => <>{formatNumber(value)}</>,
  },
  {
    accessor: 'vatAmount',
    Header: 'Moms',
    Cell: ({ value }) => <>{formatNumber(value)}</>,
  },
];

interface Props {
  groupBy: FilterValueEnum;
  transactions: Transaction[];
}

export default function Total({ groupBy, transactions }: Props): JSX.Element {
  const header: FilterLabelEnum = useMemo(
    () =>
      groupBy === FilterValueEnum.HorseId
        ? FilterLabelEnum.Horse
        : groupBy === FilterValueEnum.RepresentativeId
          ? FilterLabelEnum.Representative
          : FilterLabelEnum.Date,
    [groupBy],
  );

  const groupedTotalRows: GroupedTotalRows = useMemo(
    () =>
      transactions.reduce((acc, current) => {
        const currentKey = current[groupBy].toString();
        const textKey: FilterValueEnum =
          groupBy === FilterValueEnum.HorseId
            ? FilterValueEnum.HorseName
            : groupBy === FilterValueEnum.RepresentativeId
              ? FilterValueEnum.RepresentativeName
              : FilterValueEnum.Date;

        return {
          ...acc,
          [currentKey]: acc[currentKey]
            ? {
                ...acc[currentKey],
                amount: acc[currentKey].amount + current.amount,
                vatAmount: acc[currentKey].vatAmount + current.vatAmount,
              }
            : {
                groupBy,
                header: current[textKey],
                amount: current.amount,
                vatAmount: current.vatAmount,
              },
        };
      }, {}),
    [transactions, groupBy],
  );

  const sortedTotalRows = useMemo(
    () =>
      Object.values(groupedTotalRows).sort((a: TotalRow, b: TotalRow) => {
        if (a.header instanceof Date && b.header instanceof Date) {
          return b.header.getTime() - a.header.getTime();
        }

        if (a.header < b.header) {
          return -1;
        }

        if (a.header > b.header) {
          return 1;
        }

        return 0;
      }),
    [groupedTotalRows],
  );

  const sum = useMemo(
    () =>
      sortedTotalRows.reduce(
        (acc, current) => ({
          amount: acc.amount + current.amount,
          vatAmount: acc.vatAmount + current.vatAmount,
        }),
        { amount: 0, vatAmount: 0 },
      ),
    [sortedTotalRows],
  );

  return (
    <DataGrid
      columns={columns(header)}
      data={sortedTotalRows}
      tfoot={
        <tr>
          <TD alignment="right" fontWeight="bold">
            Summa:
          </TD>
          <TD>{formatNumber(sum.amount)}</TD>
          <TD>{formatNumber(sum.vatAmount)}</TD>
        </tr>
      }
    />
  );
}

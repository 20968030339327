// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContentPageResourceDocument,
    ContentPageResourceDocumentFromJSON,
    ContentPageResourceDocumentToJSON,
    ContentPageResourceMeta,
    ContentPageResourceMetaFromJSON,
    ContentPageResourceMetaToJSON,
    ImageResource,
    ImageResourceFromJSON,
    ImageResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContentPageResource
 */
export interface ContentPageResource  {
    /**
     * 
     * @type {number}
     * @memberof ContentPageResource
     */
    id?: number;
    /**
     * 
     * @type {ContentPageResourceMeta}
     * @memberof ContentPageResource
     */
    meta?: ContentPageResourceMeta;
    /**
     * 
     * @type {string}
     * @memberof ContentPageResource
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageResource
     */
    text?: string;
    /**
     * 
     * @type {ImageResource}
     * @memberof ContentPageResource
     */
    image?: ImageResource;
    /**
     * 
     * @type {ContentPageResourceDocument}
     * @memberof ContentPageResource
     */
    document?: ContentPageResourceDocument;
}

export function ContentPageResourceFromJSON(json: any): ContentPageResource {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'meta': !exists(json, 'meta') ? undefined : ContentPageResourceMetaFromJSON(json['meta']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'image': !exists(json, 'image') ? undefined : ImageResourceFromJSON(json['image']),
        'document': !exists(json, 'document') ? undefined : ContentPageResourceDocumentFromJSON(json['document']),
    };
}

export function ContentPageResourceToJSON(value?: ContentPageResource): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'meta': ContentPageResourceMetaToJSON(value.meta),
        'title': value.title,
        'text': value.text,
        'image': ImageResourceToJSON(value.image),
        'document': ContentPageResourceDocumentToJSON(value.document),
    };
}



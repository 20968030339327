// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface ResponseError
 */
export interface ResponseError  {
    /**
     * 
     * @type {string}
     * @memberof ResponseError
     */
    errorIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseError
     */
    errorCode?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseError
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseError
     */
    errorMessage?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseError
     */
    errorRelatedTo?: Array<string>;
}

export function ResponseErrorFromJSON(json: any): ResponseError {
    return {
        'errorIdentifier': !exists(json, 'errorIdentifier') ? undefined : json['errorIdentifier'],
        'errorCode': !exists(json, 'errorCode') ? undefined : json['errorCode'],
        'statusCode': !exists(json, 'statusCode') ? undefined : json['statusCode'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'errorRelatedTo': !exists(json, 'errorRelatedTo') ? undefined : json['errorRelatedTo'],
    };
}

export function ResponseErrorToJSON(value?: ResponseError): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'errorIdentifier': value.errorIdentifier,
        'errorCode': value.errorCode,
        'statusCode': value.statusCode,
        'errorMessage': value.errorMessage,
        'errorRelatedTo': value.errorRelatedTo,
    };
}



// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface HorseImportExport
 */
export interface HorseImportExport  {
    /**
     * 
     * @type {number}
     * @memberof HorseImportExport
     */
    horseId: number;
    /**
     * 
     * @type {string}
     * @memberof HorseImportExport
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof HorseImportExport
     */
    isImport: string;
    /**
     * 
     * @type {Date}
     * @memberof HorseImportExport
     */
    toDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof HorseImportExport
     */
    fromDate: Date;
    /**
     * 
     * @type {string}
     * @memberof HorseImportExport
     */
    swedishName: string;
    /**
     * 
     * @type {string}
     * @memberof HorseImportExport
     */
    causeCode: string;
    /**
     * 
     * @type {string}
     * @memberof HorseImportExport
     */
    cause: string;
    /**
     * 
     * @type {string}
     * @memberof HorseImportExport
     */
    lcode?: string;
}

export function HorseImportExportFromJSON(json: any): HorseImportExport {
    return {
        'horseId': json['horseId'],
        'name': json['name'],
        'isImport': json['isImport'],
        'toDate': parseDateTime(json['toDate']),
        'fromDate': parseDateTime(json['fromDate']),
        'swedishName': json['swedishName'],
        'causeCode': json['causeCode'],
        'cause': json['cause'],
        'lcode': !exists(json, 'lcode') ? undefined : json['lcode'],
    };
}

export function HorseImportExportToJSON(value?: HorseImportExport): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'name': value.name,
        'isImport': value.isImport,
        'toDate': value.toDate.toLocalDate(),
        'fromDate': value.fromDate.toLocalDate(),
        'swedishName': value.swedishName,
        'causeCode': value.causeCode,
        'cause': value.cause,
        'lcode': value.lcode,
    };
}



import {
  getRegistrationStatusOptions,
  RegistrationStatusOptions,
} from '@generated/horses/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { LazyApiResponse } from '../utils/types';
import useApiLazy from '../utils/useApiLazy';

type Result = RegistrationStatusOptions | undefined;

export interface Entities {
  registrationStatusOptions?: {
    [horseId: number]: Result;
  };
}

const getQuery = (horseId: number): QueryConfig<Entities> =>
  getRegistrationStatusOptions<Entities>(
    {
      horseId,
    },
    {
      transform: responseBody => ({
        registrationStatusOptions: {
          [horseId]: responseBody,
        },
      }),
      update: {
        registrationStatusOptions: (prev, next) => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetRegistrationStatusOptions = (
  horseId: number,
): LazyApiResponse<Result, Entities> => {
  const query = getQuery(horseId);
  const selector = (state: State): Result =>
    state.entities?.registrationStatusOptions?.[horseId];

  return useApiLazy<Result>({
    query: {
      ...query,
      queryKey: `registrationStatusOptions:${horseId}`,
    },
    selector,
  });
};

export default useGetRegistrationStatusOptions;

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  entitiesReducer,
  EntitiesState,
  errorsReducer,
  ErrorsState,
  QueriesState,
  queryMiddleware,
  ReduxQueryAction,
} from 'redux-query';

import { Entities } from '../apis';
import superagentInterface from './networkInterface';
import queriesReducer from './state/queriesReducer';
import appReducer, { AppState } from './state/slice';

const RESET = 'RESET_STATE';

interface ResetStateAction {
  type: typeof RESET;
}

// Reset state action
export const resetState = (): ResetStateAction => ({
  type: RESET,
});

export interface State {
  queries: QueriesState;
  entities: EntitiesState & Partial<Entities>;
  errors: ErrorsState;
  app: AppState;
}

export const getQueries = (state: State): QueriesState => state.queries;
export const getEntities = (state: State): EntitiesState => state.entities;

const initialState = {
  forceChangeOfPassword: Boolean(localStorage.getItem('forceChangeOfPassword')),
};

const makeStore = (): ReturnType<typeof configureStore> => {
  const reducer = combineReducers({
    entities: (
      state: State['entities'] = initialState,
      action: ReduxQueryAction,
    ) => entitiesReducer(state, action),
    queries: queriesReducer,
    errors: errorsReducer,
    app: appReducer,
  });

  const rootReducer = (state, action): ReturnType<typeof reducer> => {
    if (action.type === RESET) {
      // reset the entire state, useful at logout
      return reducer(undefined, action);
    }

    return reducer(state, action);
  };

  const middleware = [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: {
        ignoredPaths: ['entities', 'queries', 'errors'],
      },
    }),
    queryMiddleware(superagentInterface, getQueries, getEntities),
  ];

  return configureStore({
    reducer: rootReducer,
    middleware,
    devTools: typeof window !== 'undefined',
  });
};

export const store = makeStore();

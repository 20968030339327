import config from '@config';
import {
  SearchResult,
  searchSportInfo,
  SearchSportInfoRequest,
} from '@generated/content/src';

import { ApiResponse } from './utils/types';
import useApi from './utils/useApi';

type Resp = SearchResult | undefined;

export interface Entities {
  searchSportInfo?: { [key: string]: Resp };
}

const useSearch = (
  args: SearchSportInfoRequest,
): ApiResponse<Resp, Entities> => {
  const key = JSON.stringify(args);

  const base = searchSportInfo<Entities>(args, {
    transform: responseBody => ({
      searchSportInfo: { [key]: responseBody },
    }),
    update: {
      searchSportInfo: (prev, next): typeof next => ({
        ...prev,
        ...next,
      }),
    },
  });

  return useApi({
    query: {
      ...base,
      url: config.isProduction
        ? base.url
        : base.url.replace('/api/', '/api-test/'),
    },
    selector: state => state.entities.searchSportInfo?.[key],
    basePath: 'contentapi',
  });
};

export default useSearch;

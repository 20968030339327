import {
  EnrollmentRaceDayInformation,
  getEnrollmentsForSpecificRaceDay,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  enrollmentRaceDayInformation?: {
    [key: string]: EnrollmentRaceDayInformation;
  };
}

export const enrollmentRaceDayPrefix = 'enrollmentRaceDayInformation';

const useGetEnrollmentsForSpecificRaceDay = (
  licenseId: number,
  raceDayId: number,
): ApiResponse<EnrollmentRaceDayInformation, Entities> => {
  const key = `${licenseId}-${raceDayId}`;
  const query = getEnrollmentsForSpecificRaceDay<Entities>(
    {
      licenseId,
      raceDayId,
    },
    {
      force: true,
      queryKey: `${enrollmentRaceDayPrefix}:${key}`,
      transform: responseBody => ({
        enrollmentRaceDayInformation: {
          [key]: responseBody,
        },
      }),
      update: {
        enrollmentRaceDayInformation: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): EnrollmentRaceDayInformation =>
    state.entities?.enrollmentRaceDayInformation?.[key];

  return useApi<EnrollmentRaceDayInformation>({
    query,
    selector,
  });
};

export default useGetEnrollmentsForSpecificRaceDay;

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Statistics,
    StatisticsFromJSON,
    StatisticsToJSON,
} from '../models';

export interface GetOwnershipStatisticsRequest {
    ownerId: number;
}

export interface GetUserOwnershipStatisticsRequest {
    ownerId: number;
}


/**
 * Get ownership statistics
 */
function getOwnershipStatisticsRaw<T>(requestParameters: GetOwnershipStatisticsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Statistics>> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getOwnershipStatistics.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/statistics`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(StatisticsFromJSON), text);
    }

    return config;
}

/**
* Get ownership statistics
*/
export function getOwnershipStatistics<T>(requestParameters: GetOwnershipStatisticsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Statistics>>): QueryConfig<T> {
    return getOwnershipStatisticsRaw(requestParameters, requestConfig);
}

/**
 * Retrieve all statistics of the horses I have ownership of for the provided owners id.
 */
function getUserOwnershipStatisticsRaw<T>(requestParameters: GetUserOwnershipStatisticsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Statistics>> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getUserOwnershipStatistics.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/statistics`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(StatisticsFromJSON), text);
    }

    return config;
}

/**
* Retrieve all statistics of the horses I have ownership of for the provided owners id.
*/
export function getUserOwnershipStatistics<T>(requestParameters: GetUserOwnershipStatisticsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Statistics>>): QueryConfig<T> {
    return getUserOwnershipStatisticsRaw(requestParameters, requestConfig);
}


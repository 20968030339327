import { darken } from 'polished';
import { variant as systemVariant } from 'styled-system';

interface Ps {
  theme: {
    colors: {
      white: string;
      blue: string;
      green: string;
    };
  };
}

export const colorScheme = <P extends Ps>(
  props: P,
): ReturnType<typeof systemVariant> =>
  systemVariant({
    prop: 'colorScheme',
    variants: {
      secondary: {
        color: props.theme.colors.blue,
        'border-color': props.theme.colors.blue,
        '&:hover': {
          'border-color': darken(0.05, props.theme.colors.blue),
        },
      },
      save: {
        color: props.theme.colors.green,
        'border-color': props.theme.colors.green,
        '&:hover': {
          'border-color': darken(0.05, props.theme.colors.green),
        },
      },
    },
  });

// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface ResultsRaceInformation
 */
export interface ResultsRaceInformation  {
    /**
     * Race date
     * @type {Date}
     * @memberof ResultsRaceInformation
     */
    date: Date;
    /**
     * Used for link to results. Contains the date concatenated with the race number.
     * @type {string}
     * @memberof ResultsRaceInformation
     */
    displayDate: string;
    /**
     * The race id.
     * @type {number}
     * @memberof ResultsRaceInformation
     */
    raceId: number;
    /**
     * The race day id.
     * @type {number}
     * @memberof ResultsRaceInformation
     */
    raceDayId: number;
    /**
     * The number that the race has on the current day.
     * @type {number}
     * @memberof ResultsRaceInformation
     */
    raceNumber: number;
    /**
     * If results are available.
     * @type {boolean}
     * @memberof ResultsRaceInformation
     */
    linkable: boolean;
}

export function ResultsRaceInformationFromJSON(json: any): ResultsRaceInformation {
    return {
        'date': parseDateTime(json['date']),
        'displayDate': json['displayDate'],
        'raceId': json['raceId'],
        'raceDayId': json['raceDayId'],
        'raceNumber': json['raceNumber'],
        'linkable': json['linkable'],
    };
}

export function ResultsRaceInformationToJSON(value?: ResultsRaceInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'date': value.date.toLocalDate(),
        'displayDate': value.displayDate,
        'raceId': value.raceId,
        'raceDayId': value.raceDayId,
        'raceNumber': value.raceNumber,
        'linkable': value.linkable,
    };
}



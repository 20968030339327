// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    UpdateRegistrationStatusHorseStatus,
    UpdateRegistrationStatusHorseStatusFromJSON,
    UpdateRegistrationStatusHorseStatusToJSON,
} from './';

/**
 * Only returned if the collect was successful, i.e. the sign in BankID app was successfully performed.
 * @export
 * @interface UpdateRegistrationStatusHorse
 */
export interface UpdateRegistrationStatusHorse  {
    /**
     * 
     * @type {string}
     * @memberof UpdateRegistrationStatusHorse
     */
    horseName?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateRegistrationStatusHorse
     */
    horseId?: number;
    /**
     * 
     * @type {UpdateRegistrationStatusHorseStatus}
     * @memberof UpdateRegistrationStatusHorse
     */
    status?: UpdateRegistrationStatusHorseStatus;
    /**
     * 
     * @type {Date}
     * @memberof UpdateRegistrationStatusHorse
     */
    fromDate?: Date;
}

export function UpdateRegistrationStatusHorseFromJSON(json: any): UpdateRegistrationStatusHorse {
    return {
        'horseName': !exists(json, 'horseName') ? undefined : json['horseName'],
        'horseId': !exists(json, 'horseId') ? undefined : json['horseId'],
        'status': !exists(json, 'status') ? undefined : UpdateRegistrationStatusHorseStatusFromJSON(json['status']),
        'fromDate': !exists(json, 'fromDate') ? undefined : parseDateTime(json['fromDate']),
    };
}

export function UpdateRegistrationStatusHorseToJSON(value?: UpdateRegistrationStatusHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseName': value.horseName,
        'horseId': value.horseId,
        'status': UpdateRegistrationStatusHorseStatusToJSON(value.status),
        'fromDate': value.fromDate === undefined ? undefined : value.fromDate.toLocalDate(),
    };
}



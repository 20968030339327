import { enrollmentRaceDayPrefix, useDeclareHorseForStart } from '@apis';
import DirtyFormWarning from '@components/DirtyFormWarning';
import InfoBadge from '@components/InfoBadge';
import RegDateHasPassedAlert from '@components/RegDateHasPassedAlert';
import {
  DeclaredEntryDetail,
  Driver,
  StartDeclaration,
  StartDeclarationDriverChoiceEnum,
} from '@generated/propositions/src';
import Box, { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import { Link } from '@primitives/Link';
import { Ban } from '@styled-icons/fa-solid';
import Refresh from '@utils/Refresh';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Blinkers from './Blinkers';
import Drivers from './Drivers';
import Exemptions from './Exemptions';

export interface Props {
  data: DeclaredEntryDetail;
  horseId: number;
  licenseId: number;
  propositionId: number;
  refresh: Refresh;
}

export interface FormState extends StartDeclaration {
  drivers: Driver[];
}

export default function Form({
  data,
  horseId,
  licenseId,
  propositionId,
  refresh,
}: Props): JSX.Element {
  const history = useHistory();

  const { action: pathAction } = useParams<{ action: string }>();

  const { action, loading } = useDeclareHorseForStart(
    propositionId,
    licenseId,
    [
      `${enrollmentRaceDayPrefix}:${licenseId}-${data.raceDayId}`, // Reset the list of enrollments
      `startDeclarations:${licenseId}`, // Reset the list of declared horses
      `enrolledRaceDays:${licenseId}`, // Reset the notes
      `ridingWeight:${propositionId}-*`, //Reset the weights
    ],
  );

  const {
    drivers,
    blinkers,
    exemptions,
    exemptionMessage,
    message,
    driverChoice,
    incompleteStatusMessage: statusMessage,
  } = data;

  const initialValues = {
    horse: { id: horseId },
    blinkers: {
      code: blinkers.code || 'N',
      description:
        blinkers.code === 'B'
          ? 'Blinkers'
          : blinkers.code === 'S'
            ? 'Sidostycken (ludd)'
            : '-',
    },
    exemptions,
    exemptionMessage,
    message,
    driverChoice: (driverChoice ||
      'SPECIFIED_DRIVER') as StartDeclarationDriverChoiceEnum,
    drivers,
  };

  const registrationDate = new Date(data.lastEnrollStartDateTime);
  const currentDate = new Date();

  const [error, setError] = useState('');

  if (registrationDate < currentDate) {
    return (
      <Error mt={4}>
        Den här hästen går inte att startanmäla. Anmälningstiden har gått ut.
      </Error>
    );
  }

  return (
    <>
      <RegDateHasPassedAlert
        date={data.lastEnrollStartDateTime}
        refresh={refresh}
        title="Tiden för startanmälan har löpt ut"
        description="Därmed kan startanmälan ej längre genomföras."
      />

      {statusMessage ? (
        <Box mt={4}>
          <InfoBadge
            color="red"
            icon={Ban}
            message={
              <>
                {statusMessage.includes('saknar giltig vaccination') ? (
                  <>
                    Häst saknar giltig{' '}
                    <Link to="/minasidor/vaccinationer">vaccination.</Link>
                  </>
                ) : (
                  statusMessage
                )}
              </>
            }
          />
        </Box>
      ) : (
        <>
          <Formik<FormState>
            initialValues={initialValues}
            validateOnChange={false}
            onSubmit={async values => {
              const { body } = await action(values);

              if (body && body.errorMessage) {
                setError(body.localizedErrorMessage);
              }

              if (!(body && body.errorMessage)) {
                // Refresh the current Horse
                await refresh();

                history.push(
                  `/minasidor/startanmalan/${horseId}/${propositionId}/${pathAction}/kvitto`,
                );
              }
            }}
            validate={values => {
              const errors: Partial<{
                drivers: string;
                message: string;
                exemptionMessage: string;
              }> = {};
              if (
                values.driverChoice === 'SPECIFIED_DRIVER' &&
                values.drivers.length < 1
              ) {
                errors.drivers = 'Du måste ange minst en ryttare.';
              }
              if (values.driverChoice === 'FOREIGN_DRIVER' && !values.message) {
                errors.message = 'Du måste ange ett meddelande.';
              }
              if (data.horse.numberOfEnrollments > 0 && !values.message) {
                errors.message = 'Du måste ange ett meddelande.';
              }
              if (values.exemptions.length > 0 && !values.exemptionMessage) {
                errors.exemptionMessage = 'Du måste ange orsak till undantag.';
              }
              return errors;
            }}
          >
            {({ handleSubmit, errors }) => (
              <form>
                <DirtyFormWarning />
                <Drivers
                  licenseId={licenseId}
                  raceDayId={data.raceDayId}
                  propWeight={data.proposition.weight}
                  propositionId={propositionId}
                  startDeclaredToMultiple={data.horse.numberOfEnrollments > 0}
                />
                <Blinkers />
                <Exemptions />
                {errors.drivers && (
                  <Error mt={4}>
                    <>{errors.drivers}</>
                  </Error>
                )}
                {errors.exemptionMessage && (
                  <Error mt={4}>{errors.exemptionMessage}</Error>
                )}
                {errors.message && <Error mt={4}>{errors.message}</Error>}
                <Flex pt={5} width={1} justifyContent="space-between">
                  <Button
                    colorScheme="save"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    ml={0}
                    mb={[3, , 0]}
                    width={[1, null, 'auto']}
                    loading={loading}
                    onClick={handleSubmit}
                  >
                    Startanmäl
                  </Button>
                </Flex>
                {error && <Error mt={4}>{error}</Error>}
              </form>
            )}
          </Formik>
        </>
      )}
    </>
  );
}

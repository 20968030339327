// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface CoveringMare
 */
export interface CoveringMare  {
    /**
     * 
     * @type {number}
     * @memberof CoveringMare
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CoveringMare
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof CoveringMare
     */
    bornDate?: Date;
}

export function CoveringMareFromJSON(json: any): CoveringMare {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'bornDate': !exists(json, 'bornDate') ? undefined : parseDateTime(json['bornDate']),
    };
}

export function CoveringMareToJSON(value?: CoveringMare): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'bornDate': value.bornDate === undefined ? undefined : value.bornDate.toLocalDate(),
    };
}



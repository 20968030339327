const colors = {
  blue: '#145BA0',
  'light-blue': '#E1EEF7',
  'very-light-blue': '#EDF5FA',
  'deep-blue': '#233369',
  'waikawa-grey': '#647096',
  'dim-gray': '#707070',
  'light-green': '#E7F4EA',
  green: '#1C9136',
  yellow: '#EEB72B',
  red: '#BD281F',
  white: '#FFFFFF',
  snow: '#FAF9F8',
  ralphGray: '#343741',
  vistaWhite: '#E5DFD6',
  seaShell: '#FCFBFA',
  whiteSmoke: '#F2F2F2',
  gray1: '#F6F4F1',
  gray2: '#EDE9E3',
  gray3: '#C7C4C1',
  gray4: '#7C797C',
  gray5: '#56545A',
  gray6: '#404040',
  gray7: '#918A82',
  gray8: '#202020',
  black: '#000000',
  trolleyGrey: '#808080',

  alt1: '#FEECE5',
  alt3: '#AD2049',
} as const;

const space = [
  0, //  0
  4, //  1
  8, //  2
  12, // 3
  16, // 4
  24, // 5
  32, // 6
  38, // 7
  60, // 8
  72, // 9
];

type Fonts = number[] & {
  pageHeader: number;
  nano: number;
  micro: number;
  tiny: number;
  small: number;
  notes: number;
  text: number;
  h1: number[];
  h2: number[];
  h3: number[];
  h4: number[];
  h5: number[];
  h6: number[];
  lead: number;
  big: number;
  dataGridHeader: number;
  dataGridCell: number;
};

const fontSizes: Fonts = [
  12, // 0
  14, // 1
  16, // 2
  18, // 3
  24, // 4
  28, // 5
  32, // 6
  58, // 7
  70, // 8
] as Fonts;

fontSizes.nano = 8;
fontSizes.micro = 10;
fontSizes.tiny = 11;

fontSizes.small = fontSizes[0];
fontSizes.notes = 13;
fontSizes.text = fontSizes[1];
fontSizes.lead = fontSizes[2];
fontSizes.big = 20;
fontSizes.h1 = [20, , 28];
fontSizes.h2 = [20, , 22];
fontSizes.h3 = [18, , 20];
fontSizes.h4 = [14, , 16];
fontSizes.h5 = [13, ,];
fontSizes.h6 = [13, ,];
fontSizes.dataGridHeader = 13;
fontSizes.dataGridCell = 14;
fontSizes.pageHeader = fontSizes[1];

const container = 1110 + 30;
const subNav = 44;
const pageHeader = 55;

const sizes = {
  innerContainer: container - 66,
  pageHeader,
  subNav,
  pageHeaderExpanded: pageHeader + subNav,
  container,
  xlargeMaxWidthPadded: 1110 + 32,
  xlargeMaxWidth: 1110,
  smallMaxWidth: 540,
  mediumMaxWidth: 825,
  largeMaxWidth: 920,
  searchFormHeight: 68,
  searchFormMobileHeight: 150,
} as const;

interface MediaQueries {
  tablet: string;
}

export interface Theme {
  name: string;
  colors: typeof colors;
  bgColor: string;
  desktopBgColor: string;
  mainFont: string;
  space: number[];
  sizes: typeof sizes;
  fontSizes: typeof fontSizes;
  breakpoints: Array<string>;
  mediaQueries: MediaQueries;
}

const breakpoints = [null, '769px', '1024px'];

const mediaQueries = {
  tablet: `@media screen and (min-width: ${breakpoints[1]})`,
};

export const theme: Theme = {
  name: 'DEFAULT',
  bgColor: colors.seaShell,
  desktopBgColor: colors.seaShell,
  colors,
  mainFont: 'Lato, Helvetica, Arial, sans-serif',
  space,
  sizes,
  fontSizes,
  breakpoints,
  mediaQueries,
};

export default theme;

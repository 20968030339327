// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Distance,
    DistanceFromJSON,
    DistanceToJSON,
    LicenseHolder,
    LicenseHolderFromJSON,
    LicenseHolderToJSON,
    Odds,
    OddsFromJSON,
    OddsToJSON,
    Placement,
    PlacementFromJSON,
    PlacementToJSON,
    PrizeMoney,
    PrizeMoneyFromJSON,
    PrizeMoneyToJSON,
    RaceInformation,
    RaceInformationFromJSON,
    RaceInformationToJSON,
    RaceType,
    RaceTypeFromJSON,
    RaceTypeToJSON,
    SportInfoHorse,
    SportInfoHorseFromJSON,
    SportInfoHorseToJSON,
    TrackCondition,
    TrackConditionFromJSON,
    TrackConditionToJSON,
    Weight,
    WeightFromJSON,
    WeightToJSON,
    WinnerPrizeMoney,
    WinnerPrizeMoneyFromJSON,
    WinnerPrizeMoneyToJSON,
} from './';

/**
 * 
 * @export
 * @interface LicenseHolderResults
 */
export interface LicenseHolderResults  {
    /**
     * 
     * @type {string}
     * @memberof LicenseHolderResults
     */
    trackCode: string;
    /**
     * 
     * @type {RaceInformation}
     * @memberof LicenseHolderResults
     */
    raceInformation: RaceInformation;
    /**
     * 
     * @type {RaceType}
     * @memberof LicenseHolderResults
     */
    raceType: RaceType;
    /**
     * 
     * @type {Distance}
     * @memberof LicenseHolderResults
     */
    distance: Distance;
    /**
     * 
     * @type {TrackCondition}
     * @memberof LicenseHolderResults
     */
    trackCondition: TrackCondition;
    /**
     * 
     * @type {Placement}
     * @memberof LicenseHolderResults
     */
    placement: Placement;
    /**
     * 
     * @type {Odds}
     * @memberof LicenseHolderResults
     */
    odds: Odds;
    /**
     * 
     * @type {SportInfoHorse}
     * @memberof LicenseHolderResults
     */
    horse: SportInfoHorse;
    /**
     * 
     * @type {LicenseHolder}
     * @memberof LicenseHolderResults
     */
    rider?: LicenseHolder;
    /**
     * 
     * @type {LicenseHolder}
     * @memberof LicenseHolderResults
     */
    trainer?: LicenseHolder;
    /**
     * 
     * @type {PrizeMoney}
     * @memberof LicenseHolderResults
     */
    prizeMoney: PrizeMoney;
    /**
     * 
     * @type {WinnerPrizeMoney}
     * @memberof LicenseHolderResults
     */
    winnerPrizeMoney: WinnerPrizeMoney;
    /**
     * 
     * @type {Weight}
     * @memberof LicenseHolderResults
     */
    weight: Weight;
    /**
     * 
     * @type {string}
     * @memberof LicenseHolderResults
     */
    trackSurface: string;
    /**
     * 
     * @type {number}
     * @memberof LicenseHolderResults
     */
    riderHandicap: number;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseHolderResults
     */
    withdrawn?: boolean;
}

export function LicenseHolderResultsFromJSON(json: any): LicenseHolderResults {
    return {
        'trackCode': json['trackCode'],
        'raceInformation': RaceInformationFromJSON(json['raceInformation']),
        'raceType': RaceTypeFromJSON(json['raceType']),
        'distance': DistanceFromJSON(json['distance']),
        'trackCondition': TrackConditionFromJSON(json['trackCondition']),
        'placement': PlacementFromJSON(json['placement']),
        'odds': OddsFromJSON(json['odds']),
        'horse': SportInfoHorseFromJSON(json['horse']),
        'rider': !exists(json, 'rider') ? undefined : LicenseHolderFromJSON(json['rider']),
        'trainer': !exists(json, 'trainer') ? undefined : LicenseHolderFromJSON(json['trainer']),
        'prizeMoney': PrizeMoneyFromJSON(json['prizeMoney']),
        'winnerPrizeMoney': WinnerPrizeMoneyFromJSON(json['winnerPrizeMoney']),
        'weight': WeightFromJSON(json['weight']),
        'trackSurface': json['trackSurface'],
        'riderHandicap': json['riderHandicap'],
        'withdrawn': !exists(json, 'withdrawn') ? undefined : json['withdrawn'],
    };
}

export function LicenseHolderResultsToJSON(value?: LicenseHolderResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'trackCode': value.trackCode,
        'raceInformation': RaceInformationToJSON(value.raceInformation),
        'raceType': RaceTypeToJSON(value.raceType),
        'distance': DistanceToJSON(value.distance),
        'trackCondition': TrackConditionToJSON(value.trackCondition),
        'placement': PlacementToJSON(value.placement),
        'odds': OddsToJSON(value.odds),
        'horse': SportInfoHorseToJSON(value.horse),
        'rider': LicenseHolderToJSON(value.rider),
        'trainer': LicenseHolderToJSON(value.trainer),
        'prizeMoney': PrizeMoneyToJSON(value.prizeMoney),
        'winnerPrizeMoney': WinnerPrizeMoneyToJSON(value.winnerPrizeMoney),
        'weight': WeightToJSON(value.weight),
        'trackSurface': value.trackSurface,
        'riderHandicap': value.riderHandicap,
        'withdrawn': value.withdrawn,
    };
}



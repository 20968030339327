// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Horse,
    HorseFromJSON,
    HorseToJSON,
    Owner,
    OwnerFromJSON,
    OwnerToJSON,
} from './';

/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction  {
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    invoiceId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Transaction
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    productDescription: string;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    vatAmount: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    totalAmount: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    supplierName: string;
    /**
     * 
     * @type {Horse}
     * @memberof Transaction
     */
    horse?: Horse;
    /**
     * 
     * @type {Owner}
     * @memberof Transaction
     */
    owner?: Owner;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    trackCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    raceNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    place?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    usersPrivateTransaction?: boolean;
}

export function TransactionFromJSON(json: any): Transaction {
    return {
        'invoiceId': !exists(json, 'invoiceId') ? undefined : json['invoiceId'],
        'date': parseDateTime(json['date']),
        'productDescription': json['productDescription'],
        'amount': json['amount'],
        'vatAmount': json['vatAmount'],
        'totalAmount': json['totalAmount'],
        'supplierName': json['supplierName'],
        'horse': !exists(json, 'horse') ? undefined : HorseFromJSON(json['horse']),
        'owner': !exists(json, 'owner') ? undefined : OwnerFromJSON(json['owner']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'trackCode': !exists(json, 'trackCode') ? undefined : json['trackCode'],
        'raceNumber': !exists(json, 'raceNumber') ? undefined : json['raceNumber'],
        'place': !exists(json, 'place') ? undefined : json['place'],
        'usersPrivateTransaction': !exists(json, 'usersPrivateTransaction') ? undefined : json['usersPrivateTransaction'],
    };
}

export function TransactionToJSON(value?: Transaction): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'invoiceId': value.invoiceId,
        'date': value.date.toLocalDate(),
        'productDescription': value.productDescription,
        'amount': value.amount,
        'vatAmount': value.vatAmount,
        'totalAmount': value.totalAmount,
        'supplierName': value.supplierName,
        'horse': HorseToJSON(value.horse),
        'owner': OwnerToJSON(value.owner),
        'status': value.status,
        'trackCode': value.trackCode,
        'raceNumber': value.raceNumber,
        'place': value.place,
        'usersPrivateTransaction': value.usersPrivateTransaction,
    };
}



import PrevNextMenu from '@components/PrevNextMenu';
import getMonthUrl from '@utils/getMonthUrl';
import { numToMonth } from '@utils/numToMonth';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import useParamValues from './utils/useParamValues';

export default function Navigation(): JSX.Element {
  const { year, month } = useParamValues();
  const { path } = useRouteMatch();

  const searchQuery = window.location.search;

  return (
    <PrevNextMenu
      prevUrl={`${getMonthUrl('prev', year, month, path)}${searchQuery}`}
      nextUrl={`${getMonthUrl('next', year, month, path)}${searchQuery}`}
      header={`${numToMonth(month)?.toUpperCase()} ${year}`}
    />
  );
}

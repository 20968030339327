import { useEffect, useState } from 'react';

const useWindowFocus = (): boolean => {
  const [focused, setFocused] = useState(false); // Focus for first render

  useEffect(() => {
    const onChange = (): void => {
      setFocused(!document.hidden);
    };

    document.addEventListener('visibilitychange', onChange);

    return () => {
      document.removeEventListener('visibilitychange', onChange);
    };
  }, []);

  return focused;
};

export const useOnWindowFocus = (callback, deps = []): void => {
  const focused = useWindowFocus();

  useEffect(() => {
    if (focused) {
      console.info('Focus');
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, ...deps]);
};

export default useWindowFocus;

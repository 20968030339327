import useQuery from '@hooks/useQuery';
import Error from '@primitives/Error';
import React, { useEffect } from 'react';

function DownloadLinkInNewWindow(): JSX.Element {
  const { url } = useQuery<{ url: string }>(true);

  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  if (!url) {
    return <Error>Felaktig länk.</Error>;
  }

  return null;
}

export default DownloadLinkInNewWindow;

import {
  useGetTrainerHorses,
  useGetTrainerHorsesEnrolledToStakeRaces,
} from '@apis';
import FormLayout, { FormItem } from '@components/FormLayout';
import InfoBadge from '@components/InfoBadge';
import useIsUserPages from '@hooks/useIsUserPages';
import useNumericQueryParams from '@hooks/useNumericQueryParams';
import useUser from '@hooks/useUser';
import Select, { Option } from '@primitives/Form/select';
import { Link } from '@primitives/Link';
import {
  CircleInfo as InfoCircle,
  RightFromBracket as SignOutAlt,
} from '@styled-icons/fa-solid';
import getYears from '@utils/getYears';
import isLoggedIn from '@utils/isLoggedIn';
import isTrainer from '@utils/isTrainer';
import qs from 'qs';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

interface HorseFilterProps {
  licenseId: number;
  onChangeHandler: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  horseid?: number;
  year?: number;
}

function HorseFilter({
  licenseId,
  onChangeHandler,
  year,
  horseid,
}: HorseFilterProps): JSX.Element {
  const { data: horses } = useGetTrainerHorsesEnrolledToStakeRaces(
    licenseId,
    year,
  );

  return (
    <Select
      onChange={onChangeHandler}
      name="horseid"
      value={horseid || 'alla'}
      label="Häst"
    >
      <Option value="alla">Alla hästar</Option>
      {horses?.map(horse => (
        <Option key={horse.id} value={horse.id.toString()}>
          {horse.name}
        </Option>
      ))}
    </Select>
  );
}

function TrainedHorseFilter({
  licenseId,
  onChangeHandler,
  horseid,
}: HorseFilterProps): JSX.Element {
  const { data } = useGetTrainerHorses(licenseId);

  return (
    <Select
      onChange={onChangeHandler}
      name="horseid"
      value={horseid || 'alla'}
      label="Häst"
    >
      <Option value="alla">Alla hästar</Option>
      {data?.map(item => (
        <Option key={item.horse.id} value={item.horse.id.toString()}>
          {item.horse.name}
        </Option>
      ))}
    </Select>
  );
}

const Filter = (): JSX.Element => {
  const currentYear = new Date().getFullYear();
  const years = getYears(currentYear, currentYear + 4);

  const state = useNumericQueryParams();
  const user = useUser();
  const history = useHistory();
  const isUserPage = useIsUserPages();

  const { section } = useParams<{
    section?: string;
  }>();

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ): void => {
    const { target } = event;

    const newState = {
      ...state,
      [target.name]: target.value === 'ALLA' ? undefined : target.value,
    };

    history.push({ search: qs.stringify(newState) });
  };

  return (
    <FormLayout boxScheme="onlyDesktop" as="form">
      <FormItem maxWidth={[, , '200px']}>
        <Select
          onChange={onChangeHandler}
          name="year"
          label="Tävlingsår"
          value={state.year || 'ALLA'}
        >
          <Option value="ALLA">Alla</Option>
          {years.map(item => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </FormItem>
      {isTrainer(user) && isUserPage && (
        <>
          {section === 'anmalda' && (
            <FormItem maxWidth={[, , '200px']}>
              <HorseFilter
                licenseId={user.licenseId}
                onChangeHandler={onChangeHandler}
                year={state.year}
                horseid={state.horseid}
              />
            </FormItem>
          )}

          {!section && (
            <FormItem maxWidth={[, , '200px']}>
              <TrainedHorseFilter
                licenseId={user.licenseId}
                onChangeHandler={onChangeHandler}
                horseid={state.horseid}
              />
            </FormItem>
          )}
        </>
      )}

      {!isUserPage && (
        <FormItem maxWidth={[, , '500px']} noLabel>
          {!isLoggedIn(user) && (
            <InfoBadge
              icon={InfoCircle}
              color="gray"
              message={
                <>
                  Tränare?{' '}
                  <Link to="/login?dest=/minasidor/insatslopp">Logga in</Link>{' '}
                  för att anmäla och stryka hästar
                </>
              }
            />
          )}
          {isTrainer(user) && (
            <InfoBadge
              icon={SignOutAlt}
              color="green"
              message={
                <>
                  Du kan anmäla och stryka hästar på{' '}
                  <Link to="/minasidor/insatslopp">
                    tränarsidan för insatslopp
                  </Link>
                </>
              }
            />
          )}
        </FormItem>
      )}
    </FormLayout>
  );
};

export default Filter;

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Must contain at least one driver if  the driver choice is declared as specified driver.
 * @export
 * @interface DeclaredDriver
 */
export interface DeclaredDriver  {
    /**
     * 
     * @type {number}
     * @memberof DeclaredDriver
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeclaredDriver
     */
    mountingPromise?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeclaredDriver
     */
    updatedWeight?: number;
}

export function DeclaredDriverFromJSON(json: any): DeclaredDriver {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mountingPromise': !exists(json, 'mountingPromise') ? undefined : json['mountingPromise'],
        'updatedWeight': !exists(json, 'updatedWeight') ? undefined : json['updatedWeight'],
    };
}

export function DeclaredDriverToJSON(value?: DeclaredDriver): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'mountingPromise': value.mountingPromise,
        'updatedWeight': value.updatedWeight,
    };
}



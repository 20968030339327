import { useDeleteDriverTravelFee } from '@apis';
import Error from '@primitives/Error';
import Icon from '@primitives/Icon';
import { TrashCan as TrashAlt } from '@styled-icons/fa-solid';
import Refresh from '@utils/Refresh';
import React from 'react';

interface Props {
  licenseId: number;
  refresh: Refresh;
  trackId: string;
}

export default function DeleteFee({
  licenseId,
  refresh,
  trackId,
}: Props): JSX.Element {
  const { action, error, loading } = useDeleteDriverTravelFee({
    resetKeys: [`licenseholder-${licenseId}`],
  });

  if (loading) {
    return null;
  }

  return (
    <>
      {error && <Error>{error}</Error>}
      <button
        type="button"
        onClick={async () => {
          const { status } = await action({
            licenseId,
            trackId,
          });

          if (status === 200) {
            refresh();
          }
        }}
      >
        <Icon color="gray6" size={12} as={TrashAlt} />
      </button>
    </>
  );
}

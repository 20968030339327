// tslint:disable
/**
 * Web API Auth Service
 * Service for supporting user authentication (issuing JWTs) for accessing secured resources.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    AuthenticateMessage,
    AuthenticateMessageFromJSON,
    AuthenticateMessageToJSON,
    AuthenticatedUser,
    AuthenticatedUserFromJSON,
    AuthenticatedUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface CredentialsAuthentication
 */
export interface CredentialsAuthentication  {
    /**
     * The method of authentication, can be useful for UI-presentation decisions
     * @type {string}
     * @memberof CredentialsAuthentication
     */
    authenticationMethod: CredentialsAuthenticationAuthenticationMethodEnum;
    /**
     * 
     * @type {AuthenticatedUser}
     * @memberof CredentialsAuthentication
     */
    authenticatedUser: AuthenticatedUser;
    /**
     * 
     * @type {AuthenticateMessage}
     * @memberof CredentialsAuthentication
     */
    authenticateMessage?: AuthenticateMessage;
    /**
     * Signal that tells if a user should be forced to change their password.
     * @type {boolean}
     * @memberof CredentialsAuthentication
     */
    forceChangeOfPassword: boolean;
    /**
     * Signal that tells if a user soon will be forced to change their password.
     * @type {boolean}
     * @memberof CredentialsAuthentication
     */
    changeOfPasswordImminent: boolean;
}

export function CredentialsAuthenticationFromJSON(json: any): CredentialsAuthentication {
    return {
        'authenticationMethod': json['authenticationMethod'],
        'authenticatedUser': AuthenticatedUserFromJSON(json['authenticatedUser']),
        'authenticateMessage': !exists(json, 'authenticateMessage') ? undefined : AuthenticateMessageFromJSON(json['authenticateMessage']),
        'forceChangeOfPassword': json['forceChangeOfPassword'],
        'changeOfPasswordImminent': json['changeOfPasswordImminent'],
    };
}

export function CredentialsAuthenticationToJSON(value?: CredentialsAuthentication): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'authenticationMethod': value.authenticationMethod,
        'authenticatedUser': AuthenticatedUserToJSON(value.authenticatedUser),
        'authenticateMessage': AuthenticateMessageToJSON(value.authenticateMessage),
        'forceChangeOfPassword': value.forceChangeOfPassword,
        'changeOfPasswordImminent': value.changeOfPasswordImminent,
    };
}

/**
* @export
* @enum {string}
*/
export enum CredentialsAuthenticationAuthenticationMethodEnum {
    ANONYMOUS = 'ANONYMOUS',
    PASSWORD = 'PASSWORD',
    BANKID = 'BANKID',
    FREJA_EID = 'FREJA_EID',
    SUBSCRIPTION = 'SUBSCRIPTION'
}



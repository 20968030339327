import {
  ErrorResponse,
  updateDriverCommission,
  UpdateDriverCommissionRequest,
} from '@generated/tds/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';
import { Entities as BaseEntities } from './useGetDriverCommission';

type ActionParams = UpdateDriverCommissionRequest['commission'];

export interface Entities extends BaseEntities {
  updateDriverCommission?: ErrorResponse;
}

const useUpdateDriverCommission = (
  licenseId: number,
): MutationResponse<
  Entities['updateDriverCommission'],
  Entities,
  ActionParams
> => {
  const queryFunc = (commission: ActionParams): QueryConfig<Entities> =>
    updateDriverCommission<Entities>({
      licenseId,
      commission,
    });

  return useApiMutate<Entities['updateDriverCommission'], ActionParams>({
    queryFunc,
    selector: (state: State) => state.entities.updateDriverCommission,
    queryKey: 'updateDriverCommission',
    resetKeys: [`licenseholder-${licenseId}`],
  });
};

export default useUpdateDriverCommission;

import { useSearch } from '@apis';
import Autocomplete from '@components/Autocomplete';
import {
  SearchResultItem,
  SearchResultItemTypeEnum,
  SearchSportInfoTypeEnum,
} from '@generated/content/src';
import useDebounce from '@hooks/useDebounce';
import { toggleSearchForm } from '@main/state/slice';
import Icon, { HorseIcon, RiderIcon } from '@primitives/Icon';
import { Text } from '@primitives/Typography';
import {
  CalendarDays as CalendarAlt,
  CircleInfo as InfoCircle,
  Newspaper,
  UpRightFromSquare as ExternalLinkAlt,
  User,
} from '@styled-icons/fa-solid';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StyledIcon } from 'styled-icons/types';

export const typeMap = {
  all_sportinfo: 'All sportinfo',
  horse: 'Häst',
  license_holder: 'Tränare/ryttare',
  owner: 'Ägare',
  breeder: 'Uppfödare',
};
enum allSportinfoEnum {
  allSportinfo = 'all_sportinfo',
}
interface SearchFieldProps {
  onChange: (val: string) => void;
  gender?: string;
  value?: string;
  type?: SearchSportInfoTypeEnum | allSportinfoEnum;
  focusOnSeachField?: boolean;
  onSuggestionSelected?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: any,
    { suggestion }: { suggestion: SearchResultItem },
  ) => void;
}

export default function SearchField({
  onChange,
  value,
  gender,
  type,
  focusOnSeachField = false,
  onSuggestionSelected,
}: SearchFieldProps): JSX.Element {
  const [query, setQuery] = useState('');

  const searchValue = useDebounce(query, 300);

  const { data } = useSearch({
    query: searchValue,
    gender,
    type: type === allSportinfoEnum.allSportinfo ? null : type,
    content: type === undefined ? true : null,
    appMenu: type === allSportinfoEnum.allSportinfo ? null : true,
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const sugestionIconSelector = (
    type: SearchResultItemTypeEnum,
    licenseType: string,
  ): StyledIcon => {
    switch (type) {
      case SearchResultItemTypeEnum.Horse:
        return HorseIcon;
        break;

      case SearchResultItemTypeEnum.Owner:
      case SearchResultItemTypeEnum.Breeder:
        return User;
        break;

      case SearchResultItemTypeEnum.LicenseHolder:
        return licenseType?.match(/ryttare|jockey/i) ? RiderIcon : User;
        break;
      case SearchResultItemTypeEnum.Page:
        return InfoCircle;
        break;

      case SearchResultItemTypeEnum.News:
        return Newspaper;
        break;

      case SearchResultItemTypeEnum.Event:
        return CalendarAlt;
        break;

      case SearchResultItemTypeEnum.AppPage:
        return InfoCircle;
        break;

      default:
        return null;
    }
  };

  const suggestionIsInApp = (suggestion: SearchResultItem): boolean =>
    suggestion.sportinfo || suggestion.type === 'app_page';

  return (
    <Autocomplete<SearchResultItem>
      suggestions={data?.results?.slice(0, 10) || []}
      onSuggestionSelected={(e, { suggestion }) => {
        if (onSuggestionSelected) {
          onSuggestionSelected(e, { suggestion });
        } else {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'Pageview',
            pagePath: `/autocomplete?query=${query}&type=${
              !type || type === allSportinfoEnum.allSportinfo ? '' : type
            }`,
            pageTitle: `Autocomplete sök: ${query}${
              !type || type === allSportinfoEnum.allSportinfo ? '' : type
            }`,
          });

          if (suggestion.type === 'app_page') {
            history.push(suggestion.url.replace(/https:\/\/.*?\//, '/'));
          } else if (!suggestion.sportinfo) {
            window.location.href = suggestion.url;
          } else {
            history.push(suggestion.url);
          }
        }
        dispatch(toggleSearchForm(false));
      }}
      onSuggestionsFetchRequested={arg => {
        setQuery(arg.value);
      }}
      onSuggestionsClearRequested={() => {
        setQuery('');
      }}
      getSuggestionValue={suggestion => suggestion.name}
      renderSuggestion={suggestion => (
        <Text
          fontSize={['lead', , 'text']}
          color="deep-blue"
          display="flex"
          lineHeight="1.5"
          cursor="pointer"
        >
          <Icon
            size={14}
            minWidth={14}
            color="deep-blue"
            as={sugestionIconSelector(
              suggestion.type as SearchResultItemTypeEnum,
              suggestion.licenseType,
            )}
            mr={2}
            mt={1}
          />
          <div>
            {suggestion.name}
            {!suggestionIsInApp(suggestion) && (
              <Icon
                size={14}
                color="deep-blue"
                as={ExternalLinkAlt}
                ml={3}
                mt={-1}
              />
            )}
            <br />
            <Text color="dim-gray" fontSize="small">
              {suggestion.displayType}
            </Text>
          </div>
        </Text>
      )}
      inputProps={{
        value,
        onChange: (e, { newValue }) => {
          onChange(newValue);
        },
        autoFocus: focusOnSeachField,
        placeholder: 'Skriv för att söka',
      }}
    />
  );
}

import { base, BaseProps } from '@primitives/base';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colorScheme } from './variants';

interface Props extends BaseProps {
  colorScheme?: 'secondary' | 'save';
}

const LinkButton = styled(({ to, className, children }) => (
  <Link {...{ to, className }}>{children}</Link>
))<Props>`
  border-width: 1px;
  border-style: solid;
  font-weight: bold;
  display: inline-block;
  text-align: center;
  background-color: inherit;
  border-radius: 5px;
  ${base()}
  ${colorScheme};
`;

LinkButton.defaultProps = {
  colorScheme: 'secondary',
  bg: 'white',
  py: 2,
  px: 4,
};

export default LinkButton;

// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    OwnershipTransfer,
    OwnershipTransferFromJSON,
    OwnershipTransferToJSON,
} from '../models';

export interface GetTerminableLeasesRequest {
    licenseId: number;
}


/**
 * Fetches the terminable leases.
 */
function getTerminableLeasesRaw<T>(requestParameters: GetTerminableLeasesRequest, requestConfig: runtime.TypedQueryConfig<T, Array<OwnershipTransfer>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getTerminableLeases.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/leasing/{licenseId}/terminable`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(OwnershipTransferFromJSON), text);
    }

    return config;
}

/**
* Fetches the terminable leases.
*/
export function getTerminableLeases<T>(requestParameters: GetTerminableLeasesRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<OwnershipTransfer>>): QueryConfig<T> {
    return getTerminableLeasesRaw(requestParameters, requestConfig);
}


import { Commission, getDriverCommission } from '@generated/tds/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = Commission | undefined;

export interface Entities {
  driverCommission?: {
    [licenseId: number]: Result;
  };
}

const useGetDriverCommission = (
  licenseId: number,
): ApiResponse<Result, Entities> => {
  const query = getDriverCommission<Entities>(
    {
      licenseId,
    },
    {
      transform: responseBody => ({
        driverCommission: {
          [licenseId]: responseBody,
        },
      }),
      update: {
        driverCommission: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Result =>
    state.entities?.driverCommission?.[licenseId];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetDriverCommission;

// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    ChartType,
    ChartTypeFromJSON,
    ChartTypeToJSON,
    SearchFilter,
    SearchFilterFromJSON,
    SearchFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface SavedFilter
 */
export interface SavedFilter  {
    /**
     * This is the same as 3, is for GALLOP
     * @type {number}
     * @memberof SavedFilter
     */
    categoryId: number;
    /**
     * 
     * @type {string}
     * @memberof SavedFilter
     */
    chartId: string;
    /**
     * 
     * @type {string}
     * @memberof SavedFilter
     */
    name: string;
    /**
     * 
     * @type {ChartType}
     * @memberof SavedFilter
     */
    chartType: ChartType;
    /**
     * 
     * @type {SearchFilter}
     * @memberof SavedFilter
     */
    searchFilter: SearchFilter;
}

export function SavedFilterFromJSON(json: any): SavedFilter {
    return {
        'categoryId': json['categoryId'],
        'chartId': json['chartId'],
        'name': json['name'],
        'chartType': ChartTypeFromJSON(json['chartType']),
        'searchFilter': SearchFilterFromJSON(json['searchFilter']),
    };
}

export function SavedFilterToJSON(value?: SavedFilter): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'categoryId': value.categoryId,
        'chartId': value.chartId,
        'name': value.name,
        'chartType': ChartTypeToJSON(value.chartType),
        'searchFilter': SearchFilterToJSON(value.searchFilter),
    };
}



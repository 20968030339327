// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Track
 */
export interface Track  {
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    name?: string;
}

export function TrackFromJSON(json: any): Track {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function TrackToJSON(value?: Track): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'code': value.code,
        'name': value.name,
    };
}



// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    DriverReference,
    DriverReferenceFromJSON,
    DriverReferenceToJSON,
    DriverStatisticsSummary,
    DriverStatisticsSummaryFromJSON,
    DriverStatisticsSummaryToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SuspensionStatus,
    SuspensionStatusFromJSON,
    SuspensionStatusToJSON,
} from '../models';

export interface GetDriverStatisticsSummaryRequest {
    licenseId: number;
}

export interface GetDriversRequest {
    firstname?: string;
    lastname?: string;
}

export interface GetSuspensionStatusRequest {
    licenseId: number;
}


/**
 * Get the specified drivers statistics summary. Contains info about the number of driver victories this year.
 */
function getDriverStatisticsSummaryRaw<T>(requestParameters: GetDriverStatisticsSummaryRequest, requestConfig: runtime.TypedQueryConfig<T, DriverStatisticsSummary> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getDriverStatisticsSummary.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/drivers/statistics/summary/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(DriverStatisticsSummaryFromJSON(body), text);
    }

    return config;
}

/**
* Get the specified drivers statistics summary. Contains info about the number of driver victories this year.
*/
export function getDriverStatisticsSummary<T>(requestParameters: GetDriverStatisticsSummaryRequest, requestConfig?: runtime.TypedQueryConfig<T, DriverStatisticsSummary>): QueryConfig<T> {
    return getDriverStatisticsSummaryRaw(requestParameters, requestConfig);
}

/**
 * Searches for drivers/riders matching the provided search criterias. If this is not specified the criteria is valid for both.
 */
function getDriversRaw<T>(requestParameters: GetDriversRequest, requestConfig: runtime.TypedQueryConfig<T, Array<DriverReference>> = {}): QueryConfig<T> {
    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.firstname !== undefined) {
        queryParameters['firstname'] = requestParameters.firstname;
    }


    if (requestParameters.lastname !== undefined) {
        queryParameters['lastname'] = requestParameters.lastname;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/drivers`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(DriverReferenceFromJSON), text);
    }

    return config;
}

/**
* Searches for drivers/riders matching the provided search criterias. If this is not specified the criteria is valid for both.
*/
export function getDrivers<T>(requestParameters: GetDriversRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<DriverReference>>): QueryConfig<T> {
    return getDriversRaw(requestParameters, requestConfig);
}

/**
 * Get the specified drivers/riders (license holders) suspension status. Suspension status contains info about whether or not the driver has any active suspension at present.
 */
function getSuspensionStatusRaw<T>(requestParameters: GetSuspensionStatusRequest, requestConfig: runtime.TypedQueryConfig<T, SuspensionStatus> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getSuspensionStatus.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/drivers/{licenseId}/suspensionstatus`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SuspensionStatusFromJSON(body), text);
    }

    return config;
}

/**
* Get the specified drivers/riders (license holders) suspension status. Suspension status contains info about whether or not the driver has any active suspension at present.
*/
export function getSuspensionStatus<T>(requestParameters: GetSuspensionStatusRequest, requestConfig?: runtime.TypedQueryConfig<T, SuspensionStatus>): QueryConfig<T> {
    return getSuspensionStatusRaw(requestParameters, requestConfig);
}


import 'styled-components';

import { useGetStakeRaceWithStakes } from '@apis';
import { PageLayout } from '@components/PageLayouts';
import useUser from '@hooks/useUser';
import Box, { Container, Item } from '@primitives/Box';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import { Lead } from '@primitives/Typography';
import React from 'react';
import { generatePath, useLocation, useRouteMatch } from 'react-router-dom';

import InfoTexts from '../InfoTexts';
import StakeInfo from '../StakeInfo';
import ScratchForm from './ScratchForm';

interface Props {
  propositionId: number;
}

export default function Expanded({ propositionId }: Props): JSX.Element {
  const { data, loading, error, status } =
    useGetStakeRaceWithStakes(propositionId);

  const user = useUser();
  const { path } = useRouteMatch();
  const { search } = useLocation();

  if (loading) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return <Error>{error}</Error>;
  }

  const header = data.infoTexts.find(item => item.header);

  return (
    <PageLayout
      headline={'Stryk från insatslopp'}
      singleCard
      backLink={{
        to: generatePath(path, { section: 'anmalda' }) + search,
      }}
    >
      <Box my={[-4, , 0]} py={[4, , 0]}>
        {header && (
          <Lead mb={[0, , 2]} mt={[4, , 0]} fontWeight="bold">
            {header.text}
          </Lead>
        )}
        <Container gap={15}>
          <Item width={[1, null, 0.6]} display="block">
            <ScratchForm
              user={user}
              propositionId={propositionId}
              disabled={data?.enrollmentUpdatesAllowed === false}
            />
          </Item>

          <Item width={[1, null, 0.4]} display="block">
            <Box verticalSpacing={3}>
              <StakeInfo stakes={data.stakes} />
              <InfoTexts infoTexts={data.infoTexts} />
            </Box>
          </Item>
        </Container>
      </Box>
    </PageLayout>
  );
}

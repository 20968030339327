// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Pedigree,
    PedigreeFromJSON,
    PedigreeToJSON,
    PedigreeDescription,
    PedigreeDescriptionFromJSON,
    PedigreeDescriptionToJSON,
} from '../models';

export interface GetPedigreeRequest {
    horseId: number;
    pedigreetree: GetPedigreePedigreetreeEnum;
}

export interface GetPedigreeDescriptionRequest {
    horseId: number;
}


/**
 * Get a horse\'s pedigree
 */
function getPedigreeRaw<T>(requestParameters: GetPedigreeRequest, requestConfig: runtime.TypedQueryConfig<T, Pedigree> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling getPedigree.');
    }

    if (requestParameters.pedigreetree === null || requestParameters.pedigreetree === undefined) {
        throw new runtime.RequiredError('pedigreetree','Required parameter requestParameters.pedigreetree was null or undefined when calling getPedigree.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.pedigreetree !== undefined) {
        queryParameters['pedigreetree'] = requestParameters.pedigreetree;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{horseId}/pedigree`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(PedigreeFromJSON(body), text);
    }

    return config;
}

/**
* Get a horse\'s pedigree
*/
export function getPedigree<T>(requestParameters: GetPedigreeRequest, requestConfig?: runtime.TypedQueryConfig<T, Pedigree>): QueryConfig<T> {
    return getPedigreeRaw(requestParameters, requestConfig);
}

/**
 * Get a horse\'s pedigree description (known as \'Signalement\')
 */
function getPedigreeDescriptionRaw<T>(requestParameters: GetPedigreeDescriptionRequest, requestConfig: runtime.TypedQueryConfig<T, PedigreeDescription> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling getPedigreeDescription.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{horseId}/pedigree/description`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(PedigreeDescriptionFromJSON(body), text);
    }

    return config;
}

/**
* Get a horse\'s pedigree description (known as \'Signalement\')
*/
export function getPedigreeDescription<T>(requestParameters: GetPedigreeDescriptionRequest, requestConfig?: runtime.TypedQueryConfig<T, PedigreeDescription>): QueryConfig<T> {
    return getPedigreeDescriptionRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum GetPedigreePedigreetreeEnum {
    SMALL = 'SMALL',
    LARGE = 'LARGE'
}

// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    StudbookSearchResult,
    StudbookSearchResultFromJSON,
    StudbookSearchResultToJSON,
} from '../models';

export interface SearchRequest {
    categoryid: string;
    registryid: string;
    year: string;
    sirelocation?: string;
    sireid?: number;
    gender?: string;
    siretype?: string;
}


/**
 * The response entity has fields representing the different categories. Only one field will ever be populated and it will always be the one for the given category.
 * Search for studbook data
 */
function searchRaw<T>(requestParameters: SearchRequest, requestConfig: runtime.TypedQueryConfig<T, StudbookSearchResult> = {}): QueryConfig<T> {
    if (requestParameters.categoryid === null || requestParameters.categoryid === undefined) {
        throw new runtime.RequiredError('categoryid','Required parameter requestParameters.categoryid was null or undefined when calling search.');
    }

    if (requestParameters.registryid === null || requestParameters.registryid === undefined) {
        throw new runtime.RequiredError('registryid','Required parameter requestParameters.registryid was null or undefined when calling search.');
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
        throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling search.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.categoryid !== undefined) {
        queryParameters['categoryid'] = requestParameters.categoryid;
    }


    if (requestParameters.registryid !== undefined) {
        queryParameters['registryid'] = requestParameters.registryid;
    }


    if (requestParameters.year !== undefined) {
        queryParameters['year'] = requestParameters.year;
    }


    if (requestParameters.sirelocation !== undefined) {
        queryParameters['sirelocation'] = requestParameters.sirelocation;
    }


    if (requestParameters.sireid !== undefined) {
        queryParameters['sireid'] = requestParameters.sireid;
    }


    if (requestParameters.gender !== undefined) {
        queryParameters['gender'] = requestParameters.gender;
    }


    if (requestParameters.siretype !== undefined) {
        queryParameters['siretype'] = requestParameters.siretype;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/studbook/search`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(StudbookSearchResultFromJSON(body), text);
    }

    return config;
}

/**
* The response entity has fields representing the different categories. Only one field will ever be populated and it will always be the one for the given category.
* Search for studbook data
*/
export function search<T>(requestParameters: SearchRequest, requestConfig?: runtime.TypedQueryConfig<T, StudbookSearchResult>): QueryConfig<T> {
    return searchRaw(requestParameters, requestConfig);
}


import Content from '@components/ContentBlock/Content';
import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import useNumericParams from '@hooks/useNumericParams';
import { Text } from '@primitives/Typography';
import roleEnum from '@utils/roleEnum';
import React from 'react';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import VaccinationHistory from './History';
import NewVaccination from './New';

interface Props {
  user: AuthenticatedUser;
}

const Vaccinations = ({ user: { licenseId } }: Props): JSX.Element => {
  const { path } = useRouteMatch();
  const { tab } = useParams<{ tab: string }>();
  const { horseId } = useNumericParams();

  const newVaccinationPath = generatePath(path, {
    tab: 'ny',
  });

  const vaccinationHistoryPath = generatePath(path, {
    tab: 'historik',
  });

  const vaccinationTermsPath = generatePath(path, {
    tab: 'villkor',
  });

  if (!tab) {
    return <Redirect to={newVaccinationPath} />;
  }

  if (tab === 'villkor') {
    return (
      <PageLayout singleCard headline="Vaccinationsvillkor">
        <Content slug="vaccinationsvillkor" />
      </PageLayout>
    );
  }

  return (
    <PageLayout
      singleCard
      topRightRemark={
        <Text
          as="a"
          fontSize="text"
          href={vaccinationTermsPath}
          target="_blank"
          rel="noopener noreferrer"
        >
          Läs vaccinationsvillkor
        </Text>
      }
      headline="Vaccinationer"
      tabs={[
        {
          text: 'Ny vaccination',
          to: newVaccinationPath,
        },
        {
          text: 'Vaccinationshistorik',
          to: vaccinationHistoryPath,
        },
      ]}
    >
      <Switch>
        <Route path={newVaccinationPath}>
          <NewVaccination licenseId={licenseId} />
        </Route>
        <Route path={vaccinationHistoryPath}>
          <VaccinationHistory licenseId={licenseId} horseId={horseId} />
        </Route>
      </Switch>
    </PageLayout>
  );
};

export default withUser(Vaccinations, { roles: [roleEnum.Trainer] });

import { Driver } from '@generated/propositions/src';

export default function formatDriver(driver: Driver): string {
  const parts = [
    driver.name,
    driver.amateur && 'a',
    driver.apprentice && 'I',
    driver.lowestRidingWeight,
    driver?.weightReduction && `/${driver.weightReduction}`,
    driver?.suspended && 'avstängd',
  ].filter(Boolean);

  return parts.join(' ');
}

export const formatNumber = (
  value: number | undefined,
  maximumFractionDigits = 2,
): string | null =>
  value
    ? Intl
      ? new Intl.NumberFormat('de-DE', {
          maximumFractionDigits,
        })
          .format(value)
          .replace(/\./gi, ' ')
      : value.toString()
    : null;

// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Race,
    RaceFromJSON,
    RaceToJSON,
} from './';

/**
 * 
 * @export
 * @interface StartListRaceDay
 */
export interface StartListRaceDay  {
    /**
     * 
     * @type {number}
     * @memberof StartListRaceDay
     */
    raceDayId: number;
    /**
     * If firstStart is null, it will be populated by startDateTime.
     * @type {Date}
     * @memberof StartListRaceDay
     */
    firstStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StartListRaceDay
     */
    raceDayDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StartListRaceDay
     */
    trackName: string;
    /**
     * 
     * @type {number}
     * @memberof StartListRaceDay
     */
    prevRaceDayId?: number;
    /**
     * ID of next published race day. Value 0 means none has been published yet.
     * @type {number}
     * @memberof StartListRaceDay
     */
    nextRaceDayId?: number;
    /**
     * 
     * @type {Array<Race>}
     * @memberof StartListRaceDay
     */
    raceList: Array<Race>;
    /**
     * 
     * @type {boolean}
     * @memberof StartListRaceDay
     */
    pdfAvailable?: boolean;
}

export function StartListRaceDayFromJSON(json: any): StartListRaceDay {
    return {
        'raceDayId': json['raceDayId'],
        'firstStart': !exists(json, 'firstStart') ? undefined : parseDateTime(json['firstStart']),
        'raceDayDate': !exists(json, 'raceDayDate') ? undefined : parseDateTime(json['raceDayDate']),
        'trackName': json['trackName'],
        'prevRaceDayId': !exists(json, 'prevRaceDayId') ? undefined : json['prevRaceDayId'],
        'nextRaceDayId': !exists(json, 'nextRaceDayId') ? undefined : json['nextRaceDayId'],
        'raceList': (json['raceList'] as Array<any>).map(RaceFromJSON),
        'pdfAvailable': !exists(json, 'pdfAvailable') ? undefined : json['pdfAvailable'],
    };
}

export function StartListRaceDayToJSON(value?: StartListRaceDay): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'raceDayId': value.raceDayId,
        'firstStart': value.firstStart === undefined ? undefined : value.firstStart.toISOString(),
        'raceDayDate': value.raceDayDate === undefined ? undefined : value.raceDayDate.toLocalDate(),
        'trackName': value.trackName,
        'prevRaceDayId': value.prevRaceDayId,
        'nextRaceDayId': value.nextRaceDayId,
        'raceList': (value.raceList as Array<any>).map(RaceToJSON),
        'pdfAvailable': value.pdfAvailable,
    };
}



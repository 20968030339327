// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Horse,
    HorseFromJSON,
    HorseToJSON,
    MareDetails,
    MareDetailsFromJSON,
    MareDetailsToJSON,
} from './';

/**
 * The breeding and breeding results, populated for search category: STAMBOK_BETECKNINGAR
 * @export
 * @interface BreedingAndResult
 */
export interface BreedingAndResult  {
    /**
     * 
     * @type {MareDetails}
     * @memberof BreedingAndResult
     */
    mareDetails: MareDetails;
    /**
     * 
     * @type {Horse}
     * @memberof BreedingAndResult
     */
    sire: Horse;
    /**
     * The sire\'s stable country, if other than Sweden
     * @type {string}
     * @memberof BreedingAndResult
     */
    sireStableCountry?: string;
    /**
     * The year when the breeding took place
     * @type {number}
     * @memberof BreedingAndResult
     */
    breedingYear: number;
    /**
     * The breeding result
     * @type {string}
     * @memberof BreedingAndResult
     */
    breedingResult?: string;
    /**
     * The id of the foal if it was born
     * @type {number}
     * @memberof BreedingAndResult
     */
    foalId?: number;
    /**
     * 
     * @type {string}
     * @memberof BreedingAndResult
     */
    country?: string;
}

export function BreedingAndResultFromJSON(json: any): BreedingAndResult {
    return {
        'mareDetails': MareDetailsFromJSON(json['mareDetails']),
        'sire': HorseFromJSON(json['sire']),
        'sireStableCountry': !exists(json, 'sireStableCountry') ? undefined : json['sireStableCountry'],
        'breedingYear': json['breedingYear'],
        'breedingResult': !exists(json, 'breedingResult') ? undefined : json['breedingResult'],
        'foalId': !exists(json, 'foalId') ? undefined : json['foalId'],
        'country': !exists(json, 'country') ? undefined : json['country'],
    };
}

export function BreedingAndResultToJSON(value?: BreedingAndResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'mareDetails': MareDetailsToJSON(value.mareDetails),
        'sire': HorseToJSON(value.sire),
        'sireStableCountry': value.sireStableCountry,
        'breedingYear': value.breedingYear,
        'breedingResult': value.breedingResult,
        'foalId': value.foalId,
        'country': value.country,
    };
}



// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Horse,
    HorseFromJSON,
    HorseToJSON,
} from './';

/**
 * Breeding statistics, populated for search category: STAMBOK_STAT_BETECKNINGAR
 * @export
 * @interface BreedingStatistics
 */
export interface BreedingStatistics  {
    /**
     * 
     * @type {Horse}
     * @memberof BreedingStatistics
     */
    horse?: Horse;
    /**
     * The horses birth year
     * @type {number}
     * @memberof BreedingStatistics
     */
    birthYear?: number;
    /**
     * 
     * @type {Horse}
     * @memberof BreedingStatistics
     */
    horseFather?: Horse;
    /**
     * The sire type descriptive text, not to be confused with the sire type for filtering.
     * @type {string}
     * @memberof BreedingStatistics
     */
    sireTypeText?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingStatistics
     */
    breedingYear?: number;
    /**
     * Number of breedings performed
     * @type {number}
     * @memberof BreedingStatistics
     */
    numberOfBreedings?: number;
    /**
     * Number of foals born
     * @type {number}
     * @memberof BreedingStatistics
     */
    numberOfFoals?: number;
}

export function BreedingStatisticsFromJSON(json: any): BreedingStatistics {
    return {
        'horse': !exists(json, 'horse') ? undefined : HorseFromJSON(json['horse']),
        'birthYear': !exists(json, 'birthYear') ? undefined : json['birthYear'],
        'horseFather': !exists(json, 'horseFather') ? undefined : HorseFromJSON(json['horseFather']),
        'sireTypeText': !exists(json, 'sireTypeText') ? undefined : json['sireTypeText'],
        'breedingYear': !exists(json, 'breedingYear') ? undefined : json['breedingYear'],
        'numberOfBreedings': !exists(json, 'numberOfBreedings') ? undefined : json['numberOfBreedings'],
        'numberOfFoals': !exists(json, 'numberOfFoals') ? undefined : json['numberOfFoals'],
    };
}

export function BreedingStatisticsToJSON(value?: BreedingStatistics): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horse': HorseToJSON(value.horse),
        'birthYear': value.birthYear,
        'horseFather': HorseToJSON(value.horseFather),
        'sireTypeText': value.sireTypeText,
        'breedingYear': value.breedingYear,
        'numberOfBreedings': value.numberOfBreedings,
        'numberOfFoals': value.numberOfFoals,
    };
}



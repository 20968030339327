import ExpandableHeadline from '@components/ExpandableHeadline';
import InfoTable from '@components/InfoTable';
import { PageLayout } from '@components/PageLayouts';
import { ClipboardList } from '@styled-icons/fa-solid';
import getPropositionInfoTableValues from '@utils/getPropositionInfoTableValues';
import React, { ReactNode } from 'react';

interface Props {
  trackName: string;
  raceDate: Date;
  registrationDate: Date;
  changeWeightDate: Date;
  latestStartRegistrationDate: Date;
  children: ReactNode;
  headline: string;
  backLink: string;
}

const Header = ({
  trackName,
  raceDate,
  registrationDate,
  changeWeightDate,
  latestStartRegistrationDate,
  children,
  headline,
  backLink,
}: Props): JSX.Element => (
  <PageLayout
    singleCard
    pageSize="medium"
    headline={headline}
    backLink={{
      to: backLink,
    }}
  >
    <ExpandableHeadline
      date={raceDate}
      expandedDefault={false}
      headline={trackName}
      icon={ClipboardList}
      mb={4}
    >
      <InfoTable
        values={getPropositionInfoTableValues(
          registrationDate,
          changeWeightDate,
          latestStartRegistrationDate,
        )}
      />
    </ExpandableHeadline>

    {children}
  </PageLayout>
);

export default Header;

import {
  getOwnershipStatistics,
  Statistics,
} from '../../generated/sportactors/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = Statistics[] | undefined;

export interface Entities {
  ownershipStatisics?: {
    [id: number]: Result;
  };
}

const useGetOwnershipStatistics = (
  ownerId: number,
): ApiResponse<Result, Entities> =>
  useApi<Result>({
    query: getOwnershipStatistics<Entities>(
      {
        ownerId,
      },
      {
        transform: responseBody => ({
          ownershipStatisics: {
            [ownerId]: responseBody,
          },
        }),
        update: {
          ownershipStatisics: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities?.ownershipStatisics?.[ownerId],
  });

export default useGetOwnershipStatistics;

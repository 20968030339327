// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    ExtraText,
    ExtraTextFromJSON,
    ExtraTextToJSON,
    StakeRaceOngoingPublicHorse,
    StakeRaceOngoingPublicHorseFromJSON,
    StakeRaceOngoingPublicHorseToJSON,
} from './';

/**
 * 
 * @export
 * @interface StakeRaceOngoingHorses
 */
export interface StakeRaceOngoingHorses  {
    /**
     * Number of the stake representing this list of horses
     * @type {number}
     * @memberof StakeRaceOngoingHorses
     */
    stakeNumber?: number;
    /**
     * 
     * @type {Array<ExtraText>}
     * @memberof StakeRaceOngoingHorses
     */
    extraText?: Array<ExtraText>;
    /**
     * Horses in this ongoing stake race
     * @type {Array<StakeRaceOngoingPublicHorse>}
     * @memberof StakeRaceOngoingHorses
     */
    horses?: Array<StakeRaceOngoingPublicHorse>;
}

export function StakeRaceOngoingHorsesFromJSON(json: any): StakeRaceOngoingHorses {
    return {
        'stakeNumber': !exists(json, 'stakeNumber') ? undefined : json['stakeNumber'],
        'extraText': !exists(json, 'extraText') ? undefined : (json['extraText'] as Array<any>).map(ExtraTextFromJSON),
        'horses': !exists(json, 'horses') ? undefined : (json['horses'] as Array<any>).map(StakeRaceOngoingPublicHorseFromJSON),
    };
}

export function StakeRaceOngoingHorsesToJSON(value?: StakeRaceOngoingHorses): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'stakeNumber': value.stakeNumber,
        'extraText': value.extraText === undefined ? undefined : (value.extraText as Array<any>).map(ExtraTextToJSON),
        'horses': value.horses === undefined ? undefined : (value.horses as Array<any>).map(StakeRaceOngoingPublicHorseToJSON),
    };
}



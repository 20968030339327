// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    HistoryTrack,
    HistoryTrackFromJSON,
    HistoryTrackToJSON,
} from './';

/**
 * The stake race row.
 * @export
 * @interface StakeRaceGallopRow
 */
export interface StakeRaceGallopRow  {
    /**
     * The prepayment period (forfeit) id.
     * @type {number}
     * @memberof StakeRaceGallopRow
     */
    forfeitId?: number;
    /**
     * The year.
     * @type {string}
     * @memberof StakeRaceGallopRow
     */
    year: string;
    /**
     * 
     * @type {HistoryTrack}
     * @memberof StakeRaceGallopRow
     */
    track: HistoryTrack;
    /**
     * The next deposit date.
     * @type {Date}
     * @memberof StakeRaceGallopRow
     */
    nextDepositDate?: Date;
    /**
     * The deposit number.
     * @type {number}
     * @memberof StakeRaceGallopRow
     */
    depositNo: number;
    /**
     * The deposit number total.
     * @type {number}
     * @memberof StakeRaceGallopRow
     */
    depositNoTotal: number;
    /**
     * 
     * @type {number}
     * @memberof StakeRaceGallopRow
     */
    amount?: number;
    /**
     * 
     * @type {Date}
     * @memberof StakeRaceGallopRow
     */
    withdrawn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StakeRaceGallopRow
     */
    raceDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof StakeRaceGallopRow
     */
    propositionId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StakeRaceGallopRow
     */
    entryReady?: boolean;
}

export function StakeRaceGallopRowFromJSON(json: any): StakeRaceGallopRow {
    return {
        'forfeitId': !exists(json, 'forfeitId') ? undefined : json['forfeitId'],
        'year': json['year'],
        'track': HistoryTrackFromJSON(json['track']),
        'nextDepositDate': !exists(json, 'nextDepositDate') ? undefined : parseDateTime(json['nextDepositDate']),
        'depositNo': json['depositNo'],
        'depositNoTotal': json['depositNoTotal'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'withdrawn': !exists(json, 'withdrawn') ? undefined : parseDateTime(json['withdrawn']),
        'raceDate': !exists(json, 'raceDate') ? undefined : parseDateTime(json['raceDate']),
        'propositionId': !exists(json, 'propositionId') ? undefined : json['propositionId'],
        'entryReady': !exists(json, 'entryReady') ? undefined : json['entryReady'],
    };
}

export function StakeRaceGallopRowToJSON(value?: StakeRaceGallopRow): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'forfeitId': value.forfeitId,
        'year': value.year,
        'track': HistoryTrackToJSON(value.track),
        'nextDepositDate': value.nextDepositDate === undefined ? undefined : value.nextDepositDate.toLocalDate(),
        'depositNo': value.depositNo,
        'depositNoTotal': value.depositNoTotal,
        'amount': value.amount,
        'withdrawn': value.withdrawn === undefined ? undefined : value.withdrawn.toLocalDate(),
        'raceDate': value.raceDate === undefined ? undefined : value.raceDate.toLocalDate(),
        'propositionId': value.propositionId,
        'entryReady': value.entryReady,
    };
}



// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    CoveringListStud,
    CoveringListStudFromJSON,
    CoveringListStudToJSON,
    CoveringMare,
    CoveringMareFromJSON,
    CoveringMareToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface GetAllMaresByNameRequest {
    namequery?: string;
    limitenabled?: boolean;
    limit?: number;
}

export interface GetStudsRequest {
    licenseId: number;
    year?: number;
}


/**
 * Get mares - possible to filter by name query (if not provided ALL mares will be returned). Support for limiting (enabled by default), since the number of mares might be very large
 */
function getAllMaresByNameRaw<T>(requestParameters: GetAllMaresByNameRequest, requestConfig: runtime.TypedQueryConfig<T, Array<CoveringMare>> = {}): QueryConfig<T> {
    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.namequery !== undefined) {
        queryParameters['namequery'] = requestParameters.namequery;
    }


    if (requestParameters.limitenabled !== undefined) {
        queryParameters['limitenabled'] = requestParameters.limitenabled;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlists/mares`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(CoveringMareFromJSON), text);
    }

    return config;
}

/**
* Get mares - possible to filter by name query (if not provided ALL mares will be returned). Support for limiting (enabled by default), since the number of mares might be very large
*/
export function getAllMaresByName<T>(requestParameters: GetAllMaresByNameRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<CoveringMare>>): QueryConfig<T> {
    return getAllMaresByNameRaw(requestParameters, requestConfig);
}

/**
 * Get stud manager studs for a covering year. If no year is provided, the current year is used as default
 */
function getStudsRaw<T>(requestParameters: GetStudsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<CoveringListStud>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getStuds.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.year !== undefined) {
        queryParameters['year'] = requestParameters.year;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlists/studs/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(CoveringListStudFromJSON), text);
    }

    return config;
}

/**
* Get stud manager studs for a covering year. If no year is provided, the current year is used as default
*/
export function getStuds<T>(requestParameters: GetStudsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<CoveringListStud>>): QueryConfig<T> {
    return getStudsRaw(requestParameters, requestConfig);
}


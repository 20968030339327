// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    CoveringMethod,
    CoveringMethodFromJSON,
    CoveringMethodToJSON,
    Mare,
    MareFromJSON,
    MareToJSON,
} from './';

/**
 * 
 * @export
 * @interface Covering
 */
export interface Covering  {
    /**
     * 
     * @type {number}
     * @memberof Covering
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Covering
     */
    coveringDates: Array<string>;
    /**
     * 
     * @type {Mare}
     * @memberof Covering
     */
    mare: Mare;
    /**
     * 
     * @type {boolean}
     * @memberof Covering
     */
    pregnant?: boolean;
    /**
     * 
     * @type {CoveringMethod}
     * @memberof Covering
     */
    coveringMethod?: CoveringMethod;
}

export function CoveringFromJSON(json: any): Covering {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'coveringDates': json['coveringDates'],
        'mare': MareFromJSON(json['mare']),
        'pregnant': !exists(json, 'pregnant') ? undefined : json['pregnant'],
        'coveringMethod': !exists(json, 'coveringMethod') ? undefined : CoveringMethodFromJSON(json['coveringMethod']),
    };
}

export function CoveringToJSON(value?: Covering): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'coveringDates': value.coveringDates,
        'mare': MareToJSON(value.mare),
        'pregnant': value.pregnant,
        'coveringMethod': CoveringMethodToJSON(value.coveringMethod),
    };
}



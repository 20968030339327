import { useGetDriversRidingWeight } from '@apis';
import { Driver } from '@generated/propositions/src';
import Error from '@primitives/Error';
import React from 'react';
import { CellProps } from 'react-table';

import { useDriversContext } from './SelectedDrivers';

const DriverWeight = ({ row }: CellProps<Driver>): JSX.Element => {
  const { propositionId, propositionWeight } = useDriversContext();

  const { data, loading, status, error } = useGetDriversRidingWeight(
    propositionId,
    row.original.id,
    propositionWeight,
  );

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return null;
  }

  return <>{data}</>;
};

export default React.memo(DriverWeight);

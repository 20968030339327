import CardWithHeader from '@components/CardWithHeader';
import Content from '@components/ContentBlock/Content';
import withUser from '@hoc/withUser';
import roleEnum from '@utils/roleEnum';
import React from 'react';

function EntryCards(): JSX.Element {
  return (
    <CardWithHeader header="För aktiva">
      <Content slug="faq-arskort" />
    </CardWithHeader>
  );
}

export default withUser(EntryCards, {
  roles: [roleEnum.Entrekort],
  showError: false,
  showLoader: false,
});

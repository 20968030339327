import theme from '@main/theme';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import Icon from '@primitives/Icon';
import Loading from '@primitives/Loading';
import Modal, { Props as ModalProps } from '@primitives/Modal';
import React, { ReactNode } from 'react';
import { StyledIcon } from 'styled-icons/types';

import Footer from './Footer';
import Header from './Header';

type IconParameters = Parameters<typeof Icon>;

export interface Props extends ModalProps {
  action?: string;
  cancel?: string;
  colorScheme?: 'primary' | 'delete';
  description?: ReactNode;
  error?: string;
  icon?: StyledIcon;
  iconProps?: IconParameters[0];
  loading?: boolean;
  onAction?: () => void | Promise<void>;
  showFooter?: boolean;
  showHeader?: boolean;
  showBorder?: boolean;
  size: 'small' | 'medium';
  title?: string;
  wideMobileButtons?: boolean;
  wideButtons?: boolean;
  disabled?: boolean;
}

const schemeColor = {
  primary: 'deep-blue',
  delete: 'red',
};

const sizes = {
  small: 280,
  medium: 546,
};

export default function Alert({
  action,
  cancel,
  children,
  closeModal,
  colorScheme,
  description = '',
  error,
  icon,
  iconProps,
  loading,
  wideMobileButtons = false,
  wideButtons = false,
  onAction,
  open,
  showFooter = true,
  showHeader = true,
  showBorder = true,
  size,
  title,
  disabled,
  ...props
}: Props): JSX.Element {
  return (
    <Modal
      open={open}
      closeModal={closeModal}
      style={{
        content: {
          maxWidth: `${sizes[size]}px`,
          borderTop: showBorder
            ? `5px solid ${theme.colors[schemeColor[colorScheme]]}`
            : 'none',
        },
      }}
      {...props}
    >
      {error && (
        <Box px={4} pt={4} pb={0}>
          <Error>{error}</Error>
        </Box>
      )}
      {loading && (
        <Box padding={4}>
          <Loading />
        </Box>
      )}
      {!loading && (
        <Box>
          {showHeader && (
            <Header
              colorScheme={colorScheme}
              description={description}
              icon={icon}
              iconProps={iconProps}
              size={size}
              title={title}
            />
          )}
          {children}
          {showFooter && (
            <Footer
              action={action}
              cancel={cancel}
              closeModal={closeModal}
              colorScheme={colorScheme}
              onAction={onAction}
              wideMobileButtons={wideMobileButtons}
              wideButtons={wideButtons}
              disabled={disabled}
            />
          )}
        </Box>
      )}
    </Modal>
  );
}

import { useGetEnrolledRaceDays } from '@apis';
import ContentBlock from '@components/ContentBlock';
import { EmptyState } from '@components/InfoBlock';
import { AuthenticatedUser } from '@generated/account/src';
import theme from '@main/theme';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import { ActionLink } from '@primitives/Link';
import Loading from '@primitives/Loading';
import { Text } from '@primitives/Typography';
import { formatTz } from '@utils/dateUtils';
import React from 'react';

import RefetchTimer from './utils/RefreshTimer';

interface Props {
  user: AuthenticatedUser;
}

function RaceDaysList({ user }: Props): JSX.Element {
  const { data, loading, status, error, refresh } = useGetEnrolledRaceDays(
    user.licenseId,
  );

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <>
      {data.length > 0 ? (
        <>
          <ContentBlock
            justifyContent="flex-start"
            slug="tranare-startanmalan-tavlingsdagar"
            alignment="left"
          />
          <Box mt={4} borderTop={`1px solid ${theme.colors.gray3}`} />
          {data.map(item => (
            <Box
              key={item.raceDayId}
              borderBottom={`1px solid ${theme.colors.gray3}`}
              p={2}
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              sx={{
                lineHeight: '21px',
              }}
            >
              <Box mt="auto" mb="auto">
                <Text>
                  <b>{item.track.name}</b> {formatTz(item.raceDate, 'yyMMdd')}
                </Text>
              </Box>
              <Box
                display="flex"
                pt={[1, null, 0]}
                width={['100%', null, '70%']}
              >
                <ActionLink
                  to={`/minasidor/startanmalan/tavlingsdagar/${item.raceDayId}`}
                >
                  Startanmäl senast{' '}
                  {formatTz(item.startRegistrationEndDate, 'yyMMdd HH:mm')}
                </ActionLink>

                <RefetchTimer
                  refresh={refresh}
                  startRegistrationEnd={item.startRegistrationEndDate.getTime()}
                />
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <EmptyState content="Det finns inga tävlingsdagar att startanmäla till." />
      )}
    </>
  );
}

export default RaceDaysList;

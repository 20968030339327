import auth from '@main/auth';
import { createRequest } from '@main/networkInterface';
import { HttpMethods, httpMethods, NetworkOptions } from 'redux-query';
import superagent from 'superagent';

import { apiTypes, getBaseUrl } from './utils/setBaseUrl';

export interface RequestProps {
  url: string;
  method?: HttpMethods;
  body?: NetworkOptions['body'];
  api?: apiTypes;
}

export interface RequestResponse<T = superagent.Response['body']>
  extends superagent.Response {
  body: T;
}

export const request = async <T>({
  url,
  method = httpMethods.GET,
  body,
  api = 'webapi',
}: RequestProps): Promise<RequestResponse<T>> => {
  await auth.isRefreshing;

  const request = createRequest(getBaseUrl(api) + url, method, body);

  request.set({
    Authorization: auth.token || null,
  });

  return request.ok(res => res.status < 500);
};

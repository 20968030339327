import 'styled-components';

import { useGetRacesWithEnrolledHorses } from '@apis';
import Content from '@components/ContentBlock/Content';
import Table from '@components/Table';
import { StakeRaceEnrolled } from '@generated/propositions/src';
import useNumericQueryParams from '@hooks/useNumericQueryParams';
import useUser from '@hooks/useUser';
import Box from '@primitives/Box';
import { ButtonLink } from '@primitives/Button';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import { formatTz } from '@utils/dateUtils';
import React from 'react';
import { CellProps } from 'react-table';

import Expanded from './Expanded';
import ListItem from './ListItem';

export default function ScratchFromStakeRaces(): JSX.Element {
  const user = useUser();
  const { year, horseid } = useNumericQueryParams();

  const { data, loading, status, error } = useGetRacesWithEnrolledHorses(
    user.licenseId,
    year,
    horseid,
  );

  if (loading) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return <Error>{error}</Error>;
  }

  return (
    <>
      <Content
        as="div"
        color="dim-gray"
        slug="mina-insatslopp-anmalda-till-insatslopp"
      />

      <Table
        pt={4}
        columns={[
          {
            Header: 'Lopp',
            accessor: 'propositionName',
            Cell: ({ value, row }: CellProps<StakeRaceEnrolled, string>) => (
              <ButtonLink
                fontWeight="bold"
                onClick={() => row.toggleRowExpanded()}
              >
                {value}
              </ButtonLink>
            ),
          },
          {
            Header: 'Bana',
            accessor: v => v.track.name,
            id: 'track',
          },
          {
            Header: 'Aktuell insats',
            accessor: 'stakeNumber',
            alignment: 'right',
          },
          {
            Header: 'Nästa insatstermin',
            accessor: 'invoiceExpirationDate',
            Cell: ({ value }) => <>{formatTz(value, 'yyyy-MM-dd')}</>,
          },
        ]}
        expandableBackground="snow"
        data={data}
        renderListItem={({ row }) => <ListItem row={row.original} />}
        renderRowSubComponent={({ row }) => (
          <Box>
            <Expanded propositionId={row.original.propositionId} />
          </Box>
        )}
      />
    </>
  );
}

import {
  Breeding,
  getBreedings,
  GetBreedingsRegistryEnum,
} from '@generated/horses/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type HorseBreedings = Breeding[] | undefined;

export interface Entities {
  horseBreeding?: {
    [key: string]: HorseBreedings;
  };
}

const useGetHorseBreedings = (
  horseId: number,
  registry: GetBreedingsRegistryEnum,
): ApiResponse<HorseBreedings, Entities> => {
  const key = `${horseId}-${registry}`;
  const query = getBreedings<Entities>(
    {
      horseId,
      registry,
    },
    {
      transform: responseBody => ({
        horseBreeding: {
          [key]: responseBody,
        },
      }),
      update: {
        horseBreeding: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  const selector = (state: State): HorseBreedings =>
    state.entities?.horseBreeding?.[key];

  return useApi<HorseBreedings>({
    query,
    selector,
  });
};

export default useGetHorseBreedings;

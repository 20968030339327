import config from '@config';
import { getNews, NewsResource } from '@generated/content/src';

import { ApiResponse } from './utils/types';
import useApi from './utils/useApi';

type Content = NewsResource[] | undefined;

export interface Entities {
  news?: Content;
}

const useGetNews = (): ApiResponse<Content, Entities> => {
  const base = getNews<Entities>({
    transform: responseBody => ({
      news: responseBody.items,
    }),
    update: {
      news: (prev, next): typeof prev => next,
    },
    force: true,
  });

  return useApi({
    query: {
      ...base,
      url: config.isProduction
        ? base.url
        : base.url.replace('/api/', '/api-test/'),
    },
    selector: state => state.entities.news,
    basePath: 'contentapi',
  });
};

export default useGetNews;

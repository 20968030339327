// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Horse,
    HorseFromJSON,
    HorseToJSON,
} from './';

/**
 * 
 * @export
 * @interface NegativeDopingResult
 */
export interface NegativeDopingResult  {
    /**
     * 
     * @type {Date}
     * @memberof NegativeDopingResult
     */
    testDate: Date;
    /**
     * 
     * @type {number}
     * @memberof NegativeDopingResult
     */
    trackId: number;
    /**
     * 
     * @type {string}
     * @memberof NegativeDopingResult
     */
    trackName: string;
    /**
     * 
     * @type {string}
     * @memberof NegativeDopingResult
     */
    trackCode: string;
    /**
     * 
     * @type {Array<Horse>}
     * @memberof NegativeDopingResult
     */
    horses: Array<Horse>;
}

export function NegativeDopingResultFromJSON(json: any): NegativeDopingResult {
    return {
        'testDate': parseDateTime(json['testDate']),
        'trackId': json['trackId'],
        'trackName': json['trackName'],
        'trackCode': json['trackCode'],
        'horses': (json['horses'] as Array<any>).map(HorseFromJSON),
    };
}

export function NegativeDopingResultToJSON(value?: NegativeDopingResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'testDate': value.testDate.toLocalDate(),
        'trackId': value.trackId,
        'trackName': value.trackName,
        'trackCode': value.trackCode,
        'horses': (value.horses as Array<any>).map(HorseToJSON),
    };
}



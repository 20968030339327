import PrimitiveRadio, {
  Props as PrimitiveRadioProps,
} from '@primitives/Form/radio';
import { FieldHookConfig, useField } from 'formik';
import React, { InputHTMLAttributes } from 'react';

type Props = {
  label?: string;
} & PrimitiveRadioProps &
  FieldHookConfig<string> &
  InputHTMLAttributes<HTMLInputElement>;

export default function Radio({ label, ...props }: Props): JSX.Element {
  const [field, meta] = useField<string>({
    type: 'radio',
    ...props,
  });

  const inputProps = {
    ...props,
    ...field,
    placeholder: props?.placeholder,
    disabled: props?.disabled,
    className: meta.touched && meta.error ? 'errorInput' : undefined,
  };

  return <PrimitiveRadio label={label} {...inputProps} />;
}

// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    LinkableHorse,
    LinkableHorseFromJSON,
    LinkableHorseToJSON,
    LinkableLicenseHolder,
    LinkableLicenseHolderFromJSON,
    LinkableLicenseHolderToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnrolledHorse
 */
export interface EnrolledHorse  {
    /**
     * 
     * @type {number}
     * @memberof EnrolledHorse
     */
    totalPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof EnrolledHorse
     */
    latestPoints?: number;
    /**
     * 
     * @type {string}
     * @memberof EnrolledHorse
     */
    propositionWeight?: string;
    /**
     * 
     * @type {number}
     * @memberof EnrolledHorse
     */
    handicap?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnrolledHorse
     */
    remarks?: Array<string>;
    /**
     * 
     * @type {LinkableHorse}
     * @memberof EnrolledHorse
     */
    horse: LinkableHorse;
    /**
     * 
     * @type {LinkableLicenseHolder}
     * @memberof EnrolledHorse
     */
    trainer: LinkableLicenseHolder;
}

export function EnrolledHorseFromJSON(json: any): EnrolledHorse {
    return {
        'totalPoints': !exists(json, 'totalPoints') ? undefined : json['totalPoints'],
        'latestPoints': !exists(json, 'latestPoints') ? undefined : json['latestPoints'],
        'propositionWeight': !exists(json, 'propositionWeight') ? undefined : json['propositionWeight'],
        'handicap': !exists(json, 'handicap') ? undefined : json['handicap'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'horse': LinkableHorseFromJSON(json['horse']),
        'trainer': LinkableLicenseHolderFromJSON(json['trainer']),
    };
}

export function EnrolledHorseToJSON(value?: EnrolledHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'totalPoints': value.totalPoints,
        'latestPoints': value.latestPoints,
        'propositionWeight': value.propositionWeight,
        'handicap': value.handicap,
        'remarks': value.remarks,
        'horse': LinkableHorseToJSON(value.horse),
        'trainer': LinkableLicenseHolderToJSON(value.trainer),
    };
}



// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface DriverTravelFee
 */
export interface DriverTravelFee  {
    /**
     * 
     * @type {number}
     * @memberof DriverTravelFee
     */
    licenseId: number;
    /**
     * 
     * @type {string}
     * @memberof DriverTravelFee
     */
    trackId: string;
    /**
     * 
     * @type {string}
     * @memberof DriverTravelFee
     */
    trackCode: string;
    /**
     * 
     * @type {string}
     * @memberof DriverTravelFee
     */
    trackName: string;
    /**
     * The travel fee amount
     * @type {number}
     * @memberof DriverTravelFee
     */
    amount: number;
}

export function DriverTravelFeeFromJSON(json: any): DriverTravelFee {
    return {
        'licenseId': json['licenseId'],
        'trackId': json['trackId'],
        'trackCode': json['trackCode'],
        'trackName': json['trackName'],
        'amount': json['amount'],
    };
}

export function DriverTravelFeeToJSON(value?: DriverTravelFee): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseId': value.licenseId,
        'trackId': value.trackId,
        'trackCode': value.trackCode,
        'trackName': value.trackName,
        'amount': value.amount,
    };
}



// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    SelectionOption,
    SelectionOptionFromJSON,
    SelectionOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Selection
 */
export interface Selection  {
    /**
     * 
     * @type {string}
     * @memberof Selection
     */
    defaultParameter?: string;
    /**
     * 
     * @type {Array<SelectionOption>}
     * @memberof Selection
     */
    selectionOptions: Array<SelectionOption>;
}

export function SelectionFromJSON(json: any): Selection {
    return {
        'defaultParameter': !exists(json, 'defaultParameter') ? undefined : json['defaultParameter'],
        'selectionOptions': (json['selectionOptions'] as Array<any>).map(SelectionOptionFromJSON),
    };
}

export function SelectionToJSON(value?: Selection): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'defaultParameter': value.defaultParameter,
        'selectionOptions': (value.selectionOptions as Array<any>).map(SelectionOptionToJSON),
    };
}



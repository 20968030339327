import { useGetTerminableLeases } from '@apis';
import Content from '@components/ContentBlock/Content';
import FadedContent from '@components/ContentBlock/Faded';
import InfoBadge from '@components/InfoBadge';
import Step from '@components/Step';
import { AuthenticatedUser } from '@generated/account/src';
import Box, { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import { Input } from '@primitives/Form';
import { BackLink } from '@primitives/Link';
import Pulse from '@primitives/Loading';
import Message from '@primitives/Message';
import { H2, Label, Small } from '@primitives/Typography';
import { CircleInfo as InfoCircle } from '@styled-icons/fa-solid';
import { formatTz } from '@utils/dateUtils';
import { endOfToday, startOfDay } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { OngoingHorseInfo } from '../../OwnershipTransfers/TransferHorseInfo';
import CancelLeasingConfirm from './CancelLeasingConfirm';

interface Props {
  user: AuthenticatedUser;
}

const CancelLeasingForm = ({ user }: Props): JSX.Element => {
  const { horseId } = useParams<{ horseId: string }>();
  const { data, loading, error } = useGetTerminableLeases(user.licenseId);
  const [date, setDate] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const history = useHistory();

  const onSubmit = useCallback(() => {
    setShowConfirm(true);
  }, []);

  if (loading) {
    return <Pulse />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  const transfer = data.find(item => item.horse.horseId === parseInt(horseId));

  const horse = transfer?.horse;

  const selectedDate = date ? startOfDay(new Date(date)) : null;

  const earliestTransfer = startOfDay(horse.earliestTransferDate);

  const dateIsValid =
    selectedDate &&
    selectedDate >= earliestTransfer &&
    selectedDate < endOfToday();

  const formIsValid = horse && dateIsValid;

  return (
    <>
      {showConfirm && (
        <CancelLeasingConfirm
          endDate={date}
          transfer={transfer}
          user={user}
          onClose={() => {
            setShowConfirm(false);
          }}
        />
      )}
      <Box verticalSpacing={5}>
        <Box>
          <BackLink to="/minasidor/leasing/avsluta">Byt häst</BackLink>
        </Box>
        <FadedContent slug="leasing-sag-upp-leasing-ange-uppgifter" />
        <Box verticalSpacing={3}>
          <H2>Ange datum för uppsägning av leasingavtal</H2>
          <OngoingHorseInfo
            item={transfer}
            isLeasing
            isTermination
            hideExpiryDate
          />
        </Box>
        <Step header="1. När upphörde leasingen?">
          <Small color="dim-gray">
            <Content slug="leasing-sag-upp-leasing-datum" />
          </Small>
          <Flex justifyContent="flex-start" mt={4}>
            <Label fontSize="small" mr={4}>
              Ange datum
            </Label>
            <Input
              type="date"
              name="date"
              onChange={e => {
                const { value } = e.target;
                setDate(value);
              }}
              value={date}
              pattern="\d{4}-\d{2}-\d{2}"
              width="auto"
              min={formatTz(horse.earliestTransferDate, 'yyyy-MM-dd')}
              max={formatTz(new Date(), 'yyyy-MM-dd')}
            />
          </Flex>
          {selectedDate && selectedDate < earliestTransfer && (
            <Message variant="errorBox" mt={3}>
              Vänligen ange ett senare uppsägningsdatum.
            </Message>
          )}

          {selectedDate && selectedDate > endOfToday() && (
            <Message variant="errorBox" mt={3}>
              Det är inte tillåtet att avsluta leasing i framtiden.
            </Message>
          )}
        </Step>
        <InfoBadge
          message="Observera att häst anses överlåten med de tävlingsengagemang som hästen hade vid överlåtelsetillfället. Förhyraren är dock skyldig att upplysa ägaren om vilka tävlingsengagemang hästen har vid tillfället för uppsägningen av förhyrningen. Vid underlåtelse att skriftligen upplysa ägaren kommer eventuella kostnader att debiteras den tidigare förhyraren."
          color="blue"
          icon={InfoCircle}
        />
        <Box
          horizontalSpacing={[0, 0, 2]}
          justifyContent="flex-start"
          verticalSpacing={[3, , 0]}
          flexDirection={['column', , 'row']}
        >
          <Button
            width={[1, , 'auto']}
            onClick={() => {
              history.push('/minasidor/leasing/avsluta');
            }}
            colorScheme="secondary"
          >
            Avbryt
          </Button>
          <Button
            width={[1, , 'auto']}
            onClick={onSubmit}
            disabled={!formIsValid}
            colorScheme="save"
          >
            Bekräfta uppsägning
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CancelLeasingForm;

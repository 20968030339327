// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Phonenumber,
    PhonenumberFromJSON,
    PhonenumberToJSON,
} from './';

/**
 * Contact details
 * @export
 * @interface LicenseHolderBasicContactInfo
 */
export interface LicenseHolderBasicContactInfo  {
    /**
     * Address to the license holders homepage.
     * @type {string}
     * @memberof LicenseHolderBasicContactInfo
     */
    homePage?: string;
    /**
     * The license holders email.
     * @type {string}
     * @memberof LicenseHolderBasicContactInfo
     */
    email?: string;
    /**
     * A list of the license holders phonenumbers
     * @type {Array<Phonenumber>}
     * @memberof LicenseHolderBasicContactInfo
     */
    phones?: Array<Phonenumber>;
}

export function LicenseHolderBasicContactInfoFromJSON(json: any): LicenseHolderBasicContactInfo {
    return {
        'homePage': !exists(json, 'homePage') ? undefined : json['homePage'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phones': !exists(json, 'phones') ? undefined : (json['phones'] as Array<any>).map(PhonenumberFromJSON),
    };
}

export function LicenseHolderBasicContactInfoToJSON(value?: LicenseHolderBasicContactInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'homePage': value.homePage,
        'email': value.email,
        'phones': value.phones === undefined ? undefined : (value.phones as Array<any>).map(PhonenumberToJSON),
    };
}



import {
  declareHorseForStart,
  DeclareHorseForStartRequest,
  StartDeclaration,
  StartDeclarationToJSON,
} from '@generated/propositions/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

export interface Entities {
  declarationReceipt?: StartDeclaration;
}

type ActionParams = DeclareHorseForStartRequest['startDeclaration'];

const useDeclareHorseForStart = (
  propositionId: number,
  trainerId: number,
  resetKeys: string[],
): MutationResponse<Entities['declarationReceipt'], Entities, ActionParams> => {
  const queryFunc = (startDeclaration: ActionParams): QueryConfig<Entities> => {
    const query = declareHorseForStart({
      propositionId,
      trainerId,
      startDeclaration,
    });

    return { ...query, body: StartDeclarationToJSON(startDeclaration) };
  };
  return useApiMutate<Entities['declarationReceipt'], ActionParams>({
    queryFunc,
    selector: (state: State) => state.entities.declarationReceipt,
    queryKey: 'declareHorseForStart',
    resetKeys,
  });
};

export default useDeclareHorseForStart;

import {
  deleteDriverTravelFee,
  DeleteDriverTravelFeeRequest,
  ErrorResponse,
} from '@generated/tds/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

export interface Entities {
  deleteDriverTravelFee?: ErrorResponse;
}

type ActionParams = DeleteDriverTravelFeeRequest;

interface Props {
  resetKeys: string[];
}

const useDeleteDriverTravelFee = ({
  resetKeys,
}: Props): MutationResponse<
  Entities['deleteDriverTravelFee'],
  Entities,
  ActionParams
> => {
  const queryFunc = (params: ActionParams): QueryConfig<Entities> =>
    deleteDriverTravelFee(params);
  return useApiMutate<Entities['deleteDriverTravelFee'], ActionParams>({
    queryFunc,
    selector: (state: State) => state.entities.deleteDriverTravelFee,
    queryKey: 'deleteDriverTravelFee',
    resetKeys,
  });
};

export default useDeleteDriverTravelFee;

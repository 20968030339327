// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface OwnershipsHorsesSummary
 */
export interface OwnershipsHorsesSummary  {
    /**
     * 
     * @type {number}
     * @memberof OwnershipsHorsesSummary
     */
    ownerId: number;
    /**
     * 
     * @type {number}
     * @memberof OwnershipsHorsesSummary
     */
    numberOfOwnedHorses: number;
}

export function OwnershipsHorsesSummaryFromJSON(json: any): OwnershipsHorsesSummary {
    return {
        'ownerId': json['ownerId'],
        'numberOfOwnedHorses': json['numberOfOwnedHorses'],
    };
}

export function OwnershipsHorsesSummaryToJSON(value?: OwnershipsHorsesSummary): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'ownerId': value.ownerId,
        'numberOfOwnedHorses': value.numberOfOwnedHorses,
    };
}



import {
  Address,
  BasicUserInformation,
  TDSInformation,
} from '@generated/user/src';
import React from 'react';

import InformationEntry from './InformationEntry';

const labelsMap = {
  licenseId: 'Licensnummer',
  idNumber: 'Personnummer',
  homePhoneNumber: 'Telefon bostad',
  mobilePhoneNumber: 'Mobiltelefon',
  stablePhoneNumber: 'Telefon stall',
  supplierName: 'Leverantör',
  organisationNumber: 'Org.n',
  supplierId: 'Leverantörs-id',
  contactNumber: 'Kontakttelefon',
} as const;

export const translateKey = (
  key: keyof BasicUserInformation | string | keyof TDSInformation,
): string => labelsMap?.[key] || key;

export const translate = (
  key: keyof BasicUserInformation | keyof TDSInformation | string,
  value:
    | BasicUserInformation[keyof BasicUserInformation]
    | TDSInformation[keyof TDSInformation],
): JSX.Element | null => {
  switch (key) {
    case 'address':
      // eslint-disable-next-line no-case-declarations
      const { streetAddress, postAddress } = value as Address;
      return (
        <InformationEntry
          key="address"
          label="Adress"
          value={`${streetAddress}, ${postAddress}`}
        />
      );
    case 'licenseValidTo':
    case 'firstName':
    case 'doctorsCertificate':
    case 'lastName':
    case 'inAdvancePercentage':
    case 'licenseType':
      return null;
    default:
      return (
        <InformationEntry
          key={key + value}
          label={translateKey(key)}
          value={value}
        />
      );
  }
};

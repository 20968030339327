import {
  createSelector,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

import { State } from '../store';

export interface AppState {
  searchOpen: boolean;
}

const slice = createSlice<AppState, SliceCaseReducers<AppState>>({
  name: 'app',
  initialState: {
    searchOpen: false,
  },
  reducers: {
    toggleSearchForm(state: AppState, action: PayloadAction<boolean>): void {
      state.searchOpen = action.payload;
    },
  },
});

const stateSelector = (state: State): AppState => state.app;

export const selectSearchFormOpen = createSelector(
  stateSelector,
  state => state.searchOpen,
);

export const { toggleSearchForm } = slice.actions;

export default slice.reducer;

import useQuery from './useQuery';

interface NumericParams {
  [key: string]: number;
}

export default function useNumericQueryParams(): NumericParams {
  const queryParams = useQuery(true);
  const numeric: NumericParams = {};

  Object.keys(queryParams).forEach(key => {
    if (queryParams[key] && +queryParams[key]) {
      numeric[key] = +queryParams[key];
    }
  });

  return numeric;
}

// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Deceased horses, populated for search category: STAMBOK_AVGAENDE
 * @export
 * @interface DeceasedHorse
 */
export interface DeceasedHorse  {
    /**
     * 
     * @type {number}
     * @memberof DeceasedHorse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DeceasedHorse
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof DeceasedHorse
     */
    birthYear?: number;
    /**
     * 
     * @type {number}
     * @memberof DeceasedHorse
     */
    deathYear?: number;
}

export function DeceasedHorseFromJSON(json: any): DeceasedHorse {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'birthYear': !exists(json, 'birthYear') ? undefined : json['birthYear'],
        'deathYear': !exists(json, 'deathYear') ? undefined : json['deathYear'],
    };
}

export function DeceasedHorseToJSON(value?: DeceasedHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'birthYear': value.birthYear,
        'deathYear': value.deathYear,
    };
}



// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Information about the user\'s explicit share of the invoiced transactions for an ownership. Note, this is an optional field that\'s NOT populated when it\'s the user\'s own, personal, transactions, when the user is an owner via a Company or if the ownership lacks a proper shares distribution etc.
 * @export
 * @interface ExplicitUserShare
 */
export interface ExplicitUserShare  {
    /**
     * 
     * @type {number}
     * @memberof ExplicitUserShare
     */
    shares?: number;
    /**
     * 
     * @type {number}
     * @memberof ExplicitUserShare
     */
    ownershipShares?: number;
    /**
     * 
     * @type {number}
     * @memberof ExplicitUserShare
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof ExplicitUserShare
     */
    vat?: number;
    /**
     * 
     * @type {number}
     * @memberof ExplicitUserShare
     */
    totalAmount?: number;
}

export function ExplicitUserShareFromJSON(json: any): ExplicitUserShare {
    return {
        'shares': !exists(json, 'shares') ? undefined : json['shares'],
        'ownershipShares': !exists(json, 'ownershipShares') ? undefined : json['ownershipShares'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'totalAmount': !exists(json, 'totalAmount') ? undefined : json['totalAmount'],
    };
}

export function ExplicitUserShareToJSON(value?: ExplicitUserShare): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'shares': value.shares,
        'ownershipShares': value.ownershipShares,
        'amount': value.amount,
        'vat': value.vat,
        'totalAmount': value.totalAmount,
    };
}



// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface BasicOwner
 */
export interface BasicOwner  {
    /**
     * 
     * @type {number}
     * @memberof BasicOwner
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof BasicOwner
     */
    linkable: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasicOwner
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BasicOwner
     */
    ownershipForm: string;
    /**
     * 
     * @type {string}
     * @memberof BasicOwner
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof BasicOwner
     */
    headerLeasing?: string;
    /**
     * ONLY present for GALLOP
     * @type {string}
     * @memberof BasicOwner
     */
    colors?: string;
}

export function BasicOwnerFromJSON(json: any): BasicOwner {
    return {
        'id': json['id'],
        'linkable': json['linkable'],
        'name': json['name'],
        'ownershipForm': json['ownershipForm'],
        'header': json['header'],
        'headerLeasing': !exists(json, 'headerLeasing') ? undefined : json['headerLeasing'],
        'colors': !exists(json, 'colors') ? undefined : json['colors'],
    };
}

export function BasicOwnerToJSON(value?: BasicOwner): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'linkable': value.linkable,
        'name': value.name,
        'ownershipForm': value.ownershipForm,
        'header': value.header,
        'headerLeasing': value.headerLeasing,
        'colors': value.colors,
    };
}



import ConditionalWrapper from '@components/ConditionalWrapper';
import useIsMobile from '@hooks/useIsMobile';
import theme from '@main/theme';
import { ArrowLeftSVG, ArrowRightSVG } from '@primitives/Arrow';
import Box from '@primitives/Box';
import Icon from '@primitives/Icon';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Header from './Header';

const ACTIVE_COLOR = 'blue';
const INACTIVE_COLOR = 'gray3';

interface LinkInterface {
  height: number;
}

const Prev = styled(Box)`
  display: inline-block;
  color: ${props => props.theme.colors[props.color]};
  font-size: ${props => props.theme.fontSizes.text}px;
`;

const Next = styled(Prev)``;

const StyledLinkMobile = styled(Link)<LinkInterface>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  background-color: ${props => props.theme.colors.gray1};
  border: 1px solid ${props => props.theme.colors.gray2};
`;

const StyledLinkDesktop = styled(Link)<LinkInterface>`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.gray1};
  height: ${props => props.height - 2}px;
`;

const WrapperInactiveMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  background-color: ${props => props.theme.colors.gray1};
  border: 1px solid ${props => props.theme.colors.gray2};
`;

const WrapperInactiveDesktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type BoxParameters = Parameters<typeof Box>;

type Props = {
  nextUrl?: string;
  prevUrl?: string;
  header: string;
  date?: Date;
} & BoxParameters[0];

export default function PrevNextMenu({
  header,
  date,
  nextUrl,
  prevUrl,
  ...props
}: Props): JSX.Element {
  const isMobile = useIsMobile();
  const hasPrev = Boolean(prevUrl);
  const hasNext = Boolean(nextUrl);
  const height = 44;
  const LinkComponent = isMobile ? StyledLinkMobile : StyledLinkDesktop;
  const WrapperInactive = isMobile
    ? WrapperInactiveMobile
    : WrapperInactiveDesktop;

  return (
    <Box
      className="hidden-print"
      border={[null, null, `1px solid ${theme.colors.gray2}`]}
      backgroundColor={['inherit', null, 'gray1']}
      height={height}
      {...props}
    >
      <Box display="flex" justifyContent="space-between" position="relative">
        <ConditionalWrapper
          condition={hasPrev}
          wrapperTrue={children => (
            <LinkComponent height={height} to={prevUrl}>
              {children}
            </LinkComponent>
          )}
          wrapperFalse={children => (
            <WrapperInactive>{children}</WrapperInactive>
          )}
        >
          <Icon
            ml={[0, null, 2]}
            mr={'5px'}
            size={16}
            color={hasPrev ? ACTIVE_COLOR : INACTIVE_COLOR}
            as={ArrowLeftSVG}
          />
          <Prev color={hasPrev ? ACTIVE_COLOR : INACTIVE_COLOR}>
            {/*eslint-disable-next-line*/}
            {!isMobile && <>Föregående</>}
          </Prev>
        </ConditionalWrapper>

        <Header date={date} header={header} height={height} mobile={isMobile} />

        <ConditionalWrapper
          condition={hasNext}
          wrapperTrue={children => (
            <LinkComponent height={height} to={nextUrl}>
              {children}
            </LinkComponent>
          )}
          wrapperFalse={children => (
            <WrapperInactive>{children}</WrapperInactive>
          )}
        >
          <Next color={hasNext ? ACTIVE_COLOR : INACTIVE_COLOR}>
            {/*eslint-disable-next-line*/}
            {!isMobile && <>Nästa</>}
          </Next>
          <Icon
            mr={[0, null, 2]}
            ml={'5px'}
            size={16}
            color={hasNext ? ACTIVE_COLOR : INACTIVE_COLOR}
            as={ArrowRightSVG}
          />
        </ConditionalWrapper>
      </Box>
    </Box>
  );
}

import { useGetTracks } from '@apis';
import useParamValues from '@components/Calendar/utils/useParamValues';
import Card from '@components/Card';
import FormLayout, { FormItem } from '@components/FormLayout';
import FormToggle from '@components/FormToggle';
import Box from '@primitives/Box';
import Select, { Option } from '@primitives/Form/select';
import Switch from '@primitives/Switch';
import getYears from '@utils/getYears';
import { months } from '@utils/numToMonth';
import qs from 'qs';
import React, { useCallback, useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';

import Navigation from './Navigation';

interface Props {
  isMobile: boolean;
  showTrackSelect?: boolean;
  showToggleAll?: boolean;
}

function TrackOptions(): JSX.Element {
  const { data } = useGetTracks(false);

  return (
    <>
      <Option value="alla">Alla banor</Option>
      {data?.map(item => (
        <Option key={item.code} value={item.code}>
          {item.name}
        </Option>
      ))}
    </>
  );
}

export default function Form({
  isMobile,
  showTrackSelect = false,
  showToggleAll = false,
}: Props): JSX.Element {
  const params = useParamValues();
  const { path } = useRouteMatch();
  const history = useHistory();
  const years = getYears();
  const [showFilters, setShowFilters] = useState(false);

  const updatePath = useCallback(
    (name: string, value: string | boolean): void => {
      const newParams = { ...params, [name]: value };
      const { year, month, track, showAll, onlyRacedays } = newParams;

      const pathname = generatePath(path, {
        year: year.toString(),
        month,
      });

      history.push({
        pathname,
        search: qs.stringify({
          track,
          showAll: showAll.toString(),
          onlyRacedays: onlyRacedays.toString(),
        }),
      });
    },
    [history, params, path],
  );

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
      const { target } = event;

      updatePath(target.name, target.value);
    },
    [updatePath],
  );

  return (
    <Card
      verticalSpacing={0}
      borderRadius={[0, null, '5px']}
      px={[0, , 2]}
      py={[0, , 2]}
      borderWidth={[0, , '1px']}
      pb="4px"
    >
      <Box display="flex" flexDirection={['column-reverse', null, 'column']}>
        <Box mt={['4px', , 0]} mb={[0, , '6px']}>
          <Navigation />
        </Box>
        <FormLayout as="form">
          {(!isMobile || showFilters) && (
            <>
              <FormItem data-no-grow variant="dense" width={[1 / 2, , 'auto']}>
                <Select
                  onChange={onChangeHandler}
                  name="year"
                  value={params.year}
                >
                  {years.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem data-no-grow variant="dense" width={[1 / 2, , 'auto']}>
                <Select
                  onChange={onChangeHandler}
                  name="month"
                  value={params.month}
                >
                  {months.map((item, index) => (
                    <Option key={item} value={index + 1}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              {showTrackSelect && (
                <FormItem
                  data-no-grow
                  variant="dense"
                  width={[1, , 'auto']}
                  mt={[2, , 0]}
                >
                  <Select
                    onChange={onChangeHandler}
                    name="track"
                    value={params.track}
                  >
                    <TrackOptions />
                  </Select>
                </FormItem>
              )}
              {showToggleAll && (
                <>
                  {!isMobile && (
                    <FormItem
                      data-no-grow
                      variant="dense"
                      width={[1, , 'auto']}
                      mt={[2, , 0]}
                    >
                      <Box flex={1} display="flex" alignItems="center">
                        <Switch
                          onChange={ev => {
                            const { checked } = ev.target;
                            updatePath('showAll', !checked);
                          }}
                          checked={!params.showAll}
                          label="Visa endast dagar med händelser"
                        />
                      </Box>
                    </FormItem>
                  )}
                  <FormItem
                    data-no-grow
                    variant="dense"
                    width={[1, , 'auto']}
                    mt={[2, , 0]}
                  >
                    <Box flex={1} display="flex" alignItems="center">
                      <Switch
                        onChange={ev => {
                          const { checked } = ev.target;
                          updatePath('onlyRacedays', checked);
                        }}
                        checked={params.onlyRacedays}
                        label="Visa endast tävlingsdagar"
                      />
                    </Box>
                  </FormItem>
                </>
              )}
            </>
          )}

          <FormToggle
            active={showFilters}
            inactivateText="Göm filter"
            activateText="Visa filter"
            setActive={setShowFilters}
          />
        </FormLayout>
      </Box>
    </Card>
  );
}

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Regulation
 */
export interface Regulation  {
    /**
     * 
     * @type {number}
     * @memberof Regulation
     */
    controlsAmount: number;
    /**
     * 
     * @type {string}
     * @memberof Regulation
     */
    controlsType: string;
    /**
     * 
     * @type {Date}
     * @memberof Regulation
     */
    controlsDate: Date;
    /**
     * 
     * @type {string}
     * @memberof Regulation
     */
    amountAccount: string;
}

export function RegulationFromJSON(json: any): Regulation {
    return {
        'controlsAmount': json['controlsAmount'],
        'controlsType': json['controlsType'],
        'controlsDate': parseDateTime(json['controlsDate']),
        'amountAccount': json['amountAccount'],
    };
}

export function RegulationToJSON(value?: Regulation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'controlsAmount': value.controlsAmount,
        'controlsType': value.controlsType,
        'controlsDate': value.controlsDate.toLocalDate(),
        'amountAccount': value.amountAccount,
    };
}



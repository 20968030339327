// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    Credentials,
    CredentialsFromJSON,
    CredentialsToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface UpdateRegistrationStatusRequest {
    horseId: number;
    status: UpdateRegistrationStatusStatusEnum;
    fromdate: Date;
    credentials: Credentials;
}


/**
 * Update horse registration status by supplying user password. Requires that the user has authenticated using password.
 */
function updateRegistrationStatusRaw<T>(requestParameters: UpdateRegistrationStatusRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling updateRegistrationStatus.');
    }

    if (requestParameters.status === null || requestParameters.status === undefined) {
        throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling updateRegistrationStatus.');
    }

    if (requestParameters.fromdate === null || requestParameters.fromdate === undefined) {
        throw new runtime.RequiredError('fromdate','Required parameter requestParameters.fromdate was null or undefined when calling updateRegistrationStatus.');
    }

    if (requestParameters.credentials === null || requestParameters.credentials === undefined) {
        throw new runtime.RequiredError('credentials','Required parameter requestParameters.credentials was null or undefined when calling updateRegistrationStatus.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.status !== undefined) {
        queryParameters['status'] = requestParameters.status;
    }


    if (requestParameters.fromdate !== undefined) {
        queryParameters['fromdate'] = (requestParameters.fromdate as any).toLocalDate();
    }

    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{horseId}/registrationstatus/credentials`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CredentialsToJSON(requestParameters.credentials),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Update horse registration status by supplying user password. Requires that the user has authenticated using password.
*/
export function updateRegistrationStatus<T>(requestParameters: UpdateRegistrationStatusRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateRegistrationStatusRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum UpdateRegistrationStatusStatusEnum {
    DEAD = 'DEAD',
    CASTRATE = 'CASTRATE',
    NO_LONGER_IN_FOOD_PRODUCTION = 'NO_LONGER_IN_FOOD_PRODUCTION'
}

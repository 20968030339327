import { useUpdateRegistrationStatus } from '@apis';
import { UpdateRegistrationStatusStatusEnum } from '@generated/horses/src';
import { useEffect, useState } from 'react';

import Task from './enum/Task';
import SetTask from './type/SetTask';

interface Props {
  horseId: number;
  status: UpdateRegistrationStatusStatusEnum;
  fromdate: Date;
  password: string;
  setTask: SetTask;
}

const UpdateStatusByPassword = ({
  horseId,
  status,
  fromdate,
  password,
  setTask,
}: Props): JSX.Element => {
  const [first, setFirst] = useState<boolean>(true);

  const { action } = useUpdateRegistrationStatus(horseId, status, fromdate, {
    password,
  });

  useEffect(() => {
    if (first) {
      const runAction = async (): Promise<void> => {
        const { body } = await action(undefined);

        if (body && body.errorMessage) {
          setTask({
            type: Task.UpdateStatusByPasswordFailure,
            value: body.errorMessage,
          });
        } else {
          setTask({
            type: Task.UpdateStatusByPasswordSuccess,
            value: 'Sparat!',
          });
        }
      };
      runAction();
      setFirst(false);
    }
  }, [action, first, horseId, setTask]);

  return null;
};

export default UpdateStatusByPassword;

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HorseOwnershipSharesDistribution,
    HorseOwnershipSharesDistributionFromJSON,
    HorseOwnershipSharesDistributionToJSON,
} from '../models';

export interface GetHorseOwnershipShareholdersDistributionRequest {
    ownerId: number;
    ownershipId: number;
}

export interface UpdateHorseOwnershipShareholdersDistributionRequest {
    ownerId: number;
    ownershipId: number;
    horseOwnershipSharesDistribution: HorseOwnershipSharesDistribution;
}


/**
 * Get ownership\'s shareholder distribution of shares.
 */
function getHorseOwnershipShareholdersDistributionRaw<T>(requestParameters: GetHorseOwnershipShareholdersDistributionRequest, requestConfig: runtime.TypedQueryConfig<T, HorseOwnershipSharesDistribution> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getHorseOwnershipShareholdersDistribution.');
    }

    if (requestParameters.ownershipId === null || requestParameters.ownershipId === undefined) {
        throw new runtime.RequiredError('ownershipId','Required parameter requestParameters.ownershipId was null or undefined when calling getHorseOwnershipShareholdersDistribution.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/{ownershipId}/shareholders`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))).replace(`{${"ownershipId"}}`, encodeURIComponent(String(requestParameters.ownershipId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(HorseOwnershipSharesDistributionFromJSON(body), text);
    }

    return config;
}

/**
* Get ownership\'s shareholder distribution of shares.
*/
export function getHorseOwnershipShareholdersDistribution<T>(requestParameters: GetHorseOwnershipShareholdersDistributionRequest, requestConfig?: runtime.TypedQueryConfig<T, HorseOwnershipSharesDistribution>): QueryConfig<T> {
    return getHorseOwnershipShareholdersDistributionRaw(requestParameters, requestConfig);
}

/**
 * Update the ownership\'s shareholder distribution. The updating owner must represent the ownership.
 */
function updateHorseOwnershipShareholdersDistributionRaw<T>(requestParameters: UpdateHorseOwnershipShareholdersDistributionRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling updateHorseOwnershipShareholdersDistribution.');
    }

    if (requestParameters.ownershipId === null || requestParameters.ownershipId === undefined) {
        throw new runtime.RequiredError('ownershipId','Required parameter requestParameters.ownershipId was null or undefined when calling updateHorseOwnershipShareholdersDistribution.');
    }

    if (requestParameters.horseOwnershipSharesDistribution === null || requestParameters.horseOwnershipSharesDistribution === undefined) {
        throw new runtime.RequiredError('horseOwnershipSharesDistribution','Required parameter requestParameters.horseOwnershipSharesDistribution was null or undefined when calling updateHorseOwnershipShareholdersDistribution.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/{ownershipId}/shareholders`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))).replace(`{${"ownershipId"}}`, encodeURIComponent(String(requestParameters.ownershipId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || HorseOwnershipSharesDistributionToJSON(requestParameters.horseOwnershipSharesDistribution),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Update the ownership\'s shareholder distribution. The updating owner must represent the ownership.
*/
export function updateHorseOwnershipShareholdersDistribution<T>(requestParameters: UpdateHorseOwnershipShareholdersDistributionRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateHorseOwnershipShareholdersDistributionRaw(requestParameters, requestConfig);
}


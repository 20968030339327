// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    Commission,
    CommissionFromJSON,
    CommissionToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface DeleteDriverCommissionRequest {
    licenseId: number;
}

export interface GetDriverCommissionRequest {
    licenseId: number;
}

export interface InsertDriverCommissionRequest {
    licenseId: number;
    commission: Commission;
}

export interface UpdateDriverCommissionRequest {
    licenseId: number;
    commission: Commission;
}


/**
 * Deletes the driver commission of the provided license holder
 */
function deleteDriverCommissionRaw<T>(requestParameters: DeleteDriverCommissionRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling deleteDriverCommission.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/drivercommission`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Deletes the driver commission of the provided license holder
*/
export function deleteDriverCommission<T>(requestParameters: DeleteDriverCommissionRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteDriverCommissionRaw(requestParameters, requestConfig);
}

/**
 * Fetches the driver commission of the provided license holder
 */
function getDriverCommissionRaw<T>(requestParameters: GetDriverCommissionRequest, requestConfig: runtime.TypedQueryConfig<T, Commission> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getDriverCommission.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/drivercommission`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CommissionFromJSON(body), text);
    }

    return config;
}

/**
* Fetches the driver commission of the provided license holder
*/
export function getDriverCommission<T>(requestParameters: GetDriverCommissionRequest, requestConfig?: runtime.TypedQueryConfig<T, Commission>): QueryConfig<T> {
    return getDriverCommissionRaw(requestParameters, requestConfig);
}

/**
 * Creates the driver commission of the provided license holder
 */
function insertDriverCommissionRaw<T>(requestParameters: InsertDriverCommissionRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling insertDriverCommission.');
    }

    if (requestParameters.commission === null || requestParameters.commission === undefined) {
        throw new runtime.RequiredError('commission','Required parameter requestParameters.commission was null or undefined when calling insertDriverCommission.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/drivercommission`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CommissionToJSON(requestParameters.commission),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Creates the driver commission of the provided license holder
*/
export function insertDriverCommission<T>(requestParameters: InsertDriverCommissionRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return insertDriverCommissionRaw(requestParameters, requestConfig);
}

/**
 * Updates the driver commission of the provided license holder
 */
function updateDriverCommissionRaw<T>(requestParameters: UpdateDriverCommissionRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling updateDriverCommission.');
    }

    if (requestParameters.commission === null || requestParameters.commission === undefined) {
        throw new runtime.RequiredError('commission','Required parameter requestParameters.commission was null or undefined when calling updateDriverCommission.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/drivercommission`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || CommissionToJSON(requestParameters.commission),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Updates the driver commission of the provided license holder
*/
export function updateDriverCommission<T>(requestParameters: UpdateDriverCommissionRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateDriverCommissionRaw(requestParameters, requestConfig);
}


import { useDeleteFavorite, useGetFavorites } from '@apis';
import DataGrid from '@components/DataGrid';
import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import { Favorite as F, FavoriteSportInfoTypeEnum } from '@generated/user/src';
import withUser from '@hoc/withUser';
import useIsMobile from '@hooks/useIsMobile';
import { Flex } from '@primitives/Box';
import { dataGridRemoveButtonMixin, RemoveButton } from '@primitives/Button';
import Error from '@primitives/Error';
import Icon from '@primitives/Icon';
import { Link } from '@primitives/Link';
import List from '@primitives/List';
import { Small } from '@primitives/Typography';
import { Star } from '@styled-icons/fa-solid';
import React from 'react';
import { CellProps } from 'react-table';

interface Props {
  user: AuthenticatedUser;
  simpleList?: boolean;
}

const pathMap = {
  [FavoriteSportInfoTypeEnum.HORSE]: 'hastar',
  [FavoriteSportInfoTypeEnum.LICENSE_HOLDER]: 'licensinnehavare',
  [FavoriteSportInfoTypeEnum.OWNER]: 'agare',
  [FavoriteSportInfoTypeEnum.BREEDER]: 'uppfodare',
};

const nameMap = {
  [FavoriteSportInfoTypeEnum.HORSE]: 'Häst',
  [FavoriteSportInfoTypeEnum.LICENSE_HOLDER]: 'Licensinnehavare',
  [FavoriteSportInfoTypeEnum.OWNER]: 'Ägare',
  [FavoriteSportInfoTypeEnum.BREEDER]: 'Uppfödare',
};

export function FavoritesResult({
  user,
  simpleList = false,
}: Props): JSX.Element {
  const { data, status, error, refresh } = useGetFavorites(user.licenseId);
  const { action: remove } = useDeleteFavorite(user.licenseId);

  const isMobile = useIsMobile();

  if (status && status !== 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  if (simpleList) {
    return data?.length ? (
      <List itemGap={1}>
        {data.slice(0, 5).map((item, i) => (
          <li key={i}>
            <Icon as={Star} color="yellow" size={14} mr={2}></Icon>{' '}
            <Link
              color="gray6"
              to={`/sportinfo/${pathMap[item.sportInfoType]}/${item.id}`}
              sx={{
                textDecoration: 'underline',
              }}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </List>
    ) : (
      <Small color="dim-gray">Du har inte sparat några favoriter.</Small>
    );
  }

  return (
    <DataGrid
      title={`Du har ${data?.length || 0} favoriter`}
      noContent={{
        content: 'Du har inte sparat några favoriter',
      }}
      columns={[
        {
          Header: 'Favorit',
          accessor: 'name',
          Cell: ({ row: { original: row }, value }: CellProps<F, string>) => (
            <Flex justifyContent="flex-start" alignItems="flex-start">
              <Icon as={Star} color="yellow" size={14} mr={2}></Icon>
              <div>
                <Link
                  color="gray6"
                  fontWeight="bold"
                  to={`/sportinfo/${pathMap[row.sportInfoType]}/${row.id}`}
                  sx={{
                    textDecoration: 'underline',
                  }}
                >
                  {value}
                </Link>
                {isMobile && (
                  <>
                    <br />
                    <Small mt={1} color="gray5">
                      {nameMap[row.sportInfoType]}
                    </Small>
                  </>
                )}
              </div>
            </Flex>
          ),
        },
        {
          Header: 'Typ',
          accessor: 'sportInfoType',
          Cell: ({ value }) => nameMap[value],
          hideMobile: true,
        },

        {
          Header: 'Ta bort',
          id: 'delete',
          accessor: 'id',
          Cell: ({ value }: CellProps<F, number>) => (
            <RemoveButton
              onClick={async () => {
                await remove(value);
                await refresh();
              }}
            />
          ),
          ...dataGridRemoveButtonMixin,
        },
      ]}
      data={data || []}
      noHover
    />
  );
}

function Favorites({ user }: Props): JSX.Element {
  return (
    <PageLayout headline="Favoriter" singleCard>
      <FavoritesResult user={user} />
    </PageLayout>
  );
}

export default withUser(Favorites);

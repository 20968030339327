import Box from '@primitives/Box';
import { CSSSelectorObject, SystemCssProperties } from '@styled-system/css';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

const colors = {
  success: 'green',
  error: 'red',
  info: 'dim-gray',
};

type BoxParameters = Parameters<typeof Box>;

type Props = {
  children: ReactNode;
  sx?: CSSSelectorObject | SystemCssProperties;
  variant: 'info' | 'infoBox' | 'success' | 'successBox' | 'error' | 'errorBox';
} & BoxParameters[0];

const StyledBox = styled(Box)<Props>`
  ${variant({
    prop: 'variant',
    variants: {
      info: {
        color: 'dim-gray',
      },
      infoBox: {
        color: 'dim-gray',
        padding: 3,
        backgroundColor: 'gray2',
      },
      success: {
        color: 'green',
      },
      successBox: {
        color: 'green',
        padding: 3,
        backgroundColor: 'light-green',
      },
      error: {
        color: 'red',
      },
      errorBox: {
        color: 'red',
        padding: 3,
        backgroundColor: 'alt1',
      },
    },
  })}
`;

export default function Message({
  children,
  sx,
  variant,
  ...props
}: Props): React.ReactElement {
  return (
    <StyledBox variant={variant} color={colors[variant]} sx={sx} {...props}>
      {children}
    </StyledBox>
  );
}

import Box from '@primitives/Box';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

const StyledBox = styled(Box)`
  @media print {
    padding: 0;
    border-radius: 0;
    background: transparent;
  }
`;

const mobilePadding = '16px';

type BoxParameters = Parameters<typeof Box>;

type Props = {
  children?: ReactNode;
  tight?: boolean;
  grayBg?: boolean;
} & BoxParameters[0];

const Card = ({ children, tight, ...props }: Props): JSX.Element => (
  <StyledBox
    p={tight ? [mobilePadding, null, 2] : [mobilePadding, null, 4]} // Depr.
    borderRadius="5px"
    maxWidth="100%"
    borderColor="gray2"
    borderWidth="1px"
    borderStyle="solid"
    {...props}
  >
    {children}
  </StyledBox>
);

Card.defaultProps = {
  backgroundColor: 'white',
};

Card.mobilePadding = mobilePadding;

export const FormCard = styled(Card)<{ variant: 'normal' | 'tight' }>`
  ${variant({
    prop: 'variant',
    variants: {
      normal: {
        py: [0, null, 4],
        px: [mobilePadding, null, 4],
      },
      tight: {
        py: [0, null, 2],
        px: [mobilePadding, null, 2],
      },
    },
  })}
`;

FormCard.defaultProps = {
  variant: 'normal',
  borderRadius: [0, , 5],
  borderWidth: [0, , '1px'],
};

export const DesktopCard = styled(Card)``;

DesktopCard.defaultProps = {
  px: 4,
  py: [0, , 4],
  borderWidth: [0, null, 1],
  borderStyle: 'solid',
  borderSize: [0, null, 1],
  borderRadius: [0, null, 5],
};

export default Card;

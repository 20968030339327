// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    LicenseHolderTrack,
    LicenseHolderTrackFromJSON,
    LicenseHolderTrackToJSON,
} from './';

/**
 * Suspensions if licenseholder is suspended
 * @export
 * @interface Suspension
 */
export interface Suspension  {
    /**
     * 
     * @type {Date}
     * @memberof Suspension
     */
    fromDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Suspension
     */
    toDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Suspension
     */
    dateOfIncident: Date;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    description: string;
    /**
     * True means the suspension is linked to a specific race.
     * @type {boolean}
     * @memberof Suspension
     */
    raceIncident: boolean;
    /**
     * Will not be returned if raceIncident == false
     * @type {number}
     * @memberof Suspension
     */
    raceDayId?: number;
    /**
     * Will not be returned if raceIncident == false
     * @type {number}
     * @memberof Suspension
     */
    raceId?: number;
    /**
     * Will not be returned if raceIncident == false
     * @type {string}
     * @memberof Suspension
     */
    raceNumberDescription?: string;
    /**
     * 
     * @type {LicenseHolderTrack}
     * @memberof Suspension
     */
    track?: LicenseHolderTrack;
    /**
     * 
     * @type {string}
     * @memberof Suspension
     */
    countryCode?: string;
}

export function SuspensionFromJSON(json: any): Suspension {
    return {
        'fromDate': parseDateTime(json['fromDate']),
        'toDate': parseDateTime(json['toDate']),
        'dateOfIncident': parseDateTime(json['dateOfIncident']),
        'description': json['description'],
        'raceIncident': json['raceIncident'],
        'raceDayId': !exists(json, 'raceDayId') ? undefined : json['raceDayId'],
        'raceId': !exists(json, 'raceId') ? undefined : json['raceId'],
        'raceNumberDescription': !exists(json, 'raceNumberDescription') ? undefined : json['raceNumberDescription'],
        'track': !exists(json, 'track') ? undefined : LicenseHolderTrackFromJSON(json['track']),
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
    };
}

export function SuspensionToJSON(value?: Suspension): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'fromDate': value.fromDate.toLocalDate(),
        'toDate': value.toDate.toLocalDate(),
        'dateOfIncident': value.dateOfIncident.toLocalDate(),
        'description': value.description,
        'raceIncident': value.raceIncident,
        'raceDayId': value.raceDayId,
        'raceId': value.raceId,
        'raceNumberDescription': value.raceNumberDescription,
        'track': LicenseHolderTrackToJSON(value.track),
        'countryCode': value.countryCode,
    };
}



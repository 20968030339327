import { useSendTextToUserWithoutAccount } from '@apis';
import Alert from '@components/Alert';
import Box, { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import Remove from '@primitives/Button/Remove';
import Error from '@primitives/Error';
import { Input } from '@primitives/Form';
import Icon from '@primitives/Icon';
import { Label, Small } from '@primitives/Typography';
import { MobileScreenButton as Phone } from '@styled-icons/fa-solid';
import scrollToTop from '@utils/scrollToTop';
import React, { useState } from 'react';

interface Props {
  setMessage: (message: string) => void;
}

const SMSNotice = ({ setMessage }: Props): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState<string>();
  const [phoneNumbers, setPhoneNumbers] = useState([]);

  const {
    error,
    fetch: sendTexts,
    loading,
  } = useSendTextToUserWithoutAccount(0);

  const inputIsValid = /\+?[0-9-]{5,20}/.test(inputValue);

  return (
    <Box mt={4}>
      <Button
        width={[1, , 'auto']}
        onClick={() => {
          setShowModal(true);
        }}
        colorScheme="secondary"
      >
        Skicka SMS om att skaffa användarkonto <Icon as={Phone} size={16} />
      </Button>

      {showModal && (
        <Alert
          open
          loading={loading}
          size="small"
          onAction={async () => {
            const { success } = await sendTexts(
              phoneNumbers.map(nr => nr.replace(/\s/g, '')),
            );

            if (success) {
              setPhoneNumbers([]);
              setInputValue('');

              setMessage(
                'SMS har skickats. Först när användaren har skapats kan du fortsätta registreringen.',
              );

              setShowModal(false);
              scrollToTop();
            }
          }}
          icon={Phone}
          colorScheme="primary"
          iconProps={{
            size: 12,
          }}
          description={
            <>
              Fyll i telefonnummer dit du vill skicka meddelande med
              instruktioner för hur man skapar ett användarkonto.
            </>
          }
          error={error}
          title="SMS om att skapa konto"
          closeModal={() => {
            setInputValue('');
            setPhoneNumbers([]);
            setShowModal(false);
          }}
          action="Skicka SMS"
          cancel="Avbryt"
          disabled={phoneNumbers.length === 0}
        >
          <Box as="form" p={4} backgroundColor="gray1">
            <Box verticalSpacing={2}>
              <Box verticalSpacing={2}>
                <Box>
                  <Label fontSize="small">Ange telefonnummer</Label>
                  <Input
                    name="phone"
                    type="tel"
                    value={inputValue}
                    onChange={e => {
                      const { value } = e.target;

                      if (value === '' || /^[\d+\s\-+]+$/.test(value)) {
                        setInputValue(value);
                      }

                      if (inputError) {
                        setInputError('');
                      }
                    }}
                    pattern="\+?[0-9\-]{5,15}"
                  />
                  <Small fontStyle="italic" fontSize="tiny" mt="1">
                    Endast svenska telefonnummer
                  </Small>
                </Box>
                {inputError && <Error>{inputError}</Error>}
                <Flex justifyContent="flex-end">
                  <Button
                    disabled={!inputIsValid}
                    onClick={() => {
                      const cleanNr = inputValue.replace(/[^\d+]/, '');

                      if (phoneNumbers.includes(cleanNr)) {
                        setInputError(
                          'Du kan inte lägga till samma nummer flera gånger',
                        );
                      } else if (cleanNr.length > 15) {
                        setInputError('Du har angett ett för långt nummer');
                      } else if (inputIsValid) {
                        setPhoneNumbers(prev => [...prev, cleanNr]);
                        setInputValue('');
                      } else {
                        setInputError('Inget giltigt telefonnummer!');
                      }
                    }}
                  >
                    Lägg till
                  </Button>
                </Flex>
              </Box>
              {phoneNumbers.length > 0 && (
                <Box verticalSpacing={2}>
                  <Small fontWeight="bold">Mottagare</Small>
                  {phoneNumbers.map(item => (
                    <Box key={item}>
                      {item}{' '}
                      <Remove
                        confirm={false}
                        onClick={() => {
                          setPhoneNumbers(prev => prev.filter(v => v !== item));
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Alert>
      )}
    </Box>
  );
};

export default SMSNotice;

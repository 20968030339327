import { OwnershipTransfer } from '@generated/ownership-transfers/src';
import React from 'react';

interface Context {
  selectedOwnership?: number;
  selectOwnership: (v: number | undefined) => void;
  transfer: OwnershipTransfer;
  isLeasing?: boolean;
}

export const Ctx = React.createContext<Partial<Context>>({});

// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    LicenseHolderResults,
    LicenseHolderResultsFromJSON,
    LicenseHolderResultsToJSON,
} from '../models';

export interface GetDriverResultsRequest {
    driverId: number;
}

export interface GetTrainerResultsRequest {
    trainerId: number;
}


/**
 * Get results for a driver/rider.
 */
function getDriverResultsRaw<T>(requestParameters: GetDriverResultsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<LicenseHolderResults>> = {}): QueryConfig<T> {
    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
        throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling getDriverResults.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/drivers/{driverId}/results`.replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(LicenseHolderResultsFromJSON), text);
    }

    return config;
}

/**
* Get results for a driver/rider.
*/
export function getDriverResults<T>(requestParameters: GetDriverResultsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<LicenseHolderResults>>): QueryConfig<T> {
    return getDriverResultsRaw(requestParameters, requestConfig);
}

/**
 * Get results for a trainer.
 */
function getTrainerResultsRaw<T>(requestParameters: GetTrainerResultsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<LicenseHolderResults>> = {}): QueryConfig<T> {
    if (requestParameters.trainerId === null || requestParameters.trainerId === undefined) {
        throw new runtime.RequiredError('trainerId','Required parameter requestParameters.trainerId was null or undefined when calling getTrainerResults.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/trainers/{trainerId}/results`.replace(`{${"trainerId"}}`, encodeURIComponent(String(requestParameters.trainerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(LicenseHolderResultsFromJSON), text);
    }

    return config;
}

/**
* Get results for a trainer.
*/
export function getTrainerResults<T>(requestParameters: GetTrainerResultsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<LicenseHolderResults>>): QueryConfig<T> {
    return getTrainerResultsRaw(requestParameters, requestConfig);
}


// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Ownership,
    OwnershipFromJSON,
    OwnershipToJSON,
    OwnershipExistenceResponse,
    OwnershipExistenceResponseFromJSON,
    OwnershipExistenceResponseToJSON,
} from '../models';

export interface GetOwnershipExistenceStatusRequest {
    licenseId: number;
    transferId: number;
    serviceType?: GetOwnershipExistenceStatusServiceTypeEnum;
}

export interface GetRepresentativeOwnershipsRequest {
    licenseId: number;
}


/**
 * Shows if ownership exists or not for the given transferId and if exists,then if representative and part owners are part of existing ownership or not.
 * Show ownership existence status.
 */
function getOwnershipExistenceStatusRaw<T>(requestParameters: GetOwnershipExistenceStatusRequest, requestConfig: runtime.TypedQueryConfig<T, OwnershipExistenceResponse> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getOwnershipExistenceStatus.');
    }

    if (requestParameters.transferId === null || requestParameters.transferId === undefined) {
        throw new runtime.RequiredError('transferId','Required parameter requestParameters.transferId was null or undefined when calling getOwnershipExistenceStatus.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.serviceType !== undefined) {
        queryParameters['serviceType'] = requestParameters.serviceType;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/{licenseId}/{transferId}/ownership`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"transferId"}}`, encodeURIComponent(String(requestParameters.transferId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OwnershipExistenceResponseFromJSON(body), text);
    }

    return config;
}

/**
* Shows if ownership exists or not for the given transferId and if exists,then if representative and part owners are part of existing ownership or not.
* Show ownership existence status.
*/
export function getOwnershipExistenceStatus<T>(requestParameters: GetOwnershipExistenceStatusRequest, requestConfig?: runtime.TypedQueryConfig<T, OwnershipExistenceResponse>): QueryConfig<T> {
    return getOwnershipExistenceStatusRaw(requestParameters, requestConfig);
}

/**
 * Show all representative\'s ownerships
 */
function getRepresentativeOwnershipsRaw<T>(requestParameters: GetRepresentativeOwnershipsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Ownership>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getRepresentativeOwnerships.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/{licenseId}/ownerships`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(OwnershipFromJSON), text);
    }

    return config;
}

/**
* Show all representative\'s ownerships
*/
export function getRepresentativeOwnerships<T>(requestParameters: GetRepresentativeOwnershipsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Ownership>>): QueryConfig<T> {
    return getRepresentativeOwnershipsRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum GetOwnershipExistenceStatusServiceTypeEnum {
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS',
    LEASING = 'LEASING'
}

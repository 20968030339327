const TOKEN_STORAGE_KEY = 'jwtToken';
const TOKEN_LAST_REFRESH = 'jwtTokenLastRefresh';
export const AUTH_SWITCH_CONSTANT = 'switch';

const refreshInterval = 1000 * 5 * 60; // 5 minutes

export const loginRedirectReasons = {
  session:
    'Du har varit inaktiv för länge så din session har löpt ut, var god logga in på nytt.',
  unauthorized: 'Sidan du försökte besöka kräver inloggning.',
  switch: 'Sidan du försöker besöka kräver inloggning med BankID.',
} as const;

class AuthTokenClient {
  constructor() {
    console.info('Initialize auth class');
  }

  isRefreshing: false | Promise<void> = false;

  get tokenAge(): number {
    const refreshed = localStorage.getItem(TOKEN_LAST_REFRESH);

    if (refreshed) {
      return new Date().getTime() - new Date(refreshed).getTime();
    }

    return -1;
  }

  get needsRefresh(): boolean {
    const age = this.tokenAge;

    // Older than 5 minutes or -1
    return age < 0 || age > refreshInterval;
  }

  get token(): string | undefined | null {
    //return null;
    return localStorage.getItem(TOKEN_STORAGE_KEY);
  }

  set token(newValue: string | null | undefined) {
    if (newValue == null) {
      console.info('Remove token from local storage');
      localStorage.removeItem(TOKEN_STORAGE_KEY);
      localStorage.removeItem(TOKEN_LAST_REFRESH);
    } else {
      console.info('set token');
      localStorage.setItem(TOKEN_STORAGE_KEY, newValue);
      localStorage.setItem(TOKEN_LAST_REFRESH, new Date().toISOString());
    }
  }
}

export default new AuthTokenClient();

import { getNumberOfHorseWithVaccineWarning } from '@generated/licenseholders/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = number | undefined;

export interface Entities {
  numberOfHorseWithVaccineWarning?: {
    [key: string]: Result;
  };
}

export default function useGetNumberOfHorseWithVaccineWarning(
  licenseId: number,
): ApiResponse<Result, Entities> {
  const key = `${licenseId}`;
  const query = {
    ...getNumberOfHorseWithVaccineWarning<Entities>({
      licenseId,
    }),
    queryKey: `vaccineWarnings:${licenseId}`,
    transform: body => ({
      numberOfHorseWithVaccineWarning: {
        [key]: body,
      },
    }),
    update: {
      numberOfHorseWithVaccineWarning: (prev, next): typeof next => ({
        ...prev,
        ...next,
      }),
    },
  };
  const selector = (state: State): Result =>
    state.entities?.numberOfHorseWithVaccineWarning?.[key];

  return useApi<Result>({
    query,
    selector,
  });
}

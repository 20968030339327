import { variant as systemVariant } from 'styled-system';

export const massScheme = systemVariant({
  prop: 'mass',
  variants: {
    small: {
      'font-size': '1.25rem',
      'line-height': '2rem',
      'font-weight': '400',
    },
    medium: {
      'font-size': '2rem',
      'font-weight': '400',
      'line-height': '2.5rem',
    },
    large: {
      'font-weight': '400',
      'line-height': '2.25rem',
      'font-size': '2.25rem',
    },
  },
});

import Box from '@primitives/Box';
import React from 'react';

type BoxParameters = Parameters<typeof Box>;
type Props = BoxParameters[0];

export default function Triangle({ ...props }: Props): JSX.Element {
  return (
    <Box
      width={0}
      height={0}
      left={0}
      right={0}
      borderStyle="solid"
      borderWidth="11px 11px 0 11px"
      borderColor="transparent"
      position="absolute"
      {...props}
    />
  );
}

// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    SelectionLicenseOption,
    SelectionLicenseOptionFromJSON,
    SelectionLicenseOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Selection
 */
export interface Selection  {
    /**
     * 
     * @type {number}
     * @memberof Selection
     */
    id: number;
    /**
     * Type of selection
     * @type {string}
     * @memberof Selection
     */
    name: string;
    /**
     * 
     * @type {Array<SelectionLicenseOption>}
     * @memberof Selection
     */
    selectionLicenseOption: Array<SelectionLicenseOption>;
}

export function SelectionFromJSON(json: any): Selection {
    return {
        'id': json['id'],
        'name': json['name'],
        'selectionLicenseOption': (json['selectionLicenseOption'] as Array<any>).map(SelectionLicenseOptionFromJSON),
    };
}

export function SelectionToJSON(value?: Selection): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'selectionLicenseOption': (value.selectionLicenseOption as Array<any>).map(SelectionLicenseOptionToJSON),
    };
}



import {
  EnrolledRaceDay,
  getEnrolledRaceDays,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Results = EnrolledRaceDay[] | undefined;

export interface Entities {
  enrolledRaceDays?: {
    [key: number]: Results;
  };
}

export default function useGetEnrolledRaceDays(
  licenseId: number,
): ApiResponse<Results, Entities> {
  const query = getEnrolledRaceDays<Entities>(
    {
      licenseId,
    },
    {
      force: true,
      queryKey: `enrolledRaceDays:${licenseId}`,
      transform: responseBody => ({
        enrolledRaceDays: {
          [licenseId]: responseBody,
        },
      }),
      update: {
        enrolledRaceDays: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Results =>
    state.entities?.enrolledRaceDays?.[licenseId];

  return useApi<Results>({
    query,
    selector,
  });
}

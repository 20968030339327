// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Owner,
    OwnerFromJSON,
    OwnerToJSON,
    Representative,
    RepresentativeFromJSON,
    RepresentativeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Ownership
 */
export interface Ownership  {
    /**
     * 
     * @type {Owner}
     * @memberof Ownership
     */
    owner: Owner;
    /**
     * 
     * @type {Representative}
     * @memberof Ownership
     */
    representative: Representative;
    /**
     * If the ownership has active horses or not
     * @type {boolean}
     * @memberof Ownership
     */
    active: boolean;
}

export function OwnershipFromJSON(json: any): Ownership {
    return {
        'owner': OwnerFromJSON(json['owner']),
        'representative': RepresentativeFromJSON(json['representative']),
        'active': json['active'],
    };
}

export function OwnershipToJSON(value?: Ownership): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'owner': OwnerToJSON(value.owner),
        'representative': RepresentativeToJSON(value.representative),
        'active': value.active,
    };
}



import 'styled-components';

import { useGetStakeRaceWithStakes } from '@apis';
import { Container, Item } from '@primitives/Box';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import React from 'react';

import InfoTexts from '../InfoTexts';
import StakeInfo from '../StakeInfo';

interface Props {
  propositionId: number;
  accountPage: boolean;
}

export default function Expanded({
  propositionId,
  accountPage,
}: Props): JSX.Element {
  const { data, loading, error, status } =
    useGetStakeRaceWithStakes(propositionId);

  if (loading) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return <Error>{error}</Error>;
  }

  return (
    <Container gap={accountPage ? 7.5 : 32} my="-7.5px">
      <Item width={[1, null, 1 / 2]} display="block">
        <StakeInfo stakes={data.stakes} />
      </Item>
      <Item width={[1, null, 1 / 2]} display="block">
        <InfoTexts infoTexts={data.infoTexts} />
      </Item>
    </Container>
  );
}

import { useGetHorsesWithVaccine } from '@apis';
import DataGrid from '@components/DataGrid';
import { VaccinatedHorse } from '@generated/licenseholders/src';
import Error from '@primitives/Error';
import { Checkbox } from '@primitives/Form';
import List, { LI } from '@primitives/List';
import Loading from '@primitives/Loading';
import Message from '@primitives/Message';
import { Text } from '@primitives/Typography';
import { dateToUtc, formatTz } from '@utils/dateUtils';
import isEqual from 'lodash/isEqual';
import React, { memo, useEffect, useMemo } from 'react';
import { CellProps, Column } from 'react-table';

import SelectedHorses from './utils/type/SelectedHorses';
import SetFieldValue from './utils/type/SetFieldValue';
import SetSelectedHorse from './utils/type/SetSelectedHorse';

const columns = (
  disabled: boolean,
  selectedHorses: SelectedHorses,
  setFieldValue: SetFieldValue,
  setSelectedHorse: SetSelectedHorse,
): Column<VaccinatedHorse>[] => [
  {
    Header: 'Välj häst',
    accessor: 'horseDisplayName',
    tdStyle: () => ({ whiteSpace: 'normal' }),
    Cell: ({
      row,
      value,
    }: CellProps<
      VaccinatedHorse,
      VaccinatedHorse['horseDisplayName']
    >): JSX.Element => {
      const checked = Boolean(
        row.original.horseId in selectedHorses &&
          selectedHorses[row.original.horseId],
      );

      return (
        <Checkbox
          checked={checked}
          disabled={disabled}
          mr={1}
          label={`${value}${
            row.original.horseName === 'nn' &&
            row.original?.horseRegistrationNumber
              ? ` (${row.original.horseRegistrationNumber})`
              : ''
          }`}
          onChange={() =>
            setSelectedHorse(
              row.original.horseId,
              !checked ? value : null,
              setFieldValue,
              selectedHorses,
            )
          }
        />
      );
    },
  },
  {
    Header: 'Ålder',
    accessor: 'age',
    alignment: 'center',
  },
  {
    Header: 'Senaste vaccin',
    thStyle: () => ({
      whiteSpace: 'nowrap',
    }),
    accessor: row => row.vaccineInjection?.injectionDate,
    alignment: 'center',
    sortType: 'datetime',
    Cell: ({ value }: CellProps<VaccinatedHorse, Date>): JSX.Element => (
      <>{formatTz(value, 'yyyy-MM-dd')}</>
    ),
  },
  {
    Header: 'Vaccin',
    accessor: row => row.vaccineInjection?.vaccine?.name,
    Cell: ({ value }: CellProps<VaccinatedHorse, string>): JSX.Element => (
      <>{value}</>
    ),
    tdStyle: () => ({
      whiteSpace: 'normal',
    }),
  },
  {
    Header: 'Sekv.',
    accessor: row => row.vaccineInjection?.vaccineSequence,
    alignment: 'center',
    Cell: ({ value }: CellProps<VaccinatedHorse, string>): JSX.Element => (
      <>{value}</>
    ),
  },
  {
    Header: 'Giltigt tom',
    accessor: row => row.vaccineInjection?.validTo,
    alignment: 'center',
    sortType: (a, b) => {
      const aValue = a.original?.vaccineInjection?.validTo?.getTime() || 0;
      const bValue = b.original?.vaccineInjection?.validTo?.getTime() || 0;

      return aValue - bValue;
    },
    Cell: ({ value }: CellProps<VaccinatedHorse, Date>): JSX.Element => (
      <>{formatTz(value, 'yyyy-MM-dd')}</>
    ),
  },
  {
    Header: 'Anmärkning',
    accessor: 'remarkText',
    tdStyle: () => ({ whiteSpace: 'normal' }),
    Cell: ({ value }: CellProps<VaccinatedHorse, string>): JSX.Element =>
      value ? (
        <List markerType="circle" markerColor="red">
          <LI fontSize="small">
            <Message as="span" variant="error">
              {value}
            </Message>
          </LI>
        </List>
      ) : null,
  },
];

interface Props {
  disabled: boolean;
  date?: Date;
  licenseId: number;
  refreshHorses: number;
  selectedHorses: SelectedHorses;
  setFieldValue: SetFieldValue;
  setSelectedHorse: SetSelectedHorse;
}

const Horses = ({
  disabled,
  date,
  licenseId,
  refreshHorses,
  selectedHorses,
  setFieldValue,
  setSelectedHorse,
}: Props): JSX.Element => {
  const { data, loading, status, error, refresh } = useGetHorsesWithVaccine(
    licenseId,
    dateToUtc(date),
  );

  useEffect(() => {
    if (refreshHorses) {
      refresh();
    }
  }, [refreshHorses, refresh]);

  const horses = useMemo(
    () => (data ? data.filter(item => item.newVaccinationAllowed) : []),
    [data],
  );

  if (loading || !data) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  return (
    <>
      {data.length === 0 && <Text>Fann inga hästar.</Text>}
      <DataGrid
        columns={columns(
          disabled,
          selectedHorses,
          setFieldValue,
          setSelectedHorse,
        )}
        data={horses}
        sortable
        stickyOnMobile={false}
      />
    </>
  );
};

export default memo(Horses, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Blinkers,
    BlinkersFromJSON,
    BlinkersToJSON,
    DeclaredDriver,
    DeclaredDriverFromJSON,
    DeclaredDriverToJSON,
    DeclaredHorse,
    DeclaredHorseFromJSON,
    DeclaredHorseToJSON,
    Exemption,
    ExemptionFromJSON,
    ExemptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface StartDeclaration
 */
export interface StartDeclaration  {
    /**
     * 
     * @type {DeclaredHorse}
     * @memberof StartDeclaration
     */
    horse: DeclaredHorse;
    /**
     * 
     * @type {Blinkers}
     * @memberof StartDeclaration
     */
    blinkers: Blinkers;
    /**
     * Optional list containing exemption requests
     * @type {Array<Exemption>}
     * @memberof StartDeclaration
     */
    exemptions?: Array<Exemption>;
    /**
     * Reason for seeking exemptions, required when providing exemption requests
     * @type {string}
     * @memberof StartDeclaration
     */
    exemptionMessage?: string;
    /**
     * Optional free form message, though when foreign rider is declared, details must be specified here
     * @type {string}
     * @memberof StartDeclaration
     */
    message?: string;
    /**
     * Given rider choice
     * @type {string}
     * @memberof StartDeclaration
     */
    driverChoice: StartDeclarationDriverChoiceEnum;
    /**
     * Must contain at least one driver if  the driver choice is declared as specified driver.
     * @type {Array<DeclaredDriver>}
     * @memberof StartDeclaration
     */
    drivers?: Array<DeclaredDriver>;
}

export function StartDeclarationFromJSON(json: any): StartDeclaration {
    return {
        'horse': DeclaredHorseFromJSON(json['horse']),
        'blinkers': BlinkersFromJSON(json['blinkers']),
        'exemptions': !exists(json, 'exemptions') ? undefined : (json['exemptions'] as Array<any>).map(ExemptionFromJSON),
        'exemptionMessage': !exists(json, 'exemptionMessage') ? undefined : json['exemptionMessage'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'driverChoice': json['driverChoice'],
        'drivers': !exists(json, 'drivers') ? undefined : (json['drivers'] as Array<any>).map(DeclaredDriverFromJSON),
    };
}

export function StartDeclarationToJSON(value?: StartDeclaration): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horse': DeclaredHorseToJSON(value.horse),
        'blinkers': BlinkersToJSON(value.blinkers),
        'exemptions': value.exemptions === undefined ? undefined : (value.exemptions as Array<any>).map(ExemptionToJSON),
        'exemptionMessage': value.exemptionMessage,
        'message': value.message,
        'driverChoice': value.driverChoice,
        'drivers': value.drivers === undefined ? undefined : (value.drivers as Array<any>).map(DeclaredDriverToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum StartDeclarationDriverChoiceEnum {
    SPECIFIED_DRIVER = 'SPECIFIED_DRIVER',
    SPECIFY_LATER = 'SPECIFY_LATER',
    FOREIGN_DRIVER = 'FOREIGN_DRIVER'
}



import FilterGroupEnum from './enum/FilterGroup';
import FilterLabelEnum from './enum/FilterLabel';
import FilterValueEnum from './enum/FilterValue';
import Options from './interface/Options';

const options: Options = {
  [FilterGroupEnum.GroupBy]: [
    { label: FilterLabelEnum.Horse, value: FilterValueEnum.HorseId },
    {
      label: FilterLabelEnum.Representative,
      value: FilterValueEnum.RepresentativeId,
    },
    { label: FilterLabelEnum.Date, value: FilterValueEnum.Date },
    { label: FilterLabelEnum.Product, value: FilterValueEnum.Product },
  ],
  [FilterGroupEnum.Sort]: [
    { label: FilterLabelEnum.Horse, value: FilterValueEnum.HorseName },
    {
      label: FilterLabelEnum.Representative,
      value: FilterValueEnum.RepresentativeName,
    },
    { label: FilterLabelEnum.Date, value: FilterValueEnum.Date },
    { label: FilterLabelEnum.Product, value: FilterValueEnum.Product },
    { label: FilterLabelEnum.Amount, value: FilterValueEnum.Amount },
  ],
  [FilterGroupEnum.ViewMode]: [
    { label: FilterLabelEnum.Details, value: FilterValueEnum.Details },
    { label: FilterLabelEnum.Total, value: FilterValueEnum.Total },
  ],
};

export default options;

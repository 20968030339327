import { AuthenticatedUser } from '@generated/authenticate/src';
import React, { useContext } from 'react';

interface UserContext {
  user?: AuthenticatedUser;
  refreshUser?: () => void;
}

export const UserContext = React.createContext<UserContext>({});

export default function useUser(): AuthenticatedUser | undefined {
  const { user } = useContext(UserContext);
  return user;
}

export function useRefreshUser(): UserContext['refreshUser'] {
  const { refreshUser } = useContext(UserContext);
  return refreshUser;
}

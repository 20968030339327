// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * The driver commission
 * @export
 * @interface DriverCommission
 */
export interface DriverCommission  {
    /**
     * The plain track fee
     * @type {number}
     * @memberof DriverCommission
     */
    plainFee: number;
    /**
     * The obstacle track fee
     * @type {number}
     * @memberof DriverCommission
     */
    obstacleFee: number;
    /**
     * The driver commission rate
     * @type {number}
     * @memberof DriverCommission
     */
    commissionRate: number;
}

export function DriverCommissionFromJSON(json: any): DriverCommission {
    return {
        'plainFee': json['plainFee'],
        'obstacleFee': json['obstacleFee'],
        'commissionRate': json['commissionRate'],
    };
}

export function DriverCommissionToJSON(value?: DriverCommission): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'plainFee': value.plainFee,
        'obstacleFee': value.obstacleFee,
        'commissionRate': value.commissionRate,
    };
}



// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Product,
    ProductFromJSON,
    ProductToJSON,
    Transaction,
    TransactionFromJSON,
    TransactionToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProductInformation
 */
export interface ProductInformation  {
    /**
     * License id
     * @type {number}
     * @memberof ProductInformation
     */
    licenseId: number;
    /**
     * Product id
     * @type {number}
     * @memberof ProductInformation
     */
    id?: number;
    /**
     * 
     * @type {Product}
     * @memberof ProductInformation
     */
    product: Product;
    /**
     * Amount
     * @type {number}
     * @memberof ProductInformation
     */
    amount?: number;
    /**
     * Product text
     * @type {string}
     * @memberof ProductInformation
     */
    text?: string;
    /**
     * If this is a user chosen start product or not
     * @type {boolean}
     * @memberof ProductInformation
     */
    userChosenStartProduct: boolean;
    /**
     * If this is a product for horse selection (kostnad urval)
     * @type {boolean}
     * @memberof ProductInformation
     */
    selectionProduct: boolean;
    /**
     * If this is a rider product (for gallop)
     * @type {boolean}
     * @memberof ProductInformation
     */
    riderProduct: boolean;
    /**
     * 
     * @type {Transaction}
     * @memberof ProductInformation
     */
    registeredTransaction?: Transaction;
    /**
     * 
     * @type {boolean}
     * @memberof ProductInformation
     */
    atrainProduct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductInformation
     */
    btrainProduct?: boolean;
}

export function ProductInformationFromJSON(json: any): ProductInformation {
    return {
        'licenseId': json['licenseId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'product': ProductFromJSON(json['product']),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'userChosenStartProduct': json['userChosenStartProduct'],
        'selectionProduct': json['selectionProduct'],
        'riderProduct': json['riderProduct'],
        'registeredTransaction': !exists(json, 'registeredTransaction') ? undefined : TransactionFromJSON(json['registeredTransaction']),
        'atrainProduct': !exists(json, 'atrainProduct') ? undefined : json['atrainProduct'],
        'btrainProduct': !exists(json, 'btrainProduct') ? undefined : json['btrainProduct'],
    };
}

export function ProductInformationToJSON(value?: ProductInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseId': value.licenseId,
        'id': value.id,
        'product': ProductToJSON(value.product),
        'amount': value.amount,
        'text': value.text,
        'userChosenStartProduct': value.userChosenStartProduct,
        'selectionProduct': value.selectionProduct,
        'riderProduct': value.riderProduct,
        'registeredTransaction': TransactionToJSON(value.registeredTransaction),
        'atrainProduct': value.atrainProduct,
        'btrainProduct': value.btrainProduct,
    };
}



// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    Certificate,
    CertificateFromJSON,
    CertificateToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    OTTLink,
    OTTLinkFromJSON,
    OTTLinkToJSON,
} from '../models';

export interface GenerateNordicCertificateLinkRequest {
    licenseId: number;
    id: number;
}

export interface GetNordicCertificateRequest {
    licenseId: number;
    id: number;
    ott: string;
}

export interface GetNordicCertificateForIdRequest {
    licenseId: number;
}


/**
 * Generates url to a nordic certificate.
 */
function generateNordicCertificateLinkRaw<T>(requestParameters: GenerateNordicCertificateLinkRequest, requestConfig: runtime.TypedQueryConfig<T, OTTLink> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling generateNordicCertificateLink.');
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling generateNordicCertificateLink.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/certificates/nordic/url/{licenseId}/{id}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OTTLinkFromJSON(body), text);
    }

    return config;
}

/**
* Generates url to a nordic certificate.
*/
export function generateNordicCertificateLink<T>(requestParameters: GenerateNordicCertificateLinkRequest, requestConfig?: runtime.TypedQueryConfig<T, OTTLink>): QueryConfig<T> {
    return generateNordicCertificateLinkRaw(requestParameters, requestConfig);
}

/**
 * Fetch a Nordic certificate for a license holder.
 */
function getNordicCertificateRaw<T>(requestParameters: GetNordicCertificateRequest, requestConfig: runtime.TypedQueryConfig<T, Blob> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getNordicCertificate.');
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNordicCertificate.');
    }

    if (requestParameters.ott === null || requestParameters.ott === undefined) {
        throw new runtime.RequiredError('ott','Required parameter requestParameters.ott was null or undefined when calling getNordicCertificate.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.ott !== undefined) {
        queryParameters['ott'] = requestParameters.ott;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/certificates/nordic/{licenseId}/{id}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        throw "OH NO";
    }

    return config;
}

/**
* Fetch a Nordic certificate for a license holder.
*/
export function getNordicCertificate<T>(requestParameters: GetNordicCertificateRequest, requestConfig?: runtime.TypedQueryConfig<T, Blob>): QueryConfig<T> {
    return getNordicCertificateRaw(requestParameters, requestConfig);
}

/**
 * Fetch a list of Nordic certificates for a license holder.
 */
function getNordicCertificateForIdRaw<T>(requestParameters: GetNordicCertificateForIdRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Certificate>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getNordicCertificateForId.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/certificates/nordic/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(CertificateFromJSON), text);
    }

    return config;
}

/**
* Fetch a list of Nordic certificates for a license holder.
*/
export function getNordicCertificateForId<T>(requestParameters: GetNordicCertificateForIdRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Certificate>>): QueryConfig<T> {
    return getNordicCertificateForIdRaw(requestParameters, requestConfig);
}


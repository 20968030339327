import roleEnum from '@utils/roleEnum';

import { Template } from './_templateData';

export const TraningslistaAnmalanAvHastIOchUrTraning: Template = {
  id: 4,
  actorType: 'horse',
  fields: [
    {
      name: 'Vilken häst ska anmälas i eller ur träning?',
      description:
        'Sök upp den häst som ska tas i träning eller välj den av dina tränade hästar som ska tas ur träning från listan med tränade hästar.',
      fields: [
        'horseSwitchButtonTraining',
        'horseSearchInfo',
        'horseSearch',
        'horseTraining',
        'horseInfo',
      ],
    },
    {
      name: 'När sattes hästen i träning?',
      description:
        'Ange från och med vilket datum hästen sattes i din träning samt vistelseort.',
      conditions: [
        {
          text: 'Innan hästen kan sättas i din träning måste nuvarande tränare ta hästen ur sin träning.',
          condition: '!!values?.trainer',
        },
      ],
      fields: ['horseInTraining', 'horseLocation'],
      hidden: 'values.horseSwitchButtonTraining',
    },
    {
      name: 'När togs hästen ur träning?',
      description: 'Ange till och med vilket datum hästen var i din träning.',
      fields: ['horseOutOfTraining'],
      hidden: '!values.horseSwitchButtonTraining',
    },
  ],
  fieldOptions: [
    {
      horseSearchInfo: { hidden: false },
      horseSearch: {
        hidden: 'values.horseSwitchButtonTraining || values.horse',
      },
      horseTraining: {
        hidden: '!values.horseSwitchButtonTraining || values.horse',
        output: 'horse',
      },
      horseInfo: {
        hidden: '!values.horseSwitchButtonTraining || !values.horse',
      },
      horseInTraining: {
        max: 'new Date()',
      },
      horseOutOfTraining: {
        max: 'new Date()',
      },
    },
  ],
  provider: 'email',
  roles: [{ role: roleEnum.Trainer }],
};

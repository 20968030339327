// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface BucketLink
 */
export interface BucketLink  {
    /**
     * 
     * @type {string}
     * @memberof BucketLink
     */
    url?: string;
}

export function BucketLinkFromJSON(json: any): BucketLink {
    return {
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function BucketLinkToJSON(value?: BucketLink): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'url': value.url,
    };
}



import Table from '@components/Table';
import { SportInfoStatistics } from '@generated/licenseholders/src';
import { Statistics as SportActorStatistics } from '@generated/sportactors/src';
import { formatNumber } from '@utils/formatting';
import React from 'react';
import { CellProps, Column } from 'react-table';

type Types = SportActorStatistics | SportInfoStatistics;

function getColumns<T extends Types>(): Column<T>[] {
  return [
    {
      Header: 'År',
      accessor: 'year',
    },
    {
      Header: 'Starter',
      accessor: row => row.numberOfStarts,
      alignment: 'right',
      Cell: ({ value }: CellProps<T, T['numberOfStarts']>) => <>{value}</>,
    },
    {
      Header: '1:a',
      accessor: row => row.firstPlaces,
      alignment: 'right',
      Cell: ({ value }: CellProps<T, T['firstPlaces']>) => <>{value}</>,
    },
    {
      Header: '2:a',
      accessor: row => row.secondPlaces,
      alignment: 'right',
      Cell: ({ value }: CellProps<T, T['secondPlaces']>) => <>{value}</>,
    },
    {
      Header: '3:a',
      accessor: row => row.thirdPlaces,
      alignment: 'right',
      Cell: ({ value }: CellProps<T, T['thirdPlaces']>) => <>{value}</>,
    },
    {
      Header: 'Seger%',
      alignment: 'right',
      id: 'winningRate',
      Cell: ({ row }: CellProps<T>) => (
        <>
          {row.original.numberOfStarts
            ? Math.round(
                ((row.original.firstPlaces || 0) /
                  row.original.numberOfStarts) *
                  100,
              )
            : 0}
        </>
      ),
    },
    {
      Header: 'Kr per start',
      accessor: row => row.prizeMoneyPerStart,
      alignment: 'right',
      Cell: ({ value }: CellProps<T, T['prizeMoneyPerStart']>) => (
        <>{formatNumber(value)}</>
      ),
    },
    {
      Header: 'Vinstsumma',
      accessor: row => row.prizeMoney,
      alignment: 'right',
      Cell: ({ value }: CellProps<T, T['prizeMoney']>) => (
        <>{formatNumber(value)}</>
      ),
    },
    {
      Header: 'Hästägarpremie',
      accessor: row => row.bonus,
      alignment: 'right',
      Cell: ({ value }: CellProps<T, T['bonus']>) => <>{formatNumber(value)}</>,
    },
    {
      Header: 'Vinstsumma inkl hästägarpremie',
      accessor: row => row.prizeMoneyIncludingBonus,
      alignment: 'right',
      Cell: ({
        value,
      }: CellProps<T, T['prizeMoneyIncludingBonus']>): JSX.Element => (
        <>{formatNumber(value)}</>
      ),
    },
  ];
}

interface Props<T extends Types> {
  data: T[];
}

function Statistics<T extends Types>({ data }: Props<T>): JSX.Element {
  const columns = getColumns<T>();

  return (
    <Table<T> striped condensed columns={columns} data={data || []} mb={5} />
  );
}

export default Statistics;

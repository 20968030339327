// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Status
 */
export interface Status  {
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    statusCode: string;
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    statusText?: string;
}

export function StatusFromJSON(json: any): Status {
    return {
        'statusCode': json['statusCode'],
        'statusText': !exists(json, 'statusText') ? undefined : json['statusText'],
    };
}

export function StatusToJSON(value?: Status): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'statusCode': value.statusCode,
        'statusText': value.statusText,
    };
}



// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Track,
    TrackFromJSON,
    TrackToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnrolledRaceDay
 */
export interface EnrolledRaceDay  {
    /**
     * The race day id
     * @type {number}
     * @memberof EnrolledRaceDay
     */
    raceDayId: number;
    /**
     * The date when the race takes place
     * @type {Date}
     * @memberof EnrolledRaceDay
     */
    raceDate: Date;
    /**
     * Date when the registration should be submitted at latest
     * @type {Date}
     * @memberof EnrolledRaceDay
     */
    startRegistrationEndDate: Date;
    /**
     * 
     * @type {Track}
     * @memberof EnrolledRaceDay
     */
    track: Track;
}

export function EnrolledRaceDayFromJSON(json: any): EnrolledRaceDay {
    return {
        'raceDayId': json['raceDayId'],
        'raceDate': parseDateTime(json['raceDate']),
        'startRegistrationEndDate': parseDateTime(json['startRegistrationEndDate']),
        'track': TrackFromJSON(json['track']),
    };
}

export function EnrolledRaceDayToJSON(value?: EnrolledRaceDay): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'raceDayId': value.raceDayId,
        'raceDate': value.raceDate.toLocalDate(),
        'startRegistrationEndDate': value.startRegistrationEndDate.toISOString(),
        'track': TrackToJSON(value.track),
    };
}



import ConditionalWrapper from '@components/ConditionalWrapper';
import HorseDetail from '@features/RaceInfo/Proposition/utils/HorseDetail';
import { HorseBasicInformation } from '@generated/horses/src';
import useIsMobile from '@hooks/useIsMobile';
import theme from '@main/theme';
import { Arrow } from '@primitives/Arrow';
import Box, { Flex } from '@primitives/Box';
import { Toggle } from '@primitives/Button';
import Icon, { HorseIcon } from '@primitives/Icon';
import { H3, Text } from '@primitives/Typography';
import { StyledIcon } from '@styled-icons/styled-icon';
import { formatTz } from '@utils/dateUtils';
import { firstLetterUppercase } from '@utils/firstLetterUpperCase';
import { formatNumber } from '@utils/formatting';
import React, { ReactNode, useState } from 'react';

interface Detail {
  label: string;
  value: ReactNode;
}

interface Props {
  name: string;
  text: ReactNode;
  details: Detail[];
  defaultOpen?: boolean;
  icon?: StyledIcon;
}

export const createTextFromPropostion = (
  propositionData: HorseBasicInformation,
): ReactNode => (
  <>
    {propositionData.registrationNumber}
    {propositionData.horseGender?.text
      ? `, ${firstLetterUppercase(propositionData.horseGender.text)}`
      : ''}
    , {propositionData.age} år
  </>
);

export const createDetailsFromPropostion = (
  propositionData: HorseBasicInformation,
): Detail[] =>
  [
    {
      label: 'Ras',
      value: firstLetterUppercase(propositionData.horseBreed.text),
    },
    {
      label: 'Uppfödd',
      value: firstLetterUppercase(propositionData.bredCountryText),
    },
    propositionData?.highestPrizeMoney > 0 && {
      label: 'Högsta segerpris',
      value: `${formatNumber(propositionData.highestPrizeMoney)}${
        propositionData.highestPrizeMoneyDate
          ? ` (${formatTz(
              propositionData.highestPrizeMoneyDate,
              'yyyy-MM-dd',
            )})`
          : null
      }`,
    },
    {
      label: 'Aktuellt HCP',
      value: `${propositionData.currentHandicap}/${propositionData.currentHandicapSteeple}`,
    },
    { label: 'Poäng', value: propositionData.racingPoints.toString() },
  ].filter(Boolean);

const HorseInfo = ({
  name,
  text,
  details,
  defaultOpen = false,
  icon = HorseIcon,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(defaultOpen);
  const mobile = useIsMobile();

  return (
    <Box
      background={theme.colors.white}
      border={`1px solid ${theme.colors.gray2}`}
      p={2}
      pl={4}
    >
      <ConditionalWrapper
        condition={mobile}
        wrapperTrue={children => (
          <Toggle onClick={() => setOpen(!open)}>{children}</Toggle>
        )}
        wrapperFalse={children => <>{children}</>}
      >
        <Flex justifyContent="left" width="100%">
          <Box mr={2} mb={mobile && 4}>
            <Icon size={16} color="deep-blue" as={icon} />
          </Box>
          <Box display="inline-block">
            <Box
              display={['block', null, 'flex']}
              justifyContent="left"
              alignItems="baseline"
            >
              <H3
                display="inline-block"
                sx={{ float: 'left' }}
                fontSize="text"
                width={mobile ? 1 : undefined}
              >
                {name}
              </H3>
              <Text
                pl={[null, null, 1]}
                fontSize="small"
                color="gray6"
                width={mobile ? 1 : undefined}
                textAlign="left"
              >
                {text}
              </Text>
            </Box>
          </Box>
          {mobile && <Arrow ml="auto" up={open} size={16} />}
        </Flex>
      </ConditionalWrapper>
      {(!mobile || open) && (
        <Box
          mt={[null, null, 2]}
          width="100%"
          pl={[5, null, 2]}
          display={[null, null, 'flex']}
          flexWrap="wrap"
        >
          {details
            .filter(item => Boolean(item.value))
            .map((item, i) => (
              <HorseDetail key={i} header={item.label} value={item.value} />
            ))}
        </Box>
      )}
    </Box>
  );
};

export default HorseInfo;

import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import Box from '@primitives/Box';
import { TDS_ROLES } from '@utils/constants';
import React from 'react';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import Costs from './Costs';
import InfoAndFees from './InfoAndFees';
import Lists from './Lists';
import Transactions from './Transactions';

interface Props {
  user: AuthenticatedUser;
}

const TDS = ({ user: { licenseId } }: Props): JSX.Element => {
  const { path } = useRouteMatch();

  const infoAndFeesPath = generatePath(path, {
    tab: 'info-och-arvoden',
  });

  const costsPath = generatePath(path, {
    tab: 'registrera-kostnader',
  });

  const transactionsPath = generatePath(path, {
    tab: 'transaktioner',
  });

  const listsPath = generatePath(path, {
    tab: 'hamta-tds-listor',
  });

  return (
    <PageLayout
      headline="TDS"
      singleCard
      tabs={[
        {
          to: infoAndFeesPath,
          text: 'Info & Arvoden',
        },
        {
          to: costsPath,
          text: 'Registrera kostnader',
        },
        {
          to: transactionsPath,
          text: 'Se transaktioner',
        },
        {
          to: listsPath,
          text: 'Hämta TDS-listor',
        },
      ]}
    >
      <Box mt={[4, null, 0]}>
        <Switch>
          <Route path={infoAndFeesPath}>
            <InfoAndFees licenseId={licenseId} />
          </Route>
          <Route path={costsPath}>
            <Costs costsPath={costsPath} licenseId={licenseId} />
          </Route>
          <Route path={transactionsPath}>
            <Transactions licenseId={licenseId} />
          </Route>
          <Route path={listsPath}>
            <Lists licenseId={licenseId} />
          </Route>
          <Route path="*">
            <Redirect to={infoAndFeesPath} />
          </Route>
        </Switch>
      </Box>
    </PageLayout>
  );
};

export default withUser(TDS, {
  roles: TDS_ROLES,
});

import { Text } from '@primitives/Typography';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export default function ListHeader({ children }: Props): JSX.Element {
  return (
    <Text
      fontWeight="bold"
      paddingBottom={1}
      marginBottom={1}
      borderBottomWidth={1}
      borderBottomColor="gray3"
      borderBottomStyle="solid"
      fontSize="tiny"
    >
      {children}
    </Text>
  );
}

// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    CategorySelectionOption,
    CategorySelectionOptionFromJSON,
    CategorySelectionOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface CategorySelection
 */
export interface CategorySelection  {
    /**
     * 
     * @type {string}
     * @memberof CategorySelection
     */
    alias: string;
    /**
     * 
     * @type {string}
     * @memberof CategorySelection
     */
    defaultParameter?: string;
    /**
     * 
     * @type {Array<CategorySelectionOption>}
     * @memberof CategorySelection
     */
    selectionOptions: Array<CategorySelectionOption>;
}

export function CategorySelectionFromJSON(json: any): CategorySelection {
    return {
        'alias': json['alias'],
        'defaultParameter': !exists(json, 'defaultParameter') ? undefined : json['defaultParameter'],
        'selectionOptions': (json['selectionOptions'] as Array<any>).map(CategorySelectionOptionFromJSON),
    };
}

export function CategorySelectionToJSON(value?: CategorySelection): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'alias': value.alias,
        'defaultParameter': value.defaultParameter,
        'selectionOptions': (value.selectionOptions as Array<any>).map(CategorySelectionOptionToJSON),
    };
}



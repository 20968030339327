import { useGetDriverStarts } from '@apis';
import ContentBlock from '@components/ContentBlock';
import Table from '@components/Table';
import { Starts } from '@generated/tds/src';
import Error from '@primitives/Error';
import { ActionLink } from '@primitives/Link';
import Loading from '@primitives/Loading';
import { Text } from '@primitives/Typography';
import { formatTz } from '@utils/dateUtils';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { CellProps, Column } from 'react-table';

const columns = (path: string): Column<Starts>[] => [
  {
    accessor: 'date',
    Header: 'Datum',
    Cell: ({ value }: CellProps<Starts, Starts['date']>) => (
      <>{formatTz(value, 'yyyy-MM-dd')}</>
    ),
  },
  {
    accessor: 'trackName',
    Header: 'Bana',
  },

  {
    accessor: 'numberOfStarts',
    Header: 'Antal starter',
    Cell: ({ row, value }: CellProps<Starts, Starts['raceDayId']>) => (
      <>
        {value}
        {row.original.numberOfWithdrawals > 0 &&
          ` (+${row.original.numberOfWithdrawals} struk${
            row.original.numberOfWithdrawals > 1 ? 'na' : 'en'
          })`}
      </>
    ),
  },
  {
    accessor: 'raceDayId',
    Cell: ({ row, value }: CellProps<Starts, Starts['raceDayId']>) => (
      <ActionLink
        to={`${path}/${value}`}
        color={row.original.registeredTransaction ? 'gray6' : 'green'}
      >
        {row.original.registeredTransaction ? 'Uppdatera' : 'Registrera'}{' '}
        kostnader
      </ActionLink>
    ),
  },
];

interface Props {
  licenseId: number;
}

export default function List({ licenseId }: Props): JSX.Element {
  const { data, loading, error, status } = useGetDriverStarts(licenseId);
  const { path } = useRouteMatch();

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <>
      <ContentBlock
        justifyContent="flex-start"
        slug="tds-registrera-kostnader"
        alignment="left"
      />
      {data.length === 0 && <Text mt={4}>Fann inga tävlingsdagar.</Text>}
      {data.length > 0 && <Table mt={2} columns={columns(path)} data={data} />}
    </>
  );
}

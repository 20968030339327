// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Blinkers,
    BlinkersFromJSON,
    BlinkersToJSON,
    Driver,
    DriverFromJSON,
    DriverToJSON,
    Exemption,
    ExemptionFromJSON,
    ExemptionToJSON,
    HorseDetail,
    HorseDetailFromJSON,
    HorseDetailToJSON,
    PropositionDetail,
    PropositionDetailFromJSON,
    PropositionDetailToJSON,
} from './';

/**
 * 
 * @export
 * @interface DeclaredEntryDetail
 */
export interface DeclaredEntryDetail  {
    /**
     * 
     * @type {number}
     * @memberof DeclaredEntryDetail
     */
    raceDayId?: number;
    /**
     * 
     * @type {Date}
     * @memberof DeclaredEntryDetail
     */
    startDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeclaredEntryDetail
     */
    trackName: string;
    /**
     * 
     * @type {Date}
     * @memberof DeclaredEntryDetail
     */
    lastEnrollStartDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeclaredEntryDetail
     */
    enrollStartDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeclaredEntryDetail
     */
    propositionWeightDateTime?: Date;
    /**
     * 
     * @type {PropositionDetail}
     * @memberof DeclaredEntryDetail
     */
    proposition: PropositionDetail;
    /**
     * 
     * @type {HorseDetail}
     * @memberof DeclaredEntryDetail
     */
    horse?: HorseDetail;
    /**
     * Given rider choice
     * @type {string}
     * @memberof DeclaredEntryDetail
     */
    driverChoice?: DeclaredEntryDetailDriverChoiceEnum;
    /**
     * Must contain at least one driver if  the driver choice is declared as specified driver.
     * @type {Array<Driver>}
     * @memberof DeclaredEntryDetail
     */
    drivers?: Array<Driver>;
    /**
     * Optional free form message, though when foreign rider is declared, details must be specified here
     * @type {string}
     * @memberof DeclaredEntryDetail
     */
    message?: string;
    /**
     * 
     * @type {Blinkers}
     * @memberof DeclaredEntryDetail
     */
    blinkers?: Blinkers;
    /**
     * Optional list containing exemption requests
     * @type {Array<Exemption>}
     * @memberof DeclaredEntryDetail
     */
    exemptions?: Array<Exemption>;
    /**
     * Reason for seeking exemptions, required when providing exemption requests
     * @type {string}
     * @memberof DeclaredEntryDetail
     */
    exemptionMessage?: string;
    /**
     * Start declared updated date and time
     * @type {Date}
     * @memberof DeclaredEntryDetail
     */
    updateDateTime?: Date;
    /**
     * Start declared updated trainer name
     * @type {string}
     * @memberof DeclaredEntryDetail
     */
    updateTrainer?: string;
    /**
     * Populated if the horse or owner has a state in Sport DB that prevents them to start. This is called \"incomplete status\" and could be due to lack of vaccination, missing colors etc.If more than one the messages are concatenated in this same string.
     * @type {string}
     * @memberof DeclaredEntryDetail
     */
    incompleteStatusMessage?: string;
}

export function DeclaredEntryDetailFromJSON(json: any): DeclaredEntryDetail {
    return {
        'raceDayId': !exists(json, 'raceDayId') ? undefined : json['raceDayId'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : parseDateTime(json['startDateTime']),
        'trackName': json['trackName'],
        'lastEnrollStartDateTime': !exists(json, 'lastEnrollStartDateTime') ? undefined : parseDateTime(json['lastEnrollStartDateTime']),
        'enrollStartDateTime': !exists(json, 'enrollStartDateTime') ? undefined : parseDateTime(json['enrollStartDateTime']),
        'propositionWeightDateTime': !exists(json, 'propositionWeightDateTime') ? undefined : parseDateTime(json['propositionWeightDateTime']),
        'proposition': PropositionDetailFromJSON(json['proposition']),
        'horse': !exists(json, 'horse') ? undefined : HorseDetailFromJSON(json['horse']),
        'driverChoice': !exists(json, 'driverChoice') ? undefined : json['driverChoice'],
        'drivers': !exists(json, 'drivers') ? undefined : (json['drivers'] as Array<any>).map(DriverFromJSON),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'blinkers': !exists(json, 'blinkers') ? undefined : BlinkersFromJSON(json['blinkers']),
        'exemptions': !exists(json, 'exemptions') ? undefined : (json['exemptions'] as Array<any>).map(ExemptionFromJSON),
        'exemptionMessage': !exists(json, 'exemptionMessage') ? undefined : json['exemptionMessage'],
        'updateDateTime': !exists(json, 'updateDateTime') ? undefined : parseDateTime(json['updateDateTime']),
        'updateTrainer': !exists(json, 'updateTrainer') ? undefined : json['updateTrainer'],
        'incompleteStatusMessage': !exists(json, 'incompleteStatusMessage') ? undefined : json['incompleteStatusMessage'],
    };
}

export function DeclaredEntryDetailToJSON(value?: DeclaredEntryDetail): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'raceDayId': value.raceDayId,
        'startDateTime': value.startDateTime === undefined ? undefined : value.startDateTime.toISOString(),
        'trackName': value.trackName,
        'lastEnrollStartDateTime': value.lastEnrollStartDateTime === undefined ? undefined : value.lastEnrollStartDateTime.toISOString(),
        'enrollStartDateTime': value.enrollStartDateTime === undefined ? undefined : value.enrollStartDateTime.toISOString(),
        'propositionWeightDateTime': value.propositionWeightDateTime === undefined ? undefined : value.propositionWeightDateTime.toISOString(),
        'proposition': PropositionDetailToJSON(value.proposition),
        'horse': HorseDetailToJSON(value.horse),
        'driverChoice': value.driverChoice,
        'drivers': value.drivers === undefined ? undefined : (value.drivers as Array<any>).map(DriverToJSON),
        'message': value.message,
        'blinkers': BlinkersToJSON(value.blinkers),
        'exemptions': value.exemptions === undefined ? undefined : (value.exemptions as Array<any>).map(ExemptionToJSON),
        'exemptionMessage': value.exemptionMessage,
        'updateDateTime': value.updateDateTime === undefined ? undefined : value.updateDateTime.toISOString(),
        'updateTrainer': value.updateTrainer,
        'incompleteStatusMessage': value.incompleteStatusMessage,
    };
}

/**
* @export
* @enum {string}
*/
export enum DeclaredEntryDetailDriverChoiceEnum {
    SPECIFIED_DRIVER = 'SPECIFIED_DRIVER',
    SPECIFY_LATER = 'SPECIFY_LATER',
    FOREIGN_DRIVER = 'FOREIGN_DRIVER'
}



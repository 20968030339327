import theme from '@main/theme';
import { base, BaseProps } from '@primitives/base';
import styled from 'styled-components';
import {
  position,
  PositionProps,
  typography,
  TypographyProps,
} from 'styled-system';

interface Props extends BaseProps, PositionProps, TypographyProps {
  color?: string | string[];
}

export const H1 = styled.h1<Props>`
  ${base(position, typography)}
`;

export const H2 = styled.h2<Props>`
  ${base(position, typography)}
`;

export const H3 = styled.h3<Props>`
  ${base(position, typography)}
`;

export const H4 = styled.h4<Props>`
  ${base(position, typography)}
`;

export const H5 = styled.h5<Props>`
  ${base(position, typography)}
`;

export const H6 = styled.h6<Props>`
  ${base(position, typography)}
`;

H1.defaultProps = {
  color: 'deep-blue',
  fontWeight: '400',
  ml: 2,
  fontSize: theme.fontSizes.h1,
  textAlign: 'left',
  fontFamily: 'Adamina',
};

H2.defaultProps = {
  color: 'deep-blue',
  fontWeight: '400',
  fontSize: theme.fontSizes.h2,
  textAlign: 'left',
  fontFamily: 'Lato',
};

H3.defaultProps = {
  color: 'deep-blue',
  fontWeight: '400',
  fontSize: theme.fontSizes.h3,
  lineHeight: 1.25,
  textAlign: 'left',
  fontFamily: 'Lato',
};

H4.defaultProps = {
  color: 'gray6',
  fontWeight: '400',
  fontSize: theme.fontSizes.h4,
  lineHeight: 1.25,
  textAlign: 'left',
};

H5.defaultProps = {
  color: 'gray6',
  fontWeight: '700',
  fontSize: theme.fontSizes.h5,
  lineHeight: 1.25,
  textAlign: 'left',
};

H6.defaultProps = {
  color: 'dim-gray',
  fontWeight: '300',
  fontSize: theme.fontSizes.h6,
  lineHeight: 1.25,
  textAlign: 'left',
};
export const Text = styled.div<Props>`
  ${base(typography)};
`;

Text.defaultProps = {
  //fontSize: 'text',
  lineHeight: 1.25,
};

export const Small = styled.div<Props>`
  ${base(typography)};
`;

Small.defaultProps = {
  fontSize: 'small',
  lineHeight: 1.2,
};

export const Tiny = styled.div<Props>`
  ${base(typography)};
`;

Tiny.defaultProps = {
  fontSize: 'tiny',
  lineHeight: 1.2,
};

export const Micro = styled.div<Props>`
  ${base(typography)};
`;

Micro.defaultProps = {
  fontSize: 'micro',
  lineHeight: 1.2,
};

export const Lead = styled.div<Props>`
  ${base(typography)};
`;

Lead.defaultProps = {
  fontSize: 'lead',
  lineHeight: 1.25,
};

export const Label = styled.label<Props>`
  ${base(typography)};
`;

Label.defaultProps = {
  fontSize: 'tiny',
  lineHeight: '13px',
  fontWeight: 'bold',
  mb: '4px',
  display: 'inline-block',
};

import {
  getValidatedLoggedInPerson,
  Person,
} from '../../generated/ownership-transfers/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  validatedLoggedInPerson?: Person;
}

const useGetValidatedPerson = (): ApiResponse<Person, Entities> =>
  useApi<Person>({
    query: getValidatedLoggedInPerson<Entities>({
      transform: responseBody => ({
        validatedLoggedInPerson: responseBody,
      }),
      update: {
        validatedLoggedInPerson: (prev, next): typeof next => next,
      },
    }),
    selector: state => state.entities?.validatedLoggedInPerson,
  });

export default useGetValidatedPerson;

// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    DriverCostProducts,
    DriverCostProductsFromJSON,
    DriverCostProductsToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ProductInformation,
    ProductInformationFromJSON,
    ProductInformationToJSON,
    StartCostProducts,
    StartCostProductsFromJSON,
    StartCostProductsToJSON,
} from '../models';

export interface CreateDefaultProductRequest {
    licenseId: number;
    productInformation: ProductInformation;
}

export interface DeleteTransactionProductRequest {
    licenseId: number;
    id: number;
}

export interface GetDriverCostTransactionProductsRequest {
    licenseId: number;
    raceDayId: number;
    licensecategory: string;
}

export interface GetHorseCostTransactionProductsRequest {
    licenseId: number;
}

export interface GetStartCostTransactionProductsRequest {
    licenseId: number;
    raceDayId: number;
}

export interface GetTransactionProductsRequest {
    licenseId: number;
}

export interface UpdateTransactionProductRequest {
    licenseId: number;
    id: number;
    productInformation: ProductInformation;
}


/**
 * Creates a default product of the provided license holder (w.r.t. organisation and source of data).
 */
function createDefaultProductRaw<T>(requestParameters: CreateDefaultProductRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling createDefaultProduct.');
    }

    if (requestParameters.productInformation === null || requestParameters.productInformation === undefined) {
        throw new runtime.RequiredError('productInformation','Required parameter requestParameters.productInformation was null or undefined when calling createDefaultProduct.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/transactionproducts`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ProductInformationToJSON(requestParameters.productInformation),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Creates a default product of the provided license holder (w.r.t. organisation and source of data).
*/
export function createDefaultProduct<T>(requestParameters: CreateDefaultProductRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return createDefaultProductRaw(requestParameters, requestConfig);
}

/**
 * Deletes a default product of the provided license holder (w.r.t. organisation and source of data)
 */
function deleteTransactionProductRaw<T>(requestParameters: DeleteTransactionProductRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling deleteTransactionProduct.');
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTransactionProduct.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/transactionproducts/{id}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Deletes a default product of the provided license holder (w.r.t. organisation and source of data)
*/
export function deleteTransactionProduct<T>(requestParameters: DeleteTransactionProductRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteTransactionProductRaw(requestParameters, requestConfig);
}

/**
 * Fetches race day and driver cost specific products of the provided race day, licenseholder and the licensecategory parameter.
 */
function getDriverCostTransactionProductsRaw<T>(requestParameters: GetDriverCostTransactionProductsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<DriverCostProducts>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getDriverCostTransactionProducts.');
    }

    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling getDriverCostTransactionProducts.');
    }

    if (requestParameters.licensecategory === null || requestParameters.licensecategory === undefined) {
        throw new runtime.RequiredError('licensecategory','Required parameter requestParameters.licensecategory was null or undefined when calling getDriverCostTransactionProducts.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.licensecategory !== undefined) {
        queryParameters['licensecategory'] = requestParameters.licensecategory;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/transactionproducts/drivers/{raceDayId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(DriverCostProductsFromJSON), text);
    }

    return config;
}

/**
* Fetches race day and driver cost specific products of the provided race day, licenseholder and the licensecategory parameter.
*/
export function getDriverCostTransactionProducts<T>(requestParameters: GetDriverCostTransactionProductsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<DriverCostProducts>>): QueryConfig<T> {
    return getDriverCostTransactionProductsRaw(requestParameters, requestConfig);
}

/**
 * Fetches horses cost specific products of the provided license holder (w.r.t. organisation and source of data)
 */
function getHorseCostTransactionProductsRaw<T>(requestParameters: GetHorseCostTransactionProductsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<ProductInformation>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getHorseCostTransactionProducts.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/transactionproducts/horses`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(ProductInformationFromJSON), text);
    }

    return config;
}

/**
* Fetches horses cost specific products of the provided license holder (w.r.t. organisation and source of data)
*/
export function getHorseCostTransactionProducts<T>(requestParameters: GetHorseCostTransactionProductsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<ProductInformation>>): QueryConfig<T> {
    return getHorseCostTransactionProductsRaw(requestParameters, requestConfig);
}

/**
 * Fetches race day and start cost specific products of the provided license holder and race day id (w.r.t. organisation and source of data)
 */
function getStartCostTransactionProductsRaw<T>(requestParameters: GetStartCostTransactionProductsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<StartCostProducts>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getStartCostTransactionProducts.');
    }

    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling getStartCostTransactionProducts.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/transactionproducts/starts/{raceDayId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(StartCostProductsFromJSON), text);
    }

    return config;
}

/**
* Fetches race day and start cost specific products of the provided license holder and race day id (w.r.t. organisation and source of data)
*/
export function getStartCostTransactionProducts<T>(requestParameters: GetStartCostTransactionProductsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<StartCostProducts>>): QueryConfig<T> {
    return getStartCostTransactionProductsRaw(requestParameters, requestConfig);
}

/**
 * Fetches the list of products of the provided license holder (w.r.t. organisation and source of data).
 */
function getTransactionProductsRaw<T>(requestParameters: GetTransactionProductsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<ProductInformation>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getTransactionProducts.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/transactionproducts`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(ProductInformationFromJSON), text);
    }

    return config;
}

/**
* Fetches the list of products of the provided license holder (w.r.t. organisation and source of data).
*/
export function getTransactionProducts<T>(requestParameters: GetTransactionProductsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<ProductInformation>>): QueryConfig<T> {
    return getTransactionProductsRaw(requestParameters, requestConfig);
}

/**
 * Updates a default product of the provided license holder (w.r.t. organisation and source of data)
 */
function updateTransactionProductRaw<T>(requestParameters: UpdateTransactionProductRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling updateTransactionProduct.');
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTransactionProduct.');
    }

    if (requestParameters.productInformation === null || requestParameters.productInformation === undefined) {
        throw new runtime.RequiredError('productInformation','Required parameter requestParameters.productInformation was null or undefined when calling updateTransactionProduct.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/transactionproducts/{id}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || ProductInformationToJSON(requestParameters.productInformation),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Updates a default product of the provided license holder (w.r.t. organisation and source of data)
*/
export function updateTransactionProduct<T>(requestParameters: UpdateTransactionProductRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateTransactionProductRaw(requestParameters, requestConfig);
}


import { Entities } from '@apis';
import Box from '@primitives/Box';
import ButtonLink from '@primitives/Button/Link';
import Icon from '@primitives/Icon';
import { Text } from '@primitives/Typography';
import React, { useState } from 'react';
import { MutationResponse } from 'src/apis/utils/types';
import { Envelope } from 'styled-icons/fa-regular';

type BoxParameters = Parameters<typeof Box>;

type Props = {
  param1: number;
  param2: number;
  useEmailReceipt: (
    param1: number,
    param2: number,
  ) =>
    | MutationResponse<Entities['emailEnrollmentReceipt'], Entities, unknown>
    | MutationResponse<
        Entities['startDeclaredDetailEmailReceipt'],
        Entities,
        unknown
      >;
  setError: React.Dispatch<React.SetStateAction<string>>;
} & BoxParameters[0];

export default function EmailReceipt({
  param1,
  param2,
  setError,
  useEmailReceipt,
  ...rest
}: Props): JSX.Element {
  const { action, loading } = useEmailReceipt(param1, param2);
  const [success, setSuccess] = useState<boolean>(false);

  return (
    <Box {...rest}>
      {success && <Text>Kvittensen är skickad</Text>}
      {loading && <Text>Skickar...</Text>}
      {!success && !loading && (
        <ButtonLink
          disabled={loading}
          display="flex"
          alignItems="center"
          onClick={async () => {
            setError('');

            const { body } = await action(undefined);

            if (body.errorMessage) {
              setError(body.errorMessage);
            } else if (body.errors) {
              setError(body.errors.map(err => err.errorMessage).join(' '));
            } else {
              setSuccess(true);
            }
          }}
        >
          Maila kvittens
          <Icon size={16} as={Envelope} color="blue" ml={1} mb="-2px" />
        </ButtonLink>
      )}
    </Box>
  );
}

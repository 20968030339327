import { getTracks, Track } from '@generated/tracks/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Tracks = Track[] | undefined;

export interface Entities {
  tracks?: {
    [key: string]: Tracks;
  };
}

const useGetTracks = (
  licenseTracks: boolean,
): ApiResponse<Tracks, Entities> => {
  const key = licenseTracks ? 'licenseTracks' : 'tracks';
  const query = getTracks<Entities>(
    {
      licenseTracks,
    },
    {
      transform: responseBody => ({
        tracks: {
          [key]: responseBody,
        },
      }),
      update: {
        tracks: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Tracks => state.entities?.tracks?.[key];

  return useApi<Tracks>({
    query,
    selector,
  });
};

export default useGetTracks;

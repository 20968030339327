// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActorResourceChartPositions,
    ActorResourceChartPositionsFromJSON,
    ActorResourceChartPositionsToJSON,
    ActorResourceImage,
    ActorResourceImageFromJSON,
    ActorResourceImageToJSON,
} from './';

/**
 * 
 * @export
 * @interface ActorResource
 */
export interface ActorResource  {
    /**
     * 
     * @type {number}
     * @memberof ActorResource
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ActorResource
     */
    type?: ActorResourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActorResource
     */
    bioText?: string;
    /**
     * 
     * @type {ActorResourceImage}
     * @memberof ActorResource
     */
    image?: ActorResourceImage;
    /**
     * 
     * @type {ActorResourceImage}
     * @memberof ActorResource
     */
    dressImage?: string; 
    /**
     * 
     * @type {number}
     * @memberof ActorResource
     */
    activeSince?: number;
    /**
     * 
     * @type {number}
     * @memberof ActorResource
     */
    birthYear?: number;
    /**
     * 
     * @type {string}
     * @memberof ActorResource
     */
    residence?: string;
    /**
     * 
     * @type {Array<ActorResourceChartPositions>}
     * @memberof ActorResource
     */
    chartPositions?: Array<ActorResourceChartPositions>;
    /**
     * 
     * @type {boolean}
     * @memberof ActorResource
     */
    valid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActorResource
     */
    facebookLink?: string;
    /**
     * 
     * @type {string}
     * @memberof ActorResource
     */
    twitterLink?: string;
    /**
     * 
     * @type {string}
     * @memberof ActorResource
     */
    instagramLink?: string;
}

export function ActorResourceFromJSON(json: any): ActorResource {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'bioText': !exists(json, 'bio_text') ? undefined : json['bio_text'],
        'image': !exists(json, 'image') ? undefined : ActorResourceImageFromJSON(json['image']),
        'dressImage': !exists(json, 'dress_image') ? undefined : json['dress_image'],
        'activeSince': !exists(json, 'active_since') ? undefined : json['active_since'],
        'birthYear': !exists(json, 'birth_year') ? undefined : json['birth_year'],
        'residence': !exists(json, 'residence') ? undefined : json['residence'],
        'chartPositions': !exists(json, 'chart_positions') ? undefined : (json['chart_positions'] as Array<any>).map(ActorResourceChartPositionsFromJSON),
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
        'facebookLink': !exists(json, 'facebook_link') ? undefined : json['facebook_link'],
        'twitterLink': !exists(json, 'twitter_link') ? undefined : json['twitter_link'],
        'instagramLink': !exists(json, 'instagram_link') ? undefined : json['instagram_link'],
    };
}

export function ActorResourceToJSON(value?: ActorResource): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'type': value.type,
        'bio_text': value.bioText,
        'image': ActorResourceImageToJSON(value.image),
        'dress_image': value.dressImage,
        'active_since': value.activeSince,
        'birth_year': value.birthYear,
        'residence': value.residence,
        'chart_positions': value.chartPositions === undefined ? undefined : (value.chartPositions as Array<any>).map(ActorResourceChartPositionsToJSON),
        'valid': value.valid,
        'facebook_link': value.facebookLink,
        'twitter_link': value.twitterLink,
        'instagram_link': value.instagramLink,
    };
}

/**
* @export
* @enum {string}
*/
export enum ActorResourceTypeEnum {
    Horse = 'horse',
    Rider = 'rider',
    Trainer = 'trainer',
    Owner = 'owner',
    Breeder = 'breeder'
}



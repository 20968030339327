import { useCreateContract, useGetPartOwners } from '@apis';
import Confirm from '@components/Confirm';
import { AuthenticatedUser } from '@generated/account/src';
import { OwnershipTransfer } from '@generated/ownership-transfers/src';
import { OperatorEnum, resetQuery } from '@main/state/queriesReducer';
import { formatTz } from '@utils/dateUtils';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

interface Props {
  user: AuthenticatedUser;
  transfer: OwnershipTransfer;
  onClose: () => Promise<void>;
  loading?: boolean;
  ownershipName?: string;
  isLeasing?: boolean;
}

const PartOwnersConfirm = ({
  user,
  transfer,
  onClose,
  loading = false,
  ownershipName,
  isLeasing = false,
}: Props): JSX.Element => {
  const { data: partowners } = useGetPartOwners(user.licenseId, transfer.id);

  const dispatch = useDispatch();

  const serviceType = isLeasing ? 'LEASING' : 'OWNERSHIP_TRANSFERS';

  const {
    error: contractError,
    fetch: saveContract,
    loading: isSigning,
  } = useCreateContract(user.licenseId, serviceType);

  const history = useHistory();

  const onSign = useCallback(async () => {
    const { success } = await saveContract({ transferId: transfer.id });

    if (success) {
      dispatch(
        resetQuery(
          `ongoingTransfers:${user.licenseId}`,
          OperatorEnum.STARTS_WITH,
        ),
      );
      dispatch(
        resetQuery(
          `representativeHorses:${user.licenseId}`,
          OperatorEnum.STARTS_WITH,
        ),
      );
      history.push(
        isLeasing
          ? '/minasidor/leasing/pagande?message=signing'
          : '/minasidor/agarskiften/pagande?message=signing',
      );
    }
  }, [dispatch, history, saveContract, transfer.id, user.licenseId, isLeasing]);

  return (
    <Confirm
      open
      closeModal={onClose}
      loading={isSigning || loading}
      action={
        isLeasing
          ? 'Skicka leasingavtal till signering'
          : 'Skicka ägarskifte till signering'
      }
      colorScheme="primary"
      onAction={onSign}
      error={contractError}
      wideButtons={true}
      wideMobileButtons={true}
      title={isLeasing ? 'Registrera leasingavtal?' : 'Registrera ägarskifte?'}
      description={
        <>
          <p>
            {isLeasing
              ? 'Är du säker på att du vill registrera leasingavtal för hästen'
              : 'Är du säker på att du vill registrera ägarskiftet för hästen'}{' '}
            <b>{transfer.horse.horseName}</b>?
          </p>
          <p>
            <b>{isLeasing ? 'Leasingdatum' : 'Försäljningsdatum'}</b>
            <br />
            {formatTz(transfer.effectiveDate, 'yyyy-MM-dd')}
          </p>
          {ownershipName && (
            <p>
              <b>Ägarskap</b>
              <br />
              {ownershipName.split(', ').map((name, index) => (
                <div key={`o_${index}`}>{name}</div>
              ))}
            </p>
          )}
          <p>
            <b>Företrädare</b>
            <br />
            {transfer.buyerRepresentative.name}
          </p>
          <p>
            <b>{isLeasing ? 'Hyrestagare' : 'Delägare'}</b>
            <br />
            {partowners.length === 0
              ? isLeasing
                ? 'Inga övriga hyrestagare'
                : 'Inga delägare'
              : partowners.map(p => <div key={p.id}>{p.name}</div>)}
          </p>
        </>
      }
    />
  );
};

export default PartOwnersConfirm;

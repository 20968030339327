// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface PropositionTextRow
 */
export interface PropositionTextRow  {
    /**
     * 
     * @type {number}
     * @memberof PropositionTextRow
     */
    propositionId: number;
    /**
     * 
     * @type {string}
     * @memberof PropositionTextRow
     */
    propositionNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PropositionTextRow
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof PropositionTextRow
     */
    type: string;
}

export function PropositionTextRowFromJSON(json: any): PropositionTextRow {
    return {
        'propositionId': json['propositionId'],
        'propositionNumber': json['propositionNumber'],
        'text': json['text'],
        'type': json['type'],
    };
}

export function PropositionTextRowToJSON(value?: PropositionTextRow): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'propositionId': value.propositionId,
        'propositionNumber': value.propositionNumber,
        'text': value.text,
        'type': value.type,
    };
}



import {
  getTrainerStatisticsSummary,
  TrainerStatisticsSummary,
} from '@generated/licenseholders/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = TrainerStatisticsSummary | undefined;

export interface Entities {
  trainerStatisticsSummary?: {
    [id: number]: Result;
  };
}

const getQuery = (id: number): QueryConfig<Entities> =>
  getTrainerStatisticsSummary<Entities>(
    {
      licenseId: id,
    },
    {
      transform: responseBody => ({
        trainerStatisticsSummary: { [id]: responseBody },
      }),
      update: {
        trainerStatisticsSummary: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetTrainerStatisticsSummary = (
  id: number,
): ApiResponse<Result, Entities> => {
  const query = getQuery(id);
  const selector = (state: State): Result =>
    state.entities?.trainerStatisticsSummary?.[id];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetTrainerStatisticsSummary;

import { Entities } from '@apis';
import { getNewPassword, GetNewPasswordRequest } from '@generated/account/src';
import qs from 'qs';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../utils/types';
import { useApiMutateWithoutData } from '../utils/useApiMutate';

const useGetNewPassword = (): MutationResponseWithoutData<
  Entities,
  GetNewPasswordRequest
> =>
  useApiMutateWithoutData({
    queryFunc: (args): QueryConfig => {
      const base = getNewPassword(args);
      return {
        ...base,
        body: undefined,
        url: `${base.url}?${qs.stringify(args)}`,
      };
    },

    queryKey: `getNewPassword`,
  });

export default useGetNewPassword;

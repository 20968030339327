// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction  {
    /**
     * The license id of the user
     * @type {number}
     * @memberof Transaction
     */
    licenseId: number;
    /**
     * Transaction id
     * @type {number}
     * @memberof Transaction
     */
    id: number;
    /**
     * Transaction date
     * @type {Date}
     * @memberof Transaction
     */
    transactionDate: Date;
    /**
     * Product type code
     * @type {string}
     * @memberof Transaction
     */
    productCode: string;
    /**
     * Product description
     * @type {string}
     * @memberof Transaction
     */
    productDescription: string;
    /**
     * Abstract product description
     * @type {string}
     * @memberof Transaction
     */
    shortProductDescription: string;
    /**
     * Amount/Total cost
     * @type {number}
     * @memberof Transaction
     */
    amount: number;
    /**
     * Vat amount (momsbelopp)
     * @type {number}
     * @memberof Transaction
     */
    vatAmount: number;
    /**
     * Minimum amount
     * @type {number}
     * @memberof Transaction
     */
    minimumAmount?: number;
    /**
     * Minimum amount
     * @type {number}
     * @memberof Transaction
     */
    maximumAmount?: number;
    /**
     * Representative id (företrädare)
     * @type {number}
     * @memberof Transaction
     */
    representativeId?: number;
    /**
     * Name of representative
     * @type {string}
     * @memberof Transaction
     */
    representativeName?: string;
    /**
     * Ownership id
     * @type {number}
     * @memberof Transaction
     */
    ownershipId?: number;
    /**
     * Name of ownership (ägarskapsbenämning)
     * @type {string}
     * @memberof Transaction
     */
    ownershipName?: string;
    /**
     * Horse id
     * @type {number}
     * @memberof Transaction
     */
    horseId: number;
    /**
     * Horse number
     * @type {string}
     * @memberof Transaction
     */
    horseNumber: string;
    /**
     * Horse name
     * @type {string}
     * @memberof Transaction
     */
    horseName: string;
    /**
     * Text
     * @type {string}
     * @memberof Transaction
     */
    text: string;
    /**
     * Fingerprint of last time this transaction was updated
     * @type {Date}
     * @memberof Transaction
     */
    lastUpdate: Date;
    /**
     * Determines if this transaction can be changed or not
     * @type {boolean}
     * @memberof Transaction
     */
    changeable: boolean;
    /**
     * Determines if this transaction has been rejected or not (is rejected if the invoice receiver is blocked)
     * @type {boolean}
     * @memberof Transaction
     */
    rejectedTransaction: boolean;
    /**
     * Track id
     * @type {string}
     * @memberof Transaction
     */
    trackId?: string;
    /**
     * Race id
     * @type {number}
     * @memberof Transaction
     */
    raceId?: number;
    /**
     * Race number
     * @type {number}
     * @memberof Transaction
     */
    raceNumber?: number;
    /**
     * Start id
     * @type {number}
     * @memberof Transaction
     */
    startId?: number;
    /**
     * Race day id
     * @type {number}
     * @memberof Transaction
     */
    raceDayId?: number;
}

export function TransactionFromJSON(json: any): Transaction {
    return {
        'licenseId': json['licenseId'],
        'id': json['id'],
        'transactionDate': parseDateTime(json['transactionDate']),
        'productCode': json['productCode'],
        'productDescription': json['productDescription'],
        'shortProductDescription': json['shortProductDescription'],
        'amount': json['amount'],
        'vatAmount': json['vatAmount'],
        'minimumAmount': !exists(json, 'minimumAmount') ? undefined : json['minimumAmount'],
        'maximumAmount': !exists(json, 'maximumAmount') ? undefined : json['maximumAmount'],
        'representativeId': !exists(json, 'representativeId') ? undefined : json['representativeId'],
        'representativeName': !exists(json, 'representativeName') ? undefined : json['representativeName'],
        'ownershipId': !exists(json, 'ownershipId') ? undefined : json['ownershipId'],
        'ownershipName': !exists(json, 'ownershipName') ? undefined : json['ownershipName'],
        'horseId': json['horseId'],
        'horseNumber': json['horseNumber'],
        'horseName': json['horseName'],
        'text': json['text'],
        'lastUpdate': parseDateTime(json['lastUpdate']),
        'changeable': json['changeable'],
        'rejectedTransaction': json['rejectedTransaction'],
        'trackId': !exists(json, 'trackId') ? undefined : json['trackId'],
        'raceId': !exists(json, 'raceId') ? undefined : json['raceId'],
        'raceNumber': !exists(json, 'raceNumber') ? undefined : json['raceNumber'],
        'startId': !exists(json, 'startId') ? undefined : json['startId'],
        'raceDayId': !exists(json, 'raceDayId') ? undefined : json['raceDayId'],
    };
}

export function TransactionToJSON(value?: Transaction): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseId': value.licenseId,
        'id': value.id,
        'transactionDate': value.transactionDate.toLocalDate(),
        'productCode': value.productCode,
        'productDescription': value.productDescription,
        'shortProductDescription': value.shortProductDescription,
        'amount': value.amount,
        'vatAmount': value.vatAmount,
        'minimumAmount': value.minimumAmount,
        'maximumAmount': value.maximumAmount,
        'representativeId': value.representativeId,
        'representativeName': value.representativeName,
        'ownershipId': value.ownershipId,
        'ownershipName': value.ownershipName,
        'horseId': value.horseId,
        'horseNumber': value.horseNumber,
        'horseName': value.horseName,
        'text': value.text,
        'lastUpdate': value.lastUpdate.toISOString(),
        'changeable': value.changeable,
        'rejectedTransaction': value.rejectedTransaction,
        'trackId': value.trackId,
        'raceId': value.raceId,
        'raceNumber': value.raceNumber,
        'startId': value.startId,
        'raceDayId': value.raceDayId,
    };
}



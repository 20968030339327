import styled from 'styled-components';
import { typography, TypographyProps } from 'styled-system';

import { base, BaseProps } from '../base';

const TextArea = styled.textarea<BaseProps & TypographyProps>`
  ${base(typography)}
  width: 100%;
  min-height: 80px;
  resize: vertical;
  background-color: #fbfcfd;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition:
    box-shadow 150ms ease-in,
    background-image 150ms ease-in,
    background-size 150ms ease-in,
    background-color 150ms ease-in;
  font-weight: 400;
  color: #343741;
  border: 1px solid ${props => props.theme.colors['gray3']};

  border-radius: 0;
  padding: 6px;
  &:focus {
    outline: none;
    background-color: white;

    background-size: 100% 100%;
  }
`;

export default TextArea;

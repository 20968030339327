import { useSearch } from '@apis';
import Card, { FormCard } from '@components/Card';
import { EmptyState } from '@components/InfoBlock';
import { PageLayout } from '@components/PageLayouts';
import Pagination from '@components/Pagination';
import Table from '@components/Table';
import {
  SearchResultItem,
  SearchSportInfoTypeEnum,
} from '@generated/content/src';
import useNumericQueryParams from '@hooks/useNumericQueryParams';
import useQuery from '@hooks/useQuery';
import Box from '@primitives/Box';
import { H3, Text } from '@primitives/Typography';
import qs from 'qs';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import AboveSearchResult from './AboveSearchResult';
import SearchForm from './SearchForm';

enum allSportinfoEnum {
  allSportinfo = 'all_sportinfo',
}
interface FormState {
  query: string;
  type?: SearchSportInfoTypeEnum | allSportinfoEnum;
}

export default function Search(): JSX.Element {
  const params = useQuery<Partial<FormState>>(true);
  const numParams = useNumericQueryParams();

  const { query = '', type } = params;
  const { pageSize = 30, page = 1 } = numParams;

  const { data } = useSearch({
    query,
    type: type === allSportinfoEnum.allSportinfo ? null : type,
    limit: pageSize,
    offset: (page - 1) * pageSize,
    appMenu: type === allSportinfoEnum.allSportinfo ? null : true,
  });

  const { data: dataWithParamContent } = useSearch({
    query,
    type: type === allSportinfoEnum.allSportinfo ? null : type,
    limit: pageSize,
    content: true,
  });

  const pageCount = Math.ceil(data?.total / pageSize);

  const emptyQuery = !query;

  const history = useHistory();
  const updatePage = useCallback(
    newPage => {
      history.push({
        search: qs.stringify({
          ...params,
          ...numParams,
          type:
            params.type === allSportinfoEnum.allSportinfo ? null : params.type,
          page: newPage,
        }),
      });
    },
    [history, params, numParams],
  );

  const updatePageSize = useCallback(
    newPageSize => {
      history.push({
        search: qs.stringify({
          ...params,
          ...numParams,
          type:
            params.type === allSportinfoEnum.allSportinfo ? null : params.type,
          pageSize: newPageSize,
          page: 1,
        }),
      });
    },
    [history, params, numParams],
  );

  return (
    <PageLayout
      headline="Sökresultat"
      pageSize="medium"
      cardOrNoCardWrapperStyling={{
        px: 0,
        mt: ['-1px', , 0],
      }}
      childrenWrapperStyling={{ px: [4, , 0] }}
      form={
        <FormCard variant="tight">
          <SearchForm isSearchPage />
        </FormCard>
      }
      noCard
    >
      {emptyQuery && (
        <EmptyState content="Ange minst ett sökkriterie för att söka" />
      )}
      {!emptyQuery && data?.results?.length === 0 && (
        <>
          <AboveSearchResult
            data={data}
            amountOfHitsOnMainPAge={dataWithParamContent?.totalContent}
            query={query}
          />
          <EmptyState content="Din sökning gav inga träffar" />
        </>
      )}

      {!emptyQuery && data?.results?.length > 0 && (
        <>
          <AboveSearchResult
            data={data}
            amountOfHitsOnMainPAge={dataWithParamContent?.totalContent}
            query={query}
          />
          <Table<SearchResultItem>
            searchVertionStyleing={true}
            data={data?.results}
            columns={[
              {
                id: '0',
                accessor: row => (
                  <Card sx={{ whiteSpace: 'break-spaces' }}>
                    <a href={row.url}>
                      <H3 color="blue">{row.name}</H3>
                    </a>
                    <Text color="dim-gray">{row.displayType}</Text>
                  </Card>
                ),
              },
            ]}
          />
          <Box pl={[3, , 3]}>
            <Pagination
              pageIndex={page - 1}
              nextPage={() => updatePage(page + 1)}
              previousPage={() => updatePage(page - 1)}
              gotoPage={newPage => updatePage(newPage + 1)}
              pageSize={pageSize} // pageSize =  amount of rows on one page
              setPageSize={newPageSize => updatePageSize(newPageSize)}
              pageCount={pageCount} // pageCount = amount pages
              canNextPage={page < pageCount}
              canPreviousPage={page > 1}
              rowsSize={data?.total || 0} // rowsSize = amount of total rows on all pages
            />
          </Box>
        </>
      )}
    </PageLayout>
  );
}

import Box from '@primitives/Box';
import React from 'react';
import styled from 'styled-components';

import { sizeScheme } from './variants';

type Variant = 'large' | 'medium' | 'small';

interface PageWrapperProps {
  variant?: Variant;
  children: React.ReactNode;
}

interface StyledBoxProps {
  sizeScheme: Variant;
}

const StyledBox = styled(Box)<StyledBoxProps>`
  ${sizeScheme};
`;

const PageWrapper = ({ children, variant }: PageWrapperProps): JSX.Element => (
  <Box mx={[4, 0, 0]} mt={[4, 0, 0]}>
    <StyledBox sizeScheme={variant} mx="auto" mt={[0, 0, 4]}>
      {children}
    </StyledBox>
  </Box>
);

PageWrapper.defaultProps = {
  variant: 'large',
};

export default PageWrapper;

// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    LinkableHorse,
    LinkableHorseFromJSON,
    LinkableHorseToJSON,
} from './';

/**
 * 
 * @export
 * @interface DopingTestedHorse
 */
export interface DopingTestedHorse  {
    /**
     * 
     * @type {LinkableHorse}
     * @memberof DopingTestedHorse
     */
    horse: LinkableHorse;
    /**
     * 
     * @type {string}
     * @memberof DopingTestedHorse
     */
    cause: string;
}

export function DopingTestedHorseFromJSON(json: any): DopingTestedHorse {
    return {
        'horse': LinkableHorseFromJSON(json['horse']),
        'cause': json['cause'],
    };
}

export function DopingTestedHorseToJSON(value?: DopingTestedHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horse': LinkableHorseToJSON(value.horse),
        'cause': value.cause,
    };
}



import Box, { Flex } from '@primitives/Box';
import Icon from '@primitives/Icon';
import { Link } from '@primitives/Link';
import { Text } from '@primitives/Typography';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { StyledIcon } from 'styled-icons/types';

const StyledBox = styled(Box)`
  @media print {
    padding: 0;
    border-radius: 0;
    background: transparent;
  }
`;

const MobileLinkWrapper = styled(Box)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid ${props => props.theme.colors.gray2};
`;

const HeaderExtLink = styled.a`
  width: 100%;
  font-size: 13px;
  text-align: right;
  margin-top: auto;
`;

const MobileHeaderExtLink = styled(HeaderExtLink)`
  text-align: center;
`;

type BoxParameters = Parameters<typeof Box>;

type Props = {
  children?: ReactNode;
  icon?: StyledIcon;
  header?: string;
  headerAlwaysBold?: boolean;
  link?: string;
  externalLink?: string;
  externalLinkTitle?: string;
  linkTitle?: string;
  ctaLink?: {
    to: string;
    text: string;
  };
  maxHeight?: string[];
} & BoxParameters[0];

const CardWithHeader = ({
  children,
  icon,
  header,
  headerAlwaysBold,
  link,
  linkTitle,
  externalLink,
  externalLinkTitle,
  ctaLink,
  maxHeight,
  ...props
}: Props): JSX.Element => (
  <StyledBox
    borderRadius={'5px'}
    width="100%"
    borderColor="gray2"
    borderWidth="1px"
    borderStyle="solid"
    display="flex"
    flexDirection="column"
    maxHeight={maxHeight}
    {...props}
  >
    <Box
      borderBottomColor="gray2"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      pl={4}
      pr={2}
      pt={4}
      pb={3}
      display="flex"
      justifyContent="space-between"
    >
      <Box display="flex" sx={{ fontSize: 'lead' }}>
        {icon && (
          <Icon mb="auto" mt="auto" as={icon} size={16} color="deep-blue" />
        )}
        <Text
          fontWeight={['bold', , headerAlwaysBold ? 'bold' : 'normal']}
          pl={icon ? 1 : '0px'}
        >
          {header}
        </Text>
      </Box>

      {link ? (
        <Box mt="auto" display={['none', , 'block']}>
          <Link
            to={link}
            width={1}
            fontSize="notes"
            textAlign="right"
            mt="-5px"
          >
            <Text>{linkTitle}</Text>
          </Link>
        </Box>
      ) : null}

      {externalLink ? (
        <Box mt="auto" display={['none', , 'block']}>
          <HeaderExtLink href={externalLink}>{externalLinkTitle}</HeaderExtLink>
        </Box>
      ) : null}
    </Box>

    <Box p={3} flexGrow={1}>
      {children}
    </Box>

    {link && (
      <MobileLinkWrapper p={3} display={[, , 'none']}>
        <Link to={link} width={1} fontSize="notes" textAlign="center">
          <Text>{linkTitle}</Text>
        </Link>
      </MobileLinkWrapper>
    )}

    {externalLink && (
      <MobileLinkWrapper p={3} display={[, , 'none']}>
        <MobileHeaderExtLink href={externalLink}>
          <Text>{externalLinkTitle}</Text>
        </MobileHeaderExtLink>
      </MobileLinkWrapper>
    )}

    {ctaLink && (
      <Flex
        borderTopColor="gray2"
        borderTopWidth="1px"
        borderTopStyle="solid"
        py={3}
      >
        <Link color="blue" fontSize="notes" to={ctaLink.to}>
          {ctaLink.text}
        </Link>
      </Flex>
    )}
  </StyledBox>
);

CardWithHeader.defaultProps = {
  backgroundColor: 'white',
};

export default CardWithHeader;

// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification  {
    /**
     * Transfer id
     * @type {number}
     * @memberof Notification
     */
    transferId?: number;
    /**
     * Transfer status
     * @type {string}
     * @memberof Notification
     */
    transferStatus?: string;
    /**
     * User\'s roles in the transfer
     * @type {Array<string>}
     * @memberof Notification
     */
    userRoles?: Array<NotificationUserRolesEnum>;
    /**
     * Name of horse in transfer.
     * @type {string}
     * @memberof Notification
     */
    horseName?: string;
    /**
     * Service state
     * @type {string}
     * @memberof Notification
     */
    serviceState?: NotificationServiceStateEnum;
}

export function NotificationFromJSON(json: any): Notification {
    return {
        'transferId': !exists(json, 'transferId') ? undefined : json['transferId'],
        'transferStatus': !exists(json, 'transferStatus') ? undefined : json['transferStatus'],
        'userRoles': !exists(json, 'userRoles') ? undefined : json['userRoles'],
        'horseName': !exists(json, 'horseName') ? undefined : json['horseName'],
        'serviceState': !exists(json, 'serviceState') ? undefined : json['serviceState'],
    };
}

export function NotificationToJSON(value?: Notification): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'transferId': value.transferId,
        'transferStatus': value.transferStatus,
        'userRoles': value.userRoles,
        'horseName': value.horseName,
        'serviceState': value.serviceState,
    };
}

/**
* @export
* @enum {string}
*/
export enum NotificationUserRolesEnum {
    BUYER = 'BUYER',
    SELLER = 'SELLER',
    PART_OWNER_SELLER = 'PART_OWNER_SELLER',
    PART_OWNER_BUYER = 'PART_OWNER_BUYER',
    LEGAL_SELLER_REPRESENTATIVE = 'LEGAL_SELLER_REPRESENTATIVE',
    LEGAL_BUYER_REPRESENTATIVE = 'LEGAL_BUYER_REPRESENTATIVE',
    BUYER_OR_SELLER = 'BUYER_OR_SELLER'
}
/**
* @export
* @enum {string}
*/
export enum NotificationServiceStateEnum {
    LEASE_TERMINATION = 'LEASE_TERMINATION',
    LEASE_START = 'LEASE_START',
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS'
}



import List, { LI } from '@primitives/List';
import { Field, useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
  font-weight: normal;
  input {
    margin-right: ${p => p.theme.space[1]}px;
  }
`;

import Error from './Error';

interface Props {
  label: string;
  name: string;
  radios: Array<{ disabled?: boolean; label: string; value: string }>;
}

function Radios({ label, name, radios }: Props): JSX.Element {
  const fieldProps = { name, type: 'radio' };
  const [, meta] = useField(fieldProps);

  return (
    <>
      {label && <label>{label}</label>}
      <List>
        {radios.map(radio => (
          <LI pt={3} key={radio.value}>
            <Label>
              <Field
                {...fieldProps}
                disabled={radio.disabled}
                value={radio.value}
              />
              {radio.label}
            </Label>
          </LI>
        ))}
      </List>
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </>
  );
}

export default Radios;

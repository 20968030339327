/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
enum roleEnum {
  Driver = 'Tevla',
  Partner = 'Delegare',
  Representative = 'Foretredare',
  AccountHolder = 'Kontoinnehavare',
  Licensee = 'Licensinnehavare',
  Trainer = 'Trena',
  Tds = 'TDS',
  TdsDriver = 'TDSTevla',
  Hingsthallare = 'Hingsthallare',
  Delegare = 'Delegare',
  Foretredare = 'Foretredare',
  TempForetredare = 'TempForetredare',
  TempDelegare = 'TempDelegare',
  Entrekort = 'Entrekort',
}

export default roleEnum;

import { DriverReference, getDrivers } from '@generated/licenseholders/src';
import { State } from '@main/store';
import { useCallback } from 'react';

import { LazyApiResponse } from '../utils/types';
import useApiLazy from '../utils/useApiLazy';

type LicenseHolderDrivers = DriverReference[] | undefined;

export interface Entities {
  licenseHolderDrivers?: {
    [key: string]: LicenseHolderDrivers;
  };
}

interface Props {
  firstname?: string;
  lastname?: string;
}

const useGetDrivers = ({
  firstname,
  lastname,
}: Props): LazyApiResponse<LicenseHolderDrivers, Entities> => {
  const key = `${firstname}-${lastname}`;
  const query = getDrivers<Entities>(
    {
      firstname,
      lastname,
    },
    {
      transform: responseBody => ({
        licenseHolderDrivers: {
          [key]: responseBody,
        },
      }),
      update: {
        licenseHolderDrivers: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): LicenseHolderDrivers =>
    state.entities?.licenseHolderDrivers?.[key];

  return useApiLazy<LicenseHolderDrivers>({
    query,
    selector,
  });
};

function useGetLicenseHolderDrivers(
  key: string,
): LazyApiResponse<LicenseHolderDrivers, Entities> {
  const {
    data: fdata,
    error: ferr,
    loading: floading,
    action: faction,
  } = useGetDrivers({
    firstname: key,
  });

  const {
    data: ldata,
    error: lerr,
    loading: lloading,
    action: laction,
    ...rest
  } = useGetDrivers({ lastname: key });

  const action = useCallback(async () => {
    await laction();
    await faction();
  }, [faction, laction]);

  return {
    data: [...(fdata || []), ...(ldata || [])],
    error: ferr || lerr,
    loading: floading || lloading,
    action,
    ...rest,
  };
}

export default useGetLicenseHolderDrivers;

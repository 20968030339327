import { saveStartMonitoring } from '@generated/user/src';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../utils/types';
import { useApiMutateWithoutData } from '../utils/useApiMutate';
import { Entities } from './useGetSavedChartFilters';

const useSaveStartMonitoring = (
  licenseId: number,
): MutationResponseWithoutData<Entities, number> =>
  useApiMutateWithoutData({
    queryFunc: (horseId: number): QueryConfig<Entities> =>
      saveStartMonitoring({
        licenseId,
        horseId,
      }),
    queryKey: 'saveStartMonitoring',
  });

export default useSaveStartMonitoring;

// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Transaction,
    TransactionFromJSON,
    TransactionToJSON,
} from '../models';

export interface DeleteTransactionRequest {
    licenseId: number;
    id: number;
}

export interface GetTransactionRequest {
    licenseId: number;
    id: number;
}

export interface GetTransactionsRequest {
    licenseId: number;
}

export interface InsertTransactionsRequest {
    licenseId: number;
    transaction: Array<Transaction>;
}

export interface UpdateTransactionRequest {
    licenseId: number;
    id: number;
    transaction: Transaction;
}


/**
 * Deletes the transaction of the provided transaction id and licenseholder
 */
function deleteTransactionRaw<T>(requestParameters: DeleteTransactionRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling deleteTransaction.');
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTransaction.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/transactions/{id}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Deletes the transaction of the provided transaction id and licenseholder
*/
export function deleteTransaction<T>(requestParameters: DeleteTransactionRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteTransactionRaw(requestParameters, requestConfig);
}

/**
 * Fetches the requested transaction of the provided licenseholder
 */
function getTransactionRaw<T>(requestParameters: GetTransactionRequest, requestConfig: runtime.TypedQueryConfig<T, Transaction> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getTransaction.');
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTransaction.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/transactions/{id}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(TransactionFromJSON(body), text);
    }

    return config;
}

/**
* Fetches the requested transaction of the provided licenseholder
*/
export function getTransaction<T>(requestParameters: GetTransactionRequest, requestConfig?: runtime.TypedQueryConfig<T, Transaction>): QueryConfig<T> {
    return getTransactionRaw(requestParameters, requestConfig);
}

/**
 * Fetches all registered transactions of the provided licenseholder
 */
function getTransactionsRaw<T>(requestParameters: GetTransactionsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Transaction>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getTransactions.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/transactions`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(TransactionFromJSON), text);
    }

    return config;
}

/**
* Fetches all registered transactions of the provided licenseholder
*/
export function getTransactions<T>(requestParameters: GetTransactionsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Transaction>>): QueryConfig<T> {
    return getTransactionsRaw(requestParameters, requestConfig);
}

/**
 * Registers a list of new transactions of the provided licenseholder. Http 200 will return the transactions meaning something went wrong. Http 204 means the insert went ok
 */
function insertTransactionsRaw<T>(requestParameters: InsertTransactionsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Transaction>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling insertTransactions.');
    }

    if (requestParameters.transaction === null || requestParameters.transaction === undefined) {
        throw new runtime.RequiredError('transaction','Required parameter requestParameters.transaction was null or undefined when calling insertTransactions.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/transactions`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || requestParameters.transaction?.map(TransactionToJSON),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(TransactionFromJSON), text);
    }

    return config;
}

/**
* Registers a list of new transactions of the provided licenseholder. Http 200 will return the transactions meaning something went wrong. Http 204 means the insert went ok
*/
export function insertTransactions<T>(requestParameters: InsertTransactionsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Transaction>>): QueryConfig<T> {
    return insertTransactionsRaw(requestParameters, requestConfig);
}

/**
 * Updates the given transaction of the provided transaction id and licenseholder
 */
function updateTransactionRaw<T>(requestParameters: UpdateTransactionRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling updateTransaction.');
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTransaction.');
    }

    if (requestParameters.transaction === null || requestParameters.transaction === undefined) {
        throw new runtime.RequiredError('transaction','Required parameter requestParameters.transaction was null or undefined when calling updateTransaction.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/transactions/{id}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || TransactionToJSON(requestParameters.transaction),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Updates the given transaction of the provided transaction id and licenseholder
*/
export function updateTransaction<T>(requestParameters: UpdateTransactionRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateTransactionRaw(requestParameters, requestConfig);
}


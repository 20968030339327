// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    BucketLink,
    BucketLinkFromJSON,
    BucketLinkToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    StartListRaceDay,
    StartListRaceDayFromJSON,
    StartListRaceDayToJSON,
} from '../models';

export interface GetStartListRequest {
    raceDayId: number;
}

export interface GetStartlistUrlRequest {
    raceDayId: number;
}


/**
 * Fetch the latest start list.
 */
function getLatestStartListRaw<T>( requestConfig: runtime.TypedQueryConfig<T, number> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/raceinfo/startlists/latest`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        throw "OH NO";
    }

    return config;
}

/**
* Fetch the latest start list.
*/
export function getLatestStartList<T>( requestConfig?: runtime.TypedQueryConfig<T, number>): QueryConfig<T> {
    return getLatestStartListRaw( requestConfig);
}

/**
 * Fetch a start list for a raceday.
 */
function getStartListRaw<T>(requestParameters: GetStartListRequest, requestConfig: runtime.TypedQueryConfig<T, StartListRaceDay> = {}): QueryConfig<T> {
    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling getStartList.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/raceinfo/{raceDayId}/startlists`.replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(StartListRaceDayFromJSON(body), text);
    }

    return config;
}

/**
* Fetch a start list for a raceday.
*/
export function getStartList<T>(requestParameters: GetStartListRequest, requestConfig?: runtime.TypedQueryConfig<T, StartListRaceDay>): QueryConfig<T> {
    return getStartListRaw(requestParameters, requestConfig);
}

/**
 * Get URL to startlist document for a given race day id.
 */
function getStartlistUrlRaw<T>(requestParameters: GetStartlistUrlRequest, requestConfig: runtime.TypedQueryConfig<T, BucketLink> = {}): QueryConfig<T> {
    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling getStartlistUrl.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/raceinfo/{raceDayId}/startlists/url`.replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(BucketLinkFromJSON(body), text);
    }

    return config;
}

/**
* Get URL to startlist document for a given race day id.
*/
export function getStartlistUrl<T>(requestParameters: GetStartlistUrlRequest, requestConfig?: runtime.TypedQueryConfig<T, BucketLink>): QueryConfig<T> {
    return getStartlistUrlRaw(requestParameters, requestConfig);
}


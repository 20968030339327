export const horse = (id: number, tab = ''): string =>
  `/sportinfo/hastar/${id}${tab}`;

export const licenseholder = (id: number, tab = ''): string =>
  `/sportinfo/licensinnehavare/${id}${tab}`;

export const breeder = (id: number, tab = ''): string =>
  `/sportinfo/uppfodare/${id}${tab}`;

export const owner = (id: number, tab = ''): string =>
  `/sportinfo/agare/${id}${tab}`;

export const racedayresult = (id: number, tab = ''): string =>
  `/tavling/resultat/${id}${tab}`;

export const startlist = (id: number, tab = ''): string =>
  `/tavling/startlistor/${id}${tab}`;

export const entryweightlist = (raceDayId: number): string =>
  `/tavling/viktlistor/${raceDayId}`;

export const entryweightlistDetail = (
  raceDayId: number,
  propId: number,
): string => `/tavling/viktlistor/${raceDayId}/${propId}`;

export const declarationlist = (raceDayId: number): string =>
  `/tavling/anmalningslistor/${raceDayId}`;

export const declarationlistDetail = (
  raceDayId: number,
  propId: number,
): string => `/tavling/anmalningslistor/${raceDayId}/${propId}`;

export const startregistration = (raceDayId: number): string =>
  `/minasidor/startanmalan/tavlingsdagar/${raceDayId}`;

export const enrollment = (raceDayId: number): string =>
  `/anmalan/${raceDayId}`;

export const racedaypropositions = (raceDayId: number): string =>
  `/propositioner/${raceDayId}`;

// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Horse,
    HorseFromJSON,
    HorseToJSON,
} from '../models';

export interface GetRepresentativeHorsesRequest {
    licenseId: number;
    serviceType?: GetRepresentativeHorsesServiceTypeEnum;
}


/**
 * Show all representative\'s horses available for selling
 */
function getRepresentativeHorsesRaw<T>(requestParameters: GetRepresentativeHorsesRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Horse>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getRepresentativeHorses.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.serviceType !== undefined) {
        queryParameters['serviceType'] = requestParameters.serviceType;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/{licenseId}/horses`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(HorseFromJSON), text);
    }

    return config;
}

/**
* Show all representative\'s horses available for selling
*/
export function getRepresentativeHorses<T>(requestParameters: GetRepresentativeHorsesRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Horse>>): QueryConfig<T> {
    return getRepresentativeHorsesRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum GetRepresentativeHorsesServiceTypeEnum {
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS',
    LEASING = 'LEASING'
}

import {
  getNotifications,
  GetNotificationsServiceTypeEnum,
  Notification,
} from '../../generated/ownership-transfers/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  ownershipTransferNotifications?: {
    [key: string]: Notification[];
  };
}

const useGetOwnershipTransferNotifications = (
  licenseId: number,
  serviceType: GetNotificationsServiceTypeEnum = GetNotificationsServiceTypeEnum.OWNERSHIP_TRANSFERS,
): ApiResponse<Notification[], Entities> => {
  const key = `${licenseId}:${serviceType}`;

  return useApi<Notification[]>({
    resetOnUnmount: true,
    query: getNotifications<Entities>(
      { licenseId, serviceType },
      {
        transform: responseBody => ({
          ownershipTransferNotifications: {
            [key]: responseBody,
          },
        }),
        update: {
          ownershipTransferNotifications: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities?.ownershipTransferNotifications?.[key],
  });
};

export default useGetOwnershipTransferNotifications;

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Shareholder,
    ShareholderFromJSON,
    ShareholderToJSON,
} from './';

/**
 * 
 * @export
 * @interface HorseOwnershipSharesDistribution
 */
export interface HorseOwnershipSharesDistribution  {
    /**
     * Total shares in the ownership
     * @type {number}
     * @memberof HorseOwnershipSharesDistribution
     */
    sharesToDistribute: number;
    /**
     * Sum of shares currently distributed on shareholders. Note that this is just a GET helper, it will be ignored when updating
     * @type {number}
     * @memberof HorseOwnershipSharesDistribution
     */
    sharesDistributed: number;
    /**
     * True if the user is authorized to update the shares distribution. Note that this is just a GET helper, it will be ignored when updating
     * @type {boolean}
     * @memberof HorseOwnershipSharesDistribution
     */
    allowedToUpdate: boolean;
    /**
     * 
     * @type {Array<Shareholder>}
     * @memberof HorseOwnershipSharesDistribution
     */
    shareholders: Array<Shareholder>;
}

export function HorseOwnershipSharesDistributionFromJSON(json: any): HorseOwnershipSharesDistribution {
    return {
        'sharesToDistribute': json['sharesToDistribute'],
        'sharesDistributed': json['sharesDistributed'],
        'allowedToUpdate': json['allowedToUpdate'],
        'shareholders': (json['shareholders'] as Array<any>).map(ShareholderFromJSON),
    };
}

export function HorseOwnershipSharesDistributionToJSON(value?: HorseOwnershipSharesDistribution): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'sharesToDistribute': value.sharesToDistribute,
        'sharesDistributed': value.sharesDistributed,
        'allowedToUpdate': value.allowedToUpdate,
        'shareholders': (value.shareholders as Array<any>).map(ShareholderToJSON),
    };
}



import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import {
  getHorseEnrollment,
  HorseEnrollment,
} from '../../generated/horses/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Enrollment = HorseEnrollment[] | undefined;

export interface Entities {
  horseEnrollment?: {
    [id: number]: Enrollment;
  };
}

const getQuery = (horseId: number): QueryConfig<Entities> =>
  getHorseEnrollment<Entities>(
    {
      horseId,
    },
    {
      transform: responseBody => ({
        horseEnrollment: { [horseId]: responseBody },
      }),
      update: {
        horseEnrollment: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetHorseEnrollment = (
  horseId: number,
): ApiResponse<Enrollment, Entities> => {
  const query = getQuery(horseId);
  const selector = (state: State): Enrollment =>
    state.entities?.horseEnrollment?.[horseId];

  return useApi<Enrollment>({
    query,
    selector,
  });
};

export default useGetHorseEnrollment;

// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * License details
 * @export
 * @interface LicenseHolderBasicLicenseInfo
 */
export interface LicenseHolderBasicLicenseInfo  {
    /**
     * The description of the license
     * @type {string}
     * @memberof LicenseHolderBasicLicenseInfo
     */
    description?: string;
    /**
     * The license code
     * @type {string}
     * @memberof LicenseHolderBasicLicenseInfo
     */
    code?: string;
    /**
     * The license category
     * @type {string}
     * @memberof LicenseHolderBasicLicenseInfo
     */
    category?: string;
}

export function LicenseHolderBasicLicenseInfoFromJSON(json: any): LicenseHolderBasicLicenseInfo {
    return {
        'description': !exists(json, 'description') ? undefined : json['description'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'category': !exists(json, 'category') ? undefined : json['category'],
    };
}

export function LicenseHolderBasicLicenseInfoToJSON(value?: LicenseHolderBasicLicenseInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'description': value.description,
        'code': value.code,
        'category': value.category,
    };
}



import css, {
  CSSObject,
  CSSSelectorObject,
  SystemCssProperties,
  Theme,
} from '@styled-system/css';
import CSS from 'csstype';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  styleFn,
  system,
} from 'styled-system';

const animation = system({
  transitionProperty: true,
  transitionDuration: {
    property: 'transitionDuration',
    scale: 'duration',
  },
  transitionTimingFunction: {
    property: 'transitionTimingFunction',
    scale: 'timingFunction',
  },
});

const cursor = system({
  transition: true,
  cursor: { property: 'cursor' },
});

interface CursorProps {
  cursor?: CSS.Property.Cursor;
}

/**
 * The sx prop is like styled-components css prop but allows
 * using values from the theme. E.g
 * <Box sx={{ bg: 'primary' }}/>
 */
export interface SxProps {
  sx?: CSSSelectorObject | SystemCssProperties;
  theme: Theme;
}

const sx = (props: SxProps): CSSObject => css(props.sx)(props.theme);

export interface BaseProps
  extends ColorProps,
    CursorProps,
    SxProps,
    FlexboxProps,
    LayoutProps,
    BorderProps,
    PositionProps,
    SpaceProps {}

export const base =
  (...systemFunctions: styleFn[]) =>
  <P extends SxProps>(props: P): CSSObject & styleFn => {
    const composed = compose(
      animation,
      color,
      cursor,
      flexbox,
      layout,
      space,
      border,
      position,
      ...systemFunctions,
    );

    return { ...composed(props), ...sx(props) };
  };

import { getRaceDays, RaceDay } from '@generated/raceinfo/src';
import { State } from '@main/store';
import { addMonths, endOfMonth, subMinutes } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  raceDays?: {
    [key: string]: RaceDay[];
  };
}

export default function useCurrentRaceDays(): ApiResponse<
  RaceDay[] | undefined,
  Entities
> {
  // Fetch data with a 2 hour offset + timezone offset
  let today = utcToZonedTime(new Date(), 'Europe/Stockholm');
  today = subMinutes(today, today.getTimezoneOffset() + 120);

  const year = today.getFullYear();
  const month = today.getMonth();

  const to = addMonths(endOfMonth(today), 6);

  const query = getRaceDays<Entities>(
    {
      fromracedate: today,
      tosubmissiondate: to,
    },
    {
      transform: responseBody => ({
        raceDays: {
          [`${year}-${month}`]: responseBody,
        },
      }),
      update: {
        raceDays: (prev, next) => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  const selector = (state: State): RaceDay[] | undefined =>
    state.entities.raceDays?.[`${year}-${month}`];

  return useApi<RaceDay[] | undefined>({
    query,
    selector,
  });
}

import Message from '@primitives/Message';
import scrollToTop from '@utils/scrollToTop';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ResetForm from './utils/type/ResetForm';
import SaveRegistrationData from './utils/type/SaveRegistrationData';

interface Props {
  error: string;
  resetForm: ResetForm;
  setRefreshHorses: (func: (prev: number) => number) => void;
  setSaveRegistrationData: (saveRegistrationData: SaveRegistrationData) => void;
  setSubmitted: (submitted: boolean) => void;
}

export default function RegistrationResult({
  error,
  resetForm,
  setRefreshHorses,
  setSaveRegistrationData,
  setSubmitted,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const delay = 5000;

  useEffect(() => {
    if (!error) {
      // Successfull registration!
      setRefreshHorses(prev => prev + 1);
      resetForm();
      setSubmitted(false);
    }
    scrollToTop();
  }, [
    dispatch,
    error,
    resetForm,
    setRefreshHorses,
    setSaveRegistrationData,
    setSubmitted,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setSaveRegistrationData(null);
    }, delay);
  }, [delay, setSaveRegistrationData]);

  return (
    <Message variant={error ? 'errorBox' : 'successBox'} mb={4}>
      {error ? error : 'Sparat!'}
    </Message>
  );
}

import { OwnershipTransfer } from '@generated/ownership-transfers/src';

enum ownershipTransferStatusOrderingEnum {
  GENOMFORD = 0,
  AKTIV,
  INIT,
  WEBKLAR,
  MAKSELJ,
  MAKKOP,
  MAKST,
  MAKTID,
}

/**
 * Helper function to sort ownership transfers by status and expiry date
 * @param itemA first OwnershipTransfer
 * @param itemB second OwnershipTransfer
 */
export function sortByStatusAndExpiryDate(
  itemA: OwnershipTransfer,
  itemB: OwnershipTransfer,
): number {
  // Convert status text to number according to OwnershipTransferStatusOrderingEnum
  const statusA = ownershipTransferStatusOrderingEnum[itemA.status.statusCode];
  const statusB = ownershipTransferStatusOrderingEnum[itemB.status.statusCode];

  // Sort by status
  if (statusA < statusB) {
    return -1;
  }
  if (statusA > statusB) {
    return 1;
  }

  // If not sorted by status, sort by expiry date
  return itemA.expiryDate < itemB.expiryDate
    ? -1
    : itemA.expiryDate > itemB.expiryDate
      ? 1
      : 0;
}

export default ownershipTransferStatusOrderingEnum;

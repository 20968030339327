import {
  DeclaredEntryDetail,
  getHorseStartDeclaredDetail,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Results = DeclaredEntryDetail | undefined;

export interface Entities {
  declaredProposition?: {
    [key: number]: Results;
  };
}

export const startDeclarationQueryKeyPrefix = 'startDeclarationInformation';

export default function useGetStartDeclarationInformation(
  horseId: number,
  propositionId: number,
  force = false,
): ApiResponse<Results, Entities> {
  const key = `${horseId}-${propositionId}`;
  const queryKey = `${startDeclarationQueryKeyPrefix}:${key}`;
  const query = getHorseStartDeclaredDetail<Entities>(
    {
      horseId,
      propositionId,
    },
    {
      force,
      queryKey,
      transform: responseBody => ({
        declaredProposition: {
          [key]: responseBody,
        },
      }),
      update: {
        declaredProposition: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Results =>
    state.entities?.declaredProposition?.[key];

  return useApi<Results>({
    query,
    selector,
  });
}

import InfoBadge from '@components/InfoBadge';
import useAuthenticationMethod from '@hooks/useAuthenticationMethod';
import useForceChangeOfPassword from '@hooks/useForceChangeOfPassword';
import Box from '@primitives/Box';
import { Link } from '@primitives/Link';
import { Ban } from '@styled-icons/fa-solid';
import { CHANGE_PASSWORD_PATH } from '@utils/constants';
import React from 'react';

function ChangePasswordInfo(): JSX.Element {
  const forceChangeOfPassword = useForceChangeOfPassword();
  const authenticationMethod = useAuthenticationMethod();

  return forceChangeOfPassword && authenticationMethod === 'PASSWORD' ? (
    <Box mt={[4, , 5]} px={4} mx="auto" maxWidth="920px">
      <InfoBadge
        color="red"
        message={
          <>
            Du måste byta lösenord omgående för att kunna använda inloggade
            tjänster. <Link to={CHANGE_PASSWORD_PATH}>Ändra lösenord</Link>
          </>
        }
        icon={Ban}
      />
    </Box>
  ) : null;
}

export default ChangePasswordInfo;

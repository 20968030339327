import { Autocomplete as PrimitiveAutocomplete } from '@primitives/Form';
import { AutocompleteProps } from '@primitives/Form/autocomplete';
import { Label, Wrapper } from '@primitives/Form/select';
import React from 'react';

type WrapperParameters = Parameters<typeof Wrapper>;

type Props<TSuggestion> = {
  label?: string;
  loading?: boolean;
} & AutocompleteProps<TSuggestion> &
  WrapperParameters[0];

// eslint-disable-next-line @typescript-eslint/ban-types
const Autocomplete = <TSuggestion extends object>({
  suggestions,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  onSuggestionSelected,
  getSuggestionValue,
  renderSuggestion,
  inputProps,
  loading,
  label,
  ...props
}: Props<TSuggestion>): JSX.Element => (
  <Wrapper {...props}>
    {label && <Label>{label}</Label>}
    <PrimitiveAutocomplete<TSuggestion>
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      loading={loading}
    />
  </Wrapper>
);
export default Autocomplete;

import Box from '@primitives/Box';
import { Text } from '@primitives/Typography';
import { formatTz } from '@utils/dateUtils';
import { sv } from 'date-fns/locale';
import React from 'react';

interface Props {
  date?: Date;
  header: string;
  height?: number;
  mobile: boolean;
}

export default function Heading2({
  date,
  header,
  height,
  mobile,
}: Props): JSX.Element {
  return (
    <Box
      display={['flex']}
      flexGrow={1}
      flexDirection={['column', null, 'row']}
      alignItems="center"
      justifyContent="center"
      height={`${height}px`}
      backgroundColor={['gray1', null, 'inherit']}
      borderStyle="solid"
      borderWidth={[1, null, 0]}
      borderColor="gray2"
      mx={['1px', null, 0]}
      margin={'0px 4px 0px 4px'}
    >
      <Text
        fontSize={['text', null, 'lead']}
        textAlign="center"
        mr={[0, null, 1]}
        fontWeight={date ? 'bold' : 'normal'}
      >
        {header}
      </Text>
      {date && (
        <Text
          fontSize={['text', null, 'lead']}
          sx={{ textTransform: 'uppercase' }}
        >
          {formatTz(date, mobile ? 'yyyy-MM-dd' : 'EEEE d LLLL y', {
            locale: sv,
          })}
        </Text>
      )}
    </Box>
  );
}

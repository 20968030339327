// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Signatory
 */
export interface Signatory  {
    /**
     * Sign status of the Signatory.
     * @type {string}
     * @memberof Signatory
     */
    status?: SignatoryStatusEnum;
    /**
     * Role of the Signatory.
     * @type {string}
     * @memberof Signatory
     */
    role?: SignatoryRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Signatory
     */
    contractLink?: string;
}

export function SignatoryFromJSON(json: any): Signatory {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'contractLink': !exists(json, 'contractLink') ? undefined : json['contractLink'],
    };
}

export function SignatoryToJSON(value?: Signatory): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'role': value.role,
        'contractLink': value.contractLink,
    };
}

/**
* @export
* @enum {string}
*/
export enum SignatoryStatusEnum {
    SIGNING_NOT_NEEDED = 'SIGNING_NOT_NEEDED',
    SIGNING_REQUESTED = 'SIGNING_REQUESTED',
    SIGNED = 'SIGNED'
}
/**
* @export
* @enum {string}
*/
export enum SignatoryRoleEnum {
    BUYER_REPRESENTATIVE = 'BUYER_REPRESENTATIVE',
    SELLER_REPRESENTATIVE = 'SELLER_REPRESENTATIVE',
    BUYER_PART_OWNER = 'BUYER_PART_OWNER',
    OTHER = 'OTHER'
}



import { useGetAllMaresByName } from '@apis';
import Autocomplete from '@components/Autocomplete';
import { CoveringMare } from '@generated/breedings/src';
import Box from '@primitives/Box';
import Icon, { HorseIcon } from '@primitives/Icon';
import { Text } from '@primitives/Typography';
import { formatTz, isValidDate } from '@utils/dateUtils';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { FormState } from './CoveringForm';
import SelectedMare from './CoveringFormSelectMare';

export default function (): JSX.Element {
  const [query, setQuery] = useState('');
  const [state, setState] = useState('');

  const { data, action: fetch } = useGetAllMaresByName(query);
  const { values, setFieldValue } = useFormikContext<FormState>();

  const { mareId } = values;

  useEffect(() => {
    if (query.length >= 2) {
      fetch();
    }
  }, [fetch, query]);

  return (
    <Box verticalSpacing={3} my={3}>
      <Box maxWidth="300px">
        <Autocomplete<CoveringMare>
          suggestions={data || []}
          onSuggestionSelected={(e, { suggestion }) => {
            setFieldValue('mareId', suggestion.id);
          }}
          onSuggestionsFetchRequested={arg => {
            setQuery(arg.value);
          }}
          onSuggestionsClearRequested={() => {
            setQuery('');
          }}
          getSuggestionValue={suggestion => suggestion.name}
          renderSuggestion={suggestion => (
            <Text fontSize={['lead', , 'text']} color="deep-blue">
              <Icon size={12} as={HorseIcon} mr={2} />
              {suggestion.name}
              {isValidDate(suggestion?.bornDate) &&
                ` (${formatTz(suggestion.bornDate, 'yyyy-MM-dd')})`}
            </Text>
          )}
          inputProps={{
            value: state,
            placeholder: 'Skriv 2 eller fler tecken',
            disabled: mareId != null,
            onChange: (e, { newValue }) => {
              setState(newValue);
            },
          }}
        />
      </Box>
      {mareId && <SelectedMare mareId={mareId} />}
    </Box>
  );
}

import {
  ComingStart,
  getComingStartsForBreeder,
} from '@generated/sportactors/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type BreederComingStarts = ComingStart[] | undefined;

export interface Entities {
  breederComingStarts?: {
    [id: number]: BreederComingStarts;
  };
}

const useGetBreeder = (
  breederId: number,
): ApiResponse<BreederComingStarts, Entities> => {
  // 1. Query
  const query = getComingStartsForBreeder<Entities>(
    {
      breederId,
    },
    {
      transform: responseBody => ({
        breederComingStarts: {
          [breederId]: responseBody,
        },
      }),
      update: {
        breederComingStarts: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  // 2. Selecctor
  const selector = (state: State): BreederComingStarts =>
    state.entities?.breederComingStarts?.[breederId];

  return useApi<BreederComingStarts>({
    query,
    selector,
  });
};

export default useGetBreeder;

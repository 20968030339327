// tslint:disable
/**
 * Web API Charts Service
 * Service for getting info about the different charts.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    SelectionParameter,
    SelectionParameterFromJSON,
    SelectionParameterToJSON,
} from './';

/**
 * 
 * @export
 * @interface Selection
 */
export interface Selection  {
    /**
     * 
     * @type {number}
     * @memberof Selection
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Selection
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof Selection
     */
    name?: string;
    /**
     * The default value that for the selections
     * @type {string}
     * @memberof Selection
     */
    defaultParameter?: string;
    /**
     * A list of the selections)
     * @type {Array<SelectionParameter>}
     * @memberof Selection
     */
    selectionParameters?: Array<SelectionParameter>;
}

export function SelectionFromJSON(json: any): Selection {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'defaultParameter': !exists(json, 'defaultParameter') ? undefined : json['defaultParameter'],
        'selectionParameters': !exists(json, 'selectionParameters') ? undefined : (json['selectionParameters'] as Array<any>).map(SelectionParameterFromJSON),
    };
}

export function SelectionToJSON(value?: Selection): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'alias': value.alias,
        'name': value.name,
        'defaultParameter': value.defaultParameter,
        'selectionParameters': value.selectionParameters === undefined ? undefined : (value.selectionParameters as Array<any>).map(SelectionParameterToJSON),
    };
}



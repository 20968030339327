import formatPropositionDate from '@utils/formatPropositionDate';

const getPropositionInfoTableValues = (
  registrationDate: Date,
  changeWeightDate: Date,
  latestStartRegistrationDate: Date,
): Array<{ label: string; value: string }> => [
  {
    label: 'Anmälan',
    value: formatPropositionDate(registrationDate),
  },
  {
    label: 'Vikter',
    value: formatPropositionDate(changeWeightDate),
  },
  {
    label: 'Startanmälan',
    value: formatPropositionDate(latestStartRegistrationDate),
  },
];

export default getPropositionInfoTableValues;

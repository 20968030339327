import Box from '@primitives/Box';
import Icon from '@primitives/Icon';
import { Link as BaseLink } from '@primitives/Link';
import List from '@primitives/List';
import { Minus, Plus } from '@styled-icons/fa-solid';
import { StyledIcon } from '@styled-icons/styled-icon';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { MenuItem } from '../menus';

interface Props extends SpaceProps, MenuItem {
  href?: string;
  label: string;
  subMenu?: Props[];
  setSidebarClosed?: () => void;
  icon?: StyledIcon;
  isSubItem?: boolean;
  eventTrackingTag?: string[]; //Input an array where each index in the array is one layer deaper in the SidebarItem recursion.
}

const Item = styled(Box)`
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.colors.gray6};
  border-bottom: 1px solid ${props => props.theme.colors.gray3};

  button:focus {
    outline: none;
  }
`;

const Link = styled(BaseLink).attrs({
  exact: true,
})<SpaceProps>`
  ${space}
  font-size: ${props => props.theme.fontSizes.pageHeader}px;
  text-decoration: none;
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.gray4};
  &.active {
    color: ${props => props.theme.colors['blue']};
    border-left: solid 4px ${props => props.theme.colors['blue']};
    font-weight: normal;
  }
`;

const SidebarItem = ({
  href,
  label,
  subMenu,
  setSidebarClosed,
  icon,
  isSubItem = false,
  eventTrackingTag,
}: Props): JSX.Element => {
  const { pathname } = useLocation();
  const [submenuOpen, setSubmenuOpen] = useState(pathname.indexOf(href) === 0);

  useEffect(() => {
    setSubmenuOpen(pathname.indexOf(href) === 0);
  }, [pathname, href]);

  const commonProps = {
    pl: isSubItem ? '48px' : 4,
    onClick: setSidebarClosed,
    fontWeight: submenuOpen ? 'bold' : 'normal',
  };

  const labelItem = (
    <>
      {icon && <Icon as={icon} size={12} color="deep-blue" mr={3} />}
      {label}
    </>
  );

  return (
    <li>
      <Item
        bg={isSubItem ? 'gray1' : 'white'}
        data-eventtracking-region={eventTrackingTag?.[0]}
      >
        {href ? (
          <Link
            to={href}
            {...commonProps}
            //isActive={(match, { pathname }) => pathname.startsWith(href)}
          >
            {labelItem}
          </Link>
        ) : (
          <Link {...commonProps} as="span">
            {labelItem}
          </Link>
        )}

        {subMenu && (
          <Box
            as="button"
            onClick={() => setSubmenuOpen(!submenuOpen)}
            px={4}
            py="13px"
            borderLeftColor="gray3"
            borderLeftWidth="1px"
            borderLeftStyle="solid"
          >
            <Icon
              size={14}
              width="14px"
              height="14px"
              color="dim-gray"
              as={submenuOpen ? Minus : Plus}
            />
          </Box>
        )}
      </Item>
      {subMenu && submenuOpen && (
        <List>
          {subMenu.map(item => (
            <SidebarItem
              eventTrackingTag={
                eventTrackingTag.length > 0
                  ? eventTrackingTag?.slice(1)
                  : undefined
              }
              key={item.label}
              label={item.label}
              href={item.href}
              subMenu={item.subMenu}
              setSidebarClosed={setSidebarClosed}
              isSubItem
              icon={item.icon}
            />
          ))}
        </List>
      )}
    </li>
  );
};

export default SidebarItem;

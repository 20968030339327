import {
  getOngoingLeasingTerminations,
  OwnershipTransfer,
} from '../../generated/ownership-transfers/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  ongoingTerminations?: {
    [key: number]: OwnershipTransfer[];
  };
}

const useGetOngoingTerminations = (
  licenseId: number,
  resetOnUnmount = false,
): ApiResponse<OwnershipTransfer[], Entities> =>
  useApi<OwnershipTransfer[]>({
    resetOnUnmount,
    query: getOngoingLeasingTerminations<Entities>(
      { licenseId },
      {
        queryKey: `ongoingTerminations:${licenseId}`,
        transform: responseBody => ({
          ongoingTerminations: {
            [licenseId]: responseBody,
          },
        }),
        update: {
          ongoingTerminations: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities?.ongoingTerminations?.[licenseId],
  });

export default useGetOngoingTerminations;

import { useGetOwnershipFutureInvoiceTransactions } from '@apis';
import ContentBlock from '@components/ContentBlock';
import DataGrid from '@components/DataGrid';
import { TD } from '@components/ReactTable';
import { Transaction } from '@generated/sportactors/src';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import List, { LI } from '@primitives/List';
import Loading from '@primitives/Loading';
import { H3, Text } from '@primitives/Typography';
import { formatTz } from '@utils/dateUtils';
import { formatNumber } from '@utils/formatting';
import React from 'react';
import { CellProps, Column } from 'react-table';

const columns: Column<Transaction>[] = [
  {
    Header: 'Datum',
    accessor: 'date',
    sortType: 'datetime',
    thStyle: () => ({
      whiteSpace: 'normal',
    }),
    Cell: ({ value }: CellProps<Transaction, Date>) => (
      <>{formatTz(value, 'yyyy-MM-dd')}</>
    ),
  },
  {
    Header: 'Produkt',
    accessor: 'productDescription',
  },
  {
    Header: 'Belopp',
    accessor: 'amount',
    Cell: ({ value }) => <>{`${value ? formatNumber(value) : 0} kr`}</>,
    alignment: 'right',
  },
  {
    Header: 'Moms',
    accessor: 'vatAmount',
    Cell: ({ value }) => <>{`${value ? formatNumber(value) : 0} kr`}</>,
    alignment: 'right',
  },
  {
    Header: 'Totalt',
    accessor: 'totalAmount',
    Cell: ({ value }) => <>{`${value ? formatNumber(value) : 0} kr`}</>,
    alignment: 'right',
  },
  {
    Header: 'Häst',
    accessor: row => row?.horse?.name,
  },
  {
    Header: 'Bana',
    accessor: 'trackCode',
  },
  {
    Header: 'Lopp',
    accessor: 'raceNumber',
  },
  {
    Header: 'Plac.',
    accessor: 'place',
  },
  {
    Header: 'Leverantör',
    accessor: 'supplierName',
  },
];

interface Props {
  licenseId: number;
}

export default function UninvoicedTransactions({
  licenseId,
}: Props): JSX.Element {
  const { data, loading, status, error } =
    useGetOwnershipFutureInvoiceTransactions(licenseId);

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <Box px={[4, null, 0]} pt={[4, null, 0]}>
      <ContentBlock
        slug="fakturor-och-transaktioner-ej-fakturerade-transaktioner"
        alignment="left"
        justifyContent="flex-start"
      />
      {data.length === 0 && (
        <Text mt={4}>Fann inga ej fakturerade transaktioner.</Text>
      )}
      {data.length > 0 && (
        <List>
          {data.map(item => (
            <LI key={item.ownerName}>
              <Box mt={4}>
                <H3>{item.ownerName}</H3>
                <DataGrid
                  stickyOnMobile={false}
                  columns={columns}
                  data={item.transactions}
                  mt={4}
                  condensed
                  tfoot={
                    <tr>
                      <TD colSpan={2} alignment="right" fontWeight="bold">
                        Summa:
                      </TD>
                      <TD alignment="right">
                        {item?.amountSum ? formatNumber(item.amountSum) : 0} kr
                      </TD>
                      <TD alignment="right">
                        {item?.vatAmountSum
                          ? formatNumber(item.vatAmountSum)
                          : 0}{' '}
                        kr
                      </TD>
                      <TD alignment="right">
                        {item?.totalAmountSum
                          ? formatNumber(item.totalAmountSum)
                          : 0}{' '}
                        kr
                      </TD>
                      <TD colSpan={5} />
                    </tr>
                  }
                />
              </Box>
            </LI>
          ))}
        </List>
      )}
    </Box>
  );
}

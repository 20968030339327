import Icon from '@primitives/Icon';
import { Link } from '@primitives/Link';
import { RightToBracket as SignInAlt } from '@styled-icons/fa-solid';
import React, { ReactNode } from 'react';
import { StyledIcon } from 'styled-icons/types';

type LinkParameters = Parameters<typeof Link>;

type Props = {
  children: ReactNode;
  icon?: StyledIcon;
} & LinkParameters[0];

const ActionLink = ({
  children,
  icon = SignInAlt,
  ...props
}: Props): JSX.Element => (
  <Link color="green" sx={{ whiteSpace: 'nowrap' }} {...props}>
    {children}{' '}
    <Icon pb="1px" size={12} color={props?.color || 'green'} as={icon} />
  </Link>
);

export default ActionLink;

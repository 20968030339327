import Step from '@components/Step';
import Message from '@features/StartDeclaration/utils/Message';
import { StartDeclarationDriverChoiceEnum } from '@generated/propositions/src';
import Box from '@primitives/Box';
import { SwitchRadios } from '@primitives/SwitchButton';
import { useFormikContext } from 'formik';
import React from 'react';

import { FormState } from '..';
import AvailableDrivers from './AvailableDrivers';
import PreSelectedDrivers from './PreSelectedDrivers';
import SelectedDrivers from './SelectedDrivers';
import ValidDrivers from './ValidDrivers';

interface Props {
  licenseId: number;
  raceDayId: number;
  propositionId: number;
  startDeclaredToMultiple: boolean;
  propWeight: number;
}

const Drivers = ({
  propWeight,
  licenseId,
  raceDayId,
  propositionId,
  startDeclaredToMultiple,
}: Props): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<FormState>();

  const tab =
    values?.driverChoice || StartDeclarationDriverChoiceEnum.SPECIFIED_DRIVER;

  return (
    <Step
      header={
        <>
          1. Ryttare, propvikt <b>{propWeight} kg</b>
        </>
      }
      mt={4}
    >
      <Box maxWidth="640px">
        <Box mb={4}>
          <SwitchRadios
            value={tab}
            onChange={e => {
              const { value } = e.target;
              setFieldValue('driverChoice', value, true);
            }}
            options={[
              {
                value: StartDeclarationDriverChoiceEnum.SPECIFIED_DRIVER,
                label: 'Välj ryttare',
              },
              {
                value: StartDeclarationDriverChoiceEnum.FOREIGN_DRIVER,
                label: 'Ryttare saknas i söklista',
              },
              {
                value: StartDeclarationDriverChoiceEnum.SPECIFY_LATER,
                label: 'Ej avtalad ryttare',
              },
            ]}
          />
        </Box>
        {tab === StartDeclarationDriverChoiceEnum.SPECIFIED_DRIVER && (
          <Box mb={4}>
            <Box
              display={[null, null, 'flex']}
              verticalSpacing={[4, , 0]}
              horizontalSpacing={[0, , 6]}
            >
              <Box width={[1, , 1 / 3]}>
                <PreSelectedDrivers
                  licenseId={licenseId}
                  propositionId={propositionId}
                />
              </Box>
              <Box width={[1, , 1 / 3]}>
                <AvailableDrivers
                  raceDayId={raceDayId}
                  propositionId={propositionId}
                />
              </Box>
              <Box width={[1, , 1 / 3]}>
                <ValidDrivers propositionId={propositionId} />
              </Box>
            </Box>
            {values.drivers && values.drivers.length > 0 && (
              <SelectedDrivers
                propositionId={propositionId}
                propositionWeight={propWeight}
              />
            )}
          </Box>
        )}
        <Message
          driverType={tab}
          startDeclaredToMultiple={startDeclaredToMultiple}
        />
      </Box>
    </Step>
  );
};

export default React.memo(Drivers);

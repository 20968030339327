// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SavedFilter,
    SavedFilterFromJSON,
    SavedFilterToJSON,
    SearchFilter,
    SearchFilterFromJSON,
    SearchFilterToJSON,
} from '../models';

export interface DeleteChartRequest {
    licenseId: number;
    categoryId: number;
    typeId: number;
    chartId: string;
}

export interface GetSavedChartFiltersRequest {
    categoryId: number;
    licenseId: number;
}

export interface InsertChartRequest {
    categoryId: number;
    typeId: number;
    licenseId: number;
    chartname: string;
    searchFilter: SearchFilter;
}


/**
 * Delete a chart for the given user. Only available for TROT/SPORT users.
 */
function deleteChartRaw<T>(requestParameters: DeleteChartRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling deleteChart.');
    }

    if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
        throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling deleteChart.');
    }

    if (requestParameters.typeId === null || requestParameters.typeId === undefined) {
        throw new runtime.RequiredError('typeId','Required parameter requestParameters.typeId was null or undefined when calling deleteChart.');
    }

    if (requestParameters.chartId === null || requestParameters.chartId === undefined) {
        throw new runtime.RequiredError('chartId','Required parameter requestParameters.chartId was null or undefined when calling deleteChart.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/{licenseId}/savedcharts/categories/{categoryId}/types/{typeId}/{chartId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))).replace(`{${"typeId"}}`, encodeURIComponent(String(requestParameters.typeId))).replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters.chartId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Delete a chart for the given user. Only available for TROT/SPORT users.
*/
export function deleteChart<T>(requestParameters: DeleteChartRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteChartRaw(requestParameters, requestConfig);
}

/**
 * Get all saved charts for the given user
 */
function getSavedChartFiltersRaw<T>(requestParameters: GetSavedChartFiltersRequest, requestConfig: runtime.TypedQueryConfig<T, Array<SavedFilter>> = {}): QueryConfig<T> {
    if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
        throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getSavedChartFilters.');
    }

    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getSavedChartFilters.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/{licenseId}/savedcharts/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))).replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(SavedFilterFromJSON), text);
    }

    return config;
}

/**
* Get all saved charts for the given user
*/
export function getSavedChartFilters<T>(requestParameters: GetSavedChartFiltersRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<SavedFilter>>): QueryConfig<T> {
    return getSavedChartFiltersRaw(requestParameters, requestConfig);
}

/**
 * Insert a chart for the given user.
 */
function insertChartRaw<T>(requestParameters: InsertChartRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
        throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling insertChart.');
    }

    if (requestParameters.typeId === null || requestParameters.typeId === undefined) {
        throw new runtime.RequiredError('typeId','Required parameter requestParameters.typeId was null or undefined when calling insertChart.');
    }

    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling insertChart.');
    }

    if (requestParameters.chartname === null || requestParameters.chartname === undefined) {
        throw new runtime.RequiredError('chartname','Required parameter requestParameters.chartname was null or undefined when calling insertChart.');
    }

    if (requestParameters.searchFilter === null || requestParameters.searchFilter === undefined) {
        throw new runtime.RequiredError('searchFilter','Required parameter requestParameters.searchFilter was null or undefined when calling insertChart.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.chartname !== undefined) {
        queryParameters['chartname'] = requestParameters.chartname;
    }

    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/{licenseId}/savedcharts/categories/{categoryId}/types/{typeId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))).replace(`{${"typeId"}}`, encodeURIComponent(String(requestParameters.typeId))).replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || SearchFilterToJSON(requestParameters.searchFilter),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Insert a chart for the given user.
*/
export function insertChart<T>(requestParameters: InsertChartRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return insertChartRaw(requestParameters, requestConfig);
}


import Tabs, { TabList } from '@components/Tabs';
import Box from '@primitives/Box';
import { Select } from '@primitives/Form';
import isEqual from 'lodash/isEqual';
import React, { memo, ReactNode, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type TabListParameters = Parameters<typeof TabList>;
type TabsParameters = Parameters<typeof Tabs>;

export type Tab = {
  component?: ReactNode;
  exact?: boolean;
  to: string;
  text: string;
} & TabsParameters[0];

type Props = {
  label?: string;
  tabs: Array<Tab>;
  forceSelectInMobile?: boolean;
} & TabListParameters[0];

const TabsRenderer = ({
  label,
  tabs,
  forceSelectInMobile = false,
  ...props
}: Props): JSX.Element => {
  const history = useHistory();
  const showSelectInMobile = tabs.length > 2 || forceSelectInMobile;
  const { pathname } = useLocation();

  const selectedTab = tabs.find(tab =>
    (!tab.exact && pathname.endsWith(String(tab.to))) || pathname === tab.to
      ? tab
      : null,
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      history.push(event.target.value);
    },
    [history],
  );

  return (
    <>
      <Box
        sx={{
          display: [showSelectInMobile ? 'none' : 'block', , 'block'],
        }}
      >
        <TabList {...props}>
          {tabs.map(({ component, to, exact, text, ...rest }) => (
            <Box as="li" key={to} width={[0.5, , 'auto']}>
              <Tabs
                {...rest}
                to={to}
                display="block"
                lineHeight="44px"
                exact={exact || false}
                width={[1, , 'auto']}
                sx={{
                  textAlign: ['center', , 'left'],
                }}
              >
                {component || text}
              </Tabs>
            </Box>
          ))}
        </TabList>
      </Box>

      {showSelectInMobile && (
        <Box
          display={[showSelectInMobile ? 'block' : 'none', null, 'none']}
          backgroundColor="gray1"
          p={4}
          pt={3}
        >
          <Select
            label={label}
            options={tabs.map(tab => ({ label: tab.text, value: tab.to }))}
            value={selectedTab ? selectedTab.to : ''}
            onChange={onChange}
          />
        </Box>
      )}
    </>
  );
};

TabsRenderer.defaultProps = {
  variant: 'horizontal',
  itemGap: 4,
  label: 'Visa sektion:',
};

export default memo(TabsRenderer, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);

import {
  useGetCoveringColors,
  useGetCoveringCountries,
  useGetCoveringGenders,
  useGetCoveringOutcomes,
  useSaveCoveringResult,
} from '@apis';
import Alert from '@components/Alert';
import { DatePicker, Select, SelectOption } from '@components/FormikComponents';
import FormLayout, { FormItem } from '@components/FormLayout';
import {
  CoveringResult,
  SaveCoveringResultRequest,
  Selection,
} from '@generated/breedings/src';
import Message from '@primitives/Message';
import { RightToBracket as SignInAlt } from '@styled-icons/fa-solid';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { useCoveringContext, useGetStud } from '../context';

type FormState = Partial<CoveringResult> & {
  all?: string;
};

interface Props {
  onClose: () => void;
  coveringResult: CoveringResult;
}

function hadFoals(code: string | undefined): boolean {
  return ['F', 'T'].includes(code);
}

function selectionsToOptions(
  data: Selection | undefined,
  defaultLabel = 'Välj',
): SelectOption[] {
  const options =
    data?.selectionOptions.map(item => ({
      label: item.name,
      value: item.value,
    })) || [];
  return [{ label: defaultLabel, value: '' }, ...options];
}

const MissingMareSchema = Yup.object().shape({
  outcome: Yup.object()
    .shape({
      code: Yup.string().ensure().required('Resultat måste anges'),
    })
    .required('Resultat måste anges'),
  birthDate: Yup.date().when('outcome.code', (outcome, schema) =>
    hadFoals(outcome)
      ? schema.required('Födelsedatum måste anges')
      : schema.oneOf([null], 'Födelsedatum får inte anges'),
  ),
  color: Yup.mixed().when('outcome.code', (outcome, schema) =>
    hadFoals(outcome) ? Yup.string().required('Färg måste anges') : schema,
  ),
  gender: Yup.mixed().when('outcome.code', (outcome, schema) =>
    hadFoals(outcome) ? Yup.string().required('Kön måste anges') : schema,
  ),
});

export default function ResultsForm({
  onClose,
  coveringResult,
}: Props): JSX.Element {
  const { year, studId, onSuccess } = useCoveringContext();
  const stud = useGetStud(studId);

  const { action: save } = useSaveCoveringResult();
  const { data: outcomes } = useGetCoveringOutcomes();
  const { data: genders } = useGetCoveringGenders();
  const { data: colors } = useGetCoveringColors();
  const { data: countries } = useGetCoveringCountries();

  const studName = stud?.name;

  const isEditMode = Boolean(coveringResult.outcome?.code);

  return (
    <Formik<FormState>
      initialValues={coveringResult}
      validationSchema={MissingMareSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        if (!hadFoals(values.outcome?.code)) {
          values.birthDate = undefined;
          values.birthCountryCode = undefined;
          values.color = undefined;
          values.gender = undefined;
        }

        if (values.birthCountryCode === '') {
          values.birthCountryCode = null;
        }

        const resp = await save({
          studId,
          year,
          coveringResult: values,
        } as SaveCoveringResultRequest);

        if (resp.status < 400) {
          await onSuccess();
          onClose();
        } else {
          setFieldError(
            'all',
            resp.body?.localizedErrorMessage ||
              resp.body?.errorMessage ||
              resp.text,
          );
        }
        setSubmitting(false);
      }}
    >
      {({ values, errors, isSubmitting, handleSubmit, setFieldValue }) => {
        // Föl / Tvillingar
        const wasSuccessful = hadFoals(values.outcome?.code);
        return (
          <Alert
            shouldCloseOnOverlayClick={false}
            closeModal={onClose}
            open
            size="medium"
            action="Spara"
            title={isEditMode ? 'Ändra resultat' : 'Registrera resultat'}
            icon={SignInAlt}
            loading={isSubmitting}
            iconProps={{
              size: 10,
              mb: '0px',
            }}
            onAction={handleSubmit}
            description={`För ${studName} språng ${coveringResult.coveringOccasions.join(
              ', ',
            )} med sto ${coveringResult.mare.name}`}
            colorScheme="primary"
          >
            <FormLayout>
              <FormItem width={[1, , 0.5]} data-no-grow>
                <Select
                  label="Resultat"
                  name="outcome.code"
                  options={selectionsToOptions(outcomes)}
                  onChange={ev => {
                    const { value } = ev.target;
                    setFieldValue('outcome.code', value);

                    if (!hadFoals(value)) {
                      setFieldValue('birthDate', '');
                      setFieldValue('birthCountryCode', '');
                      setFieldValue('color', '');
                      setFieldValue('gender', '');
                    }
                  }}
                />
              </FormItem>

              <FormItem width={[1, , 0.5]} data-no-grow>
                <DatePicker
                  name="birthDate"
                  label="Född"
                  disabled={!wasSuccessful}
                />
              </FormItem>

              <FormItem width={[1, , 0.5]} data-no-grow>
                <Select
                  label="Kön"
                  name="gender"
                  options={selectionsToOptions(genders)}
                  disabled={!wasSuccessful}
                />
              </FormItem>

              <FormItem width={[1, , 0.5]} data-no-grow>
                <Select
                  label="Färg"
                  name="color"
                  options={selectionsToOptions(colors)}
                  disabled={!wasSuccessful}
                />
              </FormItem>

              <FormItem width={[1, , 0.5]} data-no-grow>
                <Select
                  label="Land (om utlandsfödd)"
                  name="birthCountryCode"
                  options={selectionsToOptions(countries, 'Sverige')}
                  disabled={!wasSuccessful}
                />
              </FormItem>

              {errors?.all && (
                <FormItem width={1}>
                  <Message variant="errorBox">{errors.all}</Message>
                </FormItem>
              )}
            </FormLayout>
          </Alert>
        );
      }}
    </Formik>
  );
}

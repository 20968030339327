// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    EmailResponseMessage,
    EmailResponseMessageFromJSON,
    EmailResponseMessageToJSON,
    EnrolledRaceDay,
    EnrolledRaceDayFromJSON,
    EnrolledRaceDayToJSON,
    Enrollment,
    EnrollmentFromJSON,
    EnrollmentToJSON,
    EnrollmentRaceDayInformation,
    EnrollmentRaceDayInformationFromJSON,
    EnrollmentRaceDayInformationToJSON,
    EnrollmentReceipt,
    EnrollmentReceiptFromJSON,
    EnrollmentReceiptToJSON,
    EnrollmentRequest,
    EnrollmentRequestFromJSON,
    EnrollmentRequestToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface EmailReceiptRequest {
    licenseId: number;
    raceDayId: number;
}

export interface EnrollHorseToPropositionRequest {
    trainerId: number;
    enrollmentRequest: EnrollmentRequest;
    xEndUserIp?: string;
}

export interface GetEnrolledRaceDaysRequest {
    licenseId: number;
}

export interface GetEnrollmentsRequest {
    licenseId: number;
}

export interface GetEnrollmentsForSpecificRaceDayRequest {
    licenseId: number;
    raceDayId: number;
}

export interface TryToUnenrollFromPropositionRequest {
    propositionId: number;
    horseId: number;
}


/**
 * Email receipt of enrollment to the trainer
 */
function emailReceiptRaw<T>(requestParameters: EmailReceiptRequest, requestConfig: runtime.TypedQueryConfig<T, EmailResponseMessage> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling emailReceipt.');
    }

    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling emailReceipt.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/enrollments/{licenseId}/racedays/{raceDayId}/emailreceipt`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EmailResponseMessageFromJSON(body), text);
    }

    return config;
}

/**
* Email receipt of enrollment to the trainer
*/
export function emailReceipt<T>(requestParameters: EmailReceiptRequest, requestConfig?: runtime.TypedQueryConfig<T, EmailResponseMessage>): QueryConfig<T> {
    return emailReceiptRaw(requestParameters, requestConfig);
}

/**
 * Consider enrollment as an interest to start the horse. As long as the request is valid, each horse is processed individually, and hence it\'s possible that some horses fail and some succeed
 * Enroll one or more trained horses to each specified proposition. Propositions must belong to the same race day.
 */
function enrollHorseToPropositionRaw<T>(requestParameters: EnrollHorseToPropositionRequest, requestConfig: runtime.TypedQueryConfig<T, EnrollmentReceipt> = {}): QueryConfig<T> {
    if (requestParameters.trainerId === null || requestParameters.trainerId === undefined) {
        throw new runtime.RequiredError('trainerId','Required parameter requestParameters.trainerId was null or undefined when calling enrollHorseToProposition.');
    }

    if (requestParameters.enrollmentRequest === null || requestParameters.enrollmentRequest === undefined) {
        throw new runtime.RequiredError('enrollmentRequest','Required parameter requestParameters.enrollmentRequest was null or undefined when calling enrollHorseToProposition.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters.xEndUserIp !== undefined && requestParameters.xEndUserIp !== null) {
        headerParameters['X-End-User-Ip'] = String(requestParameters.xEndUserIp);
    }


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/enrollments/{trainerId}`.replace(`{${"trainerId"}}`, encodeURIComponent(String(requestParameters.trainerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || EnrollmentRequestToJSON(requestParameters.enrollmentRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EnrollmentReceiptFromJSON(body), text);
    }

    return config;
}

/**
* Consider enrollment as an interest to start the horse. As long as the request is valid, each horse is processed individually, and hence it\'s possible that some horses fail and some succeed
* Enroll one or more trained horses to each specified proposition. Propositions must belong to the same race day.
*/
export function enrollHorseToProposition<T>(requestParameters: EnrollHorseToPropositionRequest, requestConfig?: runtime.TypedQueryConfig<T, EnrollmentReceipt>): QueryConfig<T> {
    return enrollHorseToPropositionRaw(requestParameters, requestConfig);
}

/**
 * Get all race days a trainer has enrolled for.
 */
function getEnrolledRaceDaysRaw<T>(requestParameters: GetEnrolledRaceDaysRequest, requestConfig: runtime.TypedQueryConfig<T, Array<EnrolledRaceDay>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getEnrolledRaceDays.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/enrollments/{licenseId}/racedays`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(EnrolledRaceDayFromJSON), text);
    }

    return config;
}

/**
* Get all race days a trainer has enrolled for.
*/
export function getEnrolledRaceDays<T>(requestParameters: GetEnrolledRaceDaysRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<EnrolledRaceDay>>): QueryConfig<T> {
    return getEnrolledRaceDaysRaw(requestParameters, requestConfig);
}

/**
 * Get all enrollments for trainer.
 */
function getEnrollmentsRaw<T>(requestParameters: GetEnrollmentsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Enrollment>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getEnrollments.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/enrollments/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(EnrollmentFromJSON), text);
    }

    return config;
}

/**
* Get all enrollments for trainer.
*/
export function getEnrollments<T>(requestParameters: GetEnrollmentsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Enrollment>>): QueryConfig<T> {
    return getEnrollmentsRaw(requestParameters, requestConfig);
}

/**
 * Get all horse enrollments for specific race day
 */
function getEnrollmentsForSpecificRaceDayRaw<T>(requestParameters: GetEnrollmentsForSpecificRaceDayRequest, requestConfig: runtime.TypedQueryConfig<T, EnrollmentRaceDayInformation> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getEnrollmentsForSpecificRaceDay.');
    }

    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling getEnrollmentsForSpecificRaceDay.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/enrollments/{licenseId}/raceDays/{raceDayId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EnrollmentRaceDayInformationFromJSON(body), text);
    }

    return config;
}

/**
* Get all horse enrollments for specific race day
*/
export function getEnrollmentsForSpecificRaceDay<T>(requestParameters: GetEnrollmentsForSpecificRaceDayRequest, requestConfig?: runtime.TypedQueryConfig<T, EnrollmentRaceDayInformation>): QueryConfig<T> {
    return getEnrollmentsForSpecificRaceDayRaw(requestParameters, requestConfig);
}

/**
 * Try to unenroll a horse from a proposition.
 */
function tryToUnenrollFromPropositionRaw<T>(requestParameters: TryToUnenrollFromPropositionRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling tryToUnenrollFromProposition.');
    }

    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling tryToUnenrollFromProposition.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/{propositionId}/enrollments/{horseId}`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))).replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Try to unenroll a horse from a proposition.
*/
export function tryToUnenrollFromProposition<T>(requestParameters: TryToUnenrollFromPropositionRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return tryToUnenrollFromPropositionRaw(requestParameters, requestConfig);
}


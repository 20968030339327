// tslint:disable
/**
 * Web API Auth Service
 * Service for supporting user authentication (issuing JWTs) for accessing secured resources.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * An optional message, either used to notify the user about something or to signal an error
 * @export
 * @interface AuthenticateMessage
 */
export interface AuthenticateMessage  {
    /**
     * A message either signaling an error or user information
     * @type {string}
     * @memberof AuthenticateMessage
     */
    message: string;
}

export function AuthenticateMessageFromJSON(json: any): AuthenticateMessage {
    return {
        'message': json['message'],
    };
}

export function AuthenticateMessageToJSON(value?: AuthenticateMessage): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'message': value.message,
    };
}



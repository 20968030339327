import {
  getNegativeDopingResultsWithoutRace,
  GetNegativeDopingResultsWithoutRaceRequest,
  NegativeDopingResultsWithoutRace,
} from '@generated/raceinfo/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  negativeDopingResultsWithoutRace?: {
    [key: string]: NegativeDopingResultsWithoutRace;
  };
}

const getQuery = (
  resultKey: string,
  request: GetNegativeDopingResultsWithoutRaceRequest,
): QueryConfig<Entities> =>
  getNegativeDopingResultsWithoutRace<Entities>(request, {
    transform: responseBody => ({
      negativeDopingResultsWithoutRace: {
        [resultKey]: responseBody,
      },
    }),
    update: {
      negativeDopingResultsWithoutRace: (prev, next) => ({
        ...prev,
        ...next,
      }),
    },
  });

const useGetNegativeDopingResultsWithoutRace = (
  request: GetNegativeDopingResultsWithoutRaceRequest,
): ApiResponse<NegativeDopingResultsWithoutRace | undefined, Entities> => {
  const resultKey = `negativeDopingResultsWithoutRace-${request.fromdate}-${request.todate}`;

  const query = getQuery(resultKey, request);

  const selector = (
    state: State,
  ): NegativeDopingResultsWithoutRace | undefined =>
    state.entities?.['negativeDopingResultsWithoutRace']?.[resultKey];

  return useApi<NegativeDopingResultsWithoutRace | undefined>({
    query,
    selector,
  });
};

export default useGetNegativeDopingResultsWithoutRace;

import useElementSize from '@hooks/useElementSize';
import Box, { FadedBox } from '@primitives/Box';
import { ButtonLink } from '@primitives/Button';
import { Text } from '@primitives/Typography';
import React, { useState } from 'react';

import Content from './Content';

type BoxProps = Parameters<typeof Box>[0];

type Props = BoxProps & {
  slug: string;
};

export default function FadedContent({
  slug,
  ...props
}: Props): React.ReactElement {
  const [bioRef, { height }] = useElementSize();
  const [bioOpen, setBioOpen] = useState(false);

  const toggleLimit = 60;
  const showToggleBio = height > toggleLimit;
  return (
    <>
      <FadedBox
        maxHeight={bioOpen || !showToggleBio ? 'none' : `${toggleLimit}px`}
        overflowY="hidden"
        fadedBottom={!bioOpen && showToggleBio ? 50 : 0}
        fadedBg="white"
      >
        <Content
          slug={slug}
          as={Text}
          lineHeight="1.5"
          ref={bioRef}
          {...props}
        />
      </FadedBox>
      {showToggleBio && (
        <ButtonLink
          onClick={() => setBioOpen(!bioOpen)}
          color="gray5"
          lineHeight="18px"
          fontSize="small"
          mt={bioOpen ? 2 : 1}
          sx={{
            borderBottom: 'solid 1px',
            textTransform: 'uppercase',
          }}
        >
          {bioOpen ? 'Läs mindre' : 'Läs mer'}
        </ButtonLink>
      )}
    </>
  );
}

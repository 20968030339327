import Card from '@components/Card';
import Button from '@primitives/Button';
import { Text } from '@primitives/Typography';
import React from 'react';
import styled from 'styled-components';

interface Props {
  hidden: boolean;
  onClick: () => void;
}

const Wrapper = styled(Card)`
  background-color: ${props => props.theme.colors.gray1};
  height: 100px;
  transition: all 1s;
  max-width: 300px;
  text-align: center;
  align-items: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 2147483638;
  right: 3%;
  bottom: 30px;
  font-size: ${props => props.theme.fontSizes.text}px;
  box-shadow:
    0 1px 10px 0 rgba(0, 0, 0, 0.1),
    0 2px 15px 0 rgba(0, 0, 0, 0.05);
`;

function Toast({ hidden, onClick }: Props): JSX.Element {
  return (
    <Wrapper
      display={hidden ? 'none' : 'flex'}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Text>Ny version av sidan tillgänglig. </Text>
      <Button onClick={onClick}>Ladda om</Button>
    </Wrapper>
  );
}

export default Toast;

import { Marker } from '@components/Menu';
import UserMenu from '@features/Account/UserMenu';
import { AuthenticatedUser } from '@generated/account/src';
import useClickOutside from '@hooks/useClickOutside';
import useIsMobile from '@hooks/useIsMobile';
import useIsTablet from '@hooks/useIsTablet';
import useUser from '@hooks/useUser';
import theme from '@main/theme';
import Box, { Flex } from '@primitives/Box';
import Icon from '@primitives/Icon';
import { Link } from '@primitives/Link';
import { H4, Text } from '@primitives/Typography';
import { User } from '@styled-icons/fa-solid';
import isLoggedIn from '@utils/isLoggedIn';
import { transparentize } from 'polished';
import React, { RefObject, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface Props {
  setHoverItem?: (item: string | undefined) => void;
  hoverItem?: string;
}

interface DropdownProps {
  user?: AuthenticatedUser;
}

const UserItem = ({ setHoverItem, hoverItem }: Props): React.ReactElement => {
  const user = useUser();
  const [open, setOpen] = useState(false);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const history = useHistory();

  const dropdownRef = useClickOutside(outside => {
    if (outside) {
      setOpen(false);
    }
  });

  const { pathname, search } = useLocation();

  const loginPath =
    !pathname || pathname === '/' || pathname.startsWith('/login')
      ? '/login'
      : `/login?dest=${encodeURIComponent(pathname + search)}`;

  // Dropdown closed by default
  let dropdownIsOpen = false;

  // 2. If logged in and on mobile, require click as well
  if (isLoggedIn(user) && (isMobile || isTablet) && open) {
    dropdownIsOpen = true;
  }

  // 3. If logged in and on desktop, allow hover
  if (isLoggedIn(user) && !isMobile && !isTablet && hoverItem === 'user') {
    dropdownIsOpen = true;
  }

  useEffect(() => {
    document.body.style.overflow = dropdownIsOpen ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [dropdownIsOpen]);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <Box
      height="100%"
      mr={5}
      onMouseEnter={() => setHoverItem?.('user')}
      onMouseLeave={() => setHoverItem?.(undefined)}
      ref={dropdownRef as RefObject<HTMLDivElement>} // <-- In order for useClickOutside to return generic type RefObject<HTMLElement>
      position="relative"
      display="flex"
    >
      {isLoggedIn(user) ? (
        <Flex
          as="button"
          onClick={() => {
            if (isMobile || isTablet) {
              setOpen(!open);
            } else {
              setHoverItem?.(undefined);
              history.push('/minasidor');
            }
          }}
          color={
            hoverItem === 'user' || hoverItem === undefined
              ? 'white'
              : transparentize(0.4, 'white')
          }
        >
          {
            // eslint-disable-next-line prettier/prettier
            !isMobile && !isTablet && <Text>Mina sidor</Text>
          }
          <Icon ml={[, , 2]} size={16} as={User} />
        </Flex>
      ) : (
        <Flex
          as={Link}
          to={loginPath}
          color={
            hoverItem === 'user' || hoverItem === undefined
              ? 'white'
              : transparentize(0.4, 'white')
          }
        >
          {
            // eslint-disable-next-line prettier/prettier
            !isMobile && !isTablet && <Text>Logga in</Text>
          }
          <Icon ml={[, , 2]} size={16} as={User} />
        </Flex>
      )}

      {dropdownIsOpen && (
        <>
          <Marker />
          <Dropdown user={isLoggedIn(user) ? user : null} />
        </>
      )}
    </Box>
  );
};

const Dropdown = ({ user }: DropdownProps): React.ReactElement => (
  <Box
    p="6px"
    bg="white"
    width={[`100%`, null, isLoggedIn(user) ? '288px' : '288px']}
    maxWidth="420px"
    position={['fixed', , 'absolute']}
    right={[0, , '-15px', 0]}
    top={`${theme.sizes.pageHeader}px`}
    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.16)"
    maxHeight={[
      `calc(100vh - ${theme.sizes.pageHeader}px)`,
      null,
      `calc(100vh - ${theme.sizes.pageHeader + 15}px)`,
    ]}
    overflowY="auto"
    overflowX="hidden"
  >
    <Box p={2} pb={['150px', , 2]} bg="gray1">
      <H4 mb={3} mt="2px" color="gray6">
        {user.userInfo.firstName} {user.userInfo.lastName}
      </H4>
      <UserMenu user={user} background homeLink wide={false} />
    </Box>
  </Box>
);

export default UserItem;

import { Styles as TableStyle } from '@components/DataGrid';
import { Input } from '@components/FormikComponents';
import { OverFlow, TD, TH } from '@components/ReactTable';
import { DriverCostProducts } from '@generated/tds/src';
import Box from '@primitives/Box';
import { H4, Text } from '@primitives/Typography';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import styled from 'styled-components';

import FormItemInput from './utils/FormItemInput';

interface TdProps {
  minWidth?: string;
}

const StyledTD = styled(TD)<TdProps>`
  min-width: ${props => (props.minWidth ? props.minWidth : 'none')};
  vertical-align: middle !important;
`;

const StyledTH = styled(TH)`
  position: static !important;
`;

type BoxParameters = Parameters<typeof Box>;

type Props = {
  disabled: boolean;
  horse: DriverCostProducts;
  index: number;
} & BoxParameters[0];

const Products = ({ disabled, index, horse, ...props }: Props): JSX.Element => (
  <Box {...props}>
    <H4
      mb={2}
      {...(horse.start.withdrawn && { sx: { textDecoration: 'line-through' } })}
    >
      {horse.start.horseName}
    </H4>
    <TableStyle>
      <OverFlow>
        <table className="datagrid-variant">
          <thead>
            <tr>
              <StyledTH cursor="auto">Produkt</StyledTH>
              <StyledTH cursor="auto">Text</StyledTH>
              <StyledTH cursor="auto">
                Belopp{' '}
                <Text as="span" fontWeight="normal">
                  (ex moms)
                </Text>
              </StyledTH>
            </tr>
          </thead>
          <tbody>
            {horse.productInformations.map((productInfo, i) => {
              const isSaved = productInfo.registeredTransaction != null;
              return (
                <tr key={i}>
                  <StyledTD>
                    {productInfo.product.description}
                    <Text as="span" color="green">
                      {isSaved ? ' (Sparad)' : ''}
                    </Text>
                  </StyledTD>
                  <StyledTD minWidth="200px">
                    <FormItemInput>
                      <Input
                        name={`items[${index}].productInformations[${i}].text`}
                        type="text"
                        disabled={disabled}
                      />
                    </FormItemInput>
                  </StyledTD>
                  <StyledTD minWidth="200px">
                    <FormItemInput>
                      <Input
                        name={`items[${index}].productInformations[${i}].amount`}
                        type="text"
                        disabled={disabled}
                      />
                    </FormItemInput>
                  </StyledTD>
                </tr>
              );
            })}
          </tbody>
        </table>
      </OverFlow>
    </TableStyle>
  </Box>
);

export default memo(Products, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);

import {
  getTerminableLeases,
  OwnershipTransfer,
} from '../../generated/ownership-transfers/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  terminableLeases?: {
    [key: number]: OwnershipTransfer[];
  };
}

const useGetTerminableLeases = (
  licenseId,
  resetOnUnmount = false,
): ApiResponse<OwnershipTransfer[], Entities> =>
  useApi<OwnershipTransfer[]>({
    resetOnUnmount,
    query: getTerminableLeases<Entities>(
      { licenseId },
      {
        queryKey: `terminableLeases:${licenseId}`,
        transform: responseBody => ({
          terminableLeases: {
            [licenseId]: responseBody,
          },
        }),
        update: {
          terminableLeases: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities?.terminableLeases?.[licenseId],
  });

export default useGetTerminableLeases;

import { useGetRegistrationStatusOptions } from '@apis';
import Alert from '@components/Alert';
import { BankIdUserSigning } from '@generated/horses/src';
import Error from '@primitives/Error';
import Icon from '@primitives/Icon';
import { Link } from '@primitives/Link';
import { PenToSquare as Edit } from '@styled-icons/fa-solid';
import scrollToTop from '@utils/scrollToTop';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';

import Form from './Form';
import BankIdCollect from './utils/BankIdCollect';
import TaskEnum from './utils/enum/Task';
import Task from './utils/type/Task';

interface Props {
  authenticationMethod: string;
  horseId: number;
  horseIdParam: number;
  horseName: string;
  registrationStatusChangeable: boolean;
  onSuccess: (msg: string) => void;
}

interface Query {
  horseId?: number;
}

function getPath(path: string, query: Query = {}): string {
  const search = qs.stringify(query);
  return generatePath(path) + search ? `?${search}` : '';
}

const ChangeRegistrationStatus = ({
  authenticationMethod,
  horseId,
  horseIdParam,
  horseName,
  registrationStatusChangeable,
  onSuccess,
}: Props): JSX.Element => {
  const [qrInit, setQRInit] = useState<number>(0);
  const { path } = useRouteMatch();
  const history = useHistory();

  const [task, setTask] = useState<Task>(
    horseId === horseIdParam ? { type: TaskEnum.RetrieveOptions } : null,
  );

  const { data, action, status, error } =
    useGetRegistrationStatusOptions(horseId);

  const closeModal = useCallback(() => {
    setTask(null);
    history.push(generatePath(path));
  }, [history, path]);

  const setFinishedWithoutOptionsTask = useCallback(() => {
    setTask({ type: TaskEnum.FinishedWithoutOptions });
  }, []);

  useEffect(() => {
    if (task?.type === TaskEnum.RunBankIdInitialize) {
      setQRInit(new Date().getTime());
    }
  }, [task]);

  useEffect(() => {
    if (
      [
        TaskEnum.UpdateStatusByPasswordSuccess,
        TaskEnum.BankIdCollectSuccess,
        TaskEnum.FinishedWithoutOptions,
      ].includes(task?.type)
    ) {
      history.push(generatePath(path));

      if (
        [
          TaskEnum.UpdateStatusByPasswordSuccess,
          TaskEnum.BankIdCollectSuccess,
        ].includes(task?.type)
      ) {
        onSuccess(task.value as string);
        scrollToTop();
      }
    }
  }, [history, path, task, onSuccess]);

  useEffect(() => {
    if (
      [
        TaskEnum.RunBankIdCollect,
        TaskEnum.RetrieveOptions,
        TaskEnum.BankIdCollectQrCode,
      ].includes(task?.type)
    ) {
      action();
    }
  }, [action, task]);

  useEffect(() => {
    if (data && task?.type === TaskEnum.RetrieveOptions) {
      setTask(
        data.registrationStatuses.length
          ? { type: TaskEnum.ShowOptionsAlert }
          : { type: TaskEnum.ShowNoOptionsAlert },
      );
    }
  }, [data, task]);

  if (
    !['BANKID', 'PASSWORD'].includes(authenticationMethod) ||
    !registrationStatusChangeable
  ) {
    return null;
  }

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  return (
    <>
      {(task?.type === TaskEnum.RunBankIdCollect ||
        task?.type === TaskEnum.BankIdCollectPending ||
        task?.type === TaskEnum.BankIdCollectQrCode) && (
        <BankIdCollect
          horseId={horseId}
          qrInit={qrInit}
          orderReference={
            (task.value as unknown as BankIdUserSigning).orderReference
          }
          setTask={setTask}
        />
      )}
      {task?.type === TaskEnum.RetrieveOptions && <></>}
      {(!task ||
        [
          TaskEnum.UpdateStatusByPasswordSuccess,
          TaskEnum.BankIdCollectSuccess,
          TaskEnum.FinishedWithoutOptions,
        ].includes(task?.type)) && (
        <Link
          to={getPath(path, {
            horseId,
          })}
        >
          <Icon size={16} color="gray6" as={Edit} />
        </Link>
      )}
      {[
        TaskEnum.ShowOptionsAlert,
        TaskEnum.RunUpdateStatusByPassword,
        TaskEnum.UpdateStatusByPasswordFailure,
        TaskEnum.RunBankIdInitialize,
        TaskEnum.BankIdInitializeFailure,
        TaskEnum.RunBankIdCollect,
        TaskEnum.BankIdCollectQrCode,
        TaskEnum.BankIdCollectPending,
        TaskEnum.BankIdCollectFailure,
      ].includes(task?.type) && (
        <Alert
          closeModal={closeModal}
          open
          size="medium"
          showFooter={false}
          showHeader={false}
        >
          <Form
            authenticationMethod={authenticationMethod}
            horseId={horseId}
            horseName={horseName}
            closeModal={closeModal}
            colorScheme="primary"
            registrationStatuses={data?.registrationStatuses || []}
            setTask={setTask}
            task={task}
            wideMobileButtons
          />
        </Alert>
      )}
      {task?.type === TaskEnum.ShowNoOptionsAlert && (
        <Alert
          title="Registreringstatus saknas"
          description="Det finns ingen registreringsstatus att ändra. För att dödförklara en
        häst kan den inte vara i träning hos en tränare."
          action="Stäng meddelandet"
          cancel={null}
          closeModal={setFinishedWithoutOptionsTask}
          colorScheme="delete"
          icon={Edit}
          iconProps={{
            ml: '2px',
          }}
          loading={false}
          onAction={setFinishedWithoutOptionsTask}
          open
          size="small"
        />
      )}
    </>
  );
};

export default ChangeRegistrationStatus;

// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    LicenseHolder,
    LicenseHolderFromJSON,
    LicenseHolderToJSON,
    RaceRaceType,
    RaceRaceTypeFromJSON,
    RaceRaceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface StartListHorse
 */
export interface StartListHorse  {
    /**
     * 
     * @type {number}
     * @memberof StartListHorse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StartListHorse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StartListHorse
     */
    age: string;
    /**
     * 
     * @type {RaceRaceType}
     * @memberof StartListHorse
     */
    horseGender: RaceRaceType;
    /**
     * 
     * @type {string}
     * @memberof StartListHorse
     */
    reasonForWithdrawn: string;
    /**
     * 
     * @type {LicenseHolder}
     * @memberof StartListHorse
     */
    driver: LicenseHolder;
    /**
     * 
     * @type {number}
     * @memberof StartListHorse
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof StartListHorse
     */
    handicap?: number;
    /**
     * 
     * @type {LicenseHolder}
     * @memberof StartListHorse
     */
    trainer: LicenseHolder;
    /**
     * 
     * @type {LicenseHolder}
     * @memberof StartListHorse
     */
    breeder: LicenseHolder;
    /**
     * 
     * @type {LicenseHolder}
     * @memberof StartListHorse
     */
    owner: LicenseHolder;
    /**
     * this is the number that the horse has in the program for the race.
     * @type {number}
     * @memberof StartListHorse
     */
    programNumber: number;
    /**
     * this is the number that the horse has in the program for the race.
     * @type {string}
     * @memberof StartListHorse
     */
    programNumberDisplay: string;
    /**
     * 
     * @type {LicenseHolder}
     * @memberof StartListHorse
     */
    canceledDriver?: LicenseHolder;
    /**
     * 
     * @type {string}
     * @memberof StartListHorse
     */
    blinkerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StartListHorse
     */
    blinkerText?: string;
    /**
     * Populated only if the driver weight was updated
     * @type {number}
     * @memberof StartListHorse
     */
    updatedDriverWeight?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StartListHorse
     */
    horseWithdrawn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StartListHorse
     */
    driverChanged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StartListHorse
     */
    linkable?: boolean;
}

export function StartListHorseFromJSON(json: any): StartListHorse {
    return {
        'id': json['id'],
        'name': json['name'],
        'age': json['age'],
        'horseGender': RaceRaceTypeFromJSON(json['horseGender']),
        'reasonForWithdrawn': json['reasonForWithdrawn'],
        'driver': LicenseHolderFromJSON(json['driver']),
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'handicap': !exists(json, 'handicap') ? undefined : json['handicap'],
        'trainer': LicenseHolderFromJSON(json['trainer']),
        'breeder': LicenseHolderFromJSON(json['breeder']),
        'owner': LicenseHolderFromJSON(json['owner']),
        'programNumber': json['programNumber'],
        'programNumberDisplay': json['programNumberDisplay'],
        'canceledDriver': !exists(json, 'canceledDriver') ? undefined : LicenseHolderFromJSON(json['canceledDriver']),
        'blinkerCode': !exists(json, 'blinkerCode') ? undefined : json['blinkerCode'],
        'blinkerText': !exists(json, 'blinkerText') ? undefined : json['blinkerText'],
        'updatedDriverWeight': !exists(json, 'updatedDriverWeight') ? undefined : json['updatedDriverWeight'],
        'horseWithdrawn': !exists(json, 'horseWithdrawn') ? undefined : json['horseWithdrawn'],
        'driverChanged': !exists(json, 'driverChanged') ? undefined : json['driverChanged'],
        'linkable': !exists(json, 'linkable') ? undefined : json['linkable'],
    };
}

export function StartListHorseToJSON(value?: StartListHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'age': value.age,
        'horseGender': RaceRaceTypeToJSON(value.horseGender),
        'reasonForWithdrawn': value.reasonForWithdrawn,
        'driver': LicenseHolderToJSON(value.driver),
        'weight': value.weight,
        'handicap': value.handicap,
        'trainer': LicenseHolderToJSON(value.trainer),
        'breeder': LicenseHolderToJSON(value.breeder),
        'owner': LicenseHolderToJSON(value.owner),
        'programNumber': value.programNumber,
        'programNumberDisplay': value.programNumberDisplay,
        'canceledDriver': LicenseHolderToJSON(value.canceledDriver),
        'blinkerCode': value.blinkerCode,
        'blinkerText': value.blinkerText,
        'updatedDriverWeight': value.updatedDriverWeight,
        'horseWithdrawn': value.horseWithdrawn,
        'driverChanged': value.driverChanged,
        'linkable': value.linkable,
    };
}



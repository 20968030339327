import config from '@config';
import useActiveMenu from '@hooks/useActiveMenu';
import useSearchIsOpen from '@hooks/useSearchIsOpen';
import Box, { Flex } from '@primitives/Box';
import List from '@primitives/List';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import HomeLink from '../HomeLink';
import logo from '../logo.png';
import devLogo from '../logo-test.png';
import menus from '../menus';
import RightMenu from '../RightMenu';
import TiltedBorder from '../TiltedBorder';
import PrimaryItem from './PrimaryItem';
import SubNavbar from './SubNav';

const Logo = styled.a`
  width: 60px;
  background-color: ${props => props.theme.colors.white};
  background-image: url('${config.isProduction ? logo : devLogo}');
  background-size: auto 28px;
  background-position: 15px center;
  background-repeat: no-repeat;
`;

const PageHeaderDesktop = (): JSX.Element => {
  const active = useActiveMenu();
  const mainMenu = useRef(null);
  const [hoverItem, setHoverItem] = useState<string | undefined>(undefined);
  const [hoverPrimaryItem, setHoverPrimaryItem] = useState<string | undefined>(
    undefined,
  );
  const { pathname } = useLocation();
  const size = active && active.subMenu ? 'pageHeaderExpanded' : 'pageHeader';
  const searchIsOpen = useSearchIsOpen();

  useEffect(() => {
    setHoverItem(undefined);
  }, [pathname]);

  const menuItems = menus.menu.map(item => (
    <PrimaryItem
      key={item.href}
      href={item.href}
      label={item.label}
      subMenu={item.subMenu}
      shortcutMenu={item.shortcutMenu}
      hoverItem={hoverItem}
      searchIsOpen={searchIsOpen}
      setHoverItem={href => {
        setHoverItem(href);
        setHoverPrimaryItem(href);
      }}
      showMarker={
        hoverPrimaryItem === item.href ||
        (hoverPrimaryItem === undefined && active && active.href === item.href)
      }
    />
  ));

  return (
    <Box width="100%" height={size}>
      <Box
        width="100%"
        height={size}
        className="hidden-print"
        position="fixed"
        top={0}
        sx={{
          zIndex: 1000,
          transition: 'height .3s ease',
          fontSize: 'pageHeader',
          fontStyle: 'bold',
        }}
      >
        <Flex
          width="100%"
          boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.16)"
          background="linear-gradient(90deg, white 50%, #233369 50%)"
        >
          <Box bg="white" flexGrow={1} height="100%" />
          <Box
            mr="auto"
            ml="auto"
            width="container"
            height="pageHeader"
            display="flex"
            color="white"
            position="relative"
          >
            {/* This is to avoid problems with backface-visibility: hidden */}
            <Box
              overflowX="hidden"
              overflowY="hidden"
              height="pageHeader"
              display="flex"
            >
              <Logo
                href="/"
                onMouseEnter={() => setHoverItem('/')}
                onMouseLeave={() => setHoverItem(undefined)}
              />
              <HomeLink setHoverItem={setHoverItem} />
            </Box>
            <TiltedBorder />
            <Flex
              sx={{
                alignItems: 'center',
              }}
              ref={mainMenu}
              position="relative"
              bg="deep-blue"
            >
              <List ml={3} itemGap={0} variant="horizontal" height="100%">
                {menuItems}
              </List>
            </Flex>
            <RightMenu setHoverItem={setHoverItem} hoverItem={hoverItem} />
          </Box>
          <SubNavbar />
          <Box bg="deep-blue" flexGrow={1} height="100%" />
        </Flex>
      </Box>
    </Box>
  );
};

export default PageHeaderDesktop;

// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContentPageResourceDocument
 */
export interface ContentPageResourceDocument  {
    /**
     * 
     * @type {string}
     * @memberof ContentPageResourceDocument
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageResourceDocument
     */
    url?: string;
}

export function ContentPageResourceDocumentFromJSON(json: any): ContentPageResourceDocument {
    return {
        'title': !exists(json, 'title') ? undefined : json['title'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function ContentPageResourceDocumentToJSON(value?: ContentPageResourceDocument): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'url': value.url,
    };
}



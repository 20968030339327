import {
  ErrorResponse,
  updateTransaction,
  UpdateTransactionRequest,
} from '@generated/tds/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

type ActionParams = {
  transaction: UpdateTransactionRequest['transaction'];
  id: number;
};

export interface Entities {
  updateTransaction?: ErrorResponse;
}

const useUpdateTransaction = (
  licenseId: number,
): MutationResponse<Entities['updateTransaction'], Entities, ActionParams> => {
  const queryFunc = ({
    transaction,
    id,
  }: ActionParams): QueryConfig<Entities> =>
    updateTransaction<Entities>({
      licenseId,
      id,
      transaction,
    });

  return useApiMutate<Entities['updateTransaction'], ActionParams>({
    queryFunc,
    selector: (state: State) => state.entities.updateTransaction,
    queryKey: 'updateTransaction',
  });
};

export default useUpdateTransaction;

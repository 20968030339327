import { useGetEnrollmentsForTrainer } from '@apis';
import Content from '@components/ContentBlock/Content';
import DataGrid from '@components/DataGrid';
import { AuthenticatedUser } from '@generated/account/src';
import { Enrollment } from '@generated/propositions/src';
import withUser from '@hoc/withUser';
import { dataGridRemoveButtonMixin } from '@primitives/Button';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import { formatTz } from '@utils/dateUtils';
import Refresh from '@utils/Refresh';
import roleEnum from '@utils/roleEnum';
import React, { useEffect } from 'react';
import { CellProps, Column } from 'react-table';

import Disenroll from './Disenroll';

interface Props {
  user: AuthenticatedUser;
}

const columns = (licenseId: number, refresh: Refresh): Column<Enrollment>[] => [
  {
    Header: 'Datum',
    accessor: 'propositionDate',
    Cell: ({ value }) => <>{formatTz(value, 'yyMMdd')}</>,
  },
  {
    Header: 'Bana',
    accessor: 'trackName',
  },
  {
    Header: 'Prop',
    accessor: 'propositionNumber',
  },
  {
    Header: 'Namn',
    accessor: 'horseName',
  },
  {
    Header: 'Vikt',
    accessor: 'weight',
  },
  {
    Header: 'Ta bort',
    accessor: 'modifiable',
    ...dataGridRemoveButtonMixin,
    Cell: ({ value, row }: CellProps<Enrollment>): JSX.Element =>
      value ? (
        <Disenroll
          horseName={row.original.horseName}
          licenseId={licenseId}
          propositionDate={row.original.propositionDate}
          propositionNumber={row.original.propositionNumber}
          horseId={row.original.horseId}
          propositionId={row.original.propositionId}
          trackName={row.original.trackName}
          refresh={refresh}
        />
      ) : null,
  },
];

const EnrolledHorses = ({ user: { licenseId } }: Props): JSX.Element => {
  const { data, loading, status, error, refresh } =
    useGetEnrollmentsForTrainer(licenseId);

  useEffect(() => {
    const timer = setInterval(() => {
      refresh();
    }, 1000 * 60);

    return () => {
      clearInterval(timer);
    };
  }, [refresh]);

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading && !data) {
    return <Loading />;
  }

  return (
    <>
      <Content slug="tranare-anmalda-hastar" as="div" mb={4} />
      <DataGrid
        title={` Du har ${data.length} häst${
          data.length > 1 ? 'ar' : ''
        } anmälda
        till lopp.`}
        columns={columns(licenseId, refresh)}
        data={data || []}
        showMore
        mt={4}
        noContent={{
          content: 'Du har inga anmälda hästar.',
        }}
      />
    </>
  );
};

export default withUser(EnrolledHorses, {
  roles: [roleEnum.Trainer],
});

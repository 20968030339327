// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    PedigreeHorse,
    PedigreeHorseFromJSON,
    PedigreeHorseToJSON,
} from './';

/**
 * 
 * @export
 * @interface Pedigree
 */
export interface Pedigree  {
    /**
     * 
     * @type {number}
     * @memberof Pedigree
     */
    horseId: number;
    /**
     * 
     * @type {PedigreeHorse}
     * @memberof Pedigree
     */
    father?: PedigreeHorse;
    /**
     * 
     * @type {PedigreeHorse}
     * @memberof Pedigree
     */
    mother?: PedigreeHorse;
}

export function PedigreeFromJSON(json: any): Pedigree {
    return {
        'horseId': json['horseId'],
        'father': !exists(json, 'father') ? undefined : PedigreeHorseFromJSON(json['father']),
        'mother': !exists(json, 'mother') ? undefined : PedigreeHorseFromJSON(json['mother']),
    };
}

export function PedigreeToJSON(value?: Pedigree): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'father': PedigreeHorseToJSON(value.father),
        'mother': PedigreeHorseToJSON(value.mother),
    };
}



import {
  getDriverStartsQueryKey,
  getTransactionProductsQueryKey,
  useUpdateTransaction,
} from '@apis';
import Alert from '@components/Alert';
import AlertFooter from '@components/Alert/Footer';
import AlertHeader from '@components/Alert/Header';
import { Input } from '@components/FormikComponents';
import FormLayout, { FormError, FormItem } from '@components/FormLayout';
import { resetQuery } from '@main/state/queriesReducer';
import Icon from '@primitives/Icon';
import { PenToSquare as EditIcon } from '@styled-icons/fa-solid';
import Refresh from '@utils/Refresh';
import { Form as FormikForm, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

type FormState = {
  amount: number;
  text: string;
};

interface Props {
  amount: number;
  changeable: boolean;
  text: string;
  id: number;
  lastUpdate: Date;
  licenseId: number;
  horseId: number;
  horseName: string;
  horseNumber: string;
  productCode: string;
  productDescription: string;
  refresh: Refresh;
  rejectedTransaction: boolean;
  shortProductDescription: string;
  transactionDate: Date;
  vatAmount: number;
  raceDayId: number;
}

export default function Edit({
  amount,
  changeable,
  text,
  id,
  lastUpdate,
  licenseId,
  horseId,
  horseName,
  horseNumber,
  productCode,
  productDescription,
  refresh,
  rejectedTransaction,
  shortProductDescription,
  transactionDate,
  vatAmount,
  raceDayId,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const { action, error } = useUpdateTransaction(licenseId);

  const [open, setOpen] = useState(false);

  const openModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onSubmit = useCallback(
    async values => {
      const { amount, text } = values;

      const { status } = await action({
        id,
        transaction: {
          amount,
          horseId,
          text,
          // Todo: Remove the parameters below when backend are ready
          changeable,
          id,
          licenseId,
          transactionDate,
          productCode,
          productDescription,
          shortProductDescription,
          vatAmount,
          horseNumber,
          horseName,
          lastUpdate,
          rejectedTransaction,
        },
      });

      if (status && status === 200) {
        dispatch(resetQuery(getDriverStartsQueryKey(licenseId)));
        dispatch(
          resetQuery(getTransactionProductsQueryKey(licenseId, raceDayId)),
        );
        refresh();
      }
    },
    [
      action,
      id,
      horseId,
      changeable,
      licenseId,
      transactionDate,
      productCode,
      productDescription,
      shortProductDescription,
      vatAmount,
      horseNumber,
      horseName,
      lastUpdate,
      rejectedTransaction,
      dispatch,
      refresh,
      raceDayId,
    ],
  );

  return (
    <>
      <button onClick={openModal}>
        <Icon color="gray6" size={16} as={EditIcon} />
      </button>
      {open && (
        <Alert
          closeModal={closeModal}
          open
          size="small"
          showFooter={false}
          showHeader={false}
        >
          <Formik<FormState>
            initialValues={{ amount, text }}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              amount: Yup.number()
                // .required('Obligatorisk')
                .typeError('Ange ett tal'),
              text: Yup.string().required('Obligatorisk'),
            })}
          >
            {({ isSubmitting }) => (
              <FormikForm>
                <AlertHeader
                  colorScheme="primary"
                  icon={EditIcon}
                  iconProps={{
                    ml: '2px',
                  }}
                  size="medium"
                  title="Ändra transaktion"
                  description={`${productDescription} för ${horseName}.`}
                />
                <FormLayout as="div" boxScheme="borderTopAndBottom">
                  <FormItem>
                    <Input
                      type="text"
                      name="amount"
                      label="Belopp (ex moms)"
                      disabled={isSubmitting}
                    />
                  </FormItem>
                  <FormItem>
                    <Input
                      type="text"
                      name="text"
                      label="Text"
                      disabled={isSubmitting}
                    />
                  </FormItem>
                  {error && <FormError>{error}</FormError>}
                </FormLayout>
                <AlertFooter
                  action="Spara"
                  buttonType="submit"
                  borderTopWidth={0}
                  closeModal={closeModal}
                  colorScheme="primary"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                />
              </FormikForm>
            )}
          </Formik>
        </Alert>
      )}
    </>
  );
}

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * The blinker choice for the horse
 * @export
 * @interface Blinkers
 */
export interface Blinkers  {
    /**
     * Blinkers code
     * @type {string}
     * @memberof Blinkers
     */
    code: string;
    /**
     * Blinkers description
     * @type {string}
     * @memberof Blinkers
     */
    description?: string;
}

export function BlinkersFromJSON(json: any): Blinkers {
    return {
        'code': json['code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function BlinkersToJSON(value?: Blinkers): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'code': value.code,
        'description': value.description,
    };
}



import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

declare global {
  interface Date {
    toLocalDate: () => string;
  }
}

Date.prototype.toLocalDate = function (this: Date) {
  return formatTz(this, 'yyyy-MM-dd');
};

export function isValidDate(d: Date | undefined): d is Date {
  return d instanceof Date && !isNaN(d.getTime());
}

export function isFutureDate(d: Date | undefined): boolean {
  return isValidDate(d) && d >= new Date();
}

export function dateToUtc(date: Date): Date {
  return date
    ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    : undefined;
}

export function formatTz(
  date: Date | undefined,
  fmt: string,
  options = {},
): string | null {
  if (date && isValidDate(date)) {
    const zoned = utcToZonedTime(date, 'Europe/Berlin');
    return format(zoned, fmt, options);
  }
  return null;
}

export function dateStringToUtc(dateString: string): Date {
  const [year, month, day] = dateString.split('-').map(str => parseInt(str));

  return new Date(Date.UTC(year, month - 1, day));
}

export function validateInputDate(dateString: string): boolean {
  // First check for the pattern
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const [year, month, day] = dateString.split('-').map(str => parseInt(str));

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

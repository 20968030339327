import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import { OWNERSHIP_TRANSFER_ROLES } from '@utils/constants';
import hasRole from '@utils/hasRole';
import roleEnum from '@utils/roleEnum';
import React from 'react';
import { generatePath, useRouteMatch } from 'react-router-dom';

import AuthWrapper from './AuthWrapper';
import NewTransfer from './NewTransfer';
import OngoingTransfers from './OngoingTransfers';

interface Props {
  user: AuthenticatedUser;
}

const OwnershipTransfers = ({ user }: Props): JSX.Element => {
  const { path } = useRouteMatch();

  const newTransferPath = generatePath(path, {
    tab: 'anmal',
  });

  const ongoingTransfersPath = generatePath(path, {
    tab: 'pagande',
  });

  const initTransferAccess = hasRole(user, roleEnum.Foretredare);

  const defaultTab = initTransferAccess
    ? newTransferPath
    : ongoingTransfersPath;

  return (
    <AuthWrapper
      headline="Ägarskiften"
      defaultPath={defaultTab}
      tabs={[
        initTransferAccess && {
          path: newTransferPath,
          text: 'Anmäl ägarskifte',
          component: NewTransfer,
          showBannedError: true,
        },
        {
          path: ongoingTransfersPath,
          text: 'Pågående ägarskiften',
          component: OngoingTransfers,
        },
      ]}
    />
  );
};

export default withUser(OwnershipTransfers, {
  roles: OWNERSHIP_TRANSFER_ROLES,
});

// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * License information
 * @export
 * @interface LicenseHolderLicenseInfo
 */
export interface LicenseHolderLicenseInfo  {
    /**
     * 
     * @type {string}
     * @memberof LicenseHolderLicenseInfo
     */
    licenseDescription: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseHolderLicenseInfo
     */
    licenseDescriptionExtended: string;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseHolderLicenseInfo
     */
    valid?: boolean;
}

export function LicenseHolderLicenseInfoFromJSON(json: any): LicenseHolderLicenseInfo {
    return {
        'licenseDescription': json['licenseDescription'],
        'licenseDescriptionExtended': json['licenseDescriptionExtended'],
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
    };
}

export function LicenseHolderLicenseInfoToJSON(value?: LicenseHolderLicenseInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseDescription': value.licenseDescription,
        'licenseDescriptionExtended': value.licenseDescriptionExtended,
        'valid': value.valid,
    };
}



// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Starts
 */
export interface Starts  {
    /**
     * The license holder id
     * @type {number}
     * @memberof Starts
     */
    licenseId: number;
    /**
     * The race day id
     * @type {number}
     * @memberof Starts
     */
    raceDayId: number;
    /**
     * The race day date
     * @type {Date}
     * @memberof Starts
     */
    date: Date;
    /**
     * Track name
     * @type {string}
     * @memberof Starts
     */
    trackName: string;
    /**
     * The total number of stable starts this race day
     * @type {number}
     * @memberof Starts
     */
    numberOfStarts: number;
    /**
     * The total number of withdrawals this race day
     * @type {number}
     * @memberof Starts
     */
    numberOfWithdrawals: number;
    /**
     * Has at least one registered transaction
     * @type {boolean}
     * @memberof Starts
     */
    registeredTransaction: boolean;
}

export function StartsFromJSON(json: any): Starts {
    return {
        'licenseId': json['licenseId'],
        'raceDayId': json['raceDayId'],
        'date': parseDateTime(json['date']),
        'trackName': json['trackName'],
        'numberOfStarts': json['numberOfStarts'],
        'numberOfWithdrawals': json['numberOfWithdrawals'],
        'registeredTransaction': json['registeredTransaction'],
    };
}

export function StartsToJSON(value?: Starts): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseId': value.licenseId,
        'raceDayId': value.raceDayId,
        'date': value.date.toLocalDate(),
        'trackName': value.trackName,
        'numberOfStarts': value.numberOfStarts,
        'numberOfWithdrawals': value.numberOfWithdrawals,
        'registeredTransaction': value.registeredTransaction,
    };
}



import Box from '@primitives/Box';
import { Link } from '@primitives/Link';
import React from 'react';
import styled from 'styled-components';

const BreadcrumbWrapper = styled(Box)`
  .breadcrumbLink:not(:first-child)::before {
    content: '>';
    margin-right: 5px;
    margin-left: 5px;
    color: ${props => props.theme.colors.gray6};
  }

  .breadcrumbLink:last-child {
    color: ${props => props.theme.colors.gray6};
  }
`;

export interface Breadcrumb {
  link?: string;
  title: string;
}

interface Props {
  linksArray?: Breadcrumb[];
}

function Breadcrumbs({ linksArray }: Props): JSX.Element {
  return (
    <BreadcrumbWrapper
      sx={{
        fontSize: ['micro', , 'notes'],
        bg: ['white', , 'initial'],
        borderBottomWidth: ['1px', , 0],
        px: [3, , 2],
      }}
      py="10px"
      borderBottomColor="gray2"
      borderBottomStyle="solid"
    >
      {linksArray.slice(0, -1).map(item => (
        <Link className="breadcrumbLink" key={item.title} to={item.link}>
          {item.title}
        </Link>
      ))}
      <Link className="breadcrumbLink" as="span">
        {linksArray.slice(-1)[0]?.title}
      </Link>
    </BreadcrumbWrapper>
  );
}

export default Breadcrumbs;

// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchResultItem
 */
export interface SearchResultItem  {
    /**
     * 
     * @type {number}
     * @memberof SearchResultItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SearchResultItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResultItem
     */
    type: SearchResultItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchResultItem
     */
    displayType: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResultItem
     */
    url: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchResultItem
     */
    sportinfo: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchResultItem
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResultItem
     */
    licenseType?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchResultItem
     */
    birth?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchResultItem
     */
    trainingPlace?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchResultItem
     */
    score?: number;
}

export function SearchResultItemFromJSON(json: any): SearchResultItem {
    return {
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'displayType': json['displayType'],
        'url': json['url'],
        'sportinfo': json['sportinfo'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'licenseType': !exists(json, 'licenseType') ? undefined : json['licenseType'],
        'birth': !exists(json, 'birth') ? undefined : json['birth'],
        'trainingPlace': !exists(json, 'trainingPlace') ? undefined : json['trainingPlace'],
        'score': !exists(json, 'score') ? undefined : json['score'],
    };
}

export function SearchResultItemToJSON(value?: SearchResultItem): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'displayType': value.displayType,
        'url': value.url,
        'sportinfo': value.sportinfo,
        'gender': value.gender,
        'licenseType': value.licenseType,
        'birth': value.birth,
        'trainingPlace': value.trainingPlace,
        'score': value.score,
    };
}

/**
* @export
* @enum {string}
*/
export enum SearchResultItemTypeEnum {
    LicenseHolder = 'license_holder',
    Horse = 'horse',
    Breeder = 'breeder',
    Owner = 'owner',
    Page = 'page',
    AppPage = 'app_page',
    News = 'news',
    Event = 'event'
}



// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * The product
 * @export
 * @interface Product
 */
export interface Product  {
    /**
     * Product code
     * @type {string}
     * @memberof Product
     */
    productCode: string;
    /**
     * Product description
     * @type {string}
     * @memberof Product
     */
    description: string;
    /**
     * Product abstract description
     * @type {string}
     * @memberof Product
     */
    shortDescription: string;
    /**
     * Vat amount
     * @type {number}
     * @memberof Product
     */
    vatRate: number;
    /**
     * If this is a driver/rider start product or not
     * @type {boolean}
     * @memberof Product
     */
    driverStartProduct: boolean;
    /**
     * If this is a commission product for drivers and riders
     * @type {boolean}
     * @memberof Product
     */
    commissionProduct: boolean;
    /**
     * Debit and credit factor
     * @type {number}
     * @memberof Product
     */
    debitCreditFactor: number;
    /**
     * Minimum amount
     * @type {number}
     * @memberof Product
     */
    minimumAmount: number;
    /**
     * Minimum amount
     * @type {number}
     * @memberof Product
     */
    maximumAmount: number;
}

export function ProductFromJSON(json: any): Product {
    return {
        'productCode': json['productCode'],
        'description': json['description'],
        'shortDescription': json['shortDescription'],
        'vatRate': json['vatRate'],
        'driverStartProduct': json['driverStartProduct'],
        'commissionProduct': json['commissionProduct'],
        'debitCreditFactor': json['debitCreditFactor'],
        'minimumAmount': json['minimumAmount'],
        'maximumAmount': json['maximumAmount'],
    };
}

export function ProductToJSON(value?: Product): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'productCode': value.productCode,
        'description': value.description,
        'shortDescription': value.shortDescription,
        'vatRate': value.vatRate,
        'driverStartProduct': value.driverStartProduct,
        'commissionProduct': value.commissionProduct,
        'debitCreditFactor': value.debitCreditFactor,
        'minimumAmount': value.minimumAmount,
        'maximumAmount': value.maximumAmount,
    };
}



// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    EnrolledHorse,
    EnrolledHorseFromJSON,
    EnrolledHorseToJSON,
    ExtraText,
    ExtraTextFromJSON,
    ExtraTextToJSON,
    InfoText,
    InfoTextFromJSON,
    InfoTextToJSON,
    Proposition,
    PropositionFromJSON,
    PropositionToJSON,
} from './';

/**
 * 
 * @export
 * @interface EntryWeightDetail
 */
export interface EntryWeightDetail  {
    /**
     * 
     * @type {string}
     * @memberof EntryWeightDetail
     */
    title: string;
    /**
     * 
     * @type {Array<InfoText>}
     * @memberof EntryWeightDetail
     */
    infoTexts: Array<InfoText>;
    /**
     * 
     * @type {Array<EnrolledHorse>}
     * @memberof EntryWeightDetail
     */
    enrolledHorse: Array<EnrolledHorse>;
    /**
     * 
     * @type {Array<ExtraText>}
     * @memberof EntryWeightDetail
     */
    extraText?: Array<ExtraText>;
    /**
     * 
     * @type {Date}
     * @memberof EntryWeightDetail
     */
    registrationDateTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof EntryWeightDetail
     */
    startRegistrationDateTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof EntryWeightDetail
     */
    weightRegistrationDateTime: Date;
    /**
     * 
     * @type {string}
     * @memberof EntryWeightDetail
     */
    trackName: string;
    /**
     * 
     * @type {Date}
     * @memberof EntryWeightDetail
     */
    raceDate: Date;
    /**
     * 
     * @type {Proposition}
     * @memberof EntryWeightDetail
     */
    proposition?: Proposition;
}

export function EntryWeightDetailFromJSON(json: any): EntryWeightDetail {
    return {
        'title': json['title'],
        'infoTexts': (json['infoTexts'] as Array<any>).map(InfoTextFromJSON),
        'enrolledHorse': (json['enrolledHorse'] as Array<any>).map(EnrolledHorseFromJSON),
        'extraText': !exists(json, 'extraText') ? undefined : (json['extraText'] as Array<any>).map(ExtraTextFromJSON),
        'registrationDateTime': parseDateTime(json['registrationDateTime']),
        'startRegistrationDateTime': parseDateTime(json['startRegistrationDateTime']),
        'weightRegistrationDateTime': parseDateTime(json['weightRegistrationDateTime']),
        'trackName': json['trackName'],
        'raceDate': parseDateTime(json['raceDate']),
        'proposition': !exists(json, 'proposition') ? undefined : PropositionFromJSON(json['proposition']),
    };
}

export function EntryWeightDetailToJSON(value?: EntryWeightDetail): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'infoTexts': (value.infoTexts as Array<any>).map(InfoTextToJSON),
        'enrolledHorse': (value.enrolledHorse as Array<any>).map(EnrolledHorseToJSON),
        'extraText': value.extraText === undefined ? undefined : (value.extraText as Array<any>).map(ExtraTextToJSON),
        'registrationDateTime': value.registrationDateTime.toISOString(),
        'startRegistrationDateTime': value.startRegistrationDateTime.toISOString(),
        'weightRegistrationDateTime': value.weightRegistrationDateTime.toISOString(),
        'trackName': value.trackName,
        'raceDate': value.raceDate.toLocalDate(),
        'proposition': PropositionToJSON(value.proposition),
    };
}



import { Pedigree, PedigreeHorse } from '@generated/horses/src';
import { horse } from '@utils/links';

interface Ancestor {
  name: string;
  male: boolean;
  yearOfBirth?: string;
  registrationNumber?: string;
  linkable?: boolean;
  url?: string;
}

type AncestorTree = PedigreeHorse | Pedigree;

// {
//   name?: string;
//   father?: AncestorTree;
//   mother?: AncestorTree;
//   yearOfBirth?: string;
//   registrationNumber?: string;
//   linkable?: boolean;
//   horseId: number;
// }

function isPedigreeHorse(val: AncestorTree): val is PedigreeHorse {
  return {}.hasOwnProperty.call(val, 'name');
}

export default function makeIterable(
  root: AncestorTree,
  size: string,
): Ancestor[][] {
  const maxDepth = size === 'SMALL' ? 4 : 6;

  /* Separating each generation of horses into separate lists for rendering in 
    different columns. */
  const columns: Ancestor[][] = [];
  const separateColumns = (
    root: AncestorTree,
    depth: number,
    male: boolean,
  ): void => {
    /* A recursive funcition, traversing each level/generation 
    of the ancestorTree and populating columns. */

    if (depth >= maxDepth) {
      return;
    }

    if (depth >= columns.length) {
      columns.push([]);
    }

    // Push dummy if mother/father doesn't exist
    if (!root) {
      columns[depth].push({
        name: '0',
        male: male,
      });

      separateColumns(undefined, depth + 1, true);
      separateColumns(undefined, depth + 1, false);
      return;
    }

    if (isPedigreeHorse(root)) {
      columns[depth].push({
        name: root.name,
        male: male,
        yearOfBirth: root.yearOfBirth,
        linkable: root.linkable,
        registrationNumber: root.registrationNumber,
        url: horse(root.horseId),
      });
    }

    separateColumns(root.father, depth + 1, true);
    separateColumns(root.mother, depth + 1, false);
  };

  separateColumns(root, 0, true);
  // Remove the first element since the first horse is not included in the StudBook.
  return columns.slice(1);
}

import { CategorySelection, getCategories } from '@generated/breedings/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  breedingCategories?: CategorySelection;
}

type Categories = CategorySelection | undefined;

const useGetBreedingCategories = (): ApiResponse<Categories, Entities> => {
  const query = getCategories<Entities>({
    transform: responseBody => ({
      breedingCategories: responseBody,
    }),
    update: {
      breedingCategories: (prev, next) => next,
    },
  });
  const selector = (state: State): Categories =>
    state.entities?.breedingCategories;

  return useApi<Categories>({
    query,
    selector,
  });
};

export default useGetBreedingCategories;

// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Mounting,
    MountingFromJSON,
    MountingToJSON,
} from '../models';

export interface GetMountingsRequest {
    licenseId: number;
}

export interface RegisterMountingRequest {
    licenseId: number;
    raceDayId: number;
    body?: string;
}

export interface UnregisterMountingRequest {
    licenseId: number;
    raceDayId: number;
}


/**
 * Fetch all registered race days that driver has registered to ride on
 */
function getMountingsRaw<T>(requestParameters: GetMountingsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Mounting>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getMountings.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/{licenseId}/mountings`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(MountingFromJSON), text);
    }

    return config;
}

/**
* Fetch all registered race days that driver has registered to ride on
*/
export function getMountings<T>(requestParameters: GetMountingsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Mounting>>): QueryConfig<T> {
    return getMountingsRaw(requestParameters, requestConfig);
}

/**
 * Registers driver mounting by given license id and race day id
 */
function registerMountingRaw<T>(requestParameters: RegisterMountingRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling registerMounting.');
    }

    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling registerMounting.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/{licenseId}/mountings/{raceDayId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || requestParameters.body as any,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Registers driver mounting by given license id and race day id
*/
export function registerMounting<T>(requestParameters: RegisterMountingRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return registerMountingRaw(requestParameters, requestConfig);
}

/**
 * Unregisters driver mounting by given license id and race day id
 */
function unregisterMountingRaw<T>(requestParameters: UnregisterMountingRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling unregisterMounting.');
    }

    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling unregisterMounting.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/{licenseId}/mountings/{raceDayId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Unregisters driver mounting by given license id and race day id
*/
export function unregisterMounting<T>(requestParameters: UnregisterMountingRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return unregisterMountingRaw(requestParameters, requestConfig);
}


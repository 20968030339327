import {
  LicenseHolderReference,
  searchLicenseHolderByLabel,
  SearchLicenseHolderByLabelRequest,
} from '@generated/licenseholders/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  licenseHolders?: {
    [key: string]: LicenseHolderReference[];
  };
}

export const useSearchLicenseHolder = (
  request: SearchLicenseHolderByLabelRequest,
): ApiResponse<LicenseHolderReference[] | undefined, Entities> => {
  // 1. Query
  const query = searchLicenseHolderByLabel<Entities>(request, {
    transform: responseBody => ({
      licenseHolders: {
        [JSON.stringify(request)]: responseBody,
      },
    }),
    update: {
      licenseHolders: (prev, next) => ({
        ...prev,
        ...next,
      }),
    },
  });

  // 2. Selector
  const selector = (state: State): LicenseHolderReference[] | undefined =>
    state.entities.licenseHolders?.[JSON.stringify(request)];

  return useApi<LicenseHolderReference[] | undefined>({
    query,
    selector,
  });
};

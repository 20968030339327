import { Driver, getDriversForRaceDay } from '@generated/raceinfo/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Results = Driver[] | undefined;

export interface Entities {
  availableDrivers?: {
    [key: string]: Results;
  };
}

export default function useGetAvailableDrivers(
  raceDayId: number,
  propositionId: number,
): ApiResponse<Results, Entities> {
  const key = `${raceDayId}-${propositionId}`;
  const query = getDriversForRaceDay<Entities>(
    {
      raceDayId,
      propositionId,
    },
    {
      transform: responseBody => ({
        availableDrivers: {
          [key]: responseBody,
        },
      }),
      update: {
        availableDrivers: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Results =>
    state.entities?.availableDrivers?.[key];

  return useApi<Results>({
    query,
    selector,
  });
}

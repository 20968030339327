// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ComingStart,
    ComingStartFromJSON,
    ComingStartToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface GetComingStartsByOwnershipsRequest {
    ownerId: number;
}

export interface GetComingStartsForBreederRequest {
    breederId: number;
}

export interface GetComingStartsForOwnerRequest {
    ownerId: number;
}


/**
 * Get all coming starts for the provided owner id
 */
function getComingStartsByOwnershipsRaw<T>(requestParameters: GetComingStartsByOwnershipsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<ComingStart>> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getComingStartsByOwnerships.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/comingstarts`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(ComingStartFromJSON), text);
    }

    return config;
}

/**
* Get all coming starts for the provided owner id
*/
export function getComingStartsByOwnerships<T>(requestParameters: GetComingStartsByOwnershipsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<ComingStart>>): QueryConfig<T> {
    return getComingStartsByOwnershipsRaw(requestParameters, requestConfig);
}

/**
 * Get coming starts for breeder\'s horses
 */
function getComingStartsForBreederRaw<T>(requestParameters: GetComingStartsForBreederRequest, requestConfig: runtime.TypedQueryConfig<T, Array<ComingStart>> = {}): QueryConfig<T> {
    if (requestParameters.breederId === null || requestParameters.breederId === undefined) {
        throw new runtime.RequiredError('breederId','Required parameter requestParameters.breederId was null or undefined when calling getComingStartsForBreeder.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/breeders/{breederId}/comingstarts`.replace(`{${"breederId"}}`, encodeURIComponent(String(requestParameters.breederId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(ComingStartFromJSON), text);
    }

    return config;
}

/**
* Get coming starts for breeder\'s horses
*/
export function getComingStartsForBreeder<T>(requestParameters: GetComingStartsForBreederRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<ComingStart>>): QueryConfig<T> {
    return getComingStartsForBreederRaw(requestParameters, requestConfig);
}

/**
 * Get coming starts for ownership horses
 */
function getComingStartsForOwnerRaw<T>(requestParameters: GetComingStartsForOwnerRequest, requestConfig: runtime.TypedQueryConfig<T, Array<ComingStart>> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getComingStartsForOwner.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/comingstarts`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(ComingStartFromJSON), text);
    }

    return config;
}

/**
* Get coming starts for ownership horses
*/
export function getComingStartsForOwner<T>(requestParameters: GetComingStartsForOwnerRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<ComingStart>>): QueryConfig<T> {
    return getComingStartsForOwnerRaw(requestParameters, requestConfig);
}


// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    LicenseHolderReference,
    LicenseHolderReferenceFromJSON,
    LicenseHolderReferenceToJSON,
    Selection,
    SelectionFromJSON,
    SelectionToJSON,
} from '../models';

export interface SearchLicenseHolderByLabelRequest {
    showAll: boolean;
    abbreviation?: string;
    firstName?: string;
    lastName?: string;
    licenseType?: string;
    trackId?: string;
    trainingPlace?: string;
}


/**
 * Get available search selections and their corresponding options.
 */
function getSearchSelectionsRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Array<Selection>> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/search/selections`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(SelectionFromJSON), text);
    }

    return config;
}

/**
* Get available search selections and their corresponding options.
*/
export function getSearchSelections<T>( requestConfig?: runtime.TypedQueryConfig<T, Array<Selection>>): QueryConfig<T> {
    return getSearchSelectionsRaw( requestConfig);
}

/**
 * Searches for license holders matching the provided search criterias.
 */
function searchLicenseHolderByLabelRaw<T>(requestParameters: SearchLicenseHolderByLabelRequest, requestConfig: runtime.TypedQueryConfig<T, Array<LicenseHolderReference>> = {}): QueryConfig<T> {
    if (requestParameters.showAll === null || requestParameters.showAll === undefined) {
        throw new runtime.RequiredError('showAll','Required parameter requestParameters.showAll was null or undefined when calling searchLicenseHolderByLabel.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.abbreviation !== undefined) {
        queryParameters['abbreviation'] = requestParameters.abbreviation;
    }


    if (requestParameters.firstName !== undefined) {
        queryParameters['firstName'] = requestParameters.firstName;
    }


    if (requestParameters.lastName !== undefined) {
        queryParameters['lastName'] = requestParameters.lastName;
    }


    if (requestParameters.licenseType !== undefined) {
        queryParameters['licenseType'] = requestParameters.licenseType;
    }


    if (requestParameters.showAll !== undefined) {
        queryParameters['showAll'] = requestParameters.showAll;
    }


    if (requestParameters.trackId !== undefined) {
        queryParameters['trackId'] = requestParameters.trackId;
    }


    if (requestParameters.trainingPlace !== undefined) {
        queryParameters['trainingPlace'] = requestParameters.trainingPlace;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/search`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(LicenseHolderReferenceFromJSON), text);
    }

    return config;
}

/**
* Searches for license holders matching the provided search criterias.
*/
export function searchLicenseHolderByLabel<T>(requestParameters: SearchLicenseHolderByLabelRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<LicenseHolderReference>>): QueryConfig<T> {
    return searchLicenseHolderByLabelRaw(requestParameters, requestConfig);
}


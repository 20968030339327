// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface RaceDayWithNegativeDoping
 */
export interface RaceDayWithNegativeDoping  {
    /**
     * 
     * @type {Date}
     * @memberof RaceDayWithNegativeDoping
     */
    raceDate: Date;
    /**
     * 
     * @type {number}
     * @memberof RaceDayWithNegativeDoping
     */
    trackId: number;
    /**
     * 
     * @type {string}
     * @memberof RaceDayWithNegativeDoping
     */
    trackCode: string;
    /**
     * 
     * @type {string}
     * @memberof RaceDayWithNegativeDoping
     */
    trackName: string;
    /**
     * 
     * @type {number}
     * @memberof RaceDayWithNegativeDoping
     */
    raceDayId: number;
    /**
     * 
     * @type {boolean}
     * @memberof RaceDayWithNegativeDoping
     */
    negativeResultsExists: boolean;
}

export function RaceDayWithNegativeDopingFromJSON(json: any): RaceDayWithNegativeDoping {
    return {
        'raceDate': parseDateTime(json['raceDate']),
        'trackId': json['trackId'],
        'trackCode': json['trackCode'],
        'trackName': json['trackName'],
        'raceDayId': json['raceDayId'],
        'negativeResultsExists': json['negativeResultsExists'],
    };
}

export function RaceDayWithNegativeDopingToJSON(value?: RaceDayWithNegativeDoping): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'raceDate': value.raceDate.toLocalDate(),
        'trackId': value.trackId,
        'trackCode': value.trackCode,
        'trackName': value.trackName,
        'raceDayId': value.raceDayId,
        'negativeResultsExists': value.negativeResultsExists,
    };
}



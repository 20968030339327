// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface PrizeMoney
 */
export interface PrizeMoney  {
    /**
     * 
     * @type {number}
     * @memberof PrizeMoney
     */
    sortValue: number;
    /**
     * 
     * @type {string}
     * @memberof PrizeMoney
     */
    displayValue: string;
}

export function PrizeMoneyFromJSON(json: any): PrizeMoney {
    return {
        'sortValue': json['sortValue'],
        'displayValue': json['displayValue'],
    };
}

export function PrizeMoneyToJSON(value?: PrizeMoney): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'sortValue': value.sortValue,
        'displayValue': value.displayValue,
    };
}



// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    SelectionParameter,
    SelectionParameterFromJSON,
    SelectionParameterToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchFilter
 */
export interface SearchFilter  {
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    list: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    year: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    firstPrice: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    trackSurface: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    breed: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    returnNumberOfEntries: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    raceOnTrack?: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    licenseType?: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    licenseCountry?: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    homeTrack?: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    gender?: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    breederStatus?: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    age?: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    ageFrom?: SelectionParameter;
    /**
     * 
     * @type {SelectionParameter}
     * @memberof SearchFilter
     */
    ageTo?: SelectionParameter;
}

export function SearchFilterFromJSON(json: any): SearchFilter {
    return {
        'list': SelectionParameterFromJSON(json['list']),
        'year': SelectionParameterFromJSON(json['year']),
        'firstPrice': SelectionParameterFromJSON(json['firstPrice']),
        'trackSurface': SelectionParameterFromJSON(json['trackSurface']),
        'breed': SelectionParameterFromJSON(json['breed']),
        'returnNumberOfEntries': SelectionParameterFromJSON(json['returnNumberOfEntries']),
        'raceOnTrack': !exists(json, 'raceOnTrack') ? undefined : SelectionParameterFromJSON(json['raceOnTrack']),
        'licenseType': !exists(json, 'licenseType') ? undefined : SelectionParameterFromJSON(json['licenseType']),
        'licenseCountry': !exists(json, 'licenseCountry') ? undefined : SelectionParameterFromJSON(json['licenseCountry']),
        'homeTrack': !exists(json, 'homeTrack') ? undefined : SelectionParameterFromJSON(json['homeTrack']),
        'gender': !exists(json, 'gender') ? undefined : SelectionParameterFromJSON(json['gender']),
        'breederStatus': !exists(json, 'breederStatus') ? undefined : SelectionParameterFromJSON(json['breederStatus']),
        'age': !exists(json, 'age') ? undefined : SelectionParameterFromJSON(json['age']),
        'ageFrom': !exists(json, 'ageFrom') ? undefined : SelectionParameterFromJSON(json['ageFrom']),
        'ageTo': !exists(json, 'ageTo') ? undefined : SelectionParameterFromJSON(json['ageTo']),
    };
}

export function SearchFilterToJSON(value?: SearchFilter): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'list': SelectionParameterToJSON(value.list),
        'year': SelectionParameterToJSON(value.year),
        'firstPrice': SelectionParameterToJSON(value.firstPrice),
        'trackSurface': SelectionParameterToJSON(value.trackSurface),
        'breed': SelectionParameterToJSON(value.breed),
        'returnNumberOfEntries': SelectionParameterToJSON(value.returnNumberOfEntries),
        'raceOnTrack': SelectionParameterToJSON(value.raceOnTrack),
        'licenseType': SelectionParameterToJSON(value.licenseType),
        'licenseCountry': SelectionParameterToJSON(value.licenseCountry),
        'homeTrack': SelectionParameterToJSON(value.homeTrack),
        'gender': SelectionParameterToJSON(value.gender),
        'breederStatus': SelectionParameterToJSON(value.breederStatus),
        'age': SelectionParameterToJSON(value.age),
        'ageFrom': SelectionParameterToJSON(value.ageFrom),
        'ageTo': SelectionParameterToJSON(value.ageTo),
    };
}



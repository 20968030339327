// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * All races with result status - is the result data ready or not?
 * @export
 * @interface RaceWithBasicInfoAndResultStatus
 */
export interface RaceWithBasicInfoAndResultStatus  {
    /**
     * The unique race id
     * @type {number}
     * @memberof RaceWithBasicInfoAndResultStatus
     */
    raceId: number;
    /**
     * 
     * @type {number}
     * @memberof RaceWithBasicInfoAndResultStatus
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof RaceWithBasicInfoAndResultStatus
     */
    numberDisplay: string;
    /**
     * True once the race is finished and results are registered
     * @type {boolean}
     * @memberof RaceWithBasicInfoAndResultStatus
     */
    resultReady?: boolean;
}

export function RaceWithBasicInfoAndResultStatusFromJSON(json: any): RaceWithBasicInfoAndResultStatus {
    return {
        'raceId': json['raceId'],
        'number': json['number'],
        'numberDisplay': json['numberDisplay'],
        'resultReady': !exists(json, 'resultReady') ? undefined : json['resultReady'],
    };
}

export function RaceWithBasicInfoAndResultStatusToJSON(value?: RaceWithBasicInfoAndResultStatus): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'raceId': value.raceId,
        'number': value.number,
        'numberDisplay': value.numberDisplay,
        'resultReady': value.resultReady,
    };
}



// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Only returned if the collect was successful, i.e. the sign in Freja Eid app was successfully performed.
 * @export
 * @interface HorseBasicInformation
 */
export interface HorseBasicInformation  {
    /**
     * 
     * @type {number}
     * @memberof HorseBasicInformation
     */
    horseId?: number;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    horseName?: string;
}

export function HorseBasicInformationFromJSON(json: any): HorseBasicInformation {
    return {
        'horseId': !exists(json, 'horseId') ? undefined : json['horseId'],
        'horseName': !exists(json, 'horseName') ? undefined : json['horseName'],
    };
}

export function HorseBasicInformationToJSON(value?: HorseBasicInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'horseName': value.horseName,
    };
}



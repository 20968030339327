// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SportActorResults,
    SportActorResultsFromJSON,
    SportActorResultsToJSON,
} from '../models';

export interface GetBreederResultsRequest {
    breederId: number;
}

export interface GetOwnershipResultsRequest {
    ownerId: number;
}

export interface GetResultsByOwnershipsRequest {
    ownerId: number;
}


/**
 * Get breeder results
 */
function getBreederResultsRaw<T>(requestParameters: GetBreederResultsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<SportActorResults>> = {}): QueryConfig<T> {
    if (requestParameters.breederId === null || requestParameters.breederId === undefined) {
        throw new runtime.RequiredError('breederId','Required parameter requestParameters.breederId was null or undefined when calling getBreederResults.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/breeders/{breederId}/results`.replace(`{${"breederId"}}`, encodeURIComponent(String(requestParameters.breederId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(SportActorResultsFromJSON), text);
    }

    return config;
}

/**
* Get breeder results
*/
export function getBreederResults<T>(requestParameters: GetBreederResultsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<SportActorResults>>): QueryConfig<T> {
    return getBreederResultsRaw(requestParameters, requestConfig);
}

/**
 * Get ownership results
 */
function getOwnershipResultsRaw<T>(requestParameters: GetOwnershipResultsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<SportActorResults>> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getOwnershipResults.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/results`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(SportActorResultsFromJSON), text);
    }

    return config;
}

/**
* Get ownership results
*/
export function getOwnershipResults<T>(requestParameters: GetOwnershipResultsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<SportActorResults>>): QueryConfig<T> {
    return getOwnershipResultsRaw(requestParameters, requestConfig);
}

/**
 * Retrieve all results of the horses I have ownership of for the provided owners id.
 */
function getResultsByOwnershipsRaw<T>(requestParameters: GetResultsByOwnershipsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<SportActorResults>> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getResultsByOwnerships.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/results`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(SportActorResultsFromJSON), text);
    }

    return config;
}

/**
* Retrieve all results of the horses I have ownership of for the provided owners id.
*/
export function getResultsByOwnerships<T>(requestParameters: GetResultsByOwnershipsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<SportActorResults>>): QueryConfig<T> {
    return getResultsByOwnershipsRaw(requestParameters, requestConfig);
}


// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HorseResult,
    HorseResultFromJSON,
    HorseResultToJSON,
    RaceResult,
    RaceResultFromJSON,
    RaceResultToJSON,
} from '../models';

export interface FetchHorseResultsRequest {
    horseId: number;
}

export interface GetRaceResultRequest {
    horseId: number;
}


/**
 * Fetch all horse results.
 */
function fetchHorseResultsRaw<T>(requestParameters: FetchHorseResultsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<HorseResult>> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling fetchHorseResults.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{horseId}/results`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(HorseResultFromJSON), text);
    }

    return config;
}

/**
* Fetch all horse results.
*/
export function fetchHorseResults<T>(requestParameters: FetchHorseResultsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<HorseResult>>): QueryConfig<T> {
    return fetchHorseResultsRaw(requestParameters, requestConfig);
}

/**
 * Fetch horse race list.
 */
function getRaceResultRaw<T>(requestParameters: GetRaceResultRequest, requestConfig: runtime.TypedQueryConfig<T, Array<RaceResult>> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling getRaceResult.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{horseId}/races`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(RaceResultFromJSON), text);
    }

    return config;
}

/**
* Fetch horse race list.
*/
export function getRaceResult<T>(requestParameters: GetRaceResultRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<RaceResult>>): QueryConfig<T> {
    return getRaceResultRaw(requestParameters, requestConfig);
}


import { AuthenticatedUser } from '@generated/account/src';
import { useSelector } from 'react-redux';

import { State } from '../main/store';

export default function useForceChangeOfPassword(): boolean | undefined {
  const selector = (state: State): boolean | undefined =>
    state.entities.forceChangeOfPassword ||
    (state.entities.user as AuthenticatedUser)?.userInfo?.temporaryPassword;

  return useSelector(selector);
}

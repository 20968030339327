import {
  getOngoingTransfers,
  GetOngoingTransfersServiceTypeEnum,
  OwnershipTransfer,
} from '../../generated/ownership-transfers/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  ongoingTransfers?: {
    [key: string]: OwnershipTransfer[];
  };
}

const useGetOngoingTransfers = (
  licenseId: number,
  serviceType: GetOngoingTransfersServiceTypeEnum = GetOngoingTransfersServiceTypeEnum.OWNERSHIP_TRANSFERS,
): ApiResponse<OwnershipTransfer[], Entities> =>
  useApi<OwnershipTransfer[]>({
    resetOnUnmount: true,
    query: getOngoingTransfers<Entities>(
      { licenseId, serviceType },
      {
        queryKey: `ongoingTransfers:${licenseId}:${serviceType}`,
        transform: responseBody => ({
          ongoingTransfers: {
            [`${licenseId}:${serviceType}`]: responseBody,
          },
        }),
        update: {
          ongoingTransfers: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state =>
      state.entities?.ongoingTransfers?.[`${licenseId}:${serviceType}`],
  });

export default useGetOngoingTransfers;

import Box from '@primitives/Box';
import React from 'react';
import { useParams } from 'react-router-dom';

import Form from './Form';
import List from './List';

interface Props {
  accountPage?: boolean;
}

export default function UpcomingStakeRaces({
  accountPage,
}: Props): JSX.Element {
  const { propositionId } = useParams<{ propositionId?: string }>();

  if (propositionId) {
    return (
      <Box mt={[0, , 4]}>
        <Form
          accountPage={accountPage}
          propositionId={parseInt(propositionId)}
        />
      </Box>
    );
  }

  return (
    <Box mt={4}>
      <List accountPage={accountPage} />
    </Box>
  );
}

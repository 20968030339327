// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    LicenseHolderContactInfo,
    LicenseHolderContactInfoFromJSON,
    LicenseHolderContactInfoToJSON,
    LicenseHolderLicenseInfo,
    LicenseHolderLicenseInfoFromJSON,
    LicenseHolderLicenseInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface LicenseHolderReference
 */
export interface LicenseHolderReference  {
    /**
     * 
     * @type {number}
     * @memberof LicenseHolderReference
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LicenseHolderReference
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseHolderReference
     */
    shortName: string;
    /**
     * If this license holder is linkable.
     * @type {boolean}
     * @memberof LicenseHolderReference
     */
    linkable: boolean;
    /**
     * The home track name.
     * @type {string}
     * @memberof LicenseHolderReference
     */
    trackName?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseHolderReference
     */
    trainingPlace: string;
    /**
     * 
     * @type {LicenseHolderContactInfo}
     * @memberof LicenseHolderReference
     */
    contactInfo: LicenseHolderContactInfo;
    /**
     * 
     * @type {LicenseHolderLicenseInfo}
     * @memberof LicenseHolderReference
     */
    licenseInfo: LicenseHolderLicenseInfo;
}

export function LicenseHolderReferenceFromJSON(json: any): LicenseHolderReference {
    return {
        'id': json['id'],
        'name': json['name'],
        'shortName': json['shortName'],
        'linkable': json['linkable'],
        'trackName': !exists(json, 'trackName') ? undefined : json['trackName'],
        'trainingPlace': json['trainingPlace'],
        'contactInfo': LicenseHolderContactInfoFromJSON(json['contactInfo']),
        'licenseInfo': LicenseHolderLicenseInfoFromJSON(json['licenseInfo']),
    };
}

export function LicenseHolderReferenceToJSON(value?: LicenseHolderReference): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'shortName': value.shortName,
        'linkable': value.linkable,
        'trackName': value.trackName,
        'trainingPlace': value.trainingPlace,
        'contactInfo': LicenseHolderContactInfoToJSON(value.contactInfo),
        'licenseInfo': LicenseHolderLicenseInfoToJSON(value.licenseInfo),
    };
}



export const pageRange = (
  page: number,
  pageCount: number,
): { start: number; end: number } => {
  let start = page - 2,
    end = page + 2;

  if (end > pageCount) {
    start -= end - pageCount;
    end = pageCount;
  }
  if (start <= 0) {
    end += (start - 1) * -1;
    start = 1;
  }

  end = end > pageCount ? pageCount : end;

  return {
    start: start,
    end: end,
  };
};

export const max = (num1: number, num2: number): number =>
  num1 > num2 ? num1 : num2;
export const min = (num1: number, num2: number): number =>
  num1 < num2 ? num1 : num2;

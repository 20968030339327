// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Mounting
 */
export interface Mounting  {
    /**
     * The race day id.
     * @type {number}
     * @memberof Mounting
     */
    raceDayId: number;
    /**
     * The license id of the driver.
     * @type {number}
     * @memberof Mounting
     */
    licenseId: number;
    /**
     * Mounting comment.
     * @type {string}
     * @memberof Mounting
     */
    comment?: string;
}

export function MountingFromJSON(json: any): Mounting {
    return {
        'raceDayId': json['raceDayId'],
        'licenseId': json['licenseId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function MountingToJSON(value?: Mounting): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'raceDayId': value.raceDayId,
        'licenseId': value.licenseId,
        'comment': value.comment,
    };
}



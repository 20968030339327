// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    BankIdUserSigning,
    BankIdUserSigningFromJSON,
    BankIdUserSigningToJSON,
    CollectedHorseSignStatus,
    CollectedHorseSignStatusFromJSON,
    CollectedHorseSignStatusToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HorseBankIdSignInitializeRequest,
    HorseBankIdSignInitializeRequestFromJSON,
    HorseBankIdSignInitializeRequestToJSON,
} from '../models';

export interface CancelHorseSigningByBankIdRequest {
    orderReference: string;
    serviceType?: CancelHorseSigningByBankIdServiceTypeEnum;
}

export interface CollectHorseSigningStatusAndQrCodeBankIdRequest {
    orderReference: string;
    qrTime: number;
    serviceType?: CollectHorseSigningStatusAndQrCodeBankIdServiceTypeEnum;
}

export interface CollectHorseSigningStatusByBankIdRequest {
    orderReference: string;
    serviceType?: CollectHorseSigningStatusByBankIdServiceTypeEnum;
}

export interface InitializeHorseSigningByBankIdRequest {
    horseId: number;
    horseBankIdSignInitializeRequest: HorseBankIdSignInitializeRequest;
    serviceType?: InitializeHorseSigningByBankIdServiceTypeEnum;
}


/**
 * Cancel an ongoing bankid signing request.
 */
function cancelHorseSigningByBankIdRaw<T>(requestParameters: CancelHorseSigningByBankIdRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.orderReference === null || requestParameters.orderReference === undefined) {
        throw new runtime.RequiredError('orderReference','Required parameter requestParameters.orderReference was null or undefined when calling cancelHorseSigningByBankId.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.serviceType !== undefined) {
        queryParameters['serviceType'] = requestParameters.serviceType;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/bankid/cancel/{orderReference}`.replace(`{${"orderReference"}}`, encodeURIComponent(String(requestParameters.orderReference))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Cancel an ongoing bankid signing request.
*/
export function cancelHorseSigningByBankId<T>(requestParameters: CancelHorseSigningByBankIdRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return cancelHorseSigningByBankIdRaw(requestParameters, requestConfig);
}

/**
 * The client needs to call this endpoint approximately 1 time per second as long as HTTP 200 is returned. When HTTP 201 is returned the update was successfully completed while any other HTTP status means the update was unsuccessful and the user should stop calling the endpoint.
 * Collecting horse signing status for a horse which was initialized with BankID.
 */
function collectHorseSigningStatusAndQrCodeBankIdRaw<T>(requestParameters: CollectHorseSigningStatusAndQrCodeBankIdRequest, requestConfig: runtime.TypedQueryConfig<T, CollectedHorseSignStatus> = {}): QueryConfig<T> {
    if (requestParameters.orderReference === null || requestParameters.orderReference === undefined) {
        throw new runtime.RequiredError('orderReference','Required parameter requestParameters.orderReference was null or undefined when calling collectHorseSigningStatusAndQrCodeBankId.');
    }

    if (requestParameters.qrTime === null || requestParameters.qrTime === undefined) {
        throw new runtime.RequiredError('qrTime','Required parameter requestParameters.qrTime was null or undefined when calling collectHorseSigningStatusAndQrCodeBankId.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.serviceType !== undefined) {
        queryParameters['serviceType'] = requestParameters.serviceType;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/bankid/collect/{orderReference}/{qrTime}`.replace(`{${"orderReference"}}`, encodeURIComponent(String(requestParameters.orderReference))).replace(`{${"qrTime"}}`, encodeURIComponent(String(requestParameters.qrTime))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CollectedHorseSignStatusFromJSON(body), text);
    }

    return config;
}

/**
* The client needs to call this endpoint approximately 1 time per second as long as HTTP 200 is returned. When HTTP 201 is returned the update was successfully completed while any other HTTP status means the update was unsuccessful and the user should stop calling the endpoint.
* Collecting horse signing status for a horse which was initialized with BankID.
*/
export function collectHorseSigningStatusAndQrCodeBankId<T>(requestParameters: CollectHorseSigningStatusAndQrCodeBankIdRequest, requestConfig?: runtime.TypedQueryConfig<T, CollectedHorseSignStatus>): QueryConfig<T> {
    return collectHorseSigningStatusAndQrCodeBankIdRaw(requestParameters, requestConfig);
}

/**
 * The client needs to call this endpoint approximately 1 time per second as long as HTTP 200 is returned. When HTTP 201 is returned the update was successfully completed while any other HTTP status means the update was unsuccessful and the user should stop calling the endpoint.
 * Collecting horse signing status for a horse which was initialized with BankID.
 */
function collectHorseSigningStatusByBankIdRaw<T>(requestParameters: CollectHorseSigningStatusByBankIdRequest, requestConfig: runtime.TypedQueryConfig<T, CollectedHorseSignStatus> = {}): QueryConfig<T> {
    if (requestParameters.orderReference === null || requestParameters.orderReference === undefined) {
        throw new runtime.RequiredError('orderReference','Required parameter requestParameters.orderReference was null or undefined when calling collectHorseSigningStatusByBankId.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.serviceType !== undefined) {
        queryParameters['serviceType'] = requestParameters.serviceType;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/bankid/collect/{orderReference}`.replace(`{${"orderReference"}}`, encodeURIComponent(String(requestParameters.orderReference))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CollectedHorseSignStatusFromJSON(body), text);
    }

    return config;
}

/**
* The client needs to call this endpoint approximately 1 time per second as long as HTTP 200 is returned. When HTTP 201 is returned the update was successfully completed while any other HTTP status means the update was unsuccessful and the user should stop calling the endpoint.
* Collecting horse signing status for a horse which was initialized with BankID.
*/
export function collectHorseSigningStatusByBankId<T>(requestParameters: CollectHorseSigningStatusByBankIdRequest, requestConfig?: runtime.TypedQueryConfig<T, CollectedHorseSignStatus>): QueryConfig<T> {
    return collectHorseSigningStatusByBankIdRaw(requestParameters, requestConfig);
}

/**
 * When 200 OK is returned the client needs to use the information in the response body to collect the pending BankID transaction.
 * Initializes signing of a horse with BankID.
 */
function initializeHorseSigningByBankIdRaw<T>(requestParameters: InitializeHorseSigningByBankIdRequest, requestConfig: runtime.TypedQueryConfig<T, BankIdUserSigning> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling initializeHorseSigningByBankId.');
    }

    if (requestParameters.horseBankIdSignInitializeRequest === null || requestParameters.horseBankIdSignInitializeRequest === undefined) {
        throw new runtime.RequiredError('horseBankIdSignInitializeRequest','Required parameter requestParameters.horseBankIdSignInitializeRequest was null or undefined when calling initializeHorseSigningByBankId.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.serviceType !== undefined) {
        queryParameters['serviceType'] = requestParameters.serviceType;
    }

    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/bankid/initialize/{horseId}`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || HorseBankIdSignInitializeRequestToJSON(requestParameters.horseBankIdSignInitializeRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(BankIdUserSigningFromJSON(body), text);
    }

    return config;
}

/**
* When 200 OK is returned the client needs to use the information in the response body to collect the pending BankID transaction.
* Initializes signing of a horse with BankID.
*/
export function initializeHorseSigningByBankId<T>(requestParameters: InitializeHorseSigningByBankIdRequest, requestConfig?: runtime.TypedQueryConfig<T, BankIdUserSigning>): QueryConfig<T> {
    return initializeHorseSigningByBankIdRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum CancelHorseSigningByBankIdServiceTypeEnum {
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS',
    LEASING = 'LEASING'
}
/**
    * @export
    * @enum {string}
    */
export enum CollectHorseSigningStatusAndQrCodeBankIdServiceTypeEnum {
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS',
    LEASING = 'LEASING'
}
/**
    * @export
    * @enum {string}
    */
export enum CollectHorseSigningStatusByBankIdServiceTypeEnum {
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS',
    LEASING = 'LEASING'
}
/**
    * @export
    * @enum {string}
    */
export enum InitializeHorseSigningByBankIdServiceTypeEnum {
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS',
    LEASING = 'LEASING'
}

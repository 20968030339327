import Alert from '@components/Alert';
import { TrashCan as TrashAlt } from '@styled-icons/fa-solid';
import { Warning } from '@styled-icons/ionicons-solid';
import React, { ReactNode } from 'react';

export interface Props {
  action?: string;
  children?: ReactNode;
  closeModal: () => void;
  error?: string;
  loading?: boolean;
  onAction: () => void | Promise<void>;
  open: boolean;
  title: string;
  description?: ReactNode;
  colorScheme: 'primary' | 'delete';
  size?: 'small' | 'medium';
  wideMobileButtons?: boolean;
  wideButtons?: boolean;
}

export default function Confirm({
  action,
  children,
  closeModal,
  error,
  loading = false,
  onAction,
  open,
  title = 'Är du säker?',
  description = '',
  colorScheme,
  size = 'small',
  wideMobileButtons = false,
  wideButtons = false,
}: Props): JSX.Element {
  const icon = colorScheme === 'delete' ? TrashAlt : Warning;
  const actionText = action
    ? action
    : colorScheme === 'delete'
      ? 'Ta bort'
      : 'Spara';

  return (
    <Alert
      action={actionText}
      closeModal={closeModal}
      colorScheme={colorScheme}
      description={description}
      error={error}
      icon={icon}
      {...(colorScheme === 'delete' && {
        iconProps: {
          size: 10,
          mb: '0px',
        },
      })}
      loading={loading}
      onAction={onAction}
      open={open}
      size={size}
      title={title}
      wideMobileButtons={wideMobileButtons}
      wideButtons={wideButtons}
    >
      {children}
    </Alert>
  );
}

import { useGetPersonByIdNumber } from '@apis';
import { Person } from '@generated/ownership-transfers/src';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import Remove from '@primitives/Button/Remove';
import { Input } from '@primitives/Form';
import { Label, Text } from '@primitives/Typography';
import React, { useState } from 'react';

import PersonError, { combineErrors } from './PersonError';

interface Props {
  onChange: (p: Person | undefined) => void;
  value?: Person;
  setMessage: (message: string) => void;
  header?: string;
  isLeasing?: boolean;
}

const SelectPerson = ({
  onChange,
  value: person,
  setMessage,
  header,
  isLeasing,
}: Props): JSX.Element => {
  const [idNumber, setIdNumber] = useState<string>('');
  const {
    loading,
    error: errorMessage,
    errors,
    fetch,
    status,
    errorCode,
  } = useGetPersonByIdNumber(onChange);

  const idNumberIsValid = /^(19|20)?\d{10}$/.test(
    idNumber.replace(/[^\d]/g, ''),
  );

  return (
    <>
      <Box verticalSpacing={2}>
        <Box
          display="flex"
          alignItems="flex-start"
          mt={4}
          horizontalSpacing={[0, , 4]}
          flexDirection={['column', , 'row']}
        >
          <Label fontSize="small" lineHeight="32px">
            Ange person-/organisationsnummer
          </Label>
          <Box>
            <Input
              type="text"
              name="idNumber"
              onChange={e => {
                const { value } = e.target;
                if (value === '' || /[\d+\s-]+/.test(value.slice(-1))) {
                  setIdNumber(value);
                }
              }}
              value={idNumber}
              width="auto"
              placeholder="NNNNNN-NNNN"
              disabled={person != null}
            />
            <Text fontSize="small" color="dim-gray" mt={1} fontStyle="italic">
              Ange med 10 siffror
            </Text>
          </Box>
          <Button
            variant="primary"
            disabled={person || !idNumberIsValid}
            loading={loading}
            onClick={async () => {
              await fetch(idNumber);
              setIdNumber('');
            }}
            width={[1, , 'auto']}
            mt={[3, , 0]}
          >
            Hämta företrädare
          </Button>
        </Box>

        <PersonError
          setMessage={setMessage}
          errors={combineErrors({
            errors,
            status,
            errorCode,
            idNumber,
            errorMessage,
          })}
          isLeasing={isLeasing}
        />
      </Box>
      {person && (
        <Box verticalSpacing={2} mt={4}>
          {header && (
            <Text fontWeight="bold" fontSize="tiny">
              {header}
            </Text>
          )}
          <Box display="flex" alignItems="center" horizontalSpacing={4}>
            <span>{person.name}</span>
            <Remove
              confirm={false}
              onClick={() => {
                onChange(undefined);
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default SelectPerson;

// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ChangePasswordCredentials,
    ChangePasswordCredentialsFromJSON,
    ChangePasswordCredentialsToJSON,
    ChangeableUserInformation,
    ChangeableUserInformationFromJSON,
    ChangeableUserInformationToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PersonalInformation,
    PersonalInformationFromJSON,
    PersonalInformationToJSON,
} from '../models';

export interface ChangePasswordRequest {
    licenseId: number;
    changePasswordCredentials: ChangePasswordCredentials;
}

export interface GetPersonalInformationRequest {
    licenseId: number;
}

export interface UpdatePersonalInformationRequest {
    licenseId: number;
    changeableUserInformation: ChangeableUserInformation;
}


/**
 * Change password for a user
 */
function changePasswordRaw<T>(requestParameters: ChangePasswordRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling changePassword.');
    }

    if (requestParameters.changePasswordCredentials === null || requestParameters.changePasswordCredentials === undefined) {
        throw new runtime.RequiredError('changePasswordCredentials','Required parameter requestParameters.changePasswordCredentials was null or undefined when calling changePassword.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/{licenseId}/changepassword`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ChangePasswordCredentialsToJSON(requestParameters.changePasswordCredentials),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Change password for a user
*/
export function changePassword<T>(requestParameters: ChangePasswordRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return changePasswordRaw(requestParameters, requestConfig);
}

/**
 * Get personal information for the given user
 */
function getPersonalInformationRaw<T>(requestParameters: GetPersonalInformationRequest, requestConfig: runtime.TypedQueryConfig<T, PersonalInformation> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getPersonalInformation.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/{licenseId}/settings/personalinformation`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(PersonalInformationFromJSON(body), text);
    }

    return config;
}

/**
* Get personal information for the given user
*/
export function getPersonalInformation<T>(requestParameters: GetPersonalInformationRequest, requestConfig?: runtime.TypedQueryConfig<T, PersonalInformation>): QueryConfig<T> {
    return getPersonalInformationRaw(requestParameters, requestConfig);
}

/**
 * Update \'changeableUserInformation\' for the given user. For BankID authenticated user the SMS-number can\'t be changed.
 */
function updatePersonalInformationRaw<T>(requestParameters: UpdatePersonalInformationRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling updatePersonalInformation.');
    }

    if (requestParameters.changeableUserInformation === null || requestParameters.changeableUserInformation === undefined) {
        throw new runtime.RequiredError('changeableUserInformation','Required parameter requestParameters.changeableUserInformation was null or undefined when calling updatePersonalInformation.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/{licenseId}/settings/personalinformation`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || ChangeableUserInformationToJSON(requestParameters.changeableUserInformation),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Update \'changeableUserInformation\' for the given user. For BankID authenticated user the SMS-number can\'t be changed.
*/
export function updatePersonalInformation<T>(requestParameters: UpdatePersonalInformationRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updatePersonalInformationRaw(requestParameters, requestConfig);
}


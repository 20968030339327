// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Distance,
    DistanceFromJSON,
    DistanceToJSON,
    Odds,
    OddsFromJSON,
    OddsToJSON,
    Placement,
    PlacementFromJSON,
    PlacementToJSON,
    PrizeMoney,
    PrizeMoneyFromJSON,
    PrizeMoneyToJSON,
    RaceType,
    RaceTypeFromJSON,
    RaceTypeToJSON,
    ResultsLicenseHolder,
    ResultsLicenseHolderFromJSON,
    ResultsLicenseHolderToJSON,
    ResultsRaceInformation,
    ResultsRaceInformationFromJSON,
    ResultsRaceInformationToJSON,
    StartPosition,
    StartPositionFromJSON,
    StartPositionToJSON,
    TrackCondition,
    TrackConditionFromJSON,
    TrackConditionToJSON,
} from './';

/**
 * 
 * @export
 * @interface HorseResult
 */
export interface HorseResult  {
    /**
     * 
     * @type {string}
     * @memberof HorseResult
     */
    trackCode: string;
    /**
     * 
     * @type {ResultsRaceInformation}
     * @memberof HorseResult
     */
    raceInformation: ResultsRaceInformation;
    /**
     * 
     * @type {RaceType}
     * @memberof HorseResult
     */
    raceType: RaceType;
    /**
     * 
     * @type {StartPosition}
     * @memberof HorseResult
     */
    startPosition: StartPosition;
    /**
     * 
     * @type {Distance}
     * @memberof HorseResult
     */
    distance: Distance;
    /**
     * 
     * @type {TrackCondition}
     * @memberof HorseResult
     */
    trackCondition: TrackCondition;
    /**
     * 
     * @type {Placement}
     * @memberof HorseResult
     */
    placement: Placement;
    /**
     * 
     * @type {Odds}
     * @memberof HorseResult
     */
    odds: Odds;
    /**
     * 
     * @type {ResultsLicenseHolder}
     * @memberof HorseResult
     */
    driver?: ResultsLicenseHolder;
    /**
     * 
     * @type {ResultsLicenseHolder}
     * @memberof HorseResult
     */
    trainer?: ResultsLicenseHolder;
    /**
     * 
     * @type {PrizeMoney}
     * @memberof HorseResult
     */
    prizeMoney: PrizeMoney;
    /**
     * 
     * @type {string}
     * @memberof HorseResult
     */
    trackSurface: string;
    /**
     * The name of the race
     * @type {string}
     * @memberof HorseResult
     */
    raceName: string;
    /**
     * 
     * @type {string}
     * @memberof HorseResult
     */
    weight?: string;
    /**
     * 
     * @type {string}
     * @memberof HorseResult
     */
    riderHandicap: string;
    /**
     * The prize money for finishing first in this race.
     * @type {number}
     * @memberof HorseResult
     */
    winnerPrizeMoney: number;
    /**
     * 
     * @type {boolean}
     * @memberof HorseResult
     */
    withdrawn?: boolean;
}

export function HorseResultFromJSON(json: any): HorseResult {
    return {
        'trackCode': json['trackCode'],
        'raceInformation': ResultsRaceInformationFromJSON(json['raceInformation']),
        'raceType': RaceTypeFromJSON(json['raceType']),
        'startPosition': StartPositionFromJSON(json['startPosition']),
        'distance': DistanceFromJSON(json['distance']),
        'trackCondition': TrackConditionFromJSON(json['trackCondition']),
        'placement': PlacementFromJSON(json['placement']),
        'odds': OddsFromJSON(json['odds']),
        'driver': !exists(json, 'driver') ? undefined : ResultsLicenseHolderFromJSON(json['driver']),
        'trainer': !exists(json, 'trainer') ? undefined : ResultsLicenseHolderFromJSON(json['trainer']),
        'prizeMoney': PrizeMoneyFromJSON(json['prizeMoney']),
        'trackSurface': json['trackSurface'],
        'raceName': json['raceName'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'riderHandicap': json['riderHandicap'],
        'winnerPrizeMoney': json['winnerPrizeMoney'],
        'withdrawn': !exists(json, 'withdrawn') ? undefined : json['withdrawn'],
    };
}

export function HorseResultToJSON(value?: HorseResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'trackCode': value.trackCode,
        'raceInformation': ResultsRaceInformationToJSON(value.raceInformation),
        'raceType': RaceTypeToJSON(value.raceType),
        'startPosition': StartPositionToJSON(value.startPosition),
        'distance': DistanceToJSON(value.distance),
        'trackCondition': TrackConditionToJSON(value.trackCondition),
        'placement': PlacementToJSON(value.placement),
        'odds': OddsToJSON(value.odds),
        'driver': ResultsLicenseHolderToJSON(value.driver),
        'trainer': ResultsLicenseHolderToJSON(value.trainer),
        'prizeMoney': PrizeMoneyToJSON(value.prizeMoney),
        'trackSurface': value.trackSurface,
        'raceName': value.raceName,
        'weight': value.weight,
        'riderHandicap': value.riderHandicap,
        'winnerPrizeMoney': value.winnerPrizeMoney,
        'withdrawn': value.withdrawn,
    };
}



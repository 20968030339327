import { useGetPersonalInformation } from '@apis';
import InfoTable from '@components/InfoTable';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import { H3 } from '@primitives/Typography';
import { Text } from '@primitives/Typography';
import React from 'react';

interface Props {
  licenseId: number;
}

export default function Info({ licenseId }: Props): JSX.Element {
  const { data, loading, error, status } = useGetPersonalInformation(licenseId);

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <>
      <H3 mb={4}>TDS-uppgifter</H3>
      {data?.tdsInformation ? (
        <InfoTable
          forceColumns={2}
          values={[
            {
              label: 'Leverantör',
              value: data.tdsInformation?.supplierName,
            },
            {
              label: 'Leverantörs-id',
              value: data.tdsInformation.supplierId,
            },
            {
              label: 'Organisationsnummer',
              value: data.tdsInformation?.organisationNumber,
            },
            {
              label: 'Kontaktperson',
              value:
                data.tdsInformation?.contactNumber &&
                data.tdsInformation.contactNumber.length > 0
                  ? data.tdsInformation.contactNumber.join(' ')
                  : null,
            },
          ]}
        />
      ) : (
        <Text>Fann inga TDS-uppgifter.</Text>
      )}
    </>
  );
}

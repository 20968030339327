// tslint:disable
/**
 * Web API Charts Service
 * Service for getting info about the different charts.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface ChartEntry
 */
export interface ChartEntry  {
    /**
     * Name
     * @type {string}
     * @memberof ChartEntry
     */
    name?: string;
    /**
     * The identity of the entry
     * @type {number}
     * @memberof ChartEntry
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ChartEntry
     */
    linkable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ChartEntry
     */
    numberOfStarts?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartEntry
     */
    numberOfFirstPlaces?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartEntry
     */
    numberOfSecondPlaces?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartEntry
     */
    numberOfThirdPlaces?: number;
    /**
     * Prize money in SEK
     * @type {number}
     * @memberof ChartEntry
     */
    prizeMoney?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartEntry
     */
    bonus?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartEntry
     */
    totalPrizeMoney?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartEntry
     */
    winningPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartEntry
     */
    placementPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof ChartEntry
     */
    age?: string;
    /**
     * 
     * @type {number}
     * @memberof ChartEntry
     */
    yearOfBirth?: number;
    /**
     * 
     * @type {string}
     * @memberof ChartEntry
     */
    gender?: string;
    /**
     * 
     * @type {number}
     * @memberof ChartEntry
     */
    offspringStarts?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartEntry
     */
    offspringWins?: number;
    /**
     * 
     * @type {number}
     * @memberof ChartEntry
     */
    handicap?: number;
    /**
     * 
     * @type {string}
     * @memberof ChartEntry
     */
    typeOfLicense?: string;
}

export function ChartEntryFromJSON(json: any): ChartEntry {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'linkable': !exists(json, 'linkable') ? undefined : json['linkable'],
        'numberOfStarts': !exists(json, 'numberOfStarts') ? undefined : json['numberOfStarts'],
        'numberOfFirstPlaces': !exists(json, 'numberOfFirstPlaces') ? undefined : json['numberOfFirstPlaces'],
        'numberOfSecondPlaces': !exists(json, 'numberOfSecondPlaces') ? undefined : json['numberOfSecondPlaces'],
        'numberOfThirdPlaces': !exists(json, 'numberOfThirdPlaces') ? undefined : json['numberOfThirdPlaces'],
        'prizeMoney': !exists(json, 'prizeMoney') ? undefined : json['prizeMoney'],
        'bonus': !exists(json, 'bonus') ? undefined : json['bonus'],
        'totalPrizeMoney': !exists(json, 'totalPrizeMoney') ? undefined : json['totalPrizeMoney'],
        'winningPercent': !exists(json, 'winningPercent') ? undefined : json['winningPercent'],
        'placementPercent': !exists(json, 'placementPercent') ? undefined : json['placementPercent'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'yearOfBirth': !exists(json, 'yearOfBirth') ? undefined : json['yearOfBirth'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'offspringStarts': !exists(json, 'offspringStarts') ? undefined : json['offspringStarts'],
        'offspringWins': !exists(json, 'offspringWins') ? undefined : json['offspringWins'],
        'handicap': !exists(json, 'handicap') ? undefined : json['handicap'],
        'typeOfLicense': !exists(json, 'typeOfLicense') ? undefined : json['typeOfLicense'],
    };
}

export function ChartEntryToJSON(value?: ChartEntry): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'id': value.id,
        'linkable': value.linkable,
        'numberOfStarts': value.numberOfStarts,
        'numberOfFirstPlaces': value.numberOfFirstPlaces,
        'numberOfSecondPlaces': value.numberOfSecondPlaces,
        'numberOfThirdPlaces': value.numberOfThirdPlaces,
        'prizeMoney': value.prizeMoney,
        'bonus': value.bonus,
        'totalPrizeMoney': value.totalPrizeMoney,
        'winningPercent': value.winningPercent,
        'placementPercent': value.placementPercent,
        'age': value.age,
        'yearOfBirth': value.yearOfBirth,
        'gender': value.gender,
        'offspringStarts': value.offspringStarts,
        'offspringWins': value.offspringWins,
        'handicap': value.handicap,
        'typeOfLicense': value.typeOfLicense,
    };
}



import { Marker } from '@components/Menu';
import config from '@config';
import useIsMobile from '@hooks/useIsMobile';
import useIsTablet from '@hooks/useIsTablet';
import theme from '@main/theme';
import { base, BaseProps } from '@primitives/base';
import { Flex } from '@primitives/Box';
import Icon from '@primitives/Icon';
import { Text } from '@primitives/Typography';
import {
  Bars,
  UpRightFromSquare as ExternalLinkAlt,
  Xmark as Close,
} from '@styled-icons/fa-solid';
import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';

import SearchItem from './SearchItem';
import UserItem from './UserItem';

interface Props {
  setSidebarOpen?: (sidebarOpen: boolean) => void;
  sidebarOpen?: boolean;
  setHoverItem?: (item: string | undefined) => void;
  hoverItem?: string;
}

interface LinkItemProps {
  setHoverItem?: (item: string | undefined) => void;
  hoverItem?: string;
}

const Wrapper = styled(Flex)`
  svg {
    transition: color 0.2s;
  }
`;

const ExtLink = styled.a<BaseProps>`
  ${base()}
  &,
  &:active,
  &:visited,
  &:focus {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  display: flex;
  flex-direction: row;
`;

function ExternalLinkItem({
  setHoverItem,
  hoverItem,
}: LinkItemProps): React.ReactElement {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return (
    <Wrapper
      height="100%"
      display="flex"
      flexDirection="row"
      alignItems="center"
      onMouseEnter={() => setHoverItem && setHoverItem('externalLink')}
      onMouseLeave={() => setHoverItem && setHoverItem(undefined)}
    >
      <ExtLink
        color={
          hoverItem === 'externalLink' || hoverItem === undefined
            ? 'white'
            : transparentize(0.4, 'white')
        }
        href={config.publicWebUrl}
      >
        {!isMobile && !isTablet ? <Text>svenskgalopp.se</Text> : null}
        <Icon ml={2} size={16} as={ExternalLinkAlt} />
      </ExtLink>
    </Wrapper>
  );
}

export default function RightMenu({
  setSidebarOpen,
  sidebarOpen,
  setHoverItem,
  hoverItem,
}: Props): React.ReactElement {
  return (
    <Wrapper
      height="pageHeader"
      flexGrow={1}
      pl={5}
      ml="-2px"
      pr={[3, null, 4]}
      justifyContent="flex-end"
      bg={theme.colors['deep-blue']}
      flexWrap="nowrap"
    >
      {setSidebarOpen ? (
        <>
          <UserItem setHoverItem={setHoverItem} hoverItem={hoverItem} />
          <SearchItem setHoverItem={setHoverItem} hoverItem={hoverItem} />
          <Flex
            onClick={() => setSidebarOpen(!sidebarOpen)}
            mr={2}
            as="button"
            height="100%"
            display="flex"
            position="relative"
          >
            {sidebarOpen ? (
              <>
                <Marker borderBottomColor="gray1" />
                <Icon size={32} margin="-4px" color="white" as={Close} />
              </>
            ) : (
              <Icon size={24} color="white" as={Bars} />
            )}
          </Flex>
        </>
      ) : (
        <>
          <UserItem setHoverItem={setHoverItem} hoverItem={hoverItem} />
          <SearchItem setHoverItem={setHoverItem} hoverItem={hoverItem} />
          <ExternalLinkItem setHoverItem={setHoverItem} hoverItem={hoverItem} />
        </>
      )}
    </Wrapper>
  );
}

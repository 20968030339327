import Content from '@components/ContentBlock/Content';
import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import theme from '@main/theme';
import Box from '@primitives/Box';
import React from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import ListIssue from './list';

interface Props {
  user: AuthenticatedUser;
}

function Issues({ user }: Props): JSX.Element {
  const { url, path } = useRouteMatch();
  const { search } = useLocation();
  const isNew = search === '?new=true';

  return (
    <PageLayout
      headline="Ärenden"
      singleCard
      tabs={[
        { text: 'Pågående ärenden', to: `${url}`, exact: true },
        { text: 'Avslutade ärenden', to: `${url}/avslutade` },
      ]}
    >
      <Switch>
        <Route path={`${path}/avslutade`} exact>
          <Content slug="arenden-avslutade-arenden" />
          <br />
          <ListIssue user={user} closed />
        </Route>
        <Route path={`${path}`}>
          {isNew && (
            <Box
              color={theme.colors.green}
              backgroundColor={theme.colors['light-green']}
              padding={'10px'}
              marginBottom={'10px'}
            >
              Ärendet har skickats till Svensk Galopp för handläggning.
            </Box>
          )}
          <Content slug="arenden-pagaende-arenden" />
          <br />
          <ListIssue user={user} />
        </Route>
      </Switch>
    </PageLayout>
  );
}

export default withUser(Issues, { onlyAuthenticated: false });

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop(): null {
  const { pathname } = useLocation();

  const [prevPath, setPrevPath] = useState(null);

  const skippedPaths = ['/sportinfo/', '/tavling/'];
  const regexes = [/^\/sportinfo\/[a-z]+\/\d+/, /^\/tavling\/[a-z]+\/\d+/];

  useEffect(() => {
    let skip = false;

    if (prevPath && skippedPaths.some(path => pathname.startsWith(path))) {
      if (
        regexes.some(regex => {
          const curr = pathname.match(regex);
          const prev = prevPath.match(regex);
          return curr && prev && curr[0] === prev[0];
        })
      ) {
        skip = true;
      }
    }

    if (!skip) {
      window.scrollTo(0, 0);
    }

    setPrevPath(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
}

import DataGrid from '@components/DataGrid';
import { ComingStart } from '@generated/sportactors/src';
import { HorseIcon } from '@primitives/Icon';
import { horse, licenseholder } from '@utils/links';
import { startOfToday } from 'date-fns/esm';
import React from 'react';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';

function isLinkable(item: ComingStart): boolean {
  const { track, raceInformation: info } = item;

  return (
    info.date &&
    (info.linkable || (info.date > startOfToday() && track.code !== 'öv'))
  );
}

const columns: Column<ComingStart>[] = [
  {
    Header: 'Datum/lopp',
    accessor: v => v.raceInformation.date,
    Cell: ({
      row: { original: item },
    }: CellProps<ComingStart, ComingStart['raceInformation']>) => (
      <>
        {isLinkable(item) ? (
          <Link
            to={`/tavling/startlistor/${item.raceInformation.raceDayId}/${item.raceInformation.raceNumber}`}
          >
            {item.raceInformation.displayDate}
          </Link>
        ) : (
          item.raceInformation?.displayDate || null
        )}
      </>
    ),
  },
  {
    Header: 'Bana',
    accessor: row => row.track.name,
  },
  {
    Header: 'Lopptyp',
    accessor: 'raceType',
  },
  {
    Header: 'Häst',
    accessor: 'horse',
    Cell: ({ value }: CellProps<ComingStart, ComingStart['horse']>) => (
      <Link to={horse(value.id, '/resultat')}>{value.name}</Link>
    ),
  },
  {
    Header: 'Ryttare',
    accessor: 'driver',
    Cell: ({ value }: CellProps<ComingStart, ComingStart['driver']>) => (
      <>
        {value.linkable ? (
          <Link to={licenseholder(value.id, '/ryttarekommande')}>
            {value.name}
          </Link>
        ) : (
          value.name || null
        )}
      </>
    ),
  },
  {
    Header: 'Tränare',
    accessor: 'trainer',
    Cell: ({ value }: CellProps<ComingStart, ComingStart['trainer']>) => (
      <>
        {value.linkable ? (
          <Link to={licenseholder(value.id, '/tranarekommande')}>
            {value.name}
          </Link>
        ) : (
          value.name || null
        )}
      </>
    ),
  },
];

interface Props {
  data: ComingStart[];
  sportinfoView?: boolean;
}

export default function ComingStartsGeneric({
  data,
  sportinfoView = false,
}: Props): JSX.Element {
  return (
    <DataGrid<ComingStart>
      columns={columns}
      data={data}
      title={
        sportinfoView
          ? `${data.length} hästar anmälda till lopp`
          : `Du har ${data.length} hästar anmälda till lopp`
      }
      noContent={{
        content: sportinfoView
          ? 'Inga hästar anmälda till lopp.'
          : 'Du har inga hästar anmälda till lopp.',
        icon: HorseIcon,
      }}
    />
  );
}

import ChangePassword from './ChangePassword';
import Personal from './Personal';

const routes = [
  {
    label: 'Kontaktuppgifter',
    path: '',
    component: Personal,
  },
  {
    label: 'Ändra lösenord',
    path: 'losen',
    component: ChangePassword,
  },
] as const;

export default routes;

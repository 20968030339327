// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Horse
 */
export interface Horse  {
    /**
     * 
     * @type {number}
     * @memberof Horse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Horse
     */
    name: string;
}

export function HorseFromJSON(json: any): Horse {
    return {
        'id': json['id'],
        'name': json['name'],
    };
}

export function HorseToJSON(value?: Horse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
    };
}



import Menu from '@components/Menu';
import Box from '@primitives/Box';
import React from 'react';
import styled from 'styled-components';

import { MenuItem, ShortcutMenuSection } from '../menus';
import Shortcuts from './Shortcuts';

const wrapperWidth = 440;
const positionLeft = -wrapperWidth / 10;

interface Props {
  subMenu?: MenuItem[];
  shortcutMenu?: ShortcutMenuSection[];
  hideMenu: () => void;
}

const InnerWrapper = styled(Box)`
  position: relative;
  display: flex;
  top: ${props => props.theme.sizes.pageHeader}px;
  left: ${positionLeft + 14}px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 2px 4px #00000029;
`;

export default function HoverMenu({
  subMenu,
  shortcutMenu,
  hideMenu,
}: Props): React.ReactElement {
  return (
    <Box position="absolute" width={0} height={0} sx={{ zIndex: 1000 }}>
      <InnerWrapper
        p={2}
        pt="10px"
        width={shortcutMenu ? wrapperWidth : wrapperWidth / 2}
      >
        <Box
          width="100%"
          maxWidth={`${wrapperWidth / 2 - 16}px`}
          height="100%"
          mr={shortcutMenu ? 3 : 0}
        >
          <Menu
            eventTrackingTag="secondary-menu"
            items={subMenu.map(item => ({
              to: item.href,
              text: item.label,
              onClick: hideMenu,
            }))}
            thin
          />
        </Box>
        {shortcutMenu && <Shortcuts menu={shortcutMenu} hideMenu={hideMenu} />}
      </InnerWrapper>
    </Box>
  );
}

// tslint:disable
/**
 * Web API Account Service
 * Service for perform account operations, such as creating accounts or recovering accounts.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface GetNewPasswordRequest {
    username: string;
    personalnumber: string;
}


/**
 * Creates a new password and sends it as text message to the user\'s phone number
 */
function getNewPasswordRaw<T>(requestParameters: GetNewPasswordRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
        throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling getNewPassword.');
    }

    if (requestParameters.personalnumber === null || requestParameters.personalnumber === undefined) {
        throw new runtime.RequiredError('personalnumber','Required parameter requestParameters.personalnumber was null or undefined when calling getNewPassword.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.username !== undefined) {
        queryParameters['username'] = requestParameters.username;
    }


    if (requestParameters.personalnumber !== undefined) {
        queryParameters['personalnumber'] = requestParameters.personalnumber;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/accounts/credentials/newpassword`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Creates a new password and sends it as text message to the user\'s phone number
*/
export function getNewPassword<T>(requestParameters: GetNewPasswordRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return getNewPasswordRaw(requestParameters, requestConfig);
}


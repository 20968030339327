// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    CoveringOutcome,
    CoveringOutcomeFromJSON,
    CoveringOutcomeToJSON,
    Mare,
    MareFromJSON,
    MareToJSON,
} from './';

/**
 * 
 * @export
 * @interface CoveringResult
 */
export interface CoveringResult  {
    /**
     * 
     * @type {number}
     * @memberof CoveringResult
     */
    id: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CoveringResult
     */
    coveringOccasions?: Array<string>;
    /**
     * 
     * @type {Mare}
     * @memberof CoveringResult
     */
    mare: Mare;
    /**
     * 
     * @type {CoveringOutcome}
     * @memberof CoveringResult
     */
    outcome?: CoveringOutcome;
    /**
     * 
     * @type {Date}
     * @memberof CoveringResult
     */
    birthDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CoveringResult
     */
    birthCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CoveringResult
     */
    gender?: CoveringResultGenderEnum;
    /**
     * True when a registration is already started in the Sport system, then no further external modifications are allowed
     * @type {boolean}
     * @memberof CoveringResult
     */
    registrationStarted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CoveringResult
     */
    lastUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof CoveringResult
     */
    color?: string;
}

export function CoveringResultFromJSON(json: any): CoveringResult {
    return {
        'id': json['id'],
        'coveringOccasions': !exists(json, 'coveringOccasions') ? undefined : json['coveringOccasions'],
        'mare': MareFromJSON(json['mare']),
        'outcome': !exists(json, 'outcome') ? undefined : CoveringOutcomeFromJSON(json['outcome']),
        'birthDate': !exists(json, 'birthDate') ? undefined : parseDateTime(json['birthDate']),
        'birthCountryCode': !exists(json, 'birthCountryCode') ? undefined : json['birthCountryCode'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'registrationStarted': !exists(json, 'registrationStarted') ? undefined : json['registrationStarted'],
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : parseDateTime(json['lastUpdated']),
        'color': !exists(json, 'color') ? undefined : json['color'],
    };
}

export function CoveringResultToJSON(value?: CoveringResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'coveringOccasions': value.coveringOccasions,
        'mare': MareToJSON(value.mare),
        'outcome': CoveringOutcomeToJSON(value.outcome),
        'birthDate': value.birthDate === undefined ? undefined : value.birthDate.toLocalDate(),
        'birthCountryCode': value.birthCountryCode,
        'gender': value.gender,
        'registrationStarted': value.registrationStarted,
        'lastUpdated': value.lastUpdated === undefined ? undefined : value.lastUpdated.toLocalDate(),
        'color': value.color,
    };
}

/**
* @export
* @enum {string}
*/
export enum CoveringResultGenderEnum {
    STALLION = 'STALLION',
    MARE = 'MARE'
}



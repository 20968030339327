// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewsResource,
    NewsResourceFromJSON,
    NewsResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200  {
    /**
     * 
     * @type {Array<NewsResource>}
     * @memberof InlineResponse200
     */
    items?: Array<NewsResource>;
}

export function InlineResponse200FromJSON(json: any): InlineResponse200 {
    return {
        'items': !exists(json, 'items') ? undefined : (json['items'] as Array<any>).map(NewsResourceFromJSON),
    };
}

export function InlineResponse200ToJSON(value?: InlineResponse200): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'items': value.items === undefined ? undefined : (value.items as Array<any>).map(NewsResourceToJSON),
    };
}



// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    CoveringResult,
    CoveringResultFromJSON,
    CoveringResultToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface GetCoveringResultRequest {
    year: number;
    studId: number;
}

export interface SaveCoveringResultRequest {
    studId: number;
    year: number;
    coveringResult: CoveringResult;
}


/**
 * Get covering list result for a specified stud.
 */
function getCoveringResultRaw<T>(requestParameters: GetCoveringResultRequest, requestConfig: runtime.TypedQueryConfig<T, Array<CoveringResult>> = {}): QueryConfig<T> {
    if (requestParameters.year === null || requestParameters.year === undefined) {
        throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getCoveringResult.');
    }

    if (requestParameters.studId === null || requestParameters.studId === undefined) {
        throw new runtime.RequiredError('studId','Required parameter requestParameters.studId was null or undefined when calling getCoveringResult.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlistresults/{studId}/{year}`.replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))).replace(`{${"studId"}}`, encodeURIComponent(String(requestParameters.studId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(CoveringResultFromJSON), text);
    }

    return config;
}

/**
* Get covering list result for a specified stud.
*/
export function getCoveringResult<T>(requestParameters: GetCoveringResultRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<CoveringResult>>): QueryConfig<T> {
    return getCoveringResultRaw(requestParameters, requestConfig);
}

/**
 * Save the covering result for a covering.
 */
function saveCoveringResultRaw<T>(requestParameters: SaveCoveringResultRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.studId === null || requestParameters.studId === undefined) {
        throw new runtime.RequiredError('studId','Required parameter requestParameters.studId was null or undefined when calling saveCoveringResult.');
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
        throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling saveCoveringResult.');
    }

    if (requestParameters.coveringResult === null || requestParameters.coveringResult === undefined) {
        throw new runtime.RequiredError('coveringResult','Required parameter requestParameters.coveringResult was null or undefined when calling saveCoveringResult.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlistresults/{studId}/{year}`.replace(`{${"studId"}}`, encodeURIComponent(String(requestParameters.studId))).replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || CoveringResultToJSON(requestParameters.coveringResult),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Save the covering result for a covering.
*/
export function saveCoveringResult<T>(requestParameters: SaveCoveringResultRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return saveCoveringResultRaw(requestParameters, requestConfig);
}


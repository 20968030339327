// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * License holders with ROLE = TEVLA should have a doctors certificate.
 * @export
 * @interface DoctorsCertificate
 */
export interface DoctorsCertificate  {
    /**
     * Doctor certificate valid to date, can be null.
     * @type {Date}
     * @memberof DoctorsCertificate
     */
    validToDate?: Date;
    /**
     * True if the doctor certificate is valid
     * @type {boolean}
     * @memberof DoctorsCertificate
     */
    valid?: boolean;
}

export function DoctorsCertificateFromJSON(json: any): DoctorsCertificate {
    return {
        'validToDate': !exists(json, 'validToDate') ? undefined : parseDateTime(json['validToDate']),
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
    };
}

export function DoctorsCertificateToJSON(value?: DoctorsCertificate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'validToDate': value.validToDate === undefined ? undefined : value.validToDate.toLocalDate(),
        'valid': value.valid,
    };
}



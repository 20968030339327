import { useGetOwnershipResults } from '@apis';
import ResultsTable from '@features/SportInfo/utils/Results';
import { AuthenticatedUser } from '@generated/account/src';
import Error from '@primitives/Error';
import React from 'react';

interface Props {
  user: AuthenticatedUser;
}

export default function Results({ user }: Props): JSX.Element {
  const { data, loading, status, error } = useGetOwnershipResults(
    user.licenseId,
  );

  if (loading) {
    return null;
  }

  if (status && status !== 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  return <ResultsTable data={data || []} type="sportActor" />;
}

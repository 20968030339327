import { Driver } from '@generated/propositions/src';
import { Input } from '@primitives/Form';
import { useFormikContext } from 'formik';
import React, { useCallback, useRef } from 'react';
import { CellProps } from 'react-table';

import { FormState } from '..';

const UpdateDriverWeight = ({
  row: {
    original: { id: driverId, updatedWeight },
  },
}: CellProps<Driver>): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<FormState>();
  const inputRef = useRef(null);

  const updateWeight = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;

      let newValue = value.slice(0, 4);

      if (+value >= 100) {
        newValue = (+value / 10).toString();
      }

      const newDrivers = values.drivers.map(driver =>
        driver.id == driverId
          ? {
              ...driver,
              updatedWeight: Number(newValue),
            }
          : driver,
      );

      setFieldValue('drivers', newDrivers);
    },
    [setFieldValue, values.drivers, driverId],
  );

  const onWheel = (): void => {
    inputRef.current.blur();
  };

  return (
    <Input
      value={updatedWeight || ''}
      onChange={updateWeight}
      maxWidth="80px"
      type="number"
      step="0.5"
      ref={inputRef}
      onWheel={onWheel}
    />
  );
};

export default UpdateDriverWeight;

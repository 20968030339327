import { Entities } from '@apis';
import {
  Covering,
  deleteCovering,
  MissingMareToJSON,
  registerMissingMare,
  RegisterMissingMareRequest,
  saveCovering,
  saveCoveringResult,
  SaveCoveringResultRequest,
  updateCovering,
} from '@generated/breedings/src';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../utils/types';
import { useApiMutateWithoutData } from '../utils/useApiMutate';

export const useUpdateCovering = (
  studId: number,
): MutationResponseWithoutData<Entities, Covering> =>
  useApiMutateWithoutData({
    queryFunc: (covering: Covering): QueryConfig<Entities> =>
      updateCovering<Entities>({ studId, covering }),

    queryKey: 'updateCovering',
  });

export const useSaveCovering = (
  studId: number,
): MutationResponseWithoutData<Entities, Covering> =>
  useApiMutateWithoutData({
    queryFunc: (covering: Covering): QueryConfig<Entities> =>
      saveCovering<Entities>({
        studId,
        covering,
      }),
    queryKey: 'saveCovering',
  });

export const useDeleteCovering = (
  studId: number,
): MutationResponseWithoutData<Entities, number> =>
  useApiMutateWithoutData({
    queryFunc: (coveringId: number): QueryConfig<Entities> =>
      deleteCovering<Entities>(
        {
          studId,
          coveringId,
        },
        {
          optimisticUpdate: {
            getCoveringList: prev => ({
              ...prev,
              [studId]: prev[studId].filter(item => item.id !== coveringId),
            }),
          },
        },
      ),
    queryKey: 'deleteCovering',
  });

export const useRegisterMissingMare = (): MutationResponseWithoutData<
  Entities,
  RegisterMissingMareRequest
> =>
  useApiMutateWithoutData({
    queryFunc: (args: RegisterMissingMareRequest): QueryConfig<Entities> => {
      const base = registerMissingMare<Entities>(args);
      return {
        ...base,
        body: MissingMareToJSON(args.missingMare),
        url: `${base.url}?ccuser=${args.ccuser.toString()}`,
      };
    },
    queryKey: 'registerMissingMare',
  });

export const useSaveCoveringResult = (): MutationResponseWithoutData<
  Entities,
  SaveCoveringResultRequest
> =>
  useApiMutateWithoutData({
    queryFunc: (args: SaveCoveringResultRequest): QueryConfig<Entities> =>
      saveCoveringResult<Entities>(args),
    queryKey: 'saveCoveringResult',
  });

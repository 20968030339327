import {
  useDeleteTransaction,
  useInsertTransactions,
  useUpdateTransaction,
} from '@apis';
import FormLayout, { FormError, FormItem } from '@components/FormLayout';
import { DriverCostProducts } from '@generated/tds/src';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import List, { LI } from '@primitives/List';
import scrollToTop from '@utils/scrollToTop';
import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Products from './Products';
//import FormState from './utils/interface/FormState';
import validationSchema from './utils/validationSchema';

type FormState = {
  all?: string;
  items: DriverCostProducts[];
};
interface Props {
  costsPath: string;
  licenseId: number;
  raceDayId: number;
  products: DriverCostProducts[];
  setActionError: React.Dispatch<React.SetStateAction<string[]>>;
}

const CreateTransactions = ({
  costsPath,
  licenseId,
  products,
  setActionError,
}: Props): JSX.Element => {
  const { action: create } = useInsertTransactions(licenseId);
  const { action: update } = useUpdateTransaction(licenseId);
  const { action: remove } = useDeleteTransaction(licenseId);

  const history = useHistory();

  const hasAnySavedTransaction = products.some(product =>
    product.productInformations.some(
      cost => cost.registeredTransaction != null,
    ),
  );

  const initialValues: FormState = useMemo(
    () => ({
      items: [
        ...products.map(product => ({
          ...product,
          productInformations: [
            ...product.productInformations.map(info => ({
              ...info,
              amount: info.registeredTransaction
                ? info.registeredTransaction.amount
                : hasAnySavedTransaction
                  ? 0
                  : info.amount,
              text: info.registeredTransaction
                ? info.registeredTransaction.text
                : info.text,
            })),
          ],
        })),
      ],
    }),
    [hasAnySavedTransaction, products],
  );

  return (
    <Box mt={5}>
      <Formik<FormState>
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setActionError(undefined);

          const transactions = values.items.reduce(
            (acc, horse) => [
              ...acc,
              ...horse.productInformations
                //.filter(cost => cost.registeredTransaction == null)
                .map(cost => ({
                  licenseId: cost.licenseId,
                  transactionDate: horse.start.raceDate,
                  productCode: cost.product.productCode,
                  amount: +cost.amount,
                  representativeId: horse.start.representativeId,
                  ownershipId: horse.start.ownershipId,
                  horseId: horse.start.horseId,
                  trackId: horse.start.trackId,
                  raceId: horse.start.raceId,
                  startId: horse.start.id,
                  raceDayId: horse.start.raceDayId,
                  registeredTransaction: cost?.registeredTransaction,
                  text: cost.text,
                  // todo
                  lastUpdate: new Date(),
                })),
            ],
            [],
          );

          const toCreate = transactions.filter(
            cost => cost.registeredTransaction == null && +cost.amount > 0,
          );

          const toUpdateOrDelete = transactions
            .filter(cost => cost.registeredTransaction != null)
            .map(transaction => ({
              ...transaction.registeredTransaction,
              amount: transaction.amount,
              text: transaction.text,
            }));

          const toUpdate = toUpdateOrDelete.filter(cost => +cost.amount > 0);
          const toDelete = toUpdateOrDelete.filter(cost => !cost.amount);

          let success = true;
          const errors = [];

          if (toCreate.length > 0) {
            const { body, status } = await create(toCreate);

            if (status === 200) {
              success = false;

              body.forEach(item => {
                errors.push(
                  `Det gick inte att spara kostnad: ${item.text}, ${item.amount}`,
                );
              });
              scrollToTop();
            } else if (status !== 201 && status !== 204) {
              success = false;
              errors.push(body?.errorMessage);
            }
          }

          if (toUpdate.length > 0) {
            const resp = await Promise.all(
              toUpdate.map(transaction =>
                update({
                  transaction,
                  id: transaction.id,
                }),
              ),
            );

            resp.forEach(({ body, status }, i) => {
              if (status >= 400) {
                errors.push(
                  `Det gick inte att uppdatera kostnad: ${toUpdate[i].text}, ${toUpdate[i].amount}`,
                );
                errors.push(body?.errorMessage);
                success = false;
              }
            });
          }

          if (toDelete.length > 0) {
            const resp = await Promise.all(
              toDelete.map(transaction => remove(transaction.id)),
            );

            resp.forEach(({ body, status }, i) => {
              if (status >= 400) {
                errors.push(
                  `Det gick inte att radera kostnad: ${toDelete[i].text}, ${toDelete[i].amount}`,
                );
                errors.push(body?.errorMessage);
                success = false;
              }
            });
          }

          if (success) {
            resetForm({ values });
            history.push(costsPath);
          } else {
            setActionError(errors);
          }

          setSubmitting(false);
        }}
      >
        {({ values, errors, isSubmitting }) => (
          <FormLayout variant="vertical">
            <FormItem>
              <List>
                {values.items.map((horse, i) => (
                  <LI key={horse.start.horseId}>
                    <Products
                      disabled={isSubmitting}
                      horse={horse}
                      mt={i === 0 ? 0 : 4}
                      index={i}
                    />
                  </LI>
                ))}
              </List>
            </FormItem>
            <FormItem data-no-grow display="block" width={[1, null, 'auto']}>
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Spara
              </Button>
            </FormItem>
            {errors.all && <FormError>{errors.all}</FormError>}
          </FormLayout>
        )}
      </Formik>
    </Box>
  );
};

export default CreateTransactions;

import {
  useGetDriverCostTransactionProducts,
  useGetLicenseHolder,
} from '@apis';
import { LicenseHolderBasicInformation } from '@generated/licenseholders/src';
import useNumericParams from '@hooks/useNumericParams';
import Error from '@primitives/Error';
import { BackLink } from '@primitives/Link';
import { SimpleList } from '@primitives/List';
import Loading from '@primitives/Loading';
import Message from '@primitives/Message';
import { Text } from '@primitives/Typography';
import React, { useMemo, useState } from 'react';

import CreateTransactions from './CreateTransactions';
import Starts from './Starts';

interface Props {
  costsPath: string;
  licenseId: number;
}

interface DetailsProps extends Props {
  info: LicenseHolderBasicInformation;
}

const withBasicInformation =
  (Base: React.FC<DetailsProps>): React.FC<Props> =>
  (props: Props) => {
    const { data } = useGetLicenseHolder(props.licenseId);
    if (!data) {
      return null;
    }
    return <Base info={data} {...props} />;
  };

interface DetailsProps extends Props {
  info: LicenseHolderBasicInformation;
}

function Details({ costsPath, licenseId, info }: DetailsProps): JSX.Element {
  const { raceDayId } = useNumericParams();
  const [actionError, setActionError] = useState<string[]>();

  const { data, loading, error, status } = useGetDriverCostTransactionProducts(
    licenseId,
    raceDayId,
    info.licenseInfo.category, // Utländsk = U
  );

  const starts = useMemo(
    () => (data ? data.map(item => item.start) : []),
    [data],
  );

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <>
      <BackLink to={costsPath} mb={4}>
        Tillbaka
      </BackLink>
      {!starts.length && <Text>Fann inga starter.</Text>}
      {starts.length > 0 && (
        <>
          {actionError && (
            <Message variant="errorBox" mb={4}>
              <SimpleList items={actionError} />
            </Message>
          )}
          <Starts starts={starts} />
          <CreateTransactions
            costsPath={costsPath}
            licenseId={licenseId}
            products={data}
            raceDayId={raceDayId}
            setActionError={setActionError}
          />
        </>
      )}
    </>
  );
}

export default withBasicInformation(Details);

// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Shows the number of persons who have signed and total signatories in a Contract. This field will only exist if Contract has been created for the Ownership Transfer.
 * @export
 * @interface Signatures
 */
export interface Signatures  {
    /**
     * 
     * @type {number}
     * @memberof Signatures
     */
    signedPersonsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Signatures
     */
    totalSignatories?: number;
}

export function SignaturesFromJSON(json: any): Signatures {
    return {
        'signedPersonsCount': !exists(json, 'signedPersonsCount') ? undefined : json['signedPersonsCount'],
        'totalSignatories': !exists(json, 'totalSignatories') ? undefined : json['totalSignatories'],
    };
}

export function SignaturesToJSON(value?: Signatures): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'signedPersonsCount': value.signedPersonsCount,
        'totalSignatories': value.totalSignatories,
    };
}



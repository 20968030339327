// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    BucketLink,
    BucketLinkFromJSON,
    BucketLinkToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    TdsListCategory,
    TdsListCategoryFromJSON,
    TdsListCategoryToJSON,
} from '../models';

export interface GetTdsListLinkRequest {
    licenseId: number;
    documentId: number;
}

export interface GetTdsListsRequest {
    licenseId: number;
}


/**
 * Generates url to a tds list.
 */
function getTdsListLinkRaw<T>(requestParameters: GetTdsListLinkRequest, requestConfig: runtime.TypedQueryConfig<T, BucketLink> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getTdsListLink.');
    }

    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
        throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getTdsListLink.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/lists/{documentId}/url`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(BucketLinkFromJSON(body), text);
    }

    return config;
}

/**
* Generates url to a tds list.
*/
export function getTdsListLink<T>(requestParameters: GetTdsListLinkRequest, requestConfig?: runtime.TypedQueryConfig<T, BucketLink>): QueryConfig<T> {
    return getTdsListLinkRaw(requestParameters, requestConfig);
}

/**
 * Get the TDS-lists of the provided licenseholder
 */
function getTdsListsRaw<T>(requestParameters: GetTdsListsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<TdsListCategory>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getTdsLists.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/lists`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(TdsListCategoryFromJSON), text);
    }

    return config;
}

/**
* Get the TDS-lists of the provided licenseholder
*/
export function getTdsLists<T>(requestParameters: GetTdsListsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<TdsListCategory>>): QueryConfig<T> {
    return getTdsListsRaw(requestParameters, requestConfig);
}


import { getTdsLists, TdsListCategory } from '@generated/tds/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = TdsListCategory[] | undefined;

export interface Entities {
  tdsLists?: {
    [licenseId: number]: Result;
  };
}

const useGetTdsLists = (licenseId: number): ApiResponse<Result, Entities> => {
  const query = getTdsLists<Entities>(
    {
      licenseId,
    },
    {
      transform: responseBody => ({
        tdsLists: {
          [licenseId]: responseBody,
        },
      }),
      update: {
        tdsLists: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Result =>
    state.entities?.tdsLists?.[licenseId];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetTdsLists;

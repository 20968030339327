// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Track,
    TrackFromJSON,
    TrackToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpcomingStakeRace
 */
export interface UpcomingStakeRace  {
    /**
     * 
     * @type {number}
     * @memberof UpcomingStakeRace
     */
    propositionId: number;
    /**
     * 
     * @type {string}
     * @memberof UpcomingStakeRace
     */
    raceName: string;
    /**
     * 
     * @type {Track}
     * @memberof UpcomingStakeRace
     */
    track: Track;
    /**
     * 
     * @type {Date}
     * @memberof UpcomingStakeRace
     */
    raceDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpcomingStakeRace
     */
    firstStakeDate: Date;
    /**
     * 
     * @type {string}
     * @memberof UpcomingStakeRace
     */
    genderText: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingStakeRace
     */
    ageText: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingStakeRace
     */
    surface: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingStakeRace
     */
    distance: string;
}

export function UpcomingStakeRaceFromJSON(json: any): UpcomingStakeRace {
    return {
        'propositionId': json['propositionId'],
        'raceName': json['raceName'],
        'track': TrackFromJSON(json['track']),
        'raceDate': parseDateTime(json['raceDate']),
        'firstStakeDate': parseDateTime(json['firstStakeDate']),
        'genderText': json['genderText'],
        'ageText': json['ageText'],
        'surface': json['surface'],
        'distance': json['distance'],
    };
}

export function UpcomingStakeRaceToJSON(value?: UpcomingStakeRace): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'propositionId': value.propositionId,
        'raceName': value.raceName,
        'track': TrackToJSON(value.track),
        'raceDate': value.raceDate.toLocalDate(),
        'firstStakeDate': value.firstStakeDate.toLocalDate(),
        'genderText': value.genderText,
        'ageText': value.ageText,
        'surface': value.surface,
        'distance': value.distance,
    };
}



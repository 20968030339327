import { useGetPersonalInformation } from '@apis';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import useAuthenticationMethod from '@hooks/useAuthenticationMethod';
import Box from '@primitives/Box';
import hasRole from '@utils/hasRole';
import roleEnum from '@utils/roleEnum';
import React from 'react';

interface Props {
  user: AuthenticatedUser;
}

function UserInfo({ user }: Props): JSX.Element | null {
  const { data } = useGetPersonalInformation(user.licenseId);
  const authMethod = useAuthenticationMethod();

  if (!data) {
    return null;
  }

  return (
    <Box sx={{ lineHeight: '1.5' }}>
      <b>E-post:</b> {data.changeableUserInformation.email}
      <br />
      {authMethod !== 'BANKID' && (
        <>
          <b>SMS-nummer:</b> {data.changeableUserInformation.smsNumber}
          <br />
        </>
      )}
      {hasRole(user, roleEnum.Licensee) && (
        <>
          <b>Webbplats:</b> {data.changeableUserInformation.webPage}
        </>
      )}
    </Box>
  );
}

export default withUser(UserInfo);

import {
  StyledIcon,
  StyledIconBase,
  StyledIconProps,
} from '@styled-icons/styled-icon';
import React from 'react';

import { ReactComponent as RiderIconSvg } from './icons/rider.svg';

const RiderIcon: StyledIcon = React.forwardRef<SVGSVGElement, StyledIconProps>(
  (props, ref) => {
    const attrs: React.SVGProps<SVGSVGElement> = {};

    return (
      <StyledIconBase
        iconAttrs={attrs}
        iconVerticalAlign="middle"
        iconViewBox="0 0 595.28 595.28"
        {...props}
        ref={ref}
      >
        <RiderIconSvg />
      </StyledIconBase>
    );
  },
);

RiderIcon.displayName = 'RiderIcon';

export default RiderIcon;

import Menu from '@components/Menu';
import { AuthenticatedUser } from '@generated/account/src';
import { useRefreshUser } from '@hooks/useUser';
import auth from '@main/auth';
import { resetState } from '@main/store';
import Box from '@primitives/Box';
import List from '@primitives/List';
import { House as Home } from '@styled-icons/fa-solid';
import hasRole from '@utils/hasRole';
import roleEnum from '@utils/roleEnum';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { navigation } from './navigation';

interface Props {
  user: AuthenticatedUser;
  background?: boolean;
  homeLink?: boolean;
  wide?: boolean;
}

export default function UserMenu({
  user,
  background = false,
  homeLink = false,
  wide = false,
}: Props): JSX.Element | null {
  const history = useHistory();
  const dispatch = useDispatch();
  const refreshUser = useRefreshUser();

  const signOut = async (event: MouseEvent): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    localStorage.removeItem('forceChangeOfPassword');

    auth.token = null;

    await dispatch(resetState());
    await refreshUser();

    history.push('/');
  };

  return (
    <List sx={wide ? { columnCount: 2, columnGap: '10px' } : undefined}>
      {homeLink && (
        <Box as="li" mb={5} sx={{ breakInside: 'avoid-column' }}>
          <Menu
            eventTrackingTag="userpages-menu"
            background={background}
            items={[
              {
                text: 'Min startsida',
                icon: Home,
                to: `/minasidor`,
                exact: true,
              },
            ]}
          />
        </Box>
      )}

      {navigation
        .map(group => {
          const items = group.items.filter(
            item =>
              !item.hidden &&
              (item.roles.length === 0
                ? true
                : item.requireEveryRole
                  ? item.roles.every(role => hasRole(user, role as roleEnum))
                  : item.roles.some(role => hasRole(user, role as roleEnum))),
          );

          if (items.length === 0) {
            return null;
          }

          return (
            <Box
              as="li"
              key={group.key}
              mb={5}
              sx={{ breakInside: 'avoid-column' }}
            >
              <Menu
                eventTrackingTag="userpages-menu"
                background={background}
                label={group.label}
                items={items.map(item => ({
                  text: item.label,
                  icon: item.icon,
                  to: `/minasidor/${item?.to || item.path.split('/')[0]}`,
                  onClick: item.path === 'log-out' ? signOut : undefined,
                }))}
              />
            </Box>
          );
        })
        .filter(Boolean)}
    </List>
  );
}

import { AuthenticatedUser } from '@generated/account/src';
import Box from '@primitives/Box';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LeasingTerminationsList from './LeasingTerminationsList';

interface Props {
  user: AuthenticatedUser;
}

const LeasingTerminations = ({ user }: Props): JSX.Element => (
  <Switch>
    <Route>
      <Box verticalSpacing={4} my={4}>
        <LeasingTerminationsList user={user} />
      </Box>
    </Route>
  </Switch>
);

export default LeasingTerminations;

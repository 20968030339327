import {
  ErrorResponse,
  tryToUnenrollFromProposition,
} from '@generated/propositions/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

export interface Entities {
  tryToUnenrollFromProposition?: ErrorResponse;
}

const useTryToUnenrollFromProposition = (
  propositionId: number,
  horseId: number,
  trainerId: number,
): MutationResponse<
  Entities['tryToUnenrollFromProposition'],
  Entities,
  unknown
> => {
  const queryFunc = (): QueryConfig<Entities> =>
    tryToUnenrollFromProposition({
      propositionId,
      horseId,
    });
  return useApiMutate<Entities['tryToUnenrollFromProposition'], unknown>({
    queryFunc,
    selector: (state: State) => state.entities.tryToUnenrollFromProposition,
    queryKey: 'tryToUnenrollFromProposition',
    resetKeys: [
      'availableHorsesForStart*',
      `enrollmentsForTrainer-${trainerId}`,
    ],
  });
};

export default useTryToUnenrollFromProposition;

import ReactTable, { TableProps } from '@components/ReactTable';
import { Theme } from '@main/theme';
import { base, BaseProps } from '@primitives/base';
import Box from '@primitives/Box';
import React from 'react';
import styled from 'styled-components';

interface StyleProps extends Omit<BaseProps, 'theme' | 'color' | 'maxWidth'> {
  layout?: 'fixed' | 'auto';
  expandableBackground?: keyof Theme['colors'];
  condensed?: boolean;
  searchVertionStyleing?: boolean;
}

export const Styles = styled(Box).attrs({
  maxWidth: '100%',
})<StyleProps>`
  font-family: ${props => props.theme.mainFont};
  text-align: left;

  table {
    border-spacing: 0;
    border-collapse: separate;
    min-width: 100%;
    table-layout: ${p => p?.layout || 'auto'};
  }

  .scrollable-table::after {
    width: 16px;
    height: calc(100% - 22px);
    content: '';
    top: 22px;
    right: 0;
    position: absolute;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.4913250811688312) 0%,
      rgba(199, 196, 193, 0.8) 100%
    );
  }

  .parentRow {
    font-weight: bold;
  }
  thead {
    ${props => props.searchVertionStyleing && 'display : none;'}
  }
  thead {
    th {
      font-size: ${props =>
        props.condensed
          ? props.theme.fontSizes.notes
          : props.theme.fontSizes.notes}px;
      line-height: 1.15;
      border-bottom: 1px
        ${props => (props.searchVertionStyleing ? 'none' : 'solid')}
        ${props => props.theme.colors.vistaWhite};
      font-weight: bold;
      padding: ${props => (props.condensed ? '6px 6px 4px' : '10px 10px 4px')};
      background: transparent;
      vertical-align: bottom;
    }
  }

  td {
    vertical-align: top;
    line-height: 1.15;
    white-space: nowrap;
    border: none;
    padding: ${props =>
      props.searchVertionStyleing ? '0px 0px 16px 0px' : '10px'};
    font-size: ${props =>
      props.condensed
        ? props.theme.fontSizes.small
        : props.theme.fontSizes.text}px;
  }

  tr:not(:last-of-type) td {
    border-bottom: 1px
      ${props => (props.searchVertionStyleing ? 'none' : 'solid')}
      ${props => props.theme.colors.vistaWhite};
  }

  tr:last-of-type td {
    border-bottom: 1px
      ${props => (props.searchVertionStyleing ? 'none' : 'solid')}
      ${props => props.theme.colors.vistaWhite};
    ${props => (props.searchVertionStyleing ? 'padding: 0px 0px 0px 0px;' : '')}
  }

  tr:first-child {
    > td {
      border-top: none;
    }
  }

  th {
    padding-left: 5px;
  }
  && {
    .expanded-table-cell {
      background-color: ${props =>
        (props?.expandableBackground &&
          props.theme.colors[props.expandableBackground]) ||
        props.theme.colors['very-light-blue']};

      white-space: normal;
      padding: 16px;
    }
  }
  ${base()}
`;

// eslint-disable-next-line @typescript-eslint/ban-types
function Table<T extends object>({
  data,
  columns,
  overflow = 'auto',
  pagination = false,
  stickyOnMobile = false,
  title,
  sortable,
  subRowWide,
  subRowDoubleVerticalLine,
  striped = false,
  renderListItem,
  renderRowSubComponent,
  defaultExpanded,
  allExpanded = false,
  getRowId,
  noContent,
  emptyState,
  condensed,
  searchVertionStyleing,
  ...props
}: TableProps<T> & StyleProps): JSX.Element {
  return (
    <Styles
      condensed={condensed}
      searchVertionStyleing={searchVertionStyleing}
      {...props}
    >
      <ReactTable<T>
        variant="table"
        data={data || []}
        columns={columns}
        overflow={overflow}
        stickyOnMobile={stickyOnMobile}
        pagination={pagination}
        title={title}
        sortable={sortable}
        subRowWide={subRowWide}
        subRowDoubleVerticalLine={subRowDoubleVerticalLine}
        striped={striped}
        allExpanded={allExpanded}
        renderListItem={renderListItem}
        renderRowSubComponent={renderRowSubComponent}
        expandableBackground={props?.expandableBackground}
        defaultExpanded={defaultExpanded}
        getRowId={getRowId}
        noContent={noContent}
        emptyState={emptyState}
        condensed={condensed}
      />
    </Styles>
  );
}

export default Table;

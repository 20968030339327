import { useGetVaccineHistory } from '@apis';
import DataGrid from '@components/DataGrid';
import {
  VaccinatedHorse,
  VaccineInjection,
} from '@generated/licenseholders/src';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import { Text } from '@primitives/Typography';
import { formatTz } from '@utils/dateUtils';
import React from 'react';
import { CellProps, Column } from 'react-table';

const columns: Column<VaccineInjection>[] = [
  {
    Header: 'Vaccinationsdatum',
    accessor: 'injectionDate',
    Cell: ({ value }: CellProps<VaccineInjection, Date>) => (
      <>{formatTz(value, 'yyyy-MM-dd')}</>
    ),
  },
  {
    Header: 'Vaccin',
    accessor: row => row?.vaccine?.name,
    tdStyle: () => ({
      whiteSpace: 'normal',
    }),
  },
  {
    Header: 'Sekvens',
    accessor: 'vaccineSequence',
    alignment: 'center',
  },
  {
    Header: 'Giltigt tom',
    accessor: 'validTo',
    sortType: (a, b) => {
      const aValue = a.original?.validTo?.getTime() || 0;
      const bValue = b.original?.validTo?.getTime() || 0;

      return aValue - bValue;
    },
    Cell: ({ value }: CellProps<VaccineInjection, Date>): JSX.Element => (
      <>{formatTz(value, 'yyyy-MM-dd')}</>
    ),
  },
];

interface Props {
  horse: VaccinatedHorse;
  licenseId: number;
}

export default function Vaccinations({ horse, licenseId }: Props): JSX.Element {
  const { data, loading, error, status } = useGetVaccineHistory(
    licenseId,
    horse.horseId,
  );

  if (loading || !data) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  return (
    <>
      {data.length === 0 && (
        <Text color="dim-gray">Fann inga vaccinationer.</Text>
      )}
      {data.length > 0 && (
        <Text color="dim-gray">
          {horse.horseName} har {data.length} registrerad
          {data.length > 1 ? 'e' : ''} vaccination
          {data.length > 1 ? 'er' : ''}:
        </Text>
      )}
      <DataGrid mt={2} columns={columns} data={data} sortable />
    </>
  );
}

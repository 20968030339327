// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActorResourceChartPositions
 */
export interface ActorResourceChartPositions  {
    /**
     * 
     * @type {string}
     * @memberof ActorResourceChartPositions
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ActorResourceChartPositions
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof ActorResourceChartPositions
     */
    position?: number;
    /**
     * 
     * @type {string}
     * @memberof ActorResourceChartPositions
     */
    description?: string;
}

export function ActorResourceChartPositionsFromJSON(json: any): ActorResourceChartPositions {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ActorResourceChartPositionsToJSON(value?: ActorResourceChartPositions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'label': value.label,
        'position': value.position,
        'description': value.description,
    };
}



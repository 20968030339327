import config from '@config';
import { ContentPageResource, getContent } from '@generated/content/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from './utils/types';
import useApi from './utils/useApi';

type Content = ContentPageResource | undefined;

export interface Entities {
  content?: { [key: string]: Content };
}

const getQuery = (slug: string): QueryConfig<Entities> =>
  getContent<Entities>(
    {
      slug,
    },
    {
      transform: responseBody => ({
        content: { [slug]: responseBody },
      }),
      update: {
        content: (prev, next): typeof prev => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useContent = (slug: string): ApiResponse<Content, Entities> => {
  const base = getQuery(`${slug}`);
  const selector = (state: State): Content => state.entities.content?.[slug];
  return useApi<Content>({
    query: {
      ...base,
      url: config.isProduction
        ? base.url
        : base.url.replace('/api/', '/api-test/'),
    },
    selector,
    basePath: 'contentapi',
  });
};

export default useContent;

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    RegistrationDetails,
    RegistrationDetailsFromJSON,
    RegistrationDetailsToJSON,
    StartRegistrationDetails,
    StartRegistrationDetailsFromJSON,
    StartRegistrationDetailsToJSON,
    Text,
    TextFromJSON,
    TextToJSON,
    WeightRegistrationDetails,
    WeightRegistrationDetailsFromJSON,
    WeightRegistrationDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface PropositionDetails
 */
export interface PropositionDetails  {
    /**
     * The unique id for the proposition.
     * @type {number}
     * @memberof PropositionDetails
     */
    propositionId: number;
    /**
     * The name of the track.
     * @type {string}
     * @memberof PropositionDetails
     */
    trackName: string;
    /**
     * The race date.
     * @type {Date}
     * @memberof PropositionDetails
     */
    raceDate: Date;
    /**
     * 
     * @type {RegistrationDetails}
     * @memberof PropositionDetails
     */
    registrationDetails: RegistrationDetails;
    /**
     * 
     * @type {StartRegistrationDetails}
     * @memberof PropositionDetails
     */
    startRegistrationDetails: StartRegistrationDetails;
    /**
     * 
     * @type {WeightRegistrationDetails}
     * @memberof PropositionDetails
     */
    weightRegistrationDetails: WeightRegistrationDetails;
    /**
     * If the registration is done or not.
     * @type {boolean}
     * @memberof PropositionDetails
     */
    registrationDone: boolean;
    /**
     * A list of text models.
     * @type {Array<Text>}
     * @memberof PropositionDetails
     */
    texts: Array<Text>;
}

export function PropositionDetailsFromJSON(json: any): PropositionDetails {
    return {
        'propositionId': json['propositionId'],
        'trackName': json['trackName'],
        'raceDate': parseDateTime(json['raceDate']),
        'registrationDetails': RegistrationDetailsFromJSON(json['registrationDetails']),
        'startRegistrationDetails': StartRegistrationDetailsFromJSON(json['startRegistrationDetails']),
        'weightRegistrationDetails': WeightRegistrationDetailsFromJSON(json['weightRegistrationDetails']),
        'registrationDone': json['registrationDone'],
        'texts': (json['texts'] as Array<any>).map(TextFromJSON),
    };
}

export function PropositionDetailsToJSON(value?: PropositionDetails): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'propositionId': value.propositionId,
        'trackName': value.trackName,
        'raceDate': value.raceDate.toLocalDate(),
        'registrationDetails': RegistrationDetailsToJSON(value.registrationDetails),
        'startRegistrationDetails': StartRegistrationDetailsToJSON(value.startRegistrationDetails),
        'weightRegistrationDetails': WeightRegistrationDetailsToJSON(value.weightRegistrationDetails),
        'registrationDone': value.registrationDone,
        'texts': (value.texts as Array<any>).map(TextToJSON),
    };
}



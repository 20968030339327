import { State } from '@main/store';
import { format } from 'date-fns';
import { QueryConfig } from 'redux-query';

import {
  getOwnershipInvoiceTransactions,
  InvoicedTransactions,
} from '../../generated/sportactors/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = InvoicedTransactions[] | undefined;

export interface Entities {
  ownershipInvoiceTransactions?: {
    [key: string]: Result;
  };
}

export function ownershipInvoiceTransactionsKey(
  ownerId: number,
  date: Date,
): string {
  return `ownershipInvoiceTransactions-${ownerId}-${format(
    date,
    'yyyy-MM-dd',
  )}`;
}

const getQuery = (
  ownerId: number,
  date: Date,
  key: string,
): QueryConfig<Entities> => {
  const query = getOwnershipInvoiceTransactions<Entities>(
    {
      ownerId,
      date,
    },
    {
      queryKey: ownershipInvoiceTransactionsKey(ownerId, date),
      transform: responseBody => ({
        ownershipInvoiceTransactions: {
          [key]: responseBody,
        },
      }),
      update: {
        ownershipInvoiceTransactions: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  // A hack to format date param correctly
  return {
    ...query,
    url: `/sportactors/owners/{ownerId}/ownerships/invoicetransactions/{date}`
      .replace(`{${'ownerId'}}`, ownerId.toString())
      .replace(`{${'date'}}`, format(date, 'yyyy-MM-dd')),
  };
};

const useGetOwnershipInvoiceTransactions = (
  ownerId: number,
  date: Date,
): ApiResponse<Result, Entities> => {
  const key = ownershipInvoiceTransactionsKey(ownerId, date);

  const query = getQuery(ownerId, date, key);
  const selector = (state: State): Result =>
    state.entities?.ownershipInvoiceTransactions?.[key];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetOwnershipInvoiceTransactions;

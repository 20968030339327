// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    SportInfoStatistics,
    SportInfoStatisticsFromJSON,
    SportInfoStatisticsToJSON,
} from './';

/**
 * 
 * @export
 * @interface SportInfoLicenseHolderStatistics
 */
export interface SportInfoLicenseHolderStatistics  {
    /**
     * Only for riders: The message includes additional information regarding international wins.
     * @type {string}
     * @memberof SportInfoLicenseHolderStatistics
     */
    foreignVictoriesMessage?: string;
    /**
     * Message regarding what the statistics cover.
     * @type {string}
     * @memberof SportInfoLicenseHolderStatistics
     */
    statisticsMessage?: string;
    /**
     * Used for drivers/riders/trainers
     * @type {Array<SportInfoStatistics>}
     * @memberof SportInfoLicenseHolderStatistics
     */
    statistics: Array<SportInfoStatistics>;
}

export function SportInfoLicenseHolderStatisticsFromJSON(json: any): SportInfoLicenseHolderStatistics {
    return {
        'foreignVictoriesMessage': !exists(json, 'foreignVictoriesMessage') ? undefined : json['foreignVictoriesMessage'],
        'statisticsMessage': !exists(json, 'statisticsMessage') ? undefined : json['statisticsMessage'],
        'statistics': (json['statistics'] as Array<any>).map(SportInfoStatisticsFromJSON),
    };
}

export function SportInfoLicenseHolderStatisticsToJSON(value?: SportInfoLicenseHolderStatistics): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'foreignVictoriesMessage': value.foreignVictoriesMessage,
        'statisticsMessage': value.statisticsMessage,
        'statistics': (value.statistics as Array<any>).map(SportInfoStatisticsToJSON),
    };
}



import { useGetTdsListLink } from '@apis';
import ErrorPage from '@components/ErrorPage';
import useNumericParams from '@hooks/useNumericParams';
import Box from '@primitives/Box';
import Loading from '@primitives/Loading';
import { TriangleExclamation as ExclamationTriangle } from '@styled-icons/fa-solid';
import { getExternalFileUrl } from '@utils/urls';
import React, { useEffect } from 'react';

function GenerateTdsListUrlAndRedirect(): JSX.Element {
  const { licenseId, documentId } = useNumericParams();

  const { data, loading, status, error } = useGetTdsListLink(
    licenseId,
    documentId,
  );

  useEffect(() => {
    if (data && data.url) {
      window.location.href = getExternalFileUrl(data.url);
    }
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return (
      <ErrorPage
        headline="Oväntat fel"
        header="Ett oväntat fel har inträffat"
        content={
          <Box
            as="pre"
            mx="auto"
            maxWidth="300px"
            bg="gray1"
            border="solid 1px #ccc"
            p={4}
          >
            {error}
          </Box>
        }
        icon={ExclamationTriangle}
      />
    );
  }

  return null;
}

export default GenerateTdsListUrlAndRedirect;

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Invoice,
    InvoiceFromJSON,
    InvoiceToJSON,
    OwnerTransactions,
    OwnerTransactionsFromJSON,
    OwnerTransactionsToJSON,
    Regulation,
    RegulationFromJSON,
    RegulationToJSON,
} from './';

/**
 * 
 * @export
 * @interface DetailedInvoice
 */
export interface DetailedInvoice  {
    /**
     * 
     * @type {Invoice}
     * @memberof DetailedInvoice
     */
    invoice: Invoice;
    /**
     * 
     * @type {Array<Regulation>}
     * @memberof DetailedInvoice
     */
    regulations: Array<Regulation>;
    /**
     * 
     * @type {Array<OwnerTransactions>}
     * @memberof DetailedInvoice
     */
    ownerTransactions: Array<OwnerTransactions>;
}

export function DetailedInvoiceFromJSON(json: any): DetailedInvoice {
    return {
        'invoice': InvoiceFromJSON(json['invoice']),
        'regulations': (json['regulations'] as Array<any>).map(RegulationFromJSON),
        'ownerTransactions': (json['ownerTransactions'] as Array<any>).map(OwnerTransactionsFromJSON),
    };
}

export function DetailedInvoiceToJSON(value?: DetailedInvoice): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'invoice': InvoiceToJSON(value.invoice),
        'regulations': (value.regulations as Array<any>).map(RegulationToJSON),
        'ownerTransactions': (value.ownerTransactions as Array<any>).map(OwnerTransactionsToJSON),
    };
}



import { useParams } from 'react-router-dom';

interface NumericParams {
  [key: string]: number;
}
export default function useNumericParams<T extends NumericParams>(): T {
  const params = useParams();
  const numeric: NumericParams = {};

  Object.keys(params).forEach(key => {
    if (params[key]) {
      numeric[key] = parseInt(params[key]);
    }
  });

  return numeric as T;
}

// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * TDS information.
 * @export
 * @interface TDSInformation
 */
export interface TDSInformation  {
    /**
     * The user\'s supplier/company name
     * @type {string}
     * @memberof TDSInformation
     */
    supplierName?: string;
    /**
     * The organisation number of the supplier/company
     * @type {string}
     * @memberof TDSInformation
     */
    organisationNumber?: string;
    /**
     * The supplier id
     * @type {number}
     * @memberof TDSInformation
     */
    supplierId: number;
    /**
     * The contact phone number
     * @type {Array<string>}
     * @memberof TDSInformation
     */
    contactNumber?: Array<string>;
    /**
     * The in advance percentage rate
     * @type {number}
     * @memberof TDSInformation
     */
    inAdvancePercentage?: number;
}

export function TDSInformationFromJSON(json: any): TDSInformation {
    return {
        'supplierName': !exists(json, 'supplierName') ? undefined : json['supplierName'],
        'organisationNumber': !exists(json, 'organisationNumber') ? undefined : json['organisationNumber'],
        'supplierId': json['supplierId'],
        'contactNumber': !exists(json, 'contactNumber') ? undefined : json['contactNumber'],
        'inAdvancePercentage': !exists(json, 'inAdvancePercentage') ? undefined : json['inAdvancePercentage'],
    };
}

export function TDSInformationToJSON(value?: TDSInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'supplierName': value.supplierName,
        'organisationNumber': value.organisationNumber,
        'supplierId': value.supplierId,
        'contactNumber': value.contactNumber,
        'inAdvancePercentage': value.inAdvancePercentage,
    };
}



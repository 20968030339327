import {
  fetchSearchSelections,
  GeneralSearchSelectionModel,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  propositionSelections?: GeneralSearchSelectionModel;
}

export default function useSearchPropositionSelections(): ApiResponse<
  GeneralSearchSelectionModel,
  Entities
> {
  const query = fetchSearchSelections<Entities>({
    transform: responseBody => ({
      propositionSelections: responseBody,
    }),
    update: {
      propositionSelections: (prev, next) => next,
    },
  });

  const selector = (state: State): GeneralSearchSelectionModel | undefined =>
    state.entities?.propositionSelections;

  return useApi<GeneralSearchSelectionModel>({
    query,
    selector,
  });
}

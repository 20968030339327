import { base, BaseProps } from '@primitives/base';
import styled from 'styled-components';

type Props = BaseProps;

const HR = styled.hr<Props>`
  width: 100%;
  border: 0;
  border-top-style: solid;
  ${base()}
`;

HR.defaultProps = {
  borderTopWidth: 1,
  borderTopColor: 'gray3',
  mt: 5,
  mb: 5,
};

export default HR;

// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    AdditionalInformation,
    AdditionalInformationFromJSON,
    AdditionalInformationToJSON,
    StartsHorse,
    StartsHorseFromJSON,
    StartsHorseToJSON,
    StartsLicenseHolder,
    StartsLicenseHolderFromJSON,
    StartsLicenseHolderToJSON,
    StartsRaceInformation,
    StartsRaceInformationFromJSON,
    StartsRaceInformationToJSON,
    StartsTrack,
    StartsTrackFromJSON,
    StartsTrackToJSON,
} from './';

/**
 * 
 * @export
 * @interface ComingStart
 */
export interface ComingStart  {
    /**
     * 
     * @type {StartsTrack}
     * @memberof ComingStart
     */
    track: StartsTrack;
    /**
     * 
     * @type {StartsRaceInformation}
     * @memberof ComingStart
     */
    raceInformation: StartsRaceInformation;
    /**
     * 
     * @type {StartsHorse}
     * @memberof ComingStart
     */
    horse?: StartsHorse;
    /**
     * 
     * @type {StartsLicenseHolder}
     * @memberof ComingStart
     */
    driver?: StartsLicenseHolder;
    /**
     * 
     * @type {StartsLicenseHolder}
     * @memberof ComingStart
     */
    trainer?: StartsLicenseHolder;
    /**
     * 
     * @type {AdditionalInformation}
     * @memberof ComingStart
     */
    additionalInformation?: AdditionalInformation;
}

export function ComingStartFromJSON(json: any): ComingStart {
    return {
        'track': StartsTrackFromJSON(json['track']),
        'raceInformation': StartsRaceInformationFromJSON(json['raceInformation']),
        'horse': !exists(json, 'horse') ? undefined : StartsHorseFromJSON(json['horse']),
        'driver': !exists(json, 'driver') ? undefined : StartsLicenseHolderFromJSON(json['driver']),
        'trainer': !exists(json, 'trainer') ? undefined : StartsLicenseHolderFromJSON(json['trainer']),
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : AdditionalInformationFromJSON(json['additionalInformation']),
    };
}

export function ComingStartToJSON(value?: ComingStart): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'track': StartsTrackToJSON(value.track),
        'raceInformation': StartsRaceInformationToJSON(value.raceInformation),
        'horse': StartsHorseToJSON(value.horse),
        'driver': StartsLicenseHolderToJSON(value.driver),
        'trainer': StartsLicenseHolderToJSON(value.trainer),
        'additionalInformation': AdditionalInformationToJSON(value.additionalInformation),
    };
}



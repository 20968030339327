import SelectedHorses from './type/SelectedHorses';

const getNamesFromSelectedHorses = (
  selectedHorses: SelectedHorses,
): string[] => {
  const names = [];
  for (const horseId in selectedHorses) {
    if (selectedHorses[horseId]) {
      names.push(selectedHorses[horseId]);
    }
  }
  return names;
};

export default getNamesFromSelectedHorses;

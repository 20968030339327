import {
  HorsePreCheckedForVaccineRegistration,
  preCheckHorsesForVaccineRegistration,
} from '@generated/licenseholders/src';
import { State } from '@main/store';
import { format } from 'date-fns';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = HorsePreCheckedForVaccineRegistration[] | undefined;

export interface Entities {
  preCheckHorsesForVaccineRegistration?: {
    [key: string]: Result;
  };
}

const getQuery = (
  licenseId: number,
  vaccinationdate: Date,
  vaccineid: number,
  horseids: number[],
  key: string,
): QueryConfig<Entities> =>
  preCheckHorsesForVaccineRegistration<Entities>(
    {
      licenseId,
      vaccinationdate,
      vaccineid,
      horseids,
    },
    {
      force: true,
      transform: responseBody => ({
        preCheckHorsesForVaccineRegistration: {
          [key]: responseBody,
        },
      }),
      update: {
        preCheckHorsesForVaccineRegistration: (prev, next): typeof next => next,
      },
    },
  );

const usePreCheckHorsesForVaccineRegistration = (
  licenseId: number,
  vaccinationdate: Date,
  vaccineid: number,
  horseids: number[],
): ApiResponse<Result, Entities> => {
  const key = `${licenseId}-${format(
    vaccinationdate,
    'yyyy-MM-dd',
  )}-${vaccineid}-${horseids.join('-')}`;
  const query = getQuery(licenseId, vaccinationdate, vaccineid, horseids, key);
  const selector = (state: State): Result =>
    state.entities?.preCheckHorsesForVaccineRegistration?.[key];

  return useApi<Result>({
    query,
    selector,
  });
};

export default usePreCheckHorsesForVaccineRegistration;

import { useGetRepresentativeHorses } from '@apis';
import DataGrid from '@components/DataGrid';
import { AuthenticatedUser } from '@generated/account/src';
import { Horse } from '@generated/ownership-transfers/src';
import useIsMobile from '@hooks/useIsMobile';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import { Link } from '@primitives/Link';
import Pulse from '@primitives/Loading';
import { H2, Small, Text } from '@primitives/Typography';
import { owner } from '@utils/links';
import scrollToTop from '@utils/scrollToTop';
import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { CellProps, Column } from 'react-table';

import Remarks, { RemarksCell } from '../Remarks';

interface Props {
  user: AuthenticatedUser;
}

interface Context {
  selectedHorse?: number;
  selectHorse: (v: number | undefined) => void;
}

export const Ctx = React.createContext<Partial<Context>>({});

const ActionCell = ({
  value,
  row,
}: CellProps<Horse, Horse['horseName']>): JSX.Element => {
  const { selectHorse, selectedHorse } = useContext(Ctx);
  const isMobile = useIsMobile();

  return (
    <>
      <Text
        as="label"
        fontWeight="bold"
        fontSize="text"
        sx={{ cursor: 'pointer' }}
      >
        <Box
          as="input"
          type="radio"
          checked={selectedHorse === row.original.horseId}
          onChange={event => {
            const { checked } = event.target;
            selectHorse(checked ? row.original.horseId : undefined);
          }}
          sx={{ cursor: 'pointer' }}
          mr={2}
          mt="2px"
        />
        {value}
      </Text>

      {value === 'NN' && (
        <>
          <Small mt={1} color="gray4" display="block">
            Regnr: {row.original.horseRegistrationNumber}
          </Small>
        </>
      )}
      {isMobile && (
        <>
          <Box mt={1}>
            <Remarks item={row.original} list />
          </Box>
        </>
      )}
    </>
  );
};

const RepresentativeHorsesList = ({ user }: Props): JSX.Element => {
  const { data, loading, error } = useGetRepresentativeHorses(user.licenseId);
  const isMobile = useIsMobile();
  const [selectedHorse, selectHorse] = useState<number>();
  const history = useHistory();

  const columns = useMemo(() => {
    const items: Column<Horse>[] = [
      {
        id: 'name',
        Header: 'Namn',
        accessor: row => row.horseName,
        tdStyle: () => ({
          whiteSpace: 'normal',
        }),
        Cell: ActionCell,
      },
      {
        id: 'age',
        Header: 'Ålder',
        alignment: 'center',
        accessor: row => row.horseAge,
      },
      {
        id: 'gender',
        Header: 'Kön',
        alignment: 'center',
        accessor: row => row.horseGender.code,
      },
      {
        id: 'owner',
        Header: 'Ägare',
        accessor: row => row.ownershipName,
        Cell: ({ row, value }: CellProps<Horse>) => (
          <Link to={owner(row.original.ownershipId)} fontWeight="bold">
            {value}
          </Link>
        ),
      },
      !isMobile && {
        id: 'remark',
        Header: 'Anmärkning',
        tdStyle: () => ({ whiteSpace: 'normal' }),
        // Accessor for sort purpuse
        accessor: row =>
          row.horseBarred
            ? 1
            : row.permanentStartBan
              ? 2
              : row.inStartList
                ? 3
                : row.inStakeRace
                  ? 4
                  : 9,
        Cell: RemarksCell,
      },
    ];

    return items.filter(Boolean);
  }, [isMobile]);

  if (loading) {
    return <Pulse />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <Ctx.Provider value={{ selectedHorse, selectHorse }}>
      <H2 mb="4" mt={[4, , 6]}>
        Välj häst för ägarskifte
      </H2>
      <DataGrid
        mt={2}
        columns={columns}
        data={data}
        sortable
        noContent={{
          content: 'Du har inga hästar som kan registreras för ägarskifte.',
        }}
        emptyRow
        defaultSort={[
          {
            desc: false,
            id: 'name',
          },
        ]}
      />
      <Button
        width={[1, , 'auto']}
        colorScheme="save"
        onClick={() => {
          scrollToTop();
          history.push(`/minasidor/agarskiften/anmal/${selectedHorse}`);
        }}
        disabled={!selectedHorse}
      >
        Påbörja ägarskifte
      </Button>
    </Ctx.Provider>
  );
};

export default RepresentativeHorsesList;

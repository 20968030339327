import { useCallback, useState } from 'react';

import {
  CancelTransferRequest,
  ErrorResponse,
  GetOwnershipExistenceStatusRequest,
  getPartOwners,
  OwnershipExistenceResponse,
  PartOwnersRequest,
  PartOwnersResponse,
  Person,
  RemoveAllPartOwnersRequest,
  RemovePartOwnerRequest,
  SetPartOwnersFromExistingOwnershipRequest,
} from '../../generated/ownership-transfers/src';
import { request } from '../request';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  partowners?: {
    [key: string]: Person[];
  };
}

export const useGetPartOwners = (
  licenseId: number,
  transferId: number,
): ApiResponse<Person[], Entities> => {
  const key = `${licenseId}:${transferId}`;
  return useApi<Person[]>({
    query: getPartOwners<Entities>(
      { licenseId, transferId },
      {
        transform: responseBody => ({
          partowners: {
            [key]: responseBody,
          },
        }),
        update: {
          partowners: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities?.partowners?.[key],
  });
};

const initialState = {
  loading: false,
};

export interface PartownerErrors {
  errors?: {
    [key: number]: {
      id?: number;
      name?: string;
      personType?: string;
      personalNumber?: string;
      organisationNumber?: string;
    }[];
  };
}

interface State<Response> extends PartownerErrors {
  loading: boolean;
  error?: string;
  data?: Response;
  status?: number;
  errorCode?: number;
}

interface FetchResponse<T> extends PartownerErrors {
  success: boolean;
  data?: T;
}

interface Response<RequestArgs, Response> extends State<Response> {
  fetch: (
    args: Omit<RequestArgs, 'licenseId'>,
  ) => Promise<FetchResponse<Response>>;
}

export const createPartownerMutation =
  <TReq, TRes>(method, pathMethod) =>
  (
    licenseId: number,
    serviceType = 'OWNERSHIP_TRANSFERS',
  ): Response<TReq, TRes> => {
    const [state, setState] = useState<State<TRes>>(initialState);

    const fetch = useCallback(
      async args => {
        setState(prev => ({
          ...prev,
          errors: undefined,
          error: undefined,
          loading: true,
        }));

        const pathArgs = Array.isArray(args)
          ? { licenseId, serviceType }
          : { licenseId, serviceType, ...args };

        try {
          const resp = await request<TRes & ErrorResponse & PartownerErrors>({
            url: pathMethod(pathArgs),
            method,
            body: args,
          });

          if (resp.ok) {
            setState({
              loading: false,
              data: resp.body,
              status: resp.statusCode,
              errors: resp.body?.errors,
            });

            return {
              success: true,
              data: resp.body,
              status: resp.statusCode,
              errors: resp.body?.errors,
            };
          }

          setState({
            error:
              resp.body?.localizedErrorMessage ||
              resp.body?.errorMessage ||
              'Oväntat fel',
            errors: resp.body?.errors,
            status: resp.statusCode,
            errorCode: resp.body?.errorCode,
            loading: false,
          });
          return { success: false, errors: resp.body?.errors };
        } catch (error) {
          setState({
            error: 'Server error',
            loading: false,
          });
          return { success: false };
        }
      },
      [licenseId, serviceType],
    );

    return {
      ...state,
      fetch,
    };
  };

export const useSetPartOwnersFromExistingOwnership = createPartownerMutation<
  SetPartOwnersFromExistingOwnershipRequest,
  PartOwnersResponse
>(
  'PUT',
  ({ licenseId, ownershipId, transferId, serviceType }) =>
    `/ownershiptransfers/${licenseId}/partowners?ownershipId=${ownershipId}&transferId=${transferId}&serviceType=${serviceType}`,
);

export const useAddPartOwners = createPartownerMutation<
  PartOwnersRequest,
  PartOwnersResponse
>(
  'POST',
  ({ licenseId, serviceType }) =>
    `/ownershiptransfers/${licenseId}/partowners?serviceType=${serviceType}`,
);

export const useRemovePartOwner = createPartownerMutation<
  RemovePartOwnerRequest,
  void
>(
  'DELETE',
  ({ licenseId, partOwnerId, transferId, serviceType }) =>
    `/ownershiptransfers/${licenseId}/partowners?partOwnerId=${partOwnerId}&transferId=${transferId}&serviceType=${serviceType}`,
);

export const useCancelTransfer = createPartownerMutation<
  CancelTransferRequest,
  void
>(
  'DELETE',
  ({ licenseId, message, transferId, serviceType }) =>
    `/ownershiptransfers/${licenseId}/${transferId}?message=${message}&serviceType=${serviceType}`,
);

export const useRemoveAllPartOwners = createPartownerMutation<
  RemoveAllPartOwnersRequest,
  void
>(
  'DELETE',
  ({ licenseId, transferId, serviceType }) =>
    `/ownershiptransfers/${licenseId}/partowners/all?transferId=${transferId}&serviceType=${serviceType}`,
);

export const useCreateContract = createPartownerMutation<
  RemoveAllPartOwnersRequest,
  void
>(
  'POST',
  ({ licenseId, transferId, serviceType }) =>
    `/ownershiptransfers/${licenseId}/contracts?transferId=${transferId}&serviceType=${serviceType}`,
);

export const useSendTextToUserWithoutAccount = createPartownerMutation<
  Array<string>,
  void
>('POST', () => `/ownershiptransfers/textnotifications`);

export const useCheckExistingOwnership = createPartownerMutation<
  GetOwnershipExistenceStatusRequest,
  OwnershipExistenceResponse
>(
  'GET',
  ({ licenseId, transferId, serviceType }) =>
    `/ownershiptransfers/${licenseId}/${transferId}/ownership?serviceType=${serviceType}`,
);

export const useCreateLeaseTermination = createPartownerMutation<
  { licenseId: number; transferId: number; terminationDate: string },
  void
>(
  'POST',
  ({ licenseId }) => `/ownershiptransfers/leasing/terminations/${licenseId}`,
);

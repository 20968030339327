import Box from '@primitives/Box';
import { transparentize } from 'polished';
import React from 'react';
import styled, { keyframes } from 'styled-components';

import { base, BaseProps } from '../base';

export const rotation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const colorFunc = (p): string =>
  transparentize(0.8, p.theme.colors['deep-blue']);

export const Loading = styled.div<BaseProps>`
  margin: auto;
  align-self: center;
  border-radius: 50%;
  border-top: 0.3em solid ${colorFunc};
  border-right: 0.3em solid ${colorFunc};
  border-bottom: 0.3em solid ${colorFunc};
  border-left: 0.3em solid ${p => p.theme.colors['deep-blue']};
  animation: ${rotation} 0.8s infinite linear;

  ${base()};
`;

const pulse = keyframes`
  0% {
    transform: scale(.1);
    opacity: 0;
  }
  50% { 
    opacity: .1;
  }
  100% {
    transform: scale(1.6); 
    opacity: 0;
  }
`;

const Part = styled(Box)<{ delay: number }>`
  opacity: 0;
  background-color: ${p => p.theme.colors['deep-blue']};
  border-radius: 50%;
  position: absolute;
  margin: auto auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: ${pulse} 2s infinite ${p => p.delay}s;
`;

type PulseProps = Parameters<typeof Box>[0] & {
  size?: number;
};

export const Pulse = ({ size, ...props }: PulseProps): JSX.Element => {
  const width = `${size || 100}px`;
  const height = `${size || 100}px`;
  return (
    <Box position="relative" my="150px" height={height} {...props}>
      <Part delay={0} height={height} width={width} />
      <Part delay={0.3} height={height} width={width} />
      <Part delay={0.6} height={height} width={width} />
    </Box>
  );
};

export default Pulse;

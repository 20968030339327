import { useGetHorsesByOwnerships } from '@apis';
import ContentBlock from '@components/ContentBlock';
import DataGrid from '@components/DataGrid';
import Linkable from '@components/Linkable';
import { PageLayout } from '@components/PageLayouts';
import ChangeRegistrationStatus from '@features/ChangeRegistrationStatus';
import { AuthenticatedUser } from '@generated/account/src';
import { OwnershipHorse } from '@generated/sportactors/src';
import withUser from '@hoc/withUser';
import useAuthenticationMethod from '@hooks/useAuthenticationMethod';
import useNumericQueryParams from '@hooks/useNumericQueryParams';
import Error from '@primitives/Error';
import { Link } from '@primitives/Link';
import Loading from '@primitives/Loading';
import Message from '@primitives/Message';
import { formatNumber } from '@utils/formatting';
import { horse, owner } from '@utils/links';
import roleEnum from '@utils/roleEnum';
import React, { useCallback, useEffect, useState } from 'react';
import { CellProps, Column } from 'react-table';

const columns = (
  authenticationMethod: string,
  horseId: number,
  onSuccess: (msg: string) => void,
): Column<OwnershipHorse>[] => [
  {
    id: 'name',
    Header: 'Namn',
    accessor: row => row.horseOwnership.horse,
    tdStyle: () => ({ whiteSpace: 'normal' }),
    Cell: ({
      value,
    }: CellProps<
      OwnershipHorse,
      OwnershipHorse['horseOwnership']['horse']
    >) => <Link to={horse(value.id)}>{value.name}</Link>,
  },
  {
    Header: 'Ändra regstatus',
    accessor: row => row.horseOwnership.horse.id,
    thStyle: () => ({ whiteSpace: 'normal' }),
    alignment: 'center',
    Cell: ({
      row,
      value,
    }: CellProps<
      OwnershipHorse,
      OwnershipHorse['horseOwnership']['horse']['id']
    >) => (
      <ChangeRegistrationStatus
        authenticationMethod={authenticationMethod}
        horseId={value}
        horseIdParam={horseId}
        horseName={row.original.horseOwnership.horse.name}
        registrationStatusChangeable={
          row.original.horseOwnership?.registrationStatusChangeable
        }
        onSuccess={onSuccess}
      />
    ),
  },
  // {
  //   Header: 'Född',
  //   accessor: row => row.horseOwnership.dateOfBirth,
  //   tdStyle: row =>
  //     row.original.horseOwnership.markedAsDead
  //       ? { borderLeft: `3px solid ${theme.colors.black}` }
  //       : null,
  //   sortType: 'datetime',

  //   Cell: ({
  //     row,
  //     value,
  //   }: CellProps<
  //     OwnershipHorse,
  //     OwnershipHorse['horseOwnership']['dateOfBirth']
  //   >): JSX.Element => (
  //     <>
  //       <Text>{format(value, 'yyyy')}</Text>{' '}
  //       {row.original.horseOwnership.markedAsDead && (
  //         <Micro color="black">Död</Micro>
  //       )}
  //     </>
  //   ),
  // },
  {
    Header: 'Ålder',
    accessor: row => row.horseOwnership.age,
    alignment: 'center',
  },
  {
    Header: 'Kön',
    accessor: row => row.horseOwnership.gender?.code,
    alignment: 'center',
  },
  {
    Header: 'HCP',
    accessor: row => row.horseOwnership.generalHandicap,
    alignment: 'center',
  },
  {
    Header: 'Vinstsumma',
    accessor: row => row.horseOwnership.prizeMoney,
    alignment: 'right',
    Cell: ({
      value,
    }: CellProps<
      OwnershipHorse,
      OwnershipHorse['horseOwnership']['prizeMoney']
    >) => <>{formatNumber(value)}</>,
  },
  {
    Header: 'Tränare',
    accessor: row => row.horseOwnership?.trainer?.name,
    Cell: ({ value, row }: CellProps<OwnershipHorse, string>) =>
      value ? (
        <Linkable
          entity={row.original.horseOwnership.trainer}
          type="LinkableLicenseHolder"
          tab="/tranarstatistik"
          fontSize="small"
        />
      ) : null,
  },
  {
    Header: 'Ägare',
    accessor: row => row.ownership.owner?.name,
    Cell: ({
      row: { original: row },
      value,
    }: CellProps<OwnershipHorse, string>) => (
      <Link to={owner(row.ownership.owner.id)} fontSize="small">
        {value}
      </Link>
    ),
  },
];

interface MyOwnedHorsesResultsProps {
  user: AuthenticatedUser;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
}

const MyOwnedHorsesResults = ({
  user: { licenseId },
  setSuccessMessage,
}: MyOwnedHorsesResultsProps): JSX.Element => {
  const { horseId } = useNumericQueryParams();
  const { data, loading, status, error, refresh } =
    useGetHorsesByOwnerships(licenseId);
  const authenticationMethod = useAuthenticationMethod();

  const onSuccess = useCallback(
    (message: string) => {
      setSuccessMessage(message);
      refresh();
    },
    [refresh, setSuccessMessage],
  );

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <DataGrid
      title={
        data.length > 0
          ? `Du äger ${data.length} häst${data.length > 1 ? 'ar' : ''}:`
          : 'Fann inga ägda hästar.'
      }
      mt={2}
      columns={columns(authenticationMethod, horseId, onSuccess)}
      data={data}
      showMore
      sortable
      defaultSort={[
        {
          desc: false,
          id: 'name',
        },
      ]}
    />
  );
};

interface MyOwnedHorsesProps {
  user: AuthenticatedUser;
}

const MyOwnedHorses = ({ user }: MyOwnedHorsesProps): JSX.Element => {
  const [successMessage, setSuccessMessage] = useState<string>();

  useEffect(() => {
    let timer;
    if (successMessage) {
      timer = setInterval(() => {
        setSuccessMessage(null);
      }, 5000);
    }
    return () => timer && clearInterval(timer);
  }, [successMessage]);

  return (
    <PageLayout headline="Mina ägda hästar" singleCard>
      {successMessage && (
        <Message variant="successBox" mb={2}>
          {successMessage}
        </Message>
      )}
      <ContentBlock
        slug="agare-mina-agda-hastar"
        alignment="left"
        justifyContent="flex-start"
      />
      <MyOwnedHorsesResults user={user} setSuccessMessage={setSuccessMessage} />
    </PageLayout>
  );
};

export default withUser(MyOwnedHorses, {
  roles: [roleEnum.Partner, roleEnum.Representative],
  showLoader: false,
});

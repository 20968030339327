import React from 'react';
import { useParams } from 'react-router-dom';

import List from './List';
import Race from './Race';

export default function Ongoing(): JSX.Element {
  const { propositionId } = useParams<{ propositionId?: string }>();

  if (propositionId) {
    return <Race propositionId={parseInt(propositionId)} />;
  }

  return <List />;
}

import { Theme } from '@main/theme';
import { darken, lighten } from 'polished';
import { variant as systemVariant } from 'styled-system';

interface Ps {
  theme: Theme;
}
//De typar funktionen med any själva
export const colorScheme = <P extends Ps>(
  props: P,
): ReturnType<typeof systemVariant> =>
  systemVariant({
    prop: 'colorScheme',
    variants: {
      primary: {
        color: props.theme.colors.white,
        'background-color': props.theme.colors.blue,
        '&:hover:not(:disabled)': {
          bg: darken(0.05, props.theme.colors.blue),
        },
      },
      secondary: {
        color: props.theme.colors.blue,
        border: `thin solid ${props.theme.colors.blue}`,
        'background-color': props.theme.colors.white,
        '&:hover:not(:disabled)': {
          bg: lighten(0.6, props.theme.colors.blue),
        },
      },
      secondaryAlt: {
        color: props.theme.colors['dim-gray'],
        border: `thin solid ${props.theme.colors['dim-gray']}`,
        'background-color': props.theme.colors.white,
        '&:hover:not(:disabled)': {
          bg: lighten(0.6, props.theme.colors.blue),
        },
      },
      save: {
        color: props.theme.colors.white,
        'background-color': props.theme.colors.green,
        '&:hover:not(:disabled)': {
          bg: darken(0.05, props.theme.colors.green),
        },
      },
      saveAlt: {
        color: props.theme.colors.green,
        border: `thin solid ${props.theme.colors.green}`,
        'background-color': props.theme.colors.white,
        '&:hover:not(:disabled)': {
          bg: lighten(0.6, props.theme.colors.green),
        },
      },
      delete: {
        color: props.theme.colors.white,
        'background-color': props.theme.colors.red,
        '&:hover:not(:disabled)': {
          bg: darken(0.05, props.theme.colors.red),
        },
      },

      bookmark: {
        color: props.theme.colors['dim-gray'],
        'background-color': props.theme.colors.whiteSmoke,
        '&:hover:not(:disabled)': {
          bg: darken(0.05, props.theme.colors.whiteSmoke),
        },
      },

      savedBookmark: {
        color: props.theme.colors.gray6,
        'background-color': props.theme.colors.white,
        border: `solid 2px ${props.theme.colors.gray2}`,
        '.button-icon': {
          color: props.theme.colors.yellow,
        },
      },

      saveStart: {
        color: props.theme.colors.white,
        'background-color': '#222d52',
        fontSize: 'lead',
        fontWeight: 'normal',
        '.button-icon': {
          color: props.theme.colors.yellow,
        },
        '&:hover': {
          bg: darken(0.1, '#222d52'),
        },
      },
    },
  });

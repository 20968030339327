// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    BasicBreeder,
    BasicBreederFromJSON,
    BasicBreederToJSON,
    BasicOwner,
    BasicOwnerFromJSON,
    BasicOwnerToJSON,
    BasicRegistrationStatus,
    BasicRegistrationStatusFromJSON,
    BasicRegistrationStatusToJSON,
    BasicTrainer,
    BasicTrainerFromJSON,
    BasicTrainerToJSON,
    GenderGroupGenders,
    GenderGroupGendersFromJSON,
    GenderGroupGendersToJSON,
    HorseBreed,
    HorseBreedFromJSON,
    HorseBreedToJSON,
    Messages,
    MessagesFromJSON,
    MessagesToJSON,
    Representative,
    RepresentativeFromJSON,
    RepresentativeToJSON,
    StartMonitoringInformation,
    StartMonitoringInformationFromJSON,
    StartMonitoringInformationToJSON,
} from './';

/**
 * 
 * @export
 * @interface HorseBasicInformation
 */
export interface HorseBasicInformation  {
    /**
     * 
     * @type {number}
     * @memberof HorseBasicInformation
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    name: string;
    /**
     * 
     * @type {GenderGroupGenders}
     * @memberof HorseBasicInformation
     */
    horseGender: GenderGroupGenders;
    /**
     * 
     * @type {HorseBreed}
     * @memberof HorseBasicInformation
     */
    horseBreed: HorseBreed;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    registrationNumber: string;
    /**
     * International horse id
     * @type {string}
     * @memberof HorseBasicInformation
     */
    uelnNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    passport?: string;
    /**
     * ISO code of the country where the horse is registered
     * @type {string}
     * @memberof HorseBasicInformation
     */
    registrationCountryCode: string;
    /**
     * ISO code of the country where the horse was bred
     * @type {string}
     * @memberof HorseBasicInformation
     */
    bredCountryCode: string;
    /**
     * Description of the country where the horse was bred
     * @type {string}
     * @memberof HorseBasicInformation
     */
    bredCountryText: string;
    /**
     * ISO code of the country where the horse was born
     * @type {string}
     * @memberof HorseBasicInformation
     */
    birthCountryCode: string;
    /**
     * 
     * @type {Date}
     * @memberof HorseBasicInformation
     */
    dateOfBirth: Date;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    dateOfBirthDisplayValue: string;
    /**
     * 
     * @type {number}
     * @memberof HorseBasicInformation
     */
    age: number;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    maxHandicap?: string;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    currentHandicap: string;
    /**
     * 
     * @type {number}
     * @memberof HorseBasicInformation
     */
    racingPoints: number;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    studBook: string;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    importExport?: string;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    raceProhibitionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    raceProhibitionReason?: string;
    /**
     * Whether or not the horse is a guest horse in Sweden
     * @type {boolean}
     * @memberof HorseBasicInformation
     */
    guestHorse: boolean;
    /**
     * 
     * @type {BasicRegistrationStatus}
     * @memberof HorseBasicInformation
     */
    registrationStatus: BasicRegistrationStatus;
    /**
     * 
     * @type {BasicOwner}
     * @memberof HorseBasicInformation
     */
    owner: BasicOwner;
    /**
     * 
     * @type {BasicBreeder}
     * @memberof HorseBasicInformation
     */
    breeder?: BasicBreeder;
    /**
     * 
     * @type {BasicTrainer}
     * @memberof HorseBasicInformation
     */
    trainer?: BasicTrainer;
    /**
     * 
     * @type {Representative}
     * @memberof HorseBasicInformation
     */
    representative: Representative;
    /**
     * 
     * @type {Messages}
     * @memberof HorseBasicInformation
     */
    messages?: Messages;
    /**
     * 
     * @type {boolean}
     * @memberof HorseBasicInformation
     */
    offspringExists: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HorseBasicInformation
     */
    resultsExists: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HorseBasicInformation
     */
    historyExists: boolean;
    /**
     * 
     * @type {StartMonitoringInformation}
     * @memberof HorseBasicInformation
     */
    startMonitoringInformation: StartMonitoringInformation;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    sportInfoType: HorseBasicInformationSportInfoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof HorseBasicInformation
     */
    horseEnrollmentStatus: boolean;
    /**
     * Does the horse have records in the SvSF registry
     * @type {boolean}
     * @memberof HorseBasicInformation
     */
    svsfExists: boolean;
    /**
     * Does the horse have records in the NTB registry
     * @type {boolean}
     * @memberof HorseBasicInformation
     */
    ntbExists: boolean;
    /**
     * 
     * @type {string}
     * @memberof HorseBasicInformation
     */
    currentHandicapSteeple?: string;
    /**
     * Highest winning prize.
     * @type {number}
     * @memberof HorseBasicInformation
     */
    highestPrizeMoney?: number;
    /**
     * The date of the highest winning prize.
     * @type {Date}
     * @memberof HorseBasicInformation
     */
    highestPrizeMoneyDate?: Date;
}

export function HorseBasicInformationFromJSON(json: any): HorseBasicInformation {
    return {
        'id': json['id'],
        'name': json['name'],
        'horseGender': GenderGroupGendersFromJSON(json['horseGender']),
        'horseBreed': HorseBreedFromJSON(json['horseBreed']),
        'color': json['color'],
        'registrationNumber': json['registrationNumber'],
        'uelnNumber': !exists(json, 'uelnNumber') ? undefined : json['uelnNumber'],
        'passport': !exists(json, 'passport') ? undefined : json['passport'],
        'registrationCountryCode': json['registrationCountryCode'],
        'bredCountryCode': json['bredCountryCode'],
        'bredCountryText': json['bredCountryText'],
        'birthCountryCode': json['birthCountryCode'],
        'dateOfBirth': parseDateTime(json['dateOfBirth']),
        'dateOfBirthDisplayValue': json['dateOfBirthDisplayValue'],
        'age': json['age'],
        'maxHandicap': !exists(json, 'maxHandicap') ? undefined : json['maxHandicap'],
        'currentHandicap': json['currentHandicap'],
        'racingPoints': json['racingPoints'],
        'studBook': json['studBook'],
        'importExport': !exists(json, 'importExport') ? undefined : json['importExport'],
        'raceProhibitionDate': !exists(json, 'raceProhibitionDate') ? undefined : json['raceProhibitionDate'],
        'raceProhibitionReason': !exists(json, 'raceProhibitionReason') ? undefined : json['raceProhibitionReason'],
        'guestHorse': json['guestHorse'],
        'registrationStatus': BasicRegistrationStatusFromJSON(json['registrationStatus']),
        'owner': BasicOwnerFromJSON(json['owner']),
        'breeder': !exists(json, 'breeder') ? undefined : BasicBreederFromJSON(json['breeder']),
        'trainer': !exists(json, 'trainer') ? undefined : BasicTrainerFromJSON(json['trainer']),
        'representative': RepresentativeFromJSON(json['representative']),
        'messages': !exists(json, 'messages') ? undefined : MessagesFromJSON(json['messages']),
        'offspringExists': json['offspringExists'],
        'resultsExists': json['resultsExists'],
        'historyExists': json['historyExists'],
        'startMonitoringInformation': StartMonitoringInformationFromJSON(json['startMonitoringInformation']),
        'sportInfoType': json['sportInfoType'],
        'horseEnrollmentStatus': json['horseEnrollmentStatus'],
        'svsfExists': json['svsfExists'],
        'ntbExists': json['ntbExists'],
        'currentHandicapSteeple': !exists(json, 'currentHandicapSteeple') ? undefined : json['currentHandicapSteeple'],
        'highestPrizeMoney': !exists(json, 'highestPrizeMoney') ? undefined : json['highestPrizeMoney'],
        'highestPrizeMoneyDate': !exists(json, 'highestPrizeMoneyDate') ? undefined : parseDateTime(json['highestPrizeMoneyDate']),
    };
}

export function HorseBasicInformationToJSON(value?: HorseBasicInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'horseGender': GenderGroupGendersToJSON(value.horseGender),
        'horseBreed': HorseBreedToJSON(value.horseBreed),
        'color': value.color,
        'registrationNumber': value.registrationNumber,
        'uelnNumber': value.uelnNumber,
        'passport': value.passport,
        'registrationCountryCode': value.registrationCountryCode,
        'bredCountryCode': value.bredCountryCode,
        'bredCountryText': value.bredCountryText,
        'birthCountryCode': value.birthCountryCode,
        'dateOfBirth': value.dateOfBirth.toLocalDate(),
        'dateOfBirthDisplayValue': value.dateOfBirthDisplayValue,
        'age': value.age,
        'maxHandicap': value.maxHandicap,
        'currentHandicap': value.currentHandicap,
        'racingPoints': value.racingPoints,
        'studBook': value.studBook,
        'importExport': value.importExport,
        'raceProhibitionDate': value.raceProhibitionDate,
        'raceProhibitionReason': value.raceProhibitionReason,
        'guestHorse': value.guestHorse,
        'registrationStatus': BasicRegistrationStatusToJSON(value.registrationStatus),
        'owner': BasicOwnerToJSON(value.owner),
        'breeder': BasicBreederToJSON(value.breeder),
        'trainer': BasicTrainerToJSON(value.trainer),
        'representative': RepresentativeToJSON(value.representative),
        'messages': MessagesToJSON(value.messages),
        'offspringExists': value.offspringExists,
        'resultsExists': value.resultsExists,
        'historyExists': value.historyExists,
        'startMonitoringInformation': StartMonitoringInformationToJSON(value.startMonitoringInformation),
        'sportInfoType': value.sportInfoType,
        'horseEnrollmentStatus': value.horseEnrollmentStatus,
        'svsfExists': value.svsfExists,
        'ntbExists': value.ntbExists,
        'currentHandicapSteeple': value.currentHandicapSteeple,
        'highestPrizeMoney': value.highestPrizeMoney,
        'highestPrizeMoneyDate': value.highestPrizeMoneyDate === undefined ? undefined : value.highestPrizeMoneyDate.toLocalDate(),
    };
}

/**
* @export
* @enum {string}
*/
export enum HorseBasicInformationSportInfoTypeEnum {
    HORSE = 'HORSE',
    LICENSE_HOLDER = 'LICENSE_HOLDER',
    OWNER = 'OWNER',
    BREEDER = 'BREEDER'
}



import Box from '@primitives/Box';
import Icon from '@primitives/Icon';
import { MagnifyingGlass as Search } from '@styled-icons/fa-solid';
import React from 'react';
import {
  GetSuggestionValue,
  InputProps,
  OnSuggestionsClearRequested,
  OnSuggestionSelected,
  RenderSuggestion,
  SuggestionsFetchRequested,
} from 'react-autosuggest';
import Autosuggest from 'react-autosuggest';

import Input from './input';
import { StyledDiv } from './select';

export type AutocompleteProps<TSuggestion> = {
  suggestions?: ReadonlyArray<TSuggestion>;
  onSuggestionsFetchRequested?: SuggestionsFetchRequested;
  onSuggestionsClearRequested?: OnSuggestionsClearRequested;
  onSuggestionSelected?: OnSuggestionSelected<TSuggestion>;
  getSuggestionValue?: GetSuggestionValue<TSuggestion>;
  renderSuggestion?: RenderSuggestion<TSuggestion>;
  inputProps?: InputProps<TSuggestion>;
  loading?: boolean;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const Autocomplete = <TSuggestion extends object>({
  suggestions,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  onSuggestionSelected,
  getSuggestionValue,
  renderSuggestion,
  loading,
  inputProps,
}: AutocompleteProps<TSuggestion>): JSX.Element => (
  <StyledDiv>
    <Box position="absolute" top="6px" left="9px" sx={{ zIndex: 1 }}>
      <Icon as={Search} size={14} color="gray4" />
    </Box>
    {loading ? (
      <div>
        <Input fontSize="small" width="100%" />
      </div>
    ) : (
      <Autosuggest<TSuggestion>
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested} // Called when need to update suggestions
        onSuggestionsClearRequested={onSuggestionsClearRequested} // Called when clear suggestions
        onSuggestionSelected={onSuggestionSelected} // Called when an item is selected
        getSuggestionValue={getSuggestionValue} // Populate input when click on suggestion
        renderSuggestion={renderSuggestion} // Render suggestion
        inputProps={inputProps}
      />
    )}
  </StyledDiv>
);
export default Autocomplete;

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Start
 */
export interface Start  {
    /**
     * 
     * @type {string}
     * @memberof Start
     */
    trackId?: string;
    /**
     * 
     * @type {string}
     * @memberof Start
     */
    trackCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Start
     */
    displayDate?: string;
}

export function StartFromJSON(json: any): Start {
    return {
        'trackId': !exists(json, 'trackId') ? undefined : json['trackId'],
        'trackCode': !exists(json, 'trackCode') ? undefined : json['trackCode'],
        'displayDate': !exists(json, 'displayDate') ? undefined : json['displayDate'],
    };
}

export function StartToJSON(value?: Start): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'trackId': value.trackId,
        'trackCode': value.trackCode,
        'displayDate': value.displayDate,
    };
}



import {
  insertChart,
  InsertChartRequest,
  SearchFilterToJSON,
} from '@generated/user/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';
import { Entities } from './useGetSavedChartFilters';

type ActionParams = {
  searchFilter: InsertChartRequest['searchFilter'];
  chartname: string;
};

const useInsertChart = (
  categoryId: number,
  typeId: number,
  licenseId: number,
): MutationResponse<Entities['savedChartFilters'], Entities, ActionParams> => {
  const queryFunc = ({
    searchFilter,
    chartname,
  }: ActionParams): QueryConfig<Entities> => {
    const base = insertChart({
      categoryId,
      typeId,
      licenseId,
      chartname,
      searchFilter,
    });

    // Fix  for mixing request params...
    return {
      ...base,
      body: SearchFilterToJSON(searchFilter),
      url: `${base.url}?chartname=${encodeURIComponent(chartname)}`,
    };
  };

  return useApiMutate<Entities['savedChartFilters'], ActionParams>({
    queryFunc,
    selector: (state: State) => state.entities.savedChartFilters,
    queryKey: 'insertChart',
  });
};

export default useInsertChart;

import ContentBlock from '@components/ContentBlock';
import { MagnifyingGlass as Search } from '@styled-icons/fa-solid';
import React from 'react';

interface Props {
  slug?: string;
  text?: string;
}

export default function ContentBlockSearch({ slug, text }: Props): JSX.Element {
  return (
    <ContentBlock
      icon={Search}
      slug={slug}
      text={text}
      sx={{
        minHeight: ['0', null, '286px'],
        maxWidth: '363px',
        marginLeft: 'auto !important',
        marginRight: 'auto !important',
        flexDirection: 'column',
        lineHeight: '22px',
      }}
    />
  );
}

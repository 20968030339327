import { useFormikContext } from 'formik';
import React from 'react';
import { Prompt } from 'react-router-dom';

export default function DirtyFormWarning(): JSX.Element {
  const { dirty } = useFormikContext();

  return (
    <Prompt
      when={dirty}
      message="Du har osparade ändringar, säkert att du vill lämna sidan?"
    />
  );
}

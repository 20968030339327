import { Ownership } from '@generated/ownership-transfers/src';
import Box from '@primitives/Box';
import { Text } from '@primitives/Typography';
import React, { useContext } from 'react';
import { CellProps } from 'react-table';

import { Ctx } from './Context';

const OwnershipCell = ({
  value,
  row,
}: CellProps<Ownership, Ownership['ownershipName']>): JSX.Element => {
  const { selectedOwnership, selectOwnership, transfer, isLeasing } =
    useContext(Ctx);
  const sameAsSeller = row.original.ownershipId === transfer.horse.ownershipId;

  return (
    <Text as="label" sx={{ cursor: 'pointer' }}>
      <Box
        as="input"
        type="radio"
        checked={selectedOwnership === row.original.ownershipId}
        onChange={event => {
          const { checked } = event.target;
          selectOwnership(checked ? row.original.ownershipId : undefined);
        }}
        sx={{ cursor: 'pointer' }}
        mr={2}
      />
      {value}
      {sameAsSeller && (isLeasing ? ` (Ägare)` : ` (Säljare)`)}
      {row.original.ownerBlocked && (
        <Box as="span" color="red" ml="1">
          (Spärrat)
        </Box>
      )}
    </Text>
  );
};

export default OwnershipCell;

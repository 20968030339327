import Icon from '@primitives/Icon';
import { ChevronDown, ChevronLeft, ChevronRight } from '@styled-icons/fa-solid';
import React from 'react';
import styled from 'styled-components';

export const BaseArrow = styled(Icon)<{ up: string }>`
  transition: transform 0.25s ease;
  animation-fill-mode: forwards;
  transform: rotate(${p => (p.up === 'true' ? 180 : 0)}deg);
`;

// Overridden path from version 4.8 of styled-icons package
export const ArrowDownSVG = styled(ChevronDown)`
  path {
    fill: currentcolor;
    d: path(
      'M207.029 381.476 12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'
    );
  }
`;

export const ArrowLeftSVG = styled(ChevronLeft)`
  path {
    fill: currentcolor;
    d: path(
      'M34.52 239.03 228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 .36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z'
    );
  }
`;

export const ArrowRightSVG = styled(ChevronRight)`
  path {
    fill: currentcolor;
    d: path(
      'M285.476 272.971 91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'
    );
  )
`;

type IconParameters = Parameters<typeof Icon>;

type Props = Omit<IconParameters[0], 'up'> & {
  up?: boolean;
};

export const Arrow = ({ up = false, ...props }: Props): JSX.Element => (
  <BaseArrow
    size="12px"
    color="blue"
    up={up ? 'true' : 'false'}
    as={ArrowDownSVG}
    {...props}
  />
);

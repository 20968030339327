// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SportInfoLicenseHolderStatistics,
    SportInfoLicenseHolderStatisticsFromJSON,
    SportInfoLicenseHolderStatisticsToJSON,
} from '../models';

export interface GetDriverStatisticsRequest {
    driverId: number;
}

export interface GetTrainerStatisticsRequest {
    trainerId: number;
}


/**
 * Get statistics for a driver/rider.
 */
function getDriverStatisticsRaw<T>(requestParameters: GetDriverStatisticsRequest, requestConfig: runtime.TypedQueryConfig<T, SportInfoLicenseHolderStatistics> = {}): QueryConfig<T> {
    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
        throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling getDriverStatistics.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/drivers/{driverId}/statistics`.replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SportInfoLicenseHolderStatisticsFromJSON(body), text);
    }

    return config;
}

/**
* Get statistics for a driver/rider.
*/
export function getDriverStatistics<T>(requestParameters: GetDriverStatisticsRequest, requestConfig?: runtime.TypedQueryConfig<T, SportInfoLicenseHolderStatistics>): QueryConfig<T> {
    return getDriverStatisticsRaw(requestParameters, requestConfig);
}

/**
 * Get statistics for a trainer.
 */
function getTrainerStatisticsRaw<T>(requestParameters: GetTrainerStatisticsRequest, requestConfig: runtime.TypedQueryConfig<T, SportInfoLicenseHolderStatistics> = {}): QueryConfig<T> {
    if (requestParameters.trainerId === null || requestParameters.trainerId === undefined) {
        throw new runtime.RequiredError('trainerId','Required parameter requestParameters.trainerId was null or undefined when calling getTrainerStatistics.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/trainers/{trainerId}/statistics`.replace(`{${"trainerId"}}`, encodeURIComponent(String(requestParameters.trainerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SportInfoLicenseHolderStatisticsFromJSON(body), text);
    }

    return config;
}

/**
* Get statistics for a trainer.
*/
export function getTrainerStatistics<T>(requestParameters: GetTrainerStatisticsRequest, requestConfig?: runtime.TypedQueryConfig<T, SportInfoLicenseHolderStatistics>): QueryConfig<T> {
    return getTrainerStatisticsRaw(requestParameters, requestConfig);
}


import styled from 'styled-components';
import { typography, TypographyProps } from 'styled-system';

import { base, BaseProps } from '../base';

const Input = styled.input<BaseProps & TypographyProps>`
  background-color: ${props => props.theme.colors.white};
  font-weight: 400;
  height: 32px;
  border: 1px solid ${props => props.theme.colors.gray3};
  border-radius: 0;
  padding: 0 4px;
  box-shadow: none !important;

  :disabled {
    background-color: ${props => props.theme.colors.gray2};
    :hover {
      cursor: not-allowed;
    }
  }
  &:focus {
    outline: none;
  }

  ${base(typography)};
`;

Input.defaultProps = {
  width: '100%',
  display: 'inline-block',
};

export default Input;

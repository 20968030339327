import { variant as systemVariant } from 'styled-system';

export const sizeScheme = (): ReturnType<typeof systemVariant> =>
  systemVariant({
    prop: 'sizeScheme',
    variants: {
      large: {
        maxWidth: 'largeMaxWidth',
      },
      medium: {
        maxWidth: 'mediumMaxWidth',
      },
      small: {
        maxWidth: 'smallMaxWidth',
      },
    },
  });

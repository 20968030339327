// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface BankIdUserSigning
 */
export interface BankIdUserSigning  {
    /**
     * The order reference of this BankID order.
     * @type {string}
     * @memberof BankIdUserSigning
     */
    orderReference: string;
    /**
     * The autostart-token that can be sent to the BankID-App to connect it to an ongoing session. Optional to make use of if the user id number was sent, mandatory if not.
     * @type {string}
     * @memberof BankIdUserSigning
     */
    autoStartToken?: string;
    /**
     * The qr code pattern used to generate the animated qr code.
     * @type {string}
     * @memberof BankIdUserSigning
     */
    qrCodePattern?: string;
}

export function BankIdUserSigningFromJSON(json: any): BankIdUserSigning {
    return {
        'orderReference': json['orderReference'],
        'autoStartToken': !exists(json, 'autoStartToken') ? undefined : json['autoStartToken'],
        'qrCodePattern': !exists(json, 'qrCodePattern') ? undefined : json['qrCodePattern'],
    };
}

export function BankIdUserSigningToJSON(value?: BankIdUserSigning): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'orderReference': value.orderReference,
        'autoStartToken': value.autoStartToken,
        'qrCodePattern': value.qrCodePattern,
    };
}



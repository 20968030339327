import {
  EnrolledHorse,
  getTrainerHorsesEnrolledToAnyStakeRace,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../../utils/types';
import useApi from '../../utils/useApi';

type Results = EnrolledHorse[] | undefined;

export interface Entities {
  trainerHorsesEnrolledToStakeRaces?: {
    [key: string]: Results;
  };
}

export default function useGetTrainerHorsesEnrolledToStakeRaces(
  trainerId: number,
  year?: number,
): ApiResponse<Results, Entities> {
  const key = `${trainerId}-${year || 'all'}`;
  const query = getTrainerHorsesEnrolledToAnyStakeRace<Entities>(
    {
      trainerId,
      year,
    },
    {
      transform: responseBody => ({
        trainerHorsesEnrolledToStakeRaces: {
          [key]: responseBody,
        },
      }),
      update: {
        trainerHorsesEnrolledToStakeRaces: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Results =>
    state.entities?.trainerHorsesEnrolledToStakeRaces?.[key];

  return useApi<Results>({
    query,
    selector,
  });
}

import theme from '@main/theme';
import merge from 'lodash/merge';
import React, { ReactNode } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    borderRadius: '5px',
    padding: 0,
    borderColor: theme.colors.gray2,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
};

export interface Props extends Partial<ReactModal.Props> {
  children?: ReactNode;
  closeModal: ReactModal.Props['onRequestClose'];
  open: boolean;
  style?: ReactModal.Styles;
}

// The row below should not be used for storybook, but causes an error if removed
// Will cause a warning in the console about screen readers when using storybook.
if (!process.env.STORYBOOK) {
  Modal.setAppElement('#root');
}
Modal.defaultStyles = {};

export default function PrimitiveModal({
  children,
  closeModal,
  open,
  style,
  ...props
}: Props): JSX.Element {
  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      style={style ? merge(customStyles, style) : customStyles}
      {...props}
    >
      {children}
    </Modal>
  );
}

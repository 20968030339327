// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Covering method is always NA \'Natural\' in gallop
 * @export
 * @interface CoveringMethod
 */
export interface CoveringMethod  {
    /**
     * 
     * @type {string}
     * @memberof CoveringMethod
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CoveringMethod
     */
    description?: string;
}

export function CoveringMethodFromJSON(json: any): CoveringMethod {
    return {
        'code': !exists(json, 'code') ? undefined : json['code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function CoveringMethodToJSON(value?: CoveringMethod): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'code': value.code,
        'description': value.description,
    };
}



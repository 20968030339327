// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    PropositionWithHorses,
    PropositionWithHorsesFromJSON,
    PropositionWithHorsesToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnrollmentReceipt
 */
export interface EnrollmentReceipt  {
    /**
     * 
     * @type {Array<PropositionWithHorses>}
     * @memberof EnrollmentReceipt
     */
    propositionWithHorses: Array<PropositionWithHorses>;
}

export function EnrollmentReceiptFromJSON(json: any): EnrollmentReceipt {
    return {
        'propositionWithHorses': (json['propositionWithHorses'] as Array<any>).map(PropositionWithHorsesFromJSON),
    };
}

export function EnrollmentReceiptToJSON(value?: EnrollmentReceipt): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'propositionWithHorses': (value.propositionWithHorses as Array<any>).map(PropositionWithHorsesToJSON),
    };
}



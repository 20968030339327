// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface CounterParty
 */
export interface CounterParty  {
    /**
     * 
     * @type {boolean}
     * @memberof CounterParty
     */
    underage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterParty
     */
    protectedIdentity?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterParty
     */
    noUserAccount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterParty
     */
    missingBusinessRepresentative?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterParty
     */
    missingSwedishBusinessRepresentative?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterParty
     */
    protectedIdentityBusinessRepresentative?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterParty
     */
    missingOver18BusinessRepresentative?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CounterParty
     */
    missingAccountHolderBusinessRepresentative?: boolean;
}

export function CounterPartyFromJSON(json: any): CounterParty {
    return {
        'underage': !exists(json, 'underage') ? undefined : json['underage'],
        'protectedIdentity': !exists(json, 'protectedIdentity') ? undefined : json['protectedIdentity'],
        'noUserAccount': !exists(json, 'noUserAccount') ? undefined : json['noUserAccount'],
        'missingBusinessRepresentative': !exists(json, 'missingBusinessRepresentative') ? undefined : json['missingBusinessRepresentative'],
        'missingSwedishBusinessRepresentative': !exists(json, 'missingSwedishBusinessRepresentative') ? undefined : json['missingSwedishBusinessRepresentative'],
        'protectedIdentityBusinessRepresentative': !exists(json, 'protectedIdentityBusinessRepresentative') ? undefined : json['protectedIdentityBusinessRepresentative'],
        'missingOver18BusinessRepresentative': !exists(json, 'missingOver18BusinessRepresentative') ? undefined : json['missingOver18BusinessRepresentative'],
        'missingAccountHolderBusinessRepresentative': !exists(json, 'missingAccountHolderBusinessRepresentative') ? undefined : json['missingAccountHolderBusinessRepresentative'],
    };
}

export function CounterPartyToJSON(value?: CounterParty): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'underage': value.underage,
        'protectedIdentity': value.protectedIdentity,
        'noUserAccount': value.noUserAccount,
        'missingBusinessRepresentative': value.missingBusinessRepresentative,
        'missingSwedishBusinessRepresentative': value.missingSwedishBusinessRepresentative,
        'protectedIdentityBusinessRepresentative': value.protectedIdentityBusinessRepresentative,
        'missingOver18BusinessRepresentative': value.missingOver18BusinessRepresentative,
        'missingAccountHolderBusinessRepresentative': value.missingAccountHolderBusinessRepresentative,
    };
}



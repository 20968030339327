import ImportantMessageFromExternalSlugg from '@components/ImportantMessageFromExternalSlugg';
import { PageLayout } from '@components/PageLayouts';
import PDFLinkWithIconFromExternalSlugg from '@components/PDFLinkWithIconFromExternalSlugg';
import withUser from '@hoc/withUser';
import roleEnum from '@utils/roleEnum';
import React from 'react';
import {
  generatePath,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import Ongoing from '../Ongoing';
import Scratches from '../Scratches';
import Upcoming from '../Upcoming';
import Filter from '../utils/Filter';

export function StakeRaces(): JSX.Element | null {
  const { path } = useRouteMatch();
  const { section, propositionId } = useParams<{
    section?: string;
    propositionId?: string;
  }>();
  const { pathname } = useLocation();

  const isOngoingSection = section === 'anmalningslistor';

  if (isOngoingSection && propositionId) {
    return <Ongoing />;
  }

  if (section === 'anmalda' && propositionId) {
    return <Scratches />;
  }

  if (!section && propositionId) {
    return <Upcoming accountPage />;
  }

  return (
    <PageLayout
      headline="Mina insatslopp"
      cardOrNoCardWrapperStyling={{
        px: 0,
        mt: ['-1px', , 0],
        pt: [0, , '7.5px'],
      }}
      form={
        <>
          {pathname.includes('anmalningslistor') ? (
            <ImportantMessageFromExternalSlugg
              slug={'insatslopp-anmalningslistor-viktigt-meddelande'}
              mt={[3, , 0]}
            />
          ) : undefined}
          {!isOngoingSection && <Filter />}
        </>
      }
      background={['white', , 'inherit']}
      singleCard={isOngoingSection}
      tabs={[
        {
          text: 'Anmäl till insatslopp',
          to: generatePath(path),
          exact: true,
        },
        {
          text: 'Anmälda till insatslopp',
          to: generatePath(path, {
            section: 'anmalda',
          }),
        },
        {
          text: 'Anmälningslistor',
          to: generatePath(path, {
            section: 'anmalningslistor',
          }),
        },
      ]}
    >
      {pathname.includes('anmalningslistor') ? (
        <>
          <PDFLinkWithIconFromExternalSlugg
            alignContent="r"
            slug={'insatslopp-anmalningslistor-pdf'}
            pb={1}
          />
        </>
      ) : undefined}
      {!section && <Upcoming accountPage />}
      {section === 'anmalda' && <Scratches />}
      {isOngoingSection && <Ongoing />}
    </PageLayout>
  );
}

export default withUser(StakeRaces, {
  roles: [roleEnum.Trainer],
  header: 'Insatslopp',
});

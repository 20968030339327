import { base, BaseProps } from '@primitives/base';
import styled from 'styled-components';
import { typography, TypographyProps } from 'styled-system';

interface Props extends BaseProps, TypographyProps {}

const NoLink = styled.span<Props>`
  ${base(typography)}
`;

export default NoLink;

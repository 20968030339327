import 'styled-components';

import {
  useGetTrainerHorsesEnrolledToStakeRace,
  useUnEnrollFromUpcomingStakeRace,
} from '@apis';
import Confirm from '@components/Confirm';
import DataGrid, { Styles as TableStyle } from '@components/DataGrid';
import DirtyFormWarning from '@components/DirtyFormWarning';
import { Checkbox } from '@components/FormikComponents';
import InfoBadge from '@components/InfoBadge';
import { AuthenticatedUser } from '@generated/account/src';
import { EnrolledHorse } from '@generated/propositions/src';
import { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import { SimpleList } from '@primitives/List';
import Loading from '@primitives/Loading';
import Message from '@primitives/Message';
import { TR } from '@primitives/Table';
import { Lead } from '@primitives/Typography';
import { CircleInfo as InfoCircle } from '@styled-icons/fa-solid';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { ForceRequestCallback } from 'redux-query-react';

interface TableFormProps<R extends ForceRequestCallback> {
  horses: EnrolledHorse[];
  propositionId: number;
  refresh: R;
  licenseId: number;
  disabled: boolean;
}

type FormState = {
  scratched: { [key: string]: boolean };
};

function TableForm<R extends ForceRequestCallback>({
  horses,
  propositionId,
  refresh,
  licenseId,
  disabled,
}: TableFormProps<R>): JSX.Element {
  const initialState: FormState = {
    scratched: horses.reduce((acc, oneHorse) => {
      acc[oneHorse.id] = false;
      return acc;
    }, {}),
  };

  const [initialValues, setInitialValues] = useState(initialState);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { action: remove } = useUnEnrollFromUpcomingStakeRace(
    propositionId,
    licenseId,
  );

  return (
    <Formik<FormState>
      initialValues={initialValues}
      onSubmit={async (values, { resetForm, setStatus }) => {
        // Handle screatching
        const actions = await Promise.all(
          Object.keys(values.scratched)
            .filter(key => values.scratched[key])
            .map(key =>
              remove(parseInt(key)).then(resp => ({
                key,
                success: resp.status === 200,
                message: resp.body?.localizedErrorMessage || resp.text,
                horse: horses?.find(horse => horse.id === parseInt(key))?.name,
              })),
            ),
        );

        const errors = actions
          .filter(res => !res.success)
          .map(res => {
            // Reset this value
            delete values.scratched[res.key];
            return `"${res.horse}" ${res.message}`;
          });

        const success = actions
          .filter(res => res.success)
          .map(res => `"${res.horse}" struken`);

        await refresh();

        setInitialValues(values);

        resetForm({
          values,
          status: {
            errors,
            success,
          },
        });

        setInterval(() => {
          setStatus({});
        }, 3000);
      }}
    >
      {({ isSubmitting, isValid, dirty, values, status, submitForm }) => (
        <Form>
          <DirtyFormWarning />
          <Lead fontSize={['text', , 'lead']} mb={3}>
            Stryk häst från detta insatslopp
          </Lead>
          <TableStyle bg="white" noHover>
            <table className="datagrid-variant">
              <thead>
                <tr>
                  <th>Anmälda hästar</th>
                  {!disabled && <th align="center">Stryk</th>}
                </tr>
              </thead>

              <tbody>
                {horses?.map(horse => (
                  <TR
                    key={horse.id}
                    css={
                      values.scratched[horse.id] && {
                        textDecoration: 'line-through',
                      }
                    }
                  >
                    <td>{horse.name}</td>
                    {!disabled && (
                      <td align="center">
                        <Checkbox
                          label={''}
                          name={`scratched.${horse.id}`}
                          style={{ marginRight: 'auto', marginLeft: 'auto' }}
                          disabled={disabled}
                        />
                      </td>
                    )}
                  </TR>
                ))}
              </tbody>
            </table>
          </TableStyle>
          <Flex justifyContent="space-between" mt={2}>
            {confirmOpen && (
              <Confirm
                closeModal={() => setConfirmOpen(false)}
                colorScheme="primary"
                onAction={() => {
                  submitForm();
                  setConfirmOpen(false);
                }}
                open={confirmOpen}
                title={'Är du säker?'}
                description={`Är du säker på att du vill stryka följande hästar: ${Object.keys(
                  values.scratched,
                )
                  .filter(key => values.scratched[key])
                  .map(key => horses.find(horse => `${horse.id}` === key).name)
                  .join(', ')}.`}
              ></Confirm>
            )}
            {disabled ? (
              <InfoBadge
                width={1}
                icon={InfoCircle}
                color="blue"
                message="Det går inte att stryka just nu. Kommande insatstermin är inte aktiverad."
              />
            ) : (
              <Button
                type="button"
                loading={isSubmitting}
                disabled={!isValid || !dirty}
                width={[1, , 'auto']}
                onClick={() => {
                  setConfirmOpen(true);
                }}
              >
                Skicka
              </Button>
            )}
          </Flex>
          {status?.errors?.length > 0 && (
            <Message variant="error" sx={{ mt: 3 }}>
              <SimpleList items={status.errors} />
            </Message>
          )}
          {status?.success?.length > 0 && (
            <Message variant="success" sx={{ mt: 3 }}>
              <SimpleList items={status.success} />
            </Message>
          )}
        </Form>
      )}
    </Formik>
  );
}

interface ScratchFormProps {
  propositionId: number;
  user: AuthenticatedUser;
  disabled?: boolean;
  simple?: boolean;
}

export default function ScratchForm({
  propositionId,
  user,
  disabled = false,
  simple = false,
}: ScratchFormProps): JSX.Element {
  const {
    data: horses,
    loading,
    error,
    status,
    refresh,
  } = useGetTrainerHorsesEnrolledToStakeRace(user.licenseId, propositionId);

  if (status && status !== 200) {
    return <Error>{error}</Error>;
  }

  if (loading && !horses) {
    return <Loading />;
  }

  if (simple) {
    return (
      <DataGrid
        columns={[
          {
            Header: 'Anmälda hästar',
            accessor: 'name',
          },
        ]}
        noHover
        data={horses}
      ></DataGrid>
    );
  }

  return (
    <TableForm
      horses={horses}
      propositionId={propositionId}
      refresh={refresh}
      licenseId={user.licenseId}
      disabled={disabled}
    />
  );
}

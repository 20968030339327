import { getOffspring, OffspringView } from '@generated/horses/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Offspring = OffspringView | undefined;

export interface Entities {
  horseOffspring?: {
    [key: string]: Offspring;
  };
}

const useGetHorseOffspring = (
  horseId: number,
  gendercode: string,
): ApiResponse<Offspring, Entities> => {
  const key = `${horseId}-${gendercode}`;
  const query = getOffspring<Entities>(
    {
      horseId,
      gendercode,
    },
    {
      transform: responseBody => ({
        horseOffspring: {
          [key]: responseBody,
        },
      }),
      update: {
        horseOffspring: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  const selector = (state: State): Offspring =>
    state.entities?.horseOffspring?.[key];

  return useApi<Offspring>({
    query,
    selector,
  });
};

export default useGetHorseOffspring;

import { useGetOwner, useGetOwnerships } from '@apis';
import Content from '@components/ContentBlock/Content';
import { PageLayout } from '@components/PageLayouts';
import Table from '@components/Table';
import { AuthenticatedUser } from '@generated/account/src';
import { Ownership } from '@generated/sportactors/src';
import withUser from '@hoc/withUser';
import Box from '@primitives/Box';
import { ButtonLink } from '@primitives/Button';
import Error from '@primitives/Error';
import { Link } from '@primitives/Link';
import Loading from '@primitives/Loading';
import { H2 } from '@primitives/Typography';
import { OWNER_ROLES } from '@utils/constants';
import { owner } from '@utils/links';
import React from 'react';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { CellProps, Column } from 'react-table';

import ListItem from './ListItem';
import ShareHoldersForm from './ShareHoldersForm';

interface Props {
  user: AuthenticatedUser;
  isActive?: boolean;
  sharesForm?: boolean;
}

const columns = (sharesForm: boolean): Column<Ownership>[] => [
  {
    id: 'name',
    Header: 'Namn',
    accessor: row => row.owner.name,
    Cell: ({ value, row }: CellProps<Ownership>) =>
      sharesForm ? (
        <ButtonLink fontWeight="bold" onClick={() => row.toggleRowExpanded()}>
          {value}
        </ButtonLink>
      ) : (
        <Link to={owner(row.original.owner.id)}>{value}</Link>
      ),
  },
  {
    Header: 'Företrädare',
    accessor: row => row.representative.name,
  },
  {
    Header: 'Ägarfärger',
    Cell: ({ row: { original: row } }: CellProps<Ownership, string>) => {
      const { data, loading, status } = useGetOwner(row.owner.id);

      // Don't snow anything if we get an error
      if (status && status !== 200) {
        return null;
      }

      // Don't show anything while loading or if there is no data
      if (loading || !data) {
        return null;
      }

      return <>{`${data.dress ? data.dress : ''}`}</>;
    },
  },
];

const MyOwnershipsResults = ({
  user,
  isActive = false,
  sharesForm = false,
}: Props): JSX.Element => {
  const { data, loading, status, error } = useGetOwnerships(user.licenseId);

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <Table
      mt={2}
      columns={columns(sharesForm)}
      data={
        isActive
          ? data.filter(item => item.active)
          : data.filter(item => !item.active)
      }
      sortable={!sharesForm}
      expandableBackground="snow"
      renderRowSubComponent={
        sharesForm
          ? ({ row }) => <ShareHoldersForm row={row} user={user} />
          : undefined
      }
      renderListItem={
        sharesForm ? ({ row }) => <ListItem row={row} user={user} /> : undefined
      }
      defaultSort={[
        {
          desc: false,
          id: 'name',
        },
      ]}
    />
  );
};

const MyOwnerships = ({ user }: Props): JSX.Element => {
  const { path } = useRouteMatch();

  const myOwnerships = generatePath(path, {
    tab: 'agarskap',
  });

  const shareHoldersPath = generatePath(path, {
    tab: 'andelsfordelning',
  });

  return (
    <PageLayout
      headline="Ägarskap och andelar"
      singleCard
      tabs={[
        {
          to: myOwnerships,
          text: 'Mina ägarskap',
        },
        {
          to: shareHoldersPath,
          text: 'Andelsfördelning',
        },
      ]}
    >
      <Switch>
        <Route path={myOwnerships}>
          <Box my={4}>
            <Content slug="agare-mina-agarskap" />
          </Box>
          <Box verticalSpacing={6}>
            <Box>
              <H2>Aktiva ägarskap</H2>
              <MyOwnershipsResults user={user} isActive />
            </Box>
            <Box>
              <H2>Inaktiva ägarskap</H2>
              <MyOwnershipsResults user={user} />
            </Box>
          </Box>
        </Route>

        <Route path={shareHoldersPath}>
          <Box my={4}>
            <Content slug="agare-andelsfordelning" />
          </Box>
          <Box verticalSpacing={6}>
            <Box>
              <H2>Aktiva ägarskap</H2>
              <MyOwnershipsResults user={user} isActive sharesForm />
            </Box>
            <Box>
              <H2>Inaktiva ägarskap</H2>
              <MyOwnershipsResults user={user} sharesForm />
            </Box>
          </Box>
        </Route>
        <Route path={generatePath(path)} exact>
          <Redirect to={myOwnerships} />
        </Route>
      </Switch>
    </PageLayout>
  );
};
interface Props {
  user: AuthenticatedUser;
}

export default withUser(MyOwnerships, {
  roles: OWNER_ROLES,
});

import Content from '@components/ContentBlock/Content';
import Box from '@primitives/Box';
import { H3 } from '@primitives/Typography';
import React, { ReactNode } from 'react';

import Triangle from './Triangle';

type BoxParameters = Parameters<typeof Box>;

type Props = {
  header: ReactNode;
  children: ReactNode;
  slug?: string;
  noTriangle?: boolean;
} & BoxParameters[0];

function Step({
  header,
  children,
  slug,
  noTriangle = false,
  ...props
}: Props): JSX.Element {
  return (
    <Box
      backgroundColor="gray1"
      borderStyle="solid"
      borderColor="vistaWhite"
      borderTopWidth={1}
      borderBottomWidth={1}
      borderLeftWidth={[0, null, 1]}
      borderRightWidth={[0, null, 1]}
      p={4}
      mx={[-4, , 0]}
      position="relative"
      {...props}
    >
      <H3 color="gray6" fontSize={['text', , 'lead']}>
        {header}
      </H3>
      {slug && (
        <Content
          as="div"
          color="dim-gray"
          slug={slug}
          mt={3}
          sx={{ fontSize: 'notes' }}
        />
      )}
      <Box mt={4}>{children}</Box>
      {!noTriangle && (
        <Box position="absolute" bottom={0} left="20px">
          <Triangle zIndex={0} borderTopColor="gray2" />
          <Triangle zIndex={1} borderTopColor="gray1" top="-2px" />
        </Box>
      )}
    </Box>
  );
}

export default Step;

// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Address,
    AddressFromJSON,
    AddressToJSON,
    DoctorsCertificate,
    DoctorsCertificateFromJSON,
    DoctorsCertificateToJSON,
} from './';

/**
 * Basic user information.
 * @export
 * @interface BasicUserInformation
 */
export interface BasicUserInformation  {
    /**
     * The first name of the user
     * @type {string}
     * @memberof BasicUserInformation
     */
    firstName: string;
    /**
     * The last name of the user
     * @type {string}
     * @memberof BasicUserInformation
     */
    lastName: string;
    /**
     * The sport system id of the user
     * @type {number}
     * @memberof BasicUserInformation
     */
    licenseId?: number;
    /**
     * The license type, only present for users with a license
     * @type {string}
     * @memberof BasicUserInformation
     */
    licenseType?: string;
    /**
     * The user id number
     * @type {string}
     * @memberof BasicUserInformation
     */
    idNumber?: string;
    /**
     * 
     * @type {Address}
     * @memberof BasicUserInformation
     */
    address?: Address;
    /**
     * The home phone number
     * @type {string}
     * @memberof BasicUserInformation
     */
    homePhoneNumber?: string;
    /**
     * The mobile phone number
     * @type {string}
     * @memberof BasicUserInformation
     */
    mobilePhoneNumber?: string;
    /**
     * The stable phone number
     * @type {string}
     * @memberof BasicUserInformation
     */
    stablePhoneNumber?: string;
    /**
     * 
     * @type {DoctorsCertificate}
     * @memberof BasicUserInformation
     */
    doctorsCertificate?: DoctorsCertificate;
    /**
     * the date when the license ends, only present for users with a license
     * @type {Date}
     * @memberof BasicUserInformation
     */
    licenseValidTo?: Date;
}

export function BasicUserInformationFromJSON(json: any): BasicUserInformation {
    return {
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'licenseId': !exists(json, 'licenseId') ? undefined : json['licenseId'],
        'licenseType': !exists(json, 'licenseType') ? undefined : json['licenseType'],
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'homePhoneNumber': !exists(json, 'homePhoneNumber') ? undefined : json['homePhoneNumber'],
        'mobilePhoneNumber': !exists(json, 'mobilePhoneNumber') ? undefined : json['mobilePhoneNumber'],
        'stablePhoneNumber': !exists(json, 'stablePhoneNumber') ? undefined : json['stablePhoneNumber'],
        'doctorsCertificate': !exists(json, 'doctorsCertificate') ? undefined : DoctorsCertificateFromJSON(json['doctorsCertificate']),
        'licenseValidTo': !exists(json, 'licenseValidTo') ? undefined : parseDateTime(json['licenseValidTo']),
    };
}

export function BasicUserInformationToJSON(value?: BasicUserInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'firstName': value.firstName,
        'lastName': value.lastName,
        'licenseId': value.licenseId,
        'licenseType': value.licenseType,
        'idNumber': value.idNumber,
        'address': AddressToJSON(value.address),
        'homePhoneNumber': value.homePhoneNumber,
        'mobilePhoneNumber': value.mobilePhoneNumber,
        'stablePhoneNumber': value.stablePhoneNumber,
        'doctorsCertificate': DoctorsCertificateToJSON(value.doctorsCertificate),
        'licenseValidTo': value.licenseValidTo === undefined ? undefined : value.licenseValidTo.toLocalDate(),
    };
}



import Menu from '@components/Menu';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { MenuItem } from '../menus';

const Content = styled.div`
  display: none;
  position: absolute;
  min-width: 160px;
  background: ${props => props.theme.colors.white} 0% 0% no-repeat padding-box;
  padding: 10px 8px 8px 8px;
  box-shadow: 0px 2px 4px #00000029;
  opacity: 1;
`;

const DropDownMenu = styled.li`
  display: inline-block;
  &:hover ${Content} {
    display: block;
  }
`;

interface Props {
  children: ReactNode;
  subMenu?: MenuItem[];
}

const Dropdown = ({ subMenu, children }: Props): JSX.Element => (
  <DropDownMenu>
    {children}
    <Content>
      <Menu
        eventTrackingTag="secondary-menu" //TODO behövs denna? Eller är det denna för mobil?
        items={subMenu.map(item => ({
          to: item.href,
          text: item.label,
        }))}
      />
    </Content>
  </DropDownMenu>
);

export default Dropdown;

import { Entities } from '@apis';
import {
  updateHorseOwnershipShareholdersDistribution,
  UpdateHorseOwnershipShareholdersDistributionRequest as RequestParams,
} from '@generated/sportactors/src';
import { OperatorEnum, resetQuery } from '@main/state/queriesReducer';
import { useDispatch } from 'react-redux';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../utils/types';
import { useApiMutateWithoutData } from '../utils/useApiMutate';

const useUpdateShareholdersDistribution = (): MutationResponseWithoutData<
  Entities,
  RequestParams
> => {
  const dispatch = useDispatch();
  return useApiMutateWithoutData({
    queryFunc: (args): QueryConfig =>
      updateHorseOwnershipShareholdersDistribution(args),
    queryKey: `updateShareholdersDistribution`,
    onSuccess: () => {
      dispatch(
        resetQuery('ownershipInvoiceTransactions', OperatorEnum.STARTS_WITH),
      );
    },
  });
};

export default useUpdateShareholdersDistribution;

import {
  ErrorResponse,
  insertTransactions,
  InsertTransactionsRequest,
} from '@generated/tds/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

type Entities = State['entities'];
type ActionParams = InsertTransactionsRequest['transaction'];

const useInsertTransactions = (
  licenseId: number,
): MutationResponse<ErrorResponse | undefined, Entities, ActionParams> => {
  const queryFunc = (transaction: ActionParams): QueryConfig<Entities> =>
    insertTransactions<Entities>({
      licenseId,
      transaction,
    });

  const selector = (state: State): ErrorResponse | undefined =>
    state.entities?.insertTransactions?.[licenseId];

  return useApiMutate<ErrorResponse | undefined, ActionParams>({
    queryFunc,
    selector,
    queryKey: 'insertTransactions',
  });
};

export default useInsertTransactions;

import {
  DeclaredEntry,
  getHorseDeclaredForStart,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Results = DeclaredEntry[] | undefined;

export interface Entities {
  startDeclarations?: {
    [trainerId: number]: Results;
  };
}

export default function useGetHorsesDeclaredForStart(
  trainerId: number,
): ApiResponse<Results, Entities> {
  const query = getHorseDeclaredForStart<Entities>(
    {
      trainerId,
    },
    {
      queryKey: `startDeclarations:${trainerId}`,
      transform: responseBody => ({
        startDeclarations: {
          [trainerId]: responseBody,
        },
      }),
      update: {
        startDeclarations: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Results =>
    state.entities?.startDeclarations?.[trainerId];

  return useApi<Results>({
    query,
    selector,
  });
}

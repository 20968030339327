import { Entities } from '@apis';
import { deleteFavorite } from '@generated/user/src';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../utils/types';
import { useApiMutateWithoutData } from '../utils/useApiMutate';

const useDeleteFavorite = (
  licenseId: number,
): MutationResponseWithoutData<Entities, number> =>
  useApiMutateWithoutData({
    queryFunc: (id: number): QueryConfig<Entities> =>
      deleteFavorite(
        {
          licenseId,
          id,
        },
        {
          optimisticUpdate: {
            favorites: prev => ({
              ...prev,
              [licenseId]: prev[licenseId].filter(item => item.id !== id),
            }),
          },
        },
      ),
    queryKey: 'deleteFavorite',
  });

export default useDeleteFavorite;

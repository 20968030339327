import Card from '@components/Card';
import Box from '@primitives/Box';
import Icon from '@primitives/Icon';
import React, { ReactNode } from 'react';
import { StyledIcon } from 'styled-icons/types';

type CardParameters = Parameters<typeof Card>;

const colorMap = {
  red: {
    color: 'red',
    textColor: 'red',
  },
  blue: {
    color: 'deep-blue',
    textColor: 'dim-gray',
  },
  green: {
    color: 'green',
    textColor: 'dim-gray',
  },
  gray: {
    color: 'dim-gray',
    textColor: 'dim-gray',
  },
} as const;

export type InfoBadgeProps = {
  message: string | ReactNode;
  color: keyof typeof colorMap;
  icon: StyledIcon;
} & CardParameters[0];

export default function InfoBadge({
  message,
  color: baseColor,
  icon,
  width,
  ...props
}: InfoBadgeProps): JSX.Element | null {
  const { color, textColor } = colorMap[baseColor];

  return (
    <Box position="relative" overflow="hidden" borderRadius="5px" width={width}>
      <Card flexDirection="row" p={0} py="7px" pr={2} pl={'5px'} {...props}>
        <Box
          bg={color}
          width="6px"
          position="absolute"
          left="0"
          top="0"
          bottom="0"
        />
        <Box sx={{ display: 'flex', alignItems: 'flext-start' }}>
          <Icon
            size={16}
            color={color}
            mr="8px"
            ml="8px"
            as={icon}
            sx={{ flexShrink: 0 }}
          />
          <Box
            color={textColor || color}
            sx={{
              lineHeight: '15px',
              fontSize: 'small',
              a: {
                textDecoration: 'underline',
                color: textColor,
              },
              p: {
                marginBottom: 0,
              },
            }}
          >
            {message}
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HorsePreCheckedForVaccineRegistration,
    HorsePreCheckedForVaccineRegistrationFromJSON,
    HorsePreCheckedForVaccineRegistrationToJSON,
    VaccinatedHorse,
    VaccinatedHorseFromJSON,
    VaccinatedHorseToJSON,
    Vaccination,
    VaccinationFromJSON,
    VaccinationToJSON,
    Vaccine,
    VaccineFromJSON,
    VaccineToJSON,
    VaccineInjection,
    VaccineInjectionFromJSON,
    VaccineInjectionToJSON,
} from '../models';

export interface GetHorsesWithVaccineRequest {
    licenseId: number;
    vaccinationdate?: Date;
}

export interface GetNumberOfHorseWithVaccineWarningRequest {
    licenseId: number;
}

export interface GetVaccineHistoryRequest {
    licenseId: number;
    horseId: number;
}

export interface PreCheckHorsesForVaccineRegistrationRequest {
    licenseId: number;
    vaccinationdate: Date;
    vaccineid: number;
    horseids: Array<number>;
}

export interface SaveVaccineRegistrationRequest {
    licenseId: number;
    vaccination: Vaccination;
}


/**
 * Get a list of horses with vaccine information for the authenticated license holder. Horses are filtered based on the suggested vaccination date, if any.
 */
function getHorsesWithVaccineRaw<T>(requestParameters: GetHorsesWithVaccineRequest, requestConfig: runtime.TypedQueryConfig<T, Array<VaccinatedHorse>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getHorsesWithVaccine.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.vaccinationdate !== undefined) {
        queryParameters['vaccinationdate'] = (requestParameters.vaccinationdate as any).toLocalDate();
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/vaccines/horses/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(VaccinatedHorseFromJSON), text);
    }

    return config;
}

/**
* Get a list of horses with vaccine information for the authenticated license holder. Horses are filtered based on the suggested vaccination date, if any.
*/
export function getHorsesWithVaccine<T>(requestParameters: GetHorsesWithVaccineRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<VaccinatedHorse>>): QueryConfig<T> {
    return getHorsesWithVaccineRaw(requestParameters, requestConfig);
}

/**
 * Get number of horses that lacks vaccination or has one soon to expire.
 */
function getNumberOfHorseWithVaccineWarningRaw<T>(requestParameters: GetNumberOfHorseWithVaccineWarningRequest, requestConfig: runtime.TypedQueryConfig<T, number> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getNumberOfHorseWithVaccineWarning.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/vaccines/warnedhorses/quantity/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        throw "OH NO";
    }

    return config;
}

/**
* Get number of horses that lacks vaccination or has one soon to expire.
*/
export function getNumberOfHorseWithVaccineWarning<T>(requestParameters: GetNumberOfHorseWithVaccineWarningRequest, requestConfig?: runtime.TypedQueryConfig<T, number>): QueryConfig<T> {
    return getNumberOfHorseWithVaccineWarningRaw(requestParameters, requestConfig);
}

/**
 * Get vaccine history of a horse
 */
function getVaccineHistoryRaw<T>(requestParameters: GetVaccineHistoryRequest, requestConfig: runtime.TypedQueryConfig<T, Array<VaccineInjection>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getVaccineHistory.');
    }

    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling getVaccineHistory.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/vaccines/history/{licenseId}/{horseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(VaccineInjectionFromJSON), text);
    }

    return config;
}

/**
* Get vaccine history of a horse
*/
export function getVaccineHistory<T>(requestParameters: GetVaccineHistoryRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<VaccineInjection>>): QueryConfig<T> {
    return getVaccineHistoryRaw(requestParameters, requestConfig);
}

/**
 * Get a list of vaccines that are allowed for the gallop horses
 */
function getVaccinesRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Array<Vaccine>> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/vaccines`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(VaccineFromJSON), text);
    }

    return config;
}

/**
* Get a list of vaccines that are allowed for the gallop horses
*/
export function getVaccines<T>( requestConfig?: runtime.TypedQueryConfig<T, Array<Vaccine>>): QueryConfig<T> {
    return getVaccinesRaw( requestConfig);
}

/**
 * Get a pre vaccine registration check on the suggested vaccination for all the horsesprovided. Recommended to call this before trying to save vaccinations
 */
function preCheckHorsesForVaccineRegistrationRaw<T>(requestParameters: PreCheckHorsesForVaccineRegistrationRequest, requestConfig: runtime.TypedQueryConfig<T, Array<HorsePreCheckedForVaccineRegistration>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling preCheckHorsesForVaccineRegistration.');
    }

    if (requestParameters.vaccinationdate === null || requestParameters.vaccinationdate === undefined) {
        throw new runtime.RequiredError('vaccinationdate','Required parameter requestParameters.vaccinationdate was null or undefined when calling preCheckHorsesForVaccineRegistration.');
    }

    if (requestParameters.vaccineid === null || requestParameters.vaccineid === undefined) {
        throw new runtime.RequiredError('vaccineid','Required parameter requestParameters.vaccineid was null or undefined when calling preCheckHorsesForVaccineRegistration.');
    }

    if (requestParameters.horseids === null || requestParameters.horseids === undefined) {
        throw new runtime.RequiredError('horseids','Required parameter requestParameters.horseids was null or undefined when calling preCheckHorsesForVaccineRegistration.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.vaccinationdate !== undefined) {
        queryParameters['vaccinationdate'] = (requestParameters.vaccinationdate as any).toLocalDate();
    }


    if (requestParameters.vaccineid !== undefined) {
        queryParameters['vaccineid'] = requestParameters.vaccineid;
    }


    if (requestParameters.horseids) {
        queryParameters['horseids'] = requestParameters.horseids;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/vaccines/horses/prechecked/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(HorsePreCheckedForVaccineRegistrationFromJSON), text);
    }

    return config;
}

/**
* Get a pre vaccine registration check on the suggested vaccination for all the horsesprovided. Recommended to call this before trying to save vaccinations
*/
export function preCheckHorsesForVaccineRegistration<T>(requestParameters: PreCheckHorsesForVaccineRegistrationRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<HorsePreCheckedForVaccineRegistration>>): QueryConfig<T> {
    return preCheckHorsesForVaccineRegistrationRaw(requestParameters, requestConfig);
}

/**
 * Save the suggested vaccination choice for all the horses provided. It\'s recommended to call preCheckHorsesForVaccineRegistration before trying to save any vaccine registrations
 */
function saveVaccineRegistrationRaw<T>(requestParameters: SaveVaccineRegistrationRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling saveVaccineRegistration.');
    }

    if (requestParameters.vaccination === null || requestParameters.vaccination === undefined) {
        throw new runtime.RequiredError('vaccination','Required parameter requestParameters.vaccination was null or undefined when calling saveVaccineRegistration.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/vaccines/horses/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || VaccinationToJSON(requestParameters.vaccination),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Save the suggested vaccination choice for all the horses provided. It\'s recommended to call preCheckHorsesForVaccineRegistration before trying to save any vaccine registrations
*/
export function saveVaccineRegistration<T>(requestParameters: SaveVaccineRegistrationRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return saveVaccineRegistrationRaw(requestParameters, requestConfig);
}


// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SportActorBasicInformation,
    SportActorBasicInformationFromJSON,
    SportActorBasicInformationToJSON,
    SportActorHorse,
    SportActorHorseFromJSON,
    SportActorHorseToJSON,
    SportActorSearchReference,
    SportActorSearchReferenceFromJSON,
    SportActorSearchReferenceToJSON,
} from '../models';

export interface GetOwnershipBasicInformationRequest {
    ownerId: number;
}

export interface GetOwnershipHorsesRequest {
    ownerId: number;
}

export interface SearchOwnershipByLabelRequest {
    query: string;
}


/**
 * Get the basic information of an ownership.
 */
function getOwnershipBasicInformationRaw<T>(requestParameters: GetOwnershipBasicInformationRequest, requestConfig: runtime.TypedQueryConfig<T, SportActorBasicInformation> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getOwnershipBasicInformation.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/basicinformation`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SportActorBasicInformationFromJSON(body), text);
    }

    return config;
}

/**
* Get the basic information of an ownership.
*/
export function getOwnershipBasicInformation<T>(requestParameters: GetOwnershipBasicInformationRequest, requestConfig?: runtime.TypedQueryConfig<T, SportActorBasicInformation>): QueryConfig<T> {
    return getOwnershipBasicInformationRaw(requestParameters, requestConfig);
}

/**
 * Get all ownership horses
 */
function getOwnershipHorsesRaw<T>(requestParameters: GetOwnershipHorsesRequest, requestConfig: runtime.TypedQueryConfig<T, Array<SportActorHorse>> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getOwnershipHorses.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/horses`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(SportActorHorseFromJSON), text);
    }

    return config;
}

/**
* Get all ownership horses
*/
export function getOwnershipHorses<T>(requestParameters: GetOwnershipHorsesRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<SportActorHorse>>): QueryConfig<T> {
    return getOwnershipHorsesRaw(requestParameters, requestConfig);
}

/**
 * Search for horse ownership. At least 2 characters. A trailing wildcard will be added to the query.
 */
function searchOwnershipByLabelRaw<T>(requestParameters: SearchOwnershipByLabelRequest, requestConfig: runtime.TypedQueryConfig<T, Array<SportActorSearchReference>> = {}): QueryConfig<T> {
    if (requestParameters.query === null || requestParameters.query === undefined) {
        throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling searchOwnershipByLabel.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.query !== undefined) {
        queryParameters['query'] = requestParameters.query;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/search`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(SportActorSearchReferenceFromJSON), text);
    }

    return config;
}

/**
* Search for horse ownership. At least 2 characters. A trailing wildcard will be added to the query.
*/
export function searchOwnershipByLabel<T>(requestParameters: SearchOwnershipByLabelRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<SportActorSearchReference>>): QueryConfig<T> {
    return searchOwnershipByLabelRaw(requestParameters, requestConfig);
}


// tslint:disable
/**
 * Web API Auth Service
 * Service for supporting user authentication (issuing JWTs) for accessing secured resources.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * The user roles
 * @export
 * @interface Role
 */
export interface Role  {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    role?: string;
}

export function RoleFromJSON(json: any): Role {
    return {
        'role': !exists(json, 'role') ? undefined : json['role'],
    };
}

export function RoleToJSON(value?: Role): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'role': value.role,
    };
}



import {
  useGetHorsesInOngoingStakeRace,
  useGetStakeRaceWithStakes,
} from '@apis';
import DataGrid from '@components/DataGrid';
import ExpandableHeadline from '@components/ExpandableHeadline';
import ImportantMessageFromExternalSlugg from '@components/ImportantMessageFromExternalSlugg';
import InfoBadge from '@components/InfoBadge';
import { PageLayout } from '@components/PageLayouts';
import PDFLinkWithIconFromExternalSlugg from '@components/PDFLinkWithIconFromExternalSlugg';
import { StakeRaceOngoingPublicHorse } from '@generated/propositions/src';
import useIsMobile from '@hooks/useIsMobile';
import theme from '@main/theme';
import Box, { Container, Item } from '@primitives/Box';
import Error from '@primitives/Error';
import {
  CircleInfo as InfoCircle,
  ClipboardList,
} from '@styled-icons/fa-solid';
import { formatTz } from '@utils/dateUtils';
import { horse, licenseholder } from '@utils/links';
import React from 'react';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import { CellProps, defaultOrderByFn } from 'react-table';

import InfoTexts from '../InfoTexts';
import StakeInfo from '../StakeInfo';

interface Props {
  propositionId: number;
}
export default function Race({ propositionId }: Props): JSX.Element {
  const { data, error, status } = useGetHorsesInOngoingStakeRace(propositionId);

  const {
    data: stakeData,
    error: stakeError,
    status: stakeStatus,
  } = useGetStakeRaceWithStakes(propositionId);

  const { path } = useRouteMatch();
  const isMobile = useIsMobile();

  if (status && status !== 200) {
    return <Error>{error}</Error>;
  }

  if (stakeStatus && stakeStatus !== 200) {
    return <Error>{stakeError}</Error>;
  }

  const header = stakeData?.infoTexts?.find(item => item.header)?.text;

  return (
    <PageLayout
      headline="Anmälningslista"
      singleCard
      pt={[0, , 4]}
      bg={[theme.colors.white, , 'inherit']}
      topRightRemark={
        <PDFLinkWithIconFromExternalSlugg
          slug="insatslopp-anmalningslistor-pdf"
          mt={[3, , 1]}
          mx={[0, , 2]}
        />
      }
      altPossitionTopRightRemark={isMobile}
      contentTop={
        <ImportantMessageFromExternalSlugg
          slug="insatslopp-anmalningslistor-viktigt-meddelande"
          mx={[4, , 2]}
          mt={[3, , 1]}
        />
      }
      backLink={{
        to: generatePath(path, {
          section: 'anmalningslistor',
        }),
      }}
    >
      <ExpandableHeadline
        expandedDefault={false}
        headline={header}
        icon={ClipboardList}
        mb={4}
      >
        <Container gap={36} pb={4}>
          <Item width={[1, , 1 / 2]}>
            {stakeData && <StakeInfo stakes={stakeData.stakes} />}
          </Item>
          <Item width={[1, , 1 / 2]}>
            <Box pt="20px">
              {stakeData && <InfoTexts infoTexts={stakeData.infoTexts} />}
            </Box>
          </Item>
        </Container>
      </ExpandableHeadline>

      <DataGrid
        title={`Listan gäller insats nummer ${data?.stakeNumber}. Antal anmälda hästar: ${
          data?.horses?.length || 0
        } `}
        columns={[
          {
            Header: 'Poäng',
            accessor: 'points',
            id: 'points',
          },
          {
            Header: 'HCP',
            accessor: 'handicap',
          },
          {
            Header: 'Hästnamn',
            accessor: 'name',
            Cell: ({
              value,
              row: { original: row },
            }: CellProps<StakeRaceOngoingPublicHorse, string>) => (
              <Link to={horse(row.id, '/historik')}>{value}</Link>
            ),
          },
          {
            Header: 'Tränare',
            accessor: 'trainerName',
            Cell: ({
              value,
              row: { original: row },
            }: CellProps<StakeRaceOngoingPublicHorse, string>) => (
              <Link to={licenseholder(row.trainerId)}>{value}</Link>
            ),
          },
          {
            Header: 'Eft. anm',
            accessor: 'lateRegistration',
            Cell: ({ value }) => <>{formatTz(value, 'yyyy-MM-dd')}</>,
          },
        ]}
        sortable
        data={data?.horses || []}
        defaultSort={[
          {
            id: 'handicap',
            desc: true,
          },
        ]}
        orderByFn={(arr, funcs, dirs) => {
          const [asc] = dirs;

          const sorted = [...arr].sort(({ original: a }, { original: b }) => {
            // 1. sort by hcp
            let sortValue = b.handicap - a.handicap;

            // 2. sort by points
            if (sortValue === 0) {
              sortValue = b.points - a.points;
            }

            // 3. sort by name
            if (sortValue === 0) {
              sortValue = a.name.localeCompare(b.name);
            }

            return asc ? sortValue : sortValue * -1;
          });

          sorted.forEach((row, index) => {
            row.index = index;
          });

          return defaultOrderByFn(sorted, funcs, dirs);
        }}
      />
      {data?.extraText?.map((extra, i) => (
        <Box key={i} mt={4}>
          <InfoBadge icon={InfoCircle} message={extra.text} color="blue" />
        </Box>
      ))}
    </PageLayout>
  );
}

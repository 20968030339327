import Card from '@components/Card';
import InfoBlock from '@components/InfoBlock';
import { AuthenticatedUser } from '@generated/account/src';
import Box from '@primitives/Box';
import { User } from '@styled-icons/fa-solid';
import React from 'react';

import UserMenu from './UserMenu';
import UserRoles from './UserRoles';

interface Props {
  user: AuthenticatedUser;
}

export default function Sidebar({ user }: Props): JSX.Element | null {
  return (
    <Card p={0}>
      <Box borderBottom="solid 1px" borderBottomColor="gray2" p={[2, , 4]}>
        <InfoBlock
          header={`${user.userInfo.firstName} ${user.userInfo.lastName}`}
          icon={User}
          subHeader={<UserRoles />}
        />
      </Box>
      <Box p={['5px', null, 4]}>
        <UserMenu user={user} homeLink />
      </Box>
    </Card>
  );
}

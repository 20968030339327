// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    RaceDayWithResults,
    RaceDayWithResultsFromJSON,
    RaceDayWithResultsToJSON,
} from '../models';

export interface GetRaceDayWithResultsRequest {
    raceDayId: number;
}


/**
 * Get race results for the latest racing day.
 */
function getLatestRaceDayWithResultsRaw<T>( requestConfig: runtime.TypedQueryConfig<T, number> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/raceinfo/results/latest`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        throw "OH NO";
    }

    return config;
}

/**
* Get race results for the latest racing day.
*/
export function getLatestRaceDayWithResults<T>( requestConfig?: runtime.TypedQueryConfig<T, number>): QueryConfig<T> {
    return getLatestRaceDayWithResultsRaw( requestConfig);
}

/**
 * Get race results for a race day
 */
function getRaceDayWithResultsRaw<T>(requestParameters: GetRaceDayWithResultsRequest, requestConfig: runtime.TypedQueryConfig<T, RaceDayWithResults> = {}): QueryConfig<T> {
    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling getRaceDayWithResults.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/raceinfo/{raceDayId}/results`.replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(RaceDayWithResultsFromJSON(body), text);
    }

    return config;
}

/**
* Get race results for a race day
*/
export function getRaceDayWithResults<T>(requestParameters: GetRaceDayWithResultsRequest, requestConfig?: runtime.TypedQueryConfig<T, RaceDayWithResults>): QueryConfig<T> {
    return getRaceDayWithResultsRaw(requestParameters, requestConfig);
}


import { PartownerErrors } from '@apis';
import Box from '@primitives/Box';
import Message from '@primitives/Message';
import React from 'react';

import SMSNotice from './SMSNotice';

const customErrorMessages = (leasing = false) =>
  ({
    1: 'Ägarskapet finns inte.',
    2: `Personnumret/organisationsnumret har inte registrerat användarkonto.`,
    3: leasing
      ? 'Leasingavtalet kan inte registreras eftersom hyrestagaren inte uppfyller villkoren, kontakta Svensk Galopp för mer information.'
      : 'Ägarskapet kan inte registrera ägarskiften, kontakta Svensk Galopp för mer information.',
    4: leasing
      ? 'Leasingavtalet kan inte registreras digitalt eftersom hyrestagaren är minderårig.'
      : 'Ägarskiftet kan inte genomföras eftersom köparen är minderårig.',
    5: 'Angivet telefonnummer är inte giltigt.',
    6: 'För många telefonnummer angivna, max tillåtet: 19',
    7: 'Delägaren är redan tillagd.',
    8: `Bolagsrepresentant saknas för företaget.`,
    9: `Bolagsrepresentant saknas för företaget.`,
    10: `Bolagsrepresentant för företaget saknar användarkonto.`,
    11: 'Företaget saknar bolagsrepresentant med svenskt personnummer.',
    12: 'Personen saknar svenskt personnummer.',
    13: 'Företaget saknar bolagsrepresentant med svenskt personnummer.',
    404: 'Personnumret/organisationsnumret är inte registrerat hos Svensk Galopp.',
  }) as const;

interface Props {
  setMessage: (m: string) => void;
  errors: Error[];
  isLeasing?: boolean;
}

interface Error {
  errorCode: number;
  idNumber?: string;
  name?: string;
  message?: string;
}

interface CombineErrorsProps {
  errors: PartownerErrors['errors'];
  errorCode?: number;
  status?: number;
  idNumber?: string;
  errorMessage?: string;
}

export function combineErrors({
  errors = {},
  errorCode,
  status,
  idNumber,
  errorMessage,
}: CombineErrorsProps): Error[] {
  const combinedErrors: Error[] = [];

  for (const code of Object.keys(errors)) {
    errors[code].forEach(item => {
      combinedErrors.push({
        errorCode: parseInt(code),
        idNumber: item?.organisationNumber || item?.personalNumber,
        name: item?.name,
      });
    });
  }

  if (errorCode && combinedErrors.length === 0) {
    combinedErrors.push({
      errorCode,
      message: errorMessage,
      idNumber,
    });
  }

  if (status === 404 && combinedErrors.length === 0) {
    combinedErrors.push({
      errorCode: status,
      message: errorMessage,
      idNumber,
    });
  }

  if (errorMessage && combinedErrors.length === 0) {
    combinedErrors.push({
      errorCode: 999,
      message: errorMessage,
      idNumber,
    });
  }

  return combinedErrors;
}

const getErrorFromCode = (
  code: number,
  isLeasing: boolean,
  fallback = 'Okänt fel',
): string => {
  const messages = customErrorMessages(isLeasing);
  if (messages?.[code]) {
    return messages[code];
  }

  return fallback.includes('Failed to find person/s for the given idNumbers')
    ? 'Personnumret/organisationsnumret är inte registrerat hos Svensk Galopp.'
    : fallback;
};

const PersonError = ({
  errors = [],
  setMessage,
  isLeasing = false,
}: Props): JSX.Element => {
  const missingAccountError =
    errors.length > 0 &&
    errors.some(e => [2, 8, 9, 10, 11, 13, 404].includes(e.errorCode));

  if (errors.length === 0) {
    return null;
  }

  return (
    <Box verticalSpacing={3}>
      <Message variant="errorBox">
        {errors.map((e, i) => (
          <div key={`${e.errorCode}-${i}`}>
            {e.name && (
              <>
                <b>{e.name}</b>{' '}
              </>
            )}
            {getErrorFromCode(e.errorCode, isLeasing, e.message)}
          </div>
        ))}
      </Message>

      {missingAccountError && <SMSNotice setMessage={setMessage} />}
    </Box>
  );
};

export default PersonError;

// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    DriverCommission,
    DriverCommissionFromJSON,
    DriverCommissionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Commission
 */
export interface Commission  {
    /**
     * The license id of the user
     * @type {number}
     * @memberof Commission
     */
    licenseId: number;
    /**
     * 
     * @type {DriverCommission}
     * @memberof Commission
     */
    driverCommission: DriverCommission;
}

export function CommissionFromJSON(json: any): Commission {
    return {
        'licenseId': json['licenseId'],
        'driverCommission': DriverCommissionFromJSON(json['driverCommission']),
    };
}

export function CommissionToJSON(value?: Commission): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseId': value.licenseId,
        'driverCommission': DriverCommissionToJSON(value.driverCommission),
    };
}



// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Person,
    PersonFromJSON,
    PersonToJSON,
} from '../models';

export interface GetPersonRequest {
    idNumber: string;
}


/**
 * Get a person(legal/physical) by id Number(organisation number/personal number)
 */
function getPersonRaw<T>(requestParameters: GetPersonRequest, requestConfig: runtime.TypedQueryConfig<T, Person> = {}): QueryConfig<T> {
    if (requestParameters.idNumber === null || requestParameters.idNumber === undefined) {
        throw new runtime.RequiredError('idNumber','Required parameter requestParameters.idNumber was null or undefined when calling getPerson.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.idNumber !== undefined) {
        queryParameters['idNumber'] = requestParameters.idNumber;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/persons`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(PersonFromJSON(body), text);
    }

    return config;
}

/**
* Get a person(legal/physical) by id Number(organisation number/personal number)
*/
export function getPerson<T>(requestParameters: GetPersonRequest, requestConfig?: runtime.TypedQueryConfig<T, Person>): QueryConfig<T> {
    return getPersonRaw(requestParameters, requestConfig);
}

/**
 * Get validated logged-in person(legal/physical)
 */
function getValidatedLoggedInPersonRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Person> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/persons/logged-in`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(PersonFromJSON(body), text);
    }

    return config;
}

/**
* Get validated logged-in person(legal/physical)
*/
export function getValidatedLoggedInPerson<T>( requestConfig?: runtime.TypedQueryConfig<T, Person>): QueryConfig<T> {
    return getValidatedLoggedInPersonRaw( requestConfig);
}


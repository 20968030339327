import { useGetHorsesDeclaredForStart } from '@apis';
import DataGrid from '@components/DataGrid';
import { AuthenticatedUser } from '@generated/account/src';
import { DeclaredEntry } from '@generated/propositions/src';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import Icon, { HorseIcon } from '@primitives/Icon';
import { Pulse } from '@primitives/Loading';
import { Text } from '@primitives/Typography';
import { PenToSquare as Edit } from '@styled-icons/fa-solid';
import { formatTz } from '@utils/dateUtils';
import { horse } from '@utils/links';
import Refresh from '@utils/Refresh';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CellProps, Column } from 'react-table';

import DriverWeight from '../DriverWeight';
import RefetchTimer from '../utils/RefreshTimer';
import DeleteStartDeclaration from './DeleteStartDeclaration';
import OverlayReceipt from './OverlayReceipt';

const columns = (
  refresh: Refresh,
  licenseId: number,
): Column<DeclaredEntry>[] => [
  {
    Header: 'Namn',
    accessor: row => row.horse?.name,
    Cell: ({
      row,
      value,
    }: CellProps<
      DeclaredEntry,
      DeclaredEntry['horse']['name']
    >): JSX.Element => <Link to={horse(row.original.horse?.id)}>{value}</Link>,
    sortType: (a, b) =>
      a.original.horse?.name.localeCompare(b.original.horse?.name),
  },
  {
    Header: 'Bana',
    accessor: 'trackName',
  },
  {
    Header: 'Datum',
    accessor: 'raceDate',
    Cell: ({ value }: CellProps<DeclaredEntry>) => (
      <>{formatTz(value, 'yyMMdd')}</>
    ),
  },
  {
    Header: 'Prop',
    accessor: row => row.propopsitionInfo.number,
  },
  {
    Header: 'Startanmälan',
    accessor: 'registrationDate',
    Cell: ({ value }: CellProps<DeclaredEntry>) => (
      <>{formatTz(value, 'yyMMdd HH:mm')}</>
    ),
  },
  {
    Header: 'Kvitto',
    Cell: OverlayReceipt,
    alignment: 'center',
  },
  {
    Header: 'Redigera',
    Cell: ({ row }: CellProps<DeclaredEntry>): JSX.Element => {
      const history = useHistory();
      const currentDate = new Date();

      return (
        <>
          {row.original.registrationDate > currentDate && (
            <>
              <button
                onClick={() =>
                  history.push(
                    `/minasidor/startanmalan/${row.original.horse.id}/${row.original.propopsitionInfo.id}/uppdatera`,
                  )
                }
              >
                <Icon size={16} color="gray6" as={Edit} />
              </button>
              <RefetchTimer
                refresh={refresh}
                startRegistrationEnd={row.original.registrationDate.getTime()}
              />
            </>
          )}
        </>
      );
    },
    alignment: 'center',
  },
  {
    Header: 'Ta bort',
    Cell: ({ row }: CellProps<DeclaredEntry>): JSX.Element => {
      const registrationDate = new Date(row.original.registrationDate);
      const currentDate = new Date();

      return (
        <>
          {registrationDate > currentDate && (
            <DeleteStartDeclaration
              raceDayId={row.original.raceDayId}
              licenseId={licenseId}
              propositionId={row.original.propopsitionInfo.id}
              horseId={row.original.horse.id}
              refresh={refresh}
            />
          )}
        </>
      );
    },
    alignment: 'center',
  },
];

interface Props {
  user: AuthenticatedUser;
  isMobile: boolean;
}

const MyStartDeclarations = ({ user }: Props): JSX.Element => {
  const { data, loading, status, error, refresh } =
    useGetHorsesDeclaredForStart(user.licenseId);

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading) {
    return <Pulse />;
  }

  return (
    <>
      <DataGrid<DeclaredEntry>
        title={`Du har: ${data.length} ${
          data.length == 1 ? 'startanmäld häst' : 'startanmälda hästar'
        }`}
        noContent={{
          icon: HorseIcon,
          content: 'Du har inga startanmälda hästar.',
        }}
        stickyOnMobile={false}
        columns={columns(refresh, user.licenseId)}
        subRowDoubleVerticalLine={true}
        noHover
        renderRowSubComponent={({ row }) => (
          <>
            {row.original.drivers
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((driver, index) => (
                <Box key={index} width="100%">
                  <Box display="flex" padding="2px">
                    <Text width="20%" fontSize="small">
                      {driver.name}
                    </Text>
                    <Text width="20%" fontSize="small">
                      {driver?.updatedWeight ? (
                        `Ändrad ridvikt: ${driver.updatedWeight} kg`
                      ) : (
                        <>
                          Ridvikt:{' '}
                          <DriverWeight
                            propositionId={row.original.propopsitionInfo.id}
                            propositionWeight={
                              row.original.propopsitionInfo.weight
                            }
                            driverId={driver.id}
                          />
                        </>
                      )}
                    </Text>
                    <Text width="20%" fontSize="small">
                      Propvikt: {row.original.propopsitionInfo.weight} kg
                    </Text>
                  </Box>
                </Box>
              ))}
          </>
        )}
        data={
          data.sort((a, b) => {
            let sortValue: number;
            sortValue = a.raceDate.getTime() - b.raceDate.getTime();

            if (sortValue === 0) {
              sortValue = a.trackName.localeCompare(b.trackName);
            }

            if (sortValue === 0) {
              sortValue = a.propopsitionInfo.number - b.propopsitionInfo.number;
            }

            if (sortValue === 0) {
              sortValue = a.horse.name.localeCompare(b.horse.name);
            }

            return sortValue;
          }) || []
        }
      />
    </>
  );
};

export default MyStartDeclarations;

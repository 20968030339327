import { Entities } from '@apis';
import { Input } from '@components/FormikComponents';
import FormLayout, { FormItem } from '@components/FormLayout';
import { VatRegistration as VR } from '@generated/sportactors/src';
import Button from '@primitives/Button';
import Refresh from '@utils/Refresh';
import { Formik } from 'formik';
import React from 'react';
import { ActionPromiseValue } from 'redux-query';

interface FormState {
  vatNumber: string;
}

interface VatRegistrationFormProps {
  row: VR;
  action: (
    id: number,
    vatNumber: string,
  ) => Promise<ActionPromiseValue<Entities>>;
  refresh: Refresh;
}

const VatRegistrationForm = ({
  row,
  action,
  refresh,
}: VatRegistrationFormProps): JSX.Element => {
  const initialValues = {
    vatNumber: row.vatNumber,
  };

  return (
    <Formik<FormState>
      initialValues={initialValues}
      onSubmit={async (values, { resetForm, setSubmitting, setFieldError }) => {
        const status = await action(row.id, values.vatNumber).then(resp => ({
          success: resp.status === 200,
          message:
            resp.body?.localizedErrorMessage ||
            resp.body?.errorMessage ||
            resp.text,
        }));

        if (status.success) {
          resetForm({ values });

          await refresh();
        } else {
          setSubmitting(false);
          setFieldError('vatNumber', status.message);
        }
      }}
    >
      {({ isSubmitting, dirty }) => (
        <FormLayout boxScheme="clean" flexWrap="nowrap">
          <FormItem variant="normal">
            <Input name="vatNumber" sx={{ width: 'auto' }} />
          </FormItem>
          <FormItem variant="normal" data-no-grow>
            <Button
              disabled={!dirty}
              loading={isSubmitting}
              type="submit"
              width="95px"
              colorScheme="save"
            >
              {dirty ? 'Spara' : 'Sparad'}
            </Button>
          </FormItem>
        </FormLayout>
      )}
    </Formik>
  );
};

export default React.memo(VatRegistrationForm);

import {
  emailReceipt,
  EmailResponseMessage,
} from '@generated/propositions/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

export interface Entities {
  emailEnrollmentReceipt?: EmailResponseMessage;
}

const useEmailEnrollmentReceipt = (
  licenseId: number,
  raceDayId: number,
): MutationResponse<Entities['emailEnrollmentReceipt'], Entities, unknown> => {
  const queryFunc = (): QueryConfig<Entities> =>
    emailReceipt({
      licenseId,
      raceDayId,
    });
  return useApiMutate<Entities['emailEnrollmentReceipt'], unknown>({
    queryFunc,
    selector: (state: State) => state.entities.emailEnrollmentReceipt,
    queryKey: 'emailEnrollmentReceipt',
  });
};

export default useEmailEnrollmentReceipt;

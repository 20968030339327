import { getBreederHorses, SportActorHorse } from '@generated/sportactors/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  breederHorses?: {
    [id: number]: SportActorHorse[];
  };
}

type Horses = SportActorHorse[] | undefined;

const useGetBreederHorses = (
  breederId: number,
): ApiResponse<Horses, Entities> => {
  // 1. Query
  const query = getBreederHorses<Entities>(
    {
      breederId,
    },
    {
      transform: responseBody => ({
        breederHorses: {
          [breederId]: responseBody,
        },
      }),
      update: {
        breederHorses: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  // 2. Selecctor
  const selector = (state: State): Horses =>
    state.entities?.breederHorses?.[breederId];

  return useApi<Horses>({
    query,
    selector,
  });
};

export default useGetBreederHorses;

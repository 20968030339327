import { getOwnerships, Ownership } from '@generated/sportactors/src';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = Ownership[] | undefined;

export interface Entities {
  ownerships?: { [key: string]: Result };
}

const useGetOwnerships = (ownerId: number): ApiResponse<Result, Entities> =>
  useApi({
    query: getOwnerships<Entities>(
      {
        ownerId,
      },
      {
        transform: responseBody => ({
          ownerships: { [ownerId]: responseBody },
        }),
        update: {
          ownerships: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities.ownerships?.[ownerId],
  });

export default useGetOwnerships;

export const checkToDate = (toDate: Date): boolean => {
  const currentDate = new Date();

  if (toDate == undefined) {
    return false;
  }

  if (currentDate <= toDate) {
    return true;
  }
  return false;
};

export const checkFromDate = (fromDate: Date): boolean => {
  const currentDate = new Date();

  if (fromDate == undefined) {
    return false;
  }

  if (currentDate > fromDate) {
    return true;
  }
  return false;
};

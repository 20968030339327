import { getStartList, StartListRaceDay } from '@generated/raceinfo/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  startList?: {
    [key: number]: StartListRaceDay;
  };
}

const useGetStartList = (
  raceDayId: number,
): ApiResponse<StartListRaceDay, Entities> => {
  const query = getStartList<Entities>(
    {
      raceDayId,
    },
    {
      force: true,
      transform: responseBody => ({
        startList: {
          [raceDayId]: responseBody,
        },
      }),
      update: {
        startList: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): StartListRaceDay =>
    state.entities?.startList?.[raceDayId];

  return useApi<StartListRaceDay>({
    query,
    selector,
  });
};

export default useGetStartList;

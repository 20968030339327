// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface UpcomingStakeRaceHorse
 */
export interface UpcomingStakeRaceHorse  {
    /**
     * 
     * @type {number}
     * @memberof UpcomingStakeRaceHorse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UpcomingStakeRaceHorse
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpcomingStakeRaceHorse
     */
    enrolled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpcomingStakeRaceHorse
     */
    startBan: boolean;
    /**
     * Details about the start ban, if any
     * @type {string}
     * @memberof UpcomingStakeRaceHorse
     */
    startBanMessage?: string;
}

export function UpcomingStakeRaceHorseFromJSON(json: any): UpcomingStakeRaceHorse {
    return {
        'id': json['id'],
        'name': json['name'],
        'enrolled': json['enrolled'],
        'startBan': json['startBan'],
        'startBanMessage': !exists(json, 'startBanMessage') ? undefined : json['startBanMessage'],
    };
}

export function UpcomingStakeRaceHorseToJSON(value?: UpcomingStakeRaceHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'enrolled': value.enrolled,
        'startBan': value.startBan,
        'startBanMessage': value.startBanMessage,
    };
}



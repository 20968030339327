import { AuthenticatedUser } from '@generated/account/src';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import EditTransferForm from '../EditTransfer';
import OngoingTransfersList from './OngoingTransfersList';

interface Props {
  user: AuthenticatedUser;
}

const OngoingTransfers = ({ user }: Props): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:transferId(\\d+)`}>
        <EditTransferForm user={user} />
      </Route>
      <Route>
        <OngoingTransfersList user={user} />
      </Route>
    </Switch>
  );
};

export default OngoingTransfers;

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Horse,
    HorseFromJSON,
    HorseToJSON,
    LicenseHolder,
    LicenseHolderFromJSON,
    LicenseHolderToJSON,
    RaceInformation,
    RaceInformationFromJSON,
    RaceInformationToJSON,
    Track,
    TrackFromJSON,
    TrackToJSON,
} from './';

/**
 * 
 * @export
 * @interface ComingStart
 */
export interface ComingStart  {
    /**
     * 
     * @type {Track}
     * @memberof ComingStart
     */
    track: Track;
    /**
     * 
     * @type {RaceInformation}
     * @memberof ComingStart
     */
    raceInformation: RaceInformation;
    /**
     * 
     * @type {string}
     * @memberof ComingStart
     */
    raceType?: string;
    /**
     * 
     * @type {Horse}
     * @memberof ComingStart
     */
    horse: Horse;
    /**
     * 
     * @type {LicenseHolder}
     * @memberof ComingStart
     */
    driver: LicenseHolder;
    /**
     * 
     * @type {LicenseHolder}
     * @memberof ComingStart
     */
    trainer: LicenseHolder;
}

export function ComingStartFromJSON(json: any): ComingStart {
    return {
        'track': TrackFromJSON(json['track']),
        'raceInformation': RaceInformationFromJSON(json['raceInformation']),
        'raceType': !exists(json, 'raceType') ? undefined : json['raceType'],
        'horse': HorseFromJSON(json['horse']),
        'driver': LicenseHolderFromJSON(json['driver']),
        'trainer': LicenseHolderFromJSON(json['trainer']),
    };
}

export function ComingStartToJSON(value?: ComingStart): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'track': TrackToJSON(value.track),
        'raceInformation': RaceInformationToJSON(value.raceInformation),
        'raceType': value.raceType,
        'horse': HorseToJSON(value.horse),
        'driver': LicenseHolderToJSON(value.driver),
        'trainer': LicenseHolderToJSON(value.trainer),
    };
}



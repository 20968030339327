import config from '@config';
import { getIssueTemplate, IssueTemplate } from '@generated/content/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  issueTemplate?: {
    [templateId: number]: IssueTemplate;
  };
}

const useIssueTemplate = (
  templateId: number,
): ApiResponse<IssueTemplate, Entities> => {
  const base = getIssueTemplate<Entities>(
    {
      id: templateId,
    },
    {
      transform: responseBody => ({
        issueTemplate: {
          [templateId]: responseBody,
        },
      }),
      update: {
        issueTemplate: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  const selector = (state: State): IssueTemplate =>
    state.entities?.issueTemplate?.[templateId];

  return useApi<IssueTemplate>({
    query: {
      ...base,
      url: config.isProduction
        ? base.url
        : base.url.replace('/api/', '/api-test/'),
    },
    selector,
    basePath: 'contentapi',
  });
};

export default useIssueTemplate;

import Error from '@components/FormikComponents/Error';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import { Input } from '@primitives/Form';
import { validateInputDate } from '@utils/dateUtils';
import { useField } from 'formik';
import React, { useState } from 'react';

interface Props {
  name: string;
  noError?: boolean;
}

export default function CoveringDatePicker({
  name,
  noError = false,
}: Props): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('');

  const [{ value }, meta, { setValue }] = useField({
    name,
    multiple: true,
    // validate: value => {
    //   if (inputValue && validateInputDate(inputValue)) {
    //     return 'Har du valt ett språng men glömt klicka på lägg till?';
    //   }
    // },
  });

  return (
    <Box horizontalSpacing={2} display="flex">
      <div>
        <Input
          type="date"
          name="selectCoveeringDates"
          onChange={e => {
            const { value } = e.target;
            setInputValue(value);
          }}
          value={inputValue}
          pattern="\d{4}-\d{2}-\d{2}"
          min={`${new Date().getFullYear()}-01-01`}
          max={new Date().toISOString().substr(0, 10)}
        />
        {!noError && meta.touched && meta.error && <Error>{meta.error}</Error>}
      </div>
      <Button
        disabled={!validateInputDate(inputValue) || value?.includes(inputValue)}
        onClick={() => {
          setValue([...value, inputValue]);
          setInputValue('');
        }}
      >
        Lägg till
      </Button>
    </Box>
  );
}

import { useGetPreSelectedDrivers } from '@apis';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import { Option, Select } from '@primitives/Form';
import formatDriver from '@utils/formatDriver';
import { useFormikContext } from 'formik';
import React from 'react';

import { FormState } from '..';

interface Props {
  licenseId: number;
  propositionId: number;
}

const PreSelectedDrivers = ({
  licenseId,
  propositionId,
}: Props): JSX.Element => {
  const { data, loading, status } = useGetPreSelectedDrivers(
    licenseId,
    propositionId,
  );

  const { setFieldValue, values } = useFormikContext<FormState>();

  if (status && status !== 200) {
    return (
      <Error>
        {status} {status}
      </Error>
    );
  }

  if (loading || !data) {
    return (
      <Box>
        <Select label="Mina förvalda ryttare" minWidth="100%"></Select>
      </Box>
    );
  }

  const selectableDrivers = data.filter(
    item => !values.drivers.some(driver => driver.id === item.id),
  );

  return (
    <Box width="100%">
      {selectableDrivers.length > 0 && (
        <Select
          label="Mina förvalda ryttare"
          value={0}
          onChange={e => {
            const driver = selectableDrivers.find(
              element => element.id === parseInt(e.target.value),
            );
            setFieldValue(
              'drivers',
              [
                ...values.drivers,
                {
                  ...driver,
                  mountingPromise: false,
                  sortOrder: values.drivers.length + 1,
                },
              ],
              true,
            );
          }}
          minWidth="100%"
        >
          <Option value="Välj ryttare">Välj ryttare</Option>
          {selectableDrivers.map((item, index) => (
            <Option key={index} value={item.id} disabled={item.suspended}>
              {formatDriver(item)}
            </Option>
          ))}
        </Select>
      )}
    </Box>
  );
};

export default PreSelectedDrivers;

import {
  EntryWeightDetail,
  getEntryWeightDetail,
} from '@generated/raceinfo/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  entryWeightListDetail?: {
    [key: string]: EntryWeightDetail;
  };
}

const useGetEntryWeightPropositionDetail = (
  raceDayId: number,
  propositionId: number,
  force = false,
): ApiResponse<EntryWeightDetail, Entities> => {
  const key = `${raceDayId}-${propositionId}`;

  const query = getEntryWeightDetail<Entities>(
    {
      raceDayId,
      propositionId,
    },
    {
      force,
      transform: responseBody => ({
        entryWeightListDetail: {
          [key]: responseBody,
        },
      }),
      update: {
        entryWeightListDetail: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): EntryWeightDetail =>
    state.entities?.entryWeightListDetail?.[key];

  return useApi<EntryWeightDetail>({
    query,
    selector,
  });
};

export default useGetEntryWeightPropositionDetail;

import CardWithHeader from '@components/CardWithHeader';
import theme from '@main/theme';
import Box from '@primitives/Box';
import { Small } from '@primitives/Typography';
import React from 'react';

import {
  DriverComingStarts,
  LeasingNotes,
  OwnerComingStarts,
  OwnershipTransfers,
  SuspensionStatus,
  TrainerComingStarts,
  TrainerEnrollments,
  UnpaidInvoices,
  VaccineWarnings,
} from './Notes';
import {
  HorsesInTraining,
  OwnedHorses,
  WinsAsOwner,
  WinsAsRider,
  WinsAsTrainer,
} from './Statistics';

const statsComponents = [
  HorsesInTraining,
  OwnedHorses,
  WinsAsTrainer,
  WinsAsRider,
  WinsAsOwner,
];

export const notesComponents = [
  SuspensionStatus,
  TrainerEnrollments,
  TrainerComingStarts,
  VaccineWarnings,
  DriverComingStarts,
  OwnerComingStarts,
  UnpaidInvoices,
  OwnershipTransfers,
  LeasingNotes,
];

function Overview(): JSX.Element {
  return (
    <CardWithHeader header="Snabbkollen" position="relative">
      <Box width={1} pb={2} px={2}>
        {notesComponents.map((Component, i) => (
          <Component key={i} />
        ))}
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        borderTop={`solid 1px ${theme.colors.gray2}`}
        mx={-3}
        alignItems="flex-end"
        sx={{
          '> div': {
            width: ['50%', , '25%'],
          },
        }}
      >
        {statsComponents.map((Component, i) => (
          <Component key={i} />
        ))}
      </Box>
      <Small
        position="absolute"
        bottom="0"
        right="0"
        fontSize="micro"
        color="dim-gray"
        px={1}
        py={1}
        fontStyle="italic"
      >
        * Statistiken baseras på innevarande år ({new Date().getFullYear()})
      </Small>
    </CardWithHeader>
  );
}

export default Overview;

// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    Driver,
    DriverFromJSON,
    DriverToJSON,
    EntryWeightDetail,
    EntryWeightDetailFromJSON,
    EntryWeightDetailToJSON,
    EntryWeightList,
    EntryWeightListFromJSON,
    EntryWeightListToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    RaceDay,
    RaceDayFromJSON,
    RaceDayToJSON,
} from '../models';

export interface GetDriversForRaceDayRequest {
    raceDayId: number;
    propositionId: number;
}

export interface GetEntryWeightDetailRequest {
    raceDayId: number;
    propositionId: number;
}

export interface GetEntryWeightListRequest {
    raceDayId: number;
}

export interface GetRaceDaysRequest {
    fromracedate: Date;
    tosubmissiondate: Date;
    toracedate?: Date;
}


/**
 * Get available riders on specific race day by raceDayId and propositionId
 */
function getDriversForRaceDayRaw<T>(requestParameters: GetDriversForRaceDayRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Driver>> = {}): QueryConfig<T> {
    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling getDriversForRaceDay.');
    }

    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling getDriversForRaceDay.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/raceinfo/availabledrivers/{raceDayId}/{propositionId}`.replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))).replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(DriverFromJSON), text);
    }

    return config;
}

/**
* Get available riders on specific race day by raceDayId and propositionId
*/
export function getDriversForRaceDay<T>(requestParameters: GetDriversForRaceDayRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Driver>>): QueryConfig<T> {
    return getDriversForRaceDayRaw(requestParameters, requestConfig);
}

/**
 * Get entry weight detail of enrolled horses.
 */
function getEntryWeightDetailRaw<T>(requestParameters: GetEntryWeightDetailRequest, requestConfig: runtime.TypedQueryConfig<T, EntryWeightDetail> = {}): QueryConfig<T> {
    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling getEntryWeightDetail.');
    }

    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling getEntryWeightDetail.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/raceinfo/{raceDayId}/entryweightlist/{propositionId}`.replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))).replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EntryWeightDetailFromJSON(body), text);
    }

    return config;
}

/**
* Get entry weight detail of enrolled horses.
*/
export function getEntryWeightDetail<T>(requestParameters: GetEntryWeightDetailRequest, requestConfig?: runtime.TypedQueryConfig<T, EntryWeightDetail>): QueryConfig<T> {
    return getEntryWeightDetailRaw(requestParameters, requestConfig);
}

/**
 * Get race entry list or weight Lists by raceDayId
 */
function getEntryWeightListRaw<T>(requestParameters: GetEntryWeightListRequest, requestConfig: runtime.TypedQueryConfig<T, EntryWeightList> = {}): QueryConfig<T> {
    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling getEntryWeightList.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/raceinfo/{raceDayId}/entryweightlist`.replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EntryWeightListFromJSON(body), text);
    }

    return config;
}

/**
* Get race entry list or weight Lists by raceDayId
*/
export function getEntryWeightList<T>(requestParameters: GetEntryWeightListRequest, requestConfig?: runtime.TypedQueryConfig<T, EntryWeightList>): QueryConfig<T> {
    return getEntryWeightListRaw(requestParameters, requestConfig);
}

/**
 * Get race days matching the search criteria.
 */
function getRaceDaysRaw<T>(requestParameters: GetRaceDaysRequest, requestConfig: runtime.TypedQueryConfig<T, Array<RaceDay>> = {}): QueryConfig<T> {
    if (requestParameters.fromracedate === null || requestParameters.fromracedate === undefined) {
        throw new runtime.RequiredError('fromracedate','Required parameter requestParameters.fromracedate was null or undefined when calling getRaceDays.');
    }

    if (requestParameters.tosubmissiondate === null || requestParameters.tosubmissiondate === undefined) {
        throw new runtime.RequiredError('tosubmissiondate','Required parameter requestParameters.tosubmissiondate was null or undefined when calling getRaceDays.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.fromracedate !== undefined) {
        queryParameters['fromracedate'] = (requestParameters.fromracedate as any).toLocalDate();
    }


    if (requestParameters.toracedate !== undefined) {
        queryParameters['toracedate'] = (requestParameters.toracedate as any).toLocalDate();
    }


    if (requestParameters.tosubmissiondate !== undefined) {
        queryParameters['tosubmissiondate'] = (requestParameters.tosubmissiondate as any).toLocalDate();
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/raceinfo/racedays`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(RaceDayFromJSON), text);
    }

    return config;
}

/**
* Get race days matching the search criteria.
*/
export function getRaceDays<T>(requestParameters: GetRaceDaysRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<RaceDay>>): QueryConfig<T> {
    return getRaceDaysRaw(requestParameters, requestConfig);
}


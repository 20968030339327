import { Select } from '@primitives/Form';
import styled from 'styled-components';

export const PageSelectorWrapper = styled.div`
  margin: auto;
  margin-right: 0;
  display: inline-flex;
`;

export const ShowTotal = styled.div`
  display: inline-flex;
  min-width: 169px;

  svg {
    color: #000;
    top: 6px;
  }
`;

export const StyledText = styled.p`
  white-space: nowrap;
  font-size: ${props => props.theme.fontSizes.text}px;
  color: ${props => props.theme.colors.ralphGray};
`;

export const StyledSelect = styled(Select)`
  min-width: 0px;
  height: auto;
  line-height: inherit;
  border: none;
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.text}px;
  > svg {
    top: 0;
  }
  color: ${props => props.theme.colors.ralphGray};
  padding-left: ${props => props.theme.space[1]}px;
`;

export const PageSelector = styled.button<{ $active?: boolean }>`
  margin: 5px;
  cursor: pointer;
  transition: transform 0.15s ease;
  color: ${props => props.theme.colors.ralphGray};
  &:active {
    color: inherit;
  }
  &:focus {
    outline: none;
  }
  :after {
    display: block;
    bottom: 0;
    width: 100%;
    content: '';
    border-bottom: solid 1px ${props => props.theme.colors.blue};
    transform: scaleX(0);
  }

  ${props =>
    props.$active &&
    `
    color: ${props.theme.colors.blue};
    &:after {
      transform: scaleX(1);
     };
    `}
`;

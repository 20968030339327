import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import {
  BankIdUserAuthentication,
  initialize,
  InitializeRequest,
} from '../../generated/account/src';
import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

export interface Entities {
  bankIdAccountInitialize?: BankIdUserAuthentication;
}

type ActionParams = InitializeRequest['bankIdUserAccount'];

const useBankIdAccountInitialize = (): MutationResponse<
  Entities['bankIdAccountInitialize'],
  Entities,
  ActionParams
> => {
  const selector = (state: State): Entities['bankIdAccountInitialize'] =>
    state.entities.bankIdAccountInitialize;

  const queryFunc = (bankIdUserAccount: ActionParams): QueryConfig<Entities> =>
    initialize<Entities>(
      {
        bankIdUserAccount,
      },
      {
        transform: responseBody => ({ bankIdAccountInitialize: responseBody }),
        update: {
          bankIdAccountInitialize: (prev, next): typeof next => next,
        },
      },
    );

  return useApiMutate<Entities['bankIdAccountInitialize'], ActionParams>({
    queryFunc,
    selector,
    queryKey: 'bankIdAccountInitialize',
  });
};

export default useBankIdAccountInitialize;

// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PartOwnersRequest,
    PartOwnersRequestFromJSON,
    PartOwnersRequestToJSON,
    PartOwnersResponse,
    PartOwnersResponseFromJSON,
    PartOwnersResponseToJSON,
    Person,
    PersonFromJSON,
    PersonToJSON,
} from '../models';

export interface AddPartOwnersRequest {
    licenseId: number;
    partOwnersRequest: PartOwnersRequest;
    serviceType?: AddPartOwnersServiceTypeEnum;
}

export interface CancelTransferRequest {
    licenseId: number;
    transferId: number;
    message?: string;
    serviceType?: CancelTransferServiceTypeEnum;
}

export interface GetPartOwnersRequest {
    licenseId: number;
    transferId: number;
}

export interface RemoveAllPartOwnersRequest {
    licenseId: number;
    transferId: number;
    serviceType?: RemoveAllPartOwnersServiceTypeEnum;
}

export interface RemovePartOwnerRequest {
    licenseId: number;
    transferId: number;
    partOwnerId: number;
    serviceType?: RemovePartOwnerServiceTypeEnum;
}

export interface SetPartOwnersFromExistingOwnershipRequest {
    licenseId: number;
    transferId: number;
    ownershipId: number;
    serviceType?: SetPartOwnersFromExistingOwnershipServiceTypeEnum;
}


/**
 * Add part owners.
 */
function addPartOwnersRaw<T>(requestParameters: AddPartOwnersRequest, requestConfig: runtime.TypedQueryConfig<T, PartOwnersResponse> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling addPartOwners.');
    }

    if (requestParameters.partOwnersRequest === null || requestParameters.partOwnersRequest === undefined) {
        throw new runtime.RequiredError('partOwnersRequest','Required parameter requestParameters.partOwnersRequest was null or undefined when calling addPartOwners.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.serviceType !== undefined) {
        queryParameters['serviceType'] = requestParameters.serviceType;
    }

    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/{licenseId}/partowners`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || PartOwnersRequestToJSON(requestParameters.partOwnersRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(PartOwnersResponseFromJSON(body), text);
    }

    return config;
}

/**
* Add part owners.
*/
export function addPartOwners<T>(requestParameters: AddPartOwnersRequest, requestConfig?: runtime.TypedQueryConfig<T, PartOwnersResponse>): QueryConfig<T> {
    return addPartOwnersRaw(requestParameters, requestConfig);
}

/**
 * Cancel an ongoing transfer for a buyer representative.
 */
function cancelTransferRaw<T>(requestParameters: CancelTransferRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling cancelTransfer.');
    }

    if (requestParameters.transferId === null || requestParameters.transferId === undefined) {
        throw new runtime.RequiredError('transferId','Required parameter requestParameters.transferId was null or undefined when calling cancelTransfer.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.message !== undefined) {
        queryParameters['message'] = requestParameters.message;
    }


    if (requestParameters.serviceType !== undefined) {
        queryParameters['serviceType'] = requestParameters.serviceType;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/{licenseId}/{transferId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"transferId"}}`, encodeURIComponent(String(requestParameters.transferId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Cancel an ongoing transfer for a buyer representative.
*/
export function cancelTransfer<T>(requestParameters: CancelTransferRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return cancelTransferRaw(requestParameters, requestConfig);
}

/**
 * Get part owners.
 */
function getPartOwnersRaw<T>(requestParameters: GetPartOwnersRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Person>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getPartOwners.');
    }

    if (requestParameters.transferId === null || requestParameters.transferId === undefined) {
        throw new runtime.RequiredError('transferId','Required parameter requestParameters.transferId was null or undefined when calling getPartOwners.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.transferId !== undefined) {
        queryParameters['transferId'] = requestParameters.transferId;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/{licenseId}/partowners`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(PersonFromJSON), text);
    }

    return config;
}

/**
* Get part owners.
*/
export function getPartOwners<T>(requestParameters: GetPartOwnersRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Person>>): QueryConfig<T> {
    return getPartOwnersRaw(requestParameters, requestConfig);
}

/**
 * Remove all part owners for a given transfer.
 */
function removeAllPartOwnersRaw<T>(requestParameters: RemoveAllPartOwnersRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling removeAllPartOwners.');
    }

    if (requestParameters.transferId === null || requestParameters.transferId === undefined) {
        throw new runtime.RequiredError('transferId','Required parameter requestParameters.transferId was null or undefined when calling removeAllPartOwners.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.transferId !== undefined) {
        queryParameters['transferId'] = requestParameters.transferId;
    }


    if (requestParameters.serviceType !== undefined) {
        queryParameters['serviceType'] = requestParameters.serviceType;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/{licenseId}/partowners/all`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Remove all part owners for a given transfer.
*/
export function removeAllPartOwners<T>(requestParameters: RemoveAllPartOwnersRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return removeAllPartOwnersRaw(requestParameters, requestConfig);
}

/**
 * Remove a part owner from transfer.
 */
function removePartOwnerRaw<T>(requestParameters: RemovePartOwnerRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling removePartOwner.');
    }

    if (requestParameters.transferId === null || requestParameters.transferId === undefined) {
        throw new runtime.RequiredError('transferId','Required parameter requestParameters.transferId was null or undefined when calling removePartOwner.');
    }

    if (requestParameters.partOwnerId === null || requestParameters.partOwnerId === undefined) {
        throw new runtime.RequiredError('partOwnerId','Required parameter requestParameters.partOwnerId was null or undefined when calling removePartOwner.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.transferId !== undefined) {
        queryParameters['transferId'] = requestParameters.transferId;
    }


    if (requestParameters.partOwnerId !== undefined) {
        queryParameters['partOwnerId'] = requestParameters.partOwnerId;
    }


    if (requestParameters.serviceType !== undefined) {
        queryParameters['serviceType'] = requestParameters.serviceType;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/{licenseId}/partowners`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Remove a part owner from transfer.
*/
export function removePartOwner<T>(requestParameters: RemovePartOwnerRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return removePartOwnerRaw(requestParameters, requestConfig);
}

/**
 * Set part owners from existing ownership
 */
function setPartOwnersFromExistingOwnershipRaw<T>(requestParameters: SetPartOwnersFromExistingOwnershipRequest, requestConfig: runtime.TypedQueryConfig<T, PartOwnersResponse> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling setPartOwnersFromExistingOwnership.');
    }

    if (requestParameters.transferId === null || requestParameters.transferId === undefined) {
        throw new runtime.RequiredError('transferId','Required parameter requestParameters.transferId was null or undefined when calling setPartOwnersFromExistingOwnership.');
    }

    if (requestParameters.ownershipId === null || requestParameters.ownershipId === undefined) {
        throw new runtime.RequiredError('ownershipId','Required parameter requestParameters.ownershipId was null or undefined when calling setPartOwnersFromExistingOwnership.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.transferId !== undefined) {
        queryParameters['transferId'] = requestParameters.transferId;
    }


    if (requestParameters.ownershipId !== undefined) {
        queryParameters['ownershipId'] = requestParameters.ownershipId;
    }


    if (requestParameters.serviceType !== undefined) {
        queryParameters['serviceType'] = requestParameters.serviceType;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/{licenseId}/partowners`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(PartOwnersResponseFromJSON(body), text);
    }

    return config;
}

/**
* Set part owners from existing ownership
*/
export function setPartOwnersFromExistingOwnership<T>(requestParameters: SetPartOwnersFromExistingOwnershipRequest, requestConfig?: runtime.TypedQueryConfig<T, PartOwnersResponse>): QueryConfig<T> {
    return setPartOwnersFromExistingOwnershipRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum AddPartOwnersServiceTypeEnum {
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS',
    LEASING = 'LEASING'
}
/**
    * @export
    * @enum {string}
    */
export enum CancelTransferServiceTypeEnum {
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS',
    LEASING = 'LEASING'
}
/**
    * @export
    * @enum {string}
    */
export enum RemoveAllPartOwnersServiceTypeEnum {
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS',
    LEASING = 'LEASING'
}
/**
    * @export
    * @enum {string}
    */
export enum RemovePartOwnerServiceTypeEnum {
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS',
    LEASING = 'LEASING'
}
/**
    * @export
    * @enum {string}
    */
export enum SetPartOwnersFromExistingOwnershipServiceTypeEnum {
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS',
    LEASING = 'LEASING'
}

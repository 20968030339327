import 'styled-components';

import {
  Entities,
  useEnrollHorseToUpcomingStakeRace,
  useGetUpcomingStakeRaceHorses,
  useUnEnrollFromUpcomingStakeRace,
} from '@apis';
import { Styles as TableStyle } from '@components/DataGrid';
import DirtyFormWarning from '@components/DirtyFormWarning';
import { Radio } from '@components/FormikComponents';
import { TD, TH } from '@components/ReactTable';
import { AuthenticatedUser } from '@generated/account/src';
import { UpcomingStakeRaceHorse } from '@generated/propositions/src';
import Box, { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import { SimpleList } from '@primitives/List';
import Loading from '@primitives/Loading';
import Message from '@primitives/Message';
import { TR } from '@primitives/Table';
import { Lead } from '@primitives/Typography';
import { RightToBracket as SignInAlt } from '@styled-icons/fa-solid';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { ActionPromiseValue } from 'redux-query';

interface TableFormProps {
  horses: UpcomingStakeRaceHorse[];
  licenseId: number;
  propositionId: number;
}

type FormState = Record<string, string> & { all?: string[] };

function TableForm({
  horses,
  licenseId,
  propositionId,
}: TableFormProps): JSX.Element {
  const initialState: FormState = {};

  horses.forEach(horse => {
    initialState[horse.id] = horse.enrolled.toString();
  });

  const [initialValues, setInitialValues] = useState(initialState);

  const { action: enroll } = useEnrollHorseToUpcomingStakeRace(
    propositionId,
    licenseId,
  );

  const { action: remove } = useUnEnrollFromUpcomingStakeRace(
    propositionId,
    licenseId,
  );

  return (
    <Formik<FormState>
      initialValues={initialValues}
      onSubmit={async (values, { resetForm, setStatus }) => {
        const handleResponse =
          (key: string) => (resp: ActionPromiseValue<Entities>) => ({
            key,
            success: resp.status === 200,
            message: resp.body?.localizedErrorMessage || resp.text,
            horse: horses?.find(horse => horse.id === parseInt(key))?.name,
          });

        // Handle Enrollments
        const actions = await Promise.all([
          ...Object.keys(values)
            .filter(
              key => values[key] === 'true' && initialValues[key] === 'false',
            )
            .map(key => enroll(parseInt(key)).then(handleResponse(key))),

          ...Object.keys(values)
            .filter(
              key => values[key] === 'false' && initialValues[key] === 'true',
            )
            .map(key => remove(parseInt(key)).then(handleResponse(key))),
        ]);

        const errors = actions
          .filter(res => !res.success)
          .map(res => {
            // Reset this value
            values[res.key] = (!values[res.key]).toString();
            return `"${res.horse}" ${res.message}`;
          });

        const success = actions.every(res => res.success) && 'Sparat!';

        setInitialValues(values);

        resetForm({
          values,
          status: {
            success,
            errors,
          },
        });

        setInterval(() => {
          setStatus({});
        }, 3000);
      }}
    >
      {({ isSubmitting, isValid, dirty, values, status }) => (
        <Form>
          <DirtyFormWarning />
          <Lead fontSize={['text', , 'lead']} mb={3}>
            Anmäl häst till detta insatslopp
          </Lead>
          <Box maxWidth="100%" overflow="auto">
            <TableStyle bg="white" noHover>
              <table className="datagrid-variant">
                <thead>
                  <TR
                    css={{
                      '&&': {
                        th: {
                          verticalAlign: 'middle',
                        },
                      },
                    }}
                  >
                    <th>Tillgängliga hästar</th>
                    <TH
                      cursor="default"
                      css={{
                        '&&': {
                          textAlign: 'center',
                          whiteSpace: 'break-spaces',
                        },
                      }}
                    >
                      <SignInAlt
                        size="14"
                        css={{
                          marginBottom: '2px',
                        }}
                      />{' '}
                      Anmäl
                    </TH>
                    <TH
                      cursor="default"
                      css={{
                        '&&': {
                          textAlign: 'center',
                          whiteSpace: 'break-spaces',
                        },
                      }}
                    >
                      Ej anmäld
                    </TH>
                  </TR>
                </thead>
                <tbody>
                  {horses?.map(horse => (
                    <TR
                      key={horse.id}
                      css={
                        values[horse.id.toString()] === 'true' && {
                          fontWeight: 'bold',
                        }
                      }
                    >
                      <TD
                        css={{
                          '&&': {
                            whiteSpace: 'break-spaces',
                            fontSize: '12px',
                          },
                        }}
                      >
                        {horse.name}
                      </TD>
                      <td align="center">
                        <Radio
                          name={`${horse.id}`}
                          value="true"
                          colorScheme={dirty ? 'gray' : 'green'}
                        />
                      </td>
                      <td align="center">
                        <Radio name={`${horse.id}`} value="false" />
                      </td>
                    </TR>
                  ))}
                </tbody>
              </table>
            </TableStyle>
          </Box>
          <Flex justifyContent="space-between" mt={2}>
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={!isValid || !dirty}
              width={[1, , 'auto']}
            >
              Skicka
            </Button>
          </Flex>
          {status?.errors?.length > 0 && (
            <Message variant="error" sx={{ mt: 3 }}>
              <SimpleList items={status.errors} />
            </Message>
          )}
          {status?.success && (
            <Message variant="success" sx={{ mt: 3 }}>
              {status.success}
            </Message>
          )}
        </Form>
      )}
    </Formik>
  );
}

interface EnrollFormProps {
  propositionId: number;
  user?: AuthenticatedUser;
}

export default function EnrollForm({
  propositionId,
  user,
}: EnrollFormProps): JSX.Element {
  const {
    data: horses,
    loading,
    error,
    status,
  } = useGetUpcomingStakeRaceHorses(propositionId, user.licenseId);

  if (status && status !== 200) {
    return <Error>{error}</Error>;
  }

  if (loading || !horses) {
    return <Loading />;
  }

  return (
    <TableForm
      horses={horses}
      licenseId={user.licenseId}
      propositionId={propositionId}
    />
  );
}

import Card from '@components/Card';
import InfoTable from '@components/InfoTable';
import { OwnershipTransfer } from '@generated/ownership-transfers/src';
import { Arrow } from '@primitives/Arrow';
import Box, { Flex } from '@primitives/Box';
import Message from '@primitives/Message';
import { H4 } from '@primitives/Typography';
import React, { useState } from 'react';
import { Row } from 'react-table';

import SMSNotice from '../../OwnershipTransfers/SMSNotice';

interface Props {
  defaultExpanded?: boolean;
  row: Row<OwnershipTransfer>;
  messages: {
    missingBusinessRepresentative: string;
    protectedIdentityBusinessRepresentative: string;
    missingOver18BusinessRepresentative: string;
    missingSwedishBusinessRepresentative: string;
    missingAccountHolderBusinessRepresentative: string;
    protectedIdentity: string;
    underage: string;
    noUserAccount: string;
  };
  showSMSButtonStatuses: string[];
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

export default function ListItem({
  defaultExpanded,
  row,
  messages,
  showSMSButtonStatuses,
  setMessage,
}: Props): JSX.Element {
  const [open, setOpen] = useState(defaultExpanded);

  return (
    <Card
      p={1}
      pb={0}
      onclick={() => setOpen(!open)}
      backgroundColor={!open ? false : 'very-light-blue'}
    >
      <Box onClick={() => setOpen(!open)} ml={1} mr={1}>
        <Flex justifyContent="left" width="100%">
          <H4 as="h2" color="gray6" mb={2} mt={2} fontWeight="bold">
            {row.original.horse.horseName}
          </H4>
          {
            <Arrow
              ml="auto"
              up={open}
              size={16}
              onclick={() => setOpen(!open)}
            />
          }
        </Flex>
      </Box>
      <InfoTable
        forceColumns={3}
        values={[
          {
            label: 'Ålder',
            value: row.original.horse.horseAge,
          },
          {
            label: 'Kön',
            value: row.original.horse.horseGender.code,
          },
          {
            label: 'Regnr',
            value: row.original.horse.horseRegistrationNumber,
          },
        ]}
      />
      {open && (
        <Box mt={2}>
          <InfoTable
            forceColumns={1}
            values={[
              {
                label: 'Företrädare ägare',
                value: row.original.sellerRepresentative.name,
              },
              {
                label: 'Företrädare hyrestagare',
                value: row.original.buyerRepresentative.name,
              },
            ]}
          />
          <Box verticalSpacing={10} mt={2} mb={2}>
            {Object.entries(messages)
              .filter(([key]) => row.original.counterParty[key])
              .slice(0, 1)
              .map(([key, msg]) => (
                <Message variant="errorBox" key={key}>
                  {msg}
                </Message>
              ))}
            {Object.entries(row.original.counterParty).filter(
              ([key, value]) => value && showSMSButtonStatuses.includes(key),
            ).length > 0 && <SMSNotice setMessage={setMessage} />}
          </Box>
        </Box>
      )}
    </Card>
  );
}

import { BucketLink, getStartlistUrl } from '@generated/raceinfo/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  getStartListUrl?: {
    [key: string]: BucketLink;
  };
}

export default function useGetStartListUrl(
  raceDayId: number,
): ApiResponse<BucketLink, Entities> {
  const query = getStartlistUrl<Entities>(
    {
      raceDayId,
    },
    {
      transform: responseBody => ({
        getStartListUrl: {
          [raceDayId]: responseBody,
        },
      }),
      update: {
        getStartListUrl: (prev, next): typeof next => next,
      },
    },
  );

  const selector = (state: State): BucketLink | undefined =>
    state.entities.getStartListUrl?.[raceDayId];

  return useApi<BucketLink>({
    query,
    selector,
  });
}

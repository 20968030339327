import { deleteTransaction, ErrorResponse } from '@generated/tds/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

export interface Entities {
  deleteTransaction?: ErrorResponse;
}

const useDeleteTransaction = (
  licenseId: number,
): MutationResponse<Entities['deleteTransaction'], Entities, number> => {
  const queryFunc = (id: number): QueryConfig<Entities> =>
    deleteTransaction({ licenseId, id });
  return useApiMutate<Entities['deleteTransaction'], number>({
    queryFunc,
    selector: (state: State) => state.entities.deleteTransaction,
    queryKey: 'deleteTransaction',
  });
};

export default useDeleteTransaction;

import {
  useGetOngoingTransfers,
  useGetPartOwners,
  useGetRepresentativeOwnerships,
  useRemoveAllPartOwners,
  useSetPartOwnersFromExistingOwnership,
} from '@apis';
import Content from '@components/ContentBlock/Content';
import FadedContent from '@components/ContentBlock/Faded';
import DataGrid from '@components/DataGrid';
import InfoBadge from '@components/InfoBadge';
import Step from '@components/Step';
import { AuthenticatedUser } from '@generated/account/src';
import { Ownership } from '@generated/ownership-transfers/src';
import useNumericParams from '@hooks/useNumericParams';
import Box, { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import { BackLink } from '@primitives/Link';
import Pulse from '@primitives/Loading';
import Message from '@primitives/Message';
import { H2, Small } from '@primitives/Typography';
import { TriangleExclamation as ExclamationTriangle } from '@styled-icons/fa-solid';
import scrollToTop from '@utils/scrollToTop';
import React, { useCallback, useMemo, useState } from 'react';
import { CellProps, Column } from 'react-table';

import PersonError, { combineErrors } from '../PersonError';
import { OngoingHorseInfo } from '../TransferHorseInfo';
import CancelTransferConfirm from './CancelTransferConfirm';
import { Ctx } from './Context';
import OwnershipCell from './OwnershipCell';
import PartOwnersConfirm from './PartOwnersConfirm';
import PartOwnersForm from './PartOwnersForm';
import Separator from './Separator';

interface Props {
  user: AuthenticatedUser;
}

const sharedColumns: Column<Ownership>[] = [
  {
    accessor: 'representativeName',
    Header: 'Företrädare',
  },
  {
    accessor: 'partOwners',
    Header: 'Delägare',
    disableSortBy: true,
    Cell: ({ value }: CellProps<Ownership, Ownership['partOwners']>) => (
      <ul>
        {value.map(partOwner => (
          <li key={partOwner.id}>{partOwner.name}</li>
        ))}
      </ul>
    ),
  },
  {
    accessor: 'numberOfHorses',
    Header: 'Antal hästar',
    sortType: ({ original: a }, { original: b }, col, desc) => {
      if (a.numberOfHorses === b.numberOfHorses) {
        return a.ownershipName.localeCompare(b.ownershipName) * (desc ? -1 : 1);
      }

      return a.numberOfHorses - b.numberOfHorses;
    },
  },
];

const columnsWithSelect: Column<Ownership>[] = [
  {
    accessor: 'ownershipName',
    Header: 'Välj ägarskap',
    Cell: OwnershipCell,
    tdStyle: () => ({
      whiteSpace: 'normal',
    }),
  },
  ...sharedColumns,
];

const columns: Column<Ownership>[] = [
  {
    accessor: 'ownershipName',
    Header: 'Ägarskap',
    tdStyle: () => ({
      whiteSpace: 'normal',
    }),
  },
  ...sharedColumns,
];

const EditTransferForm = ({ user }: Props): JSX.Element => {
  const { transferId } = useNumericParams<{ transferId: number }>();

  const { data: partOwners, refresh } = useGetPartOwners(
    user.licenseId,
    transferId,
  );
  const { data, loading, error } = useGetOngoingTransfers(user.licenseId);

  const { data: ownerships, loading: loadingOwnerships } =
    useGetRepresentativeOwnerships({
      licenseId: user.licenseId,
    });

  const [selectedOwnership, selectOwnership] = useState<number | undefined>();
  const [message, setMessage] = useState<string>();
  const [copying, setIsCopying] = useState(false);
  const [_showPartOwnersForm, setShowPartOwnersForm] = useState(false);

  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [showConfirmContractModal, setShowConfirmContractModal] =
    useState(false);

  const {
    fetch: copyPartOwners,
    errors,
    loading: copyingPartowners,
    status,
    error: errorMessage,
  } = useSetPartOwnersFromExistingOwnership(user.licenseId);

  const { fetch: removeAll, loading: isRemoving } = useRemoveAllPartOwners(
    user.licenseId,
  );

  const onNext = useCallback(async () => {
    scrollToTop();
    setShowPartOwnersForm(true);
  }, []);

  const transfer = data?.find(item => item.id === transferId);

  // Get All matching ownerships
  const selectedOwnershipPartOwnerIds = useMemo(
    () =>
      selectedOwnership &&
      ownerships
        .find(o => o.ownershipId === selectedOwnership)
        ?.partOwners?.map(po => po.id),
    [selectedOwnership, ownerships],
  );

  // Get all ownership names that have the same part owners as the selected ownership
  const ownershipName = useMemo(
    () =>
      selectedOwnership &&
      ownerships
        ?.filter(o => {
          const partOwnerIds = o.partOwners.map(po => po.id);
          return (
            partOwnerIds.every(po =>
              selectedOwnershipPartOwnerIds.includes(po),
            ) &&
            selectedOwnershipPartOwnerIds.every(sop =>
              partOwnerIds.includes(sop),
            )
          );
        })
        .map(ownership => ownership.ownershipName)
        .join(', '),
    [selectedOwnership, ownerships, selectedOwnershipPartOwnerIds],
  );

  const selectableData = useMemo(
    () =>
      ownerships?.filter(
        item =>
          !item.isPersonBlocked &&
          !item.isOwnerBlocked &&
          item.representativeId === transfer?.buyerRepresentative.id,
      ) || [],
    [ownerships, transfer?.buyerRepresentative.id],
  );

  const showPartOwnersForm =
    _showPartOwnersForm ||
    (partOwners?.length > 0 && !showConfirmContractModal);

  if (loading || loadingOwnerships) {
    return <Pulse />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  const selectedOwnershipItem = selectableData.find(
    item => item.ownershipId === selectedOwnership,
  );

  const sameAsSeller =
    selectedOwnershipItem?.ownershipId === transfer?.horse.ownershipId;

  return (
    <>
      {showConfirmContractModal && (
        <PartOwnersConfirm
          onClose={async () => {
            await removeAll({ transferId });
            await refresh();
            setShowConfirmContractModal(false);
          }}
          loading={isRemoving}
          transfer={transfer}
          user={user}
          ownershipName={ownershipName}
        />
      )}

      {showConfirmCancelModal && (
        <CancelTransferConfirm
          transfer={transfer}
          user={user}
          onClose={async () => {
            await removeAll({ transferId });
            await refresh();
            setShowConfirmCancelModal(false);
          }}
        />
      )}
      <Box verticalSpacing={4}>
        {message && <Message variant="successBox">{message}</Message>}
        <Box>
          <BackLink to="/minasidor/agarskiften/pagande">
            Alla pågående ägarskiften
          </BackLink>
        </Box>

        <FadedContent
          slug={
            showPartOwnersForm
              ? 'agarskiften-pagaende-agarskiften-ange-delagare'
              : 'agarskiften-pagaende-agarskiften-ange-agarskap'
          }
          sx={{
            lineHeight: '1.5',
          }}
        />

        <OngoingHorseInfo item={transfer} />

        <H2>Ange ägarskap för köpare</H2>

        {showPartOwnersForm ? (
          <PartOwnersForm
            user={user}
            setShowPartOwnersForm={setShowPartOwnersForm}
            setMessage={setMessage}
            transfer={transfer}
          />
        ) : (
          <Box verticalSpacing={4}>
            <Step header="Utgå från ett befintligt ägarskap" noTriangle>
              <Box verticalSpacing={6}>
                <Box verticalSpacing={3}>
                  <Small fontSize="notes" color="dim-gray">
                    <Content slug="agarskiften-pagaende-agarskiften-ange-agarskap-befintligt-foretradare" />
                  </Small>
                  {ownerships && (
                    <Ctx.Provider
                      value={{
                        selectedOwnership,
                        selectOwnership,
                        transfer,
                      }}
                    >
                      <DataGrid<Ownership>
                        title={
                          <>
                            Ägarskap där{' '}
                            <b>{transfer.buyerRepresentative.name}</b> är
                            företrädare
                          </>
                        }
                        noHover={true}
                        stickyOnMobile={false}
                        columns={columnsWithSelect}
                        data={selectableData}
                        sortable
                        defaultSort={[{ id: 'numberOfHorses', desc: true }]}
                      />
                    </Ctx.Provider>
                  )}

                  {transfer.horse.inStartList && (
                    <InfoBadge
                      message={`Observera att ägarskap måste ha hästägardress när hästen ska starta.`}
                      color="red"
                      icon={ExclamationTriangle}
                    />
                  )}

                  <Flex
                    horizontalSpacing={[0, , 3]}
                    justifyContent="flex-start"
                    verticalSpacing={[3, , 0]}
                    flexDirection={['column', , 'row']}
                  >
                    <Button
                      width={[1, , 'auto']}
                      loading={copying}
                      disabled={!selectedOwnership}
                      onClick={async () => {
                        setIsCopying(true);
                        const { success, errors } = await copyPartOwners({
                          transferId,
                          ownershipId: selectedOwnership,
                        });

                        await refresh();
                        setIsCopying(false);

                        if (success && !errors) {
                          scrollToTop();
                          setShowPartOwnersForm(true);
                        }
                      }}
                    >
                      Utgå från valt ägarskap
                    </Button>

                    <Button
                      width={[1, , 'auto']}
                      loading={!copying && copyingPartowners}
                      disabled={!selectedOwnership || sameAsSeller}
                      colorScheme="save"
                      onClick={async () => {
                        const { success, errors } = await copyPartOwners({
                          transferId,
                          ownershipId: selectedOwnership,
                        });

                        if (success && !errors) {
                          await refresh();
                          setShowConfirmContractModal(true);
                        }
                      }}
                    >
                      Registrera ägarskifte med valt ägarskap
                    </Button>
                  </Flex>
                  <PersonError
                    setMessage={setMessage}
                    errors={combineErrors({
                      errors,
                      status,
                      errorMessage,
                    })}
                  />
                </Box>

                <hr />
                <Box verticalSpacing={3}>
                  <Small fontSize="notes" color="dim-gray">
                    <Content slug="agarskiften-pagaende-agarskiften-ange-agarskap-befintligt-delagare" />
                  </Small>
                  {ownerships && (
                    <DataGrid<Ownership>
                      title={
                        <>
                          Ägarskap där{' '}
                          <b>{transfer.buyerRepresentative.name}</b> är delägare
                        </>
                      }
                      noHover={true}
                      stickyOnMobile={false}
                      columns={columns}
                      data={ownerships.filter(
                        item =>
                          !item.isPersonBlocked &&
                          !item.isOwnerBlocked &&
                          item.representativeId !==
                            transfer.buyerRepresentative.id &&
                          item.partOwners.some(
                            p => p.id === transfer.buyerRepresentative.id,
                          ),
                      )}
                      sortable
                      defaultSort={[{ id: 'numberOfHorses', desc: true }]}
                    />
                  )}
                </Box>
              </Box>
            </Step>
            <Separator>ELLER</Separator>
            <Step header="Skapa nytt ägarskap" noTriangle>
              <Box verticalSpacing={3}>
                <Small fontSize="notes" color="dim-gray">
                  <Content slug="agarskiften-pagaende-agarskiften-ange-agarskap-nytt" />
                </Small>
                <Button width={[1, , 'auto']} onClick={onNext}>
                  Skapa ett nytt ägarskap
                </Button>
              </Box>
            </Step>
            <Separator>ELLER</Separator>
            <Step header="Avvisa ägarskifte" noTriangle>
              <Box verticalSpacing={3}>
                <Small fontSize="notes" color="dim-gray">
                  <Content slug="agarskiften-pagaende-agarskiften-ange-agarskap-avvisa" />
                </Small>
                <Button
                  colorScheme="secondary"
                  width={[1, , 'auto']}
                  onClick={() => {
                    setShowConfirmCancelModal(true);
                  }}
                >
                  Avvisa ägarskifte
                </Button>
              </Box>
            </Step>
          </Box>
        )}
      </Box>
    </>
  );
};

export default EditTransferForm;

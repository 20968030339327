// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface CategorySelectionOption
 */
export interface CategorySelectionOption  {
    /**
     * 
     * @type {string}
     * @memberof CategorySelectionOption
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CategorySelectionOption
     */
    value: string;
    /**
     * Mandatory second level options of the category
     * @type {Array<string>}
     * @memberof CategorySelectionOption
     */
    secondLevelAliases: Array<string>;
    /**
     * Optional third level options of the category
     * @type {Array<string>}
     * @memberof CategorySelectionOption
     */
    thirdLevelAliases?: Array<string>;
}

export function CategorySelectionOptionFromJSON(json: any): CategorySelectionOption {
    return {
        'name': json['name'],
        'value': json['value'],
        'secondLevelAliases': json['secondLevelAliases'],
        'thirdLevelAliases': !exists(json, 'thirdLevelAliases') ? undefined : json['thirdLevelAliases'],
    };
}

export function CategorySelectionOptionToJSON(value?: CategorySelectionOption): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'value': value.value,
        'secondLevelAliases': value.secondLevelAliases,
        'thirdLevelAliases': value.thirdLevelAliases,
    };
}



import Content from '@components/ContentBlock/Content';
import RoundIcon from '@components/RoundIcon';
import Box, { Container, Item } from '@primitives/Box';
import { H3, H4, Micro, Text } from '@primitives/Typography';
import { MagnifyingGlass as Search } from '@styled-icons/fa-solid';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { StyledIcon } from 'styled-icons/types';

export interface Props {
  header?: string;
  text?: string;
  icon?: StyledIcon | ((size: string, color: string) => JSX.Element);
  image?: string;
  suffix?: string;
  subHeader?: ReactNode;
  slug?: string;
  content?: ReactNode;
}

interface EmptyStateProps extends Props {
  noPadding?: boolean;
}

const Wrapper = styled(Container)`
  text-align: center;
`;

export default function InfoBlock({
  header,
  text,
  icon,
  image,
  suffix,
  content,
  slug,
  subHeader,
}: Props): React.ReactElement {
  return (
    <Wrapper gap={3.5} my={-3.5} flexDirection="column" alignItems="center">
      {(image || icon) && (
        <Box width={[1]} display="flex" justifyContent="center">
          <RoundIcon icon={icon} image={image} />
        </Box>
      )}
      {header && (
        <Item width={[1]} marginTop="1px" display="block">
          <H3 textAlign="center">{header}</H3>
          {subHeader && (
            <H4 textAlign="center" pt={1}>
              {subHeader}
            </H4>
          )}
        </Item>
      )}

      {text && (
        <Item width={[1]}>
          <Text fontSize="small">{text}</Text>
        </Item>
      )}

      {(content || slug) && (
        <Item width={[1]}>
          <Box
            color="dim-gray"
            sx={{
              fontSize: 'text',
            }}
          >
            {slug ? <Content slug={slug} /> : content}
          </Box>
        </Item>
      )}

      {suffix && (
        <Item width={[1]}>
          <Micro>{suffix}</Micro>
        </Item>
      )}
    </Wrapper>
  );
}

export const EmptyState = ({
  noPadding,
  ...props
}: EmptyStateProps): JSX.Element => (
  <Box maxWidth="320px" mx="auto" my={noPadding ? undefined : [50, , 100]}>
    <InfoBlock {...props} />
  </Box>
);

EmptyState.defaultProps = {
  icon: Search,
  content: 'Just nu finns ingen information att visa',
};

// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface PedigreeDescription
 */
export interface PedigreeDescription  {
    /**
     * 
     * @type {number}
     * @memberof PedigreeDescription
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PedigreeDescription
     */
    head?: string;
    /**
     * 
     * @type {string}
     * @memberof PedigreeDescription
     */
    leftFront?: string;
    /**
     * 
     * @type {string}
     * @memberof PedigreeDescription
     */
    rightFront?: string;
    /**
     * 
     * @type {string}
     * @memberof PedigreeDescription
     */
    leftBack?: string;
    /**
     * 
     * @type {string}
     * @memberof PedigreeDescription
     */
    rightBack?: string;
    /**
     * 
     * @type {string}
     * @memberof PedigreeDescription
     */
    miscellaneous?: string;
    /**
     * 
     * @type {string}
     * @memberof PedigreeDescription
     */
    tattooNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PedigreeDescription
     */
    chipNumber?: string;
}

export function PedigreeDescriptionFromJSON(json: any): PedigreeDescription {
    return {
        'id': json['id'],
        'head': !exists(json, 'head') ? undefined : json['head'],
        'leftFront': !exists(json, 'leftFront') ? undefined : json['leftFront'],
        'rightFront': !exists(json, 'rightFront') ? undefined : json['rightFront'],
        'leftBack': !exists(json, 'leftBack') ? undefined : json['leftBack'],
        'rightBack': !exists(json, 'rightBack') ? undefined : json['rightBack'],
        'miscellaneous': !exists(json, 'miscellaneous') ? undefined : json['miscellaneous'],
        'tattooNumber': !exists(json, 'tattooNumber') ? undefined : json['tattooNumber'],
        'chipNumber': !exists(json, 'chipNumber') ? undefined : json['chipNumber'],
    };
}

export function PedigreeDescriptionToJSON(value?: PedigreeDescription): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'head': value.head,
        'leftFront': value.leftFront,
        'rightFront': value.rightFront,
        'leftBack': value.leftBack,
        'rightBack': value.rightBack,
        'miscellaneous': value.miscellaneous,
        'tattooNumber': value.tattooNumber,
        'chipNumber': value.chipNumber,
    };
}



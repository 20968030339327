import { useContent } from '@apis';
import Box from '@primitives/Box';
import React from 'react';

type BoxProps = Parameters<typeof Box>[0];

type Props = BoxProps & {
  slug: string;
};

function Content(
  { slug, as = 'span', ...props }: Props,
  ref,
): React.ReactElement {
  const { data, loading, status } = useContent(slug);

  if (loading || status !== 200) {
    return null;
  } else {
    return data?.text ? (
      <Box
        ref={ref}
        as={as}
        sx={{
          '> p:last-child': {
            mb: 0,
          },
        }}
        dangerouslySetInnerHTML={{ __html: data.text }}
        {...props}
      />
    ) : null;
  }
}

export default React.forwardRef(Content);

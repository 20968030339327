// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ContentPageResource,
    ContentPageResourceFromJSON,
    ContentPageResourceToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    IssueTemplate,
    IssueTemplateToJSON,
    IssueTemplateFromJSON,
    RaceDayResource,
    RaceDayResourceFromJSON,
    RaceDayResourceToJSON,
} from '../models';

export interface GetContentRequest {
    slug: string;
}

export interface GetRaceDayRequest {
    id: number;
}

export interface GetIssueTemplateRequest {
    id: number;
}


/**
 * Get content page by slug name
 */
function getContentRaw<T>(requestParameters: GetContentRequest, requestConfig: runtime.TypedQueryConfig<T, ContentPageResource> = {}): QueryConfig<T> {
    if (requestParameters.slug === null || requestParameters.slug === undefined) {
        throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getContent.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/api/content/{slug}/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ContentPageResourceFromJSON(body), text);
    }

    return config;
}

/**
* Get content page by slug name
*/
export function getContent<T>(requestParameters: GetContentRequest, requestConfig?: runtime.TypedQueryConfig<T, ContentPageResource>): QueryConfig<T> {
    return getContentRaw(requestParameters, requestConfig);
}

/**
 * Get the latest news
 */
function getNewsRaw<T>( requestConfig: runtime.TypedQueryConfig<T, InlineResponse200> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/api/news/`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(InlineResponse200FromJSON(body), text);
    }

    return config;
}

/**
* Get the latest news
*/
export function getNews<T>( requestConfig?: runtime.TypedQueryConfig<T, InlineResponse200>): QueryConfig<T> {
    return getNewsRaw( requestConfig);
}

/**
 * Get race day by id
 */
function getRaceDayRaw<T>(requestParameters: GetRaceDayRequest, requestConfig: runtime.TypedQueryConfig<T, RaceDayResource> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRaceDay.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/api/race_day/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(RaceDayResourceFromJSON(body), text);
    }

    return config;
}

/**
* Get race day by id
*/
export function getRaceDay<T>(requestParameters: GetRaceDayRequest, requestConfig?: runtime.TypedQueryConfig<T, RaceDayResource>): QueryConfig<T> {
    return getRaceDayRaw(requestParameters, requestConfig);
}

/**
 * Get issue template by id
 */
function getIssueTemplateRaw<T>(requestParameters: GetIssueTemplateRequest, requestConfig: runtime.TypedQueryConfig<T, IssueTemplate> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getIssueTemplate.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/api/issue_template/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(IssueTemplateFromJSON(body), text);
    }

    return config;
}

/**
* Get issue template by id
*/
export function getIssueTemplate<T>(requestParameters: GetIssueTemplateRequest, requestConfig?: runtime.TypedQueryConfig<T, IssueTemplate>): QueryConfig<T> {
    return getIssueTemplateRaw(requestParameters, requestConfig);
}
const isLive = true;
const productionBaseUrl = 'https://api.svenskgalopp.se';
const qaBaseUrl = 'https://qa.api.svenskgalopp.se';

const isProduction = Boolean(process.env.REACT_APP_IS_PRODUCTION);
const isLocalDev = process.env.NODE_ENV === 'development';

const baseUrl = window.location.origin
  ? window.location.origin
  : `${window.location.protocol}/${window.location.host}`;

const config = {
  productionBaseUrl,
  qaBaseUrl,
  isLocalDev,
  isLive,
  isProduction,
  baseUrl,
  publicWebUrl: isProduction
    ? isLive
      ? 'https://www.svenskgalopp.se'
      : 'https://beta.svenskgalopp.se'
    : 'https://svenskgalopp.bricco.se',
  webapiBaseUrl: isProduction
    ? `${productionBaseUrl}/webapi`
    : `${baseUrl}/webapi-qa`,
  contentapiBaseUrl: baseUrl,
  useServiceWorker: false,
  topListYear: Date.now() > new Date('2024-01-23').getTime() ? 2024 : 2023, // change manually each year when the first race has results
} as const;

export default config;

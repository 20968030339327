// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface DriverStatisticsSummary
 */
export interface DriverStatisticsSummary  {
    /**
     * 
     * @type {number}
     * @memberof DriverStatisticsSummary
     */
    licenseId: number;
    /**
     * 
     * @type {number}
     * @memberof DriverStatisticsSummary
     */
    numberOfVictoriesThisYear: number;
}

export function DriverStatisticsSummaryFromJSON(json: any): DriverStatisticsSummary {
    return {
        'licenseId': json['licenseId'],
        'numberOfVictoriesThisYear': json['numberOfVictoriesThisYear'],
    };
}

export function DriverStatisticsSummaryToJSON(value?: DriverStatisticsSummary): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseId': value.licenseId,
        'numberOfVictoriesThisYear': value.numberOfVictoriesThisYear,
    };
}



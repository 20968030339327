import { useGetBreedingYears } from '@apis';
import FormLayout, { FormItem } from '@components/FormLayout';
import Box from '@primitives/Box';
import { Select } from '@primitives/Form';
import React from 'react';

import { useCoveringContext, withContext } from '../context';
import SelectStud from '../SelectStud';
import ResultsList from './ResultsList';

function Results(): JSX.Element {
  const { year, studId, setYear } = useCoveringContext();

  const { data: years } = useGetBreedingYears();

  const yearOptions =
    years?.selectionOptions.map(item => ({
      value: item.value,
      label: item.name,
    })) || [];

  return (
    <Box verticalSpacing={4}>
      <FormLayout as="form" mx={[-4, , 0]} mt={[-4, , 0]}>
        <FormItem width={[1, , 1 / 3]} data-no-grow>
          <Select
            label="Välj betäckningsår"
            options={[
              {
                value: '',
                label: 'Välj år',
              },
              ...yearOptions.slice().reverse(),
            ]}
            onChange={e => {
              setYear(e.target.value ? parseInt(e.target.value) : undefined);
            }}
            value={year}
          />
        </FormItem>
        {year && (
          <FormItem width={[1, , 1 / 3]} data-no-grow>
            <SelectStud />
          </FormItem>
        )}
      </FormLayout>
      {year && studId && <ResultsList />}
    </Box>
  );
}

export default withContext(Results);

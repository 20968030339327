// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SportActorBasicInformation,
    SportActorBasicInformationFromJSON,
    SportActorBasicInformationToJSON,
    SportActorHorse,
    SportActorHorseFromJSON,
    SportActorHorseToJSON,
    Statistics,
    StatisticsFromJSON,
    StatisticsToJSON,
} from '../models';

export interface GetBreederBasicInformationRequest {
    breederId: number;
}

export interface GetBreederHorsesRequest {
    breederId: number;
}

export interface GetBreederStatisticsRequest {
    breederId: number;
}


/**
 * Get the basic information of a breeder.
 */
function getBreederBasicInformationRaw<T>(requestParameters: GetBreederBasicInformationRequest, requestConfig: runtime.TypedQueryConfig<T, SportActorBasicInformation> = {}): QueryConfig<T> {
    if (requestParameters.breederId === null || requestParameters.breederId === undefined) {
        throw new runtime.RequiredError('breederId','Required parameter requestParameters.breederId was null or undefined when calling getBreederBasicInformation.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/breeders/{breederId}/basicinformation`.replace(`{${"breederId"}}`, encodeURIComponent(String(requestParameters.breederId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SportActorBasicInformationFromJSON(body), text);
    }

    return config;
}

/**
* Get the basic information of a breeder.
*/
export function getBreederBasicInformation<T>(requestParameters: GetBreederBasicInformationRequest, requestConfig?: runtime.TypedQueryConfig<T, SportActorBasicInformation>): QueryConfig<T> {
    return getBreederBasicInformationRaw(requestParameters, requestConfig);
}

/**
 * Get all breeder\'s horses
 */
function getBreederHorsesRaw<T>(requestParameters: GetBreederHorsesRequest, requestConfig: runtime.TypedQueryConfig<T, Array<SportActorHorse>> = {}): QueryConfig<T> {
    if (requestParameters.breederId === null || requestParameters.breederId === undefined) {
        throw new runtime.RequiredError('breederId','Required parameter requestParameters.breederId was null or undefined when calling getBreederHorses.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/breeders/{breederId}/horses`.replace(`{${"breederId"}}`, encodeURIComponent(String(requestParameters.breederId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(SportActorHorseFromJSON), text);
    }

    return config;
}

/**
* Get all breeder\'s horses
*/
export function getBreederHorses<T>(requestParameters: GetBreederHorsesRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<SportActorHorse>>): QueryConfig<T> {
    return getBreederHorsesRaw(requestParameters, requestConfig);
}

/**
 * Get breeder statistics
 */
function getBreederStatisticsRaw<T>(requestParameters: GetBreederStatisticsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Statistics>> = {}): QueryConfig<T> {
    if (requestParameters.breederId === null || requestParameters.breederId === undefined) {
        throw new runtime.RequiredError('breederId','Required parameter requestParameters.breederId was null or undefined when calling getBreederStatistics.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/breeders/{breederId}/statistics`.replace(`{${"breederId"}}`, encodeURIComponent(String(requestParameters.breederId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(StatisticsFromJSON), text);
    }

    return config;
}

/**
* Get breeder statistics
*/
export function getBreederStatistics<T>(requestParameters: GetBreederStatisticsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Statistics>>): QueryConfig<T> {
    return getBreederStatisticsRaw(requestParameters, requestConfig);
}


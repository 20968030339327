import config from '@config';
import {
  ActorResource,
  getActor,
  GetActorTypeEnum,
} from '@generated/content/src';
import { State } from '@main/store';

import { ApiResponse } from './utils/types';
import useApi from './utils/useApi';

type Result = ActorResource | undefined;

export interface Entities {
  actors?: { [key: string]: Result };
}

const useActor = (
  type: GetActorTypeEnum,
  id: number,
): ApiResponse<Result, Entities> => {
  const key = `${type}-${id}`;

  const base = getActor<Entities>(
    {
      type,
      id,
    },
    {
      transform: responseBody => ({
        actors: { [key]: responseBody },
      }),
      update: {
        actors: (prev, next): typeof prev => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  const selector = (state: State): Result => state.entities.actors?.[key];

  return useApi<Result>({
    query: {
      ...base,
      url: config.isProduction
        ? base.url
        : base.url.replace('/api/', '/api-test/'),
    },
    selector,
    basePath: 'contentapi',
  });
};

export default useActor;

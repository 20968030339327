// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface PropositionDetail
 */
export interface PropositionDetail  {
    /**
     * 
     * @type {number}
     * @memberof PropositionDetail
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PropositionDetail
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof PropositionDetail
     */
    raceName: string;
    /**
     * 
     * @type {number}
     * @memberof PropositionDetail
     */
    totalSum: number;
    /**
     * 
     * @type {number}
     * @memberof PropositionDetail
     */
    weight: number;
    /**
     * 
     * @type {number}
     * @memberof PropositionDetail
     */
    distance: number;
    /**
     * 
     * @type {string}
     * @memberof PropositionDetail
     */
    surface: string;
    /**
     * 
     * @type {string}
     * @memberof PropositionDetail
     */
    raceType: string;
    /**
     * 
     * @type {string}
     * @memberof PropositionDetail
     */
    propositionRow: string;
}

export function PropositionDetailFromJSON(json: any): PropositionDetail {
    return {
        'id': json['id'],
        'number': json['number'],
        'raceName': json['raceName'],
        'totalSum': json['totalSum'],
        'weight': json['weight'],
        'distance': json['distance'],
        'surface': json['surface'],
        'raceType': json['raceType'],
        'propositionRow': json['propositionRow'],
    };
}

export function PropositionDetailToJSON(value?: PropositionDetail): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'number': value.number,
        'raceName': value.raceName,
        'totalSum': value.totalSum,
        'weight': value.weight,
        'distance': value.distance,
        'surface': value.surface,
        'raceType': value.raceType,
        'propositionRow': value.propositionRow,
    };
}



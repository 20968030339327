// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface OwnershipsStatisticsSummary
 */
export interface OwnershipsStatisticsSummary  {
    /**
     * 
     * @type {number}
     * @memberof OwnershipsStatisticsSummary
     */
    ownerId: number;
    /**
     * 
     * @type {number}
     * @memberof OwnershipsStatisticsSummary
     */
    numberOfVictoriesThisYear: number;
}

export function OwnershipsStatisticsSummaryFromJSON(json: any): OwnershipsStatisticsSummary {
    return {
        'ownerId': json['ownerId'],
        'numberOfVictoriesThisYear': json['numberOfVictoriesThisYear'],
    };
}

export function OwnershipsStatisticsSummaryToJSON(value?: OwnershipsStatisticsSummary): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'ownerId': value.ownerId,
        'numberOfVictoriesThisYear': value.numberOfVictoriesThisYear,
    };
}



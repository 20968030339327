// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Stake
 */
export interface Stake  {
    /**
     * 
     * @type {number}
     * @memberof Stake
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Stake
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof Stake
     */
    fee: number;
    /**
     * 
     * @type {Date}
     * @memberof Stake
     */
    lastSignUpDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Stake
     */
    dueDate: Date;
    /**
     * Whether or not the stake is the next upcoming one
     * @type {boolean}
     * @memberof Stake
     */
    current: boolean;
    /**
     * Marked as ready in the Sport system?
     * @type {boolean}
     * @memberof Stake
     */
    markedAsReady: boolean;
}

export function StakeFromJSON(json: any): Stake {
    return {
        'id': json['id'],
        'number': json['number'],
        'fee': json['fee'],
        'lastSignUpDate': parseDateTime(json['lastSignUpDate']),
        'dueDate': parseDateTime(json['dueDate']),
        'current': json['current'],
        'markedAsReady': json['markedAsReady'],
    };
}

export function StakeToJSON(value?: Stake): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'number': value.number,
        'fee': value.fee,
        'lastSignUpDate': value.lastSignUpDate.toISOString(),
        'dueDate': value.dueDate.toISOString(),
        'current': value.current,
        'markedAsReady': value.markedAsReady,
    };
}



import styled from 'styled-components';
import { typography } from 'styled-system';

import { base, BaseProps } from '../base';

const Error = styled.div.attrs({
  className: 'hidden-print',
  fontSize: 1,
  textAlign: 'left',
})<BaseProps>`
  ${base(typography)};
  padding: 15px 10px;
  background-color: #ffe0e0;
  color: ${props => props.theme.colors.red};
  //border: 1px solid ${props => props.theme.colors.red};
`;

export default Error;

// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Proposition,
    PropositionFromJSON,
    PropositionToJSON,
} from './';

/**
 * 
 * @export
 * @interface EntryWeightList
 */
export interface EntryWeightList  {
    /**
     * 
     * @type {number}
     * @memberof EntryWeightList
     */
    raceDayId: number;
    /**
     * 
     * @type {string}
     * @memberof EntryWeightList
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof EntryWeightList
     */
    trackName: string;
    /**
     * 
     * @type {Date}
     * @memberof EntryWeightList
     */
    registrationDateTime: Date;
    /**
     * 
     * @type {string}
     * @memberof EntryWeightList
     */
    registrationTime: string;
    /**
     * 
     * @type {Date}
     * @memberof EntryWeightList
     */
    startRegistrationDateTime: Date;
    /**
     * 
     * @type {string}
     * @memberof EntryWeightList
     */
    startRegistrationTime: string;
    /**
     * 
     * @type {Date}
     * @memberof EntryWeightList
     */
    weightRegistrationDateTime: Date;
    /**
     * 
     * @type {string}
     * @memberof EntryWeightList
     */
    weightRegistrationTime: string;
    /**
     * 
     * @type {Date}
     * @memberof EntryWeightList
     */
    raceDate: Date;
    /**
     * 
     * @type {Array<Proposition>}
     * @memberof EntryWeightList
     */
    propositions?: Array<Proposition>;
}

export function EntryWeightListFromJSON(json: any): EntryWeightList {
    return {
        'raceDayId': json['raceDayId'],
        'title': json['title'],
        'trackName': json['trackName'],
        'registrationDateTime': parseDateTime(json['registrationDateTime']),
        'registrationTime': json['registrationTime'],
        'startRegistrationDateTime': parseDateTime(json['startRegistrationDateTime']),
        'startRegistrationTime': json['startRegistrationTime'],
        'weightRegistrationDateTime': parseDateTime(json['weightRegistrationDateTime']),
        'weightRegistrationTime': json['weightRegistrationTime'],
        'raceDate': parseDateTime(json['raceDate']),
        'propositions': !exists(json, 'propositions') ? undefined : (json['propositions'] as Array<any>).map(PropositionFromJSON),
    };
}

export function EntryWeightListToJSON(value?: EntryWeightList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'raceDayId': value.raceDayId,
        'title': value.title,
        'trackName': value.trackName,
        'registrationDateTime': value.registrationDateTime.toISOString(),
        'registrationTime': value.registrationTime,
        'startRegistrationDateTime': value.startRegistrationDateTime.toISOString(),
        'startRegistrationTime': value.startRegistrationTime,
        'weightRegistrationDateTime': value.weightRegistrationDateTime.toISOString(),
        'weightRegistrationTime': value.weightRegistrationTime,
        'raceDate': value.raceDate.toLocalDate(),
        'propositions': value.propositions === undefined ? undefined : (value.propositions as Array<any>).map(PropositionToJSON),
    };
}



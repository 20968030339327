import { useDeleteCovering, useGetCoveringList } from '@apis';
import DataGrid from '@components/DataGrid';
import { Covering, Mare } from '@generated/breedings/src';
import { RemoveButton } from '@primitives/Button';
import Icon from '@primitives/Icon';
import { Link } from '@primitives/Link';
import Loading from '@primitives/Loading';
import { PenToSquare as Edit } from '@styled-icons/fa-solid';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { CellProps, Renderer } from 'react-table';

import MareTableCell from '../MareTableCell';

interface Props {
  studId: number;
}

export default function CoveringList({ studId }: Props): JSX.Element {
  const { data, refresh, loading } = useGetCoveringList(studId);
  const { action: remove } = useDeleteCovering(studId);
  const { pathname } = useLocation();

  if (loading && !data) {
    return <Loading mt={5} />;
  }

  return (
    <DataGrid<Covering>
      emptyRow
      columns={[
        {
          Header: 'Språng',
          accessor: 'coveringDates',
          Cell: ({ value }) => (
            <>
              {value.map(date => (
                <div key={date}>{date}</div>
              ))}
            </>
          ),
        },
        {
          Header: 'Sto',
          accessor: 'mare',
          Cell: MareTableCell as Renderer<CellProps<Covering, Mare>>,
        },
        {
          Header: 'Dräktig',
          accessor: 'pregnant',
          alignment: 'center',
          thStyle: () => ({ width: '70px' }),
          Cell: ({ value }) => (
            <>{value === true ? 'Ja' : value === false ? 'Nej' : ''}</>
          ),
        },
        {
          Header: 'Ändra',
          id: 'edit',
          accessor: 'id',
          alignment: 'center',
          thStyle: () => ({ width: '66px' }),
          Cell: ({ value }) => (
            <Link to={`${pathname}/${value}`}>
              <Icon
                as={Edit}
                size={18}
                color="dim-gray"
                sx={{
                  '&:hover': {
                    color: 'gray4',
                  },
                }}
              />
            </Link>
          ),
        },
        {
          Header: 'Ta bort',
          id: 'delete',
          accessor: 'id',
          alignment: 'center',
          thStyle: () => ({ width: '66px' }),
          Cell: ({ value }) => (
            <RemoveButton
              onClick={async () => {
                await remove(value);
                await refresh();
              }}
            />
          ),
        },
      ]}
      data={data || []}
    />
  );
}

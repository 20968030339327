// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Breeder,
    BreederFromJSON,
    BreederToJSON,
    BreederHorse,
    BreederHorseFromJSON,
    BreederHorseToJSON,
    FoalHorse,
    FoalHorseFromJSON,
    FoalHorseToJSON,
} from './';

/**
 * 
 * @export
 * @interface Breeding
 */
export interface Breeding  {
    /**
     * The year when the breeding took place
     * @type {number}
     * @memberof Breeding
     */
    year: number;
    /**
     * The year when the result of the breeding was due, when successful this is the birth year of the foal
     * @type {string}
     * @memberof Breeding
     */
    resultYear: string;
    /**
     * The result of the breeding, for instance a foal
     * @type {string}
     * @memberof Breeding
     */
    result: string;
    /**
     * 
     * @type {FoalHorse}
     * @memberof Breeding
     */
    foalHorse?: FoalHorse;
    /**
     * 
     * @type {BreederHorse}
     * @memberof Breeding
     */
    breederHorse: BreederHorse;
    /**
     * 
     * @type {Breeder}
     * @memberof Breeding
     */
    breeder?: Breeder;
}

export function BreedingFromJSON(json: any): Breeding {
    return {
        'year': json['year'],
        'resultYear': json['resultYear'],
        'result': json['result'],
        'foalHorse': !exists(json, 'foalHorse') ? undefined : FoalHorseFromJSON(json['foalHorse']),
        'breederHorse': BreederHorseFromJSON(json['breederHorse']),
        'breeder': !exists(json, 'breeder') ? undefined : BreederFromJSON(json['breeder']),
    };
}

export function BreedingToJSON(value?: Breeding): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'year': value.year,
        'resultYear': value.resultYear,
        'result': value.result,
        'foalHorse': FoalHorseToJSON(value.foalHorse),
        'breederHorse': BreederHorseToJSON(value.breederHorse),
        'breeder': BreederToJSON(value.breeder),
    };
}



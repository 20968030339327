import React from 'react';
import styled from 'styled-components';

export { default as SwitchRadios } from './Radio';

interface OnOffProps {
  on: string;
  off: string;
}

export const Label = styled.label<OnOffProps>`
  position: relative;
  display: inline-block;
  outline: none;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  &:input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &:before {
    z-index: 2;
    position: absolute;
    margin-top: 8px;
    width: 50%;
    left: 0;
    text-align: center;
    content: '${p => p.on}';
  }
  &:after {
    z-index: 2;
    position: absolute;
    margin-top: 8px;
    width: 50%;
    right: 0;
    text-align: center;
    content: '${p => p.off}';
  }
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  border-radius: 5px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.colors.gray2};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    margin: 2px;
    z-index: 1;
    position: absolute;
    content: '';
    height: 26px;
    width: 49%;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.2s;
  }
`;

export const Input = styled.input.attrs({ type: 'checkbox' })`
  width: 0;
  margin: 0 20px;
  &:checked + ${Slider}:before {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }
  z-index: 2;
`;

type params = Parameters<typeof Input>;

type Props = params[0] &
  React.InputHTMLAttributes<HTMLInputElement> &
  OnOffProps;

function SwitchButton({ on, off, ...props }: Props): JSX.Element {
  return (
    <Label on={on} off={off}>
      <Input {...props} />
      <Slider />
    </Label>
  );
}

export default SwitchButton;

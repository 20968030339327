import {
  useGetInvoiceTransactionsLatestBilledMonth,
  useGetOwnershipInvoiceTransactions,
} from '@apis';
import Content from '@components/ContentBlock/Content';
import DataGrid from '@components/DataGrid';
import PrevNextMenu from '@components/PrevNextMenu';
import { TD } from '@components/ReactTable';
import { Transaction } from '@generated/sportactors/src';
import useNumericParams from '@hooks/useNumericParams';
import theme from '@main/theme';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import List, { LI } from '@primitives/List';
import Loading, { Pulse } from '@primitives/Loading';
import { H3, Text } from '@primitives/Typography';
import { formatTz } from '@utils/dateUtils';
import { formatNumber } from '@utils/formatting';
import getMonthUrl from '@utils/getMonthUrl';
import { DEFAULT_FROM_YEAR } from '@utils/getYears';
import { numToMonth } from '@utils/numToMonth';
import { addMonths, isValid, subMonths } from 'date-fns';
import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { CellProps, Column } from 'react-table';

import YearMonthMenu from './YearMonthMenu';

const columns: Column<Transaction>[] = [
  {
    Header: 'Faktura-nummer',
    accessor: 'invoiceId',
    thStyle: () => ({
      whiteSpace: 'normal',
    }),
  },
  {
    Header: 'Datum',
    accessor: 'date',
    sortType: 'datetime',
    thStyle: () => ({
      whiteSpace: 'normal',
    }),
    Cell: ({ value }: CellProps<Transaction, Date>) => (
      <>{formatTz(value, 'yyyy-MM-dd')}</>
    ),
  },
  {
    Header: 'Produkt',
    accessor: 'productDescription',
  },
  {
    Header: 'Belopp',
    accessor: 'amount',
    Cell: ({ value }) => <>{`${value ? formatNumber(value) : 0} kr`}</>,
    alignment: 'right',
  },
  {
    Header: 'Moms',
    accessor: 'vatAmount',
    Cell: ({ value }) => <>{`${value ? formatNumber(value) : 0} kr`}</>,
    alignment: 'right',
  },
  {
    Header: 'Totalt',
    accessor: 'totalAmount',
    Cell: ({ value }) => <>{`${value ? formatNumber(value) : 0} kr`}</>,
    alignment: 'right',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Häst',
    accessor: row => row?.horse?.name,
  },
  {
    Header: 'Leverantör',
    accessor: 'supplierName',
  },
];

interface Props {
  licenseId: number;
}

interface InvoicedTransactionsProps extends Props {
  defaultDate: Date;
}

function InvoicedTransactions({
  licenseId,
  defaultDate,
}: InvoicedTransactionsProps): JSX.Element {
  const { path } = useRouteMatch();
  const date = useMemo(() => defaultDate, [defaultDate]);

  const fromDate = useMemo(() => new Date(DEFAULT_FROM_YEAR, 0, 1), []);
  const toDate = useMemo(
    () => new Date(new Date().getFullYear(), date.getMonth(), 1),
    [date],
  );

  const { year = date.getFullYear(), month = date.getMonth() + 1 } =
    useNumericParams();

  const selectedDate = useMemo(
    () => new Date(year, month - 1, 1),
    [year, month],
  );

  const prevDate = useMemo(() => subMonths(selectedDate, 1), [selectedDate]);
  const nextDate = useMemo(() => addMonths(selectedDate, 1), [selectedDate]);

  const { data, loading, status, error } = useGetOwnershipInvoiceTransactions(
    licenseId,
    isValid(selectedDate) ? selectedDate : new Date(),
  );
  const items = data || [];

  if (!isValid(selectedDate)) {
    return <Error>Datumet är ogiltigt.</Error>;
  }

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  return (
    <>
      <Box mt={[1, , 0]}>
        <YearMonthMenu
          year={year}
          month={month}
          fromDate={fromDate}
          toDate={toDate}
        />
      </Box>
      <PrevNextMenu
        //display={['none', null, 'block']}
        prevUrl={
          prevDate >= fromDate ? getMonthUrl('prev', year, month, path) : null
        }
        nextUrl={
          nextDate <= toDate ? getMonthUrl('next', year, month, path) : null
        }
        header={`${numToMonth(
          selectedDate.getMonth() + 1,
        ).toUpperCase()} ${year}`}
        mt={[1, , 4]}
      ></PrevNextMenu>
      <Content
        slug="fakturor-och-transaktioner-fakturerade-transaktioner"
        as="div"
        sx={{
          marginTop: theme.space[4],
          paddingRight: [theme.space[4], , 0],
          paddingLeft: [theme.space[4], , 0],
        }}
      />
      <Box px={[4, null, 0]}>
        {data && !items.length && (
          <Text mt={4}>Fann inga fakturerade transaktioner.</Text>
        )}
        {loading && <Loading mt={4} />}
        {items.length > 0 && (
          <List>
            {items.map(item => (
              <LI key={item.ownerTransactions.ownerName}>
                <Box mt={4}>
                  <H3>{item.ownerTransactions.ownerName}</H3>
                  <DataGrid
                    columns={columns}
                    data={item.ownerTransactions.transactions}
                    mt={4}
                    condensed
                    stickyOnMobile={false}
                    tfoot={
                      <>
                        {item?.ownerTransactions && (
                          <tr>
                            <TD colSpan={3} alignment="right" fontWeight="bold">
                              Summa:
                            </TD>
                            <TD alignment="right">
                              {item.ownerTransactions?.amountSum
                                ? formatNumber(item.ownerTransactions.amountSum)
                                : 0}{' '}
                              kr
                            </TD>
                            <TD alignment="right">
                              {item.ownerTransactions?.vatAmountSum
                                ? formatNumber(
                                    item.ownerTransactions.vatAmountSum,
                                  )
                                : 0}{' '}
                              kr
                            </TD>
                            <TD alignment="right">
                              {item.ownerTransactions?.totalAmountSum
                                ? formatNumber(
                                    item.ownerTransactions.totalAmountSum,
                                  )
                                : 0}{' '}
                              kr
                            </TD>
                            <TD colSpan={3} />
                          </tr>
                        )}
                        {item?.explicitUserShare && (
                          <tr>
                            <TD colSpan={3} alignment="right">
                              <b>Min andel</b> ({item.explicitUserShare.shares}{' '}
                              / {item.explicitUserShare.ownershipShares}):
                            </TD>
                            <TD>
                              {item.explicitUserShare?.amount
                                ? formatNumber(item.explicitUserShare.amount)
                                : 0}{' '}
                              kr
                            </TD>
                            <TD>
                              {item.explicitUserShare?.vat
                                ? formatNumber(item.explicitUserShare.vat)
                                : 0}{' '}
                              kr
                            </TD>
                            <TD>
                              {item.explicitUserShare?.totalAmount
                                ? formatNumber(
                                    item.explicitUserShare.totalAmount,
                                  )
                                : 0}{' '}
                              kr
                            </TD>
                            <TD colSpan={3} />
                          </tr>
                        )}
                      </>
                    }
                  />
                </Box>
              </LI>
            ))}
          </List>
        )}
      </Box>
    </>
  );
}

export default function InvoicedTransactionsWrapper({
  licenseId,
}: Props): JSX.Element | null {
  const { data: defaultDate, loading } =
    useGetInvoiceTransactionsLatestBilledMonth(licenseId);

  if (loading) {
    return <Pulse />;
  }

  const now = new Date();

  return (
    <InvoicedTransactions
      defaultDate={defaultDate || now}
      licenseId={licenseId}
    />
  );
}

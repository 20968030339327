// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActorChangeableInformation
 */
export interface ActorChangeableInformation  {
    /**
     * 
     * @type {number}
     * @memberof ActorChangeableInformation
     */
    activeSince?: number;
    /**
     * 
     * @type {number}
     * @memberof ActorChangeableInformation
     */
    birthYear?: number;
    /**
     * 
     * @type {string}
     * @memberof ActorChangeableInformation
     */
    residence?: string;
    /**
     * 
     * @type {string}
     * @memberof ActorChangeableInformation
     */
    facebookLink?: string;
    /**
     * 
     * @type {string}
     * @memberof ActorChangeableInformation
     */
    twitterLink?: string;
    /**
     * 
     * @type {string}
     * @memberof ActorChangeableInformation
     */
    instagramLink?: string;
}

export function ActorChangeableInformationFromJSON(json: any): ActorChangeableInformation {
    return {
        'activeSince': !exists(json, 'active_since') ? undefined : json['active_since'],
        'birthYear': !exists(json, 'birth_year') ? undefined : json['birth_year'],
        'residence': !exists(json, 'residence') ? undefined : json['residence'],
        'facebookLink': !exists(json, 'facebook_link') ? undefined : json['facebook_link'],
        'twitterLink': !exists(json, 'twitter_link') ? undefined : json['twitter_link'],
        'instagramLink': !exists(json, 'instagram_link') ? undefined : json['instagram_link'],
    };
}

export function ActorChangeableInformationToJSON(value?: ActorChangeableInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'active_since': value.activeSince,
        'birth_year': value.birthYear,
        'residence': value.residence,
        'facebook_link': value.facebookLink,
        'twitter_link': value.twitterLink,
        'instagram_link': value.instagramLink,
    };
}



import { StartDeclarationDriverChoiceEnum } from '@generated/propositions/src';
import Box from '@primitives/Box';
import { TextArea } from '@primitives/Form';
import { Small, Text } from '@primitives/Typography';
import { useFormikContext } from 'formik';
import React from 'react';

import { FormState } from '../Form';

interface MessageProps {
  driverType: StartDeclarationDriverChoiceEnum;
  startDeclaredToMultiple: boolean;
}

const Message = ({
  driverType,
  startDeclaredToMultiple,
}: MessageProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<FormState>();

  return (
    <>
      {driverType === StartDeclarationDriverChoiceEnum.FOREIGN_DRIVER && (
        <Text mb={4} fontSize="notes" color="dim-gray">
          Saknas ryttarens namn i söklistan under fliken &quot;Välj
          ryttare&quot;? Skriv ryttarens/ryttarnas namn här manuellt.
        </Text>
      )}

      {driverType === StartDeclarationDriverChoiceEnum.SPECIFY_LATER && (
        <Text mb={4} fontSize="notes" color="dim-gray">
          Har du ingen avtalad ryttare?
          <br />
          <br />
          <b>Du kan nu fortsätta startanmälan utan att välja ryttare.</b>
        </Text>
      )}

      <Box pb="6px">
        <label>
          Meddelande
          {startDeclaredToMultiple === true && <Text color="red">&nbsp;*</Text>}
          {startDeclaredToMultiple === false &&
            driverType === StartDeclarationDriverChoiceEnum.FOREIGN_DRIVER && (
              <Text>&nbsp;*</Text>
            )}
        </label>
      </Box>

      <TextArea
        value={values.message}
        onChange={e => setFieldValue('message', e.target.value, true)}
        placeholder={
          startDeclaredToMultiple
            ? 'Hästen är startanmäld till flera lopp, glöm inte att lämna prioordning här.'
            : undefined
        }
        sx={{
          '&::placeholder': {
            color: 'red',
            opacity: 1,
          },
          '&:-ms-input-placeholder': {
            color: 'red',
          },
          '&::-ms-input-placeholder': {
            color: 'red',
          },
        }}
      />
      <Small fontStyle="italic" color="dim-gray">
        {driverType !== StartDeclarationDriverChoiceEnum.FOREIGN_DRIVER &&
          startDeclaredToMultiple === false && (
            <Text>Valfritt meddelande.</Text>
          )}
        {driverType === StartDeclarationDriverChoiceEnum.FOREIGN_DRIVER && (
          <Text>Ange ryttarval som meddelande.</Text>
        )}
      </Small>
    </>
  );
};

export default Message;

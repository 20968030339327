import { base, BaseProps } from '@primitives/base';
import { SubNavLink as SNLink } from '@primitives/Link';
import List from '@primitives/List';
import styled from 'styled-components';
import { typography, TypographyProps } from 'styled-system';

const SubNavLink = styled(SNLink)<BaseProps & TypographyProps>`
  ${base(typography)}
  height: ${props => props.theme.sizes.subNav}px;
  :after {
    bottom: -1px;
    border-bottom: solid 3px ${props => props.theme.colors.blue};
    z-index: 999;
  }
`;

SubNavLink.defaultProps = {
  px: 2,
};

export const TabList = styled(List)``;

TabList.defaultProps = {
  mb: [0, , 4],
  borderBottomStyle: 'solid',
  borderBottomColor: ['gray3', , 'gray3'],
  borderBottomWidth: ['1px', , '1px'],
};

export default SubNavLink;

import { BucketLink, getTdsListLink } from '@generated/tds/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = BucketLink | undefined;

export interface Entities {
  tdsListLink?: {
    [key: string]: Result;
  };
}

const getQuery = (
  licenseId: number,
  documentId: number,
  key: string,
): QueryConfig<Entities> =>
  getTdsListLink<Entities>(
    {
      licenseId,
      documentId,
    },
    {
      transform: responseBody => ({
        tdsListLink: {
          [key]: responseBody,
        },
      }),
      update: {
        tdsListLink: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetTdsListLink = (
  licenseId: number,
  documentId: number,
): ApiResponse<Result, Entities> => {
  const key = `${licenseId}-${documentId}`;
  const query = getQuery(licenseId, documentId, key);
  const selector = (state: State): Result => state.entities?.tdsListLink?.[key];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetTdsListLink;

import {
  getTrainerHorses,
  HorseInTraining,
} from '@generated/licenseholders/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = HorseInTraining[] | undefined;

export interface Entities {
  trainerHorses?: {
    [id: number]: Result;
  };
}

const getQuery = (id: number): QueryConfig<Entities> =>
  getTrainerHorses<Entities>(
    {
      licenseId: id,
    },
    {
      queryKey: `trainerHorses:${id}`,
      transform: responseBody => ({
        trainerHorses: {
          [id]: responseBody,
        },
      }),
      update: {
        trainerHorses: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetTrainerHorses = (id: number): ApiResponse<Result, Entities> => {
  const query = getQuery(id);
  const selector = (state: State): Result =>
    state.entities?.trainerHorses?.[id];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetTrainerHorses;

import { Text } from '@primitives/Typography';
import styled from 'styled-components';
import { variant } from 'styled-system';

export const Label = styled(Text)<{ variant?: 'blue' | 'gray' }>`
  text-transform: uppercase;
  ${variant({
    prop: 'color',
    variants: {
      blue: {
        bg: 'very-light-blue',
        color: 'deep-blue',
      },
      gray: {
        bg: 'gray2',
        color: 'gray6',
      },
      green: {
        bg: 'light-green',
        color: 'green',
      },
    },
  })}
`;

Label.defaultProps = {
  px: '6px',
  lineHeight: '18px',
  borderRadius: '3px',
  fontSize: 'micro',
  variant: 'blue',
  fontWeight: 'bold',
  alignSelf: 'flex-start',
  //mt: '-1px',
};

import { useGetTransactions } from '@apis';
import ContentBlock from '@components/ContentBlock';
import useQuery from '@hooks/useQuery';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import { Text } from '@primitives/Typography';
import React from 'react';

import Filters from './Filters';
import List from './List';
import FilterGroupEnum from './utils/enum/FilterGroup';
import FilterValueEnum from './utils/enum/FilterValue';
import isValidFilter from './utils/isValidFilter';

const groupByToSort = {
  [FilterValueEnum.HorseId]: FilterValueEnum.HorseName,
  [FilterValueEnum.RepresentativeId]: FilterValueEnum.RepresentativeName,
  [FilterValueEnum.Date]: FilterValueEnum.Date,
};

interface Props {
  licenseId: number;
}

export default function Transactions({ licenseId }: Props): JSX.Element {
  const { data, loading, error, refresh, status } =
    useGetTransactions(licenseId);

  const queryParams = useQuery<{
    groupBy: string;
    sort: string;
    viewMode: string;
  }>(true);

  const viewMode: FilterValueEnum = isValidFilter(
    FilterGroupEnum.ViewMode,
    queryParams.viewMode,
  )
    ? (queryParams.viewMode as FilterValueEnum)
    : FilterValueEnum.Details;

  const groupBy: FilterValueEnum = isValidFilter(
    FilterGroupEnum.GroupBy,
    queryParams.groupBy,
  )
    ? (queryParams.groupBy as FilterValueEnum)
    : FilterValueEnum.HorseId;

  const sort: FilterValueEnum =
    viewMode === FilterValueEnum.Details
      ? isValidFilter(FilterGroupEnum.Sort, queryParams.sort)
        ? (queryParams.sort as FilterValueEnum)
        : FilterValueEnum.HorseName
      : groupByToSort[groupBy];

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <>
      <ContentBlock
        justifyContent="flex-start"
        slug="tds-se-transaktioner"
        alignment="left"
      />
      {data.length === 0 && <Text mt={4}>Fann inga transaktioner.</Text>}
      {data.length > 0 && (
        <>
          <Filters groupBy={groupBy} sort={sort} viewMode={viewMode} mt={4} />
          <List
            mt={4}
            licenseId={licenseId}
            transactions={data}
            groupBy={groupBy}
            refresh={refresh}
            sort={sort}
            viewMode={viewMode}
          />
        </>
      )}
    </>
  );
}

import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import { DesktopCard, FormCard } from '@components/Card';
import SeoTags from '@components/SEO';
import TabsRenderer, { Tab } from '@components/TabsRenderer';
import config from '@config';
import Box from '@primitives/Box';
import { CloseButtom } from '@primitives/Button';
import { BackLink, Link } from '@primitives/Link';
import { H1, Text } from '@primitives/Typography';
import { CSSSelectorObject, SystemCssProperties } from '@styled-system/css';
import React, { ReactNode } from 'react';

type BoxParameters = Parameters<typeof Box>[0];
export interface PageLayoutProps {
  tabs?: Tab[];
  children?: ReactNode | ReactNode[];
  form?: ReactNode;
  headline?: string;
  htmlHeader?: ReactNode;
  pageSize?: 'xlarge' | 'large' | 'medium' | 'small';
  contentTop?: ReactNode;
  topRightRemark?: ReactNode;
  italicRemark?: boolean;
  breadcrumbs?: Breadcrumb[];
  noCard?: boolean;
  includeSEO?: boolean;
  singleCard?: boolean;
  tabsLabel?: string;
  backLink?: {
    to: string;
    text?: ReactNode;
  };
  hideHeadline?: boolean;
  altPossitionTopRightRemark?: boolean;
  cardOrNoCardWrapperStyling?: CSSSelectorObject | SystemCssProperties;
  overrideFormCardsBackground?: string;
  childrenWrapperStyling?: CSSSelectorObject | SystemCssProperties;
}

export function PageLayout({
  tabs,
  children,
  form,
  headline,
  contentTop,
  topRightRemark,
  italicRemark,
  pageSize = 'xlarge',
  breadcrumbs,
  noCard = false,
  singleCard = false,
  htmlHeader,
  tabsLabel,
  backLink,
  includeSEO = true,
  hideHeadline = false,
  altPossitionTopRightRemark,
  cardOrNoCardWrapperStyling,
  overrideFormCardsBackground,
  childrenWrapperStyling,
  ...props
}: PageLayoutProps & BoxParameters): JSX.Element {
  const tabsRender = tabs && <TabsRenderer tabs={tabs} label={tabsLabel} />;

  const MainWrapper = noCard ? Box : DesktopCard;

  const emptyContentUnderCardorNoCard =
    !tabs &&
    !form &&
    !singleCard &&
    !(singleCard && children && noCard && children) &&
    !(singleCard && children) &&
    !altPossitionTopRightRemark;

  const baseImageUrl = config.isProduction
    ? 'https://www.svenskgalopp.se'
    : 'https://svenskgalopp.bricco.se';

  return (
    <Box
      maxWidth={`${pageSize}MaxWidth`}
      mx="auto"
      pt={[0, , 4]}
      px={[0, , 2]}
      {...props}
    >
      {headline && includeSEO && (
        <SeoTags
          title={headline}
          image={`${baseImageUrl}/static/img/sg_logo_og.png`}
        />
      )}
      <Box verticalSpacing={15}>
        {breadcrumbs && <Breadcrumbs linksArray={breadcrumbs}></Breadcrumbs>}

        {backLink && (
          <Box mb={4} display={['none', , 'block']}>
            <BackLink to={backLink.to}>{backLink?.text || 'Tillbaka'}</BackLink>
          </Box>
        )}

        {(headline || htmlHeader) && !hideHeadline && (
          <Box
            width={1}
            display="flex"
            bg={['seaShell', 'seaShell', 'none']}
            borderBottomColor="gray2"
            borderBottomWidth={['1px', 0, 0]}
            borderBottomStyle="solid"
            position="relative"
            justifyContent="space-between"
            sx={{
              '&&': {
                py: [3, , 7.5],
                mt: 0,
              },
            }}
          >
            {htmlHeader ? htmlHeader : <H1>{headline}</H1>}

            {topRightRemark && (
              /*Desktop vertion*/
              <Box
                sx={{
                  textAlign: 'right',
                  marginTop: '15px',
                }}
                display={['none', , 'block']}
                mr={1}
              >
                <Text
                  fontWeight="400"
                  pt={1}
                  fontSize="small"
                  color="gray4"
                  fontStyle={italicRemark ? 'italic' : 'normal'}
                >
                  {topRightRemark}
                </Text>
              </Box>
            )}

            {backLink && (
              <CloseButtom
                as={Link}
                display={['inline-block', , 'none']}
                position="absolute"
                right="0"
                top="0"
                to={backLink.to}
              />
            )}
          </Box>
        )}

        {contentTop && (
          <Box width={1} mt={0}>
            {contentTop}
          </Box>
        )}

        {!emptyContentUnderCardorNoCard && (
          <Box
            width={1}
            sx={
              cardOrNoCardWrapperStyling
                ? cardOrNoCardWrapperStyling
                : {
                    pt: [0, , noCard ? 0 : 7.5],
                    mt: ['-1px', , 0],
                    px: [0, , 2],
                  }
            }
          >
            {noCard && (
              <>
                {altPossitionTopRightRemark && (
                  /*Mobile vertion is a possable usage*/
                  <Box display={'block'}>
                    <Text
                      width="fit-content"
                      fontWeight="400"
                      fontSize="small"
                      color="gray4"
                      fontStyle={italicRemark ? 'italic' : 'normal'}
                    >
                      {topRightRemark}
                    </Text>
                  </Box>
                )}
                {form}
              </>
            )}
            {singleCard && children && noCard && children}

            {!noCard && (
              <FormCard
                variant={tabs || !form ? 'normal' : 'tight'}
                backgroundColor={overrideFormCardsBackground}
              >
                {altPossitionTopRightRemark && (
                  /*Mobile vertion is a possable usage*/
                  <Box display={'block'}>
                    <Text
                      width="fit-content"
                      fontWeight="400"
                      fontSize="small"
                      color="gray4"
                      fontStyle={italicRemark ? 'italic' : 'normal'}
                    >
                      {topRightRemark}
                    </Text>
                  </Box>
                )}

                {tabs && <Box mx={[-4, , 0]}>{tabsRender}</Box>}
                {form && (
                  <Box mt={tabs ? [0, , 4] : 0} mb={singleCard ? 4 : 0}>
                    {form}
                  </Box>
                )}
                {singleCard && children && (
                  <Box
                    sx={childrenWrapperStyling}
                    pt={[!form && tabs ? 4 : 0, , 0]}
                  >
                    {children}
                  </Box>
                )}
              </FormCard>
            )}
          </Box>
        )}

        {!singleCard && children && (
          <Box sx={childrenWrapperStyling} width={1}>
            <MainWrapper width={1}>{children}</MainWrapper>
          </Box>
        )}
      </Box>
    </Box>
  );
}

// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * The address of the user
 * @export
 * @interface Address
 */
export interface Address  {
    /**
     * The attention
     * @type {string}
     * @memberof Address
     */
    attention?: string;
    /**
     * The care of address
     * @type {string}
     * @memberof Address
     */
    coAddress?: string;
    /**
     * The street name and number
     * @type {string}
     * @memberof Address
     */
    streetAddress: string;
    /**
     * The post code
     * @type {string}
     * @memberof Address
     */
    postCode: string;
    /**
     * The post code and city
     * @type {string}
     * @memberof Address
     */
    postAddress: string;
    /**
     * The city
     * @type {string}
     * @memberof Address
     */
    city: string;
    /**
     * The country code
     * @type {string}
     * @memberof Address
     */
    countryCode: string;
    /**
     * The name of the country
     * @type {string}
     * @memberof Address
     */
    countryName: string;
}

export function AddressFromJSON(json: any): Address {
    return {
        'attention': !exists(json, 'attention') ? undefined : json['attention'],
        'coAddress': !exists(json, 'coAddress') ? undefined : json['coAddress'],
        'streetAddress': json['streetAddress'],
        'postCode': json['postCode'],
        'postAddress': json['postAddress'],
        'city': json['city'],
        'countryCode': json['countryCode'],
        'countryName': json['countryName'],
    };
}

export function AddressToJSON(value?: Address): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'attention': value.attention,
        'coAddress': value.coAddress,
        'streetAddress': value.streetAddress,
        'postCode': value.postCode,
        'postAddress': value.postAddress,
        'city': value.city,
        'countryCode': value.countryCode,
        'countryName': value.countryName,
    };
}



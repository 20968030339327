// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Ownership,
    OwnershipFromJSON,
    OwnershipToJSON,
    SportActorHorse,
    SportActorHorseFromJSON,
    SportActorHorseToJSON,
} from './';

/**
 * 
 * @export
 * @interface OwnershipHorse
 */
export interface OwnershipHorse  {
    /**
     * 
     * @type {SportActorHorse}
     * @memberof OwnershipHorse
     */
    horseOwnership: SportActorHorse;
    /**
     * 
     * @type {Ownership}
     * @memberof OwnershipHorse
     */
    ownership: Ownership;
    /**
     * 
     * @type {boolean}
     * @memberof OwnershipHorse
     */
    userRepresentative?: boolean;
}

export function OwnershipHorseFromJSON(json: any): OwnershipHorse {
    return {
        'horseOwnership': SportActorHorseFromJSON(json['horseOwnership']),
        'ownership': OwnershipFromJSON(json['ownership']),
        'userRepresentative': !exists(json, 'userRepresentative') ? undefined : json['userRepresentative'],
    };
}

export function OwnershipHorseToJSON(value?: OwnershipHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseOwnership': SportActorHorseToJSON(value.horseOwnership),
        'ownership': OwnershipToJSON(value.ownership),
        'userRepresentative': value.userRepresentative,
    };
}



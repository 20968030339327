import useActiveMenu from '@hooks/useActiveMenu';
import theme from '@main/theme';
import { ArrowDownSVG } from '@primitives/Arrow';
import { Flex } from '@primitives/Box';
import Icon from '@primitives/Icon';
import { SubNavLink } from '@primitives/Link';
import List from '@primitives/List';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import styled from 'styled-components';

import Dropdown from './Dropdown';

const NavList = styled(List)`
  margin-top: -1px;
  a,
  svg {
    transition: color 0.2s;
  }
  font-weight: normal;
`;

const SubNavbar = (): JSX.Element => {
  const active = useActiveMenu();
  const [hoverItem, setHoverItem] = useState<string | undefined>(undefined);

  const menuItems = active?.subMenu?.map(item => {
    if (item.subMenu) {
      return (
        <li key={item.href} data-eventtracking-region={'secondary-menu'}>
          <Dropdown subMenu={item.subMenu}>
            <SubNavLink to={item.href}>
              {item.label}
              <Icon size={16} color="black" as={ArrowDownSVG} ml={1} mt={1} />
            </SubNavLink>
          </Dropdown>
        </li>
      );
    } else {
      return (
        <li key={item.href} data-eventtracking-region={'secondary-menu'}>
          <SubNavLink
            to={item.href}
            onMouseEnter={() => setHoverItem(item.href)}
            onMouseLeave={() => setHoverItem(undefined)}
            color={
              hoverItem === item.href || hoverItem === undefined
                ? theme.colors.gray5
                : transparentize(0.4, theme.colors.gray5)
            }
          >
            {item.label}
          </SubNavLink>
        </li>
      );
    }
  });

  return (
    <Flex
      height="subNav"
      justifyContent="start"
      bg="white"
      color="black"
      width="100%"
      position="absolute"
      bottom="0"
      sx={{ zIndex: -2 }}
      borderColor="gray2"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.16)"
    >
      {active && (
        <NavList
          variant="horizontal"
          itemGap={4}
          ml="auto"
          mr="auto"
          pr="30px"
          pl="195px"
          maxWidth="container"
          width="100%"
        >
          {menuItems}
        </NavList>
      )}
    </Flex>
  );
};

export default SubNavbar;

import {
  PersonalInformation,
  updatePersonalInformation,
  UpdatePersonalInformationRequest,
} from '@generated/user/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';
import { Entities } from './useGetPersonalInformation';

type ActionParams =
  UpdatePersonalInformationRequest['changeableUserInformation'];

const useUpdatePersonalInformation = (
  licenseId: number,
): MutationResponse<
  PersonalInformation | undefined,
  Entities,
  ActionParams
> => {
  const queryFunc = (
    changeableUserInformation: ActionParams,
  ): QueryConfig<Entities> =>
    updatePersonalInformation<Entities>(
      {
        licenseId,
        changeableUserInformation,
      },
      {
        update: {
          personalInformation: (prev, next): typeof next =>
            (prev && {
              ...prev,
              [licenseId]: {
                ...prev[licenseId],
                changeableUserInformation,
              },
            }) ||
            undefined,
        },
      },
    );

  const selector = (state: State): PersonalInformation | undefined =>
    state.entities?.personalInformation?.[licenseId];

  return useApiMutate<PersonalInformation | undefined, ActionParams>({
    queryFunc,
    selector,
    queryKey: 'updatePersonalInformation',
  });
};

export default useUpdatePersonalInformation;

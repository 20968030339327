import { ErrorResponse } from '@generated/account/src';
import {
  BankIdCollectedAuthentication,
  BankIdCollectedAuthenticationStatusEnum,
  BankIdUserAuthentication,
  collectWithAnimatedQrCode,
} from '@generated/authenticate/src';
import useAuthenticationMethod from '@hooks/useAuthenticationMethod';
import useBankIdCollect, {
  ReturnValues,
  State as BaseState,
  wrapCollectAction,
} from '@hooks/useBankIdCollect';
import useQuery from '@hooks/useQuery';
import { useRefreshUser } from '@hooks/useUser';
import auth from '@main/auth';
import { resetState } from '@main/store';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MutateAsyncAction } from 'redux-query';
import superagent from 'superagent';

import { request } from '../request';

interface State extends BaseState {
  orderReference?: string;
  qrTime?: number;
  qrCodePattern?: string;
  autoStartToken?: string;
  errorMessage?: string;
}
interface CollectResponse extends superagent.Response {
  body: BankIdCollectedAuthentication & ErrorResponse;
}

interface Entities {
  user: BankIdCollectedAuthentication['authenticatedUser'];
  authenticationMethod?: string;
}

const collectAction = (
  orderReference: string,
  qrTime: number,
): MutateAsyncAction<Entities> =>
  wrapCollectAction(
    collectWithAnimatedQrCode<Entities>(
      {
        orderReference,
        useCookie: false,
        qrTime,
      },
      {
        transform: responseBody => ({
          user: responseBody?.authenticatedUser,
          authenticationMethod: responseBody?.authenticationMethod,
        }),
        update: {
          user: (_prev, next): typeof next => next || _prev,
          authenticationMethod: (prev, next): typeof next => next,
        },
      },
    ),
  );

const useBankId = (): ReturnValues<State> => {
  const authMethod = useAuthenticationMethod();
  const [state, setState] = useState<State>({
    orderReference: undefined,
    qrTime: undefined,
    qrCodePattern: undefined,
  });

  const dispatch = useDispatch();
  const refreshUser = useRefreshUser();
  const history = useHistory();
  const { dest } = useQuery<{ dest?: string }>(true);

  const loginDestination = dest ? dest : '/minasidor';

  /**
   * Collect callback - polling for status
   */
  const runCollect = useCallback(async (): Promise<CollectResponse> => {
    if (state.orderReference) {
      const qrTime =
        Math.round((new Date().getTime() - state.qrTime) / 1000) || 1;
      if (qrTime > 30) {
        return {
          body: {
            errorMessage: 'Tiden för att signera har gått ut.',
            status: BankIdCollectedAuthenticationStatusEnum.FAILED,
          },
        } as unknown as CollectResponse;
      }

      const resp = (await dispatch(
        collectAction(state.orderReference, qrTime),
      )) as unknown as CollectResponse;

      if (resp.status === 200) {
        setState(prev => ({
          ...prev,
          qrCodePattern: resp.body.qrCodePattern,
        }));
        return resp;
      }
      // Dispatch usaly doesn't return a response
      return resp;
    }
  }, [dispatch, state.orderReference, state.qrTime]);

  /**
   * Init callback - creates bankId orderReference
   */
  const onInit = useCallback(async () => {
    const resp = await request<BankIdUserAuthentication & ErrorResponse>({
      url: '/authenticate/bankid/initialize',
      method: 'POST',
      body: {},
    });

    if (resp.status !== 201) {
      setState(prev => ({
        ...prev,
        result: 'failed',
        errorMessage: resp.body?.errorMessage || 'Ett oväntat fel inträffade.',
      }));
      return;
    }

    const { autoStartToken, orderReference } = resp.body;

    setState(prev => ({
      ...prev,
      orderReference,
      qrTime: Date.now(),
      autoStartToken,
    }));
  }, []);

  const onComplete = useCallback(
    async ({ headers }) => {
      auth.token = headers.authorization;
      await dispatch(resetState());
      refreshUser();
      history.push(loginDestination);
    },
    [loginDestination, history, dispatch, refreshUser],
  );

  const beforeEachCollect = useCallback(async () => {
    if (auth.token && authMethod === 'BANKID') {
      await dispatch(resetState());
      refreshUser();
      history.push(loginDestination);
    }
  }, [loginDestination, dispatch, history, refreshUser, authMethod]);

  const response = useBankIdCollect({
    onInit,
    collectCallback: runCollect,
    beforeEachCollect,
    onComplete,
  });

  return {
    ...state,
    ...response,
    result: state.result || response.result,
  };
};

export default useBankId;

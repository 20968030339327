// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Horse,
    HorseFromJSON,
    HorseToJSON,
} from './';

/**
 * 
 * @export
 * @interface PropositionWithHorses
 */
export interface PropositionWithHorses  {
    /**
     * 
     * @type {number}
     * @memberof PropositionWithHorses
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PropositionWithHorses
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof PropositionWithHorses
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PropositionWithHorses
     */
    distance?: number;
    /**
     * 
     * @type {string}
     * @memberof PropositionWithHorses
     */
    trackSurface?: string;
    /**
     * 
     * @type {Array<Horse>}
     * @memberof PropositionWithHorses
     */
    processedHorses?: Array<Horse>;
}

export function PropositionWithHorsesFromJSON(json: any): PropositionWithHorses {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'trackSurface': !exists(json, 'trackSurface') ? undefined : json['trackSurface'],
        'processedHorses': !exists(json, 'processedHorses') ? undefined : (json['processedHorses'] as Array<any>).map(HorseFromJSON),
    };
}

export function PropositionWithHorsesToJSON(value?: PropositionWithHorses): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'number': value.number,
        'name': value.name,
        'distance': value.distance,
        'trackSurface': value.trackSurface,
        'processedHorses': value.processedHorses === undefined ? undefined : (value.processedHorses as Array<any>).map(HorseToJSON),
    };
}



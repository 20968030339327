import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import { Link } from '@primitives/Link';
import React, { Fragment } from 'react';

interface Props {
  user: AuthenticatedUser;
}

const userRolesMap = {
  Tevla: 'Ryttare',
  Trena: 'Tränare',
  Delegare: 'Ägare',
  Foretredare: 'Ägare',
  Hingsthallare: 'Hingsthållare',
};

function getUserLink(role: string, licenseId: number): string | void {
  if (role === 'Tränare' || role === 'Ryttare') {
    return `/sportinfo/licensinnehavare/${licenseId}/${
      role === 'Tränare' ? 'tranarstatistik' : 'ryttarstatistik'
    }`;
  }
}

function UserRoles({ user }: Props): JSX.Element {
  const order = Object.values(userRolesMap);
  const items = user.roles
    .map(role => userRolesMap?.[role.role])
    .filter((value, index, self) => self.indexOf(value) === index)
    .filter(Boolean)
    .sort((a, b) => order.indexOf(a) - order.indexOf(b));

  return (
    <>
      {items.length
        ? items.map((role, i) => {
            const path = getUserLink(role, user.licenseId);
            return (
              <Fragment key={role}>
                {path ? (
                  <Link
                    to={path}
                    color="gray6"
                    sx={{
                      textDecoration: 'underline',
                    }}
                  >
                    {role}
                  </Link>
                ) : (
                  role
                )}
                {i + 1 < items.length && `, `}
              </Fragment>
            );
          })
        : 'Medlem'}
    </>
  );
}

export default withUser(UserRoles);

import { Favorite, insertFavorite } from '@generated/user/src';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../utils/types';
import { useApiMutateWithoutData } from '../utils/useApiMutate';
import { Entities } from './useGetSavedChartFilters';

const useInsertFavorite = (
  licenseId: number,
): MutationResponseWithoutData<Entities, Favorite> =>
  useApiMutateWithoutData({
    queryFunc: (favorite: Favorite): QueryConfig<Entities> =>
      insertFavorite({
        licenseId,
        favorite,
      }),
    queryKey: 'insertFavorite',
  });

export default useInsertFavorite;

// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    HorseBasicInformation,
    HorseBasicInformationFromJSON,
    HorseBasicInformationToJSON,
} from './';

/**
 * 
 * @export
 * @interface CollectedHorseSignStatus
 */
export interface CollectedHorseSignStatus  {
    /**
     * Change of ownership Id created
     * @type {number}
     * @memberof CollectedHorseSignStatus
     */
    transferId?: number;
    /**
     * 
     * @type {HorseBasicInformation}
     * @memberof CollectedHorseSignStatus
     */
    horse?: HorseBasicInformation;
    /**
     * The progress status of the BankID order
     * @type {string}
     * @memberof CollectedHorseSignStatus
     */
    status: CollectedHorseSignStatusStatusEnum;
    /**
     * The hint code of the BankID order
     * @type {string}
     * @memberof CollectedHorseSignStatus
     */
    hintCode?: string;
    /**
     * The qr code pattern used to generate the animated qr code at the client.
     * @type {string}
     * @memberof CollectedHorseSignStatus
     */
    qrCodePattern?: string;
}

export function CollectedHorseSignStatusFromJSON(json: any): CollectedHorseSignStatus {
    return {
        'transferId': !exists(json, 'transferId') ? undefined : json['transferId'],
        'horse': !exists(json, 'horse') ? undefined : HorseBasicInformationFromJSON(json['horse']),
        'status': json['status'],
        'hintCode': !exists(json, 'hintCode') ? undefined : json['hintCode'],
        'qrCodePattern': !exists(json, 'qrCodePattern') ? undefined : json['qrCodePattern'],
    };
}

export function CollectedHorseSignStatusToJSON(value?: CollectedHorseSignStatus): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'transferId': value.transferId,
        'horse': HorseBasicInformationToJSON(value.horse),
        'status': value.status,
        'hintCode': value.hintCode,
        'qrCodePattern': value.qrCodePattern,
    };
}

/**
* @export
* @enum {string}
*/
export enum CollectedHorseSignStatusStatusEnum {
    PENDING = 'pending',
    FAILED = 'failed',
    COMPLETE = 'complete'
}



import useSearchIsOpen from '@hooks/useSearchIsOpen';
import theme from '@main/theme';
import Box from '@primitives/Box';
import React, { ReactElement } from 'react';

import DesktopMobileSwitch from '../DesktopMobileSwitch';
import PageHeaderDesktop from './Desktop';
import PageHeaderMobile from './Mobile';

const PageHeader = (): JSX.Element | null | ReactElement => {
  const open = useSearchIsOpen();

  const mobileHeight =
    theme.sizes.searchFormMobileHeight + theme.sizes.pageHeader;
  const desktopHeight = theme.sizes.searchFormHeight + theme.sizes.pageHeader;

  return (
    <Box minHeight={open ? [`${mobileHeight}px`, , `${desktopHeight}px`] : 0}>
      <DesktopMobileSwitch
        desktopElement={<PageHeaderDesktop />}
        mobileElement={<PageHeaderMobile />}
      />
    </Box>
  );
};

export default PageHeader;

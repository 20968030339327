// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Selection,
    SelectionFromJSON,
    SelectionToJSON,
} from '../models';


/**
 * Get the currently valid covering list breeding years.
 */
function getBreedingYearsRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Selection> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlistresults/years`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SelectionFromJSON(body), text);
    }

    return config;
}

/**
* Get the currently valid covering list breeding years.
*/
export function getBreedingYears<T>( requestConfig?: runtime.TypedQueryConfig<T, Selection>): QueryConfig<T> {
    return getBreedingYearsRaw( requestConfig);
}

/**
 * Get all available horse colors
 */
function getColorsRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Selection> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlistresults/colors`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SelectionFromJSON(body), text);
    }

    return config;
}

/**
* Get all available horse colors
*/
export function getColors<T>( requestConfig?: runtime.TypedQueryConfig<T, Selection>): QueryConfig<T> {
    return getColorsRaw( requestConfig);
}

/**
 * Get all countries valid for a covering result. The selection option\'s value is the country\'s code to use in save request (ISO 3166-1 alpha-2)
 */
function getCountriesRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Selection> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlistresults/countries`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SelectionFromJSON(body), text);
    }

    return config;
}

/**
* Get all countries valid for a covering result. The selection option\'s value is the country\'s code to use in save request (ISO 3166-1 alpha-2)
*/
export function getCountries<T>( requestConfig?: runtime.TypedQueryConfig<T, Selection>): QueryConfig<T> {
    return getCountriesRaw( requestConfig);
}

/**
 * Get the currently valid covering list result outcome types.
 */
function getCoveringOutcomesRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Selection> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlistresults/outcomes`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SelectionFromJSON(body), text);
    }

    return config;
}

/**
* Get the currently valid covering list result outcome types.
*/
export function getCoveringOutcomes<T>( requestConfig?: runtime.TypedQueryConfig<T, Selection>): QueryConfig<T> {
    return getCoveringOutcomesRaw( requestConfig);
}

/**
 * Get all genders valid for a covering result.
 */
function getGendersRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Selection> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlistresults/genders`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SelectionFromJSON(body), text);
    }

    return config;
}

/**
* Get all genders valid for a covering result.
*/
export function getGenders<T>( requestConfig?: runtime.TypedQueryConfig<T, Selection>): QueryConfig<T> {
    return getGendersRaw( requestConfig);
}


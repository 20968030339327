import {
  deleteHorseDeclaredStart,
  StartDeclaration,
} from '@generated/propositions/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

export interface Entities {
  deleteStartDeclaration?: StartDeclaration;
}

const useDeleteStartDeclaration = (
  propositionId: number,
  horseId: number,
): MutationResponse<Entities['deleteStartDeclaration'], Entities, unknown> => {
  const queryFunc = (): QueryConfig<Entities> =>
    deleteHorseDeclaredStart({
      propositionId,
      horseId,
    });
  return useApiMutate<Entities['deleteStartDeclaration'], unknown>({
    queryFunc,
    selector: (state: State) => state.entities.deleteStartDeclaration,
    queryKey: 'deleteHorseDeclaredStart',
  });
};

export default useDeleteStartDeclaration;

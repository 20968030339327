import { getDriversRidingWeight } from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = number | undefined;

export interface Entities {
  driversRidingWeight?: {
    [key: string]: Result;
  };
}

export default function useGetDriversRidingWeight(
  propositionId: number,
  driverId: number,
  propositionWeight: number,
): ApiResponse<Result, Entities> {
  const key = `${propositionId}-${driverId}-${propositionWeight}`;
  const query = {
    ...getDriversRidingWeight<Entities>(
      {
        propositionId,
        driverId,
        propositionWeight,
      },
      {
        queryKey: `ridingWeight:${key}`,
        update: {
          driversRidingWeight: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    transform: body => ({
      driversRidingWeight: {
        [key]: body,
      },
    }),
  };
  const selector = (state: State): Result =>
    state.entities?.driversRidingWeight?.[key];

  return useApi<Result>({
    query,
    selector,
  });
}

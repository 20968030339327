// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Statistics
 */
export interface Statistics  {
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    licenseId: number;
    /**
     * 
     * @type {string}
     * @memberof Statistics
     */
    year: string;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    numberOfStarts: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    firstPlaces: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    secondPlaces: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    thirdPlaces: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    winningRates: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    prizeMoney: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    prizeMoneyPerStart?: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    bonus?: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    prizeMoneyIncludingBonus?: number;
    /**
     * 
     * @type {string}
     * @memberof Statistics
     */
    numberOfHorses?: string;
}

export function StatisticsFromJSON(json: any): Statistics {
    return {
        'licenseId': json['licenseId'],
        'year': json['year'],
        'numberOfStarts': json['numberOfStarts'],
        'firstPlaces': json['firstPlaces'],
        'secondPlaces': json['secondPlaces'],
        'thirdPlaces': json['thirdPlaces'],
        'winningRates': json['winningRates'],
        'prizeMoney': json['prizeMoney'],
        'prizeMoneyPerStart': !exists(json, 'prizeMoneyPerStart') ? undefined : json['prizeMoneyPerStart'],
        'bonus': !exists(json, 'bonus') ? undefined : json['bonus'],
        'prizeMoneyIncludingBonus': !exists(json, 'prizeMoneyIncludingBonus') ? undefined : json['prizeMoneyIncludingBonus'],
        'numberOfHorses': !exists(json, 'numberOfHorses') ? undefined : json['numberOfHorses'],
    };
}

export function StatisticsToJSON(value?: Statistics): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseId': value.licenseId,
        'year': value.year,
        'numberOfStarts': value.numberOfStarts,
        'firstPlaces': value.firstPlaces,
        'secondPlaces': value.secondPlaces,
        'thirdPlaces': value.thirdPlaces,
        'winningRates': value.winningRates,
        'prizeMoney': value.prizeMoney,
        'prizeMoneyPerStart': value.prizeMoneyPerStart,
        'bonus': value.bonus,
        'prizeMoneyIncludingBonus': value.prizeMoneyIncludingBonus,
        'numberOfHorses': value.numberOfHorses,
    };
}



import {
  getHorsesInOngoingStakeRace,
  StakeRaceOngoingHorses,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../../utils/types';
import useApi from '../../utils/useApi';

type Results = StakeRaceOngoingHorses | undefined;

export interface Entities {
  horsesInOngoingStakeRace?: {
    [key: string]: Results;
  };
}

export default function useGetHorsesInOngoingStakeRace(
  propositionId: number,
  stakeid?: number,
): ApiResponse<Results, Entities> {
  const key = `${propositionId}-${stakeid || 'all'}`;
  const query = getHorsesInOngoingStakeRace<Entities>(
    { propositionId, stakeid },
    {
      transform: responseBody => ({
        horsesInOngoingStakeRace: {
          [key]: responseBody,
        },
      }),
      update: {
        horsesInOngoingStakeRace: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Results =>
    state.entities?.horsesInOngoingStakeRace?.[key];

  return useApi<Results>({
    query,
    selector,
  });
}

// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    StakeRaceGallopRow,
    StakeRaceGallopRowFromJSON,
    StakeRaceGallopRowToJSON,
} from './';

/**
 * 
 * @export
 * @interface StakeRace
 */
export interface StakeRace  {
    /**
     * The horse id.
     * @type {number}
     * @memberof StakeRace
     */
    horseId: number;
    /**
     * The stake race name.
     * @type {string}
     * @memberof StakeRace
     */
    name: string;
    /**
     * 
     * @type {StakeRaceGallopRow}
     * @memberof StakeRace
     */
    stakeRace?: StakeRaceGallopRow;
}

export function StakeRaceFromJSON(json: any): StakeRace {
    return {
        'horseId': json['horseId'],
        'name': json['name'],
        'stakeRace': !exists(json, 'stakeRace') ? undefined : StakeRaceGallopRowFromJSON(json['stakeRace']),
    };
}

export function StakeRaceToJSON(value?: StakeRace): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'name': value.name,
        'stakeRace': StakeRaceGallopRowToJSON(value.stakeRace),
    };
}



// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Track,
    TrackFromJSON,
    TrackToJSON,
} from './';

/**
 * 
 * @export
 * @interface StakeRaceEnrolled
 */
export interface StakeRaceEnrolled  {
    /**
     * 
     * @type {number}
     * @memberof StakeRaceEnrolled
     */
    propositionId: number;
    /**
     * 
     * @type {string}
     * @memberof StakeRaceEnrolled
     */
    propositionName: string;
    /**
     * 
     * @type {Track}
     * @memberof StakeRaceEnrolled
     */
    track: Track;
    /**
     * 
     * @type {number}
     * @memberof StakeRaceEnrolled
     */
    stakeNumber: number;
    /**
     * 
     * @type {Date}
     * @memberof StakeRaceEnrolled
     */
    invoiceExpirationDate: Date;
}

export function StakeRaceEnrolledFromJSON(json: any): StakeRaceEnrolled {
    return {
        'propositionId': json['propositionId'],
        'propositionName': json['propositionName'],
        'track': TrackFromJSON(json['track']),
        'stakeNumber': json['stakeNumber'],
        'invoiceExpirationDate': parseDateTime(json['invoiceExpirationDate']),
    };
}

export function StakeRaceEnrolledToJSON(value?: StakeRaceEnrolled): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'propositionId': value.propositionId,
        'propositionName': value.propositionName,
        'track': TrackToJSON(value.track),
        'stakeNumber': value.stakeNumber,
        'invoiceExpirationDate': value.invoiceExpirationDate.toLocalDate(),
    };
}



import { useGetValidatedPerson } from '@apis';
import InfoBadge from '@components/InfoBadge';
import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import useAuthenticationMethod from '@hooks/useAuthenticationMethod';
import { AUTH_SWITCH_CONSTANT } from '@main/auth';
import Box from '@primitives/Box';
import { ButtonLink } from '@primitives/Button';
import Pulse from '@primitives/Loading';
import Message from '@primitives/Message';
import { CircleInfo as InfoCircle } from '@styled-icons/fa-solid';
import { OWNERSHIP_TRANSFER_ROLES } from '@utils/constants';
import React, { useCallback } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

interface Props {
  user: AuthenticatedUser;
  headline: string;
  defaultPath: string;
  tabs: {
    component: React.FunctionComponent<{ user: AuthenticatedUser }>;
    text: string;
    path: string;
    showBannedError?: boolean;
  }[];
  isLeasing?: boolean;
}

const AuthWrapper = ({
  user,
  tabs,
  defaultPath,
  headline,
  isLeasing = false,
}: Props): JSX.Element => {
  const authMethod = useAuthenticationMethod();
  const history = useHistory();
  const { loading, errorCode, error: rawError } = useGetValidatedPerson();

  const { pathname, search } = history.location;

  const doLogout = useCallback(async () => {
    localStorage.setItem('loginMethodPassword', 'false');

    history.push(
      `/login?reason=${AUTH_SWITCH_CONSTANT}&dest=${encodeURIComponent(
        pathname + search,
      )}`,
    );
  }, [history, pathname, search]);

  const error =
    errorCode === 3
      ? isLeasing
        ? 'Ägarskapet kan inte registrera leasingavtal, kontakta Svensk Galopp för mer information.'
        : 'Ägarskapet kan inte registrera ägarskiften, kontakta Svensk Galopp för mer information.'
      : errorCode === 4
        ? isLeasing
          ? 'Leasingavtal kan ej registreras digitalt av minderårig.'
          : 'Ägarskiften kan ej genomföras av minderårig.'
        : rawError?.startsWith(
              'Failed to find person/s for the given idNumbers',
            )
          ? `Ägarskapet kan inte registrera ${
              isLeasing ? 'leasingavtal' : 'ägarskiften'
            }, kontakta Svensk Galopp för mer information.`
          : rawError;

  return (
    <PageLayout
      headline={headline}
      singleCard
      tabs={
        authMethod === 'BANKID'
          ? tabs.filter(Boolean).map(item => ({
              to: item.path,
              text: item.text,
            }))
          : []
      }
    >
      {authMethod === 'BANKID' ? (
        <>
          {loading ? (
            <Pulse />
          ) : error && errorCode !== 3 ? (
            <Message variant="errorBox">{error}</Message>
          ) : (
            <>
              <Switch>
                {tabs.filter(Boolean).map(item => (
                  <Route path={item.path} key={item.path}>
                    {/* Banned user */}
                    {error && errorCode === 3 && item.showBannedError ? (
                      <Message variant="errorBox">{error}</Message>
                    ) : (
                      <item.component user={user} />
                    )}
                  </Route>
                ))}

                <Route>
                  <Redirect to={defaultPath} />
                </Route>
              </Switch>
            </>
          )}
        </>
      ) : (
        <Box verticalSpacing={4} pb={6}>
          <InfoBadge
            icon={InfoCircle}
            color="blue"
            message={`Du måste vara inloggad med BankID för att hantera ${
              isLeasing ? 'leasingärenden' : 'ägarskiften'
            }.`}
          />
          <ButtonLink onClick={doLogout}>Logga in med BankID</ButtonLink>
        </Box>
      )}
    </PageLayout>
  );
};

export default withUser(AuthWrapper, {
  roles: OWNERSHIP_TRANSFER_ROLES,
});

import {
  DriverCostProducts,
  getDriverCostTransactionProducts,
} from '@generated/tds/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = DriverCostProducts[] | undefined;

export interface Entities {
  driverCostTransactionProducts?: {
    [key: string]: Result;
  };
}

export const getQueryKey = (
  licenseId: number,
  raceDayId: number,
  licensecategory = '1',
): string =>
  `driverCostTransactionProducts:${licenseId}-${raceDayId}-${licensecategory}`;

const useGetDriverCostTransactionProducts = (
  licenseId: number,
  raceDayId: number,
  licensecategory: string,
): ApiResponse<Result, Entities> => {
  const key = `${licenseId}-${raceDayId}-${licensecategory}`;
  const queryKey = getQueryKey(licenseId, raceDayId, licensecategory);
  const query = getDriverCostTransactionProducts<Entities>(
    {
      licenseId,
      raceDayId,
      licensecategory,
    },
    {
      force: true,
      queryKey,
      transform: responseBody => ({
        driverCostTransactionProducts: {
          [key]: responseBody,
        },
      }),
      update: {
        driverCostTransactionProducts: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Result =>
    state.entities?.driverCostTransactionProducts?.[key];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetDriverCostTransactionProducts;

import Box from '@primitives/Box';
import Select, { Option } from '@primitives/Form/select';
import getMonthUrl from '@utils/getMonthUrl';
import getYears from '@utils/getYears';
import { months } from '@utils/numToMonth';
import React, { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

type BoxParameters = Parameters<typeof Box>;

type Props = {
  year: number;
  month: number;
  fromDate: Date;
  toDate: Date;
} & BoxParameters[0];

export default function YearMonthMenu({
  year,
  month,
  fromDate,
  toDate,
  ...props
}: Props): JSX.Element {
  const { path } = useRouteMatch();
  const history = useHistory();
  const years = getYears(fromDate.getFullYear(), toDate.getFullYear());

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const {
        target: { name, value },
      } = event;

      history.push(
        getMonthUrl(
          'current',
          name === 'year' ? parseInt(value) : year,
          name === 'year' ? month : parseInt(value),
          path,
        ),
      );
    },
    [history, year, month, path],
  );

  return (
    <Box
      display="flex"
      borderWidth={1}
      borderStyle="solid"
      borderColor="gray2"
      backgroundColor="gray1"
      p={4}
      {...props}
    >
      <Select onChange={onChange} name="year" value={year}>
        {years.map(item => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
      </Select>
      <Box pl={4}>
        <Select onChange={onChange} name="month" value={month}>
          {months
            .filter((value, index) =>
              year === toDate.getFullYear() && new Date(year, index, 1) > toDate
                ? false
                : value,
            )
            .map((item, index) => (
              <Option key={item} value={index + 1}>
                {item}
              </Option>
            ))}
        </Select>
      </Box>
    </Box>
  );
}

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Owner
 */
export interface Owner  {
    /**
     * OwnerDB id
     * @type {number}
     * @memberof Owner
     */
    id: number;
    /**
     * Name of owner
     * @type {string}
     * @memberof Owner
     */
    name: string;
}

export function OwnerFromJSON(json: any): Owner {
    return {
        'id': json['id'],
        'name': json['name'],
    };
}

export function OwnerToJSON(value?: Owner): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
    };
}



// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    BucketLink,
    BucketLinkFromJSON,
    BucketLinkToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface GetRaceCardUrlRequest {
    raceDayId: number;
}


/**
 * Get URL to race card document for a given race day id.
 */
function getRaceCardUrlRaw<T>(requestParameters: GetRaceCardUrlRequest, requestConfig: runtime.TypedQueryConfig<T, BucketLink> = {}): QueryConfig<T> {
    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling getRaceCardUrl.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/raceinfo/{raceDayId}/racecards/url`.replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(BucketLinkFromJSON(body), text);
    }

    return config;
}

/**
* Get URL to race card document for a given race day id.
*/
export function getRaceCardUrl<T>(requestParameters: GetRaceCardUrlRequest, requestConfig?: runtime.TypedQueryConfig<T, BucketLink>): QueryConfig<T> {
    return getRaceCardUrlRaw(requestParameters, requestConfig);
}


// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Driver
 */
export interface Driver  {
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    sortOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    lowestRidingWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    ridingWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof Driver
     */
    weightReduction?: number;
    /**
     * The weight updated by the trainer while adding a driver
     * @type {number}
     * @memberof Driver
     */
    updatedWeight?: number;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    licenseType?: string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    licenseDescription?: string;
    /**
     * True if the driver is suspended
     * @type {boolean}
     * @memberof Driver
     */
    suspended?: boolean;
    /**
     * True if the driver is amateur
     * @type {boolean}
     * @memberof Driver
     */
    amateur?: boolean;
    /**
     * True if the driver is apprentice
     * @type {boolean}
     * @memberof Driver
     */
    apprentice?: boolean;
    /**
     * True if driver is mounted Promise
     * @type {boolean}
     * @memberof Driver
     */
    mountingPromise?: boolean;
    /**
     * The short name of driver
     * @type {string}
     * @memberof Driver
     */
    shortName?: string;
}

export function DriverFromJSON(json: any): Driver {
    return {
        'id': json['id'],
        'name': json['name'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'lowestRidingWeight': !exists(json, 'lowestRidingWeight') ? undefined : json['lowestRidingWeight'],
        'ridingWeight': !exists(json, 'ridingWeight') ? undefined : json['ridingWeight'],
        'weightReduction': !exists(json, 'weightReduction') ? undefined : json['weightReduction'],
        'updatedWeight': !exists(json, 'updatedWeight') ? undefined : json['updatedWeight'],
        'licenseType': !exists(json, 'licenseType') ? undefined : json['licenseType'],
        'licenseDescription': !exists(json, 'licenseDescription') ? undefined : json['licenseDescription'],
        'suspended': !exists(json, 'suspended') ? undefined : json['suspended'],
        'amateur': !exists(json, 'amateur') ? undefined : json['amateur'],
        'apprentice': !exists(json, 'apprentice') ? undefined : json['apprentice'],
        'mountingPromise': !exists(json, 'mountingPromise') ? undefined : json['mountingPromise'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
    };
}

export function DriverToJSON(value?: Driver): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'sortOrder': value.sortOrder,
        'lowestRidingWeight': value.lowestRidingWeight,
        'ridingWeight': value.ridingWeight,
        'weightReduction': value.weightReduction,
        'updatedWeight': value.updatedWeight,
        'licenseType': value.licenseType,
        'licenseDescription': value.licenseDescription,
        'suspended': value.suspended,
        'amateur': value.amateur,
        'apprentice': value.apprentice,
        'mountingPromise': value.mountingPromise,
        'shortName': value.shortName,
    };
}



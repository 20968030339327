// tslint:disable
/**
 * Web API Auth Service
 * Service for supporting user authentication (issuing JWTs) for accessing secured resources.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    AuthenticatedUser,
    AuthenticatedUserFromJSON,
    AuthenticatedUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface BankIdCollectedAuthentication
 */
export interface BankIdCollectedAuthentication  {
    /**
     * The progress status of the BankID order.
     * @type {string}
     * @memberof BankIdCollectedAuthentication
     */
    status: BankIdCollectedAuthenticationStatusEnum;
    /**
     * The authentication method, only present when status is COMPLETE.
     * @type {string}
     * @memberof BankIdCollectedAuthentication
     */
    authenticationMethod?: BankIdCollectedAuthenticationAuthenticationMethodEnum;
    /**
     * The hintCode from BankId giving more details about the status.
     * @type {string}
     * @memberof BankIdCollectedAuthentication
     */
    hintCode: string;
    /**
     * The qr code pattern used to generate the animated qr code.
     * @type {string}
     * @memberof BankIdCollectedAuthentication
     */
    qrCodePattern?: string;
    /**
     * 
     * @type {AuthenticatedUser}
     * @memberof BankIdCollectedAuthentication
     */
    authenticatedUser?: AuthenticatedUser;
}

export function BankIdCollectedAuthenticationFromJSON(json: any): BankIdCollectedAuthentication {
    return {
        'status': json['status'],
        'authenticationMethod': !exists(json, 'authenticationMethod') ? undefined : json['authenticationMethod'],
        'hintCode': json['hintCode'],
        'qrCodePattern': !exists(json, 'qrCodePattern') ? undefined : json['qrCodePattern'],
        'authenticatedUser': !exists(json, 'authenticatedUser') ? undefined : AuthenticatedUserFromJSON(json['authenticatedUser']),
    };
}

export function BankIdCollectedAuthenticationToJSON(value?: BankIdCollectedAuthentication): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'authenticationMethod': value.authenticationMethod,
        'hintCode': value.hintCode,
        'qrCodePattern': value.qrCodePattern,
        'authenticatedUser': AuthenticatedUserToJSON(value.authenticatedUser),
    };
}

/**
* @export
* @enum {string}
*/
export enum BankIdCollectedAuthenticationStatusEnum {
    PENDING = 'pending',
    FAILED = 'failed',
    COMPLETE = 'complete'
}
/**
* @export
* @enum {string}
*/
export enum BankIdCollectedAuthenticationAuthenticationMethodEnum {
    ANONYMOUS = 'ANONYMOUS',
    PASSWORD = 'PASSWORD',
    BANKID = 'BANKID',
    FREJA_EID = 'FREJA_EID',
    SUBSCRIPTION = 'SUBSCRIPTION'
}



// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * The TDS-lists of this category
 * @export
 * @interface TdsList
 */
export interface TdsList  {
    /**
     * The license holder that the list belongs to
     * @type {number}
     * @memberof TdsList
     */
    licenseId: number;
    /**
     * The id of the TDS-list
     * @type {number}
     * @memberof TdsList
     */
    id: number;
    /**
     * The text/name of the list. Is typically the period date of the list
     * @type {string}
     * @memberof TdsList
     */
    text: string;
    /**
     * True if the user has opened/viewed the list, false otherwise
     * @type {boolean}
     * @memberof TdsList
     */
    read: boolean;
}

export function TdsListFromJSON(json: any): TdsList {
    return {
        'licenseId': json['licenseId'],
        'id': json['id'],
        'text': json['text'],
        'read': json['read'],
    };
}

export function TdsListToJSON(value?: TdsList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseId': value.licenseId,
        'id': value.id,
        'text': value.text,
        'read': value.read,
    };
}



import Card from '@components/Card';
import theme from '@main/theme';
import { base, BaseProps } from '@primitives/base';
import Box from '@primitives/Box';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { typography, TypographyProps } from 'styled-system';

const StyledDate = styled.div<TypographyProps & BaseProps>`
  opacity: 1;
  ${base(typography)}
`;

const WeekDay = styled.div`
  color: ${props => props.theme.colors.gray5};
  text-align: center;
  font-size: ${props => props.theme.fontSizes.small}px;
`;

const NumDay = styled.div<TypographyProps>`
  text-align: center;
  font-size: 22px;
  letter-spacing: 0px;
  opacity: 1;
  ${base(typography)}
`;

interface Props {
  isToday?: boolean;
  num: number;
  weekDay: string;
  month?: string;
  color?: string;
  dayIsEmpty?: boolean;
  children?: ReactNode;
  opacity?: number;
}

function MobileDay({
  isToday,
  weekDay,
  num,
  color,
  month,
  children,
}: Props): JSX.Element {
  return (
    <Box>
      <StyledDate
        fontSize={'lead'}
        fontWeight={isToday ? 'bold' : 'normal'}
        color={isToday ? 'deep-blue' : 'gray6'}
        mx={2}
        ml={3}
      >
        {weekDay} {num} {month.toLowerCase()}
      </StyledDate>
      <Card
        p={0}
        mt="6px"
        mb="14px"
        borderLeft={isToday && `5px ${theme.colors['deep-blue']} solid`}
      >
        <Box bg={color} borderRadius={5} display="flex" p="6px">
          {children}
        </Box>
      </Card>
    </Box>
  );
}

const DesktopDay = ({
  isToday,
  num,
  weekDay,
  color,
  children,
  opacity,
  dayIsEmpty,
}: Props): JSX.Element => (
  <Card
    backgroundColor={color}
    opacity={opacity}
    p={0}
    maxHeight={dayIsEmpty ? '48px' : 'none'}
    py={1}
    borderLeft={isToday && `6px ${theme.colors['deep-blue']} solid`}
  >
    <Box
      display="flex"
      ml={isToday && '-6px'}
      pt={!dayIsEmpty ? 4 : '0px'}
      pb={!dayIsEmpty ? 2 : '0px'}
    >
      <StyledDate
        width="30px"
        fontWeight={isToday ? 'bold' : 'normal'}
        mx={4}
        mt={!dayIsEmpty ? '-4px' : '0px'}
      >
        <WeekDay>{weekDay}</WeekDay>
        <NumDay
          color={
            weekDay === 'SÖN' ? theme.colors['alt3'] : theme.colors['deep-blue']
          }
        >
          {num}
        </NumDay>
      </StyledDate>
      {children}
    </Box>
  </Card>
);

interface CalendarDayProps extends Props {
  isMobile: boolean;
}

export default function CalendarDay({
  isMobile,
  children,
  ...props
}: CalendarDayProps): JSX.Element {
  const Component = isMobile ? MobileDay : DesktopDay;
  return <Component {...props}>{children}</Component>;
}

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface SportActorSearchReference
 */
export interface SportActorSearchReference  {
    /**
     * 
     * @type {number}
     * @memberof SportActorSearchReference
     */
    id?: number;
    /**
     * The label might be \'Svensson Sven, Svedala\' or \'AB Bolaget, Svedala\' etc.
     * @type {string}
     * @memberof SportActorSearchReference
     */
    label?: string;
}

export function SportActorSearchReferenceFromJSON(json: any): SportActorSearchReference {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'label': !exists(json, 'label') ? undefined : json['label'],
    };
}

export function SportActorSearchReferenceToJSON(value?: SportActorSearchReference): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'label': value.label,
    };
}



import { useGetOwnershipStatistics } from '@apis';
import StatisticsTable from '@features/SportInfo/utils/Statistics';
import { AuthenticatedUser } from '@generated/account/src';
import Error from '@primitives/Error';
import React from 'react';

interface Props {
  user: AuthenticatedUser;
}

export default function Statistics({ user }: Props): JSX.Element {
  const { data, loading, error, status } = useGetOwnershipStatistics(
    user.licenseId,
  );

  if (loading) {
    return null;
  }

  if (status && status !== 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }
  return <StatisticsTable data={data || []} />;
}

import { useGetHorsesWithVaccine } from '@apis';
import ContentBlockSearch from '@components/ContentBlockSearch';
import InfoBadge from '@components/InfoBadge';
import useIsMobile from '@hooks/useIsMobile';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import { Select } from '@primitives/Form';
import Loading from '@primitives/Loading';
import { CircleInfo as InfoCircle } from '@styled-icons/fa-solid';
import React, { useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Vaccinations from './Vaccinations';

interface Props {
  horseId: number;
  licenseId: number;
}

export default function VaccinationHistory({
  horseId,
  licenseId,
}: Props): JSX.Element {
  const { data, loading, status, error } = useGetHorsesWithVaccine(licenseId);
  const { path } = useRouteMatch();
  const history = useHistory();
  const isMobile = useIsMobile();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const {
        target: { value },
      } = event;
      history.push(`${path}/${value}`);
    },
    [history, path],
  );

  const horses = useMemo(
    () =>
      data
        ? [
            { label: 'Välj häst', value: '' },
            ...data
              // Only unique horses
              .filter(
                (value, index, self) =>
                  self.map(horse => horse.horseId).indexOf(value.horseId) ===
                  index,
              )
              // Transform to options format
              .map(horse => ({
                label: `${horse.horseDisplayName}${
                  horse.horseName === 'nn' && horse?.horseRegistrationNumber
                    ? ` (${horse.horseRegistrationNumber})`
                    : ''
                }`,
                value: horse.horseId.toString(),
              })),
          ]
        : [],
    [data],
  );

  const horse = useMemo(
    () => (data ? data.find(value => value.horseId === horseId) : null),
    [data, horseId],
  );

  if (loading || !data) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  return (
    <>
      <Box>
        <Box
          p={4}
          bg="gray1"
          borderColor="gray2"
          borderStyle="solid"
          borderWidth="1px"
        >
          <Box maxWidth="250px">
            <Select name="horse" options={horses} onChange={onChange} />
          </Box>
        </Box>
        {horseId && (
          <Box mt={4}>
            <Vaccinations horse={horse} licenseId={licenseId} />
          </Box>
        )}
      </Box>
      {!horseId && !isMobile && (
        <ContentBlockSearch slug="vaccinationshistorik-sok" />
      )}
      {horseId && (
        <Box mt={3}>
          <InfoBadge
            icon={InfoCircle}
            message={
              <>
                Det är inte möjligt att ta bort registrerade vaccinationer. Vid
                felaktig registrering kontakta kundtjänst på{' '}
                <a href="mailto:info@svenskgalopp.se">info@svenskgalopp.se</a>.
              </>
            }
            color="blue"
          />
        </Box>
      )}
    </>
  );
}

import useIsMobile from '@hooks/useIsMobile';
import React from 'react';

interface Props {
  mobileElement?: React.ReactElement;
  desktopElement?: React.ReactElement;
}

const DesktopMobileSwitch = ({
  mobileElement,
  desktopElement,
}: Props): React.ReactElement | null => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return mobileElement ? mobileElement : null;
  } else {
    return desktopElement ? desktopElement : null;
  }
};

export default DesktopMobileSwitch;

import {
  getHorseOwnershipShareholdersDistribution,
  HorseOwnershipSharesDistribution,
} from '../../generated/sportactors/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = HorseOwnershipSharesDistribution | undefined;

export interface Entities {
  ownershipShareholdersDistribution?: {
    [key: string]: Result;
  };
}

const useGetOwnershipShareholdersDistribution = (
  ownerId: number,
  ownershipId: number,
): ApiResponse<Result, Entities> => {
  const key = `${ownerId}-${ownershipId}`;
  return useApi<Result>({
    query: getHorseOwnershipShareholdersDistribution<Entities>(
      {
        ownerId,
        ownershipId,
      },
      {
        transform: responseBody => ({
          ownershipShareholdersDistribution: {
            [key]: responseBody,
          },
        }),
        update: {
          ownershipShareholdersDistribution: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities?.ownershipShareholdersDistribution?.[key],
  });
};

export default useGetOwnershipShareholdersDistribution;

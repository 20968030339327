import {
  getVaccineHistory,
  VaccineInjection,
} from '@generated/licenseholders/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = VaccineInjection[] | undefined;

export interface Entities {
  vaccineHistory?: {
    [key: string]: Result;
  };
}

const useGetVaccineHistory = (
  licenseId: number,
  horseId: number,
): ApiResponse<Result, Entities> => {
  const key = `vaccineHistory:${licenseId}:${horseId}`;

  const query = getVaccineHistory<Entities>(
    { licenseId, horseId },
    {
      queryKey: key,
      transform: responseBody => ({
        vaccineHistory: {
          [key]: responseBody,
        },
      }),
      update: {
        vaccineHistory: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  const selector = (state: State): Result =>
    state.entities?.vaccineHistory?.[key];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetVaccineHistory;

import {
  Credentials,
  ErrorResponse,
  updateRegistrationStatus,
  UpdateRegistrationStatusRequest,
  UpdateRegistrationStatusStatusEnum,
} from '@generated/horses/src';
import { State } from '@main/store';
import { format } from 'date-fns';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

export interface Entities {
  updateRegistrationStatus?: ErrorResponse;
}

const useUpdateRegistrationStatus = (
  horseId: number,
  status: UpdateRegistrationStatusStatusEnum,
  fromdate: Date,
  credentials: Credentials,
): MutationResponse<
  Entities['updateRegistrationStatus'],
  Entities,
  UpdateRegistrationStatusRequest
> => {
  const queryFunc = (): QueryConfig<Entities> => {
    const query = updateRegistrationStatus({
      horseId,
      status,
      fromdate,
      credentials,
    });

    // A hack to make it work when backend are combining query and body parameters :(
    return {
      ...query,
      body: credentials,
      url: `/horses/{horseId}/registrationstatus/credentials?status={status}&fromdate={fromdate}`
        .replace(`{${'horseId'}}`, horseId.toString())
        .replace(`{${'status'}}`, status)
        .replace(`{${'fromdate'}}`, format(fromdate, 'yyyy-MM-dd')),
    };
  };

  return useApiMutate<
    Entities['updateRegistrationStatus'],
    UpdateRegistrationStatusRequest
  >({
    queryFunc,
    selector: (state: State) => state.entities.updateRegistrationStatus,
    queryKey: 'updateRegistrationStatus',
    resetKeys: [
      `registrationStatusOptions:${horseId}`,
      `horse:${horseId}`,
      // `horsesByOwnerships:*`,
      // `trainerHorses:*`,
    ],
  });
};

export default useUpdateRegistrationStatus;

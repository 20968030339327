import { DeclaredEntryDetail } from '@generated/propositions/src';
import React, { useMemo } from 'react';

import HorseInfo from '.';

interface Props {
  data: DeclaredEntryDetail;
}

const StartDeclarationHorseInfo = ({ data }: Props): JSX.Element => {
  const details = useMemo(
    () =>
      [
        {
          label: 'Ägare',
          value: data.horse?.owner || undefined,
        },
        data.horse.previousStart.trackCode
          ? {
              label: 'Senaste start',
              value: data.horse?.previousStart?.trackCode
                ? `${data.horse?.previousStart?.trackCode}${' '}
        ${data.horse?.previousStart?.displayDate}`
                : undefined,
            }
          : undefined,
        data.horse.comingStart.trackCode
          ? {
              label: 'Kommande start',
              value: data.horse?.comingStart?.trackCode
                ? `${data.horse?.comingStart?.trackCode}${' '}
          ${data.horse?.comingStart?.displayDate}`
                : undefined,
            }
          : undefined,
      ].filter(Boolean),
    [data],
  );

  return (
    <HorseInfo
      name={data.horse.name}
      text={
        <>
          ({data.proposition.weight} kg), {data.horse.gender.code},{' '}
          {data.horse.age} år, Poäng {data.horse.points}, HCP{' '}
          {data.horse.currentHCP}
        </>
      }
      details={details}
    />
  );
};

export default StartDeclarationHorseInfo;

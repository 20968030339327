import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

declare global {
  interface Window {
    dataLayer: Record<string, string>[];
  }
}

export default function GTMPageView(): null {
  const history = useHistory();
  const location = useLocation();

  useEffect(
    () => {
      history.listen((location, action) => {
        const isRedirect = action === 'REPLACE';

        if (!isRedirect) {
          // Run on page load, but not on redirects and not on initial load
          setTimeout(() => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'Pageview',
              pagePath: location.pathname + location.search,
              pageTitle: document.title,
            });
          }, 1000);
        }
      });

      // Initial page load - only fired once
      setTimeout(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'Pageview',
          pagePath: location.pathname + location.search,
          pageTitle: document.title,
        });
      }, 1000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return null;
}

import {
  getUpcomingStakeRaceHorses,
  UpcomingStakeRaceHorse,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../../utils/types';
import useApi from '../../utils/useApi';

type Results = UpcomingStakeRaceHorse[] | undefined;

export interface Entities {
  upcomingStakeRaceHorses?: {
    [key: string]: Results;
  };
}

export default function useGetUpcomingStakeRaceHorses(
  propositionId: number,
  trainerId: number,
): ApiResponse<Results, Entities> {
  const key = `${propositionId}-${trainerId}`;
  const query = getUpcomingStakeRaceHorses<Entities>(
    {
      propositionId,
      trainerId,
    },
    {
      transform: responseBody => ({
        upcomingStakeRaceHorses: {
          [key]: responseBody,
        },
      }),
      update: {
        upcomingStakeRaceHorses: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Results =>
    state.entities?.upcomingStakeRaceHorses?.[key];

  return useApi<Results>({
    query,
    selector,
  });
}

import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import { OWNERSHIP_TRANSFER_ROLES } from '@utils/constants';
import hasRole from '@utils/hasRole';
import roleEnum from '@utils/roleEnum';
import React from 'react';
import { generatePath, useRouteMatch } from 'react-router-dom';

import AuthWrapper from '../OwnershipTransfers/AuthWrapper';
import CancelLeasing from './CancelLeasing';
import LeasingTerminations from './LeasingTerminations';
import NewLeasing from './NewLeasing';
import OngoingTransfers from './OngoingTransfers';

interface Props {
  user: AuthenticatedUser;
}

const Leasing = ({ user }: Props): JSX.Element => {
  const { path } = useRouteMatch();

  const newTransferPath = generatePath(path, {
    tab: 'anmal',
  });

  const ongoingTransfersPath = generatePath(path, {
    tab: 'pagande',
  });

  const cancelLeasingPath = generatePath(path, {
    tab: 'avsluta',
  });

  const leasingTerminationsPath = generatePath(path, {
    tab: 'uppsagningar',
  });

  const initTransferAccess = hasRole(user, roleEnum.Foretredare);
  const leasingTerminationsAccess =
    hasRole(user, roleEnum.Foretredare) || hasRole(user, roleEnum.Delegare);

  const defaultTab = initTransferAccess
    ? newTransferPath
    : ongoingTransfersPath;

  return (
    <AuthWrapper
      headline="Leasing"
      defaultPath={defaultTab}
      isLeasing
      tabs={[
        initTransferAccess && {
          path: newTransferPath,
          text: 'Anmäl leasing',
          component: NewLeasing,
          showBannedError: true,
        },
        {
          path: ongoingTransfersPath,
          text: 'Påbörjade leasingärenden',
          component: OngoingTransfers,
        },
        initTransferAccess && {
          path: cancelLeasingPath,
          text: 'Säg upp leasing',
          component: CancelLeasing,
        },
        leasingTerminationsAccess && {
          path: leasingTerminationsPath,
          text: 'Påbörjade uppsägningar',
          component: LeasingTerminations,
        },
      ]}
    />
  );
};

export default withUser(Leasing, {
  roles: OWNERSHIP_TRANSFER_ROLES,
});

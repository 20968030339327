import { MissingMare } from '@generated/breedings/src';
import { RemoveButton } from '@primitives/Button';
import { useFormikContext } from 'formik';
import React from 'react';

export default function CoveringDatesTable(): JSX.Element {
  const { setFieldValue, values } = useFormikContext<MissingMare>();

  return (
    <table>
      <tbody>
        {values.coveringDates
          .slice()
          .sort()
          .map((date, i) => (
            <tr key={date}>
              <td>Språng {i + 1}</td>
              <td>{date}</td>
              <td>
                <RemoveButton
                  confirm={false}
                  onClick={() => {
                    setFieldValue(
                      'coveringDates',
                      values.coveringDates.filter(v => v !== date),
                    );
                  }}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

import { Text } from '@primitives/Typography';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';

import { base } from '../base';

interface StyledCheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  overRideCss?: string;
}

const HiddenBox = styled.input`
  display: none;
  -webkit-appearance: checkbox;
`;

const StyledCheckbox = styled.div.attrs((props: StyledCheckboxProps) => props)`
  ${base()};
  transition:
    background-color 150ms ease-in,
    border-color 150ms ease-in;
  background: ${props => props.theme.colors.white} no-repeat 50%;
  width: 16px;
  height: 16px;

  margin-right: ${props => props.theme.space[2]}px;
  border: 1px solid #cac8c6;
  flex-shrink: 0;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }

  ${HiddenBox}:checked + & {
    background-color: ${props => props.theme.colors.white};
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8'%3E%3Cpath d='M.375 2.625L3.375 5.625M3.375 5.625L8.625.375' fill-rule='evenodd' stroke='rgb%2820, 91, 160%29' stroke-linecap='round' stroke-width='1.5' transform='translate(.5 1)'/%3E%3C/svg%3E");
  }

  ${HiddenBox}:disabled+ & {
    background-color: ${props => props.theme.colors.gray1};
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8'%3E%3Cpath d='M.375 2.625L3.375 5.625M3.375 5.625L8.625.375' fill-rule='evenodd' stroke='rgb%2820, 91, 160%29' stroke-linecap='round' stroke-width='1.5' transform='translate(.5 1)'/%3E%3C/svg%3E");
  }
`;

const Label = styled.label`
  display: flex;
  align-items: top;
  outline: none;
  cursor: pointer;
`;

function Checkbox({
  tabIndex = 0,
  label,
  style,
  ...props
}: StyledCheckboxProps & SpaceProps): JSX.Element {
  return (
    <Label tabIndex={tabIndex}>
      <HiddenBox type="checkbox" {...props} />
      <StyledCheckbox style={style} />
      {label && (
        <Text fontWeight="normal" fontSize="text">
          {label}
        </Text>
      )}
    </Label>
  );
}

export default Checkbox;

import { usePreCheckHorsesForVaccineRegistration } from '@apis';
import { useEffect } from 'react';

import PreCheckData from './utils/type/PreCheckData';

interface Props {
  licenseId: number;
  vaccinationdate: Date;
  vaccineid: number;
  horseids: number[];
  setPreCheckData: (preCheck: PreCheckData) => void;
}

export default function PreCheckHorses({
  licenseId,
  vaccinationdate,
  vaccineid,
  horseids,
  setPreCheckData,
}: Props): JSX.Element {
  const { data, loading, status, error } =
    usePreCheckHorsesForVaccineRegistration(
      licenseId,
      vaccinationdate,
      vaccineid,
      horseids,
    );

  useEffect(() => {
    setPreCheckData({ data, loading, status, error });
  }, [data, loading, status, error, setPreCheckData]);

  return null;
}

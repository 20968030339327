// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface MissingMare
 */
export interface MissingMare  {
    /**
     * 
     * @type {string}
     * @memberof MissingMare
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MissingMare
     */
    yearOfBirth: string;
    /**
     * 
     * @type {string}
     * @memberof MissingMare
     */
    fatherMotherGrandFatherName?: string;
    /**
     * 
     * @type {string}
     * @memberof MissingMare
     */
    owner: string;
    /**
     * 
     * @type {string}
     * @memberof MissingMare
     */
    ownerAddress?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MissingMare
     */
    coveringDates: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MissingMare
     */
    countryOfOrigin?: string;
    /**
     * 
     * @type {string}
     * @memberof MissingMare
     */
    importDate?: string;
    /**
     * 
     * @type {string}
     * @memberof MissingMare
     */
    message?: string;
}

export function MissingMareFromJSON(json: any): MissingMare {
    return {
        'name': json['name'],
        'yearOfBirth': json['yearOfBirth'],
        'fatherMotherGrandFatherName': !exists(json, 'fatherMotherGrandFatherName') ? undefined : json['fatherMotherGrandFatherName'],
        'owner': json['owner'],
        'ownerAddress': !exists(json, 'ownerAddress') ? undefined : json['ownerAddress'],
        'coveringDates': json['coveringDates'],
        'countryOfOrigin': !exists(json, 'countryOfOrigin') ? undefined : json['countryOfOrigin'],
        'importDate': !exists(json, 'importDate') ? undefined : json['importDate'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function MissingMareToJSON(value?: MissingMare): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'yearOfBirth': value.yearOfBirth,
        'fatherMotherGrandFatherName': value.fatherMotherGrandFatherName,
        'owner': value.owner,
        'ownerAddress': value.ownerAddress,
        'coveringDates': value.coveringDates,
        'countryOfOrigin': value.countryOfOrigin,
        'importDate': value.importDate,
        'message': value.message,
    };
}



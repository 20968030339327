import {
  getSireSelections,
  GetSireSelectionsRequest,
  Selection,
} from '@generated/breedings/src';
import { State } from '@main/store';
import cleanObject from '@utils/cleanObject';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  sireSelections?: {
    [key: string]: Selection;
  };
}

type Values = Selection | undefined;

export default function useGetBreedingSireSelections(
  raw: GetSireSelectionsRequest,
): ApiResponse<Values, Entities> {
  // Remove emty params
  const params = cleanObject(raw) as GetSireSelectionsRequest;

  const key = JSON.stringify(params);

  const query = getSireSelections<Entities>(params, {
    transform: responseBody => ({
      sireSelections: {
        [key]: responseBody,
      },
    }),
    update: {
      sireSelections: (prev, next) => ({
        ...prev,
        ...next,
      }),
    },
  });

  const selector = (state: State): Values =>
    state.entities?.sireSelections?.[key];

  return useApi<Values>({
    query,
    selector,
  });
}

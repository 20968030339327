import {
  useCreateDriverTravelFee,
  useGetDriverCommission,
  useGetTracks,
} from '@apis';
import { Input } from '@components/FormikComponents';
import { Select } from '@components/FormikComponents';
import FormLayout, { FormError, FormItem } from '@components/FormLayout';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import { H3 } from '@primitives/Typography';
import { Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';

import List from './List';

interface FormState {
  trackCode: string;
  amount: number;
}

interface Props {
  licenseId: number;
}

export default function DriverTravelFees({ licenseId }: Props): JSX.Element {
  const {
    data: commission,
    loading: commissionLoading,
    error: commissionError,
    status: commissionStatus,
  } = useGetDriverCommission(licenseId);
  const {
    data: allTracks,
    loading: allTracksLoading,
    error: allTracksError,
    status: allTracksStatus,
  } = useGetTracks(false);
  const { action } = useCreateDriverTravelFee(licenseId);
  const [error, setError] = useState<string>();
  const [refreshList, setRefreshList] = useState<number>(0);

  const options = useMemo(
    () =>
      allTracks
        ? [
            { label: 'Välj bana', value: '' },
            ...allTracks.map(track => ({
              label: track.name,
              value: track.code,
            })),
          ]
        : null,
    [allTracks],
  );

  const statusAndError = useMemo(() => {
    if (commissionStatus && commissionStatus !== 200) {
      return { status: commissionStatus, error: commissionError };
    }

    if (allTracksStatus && allTracksStatus !== 200) {
      return { status: allTracksStatus, error: allTracksError };
    }

    return null;
  }, [commissionStatus, commissionError, allTracksStatus, allTracksError]);

  if (statusAndError) {
    return (
      <Error mt={4}>
        {statusAndError.status} {statusAndError.error}
      </Error>
    );
  }

  if ((commissionLoading && !commission) || (allTracksLoading && !allTracks)) {
    return <Loading mt={4} />;
  }

  const initialValues = { trackCode: '', amount: null };

  return (
    <Box mt={5}>
      <H3 mb={4}>Researvoden</H3>
      <Formik<FormState>
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          trackCode: Yup.string().required('Obligatorisk'),
          amount: Yup.number()
            .required('Obligatorisk')
            .typeError('Ange ett tal')
            .min(1, 'För lågt'),
        })}
        onSubmit={async values => {
          setError('');

          const track = allTracks.find(item => item.code === values.trackCode);

          if (track) {
            const { body } = await action({
              licenseId,
              trackCode: track.code,
              trackId: track.id,
              trackName: track.name,
              amount: values.amount,
            });

            if (body && body.errorMessage) {
              setError(body.errorMessage);
            }

            setRefreshList(prev => prev + 1);
          } else {
            setError('Fann ej vald bana.');
          }
        }}
      >
        {({ isSubmitting }) => (
          <FormLayout>
            <FormItem width={[1, null, 2 / 4]}>
              <Select
                name="trackCode"
                label="Bana"
                options={options}
                disabled={isSubmitting}
              />
            </FormItem>
            <FormItem width={[1, null, 1 / 4]}>
              <Input
                name="amount"
                label="Arvode"
                type="text"
                disabled={isSubmitting}
              />
            </FormItem>
            <FormItem width={[1, null, 1 / 4]} noLabel>
              <Button type="submit" loading={isSubmitting}>
                Lägg till
              </Button>
            </FormItem>
            {error && <FormError>{error}</FormError>}
          </FormLayout>
        )}
      </Formik>
      <List licenseId={licenseId} refreshList={refreshList} />
    </Box>
  );
}

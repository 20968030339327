import {
  getRacesWithNegativeDoping,
  GetRacesWithNegativeDopingRequest,
  NegativeDopingResultsCalendar,
} from '@generated/raceinfo/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  negativeDopingResultsCalendars?: {
    [key: string]: NegativeDopingResultsCalendar;
  };
}

const getQuery = (
  resultKey: string,
  request: GetRacesWithNegativeDopingRequest,
): QueryConfig<Entities> =>
  getRacesWithNegativeDoping<Entities>(request, {
    transform: responseBody => ({
      negativeDopingResultsCalendars: {
        [resultKey]: responseBody,
      },
    }),
    update: {
      negativeDopingResultsCalendars: (prev, next) => ({
        ...prev,
        ...next,
      }),
    },
  });

const useGetRacesWithNegativeDoping = (
  request: GetRacesWithNegativeDopingRequest,
): ApiResponse<NegativeDopingResultsCalendar | undefined, Entities> => {
  const resultKey = `negativeDopingResultsCalendar-${request.fromdate}-${request.todate}`;

  const query = getQuery(resultKey, request);

  const selector = (state: State): NegativeDopingResultsCalendar | undefined =>
    state.entities['negativeDopingResultsCalendars'] &&
    state.entities['negativeDopingResultsCalendars'][resultKey];

  return useApi<NegativeDopingResultsCalendar | undefined>({
    query,
    selector,
  });
};

export default useGetRacesWithNegativeDoping;

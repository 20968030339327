import getToday from '@utils/getToday';
import * as Yup from 'yup';

import FormState from './interface/FormState';

type Schema = Yup.SchemaOf<FormState>;

const getValidationSchema = (authenticationMethod: string): Schema => {
  const schema = Yup.object()
    .shape({
      registrationStatus: Yup.string().required('Obligatorisk').nullable(),
      registrationDate: Yup.date()
        .required('Obligatorisk')
        .typeError('Ange ett datum')
        .max(getToday(), 'Ej senare än dagens datum'),
      ...(authenticationMethod === 'PASSWORD' && {
        password: Yup.string().required('Obligatorisk'),
      }),
    })
    .defined();

  return schema;
};

export default getValidationSchema;

import {
  listSelections,
  ListSelectionsRequest,
  Selection,
} from '@generated/charts/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  jockeySelections?: Selection[];
  trainerSelections?: Selection[];
  horseSelections?: Selection[];
  ownerSelections?: Selection[];
  breederSelections?: Selection[];
  breedingSelections?: Selection[];
}

const useListSelections = (
  actorType: string,
  request: ListSelectionsRequest,
): ApiResponse<Selection[], Entities> => {
  const query = listSelections<Entities>(request, {
    transform: responseBody => ({
      [`${actorType}Selections`]: responseBody,
    }),
    update: {
      [`${actorType}Selections`]: (
        _prev: Selection[],
        next: Selection[],
      ): typeof next => next,
    },
  });
  const selector = (state: State): Selection[] =>
    state.entities[`${actorType}Selections`];

  return useApi<Selection[]>({
    query,
    selector,
  });
};

export default useListSelections;

// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Credentials
 */
export interface Credentials  {
    /**
     * Users ip address
     * @type {string}
     * @memberof Credentials
     */
    endUserIp?: string;
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    password: string;
}

export function CredentialsFromJSON(json: any): Credentials {
    return {
        'endUserIp': !exists(json, 'endUserIp') ? undefined : json['endUserIp'],
        'password': json['password'],
    };
}

export function CredentialsToJSON(value?: Credentials): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'endUserIp': value.endUserIp,
        'password': value.password,
    };
}



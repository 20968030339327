import { Entities } from '@apis';
import { deleteChart } from '@generated/user/src';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../utils/types';
import { useApiMutateWithoutData } from '../utils/useApiMutate';

interface Args {
  typeId: number;
  chartId: string;
}

const useDeleteSavedChart = (
  licenseId: number,
  categoryId: number,
): MutationResponseWithoutData<Entities, Args> => {
  const key = `${categoryId}-${licenseId}`;

  return useApiMutateWithoutData({
    queryFunc: ({ chartId, typeId }: Args): QueryConfig<Entities> =>
      deleteChart<Entities>(
        {
          licenseId,
          categoryId,
          chartId,
          typeId,
        },
        {
          optimisticUpdate: {
            savedChartFilters: prev => ({
              ...prev,
              [key]: prev[key].filter(chart => chart.chartId !== chartId),
            }),
          },
        },
      ),
    queryKey: 'deleteSavedChart',
  });
};

export default useDeleteSavedChart;

// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface StartMonitoring
 */
export interface StartMonitoring  {
    /**
     * 
     * @type {number}
     * @memberof StartMonitoring
     */
    horseId?: number;
    /**
     * 
     * @type {string}
     * @memberof StartMonitoring
     */
    horseName?: string;
    /**
     * 
     * @type {Date}
     * @memberof StartMonitoring
     */
    raceDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StartMonitoring
     */
    trackCode?: string;
    /**
     * 
     * @type {number}
     * @memberof StartMonitoring
     */
    raceNumber?: number;
    /**
     * If active competition is true, there is a completed start list which can be linked.
     * @type {boolean}
     * @memberof StartMonitoring
     */
    hasActiveCompetition?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StartMonitoring
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof StartMonitoring
     */
    raceDayId?: number;
}

export function StartMonitoringFromJSON(json: any): StartMonitoring {
    return {
        'horseId': !exists(json, 'horseId') ? undefined : json['horseId'],
        'horseName': !exists(json, 'horseName') ? undefined : json['horseName'],
        'raceDate': !exists(json, 'raceDate') ? undefined : parseDateTime(json['raceDate']),
        'trackCode': !exists(json, 'trackCode') ? undefined : json['trackCode'],
        'raceNumber': !exists(json, 'raceNumber') ? undefined : json['raceNumber'],
        'hasActiveCompetition': !exists(json, 'hasActiveCompetition') ? undefined : json['hasActiveCompetition'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'raceDayId': !exists(json, 'raceDayId') ? undefined : json['raceDayId'],
    };
}

export function StartMonitoringToJSON(value?: StartMonitoring): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'horseName': value.horseName,
        'raceDate': value.raceDate === undefined ? undefined : value.raceDate.toLocalDate(),
        'trackCode': value.trackCode,
        'raceNumber': value.raceNumber,
        'hasActiveCompetition': value.hasActiveCompetition,
        'text': value.text,
        'raceDayId': value.raceDayId,
    };
}



// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Start,
    StartFromJSON,
    StartToJSON,
    Starts,
    StartsFromJSON,
    StartsToJSON,
} from '../models';

export interface GetDriverStartsRequest {
    licenseId: number;
    includeregistered?: boolean;
    withdrawn?: boolean;
}

export interface GetDriverStartsRaceDayRequest {
    licenseId: number;
    racedayid: number;
}


/**
 * Get one driver\'s starts based on the request params as filter This start list is used to get a view of all the drivers recent starts and then let the user go into a more detailed view by fetching a per-race-day starts list (see below).
 */
function getDriverStartsRaw<T>(requestParameters: GetDriverStartsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Starts>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getDriverStarts.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.includeregistered !== undefined) {
        queryParameters['includeregistered'] = requestParameters.includeregistered;
    }


    if (requestParameters.withdrawn !== undefined) {
        queryParameters['withdrawn'] = requestParameters.withdrawn;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/driverstarts`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(StartsFromJSON), text);
    }

    return config;
}

/**
* Get one driver\'s starts based on the request params as filter This start list is used to get a view of all the drivers recent starts and then let the user go into a more detailed view by fetching a per-race-day starts list (see below).
*/
export function getDriverStarts<T>(requestParameters: GetDriverStartsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Starts>>): QueryConfig<T> {
    return getDriverStartsRaw(requestParameters, requestConfig);
}

/**
 * Get one driver\'s starts for a race day based on the request params as filterThis per-race-day driver starts list gives a more detailed view of the starts and is used to form a basis for registering costs.
 */
function getDriverStartsRaceDayRaw<T>(requestParameters: GetDriverStartsRaceDayRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Start>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getDriverStartsRaceDay.');
    }

    if (requestParameters.racedayid === null || requestParameters.racedayid === undefined) {
        throw new runtime.RequiredError('racedayid','Required parameter requestParameters.racedayid was null or undefined when calling getDriverStartsRaceDay.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/driverstarts/{racedayid}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"racedayid"}}`, encodeURIComponent(String(requestParameters.racedayid))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(StartFromJSON), text);
    }

    return config;
}

/**
* Get one driver\'s starts for a race day based on the request params as filterThis per-race-day driver starts list gives a more detailed view of the starts and is used to form a basis for registering costs.
*/
export function getDriverStartsRaceDay<T>(requestParameters: GetDriverStartsRaceDayRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Start>>): QueryConfig<T> {
    return getDriverStartsRaceDayRaw(requestParameters, requestConfig);
}


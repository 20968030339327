import {
  useGetCoveringColors,
  useGetCoveringGenders,
  useGetCoveringResult,
} from '@apis';
import DataGrid from '@components/DataGrid';
import { CoveringResult, Mare } from '@generated/breedings/src';
import Loading from '@primitives/Loading';
import { formatTz } from '@utils/dateUtils';
import React, { useCallback, useEffect } from 'react';
import { CellProps, Renderer } from 'react-table';

import { useCoveringContext } from '../context';
import MareTableCell from '../MareTableCell';
import EditCell from './EditCell';

export default function ResultsList(): JSX.Element {
  const { setOnSuccess, year, studId } = useCoveringContext();

  const { data, loading, refresh } = useGetCoveringResult(year, studId);
  const { data: colors } = useGetCoveringColors();
  const { data: genders } = useGetCoveringGenders();

  const onSuccess = useCallback(async () => {
    await refresh();
  }, [refresh]);

  useEffect(() => {
    setOnSuccess(onSuccess);
  }, [onSuccess, setOnSuccess]);

  if (loading && !data) {
    return <Loading mt={5} />;
  }

  return (
    <DataGrid<CoveringResult>
      emptyRow
      columns={[
        {
          Header: 'Sto',
          accessor: 'mare',
          Cell: MareTableCell as Renderer<CellProps<CoveringResult, Mare>>,
        },
        {
          Header: 'Språng',
          accessor: 'coveringOccasions',
          Cell: ({ value }) => (
            <>
              {value.map(date => (
                <div key={date}>{date}</div>
              ))}
            </>
          ),
        },
        {
          Header: 'Resultat',
          accessor: v => v.outcome?.description,
          id: 'outcome',
          Cell: EditCell,
        },
        {
          Header: 'Född',
          accessor: 'birthDate',
          Cell: ({ value }) => <>{formatTz(value, 'yyyy-MM-dd')}</>,
        },
        {
          Header: 'Kön',
          accessor: 'gender',
          Cell: ({ value }) => (
            <>
              {(value &&
                genders?.selectionOptions.find(item => item.value === value)
                  ?.name) ||
                null}
            </>
          ),
          tdStyle: () => ({ textTransform: 'capitalize' }),
        },
        {
          Header: 'Färg',
          accessor: 'color',
          Cell: ({ value }) => (
            <>
              {(value &&
                colors?.selectionOptions.find(item => item.value === value)
                  ?.name) ||
                null}
            </>
          ),
          tdStyle: () => ({ textTransform: 'capitalize' }),
        },
      ]}
      data={data || []}
    />
  );
}

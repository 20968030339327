import { useIssue } from '@apis';
import Alert from '@components/Alert';
import { DatePicker, Select } from '@components/FormikComponents';
import { Input as FormikInput } from '@components/FormikComponents';
import InfoBadge from '@components/InfoBadge';
import Step from '@components/Step';
import { AuthenticatedUser } from '@generated/account/src';
import { IssueTemplate } from '@generated/content/src';
import useIsMobile from '@hooks/useIsMobile';
import theme from '@main/theme';
import Box, { FadedBox, Flex } from '@primitives/Box';
import Button, { ButtonLink } from '@primitives/Button';
import { Checkbox } from '@primitives/Form';
import Input from '@primitives/Form/input';
import Icon from '@primitives/Icon';
import SwitchButton from '@primitives/SwitchButton';
import { H3, Text } from '@primitives/Typography';
import { Label } from '@primitives/Typography';
import { CircleInfo as InfoCircle } from '@styled-icons/fa-solid';
import isLoggedIn from '@utils/isLoggedIn';
import { Formik, FormikErrors } from 'formik';
import React, {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { ExclamationTriangle, ExternalLinkAlt } from 'styled-icons/fa-solid';

import { fields, FormField } from './definitions/_fields';
import { Template, templateData } from './definitions/_templateData';
import { HorseInfo } from './horseInfo';
import { HorseSearch } from './horseSearch';
import { PersonInfo } from './personInfo';
import { HorsesContext } from './withHorses';

interface FormProps {
  user: AuthenticatedUser;
  template: (typeof templateData)[number];
  issueTemplate: IssueTemplate;
}

type FormState = {
  template: number;
  [key: string]: string | number | undefined;
};

type SetFieldValueType = (
  field: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  shouldValidate?: boolean,
) => Promise<void | FormikErrors<FormState>>;

export const isMinor = (user: AuthenticatedUser): boolean => {
  const idNumber = user.userInfo.idNumber;
  const birthDate = new Date(
    `${idNumber.substring(0, 4)}-${idNumber.substring(4, 6)}-${idNumber.substring(6, 8)}T12:00:00.000Z`,
  );

  const diff = Date.now() - birthDate.getTime();
  const ageDate = new Date(diff);
  return Math.abs(ageDate.getUTCFullYear() - 1970) < 18;
};

const ExternalLink = styled(Text).attrs({
  display: 'flex',

  height: 'fit-content',
  width: 'fit-content',
  textAlign: 'right',
})``;

export function IssueFormBuilder({
  user,
  template,
  issueTemplate,
}: FormProps): JSX.Element | null {
  const [textOpen, setTextOpen] = useState(false);
  const isMobile = useIsMobile();

  const toggleLimit = isMobile ? 60 : 80;
  const { ownedHorses, trainedHorses, userData } = useContext(HorsesContext);
  const [open, setOpen] = useState(false);
  const [horseId, setHorseId] = useState<number>(0);
  const [horseSearchId, setHorseSearchId] = useState<number>(0);
  const [stallionId, setStallionId] = useState<number>(0);
  const [personCount, setPersonCount] = useState<number>(1);
  const [contractURL, setContractURL] = useState<string | undefined>(undefined);

  const { create } = useIssue();

  /**
   * Function for getting the horses
   */
  const cbHorses = useCallback(
    (options?: string[]) =>
      ownedHorses
        ?.filter(horse => {
          // Registration status changeable
          const registrationStatusChangeable = options.includes(
            'registrationStatusChangeable',
          )
            ? horse.horseOwnership.registrationStatusChangeable
            : true;

          // User representative
          const userRepresentative = options.includes('userRepresentative')
            ? horse.userRepresentative
            : true;

          // Mare
          const mare = options.includes('mare')
            ? horse.horseOwnership.gender.code === 'S'
            : true;

          // Should be included?
          return registrationStatusChangeable && userRepresentative && mare;
        })
        .map(horseOwnerhip => ({
          label: horseOwnerhip.horseOwnership.horse.name,
          value: horseOwnerhip.horseOwnership.horse.id.toString(),
        })),
    [ownedHorses],
  );

  /**
   * Function for getting the horses
   */
  const cbHorsesTraining = useCallback(
    () =>
      trainedHorses?.map(horseTrained => ({
        label: horseTrained.horse.name,
        value: horseTrained.horse.id.toString(),
      })),
    [trainedHorses],
  );

  const isHidden = useCallback(
    (
      field: FormField | Template['fields'][0],
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      values: FormState,
    ): boolean => {
      if (field.hidden) {
        return eval(`${field.hidden}`);
      }

      const option = template.fieldOptions?.find(option => option[field.name]);
      if (option && option[field.name]?.hidden) {
        return eval(`${option[field.name].hidden}`);
      }

      return false;
    },
    [template.fieldOptions],
  );

  const getHorseId = useCallback(
    (name: string): number => {
      if (name === 'horseInfo' || name === 'mareInfo') {
        return horseId;
      }
      if (name === 'stallionInfo') {
        return stallionId;
      }
      if (name === 'horseSearchInfo') {
        return horseSearchId;
      }
      return 0;
    },
    [horseId, horseSearchId, stallionId],
  );

  const getField = useCallback(
    (
      field: string,
      values: FormState,
      setFieldValue: SetFieldValueType,
    ): JSX.Element => {
      const def = fields.find(f => f.name === field);

      if (!def) {
        console.error('No field found', field);
        return null;
      }

      const options = template.fieldOptions.find(option => option[field])?.[
        def.name
      ];

      // Is the object hidden?
      if (isHidden(def, values)) {
        return null;
      }

      // Switchbutton
      if (def?.type === 'switchbutton') {
        return (
          <Box sx={{ gridColumn: [, , 'span 3'] }}>
            <SwitchButton
              name={def.name}
              on={def.options[0]}
              off={def.options[1]}
              defaultChecked={false}
              onChange={element => {
                if (def.name !== 'horseSwitchButtonStallion') {
                  setHorseId(0);
                  setHorseSearchId(0);
                  setFieldValue('horse', undefined, true);
                  setFieldValue('trainer', undefined, true);
                }
                setFieldValue(def.name, element.target.checked, true);
                setFieldValue('horseInTraining', undefined, true);
                setFieldValue('horseOutOfTraining', undefined, true);
                setFieldValue('horseLocation', undefined, true);
              }}
            />
          </Box>
        );
      }

      // Select
      if (def?.type === 'select') {
        const selectOptions =
          (def.name === 'horse' || def.name === 'mare') && cbHorses(def.options)
            ? [{ label: '', value: '' }, ...cbHorses(def.options)]
            : def.name === 'horseTraining' && cbHorsesTraining()
              ? [{ label: '', value: '' }, ...cbHorsesTraining()]
              : [];

        const onChange =
          def.name === 'horse' ||
          def.name === 'horseTraining' ||
          def.name === 'mare'
            ? (element: React.ChangeEvent<HTMLSelectElement>) => {
                const id = Number.parseInt(element.target.value);
                setHorseId(id);
                setFieldValue(options.output ?? def.name, id);
              }
            : undefined;
        return (
          <Box key={def.name} sx={{ flexGrow: 1 }} position={'relative'} pt={1}>
            <Select
              label={def.label}
              name={def.name}
              required={!def.notRequired}
              options={selectOptions}
              onChange={onChange}
            />
          </Box>
        );
      }

      // Select
      if (def?.type === 'sex') {
        return (
          <Box key={def.name} sx={{ flexGrow: 1 }} position={'relative'} pt={1}>
            <Select
              label={def.label}
              name={def.name}
              onChange={e => {
                setFieldValue(def.name, e.target.value);
              }}
              required={!def.notRequired}
              options={['Välj kön', 'Hingst', 'Sto', 'Valack'].map(value => ({
                label: value,
                value,
              }))}
            />
          </Box>
        );
      }

      if (def?.type === 'horseInfo') {
        if (!getHorseId(def.name)) {
          return null;
        }
        return (
          <HorseInfo
            def={def}
            onReset={() => {
              if (def.name === 'horseInfo' || def.name === 'mareInfo') {
                setHorseId(0);
              } else if (def.name === 'stallionInfo') {
                setStallionId(0);
              } else if (def.name === 'horseSearchInfo') {
                setHorseSearchId(0);
              }
            }}
            horseId={getHorseId(def.name)}
          />
        );
      }

      if (def?.type === 'date') {
        return (
          <div>
            <Label htmlFor={def.name}>{def.label}</Label>
            <DatePicker
              autoComplete="anyrandomstring"
              disabled={false}
              name={def.name}
              maxDate={options?.max ? eval(`${options.max}`) : undefined}
            />
          </div>
        );
      }

      if (def?.type === 'personInfo') {
        return <PersonInfo def={def} user={user} userData={userData} />;
      }

      if (def?.type === 'multiplePersons') {
        const showUser = options?.showUser
          ? options?.showUser === true || eval(options?.showUser)
          : false;

        return (
          <Box key={def.name} width={'100%'} sx={{ gridColumn: 'span 3' }}>
            {showUser && (
              <Box
                width={'100%'}
                my={2}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: '20px',
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Label>Namn</Label>
                  <Input
                    disabled
                    value={`${user.userInfo.firstName} ${user.userInfo.lastName}`}
                  />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Label>E-post</Label>
                  <Input
                    disabled
                    value={userData.changeableUserInformation.email}
                  />
                </Box>
              </Box>
            )}
            {[...Array(personCount)].map((e, i) => (
              <Box
                key={`${def.name}.${i + 1}`}
                width={'100%'}
                my={2}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: '20px',
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Label htmlFor={`${def.name}.${i}.name`}>Namn</Label>
                  <FormikInput name={`${def.name}.${i}.name`} />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Label htmlFor={`${def.name}.${i}.email`}>E-post</Label>
                  <FormikInput name={`${def.name}.${i}.email`} />
                </Box>
              </Box>
            ))}
            <ButtonLink
              onClick={() => {
                setPersonCount(personCount + 1);
              }}
            >
              + Lägg till person
            </ButtonLink>
          </Box>
        );
      }

      if (def?.type === 'search') {
        return (
          <HorseSearch
            def={def}
            gender={
              def.name === 'stallionSearch'
                ? 'H'
                : def.name === 'mareSearch'
                  ? 'S'
                  : undefined
            }
            onSelect={horseId => {
              if (def.name === 'stallionSearch') {
                setStallionId(horseId);
              } else {
                setHorseSearchId(horseId);
              }
            }}
          />
        );
      }

      if (def?.type === 'nameWithSuffix') {
        return (
          <Box key={def.name} sx={{ flexGrow: 1, position: 'relative' }}>
            <Box
              sx={{
                display: 'grid',
                gap: '5px',
                gridTemplateColumns: '3fr 1fr',
              }}
            >
              <div>
                <Label htmlFor={def.name}>{def.label}</Label>
                <FormikInput
                  name={def.name}
                  required
                  maxLength={
                    options?.max
                      ? Number.parseInt(options?.max.toString())
                      : undefined
                  }
                />
                <Text
                  position={'relative'}
                  top={1}
                  fontSize="small"
                  mb={'-1px'}
                  bottom={0}
                  pb={0}
                  alignSelf={'baseline'}
                >
                  {def.description ?? <br />}
                </Text>
              </div>
              <div>
                <Label htmlFor={`${def.name}_suffix`}>Ev. suffix</Label>
                <FormikInput name={`${def.name}_suffix`} />
              </div>
            </Box>
          </Box>
        );
      }

      // Default is an input
      const output = options?.output
        ? {
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(def.name, e.target.value);
              if (options.outputBefore) {
                const value = `${e.target.value} ${values[options.outputBefore] ?? ''}`;
                setFieldValue(options.output, value);
              } else if (options.outputAfter) {
                const value = `${values[options.outputAfter]} ${e.target.value ?? ''}`;
                setFieldValue(options.output, value);
              } else {
                setFieldValue(options.output, e.target.value);
              }
            },
          }
        : null;

      return (
        <Box key={def.name} sx={{ flexGrow: 1, position: 'relative' }}>
          <Label htmlFor={def.name}>{def.label}</Label>

          <FormikInput
            name={def.name}
            required
            max={options?.max}
            {...output}
          />
          <Text
            position={'relative'}
            top={1}
            fontSize="small"
            mb={'-1px'}
            bottom={0}
            pb={0}
            alignSelf={'baseline'}
          >
            {def.description ?? <br />}
          </Text>
        </Box>
      );
    },
    [
      template.fieldOptions,
      isHidden,
      cbHorses,
      cbHorsesTraining,
      getHorseId,
      user,
      userData,
      personCount,
    ],
  );

  const validate = useCallback(
    (values: FormState) => {
      const errors = {};
      template.fields
        .filter(f => !isHidden(f, values))
        .forEach(field => {
          field.fields.forEach(field => {
            const def = fields.find(f => f.name === field);

            if (!def) {
              console.error('No field found', field);
              return null;
            }

            const options = template.fieldOptions.find(
              option => option[field],
            )?.[def.name];

            // Is the object hidden?
            if (isHidden(def, values)) {
              return null;
            }

            // Text
            if (
              !def?.notRequired &&
              !values[options?.output ?? def.name] &&
              (def.type === 'text' || def.type === 'name')
            ) {
              errors[def.name] = 'Obligatoriskt';
            }

            // Search
            if (
              !def?.notRequired &&
              !values[options?.output ?? def.name] &&
              def.type === 'search'
            ) {
              errors[def.name] = 'Obligatoriskt';
            }

            // Å-Ä-Ö for name
            if (
              def.type === 'name' &&
              values[options?.output ?? def.name] &&
              !/^[a-zA-ZåäöÅÄÖ ]+$/.test(
                values[options?.output ?? def.name] as string,
              )
            ) {
              errors[def.name] = 'Endast A-Ö och mellanslag';
            }

            // Name with suffix
            if (
              !def?.notRequired &&
              !values[options?.output ?? def.name] &&
              def.type === 'nameWithSuffix'
            ) {
              errors[def.name] = 'Obligatoriskt';
            }

            // Select
            if (
              !def?.notRequired &&
              (!values[options?.output ?? def.name] ||
                values[options?.output ?? def.name] === 'Välj kön') &&
              (def.type === 'select' || def.type === 'sex')
            ) {
              errors[def.name] = 'Obligatoriskt';
            }

            // Date
            if (
              !def?.notRequired &&
              !values[options?.output ?? def.name] &&
              def.type === 'date'
            ) {
              errors[def.name] = 'Obligatoriskt';
            }
          });
        });

      return errors;
    },
    [isHidden, template.fieldOptions, template.fields],
  );

  // Get all boxes
  const boxes = useCallback(
    (values: FormState, setFieldValue: SetFieldValueType) =>
      template.fields
        .filter(f => !isHidden(f, values))
        .map((field, index) => (
          <Step
            mb={5}
            mx={[0, , 2]}
            key={field.name}
            header={`${index + 1}. ${field.name}`}
            verticalSpacing={2}
            pb={2}
            width={'100%'}
          >
            <Text fontSize={'normal'}>{field.description}</Text>
            {field.conditions &&
              field.conditions
                .filter(condition => eval(`${condition.condition}`))
                .map(condition => (
                  <Box pt={1} key={condition.text}>
                    <InfoBadge
                      icon={InfoCircle}
                      color={'blue'}
                      message={<>{condition.text}</>}
                    />
                  </Box>
                ))}
            <Box
              py={2}
              px={0}
              sx={{
                display: 'grid',
                gridTemplateColumns: ['repeat(1, 1fr)', , 'repeat(3, 1fr)'],
                gap: '20px',
              }}
            >
              {field.fields.map(subField => (
                <Fragment key={subField}>
                  {getField(subField, values, setFieldValue)}
                </Fragment>
              ))}
            </Box>
          </Step>
        )),
    [template.fields, isHidden, getField],
  );

  const getDescription = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (values: FormState): JSX.Element => {
      let description: JSX.Element = null;
      if (template.provider === 'scrive') {
        description = (
          <Text>
            Är du säker på att du vill skicka ärendet till signering?
            <br />
            <br />
          </Text>
        );
      } else {
        description = (
          <Text>
            Är du säker på att du vill skicka ärendet till Svensk Galopp för
            handläggning?
            <br />
            <br />
          </Text>
        );
      }
      return description;
    },
    [template.provider],
  );

  // TODO: Improve this maybe? Insecure?
  const getDefaultValue = useCallback(
    (name: string): string | undefined => {
      try {
        return eval(`${name}`);
      } catch (e) {
        return '';
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [template.id],
  );

  // Get initial values for the form
  const initialValues = useMemo(() => {
    const initial: FormState = {
      template: template.id,
      senderId: user?.licenseId,
      firstName: userData?.basicUserInformation.firstName,
      lastName: userData?.basicUserInformation.lastName,
      email: userData?.changeableUserInformation.email,
      personalNumber: user?.userInfo.idNumber,
      fullName: `${userData?.basicUserInformation.firstName} ${userData?.basicUserInformation.lastName}`,
      phone:
        userData?.basicUserInformation.mobilePhoneNumber ??
        userData?.basicUserInformation.homePhoneNumber,
      address: userData?.basicUserInformation.address.streetAddress,
      zipCodeCity: `${userData?.basicUserInformation.address.postCode} ${userData?.basicUserInformation.address.city}`,
      importCountry: null,
    };

    const templateFields = template?.fields.flatMap(field => field.fields);
    fields
      .filter(field => templateFields?.includes(field.name))
      .forEach(field => {
        if (field.type === 'text') {
          initial[field.name] = getDefaultValue(field.defaultValue);
        }
      });

    return { ...initial };
  }, [
    getDefaultValue,
    template?.fields,
    template.id,
    user?.licenseId,
    user?.userInfo.idNumber,
    userData?.basicUserInformation.address.city,
    userData?.basicUserInformation.address.postCode,
    userData?.basicUserInformation.address.streetAddress,
    userData?.basicUserInformation.firstName,
    userData?.basicUserInformation.homePhoneNumber,
    userData?.basicUserInformation.lastName,
    userData?.basicUserInformation.mobilePhoneNumber,
    userData?.changeableUserInformation.email,
  ]);

  // If there is no data, return null and we need login return null
  const needLogin = false;
  if (!userData && needLogin) {
    return null;
  }

  // Submit the form
  const onSubmit = async (values: FormState): Promise<void> => {
    const response = await create(template.provider, {
      templateUrl: `/tjanster/nytt?templateId=${template.id}`,
      senderId: user?.licenseId,
      actorType: template.actorType,
      email: userData?.changeableUserInformation.email as string,
      firstName: values.firstName as string,
      lastName: values.lastName as string,
      template: template.template?.toString(),
      values: values,
    });

    const data = await response.json();

    if (response.status !== 200 || data.status === 'error') {
      console.error({ response });
      return;
    }

    // Open new page with contract
    if (data.contract_url) {
      setContractURL(data.contract_url);
      window.open(data.contract_url, '_blank');
    }

    location.href = '/minasidor/arenden?new=true';
  };

  return (
    <>
      <Box px={2} py={1} pb={0}>
        <FadedBox
          maxHeight={textOpen ? 'none' : `${toggleLimit}px`}
          overflowY="hidden"
          ml={0}
          pb={0}
          fadedBottom={!textOpen ? 50 : 0}
          fadedBg={theme.colors['white']}
          onClick={() => !textOpen && setTextOpen(!textOpen)}
        >
          <Text
            dangerouslySetInnerHTML={{
              __html: issueTemplate.description.replace(
                'a href=',
                `a target='_blank' href=`,
              ),
            }}
          />
        </FadedBox>
        <ButtonLink
          onClick={() => setTextOpen(!textOpen)}
          color="gray5"
          lineHeight="18px"
          fontSize="small"
          mt={textOpen ? 2 : 1}
          sx={{
            borderBottom: 'solid 1px',
            textTransform: 'uppercase',
          }}
        >
          {textOpen ? 'Läs mindre' : 'Läs mer'}
        </ButtonLink>
        {isLoggedIn(user) && (
          <Box pt={4} pb={4}>
            <InfoBadge
              icon={InfoCircle}
              color={'blue'}
              message={
                <>
                  {user?.userInfo.firstName} {user?.userInfo.lastName}
                  {', '}
                  {userData.changeableUserInformation.email}
                  {', '}
                  {userData.basicUserInformation?.mobilePhoneNumber ??
                    userData.basicUserInformation?.homePhoneNumber}
                </>
              }
            />
          </Box>
        )}
      </Box>
      <Formik<FormState>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validate={validate}
      >
        {({
          values,
          setFieldValue,
          setSubmitting,
          setFieldTouched,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <>
            <Prompt
              when={dirty}
              message="Du har osparade ändringar, säkert att du vill lämna sidan?"
            />
            <Alert
              shouldCloseOnOverlayClick={false}
              closeModal={() => {
                setOpen(false);
              }}
              open={open}
              size="medium"
              action="Skicka"
              title={issueTemplate.title}
              icon={ExclamationTriangle}
              loading={isSubmitting}
              onAction={async () => {
                setSubmitting(true);
                await onSubmit(values);
                setOpen(false);
                setSubmitting(false);
              }}
              description={getDescription(values)}
              colorScheme="primary"
              iconProps={{
                size: 14,
                mb: '2px',
                mr: '1px',
              }}
            ></Alert>
            {template.description && (
              <Box mb={4} ml={2}>
                <H3>{template.description}</H3>
              </Box>
            )}
            {true && boxes(values, setFieldValue)}
            {template.confirm && (
              <Flex mb={4} ml={2} justifyContent={'start'} flexWrap="nowrap">
                <Checkbox
                  name="confirm"
                  onChange={e => {
                    setFieldValue('confirm', e.target.checked);
                  }}
                />
                <Text>
                  {template.confirm.text}
                  {/* <Link
                    to={template.confirm.link}
                    ml={'2px'}
                    style={{ display: 'inline-flex' }}
                  >
                    {template.confirm.linkText}
                  </Link> */}

                  <ExternalLink
                    as="a"
                    ml={'2px'}
                    style={{ display: 'inline-flex' }}
                    href={template.confirm.link}
                    target="_blank"
                  >
                    {template.confirm.linkText}
                    <Box>
                      <Icon
                        size={12}
                        ml={'3px'}
                        color="blue"
                        as={ExternalLinkAlt}
                      />
                    </Box>{' '}
                  </ExternalLink>
                </Text>
              </Flex>
            )}
            <Box mb={4} ml={2}>
              <Button
                disabled={
                  !!contractURL || !isValid || !dirty || template.confirm
                    ? !values.confirm
                    : false
                }
                onClick={e => {
                  const errors = validate(values);
                  if (Object.keys(errors).length > 0) {
                    Object.keys(errors).forEach(key => {
                      setFieldTouched(key, true, false);
                    });
                  } else {
                    e.preventDefault();
                    setOpen(true);
                  }
                }}
              >
                Skicka
              </Button>
              {contractURL && (
                <a
                  href={contractURL}
                  style={{ paddingLeft: '20px' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Öppna avtal för att signera
                </a>
              )}
            </Box>
          </>
        )}
      </Formik>
    </>
  );
}

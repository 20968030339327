import { MultiSelect as PrimitiveMultiSelect } from '@primitives/Form';
import { useField, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { Option } from 'react-multi-select-component';

import Error from './Error';

interface Props {
  label: string;
  name: string;
  options: Array<Option>;
  hasSelectAll?: boolean;
  onChange?: (selectedOptions: Array<Option>) => void;
}

function MultiSelect({
  label,
  name,
  options,
  hasSelectAll,
  onChange,
}: Props): JSX.Element {
  const [field, meta] = useField({ name, type: 'select' });
  const { setFieldValue } = useFormikContext();

  const onChangeCallback = useCallback(
    (selectedOptions: Array<Option>): void => {
      setFieldValue(
        field.name,
        selectedOptions.map((selectedOption: Option) => selectedOption.value),
      );
      if (onChange) {
        onChange(selectedOptions);
      }
    },
    [field, onChange, setFieldValue],
  );

  const getValueCallback = useCallback(
    (): Array<Option> =>
      options.filter(option => field.value.indexOf(option.value) >= 0),
    [options, field],
  );

  return (
    <>
      <label>{label}</label>
      <PrimitiveMultiSelect
        {...field}
        options={options}
        hasSelectAll={hasSelectAll}
        onChange={onChangeCallback}
        value={getValueCallback()}
      ></PrimitiveMultiSelect>
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </>
  );
}

export default MultiSelect;

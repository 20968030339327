import DownloadLinkInNewWindow from '@features/DownloadLinkInNewWindow';
import GenerateInvoiceUrlAndRedirect from '@features/GenerateInvoiceUrlAndRedirect';
import GenerateNordicCertificateUrlAndRedirect from '@features/GenerateNordicCertificateUrlAndRedirect copy';
import GenerateTdsListUrlAndRedirect from '@features/GenerateTdsListUrlAndRedirect';
import {
  GetUrlAndRedirectRaceCard,
  GetUrlAndRedirectStartList,
} from '@features/GetUrlAndRedirect';
import PrintAncestorTree from '@features/PrintAncestorTree';

export const rootRoutes = [
  {
    path: '/printHerstammning/:horseId?',
    component: PrintAncestorTree,
  },
  {
    path: '/storage/racecard/:raceDayId',
    component: GetUrlAndRedirectRaceCard,
  },
  {
    path: '/storage/startlist/:raceDayId',
    component: GetUrlAndRedirectStartList,
  },
  {
    path: '/storage/invoice/:ownerId/:invoiceNumber/:index',
    component: GenerateInvoiceUrlAndRedirect,
  },
  {
    path: '/storage/tds-list/:licenseId/:documentId',
    component: GenerateTdsListUrlAndRedirect,
  },
  {
    path: '/storage/nordic/:licenseId/:fileId',
    component: GenerateNordicCertificateUrlAndRedirect,
  },
  {
    path: '/storage/external',
    component: DownloadLinkInNewWindow,
  },
];

// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    AuthenticatedUser,
    AuthenticatedUserFromJSON,
    AuthenticatedUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Credentials
 */
export interface Credentials  {
    /**
     * The method of credentials, can be useful for UI-presentation decisions
     * @type {string}
     * @memberof Credentials
     */
    authenticationMethod: CredentialsAuthenticationMethodEnum;
    /**
     * 
     * @type {AuthenticatedUser}
     * @memberof Credentials
     */
    authenticatedUser: AuthenticatedUser;
    /**
     * Remaining session time in milliseconds
     * @type {number}
     * @memberof Credentials
     */
    remainingSessionTime?: number;
}

export function CredentialsFromJSON(json: any): Credentials {
    return {
        'authenticationMethod': json['authenticationMethod'],
        'authenticatedUser': AuthenticatedUserFromJSON(json['authenticatedUser']),
        'remainingSessionTime': !exists(json, 'remainingSessionTime') ? undefined : json['remainingSessionTime'],
    };
}

export function CredentialsToJSON(value?: Credentials): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'authenticationMethod': value.authenticationMethod,
        'authenticatedUser': AuthenticatedUserToJSON(value.authenticatedUser),
        'remainingSessionTime': value.remainingSessionTime,
    };
}

/**
* @export
* @enum {string}
*/
export enum CredentialsAuthenticationMethodEnum {
    ANONYMOUS = 'ANONYMOUS',
    PASSWORD = 'PASSWORD',
    BANKID = 'BANKID',
    FREJA_EID = 'FREJA_EID',
    SUBSCRIPTION = 'SUBSCRIPTION'
}



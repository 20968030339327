import {
  getRepresentativeOwnerships,
  GetRepresentativeOwnershipsRequest,
  Ownership,
} from '../../generated/ownership-transfers/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  representativeOwnerships?: {
    [key: string]: Ownership[];
  };
}

const useGetRepresentativeOwnerships = ({
  licenseId,
}: GetRepresentativeOwnershipsRequest): ApiResponse<Ownership[], Entities> => {
  const key = `${licenseId}`;
  return useApi<Ownership[]>({
    query: getRepresentativeOwnerships<Entities>(
      { licenseId },
      {
        transform: responseBody => ({
          representativeOwnerships: {
            [key]: responseBody,
          },
        }),
        update: {
          representativeOwnerships: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities?.representativeOwnerships?.[key],
  });
};

export default useGetRepresentativeOwnerships;

// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    RaceDayWithNegativeDoping,
    RaceDayWithNegativeDopingFromJSON,
    RaceDayWithNegativeDopingToJSON,
} from './';

/**
 * 
 * @export
 * @interface NegativeDopingResultsCalendar
 */
export interface NegativeDopingResultsCalendar  {
    /**
     * 
     * @type {boolean}
     * @memberof NegativeDopingResultsCalendar
     */
    negativeResultsOutsideOfCompetitionExists: boolean;
    /**
     * 
     * @type {Array<RaceDayWithNegativeDoping>}
     * @memberof NegativeDopingResultsCalendar
     */
    raceDays: Array<RaceDayWithNegativeDoping>;
}

export function NegativeDopingResultsCalendarFromJSON(json: any): NegativeDopingResultsCalendar {
    return {
        'negativeResultsOutsideOfCompetitionExists': json['negativeResultsOutsideOfCompetitionExists'],
        'raceDays': (json['raceDays'] as Array<any>).map(RaceDayWithNegativeDopingFromJSON),
    };
}

export function NegativeDopingResultsCalendarToJSON(value?: NegativeDopingResultsCalendar): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'negativeResultsOutsideOfCompetitionExists': value.negativeResultsOutsideOfCompetitionExists,
        'raceDays': (value.raceDays as Array<any>).map(RaceDayWithNegativeDopingToJSON),
    };
}



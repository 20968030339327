import { getInvoiceTransactionsLatestBilledMonth } from '../../generated/sportactors/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = Date | undefined;

export interface Entities {
  invoiceTransactionsLatestBilledMonth?: Result;
}

function useGetInvoiceTransactionsLatestBilledMonth(
  ownerId: number,
): ApiResponse<Result, Entities> {
  const query = {
    ...getInvoiceTransactionsLatestBilledMonth<Entities>(
      {
        ownerId,
      },
      {
        update: {
          invoiceTransactionsLatestBilledMonth: (prev, next) => next,
        },
      },
    ),
    force: true,
    transform: body => ({
      invoiceTransactionsLatestBilledMonth: new Date(body),
    }),
  };

  return useApi<Result>({
    query,
    selector: state => state.entities?.invoiceTransactionsLatestBilledMonth,
  });
}

export default useGetInvoiceTransactionsLatestBilledMonth;

import CardWithHeader from '@components/CardWithHeader';
import Content from '@components/ContentBlock/Content';
import withUser from '@hoc/withUser';
import { OWNER_ROLES } from '@utils/constants';
import React from 'react';

function OwnerFaq(): JSX.Element {
  return (
    <CardWithHeader header="Vilka ägarfunktioner finns här?">
      <Content slug="faq-agarfunktioner" />
    </CardWithHeader>
  );
}

export default withUser(OwnerFaq, {
  roles: OWNER_ROLES,
  showError: false,
  showLoader: false,
});

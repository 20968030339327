const weekDays = [
  'Söndag',
  'Måndag',
  'Tisdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lördag',
];

const weekDaysShort = ['SÖN', 'MÅN', 'TIS', 'ONS', 'TOR', 'FRE', 'LÖR'];

const dayOfWeek = (
  year: number,
  month: number,
  index: number,
  short: boolean,
): string => {
  const date = new Date(year, month - 1, index);

  return short ? weekDaysShort[date.getDay()] : weekDays[date.getDay()];
};

export default dayOfWeek;

// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContentPageResourceDocument,
    ContentPageResourceDocumentFromJSON,
    ContentPageResourceDocumentToJSON,
} from './';

/**
 * 
 * @export
 * @interface RaceDayResource
 */
export interface RaceDayResource  {
    /**
     * 
     * @type {number}
     * @memberof RaceDayResource
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof RaceDayResource
     */
    dateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof RaceDayResource
     */
    track?: string;
    /**
     * 
     * @type {string}
     * @memberof RaceDayResource
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RaceDayResource
     */
    additionalInfo?: string;
    /**
     * 
     * @type {ContentPageResourceDocument}
     * @memberof RaceDayResource
     */
    courtReport?: ContentPageResourceDocument;
    /**
     * 
     * @type {ContentPageResourceDocument}
     * @memberof RaceDayResource
     */
    finishPhotos?: ContentPageResourceDocument;
    /**
     * 
     * @type {string}
     * @memberof RaceDayResource
     */
    photoArchiveLink?: string;
    /**
     * 
     * @type {string}
     * @memberof RaceDayResource
     */
    raceReplaysLink?: string;    
    /**
     * 
     * @type {string}
     * @memberof RaceDayResource
     */
    trackProgramLink?: string; 
    /**
     * 
     * @type {object}
     * @memberof RaceDayResource
     */
    reports?: {
        title: string;
        text: string;
        images?: {
            desktop: string;
            mobile: string;
            og: string;
        },
    };
    /**
     * 
     * @type {object}
     * @memberof RaceDayResource
     */    
    raceReports?: {
        race: number;
        title: string;
        text: string;
        images?: {
            desktop: string;
            mobile: string;
            og: string;
        },        
    }[];
    /**
     * 
     * @type {object}
     * @memberof RaceDayResource
     */
    relatedNews?: {
        id: number;
        slug: string;
        title: string;
        preamble: string;
        url: string;
        first_published_at: Date;
        images?: {
            desktop: string;
            mobile: string;
        },  
    }[];
}

export function RaceDayResourceFromJSON(json: any): RaceDayResource {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dateTime': !exists(json, 'date_time') ? undefined : new Date(json['date_time']),
        'track': !exists(json, 'track') ? undefined : json['track'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'additionalInfo': !exists(json, 'additional_info') ? undefined : json['additional_info'],
        'courtReport': !exists(json, 'court_report') ? undefined : ContentPageResourceDocumentFromJSON(json['court_report']),
        'finishPhotos': !exists(json, 'finish_photos') ? undefined : ContentPageResourceDocumentFromJSON(json['finish_photos']),
        'trackProgramLink': !exists(json, 'track_program_link') ? undefined : json['track_program_link'],
        'raceReplaysLink': !exists(json, 'race_replays_link') ? undefined : json['race_replays_link'],
        'photoArchiveLink': !exists(json, 'photo_archive_link') ? undefined : json['photo_archive_link'],
        'reports': !exists(json, 'reports') ? undefined : json['reports'],
        'raceReports': !exists(json, 'race_reports') ? undefined : json['race_reports'],
        'relatedNews': !exists(json, 'related_news') ? undefined : json['related_news'],
    };
}

export function RaceDayResourceToJSON(value?: RaceDayResource): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'date_time': value.dateTime === undefined ? undefined : value.dateTime.toISOString(),
        'track': value.track,
        'name': value.name,
        'additional_info': value.additionalInfo,
        'court_report': ContentPageResourceDocumentToJSON(value.courtReport),
        'finish_photos': ContentPageResourceDocumentToJSON(value.finishPhotos),
        'photo_archive_link': value.photoArchiveLink,
        'race_replays_link': value.raceReplaysLink,
        'track_program_link': value.trackProgramLink,
        'reports': value.reports,
        'race_reports': value.raceReports,
        'related_news': value.relatedNews,
    };
}



import { Credentials, getUser } from '@generated/user/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  user?: Credentials['authenticatedUser'];
  authenticationMethod?: string;
}

const useGetUser = (): ApiResponse<Entities['user'], Entities> => {
  const query = getUser({
    queryKey: 'getCurrentUser',
    transform: responseBody => ({
      user: responseBody.authenticatedUser,
      authenticationMethod: responseBody.authenticationMethod,
    }),
    update: {
      user: (prev, next): typeof next => next,
      authenticationMethod: (prev, next): typeof next => next,
    },
  });

  const selector = (state: State): Entities['user'] => state.entities.user;

  return useApi<Entities['user']>({
    query,
    selector,
  });
};

export default useGetUser;

// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    UpdateRegistrationStatusHorse,
    UpdateRegistrationStatusHorseFromJSON,
    UpdateRegistrationStatusHorseToJSON,
} from './';

/**
 * 
 * @export
 * @interface CollectedRegistrationStatus
 */
export interface CollectedRegistrationStatus  {
    /**
     * 
     * @type {UpdateRegistrationStatusHorse}
     * @memberof CollectedRegistrationStatus
     */
    updateRegistrationStatusHorse?: UpdateRegistrationStatusHorse;
    /**
     * The progress status of the BankID order
     * @type {string}
     * @memberof CollectedRegistrationStatus
     */
    status: CollectedRegistrationStatusStatusEnum;
    /**
     * The hint code of the BankID order.
     * @type {string}
     * @memberof CollectedRegistrationStatus
     */
    hintCode?: string;
    /**
     * The qrCodePattern of the BankID order.
     * @type {string}
     * @memberof CollectedRegistrationStatus
     */
    qrCodePattern?: string;    
}

export function CollectedRegistrationStatusFromJSON(json: any): CollectedRegistrationStatus {
    return {
        'updateRegistrationStatusHorse': !exists(json, 'updateRegistrationStatusHorse') ? undefined : UpdateRegistrationStatusHorseFromJSON(json['updateRegistrationStatusHorse']),
        'status': json['status'],
        'hintCode': !exists(json, 'hintCode') ? undefined : json['hintCode'],
        'qrCodePattern': !exists(json, 'qrCodePattern') ? undefined : json['qrCodePattern'],
    };
}

export function CollectedRegistrationStatusToJSON(value?: CollectedRegistrationStatus): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'updateRegistrationStatusHorse': UpdateRegistrationStatusHorseToJSON(value.updateRegistrationStatusHorse),
        'status': value.status,
        'hintCode': value.hintCode,
        'qrCodePattern': value.qrCodePattern,
    };
}

/**
* @export
* @enum {string}
*/
export enum CollectedRegistrationStatusStatusEnum {
    PENDING = 'pending',
    FAILED = 'failed',
    COMPLETE = 'complete'
}



import {
  BankIdCollectedAccountCreation,
  BankIdCollectedAccountCreationStatusEnum,
  collectWithAnimatedQrCode,
  ErrorResponse,
} from '@generated/account/src';
import useBankIdCollect, {
  ReturnValues,
  State as BaseState,
  wrapCollectAction,
} from '@hooks/useBankIdCollect';
import { useRefreshUser } from '@hooks/useUser';
import auth from '@main/auth';
import { resetState } from '@main/store';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MutateAsyncAction } from 'redux-query';
import superagent from 'superagent';

interface State extends BaseState {
  orderReference?: string;
  qrTime?: number;
  qrCodePattern?: string;
  autoStartToken?: string;
  errorMessage?: string;
}

interface CollectResponse extends superagent.Response {
  body: BankIdCollectedAccountCreation & ErrorResponse;
}

interface Entities {
  user: BankIdCollectedAccountCreation['authenticatedUser'];
  authenticationMethod?: string;
}

const collectAction = (
  orderReference: string,
  qrTime: number,
): MutateAsyncAction<Entities> =>
  wrapCollectAction(
    collectWithAnimatedQrCode<Entities>(
      {
        orderReference,
        useCookie: false,
        qrTime,
      },
      {
        transform: responseBody => ({
          user: responseBody?.authenticatedUser,
          authenticationMethod: responseBody?.authenticationMethod,
        }),
        update: {
          user: (_prev, next): typeof next => next || _prev,
          authenticationMethod: (prev, next): typeof next => next,
        },
      },
    ),
  );

const useBankIdAccountCollect = (
  orderReference: string,
  qrInit: number,
): ReturnValues => {
  const [state, setState] = useState<State>({
    orderReference,
    qrCodePattern: undefined,
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const refreshUser = useRefreshUser();

  /**
   * Collect callback - polling for status
   */
  const runCollect = useCallback(async (): Promise<CollectResponse> => {
    const qrTime = Math.round((new Date().getTime() - qrInit) / 1000) || 1;
    if (qrTime > 30 && qrInit !== 0) {
      return {
        body: {
          errorMessage: 'Tiden för att signera har gått ut.',
          status: BankIdCollectedAccountCreationStatusEnum.FAILED,
        },
      } as unknown as CollectResponse;
    }
    const resp = (await dispatch(
      collectAction(orderReference, qrTime),
    )) as unknown as CollectResponse;

    if (resp.status === 200) {
      setState(prev => ({
        ...prev,
        qrCodePattern: resp.body.qrCodePattern,
      }));
      return resp;
    }

    // Dispatch usually doesn't return a response
    return resp;
  }, [dispatch, orderReference, qrInit]);

  const onComplete = useCallback(
    ({ headers }) => {
      auth.token = headers.authorization;
      history.push('/minasidor');
    },
    [history],
  );

  const beforeEachCollect = useCallback(() => {
    if (auth.token) {
      dispatch(resetState());
      refreshUser();

      history.push('/minasidor');
    }
  }, [dispatch, history, refreshUser]);

  const proccessError = useCallback(
    (code, message) =>
      // https://svenskgalopp.atlassian.net/browse/APP-735
      code === 1 ? 'Du har redan ett konto.' : message,
    [],
  );

  const response = useBankIdCollect({
    collectCallback: runCollect,
    beforeEachCollect,
    onComplete,
    proccessError,
  });

  return {
    ...state,
    ...response,
    result: state.result || response.result,
  };
};

export default useBankIdAccountCollect;

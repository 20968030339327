// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * All horse trainers.
 * @export
 * @interface Trainer
 */
export interface Trainer  {
    /**
     * The trainer id.
     * @type {number}
     * @memberof Trainer
     */
    id: number;
    /**
     * The trainer name.
     * @type {string}
     * @memberof Trainer
     */
    name: string;
    /**
     * The license text.
     * @type {string}
     * @memberof Trainer
     */
    licenseText?: string;
    /**
     * Is trainer linkable.
     * @type {boolean}
     * @memberof Trainer
     */
    linkable: boolean;
    /**
     * The from date.
     * @type {Date}
     * @memberof Trainer
     */
    from: Date;
    /**
     * The to date.
     * @type {Date}
     * @memberof Trainer
     */
    to?: Date;
}

export function TrainerFromJSON(json: any): Trainer {
    return {
        'id': json['id'],
        'name': json['name'],
        'licenseText': !exists(json, 'licenseText') ? undefined : json['licenseText'],
        'linkable': json['linkable'],
        'from': parseDateTime(json['from']),
        'to': !exists(json, 'to') ? undefined : parseDateTime(json['to']),
    };
}

export function TrainerToJSON(value?: Trainer): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'licenseText': value.licenseText,
        'linkable': value.linkable,
        'from': value.from.toLocalDate(),
        'to': value.to === undefined ? undefined : value.to.toLocalDate(),
    };
}



import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import {
  HorseSearchReference,
  searchHorses,
  SearchHorsesRequest,
} from '../../generated/horses/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  horses?: {
    [key: string]: HorseSearchReference[];
  };
}

const getQuery = (request: SearchHorsesRequest): QueryConfig<Entities> =>
  searchHorses<Entities>(
    {
      ...request,
    },
    {
      transform: responseBody => ({
        horses: {
          [JSON.stringify(request)]: responseBody,
        },
      }),
      update: {
        horses: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useSearchHorses = (
  request: SearchHorsesRequest,
): ApiResponse<HorseSearchReference[] | undefined, Entities> => {
  const query = getQuery(request);
  const selector = (state: State): HorseSearchReference[] | undefined =>
    state.entities?.horses?.[JSON.stringify(request)];

  return useApi<HorseSearchReference[] | undefined>({
    query,
    selector,
  });
};

export default useSearchHorses;

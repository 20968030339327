import { AuthenticatedUser } from '@generated/account/src';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ReceiptPage from './ReceiptPage';
import RegistrationForm from './RegistrationForm';

interface Props {
  user: AuthenticatedUser;
}

function HorseStartDeclaration({ user }: Props): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:action(registrera|uppdatera)`} exact>
        <RegistrationForm user={user} />
      </Route>
      <Route path={`${path}/:action(registrera|uppdatera)/kvitto`} exact>
        <ReceiptPage user={user} />
      </Route>
    </Switch>
  );
}
export default HorseStartDeclaration;

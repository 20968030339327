// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface StartMonitoringInformation
 */
export interface StartMonitoringInformation  {
    /**
     * If user is not logged in, the horse cannot be start monitored.
     * @type {boolean}
     * @memberof StartMonitoringInformation
     */
    userLoggedIn?: boolean;
    /**
     * If the horse is at competing age it can be start monitored.
     * @type {boolean}
     * @memberof StartMonitoringInformation
     */
    startMonitoringPossible?: boolean;
}

export function StartMonitoringInformationFromJSON(json: any): StartMonitoringInformation {
    return {
        'userLoggedIn': !exists(json, 'userLoggedIn') ? undefined : json['userLoggedIn'],
        'startMonitoringPossible': !exists(json, 'startMonitoringPossible') ? undefined : json['startMonitoringPossible'],
    };
}

export function StartMonitoringInformationToJSON(value?: StartMonitoringInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'userLoggedIn': value.userLoggedIn,
        'startMonitoringPossible': value.startMonitoringPossible,
    };
}



import { resetQuery } from '@main/state/queriesReducer';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getQueryKey, QueryConfig } from 'redux-query';
import { useRequest } from 'redux-query-react';

import { State } from '../../main/store';
import { Entities } from '..';
import { useRefreshAuthTokenCallback } from '../authenticate/useRefreshAuthToken';
import setBaseUrl, { apiTypes } from './setBaseUrl';
import { ApiResponse } from './types';
import useApiCommon from './useApiCommon';

interface Props<TQuery, TSelector> {
  query: TQuery;
  selector: TSelector;
  basePath?: apiTypes;
  refreshInterval?: number;
  resetOnUnmount?: boolean;
}

const useApi = <
  TResp,
  TQuery extends QueryConfig = QueryConfig,
  TSelector extends (state: State) => TResp = (state: State) => TResp,
>({
  query: queryConfig,
  selector,
  basePath = 'webapi',
  refreshInterval = undefined,
  resetOnUnmount = false,
}: Props<TQuery, TSelector>): ApiResponse<TResp, Entities> => {
  // Add absolute api base url to the query
  const query = setBaseUrl(queryConfig, basePath);

  const { data, error, errorCode } = useApiCommon<TResp>({
    query,
    selector,
  });

  // Handle reset on unmount
  const queryKey = getQueryKey({
    body: query.body,
    queryKey: query.queryKey,
    url: query.url,
  });

  const [{ isFinished, status }, refresh] = useRequest(query);

  const dispatch = useDispatch();

  const [attempts, setAttempts] = useState(0);

  const { refresh: refreshAuthToken } = useRefreshAuthTokenCallback();

  const retry = useCallback(async () => {
    if (attempts < 3) {
      await refreshAuthToken(true);
      await refresh();
    }
  }, [attempts, refresh, refreshAuthToken]);

  // Handle automatic refresh
  useEffect(() => {
    if (status === 401 || status === 403) {
      setAttempts(prev => prev + 1);
      //Retry
      retry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  // Handle automatic refresh
  useEffect(() => {
    let timer;
    if (refreshInterval) {
      timer = setInterval(() => {
        refresh();
      }, refreshInterval * 1000);
    }
    return () => timer && clearInterval(timer);
  }, [refreshInterval, refresh]);

  useEffect(
    () => () => {
      if (resetOnUnmount) {
        dispatch(resetQuery(queryKey));
      }
    },
    [dispatch, queryKey, resetOnUnmount],
  );

  return {
    data,
    loading: !isFinished || (!data && !error),
    status,
    refresh,
    error,
    errorCode,
  };
};

export default useApi;

import DataGrid from '@components/DataGrid';
import { Transaction } from '@generated/tds/src';
import List, { LI } from '@primitives/List';
import { formatTz } from '@utils/dateUtils';
import { formatNumber } from '@utils/formatting';
import Refresh from '@utils/Refresh';
import React, { useMemo } from 'react';
import { CellProps, Column } from 'react-table';

import FilterLabelEnum from '../../utils/enum/FilterLabel';
import FilterValueEnum from '../../utils/enum/FilterValue';
import GroupedTransactions from '../../utils/interface/GroupedTransactions';
import HorseNames from '../../utils/interface/HorseNames';
import RepresentativeNames from '../../utils/interface/RepresentativeNames';
import Delete from './Delete';
import Edit from './Edit';

const columns = (
  licenseId: number,
  refresh: Refresh,
): Column<Transaction>[] => [
  {
    accessor: 'horseName',
    Header: FilterLabelEnum.Horse,
  },
  {
    accessor: 'representativeName',
    Header: FilterLabelEnum.Representative,
  },
  {
    accessor: 'transactionDate',
    Header: FilterLabelEnum.Date,
    Cell: ({
      value,
    }: CellProps<Transaction, Transaction['transactionDate']>) => (
      <>{formatTz(value, 'yyyy-MM-dd')}</>
    ),
  },
  {
    accessor: 'productDescription',
    Header: FilterLabelEnum.Product,
  },
  {
    accessor: 'amount',
    Header: FilterLabelEnum.Amount,
    Cell: ({ value }) => <>{formatNumber(value)}</>,
  },
  {
    accessor: 'vatAmount',
    Header: 'Moms',
    Cell: ({ value }) => <>{formatNumber(value)}</>,
  },
  {
    accessor: 'id',
    alignment: 'center',
    Header: 'Ändra',
    Cell: ({ row, value }: CellProps<Transaction, Transaction['id']>) => (
      <Edit
        amount={row.original.amount}
        changeable={row.original.changeable}
        horseNumber={row.original.horseNumber}
        refresh={refresh}
        horseId={row.original.horseId}
        horseName={row.original.horseName}
        id={value}
        licenseId={licenseId}
        productDescription={row.original.productDescription}
        productCode={row.original.productCode}
        shortProductDescription={row.original.shortProductDescription}
        transactionDate={row.original.transactionDate}
        text={row.original.text}
        vatAmount={row.original.vatAmount}
        lastUpdate={row.original.lastUpdate}
        rejectedTransaction={row.original.rejectedTransaction}
        raceDayId={row.original.raceDayId}
      />
    ),
  },
  {
    accessor: 'horseId',
    alignment: 'center',
    Header: 'Ta bort',
    Cell: ({ row }: CellProps<Transaction, Transaction['horseId']>) => (
      <Delete
        id={row.original.id}
        horseName={row.original.horseName}
        licenseId={licenseId}
        productDescription={row.original.productDescription}
        refresh={refresh}
        transactionDate={row.original.transactionDate}
      />
    ),
  },
];

interface Props {
  groupBy: FilterValueEnum;
  licenseId: number;
  refresh: Refresh;
  sort: FilterValueEnum;
  transactions: Transaction[];
}

export default function Details({
  groupBy,
  licenseId,
  refresh,
  sort,
  transactions,
}: Props): JSX.Element {
  const horseNames: HorseNames = useMemo(
    () =>
      transactions.reduce(
        (accumulator, current) => ({
          ...accumulator,
          [current.horseId]: current.horseName,
        }),
        {},
      ),
    [transactions],
  );

  const representativeNames: RepresentativeNames = useMemo(
    () =>
      transactions.reduce(
        (accumulator, current) => ({
          ...accumulator,
          [current.representativeId]: current.representativeName,
        }),
        {},
      ),
    [transactions],
  );

  const groupedTransactions: GroupedTransactions = useMemo(
    () =>
      transactions.reduce(
        (accumulator, current) => ({
          ...accumulator,
          [current[groupBy].toString()]: accumulator[
            current[groupBy].toString()
          ]
            ? [...accumulator[current[groupBy].toString()], current]
            : [current],
        }),
        {},
      ),
    [transactions, groupBy],
  );

  const sortedTransactions: GroupedTransactions = useMemo(
    () =>
      Object.keys(groupedTransactions).reduce(
        (acc, key) => ({
          ...acc,
          [key]: groupedTransactions[key].sort(
            (a: Transaction, b: Transaction) => {
              if (a[sort] instanceof Date && b[sort] instanceof Date) {
                return b[sort].getTime() - a[sort].getTime();
              } else {
                if (a[sort] < b[sort]) {
                  return -1;
                }
                if (a[sort] > b[sort]) {
                  return 1;
                }
                return 0;
              }
            },
          ),
        }),
        {},
      ),
    [groupedTransactions, sort],
  );

  const sortedGroupKeys: string[] = useMemo(
    () =>
      Object.keys(groupedTransactions).sort((a, b) => {
        if (
          [FilterValueEnum.HorseId, FilterValueEnum.RepresentativeId].includes(
            groupBy,
          )
        ) {
          const names =
            groupBy === FilterValueEnum.HorseId
              ? horseNames
              : representativeNames;
          if (names[a] < names[b]) {
            return -1;
          }
          if (names[a] > names[b]) {
            return 1;
          }
          return 0;
        } else {
          return new Date(b).getTime() - new Date(a).getTime();
        }
      }),
    [groupedTransactions, groupBy, horseNames, representativeNames],
  );

  return (
    <>
      {sortedGroupKeys.map(key => (
        <DataGrid
          mt={4}
          columns={columns(licenseId, refresh)}
          key={key}
          data={sortedTransactions[key]}
          subRowWide
          subRow={({ row }) => (
            <List markerType="circle" markerColor="dim-gray">
              <LI>{row.original.text}</LI>
            </List>
          )}
        />
      ))}
    </>
  );
}

import {
  getUpcomingStakeRaces,
  UpcomingStakeRace,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../../utils/types';
import useApi from '../../utils/useApi';

type Results = UpcomingStakeRace[] | undefined;

export interface Entities {
  upcomingStakeRaces?: {
    [key: string]: Results;
  };
}

export default function useGetUpcomingStakeRaces(
  year?: number,
  horseid?: number,
): ApiResponse<Results, Entities> {
  const key = `${year || 'all'}-${horseid || 'all'}`;
  const query = getUpcomingStakeRaces<Entities>(
    {
      year,
      horseid,
    },
    {
      transform: responseBody => ({
        upcomingStakeRaces: {
          [key]: responseBody,
        },
      }),
      update: {
        upcomingStakeRaces: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Results =>
    state.entities?.upcomingStakeRaces?.[key];

  return useApi<Results>({
    query,
    selector,
  });
}

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface SportActorHorseGender
 */
export interface SportActorHorseGender  {
    /**
     * 
     * @type {string}
     * @memberof SportActorHorseGender
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof SportActorHorseGender
     */
    text?: string;
}

export function SportActorHorseGenderFromJSON(json: any): SportActorHorseGender {
    return {
        'code': !exists(json, 'code') ? undefined : json['code'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function SportActorHorseGenderToJSON(value?: SportActorHorseGender): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'code': value.code,
        'text': value.text,
    };
}



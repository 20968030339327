// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface HorseHorseGender
 */
export interface HorseHorseGender  {
    /**
     * 
     * @type {string}
     * @memberof HorseHorseGender
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof HorseHorseGender
     */
    text?: string;
}

export function HorseHorseGenderFromJSON(json: any): HorseHorseGender {
    return {
        'code': !exists(json, 'code') ? undefined : json['code'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function HorseHorseGenderToJSON(value?: HorseHorseGender): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'code': value.code,
        'text': value.text,
    };
}



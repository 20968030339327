import {
  getBreedingYears,
  getColors,
  getCountries,
  getCoveringOutcomes,
  getGenders,
  Selection,
  TypedQueryConfig,
} from '@generated/breedings/src';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Values = Selection | undefined;

export interface Entities {
  breedingYears?: Selection;
  coveringOutcomes?: Selection;
  coveringCountries?: Selection;
  coveringColors?: Selection;
  coveringGenders?: Selection;
}

type QueryFunction = <T = Entities>(
  requestConfig?: TypedQueryConfig<T, Selection>,
) => QueryConfig<T>;

const hook =
  (propName: keyof Entities, func: QueryFunction) =>
  (): ApiResponse<Values, Entities> => {
    const query = func({
      transform: responseBody => ({
        [propName]: responseBody,
      }),
      update: {
        [propName]: (prev, next) => next,
      },
    });

    return useApi({
      query,
      selector: state => state.entities?.[propName],
    });
  };

export const useGetBreedingYears = hook('breedingYears', getBreedingYears);
export const useGetCoveringOutcomes = hook(
  'coveringOutcomes',
  getCoveringOutcomes,
);
export const useGetCoveringColors = hook('coveringColors', getColors);
export const useGetCoveringGenders = hook('coveringGenders', getGenders);
export const useGetCoveringCountries = hook('coveringCountries', getCountries);

import Content from '@components/ContentBlock/Content';
import { Container } from '@primitives/Box';
import React from 'react';
import { useParams } from 'react-router';

export const IssueContent = (): JSX.Element => {
  const params = useParams() as { content: string };

  return (
    <Container
      display="flex"
      alignSelf="baseline"
      flexDirection={'column'}
      gap={2}
    >
      <Content slug={params.content} />
    </Container>
  );
};

import DataGrid from '@components/DataGrid';
import { Driver } from '@generated/propositions/src';
import Box from '@primitives/Box';
import { Checkbox } from '@primitives/Form';
import Icon from '@primitives/Icon';
import {
  CircleInfo as InfoCircle,
  RightToBracket as SignInAlt,
  TrashCan as TrashAlt,
} from '@styled-icons/fa-solid';
import formatDriver from '@utils/formatDriver';
import { useFormikContext } from 'formik';
import React, { useCallback, useContext, useState } from 'react';
import { CellProps, Column } from 'react-table';

import { FormState } from '..';
import DisplayDriverInfo from './DisplayDriverInfo';
import DriverWeight from './DriverWeight';
import UpdateDriverWeight from './UpdateDriverWeight';

interface Context {
  propositionId?: number;
  propositionWeight?: number;
}

const Ctx = React.createContext<Context>({});

export function useDriversContext(): Context {
  return useContext<Context>(Ctx);
}

interface Props {
  propositionId: number;
  propositionWeight: number;
}

const SelectedDrivers = ({
  propositionId,
  propositionWeight,
}: Props): JSX.Element => {
  const { setFieldValue, values } = useFormikContext<FormState>();

  const { drivers } = values;

  const removeDriver = useCallback(
    (driverId: number, drivers: Driver[]): void => {
      drivers.forEach(driver => {
        driver.sortOrder = driver.sortOrder - 1;
      });

      setFieldValue(
        'drivers',
        drivers.filter(e => e.id !== driverId),
      );
    },
    [setFieldValue],
  );

  const createRemoveHandler = useCallback(
    (driverId: number, drivers: Driver[]) => () =>
      removeDriver(driverId, drivers),
    [removeDriver],
  );

  const updateMountingPromise = useCallback(
    (driverId: number, drivers: Driver[]): void => {
      const newDrivers = drivers.map(driver =>
        driver.id == driverId
          ? {
              ...driver,
              mountingPromise: !driver.mountingPromise,
            }
          : driver,
      );

      setFieldValue('drivers', newDrivers);
    },
    [setFieldValue],
  );

  const [openSubRows, setOpenSubRows] = useState([]);

  const createColumns = useCallback(
    (drivers: Driver[]): Column<Driver>[] => [
      {
        Header: () => (
          <>
            <Icon pr={1} mb="2px" size={14} color="grey1" as={SignInAlt} />
            Ryttare till startanmälan
          </>
        ),
        accessor: 'name',
        Cell: ({ row }: CellProps<Driver>): JSX.Element => (
          <Box mt="8px">
            <Icon pr={1} mb="2px" size={14} color="green" as={SignInAlt} />{' '}
            {formatDriver(row.original)}
          </Box>
        ),
        sortType: (a, b) => a.original.sortOrder - b.original.sortOrder,
      },
      {
        Header: 'Ridlöfte',
        accessor: 'mountingPromise',
        alignment: 'center',
        Cell: ({
          row,
          value,
        }: CellProps<Driver, Driver['mountingPromise']>): JSX.Element => (
          <Box mt="8px" width="16px" ml="auto" mr="auto">
            <Checkbox
              checked={value}
              onChange={() => updateMountingPromise(row.original.id, drivers)}
            />
          </Box>
        ),
      },
      {
        Header: 'Ridvikt',
        accessor: 'lowestRidingWeight',
        alignment: 'center',
        tdStyle: () => ({ verticalAlign: 'middle' }),
        Cell: DriverWeight,
      },
      {
        Header: 'Ändrad ridvikt',
        accessor: 'updatedWeight',
        alignment: 'center',
        Cell: UpdateDriverWeight,
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ row }: CellProps<Driver>): JSX.Element => (
          <Box display="flex">
            <Box pl={2} mt="8px">
              <button
                onClick={() =>
                  setOpenSubRows(prev =>
                    prev.some(e => e.id === row.id)
                      ? prev.filter(e => e.id !== row.id)
                      : [...prev, row],
                  )
                }
              >
                <Icon color="gray6" size="16px" as={InfoCircle} />
              </button>
            </Box>

            <Box pl={6} mt="8px">
              <button onClick={createRemoveHandler(row.original.id, drivers)}>
                <Icon color="gray6" size="16px" as={TrashAlt} />
              </button>
            </Box>
          </Box>
        ),
      },
    ],
    [createRemoveHandler, updateMountingPromise],
  );

  return (
    <Box>
      <Box mt={5} bg="white">
        <Ctx.Provider
          value={{
            propositionId,
            propositionWeight,
          }}
        >
          <DataGrid<Driver>
            noHover={true}
            stickyOnMobile={false}
            columns={createColumns(drivers)}
            data={drivers}
            renderRowSubComponentNoPadding
            openSubRows={openSubRows}
            renderRowSubComponentOnClick={({ row }) => (
              <DisplayDriverInfo key={row.id} driverId={row.original.id} />
            )}
          />
        </Ctx.Provider>
      </Box>
    </Box>
  );
};

export default SelectedDrivers;

import { useUpdatePersonalInformation } from '@apis';
import { Input } from '@components/FormikComponents';
import FormLayout, { FormItem } from '@components/FormLayout';
import { AuthenticatedUser } from '@generated/authenticate/src';
import { ActorResource } from '@generated/content/src';
import { ChangeableUserInformation } from '@generated/user/src';
import useAuthenticationMethod from '@hooks/useAuthenticationMethod';
import { base, BaseProps } from '@primitives/base';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import Message from '@primitives/Message';
import hasRole from '@utils/hasRole';
import roleEnum from '@utils/roleEnum';
import { Formik, FormikErrors } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  initialValues: ChangeableUserInformation;
  licenseId: number;
  changeSmsNumber: boolean;
  user: AuthenticatedUser;
}

export const HintText = styled.span<BaseProps>`
  font-style: italic;
  font-size: 0.8rem;
  padding-top: 0.2rem;
  ${base()}
`;

function Form({
  initialValues,
  licenseId,
  changeSmsNumber,
  user,
}: Props): JSX.Element {
  const { action, error } = useUpdatePersonalInformation(licenseId);
  const authMethod = useAuthenticationMethod();

  const formvalues = {
    email: '',
    smsNumber: '',
    webPage: '',
    ...initialValues,
  };

  // Check if any error exists
  const anyError = (
    errors: FormikErrors<ChangeableUserInformation & ActorResource>,
  ): boolean => Object.keys(errors).some(key => errors[key]);

  // Return error text as array of strings
  const getErrorText = (
    errors: FormikErrors<ChangeableUserInformation & ActorResource>,
  ): string[] =>
    Object.keys(errors).map(key => {
      if (errors[key]) {
        return errors[key];
      }
    });

  return (
    <Formik<ChangeableUserInformation>
      initialValues={formvalues}
      onSubmit={async (values, helpers) => {
        // Save user values
        const { body, status } = await action({
          ...values,
          smsNumber: values.smsNumber || undefined,
        });

        if (status === 204) {
          helpers.resetForm({ values, status: 'success' });

          setTimeout(() => {
            helpers.setStatus(undefined);
          }, 4000);
        } else {
          helpers.setFieldError(
            'all',
            body?.errorMessage || 'Ett oväntat fel inträffade',
          );
          helpers.setSubmitting(false);
        }
      }}
      validate={values => {
        const errors: Record<string, string> = {};

        if (authMethod !== 'BANKID') {
          const number = values?.smsNumber || '';

          const length = number.replace(/[^0-9]/g, '').length;

          if (length < 4 || length > 13 || number.length > 16) {
            errors['smsNumber'] =
              'SMS-nummer måste bestå av minst 4 och max 13 siffror.';
          }
        }

        if (
          values.email &&
          (!values.email.includes('@') || values.email.length > 100)
        ) {
          errors['email'] = 'E-postadressen är inte giltig.';
        }

        return errors;
      }}
    >
      {({ isSubmitting, dirty, status, errors, touched }) => (
        <FormLayout variant="vertical">
          {authMethod !== 'BANKID' && (
            <FormItem maxWidth="400px">
              <Input
                name="smsNumber"
                label="SMS-nummer"
                type="text"
                disabled={!changeSmsNumber}
              />
              <HintText>
                För att kunna beställa temporärt lösenord via SMS.
              </HintText>
            </FormItem>
          )}

          {hasRole(user, roleEnum.Licensee) ? (
            <>
              <FormItem data-no-grow display="inline-block">
                Nedanstående uppgifter visas på{' '}
                <Link to={`/sportinfo/licensinnehavare/${licenseId}`}>
                  din profil i Sportinfo
                </Link>
              </FormItem>
              <FormItem maxWidth="400px">
                <Input name="email" label="E-post" type="text" />
              </FormItem>
              <FormItem maxWidth="400px">
                <Input name="webPage" label="Webbplats" type="text" />
              </FormItem>
            </>
          ) : (
            <FormItem maxWidth="400px">
              <Input name="email" label="E-post" type="text" />
            </FormItem>
          )}
          <FormItem data-no-grow display="inline-block">
            <Button type="submit" loading={isSubmitting} disabled={!dirty}>
              Spara
            </Button>
            {status === 'success' && (
              <Message variant="success" ml={4} display="inline-block">
                Sparat!
              </Message>
            )}
          </FormItem>
          {error && <Error>{error}</Error>}
          {anyError(errors) && touched && (
            <Error>
              {getErrorText(errors).map((err, index) => (
                <p key={index}>{err}</p>
              ))}
            </Error>
          )}
        </FormLayout>
      )}
    </Formik>
  );
}

export default Form;

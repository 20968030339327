// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Horses in this ongoing stake race
 * @export
 * @interface StakeRaceOngoingPublicHorse
 */
export interface StakeRaceOngoingPublicHorse  {
    /**
     * 
     * @type {number}
     * @memberof StakeRaceOngoingPublicHorse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StakeRaceOngoingPublicHorse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof StakeRaceOngoingPublicHorse
     */
    trainerId: number;
    /**
     * 
     * @type {string}
     * @memberof StakeRaceOngoingPublicHorse
     */
    trainerName: string;
    /**
     * 
     * @type {number}
     * @memberof StakeRaceOngoingPublicHorse
     */
    points: number;
    /**
     * 
     * @type {number}
     * @memberof StakeRaceOngoingPublicHorse
     */
    handicap: number;
    /**
     * 
     * @type {Date}
     * @memberof StakeRaceOngoingPublicHorse
     */
    lateRegistration: Date;
}

export function StakeRaceOngoingPublicHorseFromJSON(json: any): StakeRaceOngoingPublicHorse {
    return {
        'id': json['id'],
        'name': json['name'],
        'trainerId': json['trainerId'],
        'trainerName': json['trainerName'],
        'points': json['points'],
        'handicap': json['handicap'],
        'lateRegistration': parseDateTime(json['lateRegistration']),
    };
}

export function StakeRaceOngoingPublicHorseToJSON(value?: StakeRaceOngoingPublicHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'trainerId': value.trainerId,
        'trainerName': value.trainerName,
        'points': value.points,
        'handicap': value.handicap,
        'lateRegistration': value.lateRegistration.toISOString(),
    };
}



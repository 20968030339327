import { Entities } from '@apis';
import { registerMounting } from '@generated/licenseholders/src';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../utils/types';
import { useApiMutateWithoutData } from '../utils/useApiMutate';

const useRegisterMounting = (
  licenseId: number,
): MutationResponseWithoutData<Entities, number> =>
  useApiMutateWithoutData({
    queryFunc: (raceDayId: number): QueryConfig<Entities> =>
      registerMounting({
        licenseId,
        raceDayId,
      }),
    queryKey: 'registerMounting',
  });

export default useRegisterMounting;

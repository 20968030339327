import {
  Proposition,
  searchPropositions,
  SearchPropositionsRequest,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  propositions?: {
    [key: string]: Proposition[];
  };
}

const useSearchPropositions = (
  request: SearchPropositionsRequest,
): ApiResponse<Proposition[] | undefined, Entities> => {
  const query = searchPropositions<Entities>(request, {
    transform: responseBody => ({
      propositions: {
        [JSON.stringify(request)]: responseBody,
      },
    }),
    update: {
      propositions: (prev, next): typeof next => ({ ...prev, ...next }),
    },
  });

  const selector = (state: State): Proposition[] | undefined =>
    state.entities.propositions?.[JSON.stringify(request)];

  return useApi<Proposition[] | undefined>({
    query,
    selector,
  });
};

export default useSearchPropositions;

import { EmptyState } from '@components/InfoBlock';
import { PageLayout } from '@components/PageLayouts';
import EnrolledHorses from '@features/EnrolledHorses';
import withUser from '@hoc/withUser';
import Box from '@primitives/Box';
import { Link } from '@primitives/Link';
import { RightFromBracket as SignOutAlt } from '@styled-icons/fa-solid';
import roleEnum from '@utils/roleEnum';
import React from 'react';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

const EnrollForm = (): JSX.Element => (
  <Box px={[4, null, 0]}>
    <EmptyState
      icon={SignOutAlt}
      header="Ny anmälan"
      content={
        <>
          Du kan anmäla hästar via
          <br />
          <Link to="/tavling/tavlingskalender">
            tävlingskalendern
          </Link> eller{' '}
          <Link to="/tavling/propositioner/allman">sök propositioner.</Link>
        </>
      }
    />
  </Box>
);

const EnrollOverview = (): JSX.Element => {
  const { path } = useRouteMatch();

  const enrollmentPath = generatePath(path, {
    tab: 'ny-anmalan',
  });

  return (
    <PageLayout
      headline="Anmälan"
      singleCard
      tabs={[
        {
          to: generatePath(path, {
            tab: 'ny-anmalan',
          }),
          text: 'Ny anmälan',
        },
        {
          to: generatePath(path, {
            tab: 'anmalda-hastar',
          }),
          text: 'Anmälda hästar',
        },
      ]}
    >
      <Switch>
        <Route path={`${path}/ny-anmalan`}>
          <EnrollForm />
        </Route>
        <Route path={`${path}/anmalda-hastar`}>
          <EnrolledHorses />
        </Route>
        <Route path={path} exact>
          <Redirect to={enrollmentPath} />
        </Route>
      </Switch>
    </PageLayout>
  );
};

export default withUser(EnrollOverview, {
  roles: [roleEnum.Trainer],
  header: 'Anmälan',
});

// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Person,
    PersonFromJSON,
    PersonToJSON,
} from './';

/**
 * 
 * @export
 * @interface Ownership
 */
export interface Ownership  {
    /**
     * 
     * @type {number}
     * @memberof Ownership
     */
    ownershipId: number;
    /**
     * 
     * @type {string}
     * @memberof Ownership
     */
    ownershipName: string;
    /**
     * 
     * @type {number}
     * @memberof Ownership
     */
    representativeId: number;
    /**
     * 
     * @type {string}
     * @memberof Ownership
     */
    representativeName: string;
    /**
     * 
     * @type {string}
     * @memberof Ownership
     */
    representativeType: string;
    /**
     * 
     * @type {boolean}
     * @memberof Ownership
     */
    hasHorse: boolean;
    /**
     * 
     * @type {number}
     * @memberof Ownership
     */
    numberOfHorses?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Ownership
     */
    hasPartOwner: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Ownership
     */
    isOwnerBlocked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Ownership
     */
    isPersonBlocked?: boolean;
    /**
     * 
     * @type {Array<Person>}
     * @memberof Ownership
     */
    partOwners?: Array<Person>;
    /**
     * 
     * @type {boolean}
     * @memberof Ownership
     */
    ownerBlocked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Ownership
     */
    personBlocked?: boolean;
}

export function OwnershipFromJSON(json: any): Ownership {
    return {
        'ownershipId': json['ownershipId'],
        'ownershipName': json['ownershipName'],
        'representativeId': json['representativeId'],
        'representativeName': json['representativeName'],
        'representativeType': json['representativeType'],
        'hasHorse': json['hasHorse'],
        'numberOfHorses': !exists(json, 'numberOfHorses') ? undefined : json['numberOfHorses'],
        'hasPartOwner': json['hasPartOwner'],
        'isOwnerBlocked': !exists(json, 'isOwnerBlocked') ? undefined : json['isOwnerBlocked'],
        'isPersonBlocked': !exists(json, 'isPersonBlocked') ? undefined : json['isPersonBlocked'],
        'partOwners': !exists(json, 'partOwners') ? undefined : (json['partOwners'] as Array<any>).map(PersonFromJSON),
        'ownerBlocked': !exists(json, 'ownerBlocked') ? undefined : json['ownerBlocked'],
        'personBlocked': !exists(json, 'personBlocked') ? undefined : json['personBlocked'],
    };
}

export function OwnershipToJSON(value?: Ownership): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'ownershipId': value.ownershipId,
        'ownershipName': value.ownershipName,
        'representativeId': value.representativeId,
        'representativeName': value.representativeName,
        'representativeType': value.representativeType,
        'hasHorse': value.hasHorse,
        'numberOfHorses': value.numberOfHorses,
        'hasPartOwner': value.hasPartOwner,
        'isOwnerBlocked': value.isOwnerBlocked,
        'isPersonBlocked': value.isPersonBlocked,
        'partOwners': value.partOwners === undefined ? undefined : (value.partOwners as Array<any>).map(PersonToJSON),
        'ownerBlocked': value.ownerBlocked,
        'personBlocked': value.personBlocked,
    };
}



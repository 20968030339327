// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HorseInTraining,
    HorseInTrainingFromJSON,
    HorseInTrainingToJSON,
    TrainerHorsesSummary,
    TrainerHorsesSummaryFromJSON,
    TrainerHorsesSummaryToJSON,
    TrainerStart,
    TrainerStartFromJSON,
    TrainerStartToJSON,
    TrainerStatisticsSummary,
    TrainerStatisticsSummaryFromJSON,
    TrainerStatisticsSummaryToJSON,
    TrainingStatus,
    TrainingStatusFromJSON,
    TrainingStatusToJSON,
} from '../models';

export interface GetTrainerHorsesRequest {
    licenseId: number;
}

export interface GetTrainerHorsesSummaryRequest {
    licenseId: number;
}

export interface GetTrainerStartsRequest {
    licenseId: number;
}

export interface GetTrainerStatisticsSummaryRequest {
    licenseId: number;
}

export interface GetTrainingStatusRequest {
    licenseId: number;
}


/**
 * Fetch all race horses listed on trainerlist.
 */
function getTrainerHorsesRaw<T>(requestParameters: GetTrainerHorsesRequest, requestConfig: runtime.TypedQueryConfig<T, Array<HorseInTraining>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getTrainerHorses.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/trainers/{licenseId}/horses`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(HorseInTrainingFromJSON), text);
    }

    return config;
}

/**
* Fetch all race horses listed on trainerlist.
*/
export function getTrainerHorses<T>(requestParameters: GetTrainerHorsesRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<HorseInTraining>>): QueryConfig<T> {
    return getTrainerHorsesRaw(requestParameters, requestConfig);
}

/**
 * Get the specified trainers \'horses in training\' summary. Contains info about the number of horses in training at present.
 */
function getTrainerHorsesSummaryRaw<T>(requestParameters: GetTrainerHorsesSummaryRequest, requestConfig: runtime.TypedQueryConfig<T, TrainerHorsesSummary> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getTrainerHorsesSummary.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/trainers/{licenseId}/horses/summary`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(TrainerHorsesSummaryFromJSON(body), text);
    }

    return config;
}

/**
* Get the specified trainers \'horses in training\' summary. Contains info about the number of horses in training at present.
*/
export function getTrainerHorsesSummary<T>(requestParameters: GetTrainerHorsesSummaryRequest, requestConfig?: runtime.TypedQueryConfig<T, TrainerHorsesSummary>): QueryConfig<T> {
    return getTrainerHorsesSummaryRaw(requestParameters, requestConfig);
}

/**
 * Get trainer starts. Unlike the similar sportinfo endpoint for obtaining trainer starts, this endpoint is intended for a logged in trainer to view his own starts.
 */
function getTrainerStartsRaw<T>(requestParameters: GetTrainerStartsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<TrainerStart>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getTrainerStarts.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/trainers/{licenseId}/starts`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(TrainerStartFromJSON), text);
    }

    return config;
}

/**
* Get trainer starts. Unlike the similar sportinfo endpoint for obtaining trainer starts, this endpoint is intended for a logged in trainer to view his own starts.
*/
export function getTrainerStarts<T>(requestParameters: GetTrainerStartsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<TrainerStart>>): QueryConfig<T> {
    return getTrainerStartsRaw(requestParameters, requestConfig);
}

/**
 * Get the specified trainers statistics summary. Contains info about the number of victories this year.
 */
function getTrainerStatisticsSummaryRaw<T>(requestParameters: GetTrainerStatisticsSummaryRequest, requestConfig: runtime.TypedQueryConfig<T, TrainerStatisticsSummary> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getTrainerStatisticsSummary.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/trainers/{licenseId}/statistics/summary`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(TrainerStatisticsSummaryFromJSON(body), text);
    }

    return config;
}

/**
* Get the specified trainers statistics summary. Contains info about the number of victories this year.
*/
export function getTrainerStatisticsSummary<T>(requestParameters: GetTrainerStatisticsSummaryRequest, requestConfig?: runtime.TypedQueryConfig<T, TrainerStatisticsSummary>): QueryConfig<T> {
    return getTrainerStatisticsSummaryRaw(requestParameters, requestConfig);
}

/**
 * Get the specified trainers (license holders) training status. Contains info about whether or not the trainer has any horses in training at present.
 */
function getTrainingStatusRaw<T>(requestParameters: GetTrainingStatusRequest, requestConfig: runtime.TypedQueryConfig<T, TrainingStatus> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getTrainingStatus.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/trainers/{licenseId}/trainingstatus`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(TrainingStatusFromJSON(body), text);
    }

    return config;
}

/**
* Get the specified trainers (license holders) training status. Contains info about whether or not the trainer has any horses in training at present.
*/
export function getTrainingStatus<T>(requestParameters: GetTrainingStatusRequest, requestConfig?: runtime.TypedQueryConfig<T, TrainingStatus>): QueryConfig<T> {
    return getTrainingStatusRaw(requestParameters, requestConfig);
}


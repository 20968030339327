import {
  saveVaccineRegistration,
  SaveVaccineRegistrationRequest,
} from '@generated/licenseholders/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { startDeclarationQueryKeyPrefix } from '../propositions';
import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

export interface Entities {
  saveVaccineRegistration?: null;
}

const useSaveVaccineRegistration = (
  licenseId: number,
  vaccinationDate: Date,
  vaccineId: number,
  horseIds: Array<number>,
): MutationResponse<
  Entities['saveVaccineRegistration'],
  Entities,
  SaveVaccineRegistrationRequest
> => {
  const queryFunc = (): QueryConfig<Entities> =>
    saveVaccineRegistration<Entities>({
      licenseId,
      vaccination: {
        vaccinationDate,
        vaccineId,
        horseIds,
      },
    });

  return useApiMutate<
    Entities['saveVaccineRegistration'],
    SaveVaccineRegistrationRequest
  >({
    queryFunc,
    selector: (state: State) => state.entities.saveVaccineRegistration,
    queryKey: 'saveVaccineRegistration',

    resetKeys: [
      `vaccineWarnings:${licenseId}`, // Snabbkollen
      `trainerHorses:${licenseId}`, // Anmärkningar (Hästar i träning)
      `${startDeclarationQueryKeyPrefix}:*`, // Startanmälan
      'horsesWithVaccine*', // Vaccinationshistorik & Vilka hästar fick vaccinet?
      `vaccineHistory:${licenseId}:*`,
    ],
  });
};

export default useSaveVaccineRegistration;

// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Driver
 */
export interface Driver  {
    /**
     * The drivers id
     * @type {number}
     * @memberof Driver
     */
    id: number;
    /**
     * The drivers name
     * @type {string}
     * @memberof Driver
     */
    name: string;
    /**
     * True if the driver is suspended
     * @type {boolean}
     * @memberof Driver
     */
    suspended: boolean;
    /**
     * The sort order of this driver
     * @type {number}
     * @memberof Driver
     */
    sortOrder: number;
    /**
     * The lowest Riding Weight
     * @type {number}
     * @memberof Driver
     */
    lowestRidingWeight?: number;
    /**
     * The riders weight Reduction
     * @type {number}
     * @memberof Driver
     */
    weightReduction?: number;
    /**
     * True if the driver is amateur
     * @type {boolean}
     * @memberof Driver
     */
    amateur?: boolean;
    /**
     * True if the driver is apprentice
     * @type {boolean}
     * @memberof Driver
     */
    apprentice?: boolean;
}

export function DriverFromJSON(json: any): Driver {
    return {
        'id': json['id'],
        'name': json['name'],
        'suspended': json['suspended'],
        'sortOrder': json['sortOrder'],
        'lowestRidingWeight': !exists(json, 'lowestRidingWeight') ? undefined : json['lowestRidingWeight'],
        'weightReduction': !exists(json, 'weightReduction') ? undefined : json['weightReduction'],
        'amateur': !exists(json, 'amateur') ? undefined : json['amateur'],
        'apprentice': !exists(json, 'apprentice') ? undefined : json['apprentice'],
    };
}

export function DriverToJSON(value?: Driver): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'suspended': value.suspended,
        'sortOrder': value.sortOrder,
        'lowestRidingWeight': value.lowestRidingWeight,
        'weightReduction': value.weightReduction,
        'amateur': value.amateur,
        'apprentice': value.apprentice,
    };
}



// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    LinkableHorse,
    LinkableHorseFromJSON,
    LinkableHorseToJSON,
} from './';

/**
 * 
 * @export
 * @interface Penalty
 */
export interface Penalty  {
    /**
     * 
     * @type {number}
     * @memberof Penalty
     */
    licenseId: number;
    /**
     * 
     * @type {string}
     * @memberof Penalty
     */
    licenseName: string;
    /**
     * 
     * @type {LinkableHorse}
     * @memberof Penalty
     */
    horse: LinkableHorse;
    /**
     * 
     * @type {string}
     * @memberof Penalty
     */
    cause: string;
    /**
     * 
     * @type {boolean}
     * @memberof Penalty
     */
    linkableDriver: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Penalty
     */
    linkableTrainer: boolean;
}

export function PenaltyFromJSON(json: any): Penalty {
    return {
        'licenseId': json['licenseId'],
        'licenseName': json['licenseName'],
        'horse': LinkableHorseFromJSON(json['horse']),
        'cause': json['cause'],
        'linkableDriver': json['linkableDriver'],
        'linkableTrainer': json['linkableTrainer'],
    };
}

export function PenaltyToJSON(value?: Penalty): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseId': value.licenseId,
        'licenseName': value.licenseName,
        'horse': LinkableHorseToJSON(value.horse),
        'cause': value.cause,
        'linkableDriver': value.linkableDriver,
        'linkableTrainer': value.linkableTrainer,
    };
}



// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    LeaseTerminationRequest,
    LeaseTerminationRequestFromJSON,
    LeaseTerminationRequestToJSON,
    LeaseTerminationResponse,
    LeaseTerminationResponseFromJSON,
    LeaseTerminationResponseToJSON,
    OwnershipTransfer,
    OwnershipTransferFromJSON,
    OwnershipTransferToJSON,
} from '../models';

export interface CreateLeaseTerminationAndSaveContractRequest {
    licenseId: number;
    leaseTerminationRequest: LeaseTerminationRequest;
}

export interface GetOngoingLeasingTerminationsRequest {
    licenseId: number;
}


/**
 * Returns the lease termination id and contract id which gets created.
 * Creates a termination of lease, a contract for signing and saves the corresponding details in database.
 */
function createLeaseTerminationAndSaveContractRaw<T>(requestParameters: CreateLeaseTerminationAndSaveContractRequest, requestConfig: runtime.TypedQueryConfig<T, LeaseTerminationResponse> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling createLeaseTerminationAndSaveContract.');
    }

    if (requestParameters.leaseTerminationRequest === null || requestParameters.leaseTerminationRequest === undefined) {
        throw new runtime.RequiredError('leaseTerminationRequest','Required parameter requestParameters.leaseTerminationRequest was null or undefined when calling createLeaseTerminationAndSaveContract.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/leasing/terminations/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || LeaseTerminationRequestToJSON(requestParameters.leaseTerminationRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(LeaseTerminationResponseFromJSON(body), text);
    }

    return config;
}

/**
* Returns the lease termination id and contract id which gets created.
* Creates a termination of lease, a contract for signing and saves the corresponding details in database.
*/
export function createLeaseTerminationAndSaveContract<T>(requestParameters: CreateLeaseTerminationAndSaveContractRequest, requestConfig?: runtime.TypedQueryConfig<T, LeaseTerminationResponse>): QueryConfig<T> {
    return createLeaseTerminationAndSaveContractRaw(requestParameters, requestConfig);
}

/**
 * Show all the representative\'s ongoing leasing terminations.
 */
function getOngoingLeasingTerminationsRaw<T>(requestParameters: GetOngoingLeasingTerminationsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<OwnershipTransfer>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getOngoingLeasingTerminations.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/leasing/terminations/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(OwnershipTransferFromJSON), text);
    }

    return config;
}

/**
* Show all the representative\'s ongoing leasing terminations.
*/
export function getOngoingLeasingTerminations<T>(requestParameters: GetOngoingLeasingTerminationsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<OwnershipTransfer>>): QueryConfig<T> {
    return getOngoingLeasingTerminationsRaw(requestParameters, requestConfig);
}


import config from '@config';
import { navigation } from '@features/Account/navigation';
import { AuthenticatedUser } from '@generated/authenticate/src';
import withUser from '@hoc/withUser';
import useClickOutside from '@hooks/useClickOutside';
import Icon from '@primitives/Icon';
import List from '@primitives/List';
import { Text } from '@primitives/Typography';
import { UpRightFromSquare as ExternalLinkAlt } from '@styled-icons/fa-solid';
import hasRole from '@utils/hasRole';
import isLoggedIn from '@utils/isLoggedIn';
import roleEnum from '@utils/roleEnum';
import React, { RefObject, useEffect, useState } from 'react';
import styled from 'styled-components';

import menus from '../menus';
import SidebarItem from './SidebarItem';

interface Props {
  open: boolean;
  setSidebarClosed: () => void;
  user: AuthenticatedUser;
}

const Container = styled(List)`
  width: 100%;
  height: calc(100vh - ${props => props.theme.sizes.pageHeader}px);
  position: absolute;
  top: ${props => props.theme.sizes.pageHeader}px;
  bottom: 0;
  right: 0;
  background-color: ${props => props.theme.colors.white};
  z-index: 99999;
  transition: all 0.1s linear;
  overflow-y: auto;
  padding-bottom: 100px;
`;

const Label = styled(Text).attrs({
  bg: 'gray1',
  px: 4,
  py: 2,
  color: 'dim-gray',
  fontSize: 'notes',
  borderBottomColor: 'gray3',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
})`
  text-transform: uppercase;
`;

const ExternalLink = styled(Text).attrs({
  display: 'flex',
  borderBottomColor: 'gray3',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  px: 4,
  height: '44px',
  alignItems: 'center',
  color: 'gray6',
})``;

const Sidebar = ({ open, setSidebarClosed, user }: Props): JSX.Element => {
  const [clickOutside, setClickOutside] = useState(false);
  const containerRef = useClickOutside(setClickOutside);

  useEffect(() => {
    if (clickOutside) {
      setSidebarClosed();
      setClickOutside(false);
    }
    document.body.style.overflow = open ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open, clickOutside, setSidebarClosed]);

  const menuItems = menus.menu.map(item => (
    <SidebarItem
      eventTrackingTag={['main-menu', 'secondary-menu']}
      key={item.label}
      label={item.label}
      href={item.href}
      subMenu={item.subMenu}
      setSidebarClosed={setSidebarClosed}
      pl={4}
    />
  ));

  const userNavItems = navigation
    .map(group => {
      const items = group.items.filter(
        item =>
          !item.hidden &&
          (item.roles.length === 0
            ? true
            : item.roles.some(role => hasRole(user, role as roleEnum))),
      );

      if (items.length === 0) {
        return null;
      }

      return (
        <SidebarItem
          eventTrackingTag={[undefined, 'userpages-menu']}
          key={group.key}
          label={group.label}
          subMenu={items.map(item => ({
            label: item.label,
            href: `/minasidor/${item?.to || item.path.split('/')[0]}`,

            icon: item.icon,
          }))}
          setSidebarClosed={setSidebarClosed}
          pl={4}
        />
      );
    })
    .filter(Boolean);

  const externalLinks = [
    { name: 'svenskgalopp.se', path: config.publicWebUrl },
    { name: 'galopptips.se', path: 'https://www.galopptips.se' },
    {
      name: 'horseracingsweden.com',
      path: 'https://www.horseracingsweden.com',
    },
  ];

  return open ? (
    <Container
      variant="vertical"
      ref={containerRef as RefObject<HTMLUListElement>} // <-- In order for useClickOutside to return generic type RefObject<HTMLElement>
    >
      <Label>GALOPPAPP</Label>
      {menuItems}

      {isLoggedIn(user) && (
        <>
          <Label>Mina sidor</Label>
          {userNavItems}
        </>
      )}
      <Label>Mer från Svensk Galopp</Label>
      {externalLinks.map(item => (
        <ExternalLink
          key={item.name}
          rel="noopener noreferrer"
          as="a"
          href={item.path}
        >
          <Icon size={12} mr={2} color="deep-blue" as={ExternalLinkAlt} />
          {item.name}
        </ExternalLink>
      ))}
    </Container>
  ) : null;
};

export default withUser(Sidebar, {
  showError: false,
  onlyAuthenticated: false,
  showLoader: false,
});

import { Form, Navigation } from '@components/Calendar';
import { DesktopCard } from '@components/Card';
import { PageLayout } from '@components/PageLayouts';
import withUser from '@hoc/withUser';
import useIsMobile from '@hooks/useIsMobile';
import Box from '@primitives/Box';
import roleEnum from '@utils/roleEnum';
import React from 'react';

import MountingCalendarContent from './MountingCalendarContent';

export function MountingCalendar(): JSX.Element {
  const isMobile = useIsMobile();

  return (
    <PageLayout
      headline="Uppsittningskalender"
      form={<Form isMobile={isMobile} />}
      noCard
    >
      <Box px={2} verticalSpacing={2}>
        <MountingCalendarContent isMobile={isMobile} />
        <DesktopCard px={[0, , 2]} py={[0, , 2]}>
          <Navigation />
        </DesktopCard>
      </Box>
    </PageLayout>
  );
}

export default withUser(MountingCalendar, {
  roles: [roleEnum.Driver],
  header: 'Uppsittningskalender',
});

import FormLayout, { FormItem } from '@components/FormLayout';
import Box from '@primitives/Box';
import { Select } from '@primitives/Form';
import qs from 'query-string';
import React, { memo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import FilterGroupEnum from './utils/enum/FilterGroup';
import FilterValueEnum from './utils/enum/FilterValue';
import options from './utils/options';

type BoxParameters = Parameters<typeof Box>;

type FormState = {
  groupBy: FilterValueEnum;
  sort: FilterValueEnum;
  viewMode: FilterValueEnum;
};

type Props = FormState & BoxParameters[0];

const Filters = ({ groupBy, sort, viewMode, ...props }: Props): JSX.Element => {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Box {...props}>
      <FormLayout as="div">
        {[
          {
            label: 'Grupperat & summerat per',
            name: FilterGroupEnum.GroupBy,
            value: groupBy,
          },
          {
            label: 'Sortering',
            name: FilterGroupEnum.Sort,
            value: sort,
          },
          {
            label: 'Visningsläge',
            name: FilterGroupEnum.ViewMode,
            value: viewMode,
          },
        ].map(item => (
          <FormItem key={item.name} width={[1, null, 'auto']} data-no-grow>
            <Select
              disabled={
                item.name === FilterGroupEnum.Sort &&
                viewMode === FilterValueEnum.Total
              }
              value={item.value}
              name={item.name}
              label={item.label}
              onChange={event => {
                const { name, value } = event.target;
                const query = {
                  groupBy,
                  sort,
                  viewMode,
                  [name]: value,
                };
                history.push(`${path}?${qs.stringify(query)}`);
              }}
              options={options[item.name]}
            />
          </FormItem>
        ))}
      </FormLayout>
    </Box>
  );
};

export default memo(Filters);

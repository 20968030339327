import { FormItem } from '@components/FormLayout';
import styled from 'styled-components';

const FormItemInput = styled(FormItem)`
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
`;

export default FormItemInput;

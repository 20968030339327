import { useContent } from '@apis';
import Box, { Flex } from '@primitives/Box';
import Close from '@primitives/Button/Close';
import { Text } from '@primitives/Typography';
import React, { useEffect, useState } from 'react';

export default function ImportantMessage(): JSX.Element {
  const { data } = useContent('viktigt-meddelande');

  const namespace = `messsage_accepted`;

  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (data?.meta?.lastPublishedAt) {
      const accepted = new Date(localStorage.getItem(namespace) || undefined);
      setHidden(data.meta.lastPublishedAt < accepted);
    }
  }, [data?.meta?.lastPublishedAt, namespace]);

  if (hidden || !data?.text || data?.text?.trim() === '') {
    return null;
  }

  return (
    <Box
      width={1}
      py={[4, , 6]}
      bg="alt3"
      position="relative"
      className="hidden-print"
    >
      <Flex maxWidth="920px" px={4} mx="auto">
        <Text
          color="#FCD9CA"
          fontSize="lead"
          dangerouslySetInnerHTML={{ __html: data.text }}
          pr={['30px', , 4]}
          sx={{
            a: {
              color: '#FCD9CA',
              textDecoration: 'underline',
            },
          }}
        />
        <Close
          onClick={() => {
            localStorage.setItem(namespace, new Date().toISOString());
            setHidden(true);
          }}
          bg="alt3"
          color="#FCD9CA"
          position={['absolute', , 'absolute']}
          top="0px"
          right="0px"
          sx={{
            '&:hover': {
              bg: '#982143',
            },
            transition: 'background-color .2s ease',
          }}
        />
      </Flex>
    </Box>
  );
}

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface SportActorBasicInformation
 */
export interface SportActorBasicInformation  {
    /**
     * 
     * @type {number}
     * @memberof SportActorBasicInformation
     */
    id: number;
    /**
     * Name of the ownership/breeder.
     * @type {string}
     * @memberof SportActorBasicInformation
     */
    name: string;
    /**
     * The dress of the ownership.
     * @type {string}
     * @memberof SportActorBasicInformation
     */
    dress?: string;
    /**
     * The dress of the ownership.
     * @type {string}
     * @memberof SportActorBasicInformation
     */
    dressImage?: string;
    /**
     * If this ownership/breeder has horses with coming starts.
     * @type {boolean}
     * @memberof SportActorBasicInformation
     */
    comingStartsExists: boolean;
    /**
     * If this ownership/breeder has active ownerships of horses.
     * @type {boolean}
     * @memberof SportActorBasicInformation
     */
    horsesExists: boolean;
    /**
     * If this ownership/breeder has results.
     * @type {boolean}
     * @memberof SportActorBasicInformation
     */
    resultsExists: boolean;
    /**
     * If this ownership/breeder has possible results (coming starts that will give results).
     * @type {boolean}
     * @memberof SportActorBasicInformation
     */
    possibleResultsExists: boolean;
    /**
     * The sport info type (used for favorite).
     * @type {string}
     * @memberof SportActorBasicInformation
     */
    sportInfoType: SportActorBasicInformationSportInfoTypeEnum;
}

export function SportActorBasicInformationFromJSON(json: any): SportActorBasicInformation {
    return {
        'id': json['id'],
        'name': json['name'],
        'dress': !exists(json, 'dress') ? undefined : json['dress'],
        'dressImage': !exists(json, 'dress_image') ? undefined : json['dress_image'],
        'comingStartsExists': json['comingStartsExists'],
        'horsesExists': json['horsesExists'],
        'resultsExists': json['resultsExists'],
        'possibleResultsExists': json['possibleResultsExists'],
        'sportInfoType': json['sportInfoType'],
    };
}

export function SportActorBasicInformationToJSON(value?: SportActorBasicInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'dress': value.dress,
        'dressImage': value.dressImage,
        'comingStartsExists': value.comingStartsExists,
        'horsesExists': value.horsesExists,
        'resultsExists': value.resultsExists,
        'possibleResultsExists': value.possibleResultsExists,
        'sportInfoType': value.sportInfoType,
    };
}

/**
* @export
* @enum {string}
*/
export enum SportActorBasicInformationSportInfoTypeEnum {
    HORSE = 'HORSE',
    LICENSE_HOLDER = 'LICENSE_HOLDER',
    OWNER = 'OWNER',
    BREEDER = 'BREEDER'
}



import SelectedHorses from './type/SelectedHorses';

const getIdsFromSelectedHorses = (selectedHorses: SelectedHorses): number[] => {
  const ids = [];
  for (const horseId in selectedHorses) {
    if (selectedHorses[horseId]) {
      ids.push(parseInt(horseId));
    }
  }
  return ids;
};

export default getIdsFromSelectedHorses;

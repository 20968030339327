// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * This contains a license holders travel fees.
 * @export
 * @interface TravelFee
 */
export interface TravelFee  {
    /**
     * 
     * @type {number}
     * @memberof TravelFee
     */
    trackId?: number;
    /**
     * 
     * @type {string}
     * @memberof TravelFee
     */
    trackCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TravelFee
     */
    trackName?: string;
    /**
     * 
     * @type {number}
     * @memberof TravelFee
     */
    fee?: number;
}

export function TravelFeeFromJSON(json: any): TravelFee {
    return {
        'trackId': !exists(json, 'trackId') ? undefined : json['trackId'],
        'trackCode': !exists(json, 'trackCode') ? undefined : json['trackCode'],
        'trackName': !exists(json, 'trackName') ? undefined : json['trackName'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
    };
}

export function TravelFeeToJSON(value?: TravelFee): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'trackId': value.trackId,
        'trackCode': value.trackCode,
        'trackName': value.trackName,
        'fee': value.fee,
    };
}



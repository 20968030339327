import { Action, Reducer } from 'redux';
import {
  queriesReducer as baseQueriesReducer,
  QueriesState,
  ReduxQueryAction,
} from 'redux-query';

const RESET_QUERY = 'app/RESET_QUERY';

export type ResetQueryAction = Action<'app/RESET_QUERY'> & {
  queryKey: string;
  operator: Operator;
};

export enum OperatorEnum {
  EQUAL = 'EQUAL',
  STARTS_WITH = 'STARTS_WITH',
}

type Operator = OperatorEnum;

export const resetQuery = (
  queryKey: string,
  operator: Operator = OperatorEnum.EQUAL,
): ResetQueryAction => ({
  type: RESET_QUERY,
  queryKey,
  operator,
});

type QueriesReducer = Reducer<
  QueriesState,
  ReduxQueryAction | ResetQueryAction
>;

const queriesReducer: QueriesReducer = (state, action) => {
  switch (action.type) {
    case RESET_QUERY:
      if (action.queryKey) {
        const newState = Object.entries(state).filter(query =>
          action.operator === OperatorEnum.EQUAL
            ? query[0] != action.queryKey
            : !query[0].startsWith(action.queryKey),
        );
        return Object.fromEntries(newState);
      }

      return state;
  }

  return baseQueriesReducer(state, action as ReduxQueryAction);
};

export default queriesReducer;

import { useEffect, useRef } from 'react';

const isAbortControllerSupported = Object.prototype.hasOwnProperty.call(
  window,
  'AbortController',
);
const noOp = (): null => null;

const initAbortController = (): AbortController =>
  isAbortControllerSupported
    ? new AbortController()
    : ({ abort: noOp, signal: {} } as AbortController);

const useAbortController = (): AbortController => {
  const abortController = useRef(initAbortController());

  useEffect(() => () => abortController.current.abort(), []);

  return abortController.current;
};

export default useAbortController;

import { Entities } from '@apis';
import { enrollHorseToUpcomingStakeRace } from '@generated/propositions/src';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../../utils/types';
import { useApiMutateWithoutData } from '../../utils/useApiMutate';

export default function useEnrollHorseToUpcomingStakeRace(
  propositionId: number,
  trainerId: number,
): MutationResponseWithoutData<Entities, number> {
  const key = `${propositionId}-${trainerId}`;

  const queryFunc = (horseId: number): QueryConfig =>
    enrollHorseToUpcomingStakeRace(
      {
        propositionId,
        trainerId,
        xEndUserIp: '0.0.0.0',
        body: horseId,
      },
      {
        update: {
          upcomingStakeRaceHorses: prev => ({
            ...prev,
            [key]: prev[key].map(item => ({
              ...item,
              enrolled: horseId === item.id || item.enrolled,
            })),
          }),
        },
      },
    );

  return useApiMutateWithoutData({
    queryFunc,
    queryKey: 'enrollHorseToUpcomingStakeRace',
    resetKeys: [
      'stakeRacesWithEnrolledHorses:*',
      'horsesEnrolledToStakeRace:*',
    ],
  });
}

import { ArrowLeftSVG } from '@primitives/Arrow';
import Icon from '@primitives/Icon';
import { Link } from '@primitives/Link';
import React, { ReactNode } from 'react';

type LinkParameters = Parameters<typeof Link>;

type Props = {
  children: ReactNode;
} & LinkParameters[0];

const BackLink = ({ children, ...props }: Props): JSX.Element => (
  <Link display="flex" alignItems="center" {...props}>
    <Icon as={ArrowLeftSVG} color="blue" mr={'5px'} size={16} />
    {children}
  </Link>
);

export default BackLink;

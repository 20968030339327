import {
  getVatRegistrations,
  VatRegistrations,
} from '@generated/sportactors/src';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = VatRegistrations | undefined;

export interface Entities {
  vatRegistrations?: {
    [id: number]: Result;
  };
}

export default function useGetVatRegistrations(
  ownerId: number,
): ApiResponse<Result, Entities> {
  return useApi<Result>({
    query: getVatRegistrations<Entities>(
      {
        ownerId,
      },
      {
        transform: responseBody => ({
          vatRegistrations: {
            [ownerId]: responseBody,
          },
        }),
        update: {
          vatRegistrations: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities?.vatRegistrations?.[ownerId],
  });
}

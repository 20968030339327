import { Driver, getValidDrivers } from '@generated/propositions/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Results = Driver[] | undefined;

export interface Entities {
  validDrivers?: {
    [key: number]: Results;
  };
}

export const getQuery = (propositionId: number): QueryConfig<Entities> =>
  getValidDrivers<Entities>(
    {
      propositionId,
    },
    {
      transform: responseBody => ({
        validDrivers: {
          [propositionId]: responseBody,
        },
      }),
      update: {
        validDrivers: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useSearchHorses = (
  propositionId: number,
): ApiResponse<Results, Entities> => {
  const query = getQuery(propositionId);
  const selector = (state: State): Results =>
    state.entities?.validDrivers?.[propositionId];

  return useApi<Results>({
    query,
    selector,
  });
};

export default useSearchHorses;

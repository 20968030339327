// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Ownership,
    OwnershipFromJSON,
    OwnershipToJSON,
    OwnershipHorse,
    OwnershipHorseFromJSON,
    OwnershipHorseToJSON,
    OwnershipsHorsesSummary,
    OwnershipsHorsesSummaryFromJSON,
    OwnershipsHorsesSummaryToJSON,
    OwnershipsStatisticsSummary,
    OwnershipsStatisticsSummaryFromJSON,
    OwnershipsStatisticsSummaryToJSON,
    VatRegistrationInsertUpdate,
    VatRegistrationInsertUpdateFromJSON,
    VatRegistrationInsertUpdateToJSON,
    VatRegistrations,
    VatRegistrationsFromJSON,
    VatRegistrationsToJSON,
} from '../models';

export interface DeleteVatRegistrationOwnershipRequest {
    ownerId: number;
    ownershipId: number;
}

export interface DeleteVatRegistrationRepresentativeRequest {
    ownerId: number;
    representativeId: number;
}

export interface GetHorsesByOwnershipsRequest {
    ownerId: number;
}

export interface GetOwnershipsRequest {
    ownerId: number;
}

export interface GetOwnershipsHorsesSummaryRequest {
    ownerId: number;
}

export interface GetOwnershipsStatisticsSummaryRequest {
    ownerId: number;
}

export interface GetVatRegistrationsRequest {
    ownerId: number;
}

export interface InsertVatRegistrationOwnershipRequest {
    ownerId: number;
    ownershipId: number;
    vatRegistrationInsertUpdate: VatRegistrationInsertUpdate;
}

export interface InsertVatRegistrationRepresentativeRequest {
    ownerId: number;
    representativeId: number;
    vatRegistrationInsertUpdate: VatRegistrationInsertUpdate;
}


/**
 * Delete an ownership vat number for the provided ownership and owner id.
 */
function deleteVatRegistrationOwnershipRaw<T>(requestParameters: DeleteVatRegistrationOwnershipRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling deleteVatRegistrationOwnership.');
    }

    if (requestParameters.ownershipId === null || requestParameters.ownershipId === undefined) {
        throw new runtime.RequiredError('ownershipId','Required parameter requestParameters.ownershipId was null or undefined when calling deleteVatRegistrationOwnership.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/{ownershipId}/vatregistrations`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))).replace(`{${"ownershipId"}}`, encodeURIComponent(String(requestParameters.ownershipId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Delete an ownership vat number for the provided ownership and owner id.
*/
export function deleteVatRegistrationOwnership<T>(requestParameters: DeleteVatRegistrationOwnershipRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteVatRegistrationOwnershipRaw(requestParameters, requestConfig);
}

/**
 * Delete a representative vat number for the provided representative and owner id
 */
function deleteVatRegistrationRepresentativeRaw<T>(requestParameters: DeleteVatRegistrationRepresentativeRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling deleteVatRegistrationRepresentative.');
    }

    if (requestParameters.representativeId === null || requestParameters.representativeId === undefined) {
        throw new runtime.RequiredError('representativeId','Required parameter requestParameters.representativeId was null or undefined when calling deleteVatRegistrationRepresentative.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/representatives/{representativeId}/vatregistrations`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))).replace(`{${"representativeId"}}`, encodeURIComponent(String(requestParameters.representativeId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Delete a representative vat number for the provided representative and owner id
*/
export function deleteVatRegistrationRepresentative<T>(requestParameters: DeleteVatRegistrationRepresentativeRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteVatRegistrationRepresentativeRaw(requestParameters, requestConfig);
}

/**
 * Retrieve all the horses I have ownership of for the provided owner\'s id.
 */
function getHorsesByOwnershipsRaw<T>(requestParameters: GetHorsesByOwnershipsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<OwnershipHorse>> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getHorsesByOwnerships.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/horses`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(OwnershipHorseFromJSON), text);
    }

    return config;
}

/**
* Retrieve all the horses I have ownership of for the provided owner\'s id.
*/
export function getHorsesByOwnerships<T>(requestParameters: GetHorsesByOwnershipsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<OwnershipHorse>>): QueryConfig<T> {
    return getHorsesByOwnershipsRaw(requestParameters, requestConfig);
}

/**
 * Get all ownerships for the provided owner id
 */
function getOwnershipsRaw<T>(requestParameters: GetOwnershipsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Ownership>> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getOwnerships.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(OwnershipFromJSON), text);
    }

    return config;
}

/**
* Get all ownerships for the provided owner id
*/
export function getOwnerships<T>(requestParameters: GetOwnershipsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Ownership>>): QueryConfig<T> {
    return getOwnershipsRaw(requestParameters, requestConfig);
}

/**
 * Retrieve summary of owned horses for the specified owner. Contains info about the number of owned horses at present.
 */
function getOwnershipsHorsesSummaryRaw<T>(requestParameters: GetOwnershipsHorsesSummaryRequest, requestConfig: runtime.TypedQueryConfig<T, OwnershipsHorsesSummary> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getOwnershipsHorsesSummary.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/horses/summary`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OwnershipsHorsesSummaryFromJSON(body), text);
    }

    return config;
}

/**
* Retrieve summary of owned horses for the specified owner. Contains info about the number of owned horses at present.
*/
export function getOwnershipsHorsesSummary<T>(requestParameters: GetOwnershipsHorsesSummaryRequest, requestConfig?: runtime.TypedQueryConfig<T, OwnershipsHorsesSummary>): QueryConfig<T> {
    return getOwnershipsHorsesSummaryRaw(requestParameters, requestConfig);
}

/**
 * Get the specified owners statistics summary. Contains info about the number of victories this year.
 */
function getOwnershipsStatisticsSummaryRaw<T>(requestParameters: GetOwnershipsStatisticsSummaryRequest, requestConfig: runtime.TypedQueryConfig<T, OwnershipsStatisticsSummary> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getOwnershipsStatisticsSummary.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/statistics/summary`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OwnershipsStatisticsSummaryFromJSON(body), text);
    }

    return config;
}

/**
* Get the specified owners statistics summary. Contains info about the number of victories this year.
*/
export function getOwnershipsStatisticsSummary<T>(requestParameters: GetOwnershipsStatisticsSummaryRequest, requestConfig?: runtime.TypedQueryConfig<T, OwnershipsStatisticsSummary>): QueryConfig<T> {
    return getOwnershipsStatisticsSummaryRaw(requestParameters, requestConfig);
}

/**
 * Get all vat registrations for the provided owner id
 */
function getVatRegistrationsRaw<T>(requestParameters: GetVatRegistrationsRequest, requestConfig: runtime.TypedQueryConfig<T, VatRegistrations> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getVatRegistrations.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/vatregistrations`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(VatRegistrationsFromJSON(body), text);
    }

    return config;
}

/**
* Get all vat registrations for the provided owner id
*/
export function getVatRegistrations<T>(requestParameters: GetVatRegistrationsRequest, requestConfig?: runtime.TypedQueryConfig<T, VatRegistrations>): QueryConfig<T> {
    return getVatRegistrationsRaw(requestParameters, requestConfig);
}

/**
 * Insert or update ownership vat number for the provided ownership and owner id
 */
function insertVatRegistrationOwnershipRaw<T>(requestParameters: InsertVatRegistrationOwnershipRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling insertVatRegistrationOwnership.');
    }

    if (requestParameters.ownershipId === null || requestParameters.ownershipId === undefined) {
        throw new runtime.RequiredError('ownershipId','Required parameter requestParameters.ownershipId was null or undefined when calling insertVatRegistrationOwnership.');
    }

    if (requestParameters.vatRegistrationInsertUpdate === null || requestParameters.vatRegistrationInsertUpdate === undefined) {
        throw new runtime.RequiredError('vatRegistrationInsertUpdate','Required parameter requestParameters.vatRegistrationInsertUpdate was null or undefined when calling insertVatRegistrationOwnership.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/{ownershipId}/vatregistrations`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))).replace(`{${"ownershipId"}}`, encodeURIComponent(String(requestParameters.ownershipId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || VatRegistrationInsertUpdateToJSON(requestParameters.vatRegistrationInsertUpdate),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Insert or update ownership vat number for the provided ownership and owner id
*/
export function insertVatRegistrationOwnership<T>(requestParameters: InsertVatRegistrationOwnershipRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return insertVatRegistrationOwnershipRaw(requestParameters, requestConfig);
}

/**
 * Insert or update representative vat number for the provided representative and owner id
 */
function insertVatRegistrationRepresentativeRaw<T>(requestParameters: InsertVatRegistrationRepresentativeRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling insertVatRegistrationRepresentative.');
    }

    if (requestParameters.representativeId === null || requestParameters.representativeId === undefined) {
        throw new runtime.RequiredError('representativeId','Required parameter requestParameters.representativeId was null or undefined when calling insertVatRegistrationRepresentative.');
    }

    if (requestParameters.vatRegistrationInsertUpdate === null || requestParameters.vatRegistrationInsertUpdate === undefined) {
        throw new runtime.RequiredError('vatRegistrationInsertUpdate','Required parameter requestParameters.vatRegistrationInsertUpdate was null or undefined when calling insertVatRegistrationRepresentative.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/representatives/{representativeId}/vatregistrations`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))).replace(`{${"representativeId"}}`, encodeURIComponent(String(requestParameters.representativeId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || VatRegistrationInsertUpdateToJSON(requestParameters.vatRegistrationInsertUpdate),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Insert or update representative vat number for the provided representative and owner id
*/
export function insertVatRegistrationRepresentative<T>(requestParameters: InsertVatRegistrationRepresentativeRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return insertVatRegistrationRepresentativeRaw(requestParameters, requestConfig);
}


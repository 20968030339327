// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Driver
 */
export interface Driver  {
    /**
     * The license id of the driver
     * @type {number}
     * @memberof Driver
     */
    id: number;
    /**
     * The name of the driver
     * @type {string}
     * @memberof Driver
     */
    name: string;
    /**
     * The abstract of the driver\'s name
     * @type {string}
     * @memberof Driver
     */
    shortName?: string;
    /**
     * The minimum riding weight of the driver
     * @type {number}
     * @memberof Driver
     */
    lowestRidingWeight?: number;
    /**
     * The riders weight allowance
     * @type {number}
     * @memberof Driver
     */
    weightReduction?: number;
    /**
     * True if the driver is suspended
     * @type {boolean}
     * @memberof Driver
     */
    suspended?: boolean;
    /**
     * True if the driver is apprentice
     * @type {boolean}
     * @memberof Driver
     */
    apprentice?: boolean;
    /**
     * True if the driver is amateur
     * @type {boolean}
     * @memberof Driver
     */
    amateur?: boolean;
    /**
     * The driver license type
     * @type {string}
     * @memberof Driver
     */
    licenseType?: string;
    /**
     * The driver license description
     * @type {string}
     * @memberof Driver
     */
    licenseDescription?: string;
}

export function DriverFromJSON(json: any): Driver {
    return {
        'id': json['id'],
        'name': json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'lowestRidingWeight': !exists(json, 'lowestRidingWeight') ? undefined : json['lowestRidingWeight'],
        'weightReduction': !exists(json, 'weightReduction') ? undefined : json['weightReduction'],
        'suspended': !exists(json, 'suspended') ? undefined : json['suspended'],
        'apprentice': !exists(json, 'apprentice') ? undefined : json['apprentice'],
        'amateur': !exists(json, 'amateur') ? undefined : json['amateur'],
        'licenseType': !exists(json, 'licenseType') ? undefined : json['licenseType'],
        'licenseDescription': !exists(json, 'licenseDescription') ? undefined : json['licenseDescription'],
    };
}

export function DriverToJSON(value?: Driver): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'shortName': value.shortName,
        'lowestRidingWeight': value.lowestRidingWeight,
        'weightReduction': value.weightReduction,
        'suspended': value.suspended,
        'apprentice': value.apprentice,
        'amateur': value.amateur,
        'licenseType': value.licenseType,
        'licenseDescription': value.licenseDescription,
    };
}



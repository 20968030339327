import {
  getStakeRaceWithStakes,
  StakeRaceWithStakes,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../../utils/types';
import useApi from '../../utils/useApi';

type Results = StakeRaceWithStakes | undefined;

export interface Entities {
  stakeRaceWithStakes?: {
    [propositionId: number]: Results;
  };
}

export default function useGetStakeRaceWithStakes(
  propositionId: number,
): ApiResponse<Results, Entities> {
  const query = getStakeRaceWithStakes<Entities>(
    {
      propositionId,
    },
    {
      transform: responseBody => ({
        stakeRaceWithStakes: {
          [propositionId]: responseBody,
        },
      }),
      update: {
        stakeRaceWithStakes: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Results =>
    state.entities?.stakeRaceWithStakes?.[propositionId];

  return useApi<Results>({
    query,
    selector,
  });
}

import Card from '@components/Card';
import InfoTable from '@components/InfoTable';
import { StakeRaceEnrolled } from '@generated/propositions/src';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import { Link } from '@primitives/Link';
import { H4 } from '@primitives/Typography';
import { formatTz } from '@utils/dateUtils';
import React from 'react';
import { generatePath, useRouteMatch } from 'react-router-dom';

interface Props {
  row: StakeRaceEnrolled;
}

export default function ListItem({ row }: Props): JSX.Element {
  const { path } = useRouteMatch();

  const {
    track,
    stakeNumber,
    propositionName,
    propositionId,
    invoiceExpirationDate,
  } = row;

  return (
    <Card p={1} pb={0}>
      <Box px={2} py={1}>
        <H4 as="h2" color="gray6" mb={2} mt={1} fontWeight="bold">
          {propositionName}
        </H4>
        <InfoTable
          forceColumns={3}
          values={[
            {
              label: 'Bana',
              value: track.name,
              wrapHyphen: true,
            },
            {
              label: 'Insats',
              value: stakeNumber,
            },
            {
              label: 'Nästa insatstermin',
              value: formatTz(invoiceExpirationDate, 'yyyy-MM-dd'),
            },
          ]}
        />
      </Box>

      <Box mt={2} borderTop="solid 1px #E5DFD6" p={1} mx={-1}>
        <Box m={1}>
          <Button
            as={Link}
            to={generatePath(path, {
              section: 'anmalda',
              propositionId: propositionId.toString(),
            })}
            block
            colorScheme={'secondary'}
          >
            Visa anmälda hästar
          </Button>
        </Box>
      </Box>
    </Card>
  );
}

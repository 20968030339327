import { useGetStartDeclarationInformation } from '@apis';
import StartDeclarationHorseInfo from '@components/HorseInfo/StartDeclarationHorseInfo';
import { AuthenticatedUser } from '@generated/account/src';
import useNumericParams from '@hooks/useNumericParams';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import { Lead } from '@primitives/Typography';
import { thousandSeparator } from '@utils/thousandSeparator';
import React from 'react';
import { useParams } from 'react-router-dom';

import Form from '../Form';
import Header from '../utils/Header';

interface Props {
  user: AuthenticatedUser;
}

function RegistrationForm({ user }: Props): JSX.Element {
  const { action } = useParams<{ action: string }>();

  const { horseId, propId } = useNumericParams<{
    horseId: number;
    propId: number;
  }>();

  const { data, loading, status, error, refresh } =
    useGetStartDeclarationInformation(horseId, propId, true);

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <Header
      trackName={data.trackName}
      raceDate={data.startDateTime}
      registrationDate={data.enrollStartDateTime}
      changeWeightDate={data.propositionWeightDateTime}
      latestStartRegistrationDate={data.lastEnrollStartDateTime}
      headline={
        action === 'uppdatera' ? 'Uppdatera startanmälan' : 'Ny startanmälan'
      }
      backLink={
        action === 'uppdatera'
          ? '/minasidor/startanmalan/mina-startanmalningar'
          : `/minasidor/startanmalan/tavlingsdagar/${data.raceDayId}`
      }
    >
      <>
        <Box>
          <Box display="flex" pl={1}>
            <Lead color="deep-blue">
              <b>
                Prop {data.proposition.number} {data.proposition.raceName}
              </b>{' '}
              {thousandSeparator(data.proposition.totalSum)} kr{' '}
              {data.proposition.distance} {data.proposition.surface}{' '}
              {data.proposition.raceType}
            </Lead>
          </Box>
          <Box pb={3}></Box>
        </Box>

        <StartDeclarationHorseInfo data={data} />

        <Form
          data={data}
          horseId={horseId}
          propositionId={propId}
          licenseId={user.licenseId}
          refresh={refresh}
        />
      </>
    </Header>
  );
}
export default RegistrationForm;

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Distance
 */
export interface Distance  {
    /**
     * 
     * @type {number}
     * @memberof Distance
     */
    sortValue: number;
    /**
     * 
     * @type {string}
     * @memberof Distance
     */
    displayValue: string;
}

export function DistanceFromJSON(json: any): Distance {
    return {
        'sortValue': json['sortValue'],
        'displayValue': json['displayValue'],
    };
}

export function DistanceToJSON(value?: Distance): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'sortValue': value.sortValue,
        'displayValue': value.displayValue,
    };
}



import { useDeleteStartDeclaration } from '@apis';
import { RemoveButton } from '@primitives/Button';
import Refresh from '@utils/Refresh';
import React, { useCallback } from 'react';

interface Props {
  propositionId: number;
  horseId: number;
  raceDayId: number;
  licenseId: number;
  refresh?: Refresh;
}

const DeleteStartDeclaration = ({
  propositionId,
  horseId,
  refresh,
}: Props): JSX.Element => {
  const { action } = useDeleteStartDeclaration(propositionId, horseId);

  const onAction = useCallback(async () => {
    await action({});

    // Refresh the horses declared for start
    await refresh();
  }, [action, refresh]);

  return (
    <RemoveButton
      onClick={onAction}
      confirm
      confirmTitle="Är du säker på att du vill avanmäla?"
      confirmDescription=""
    />
  );
};

export default DeleteStartDeclaration;

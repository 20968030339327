import { Marker } from '@components/Menu';
import { Link } from '@primitives/Link';
import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { MenuItem as MenuItemProps } from '../menus';
import HoverMenu from './HoverMenu';

const Wrapper = styled.li`
  height: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

interface Props extends MenuItemProps {
  hoverItem?: string;
  setHoverItem: (item: string | undefined) => void;
  showMarker?: boolean;
  searchIsOpen: boolean;
}

export default function PrimaryItem({
  href,
  label,
  subMenu,
  shortcutMenu,
  hoverItem,
  setHoverItem,
  showMarker,
  searchIsOpen,
}: Props): JSX.Element {
  return (
    <Wrapper
      data-eventtracking-region={'main-menu'}
      onMouseEnter={() => setHoverItem(href)}
      onMouseLeave={() => setHoverItem(undefined)}
    >
      <Link
        to={href}
        display="flex"
        sx={{
          color:
            hoverItem === href || hoverItem === undefined
              ? 'white'
              : transparentize(0.4, 'white'),
          fontSize: 'pageHeader',
          transition: 'color 0.2s',
          textDecoration: 'none',
          paddingRight: '12px',
          paddingLeft: '12px',
          alignItems: 'center',
        }}
      >
        {label}
      </Link>
      {showMarker && subMenu?.length > 0 && !searchIsOpen && <Marker />}
      {hoverItem === href && subMenu?.length > 0 && (
        <HoverMenu
          subMenu={subMenu}
          shortcutMenu={shortcutMenu}
          hideMenu={() => setHoverItem(undefined)}
        />
      )}
    </Wrapper>
  );
}

// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface RaceType
 */
export interface RaceType  {
    /**
     * Used for sorting the result list.
     * @type {string}
     * @memberof RaceType
     */
    sortValue: string;
    /**
     * Used to show in the result list.
     * @type {string}
     * @memberof RaceType
     */
    displayValue: string;
}

export function RaceTypeFromJSON(json: any): RaceType {
    return {
        'sortValue': json['sortValue'],
        'displayValue': json['displayValue'],
    };
}

export function RaceTypeToJSON(value?: RaceType): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'sortValue': value.sortValue,
        'displayValue': value.displayValue,
    };
}



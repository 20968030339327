import { useGetStuds } from '@apis';
import { Select } from '@primitives/Form';
import React from 'react';

import { useCoveringContext } from './context';

export default function SelectStud(): JSX.Element {
  const { year, studId, setStudId, licenseId } = useCoveringContext();
  const { data: studs } = useGetStuds(licenseId, year);

  const studOptions =
    studs?.map(item => ({
      value: item.id,
      label: item.name,
    })) || [];

  return (
    <Select
      label="Hingst"
      options={[
        {
          value: '',
          label: 'Välj hingst',
        },
        ...studOptions,
      ]}
      onChange={e => {
        const studId = e.target.value ? parseInt(e.target.value) : undefined;
        setStudId(studId);
      }}
      value={studId || ''}
    />
  );
}

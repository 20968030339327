// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    InfoText,
    InfoTextFromJSON,
    InfoTextToJSON,
    Stake,
    StakeFromJSON,
    StakeToJSON,
} from './';

/**
 * 
 * @export
 * @interface StakeRaceWithStakes
 */
export interface StakeRaceWithStakes  {
    /**
     * 
     * @type {number}
     * @memberof StakeRaceWithStakes
     */
    propositionId: number;
    /**
     * 
     * @type {Array<InfoText>}
     * @memberof StakeRaceWithStakes
     */
    infoTexts: Array<InfoText>;
    /**
     * 
     * @type {Array<Stake>}
     * @memberof StakeRaceWithStakes
     */
    stakes: Array<Stake>;
    /**
     * 
     * @type {boolean}
     * @memberof StakeRaceWithStakes
     */
    enrollmentUpdatesAllowed: boolean;
}

export function StakeRaceWithStakesFromJSON(json: any): StakeRaceWithStakes {
    return {
        'propositionId': json['propositionId'],
        'infoTexts': (json['infoTexts'] as Array<any>).map(InfoTextFromJSON),
        'stakes': (json['stakes'] as Array<any>).map(StakeFromJSON),
        'enrollmentUpdatesAllowed': json['enrollmentUpdatesAllowed'],
    };
}

export function StakeRaceWithStakesToJSON(value?: StakeRaceWithStakes): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'propositionId': value.propositionId,
        'infoTexts': (value.infoTexts as Array<any>).map(InfoTextToJSON),
        'stakes': (value.stakes as Array<any>).map(StakeToJSON),
        'enrollmentUpdatesAllowed': value.enrollmentUpdatesAllowed,
    };
}



// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Bucket
 */
export interface Bucket  {
    /**
     * 
     * @type {string}
     * @memberof Bucket
     */
    key: string;
    /**
     * 
     * @type {number}
     * @memberof Bucket
     */
    docCount: number;
}

export function BucketFromJSON(json: any): Bucket {
    return {
        'key': json['key'],
        'docCount': json['doc_count'],
    };
}

export function BucketToJSON(value?: Bucket): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'key': value.key,
        'doc_count': value.docCount,
    };
}



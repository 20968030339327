import { useGetLicenseHolder } from '@apis';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import { Text } from '@primitives/Typography';
import React from 'react';

interface Props {
  driverId: number;
}

const DisplayDriverInfo = ({ driverId }: Props): JSX.Element => {
  const { data, loading, status, error } = useGetLicenseHolder(driverId);

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <Box>
      <Text>Licens: {data.licenseInfo.description}</Text>
      {data.contactInfo?.phones.map((phone, index) => (
        <Text key={index}>Telefon: {phone.number} </Text>
      ))}
      {data.contactInfo?.email && (
        <Text>E-post: {data.contactInfo?.email}</Text>
      )}
      {data.lowestRidingWeight && (
        <Text>Lägsta ridvikt: {data.lowestRidingWeight}</Text>
      )}
      {data.commissions?.riderPercent && (
        <Text>Provision: {data.commissions?.riderPercent} %</Text>
      )}
      {(data.commissions?.flatRace || data.commissions?.steepleRace) && (
        <Text>
          Ridarvode{' '}
          {data.commissions?.flatRace && (
            <>slätt: {data.commissions?.flatRace} kr</>
          )}
          {data.commissions?.steepleRace && (
            <>, hinder: {data.commissions?.steepleRace} kr</>
          )}
        </Text>
      )}
      {data.commissions?.travelFees.length > 0 && (
        <Box display="flex">
          <Text>Reseersättning: </Text>{' '}
          {data.commissions?.travelFees?.map(({ trackName, fee }, index) => (
            <Text key={index} pl={1}>
              {trackName}: {fee} kr{' '}
            </Text>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default DisplayDriverInfo;

// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    OffspringView,
    OffspringViewFromJSON,
    OffspringViewToJSON,
} from '../models';

export interface GetOffspringRequest {
    horseId: number;
    gendercode: string;
}


/**
 * Get a horse\'s offspring
 */
function getOffspringRaw<T>(requestParameters: GetOffspringRequest, requestConfig: runtime.TypedQueryConfig<T, OffspringView> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling getOffspring.');
    }

    if (requestParameters.gendercode === null || requestParameters.gendercode === undefined) {
        throw new runtime.RequiredError('gendercode','Required parameter requestParameters.gendercode was null or undefined when calling getOffspring.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.gendercode !== undefined) {
        queryParameters['gendercode'] = requestParameters.gendercode;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{horseId}/offspring`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OffspringViewFromJSON(body), text);
    }

    return config;
}

/**
* Get a horse\'s offspring
*/
export function getOffspring<T>(requestParameters: GetOffspringRequest, requestConfig?: runtime.TypedQueryConfig<T, OffspringView>): QueryConfig<T> {
    return getOffspringRaw(requestParameters, requestConfig);
}


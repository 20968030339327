// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface BankIdSignRequest
 */
export interface BankIdSignRequest  {
    /**
     * Users ip address
     * @type {string}
     * @memberof BankIdSignRequest
     */
    endUserIp?: string;
    /**
     * A set of certificate policies applied to the requested operation
     * @type {Array<string>}
     * @memberof BankIdSignRequest
     */
    certificatePolicies?: Array<BankIdSignRequestCertificatePoliciesEnum>;
}

export function BankIdSignRequestFromJSON(json: any): BankIdSignRequest {
    return {
        'endUserIp': !exists(json, 'endUserIp') ? undefined : json['endUserIp'],
        'certificatePolicies': !exists(json, 'certificatePolicies') ? undefined : json['certificatePolicies'],
    };
}

export function BankIdSignRequestToJSON(value?: BankIdSignRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'endUserIp': value.endUserIp,
        'certificatePolicies': value.certificatePolicies,
    };
}

/**
* @export
* @enum {string}
*/
export enum BankIdSignRequestCertificatePoliciesEnum {
    MOBILE_BANKID = 'MOBILE_BANKID',
    BANKID_ON_FILE = 'BANKID_ON_FILE',
    BANK_ID_ON_SMART_CARD = 'BANK_ID_ON_SMART_CARD',
    NORDEA_E_ID = 'NORDEA_E_ID'
}



// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Horse,
    HorseFromJSON,
    HorseToJSON,
} from './';

/**
 * Details about the mare used for breeding
 * @export
 * @interface MareDetails
 */
export interface MareDetails  {
    /**
     * 
     * @type {Horse}
     * @memberof MareDetails
     */
    mare: Horse;
    /**
     * Birth year of the mare
     * @type {number}
     * @memberof MareDetails
     */
    birthYear: number;
    /**
     * Present if the mare has died, contains the year of passing
     * @type {number}
     * @memberof MareDetails
     */
    deathYear?: number;
    /**
     * 
     * @type {Horse}
     * @memberof MareDetails
     */
    father: Horse;
    /**
     * 
     * @type {boolean}
     * @memberof MareDetails
     */
    bredWithMultipleStallions: boolean;
}

export function MareDetailsFromJSON(json: any): MareDetails {
    return {
        'mare': HorseFromJSON(json['mare']),
        'birthYear': json['birthYear'],
        'deathYear': !exists(json, 'deathYear') ? undefined : json['deathYear'],
        'father': HorseFromJSON(json['father']),
        'bredWithMultipleStallions': json['bredWithMultipleStallions'],
    };
}

export function MareDetailsToJSON(value?: MareDetails): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'mare': HorseToJSON(value.mare),
        'birthYear': value.birthYear,
        'deathYear': value.deathYear,
        'father': HorseToJSON(value.father),
        'bredWithMultipleStallions': value.bredWithMultipleStallions,
    };
}



import { Template } from './_templateData';

export const NamnansokanForHast: Template = {
  id: 1,
  actorType: 'horse',
  fields: [
    {
      name: 'Vilken häst gäller ärendet?',
      description:
        'Välj en av dina ägda hästar från listan med registrerade hästar eller ange uppgifter för en ej registrerad häst.',
      fields: [
        'horseSwitchButton',
        'horse',
        'horseInfo',
        'horseName',
        'horseBirthYear',
        'horseSex',
        'horseFather',
        'horseMother',
        'horseGrandFather',
      ],
    },
    {
      name: 'Vilket namn ska hästen ha?',
      description:
        'Ange minst ett och max tre namnförslag i prioritetsordning.',
      fields: ['horseName1', 'horseName2', 'horseName3'],
    },
  ],
  fieldOptions: [
    {
      horse: { hidden: 'values?.horseSwitchButton || values.horse' },
      horseSwitchButton: { hidden: 'values?.horse' },
      horseInfo: { hidden: 'values?.horseSwitchButton || !values.horse' },
      horseName: { hidden: '!values?.horseSwitchButton' },
      horseBirthYear: { hidden: '!values?.horseSwitchButton' },
      horseSex: { hidden: '!values?.horseSwitchButton' },
      horseFather: { hidden: '!values?.horseSwitchButton' },
      horseMother: { hidden: '!values?.horseSwitchButton' },
      horseGrandFather: { hidden: '!values?.horseSwitchButton' },
      horseName1: { max: 18 },
      horseName2: { max: 18 },
      horseName3: { max: 18 },
    },
  ],
  provider: 'email',
};

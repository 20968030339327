import { useGetCoveringListRegistrationDeadlines } from '@apis';
import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import roleEnum from '@utils/roleEnum';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Covering from './Covering';
import Results from './Results';

interface Props {
  user: AuthenticatedUser;
}

function CoveringLists({ user }: Props): JSX.Element {
  const { path } = useRouteMatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data } = useGetCoveringListRegistrationDeadlines();

  return (
    <PageLayout
      headline="Språngrulla"
      singleCard
      tabs={[
        {
          to: `${path}`,
          text: 'Betäckningar',
          isActive: (_, { pathname }) => !pathname.includes('resultat'),
        },
        {
          to: `${path}/resultat`,
          text: 'Betäckningsresultat',
        },
      ]}
    >
      <Switch>
        <Route path={`${path}/:studId(\\d+)?/:coveringId(new|\\d+)?`} exact>
          <Covering licenseId={user.licenseId} />
        </Route>
        <Route path={`${path}/resultat/:year(\\d+)?/:studId(\\d+)?`}>
          <Results licenseId={user.licenseId} />
        </Route>
      </Switch>
    </PageLayout>
  );
}

export default withUser(CoveringLists, {
  onlyAuthenticated: true,
  roles: [roleEnum.Hingsthallare],
  header: 'Språngrulla',
});

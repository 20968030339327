import {
  generateNordicCertificateLink,
  OTTLink,
} from '@generated/licenseholders/src';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = OTTLink | undefined;
export interface Entities {
  generateNordicCertificatePdfLink?: {
    [key: string]: Result;
  };
}

const useGenerateNordicCertificatePdfLink = (
  licenseId: number,
  id: number,
): ApiResponse<Result, Entities> => {
  const key = `${licenseId}-${id}`;
  const query = generateNordicCertificateLink<Entities>(
    {
      licenseId,
      id,
    },
    {
      transform: responseBody => ({
        generateNordicCertificatePdfLink: { [key]: responseBody },
      }),
      update: {
        generateNordicCertificatePdfLink: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  return useApi<Result>({
    query,
    selector: state => state.entities?.generateNordicCertificatePdfLink?.[key],
  });
};

export default useGenerateNordicCertificatePdfLink;

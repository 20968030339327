import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { fetchHorseResults, HorseResult } from '../../generated/horses/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type HorseResults = HorseResult[] | undefined;

export interface Entities {
  horseResults?: {
    [id: number]: HorseResults;
  };
}

const getQuery = (horseId: number): QueryConfig<Entities> =>
  fetchHorseResults<Entities>(
    {
      horseId,
    },
    {
      transform: responseBody => ({
        horseResults: {
          [horseId]: responseBody,
        },
      }),
      update: {
        horseResults: (prev, next): typeof next => ({ ...prev, ...next }),
      },
    },
  );

const useGetHorseResults = (
  horseId: number,
): ApiResponse<HorseResults, Entities> => {
  const query = getQuery(horseId);
  const selector = (state: State): HorseResults =>
    state.entities?.horseResults?.[horseId];

  return useApi<HorseResults>({
    query,
    selector,
  });
};

export default useGetHorseResults;

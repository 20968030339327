import { useBankIdHorseInitialize } from '@apis';
import {
  BankIdSignRequestCertificatePoliciesEnum,
  InitializeRegistrationStatusUpdateStatusEnum,
  UpdateRegistrationStatusStatusEnum,
} from '@generated/horses/src';
import { useEffect, useState } from 'react';

import TaskEnum from './enum/Task';
import SetTask from './type/SetTask';

interface Props {
  horseId: number;
  status: UpdateRegistrationStatusStatusEnum;
  fromdate: Date;
  setTask: SetTask;
}

export default function BankIdInitialize({
  fromdate,
  horseId,
  setTask,
  status,
}: Props): JSX.Element {
  const [first, setFirst] = useState<boolean>(true);

  const { action } = useBankIdHorseInitialize(
    fromdate,
    horseId,
    status as unknown as InitializeRegistrationStatusUpdateStatusEnum,
  );

  useEffect(() => {
    if (first) {
      const runAction = async (): Promise<void> => {
        const { body } = await action({
          certificatePolicies: [
            BankIdSignRequestCertificatePoliciesEnum.MOBILE_BANKID,
            BankIdSignRequestCertificatePoliciesEnum.BANKID_ON_FILE,
          ],
        });

        if (body && body.errorMessage) {
          setTask({
            type: TaskEnum.BankIdInitializeFailure,
            value: body.errorMessage,
          });
        } else {
          setTask({
            type: TaskEnum.RunBankIdCollect,
            value: {
              orderReference: body.orderReference,
              autoStartToken: body.autoStartToken,
            },
          });
        }
      };
      runAction();
      setFirst(false);
    }
  }, [action, first, setTask]);

  return null;
}

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface StartRegistrationDetails
 */
export interface StartRegistrationDetails  {
    /**
     * The start registration date.
     * @type {Date}
     * @memberof StartRegistrationDetails
     */
    date: Date;
    /**
     * If the start registration is still open or not.
     * @type {boolean}
     * @memberof StartRegistrationDetails
     */
    open: boolean;
    /**
     * The start registration time in string format (display value).
     * @type {string}
     * @memberof StartRegistrationDetails
     */
    time: string;
}

export function StartRegistrationDetailsFromJSON(json: any): StartRegistrationDetails {
    return {
        'date': parseDateTime(json['date']),
        'open': json['open'],
        'time': json['time'],
    };
}

export function StartRegistrationDetailsToJSON(value?: StartRegistrationDetails): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'date': value.date.toISOString(),
        'open': value.open,
        'time': value.time,
    };
}



import { useIssue } from '@apis';
import CardWithHeader from '@components/CardWithHeader';
import { Label } from '@components/Label';
import Box, { Container, Flex, Item } from '@primitives/Box';
import { Link } from '@primitives/Link';
import { Text } from '@primitives/Typography';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FilePdf } from 'styled-icons/fa-solid';

const ExternalLink = styled(Text).attrs({
  display: 'flex',

  height: 'fit-content',
  width: 'fit-content',
  textAlign: 'right',
})``;

export default function ListIssue(): JSX.Element | null {
  const { list } = useIssue();
  const [issues, setIssues] = useState([]);

  useEffect(() => {
    list().then(response => {
      response.json().then(data => {
        setIssues(data.issues);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get unique categories
  const categories = Array.from(new Set(issues.map(issue => issue.category)));

  return (
    <Flex flexDirection={'row'} verticalAlign={'top'}>
      <Container
        display="flex"
        flexDirection={'column'}
        alignSelf="baseline"
        width={[1, , 0.5]}
        gap={2}
      >
        {categories.slice(0, 3).map(category => (
          <Item key={category} mr={'0'} ml={'-4px'}>
            <CardWithHeader headerAlwaysBold header={category} mr={0} ml={0}>
              {issues
                .filter(issue => issue.category === category && issue.url)
                .map(issue => (
                  <Box key={issue.id} py={2} px={'4px'}>
                    {issue.url.startsWith('http') ? (
                      <ExternalLink
                        as="a"
                        sx={{ textAlign: 'left' }}
                        href={issue.url}
                        target="_blank"
                      >
                        {issue.title}
                        {issue.url.endsWith('.pdf') && (
                          <FilePdf
                            size={14}
                            style={{ top: 2, position: 'relative', left: 4 }}
                          />
                        )}
                      </ExternalLink>
                    ) : (
                      <div style={{ display: 'flex', gap: '4px' }}>
                        {issue.url.startsWith('/tjanster') && (
                          <>
                            <Label color="green">Ny</Label>
                          </>
                        )}
                        <Link exact strict to={issue.url}>
                          {issue.title}
                        </Link>
                      </div>
                    )}
                  </Box>
                ))}
            </CardWithHeader>
          </Item>
        ))}
      </Container>
      <Container
        display="flex"
        alignSelf="baseline"
        flexDirection={'column'}
        width={[1, , 0.5]}
        gap={2}
      >
        {categories.slice(3).map(category => (
          <Item key={category} mr={'-4px'} ml={0}>
            <CardWithHeader headerAlwaysBold header={category} mr={0} ml={0}>
              {issues
                .filter(issue => issue.category === category && issue.url)
                .map(issue => (
                  <Box key={issue.id} py={2} px={'4px'}>
                    {issue.url.startsWith('http') ? (
                      <ExternalLink
                        as="a"
                        sx={{ textAlign: 'left' }}
                        href={issue.url}
                        target="_blank"
                      >
                        {issue.title}
                        {issue.url.endsWith('.pdf') && (
                          <FilePdf
                            size={14}
                            style={{ top: 2, position: 'relative', left: 4 }}
                          />
                        )}
                      </ExternalLink>
                    ) : (
                      <div style={{ display: 'flex', gap: '4px' }}>
                        {issue.url.startsWith('/tjanster') && (
                          <>
                            <Label color="green">Ny</Label>
                          </>
                        )}
                        <Link exact strict to={issue.url}>
                          {issue.title}
                        </Link>
                      </div>
                    )}
                  </Box>
                ))}
            </CardWithHeader>
          </Item>
        ))}
      </Container>
    </Flex>
  );
}

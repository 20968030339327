import { useGetBlinkers } from '@apis';
import Step from '@components/Step';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import { Radio } from '@primitives/Form';
import Loading from '@primitives/Loading';
import { useFormikContext } from 'formik';
import React from 'react';

import { FormState } from '.';

const FormikBlinkers = (): JSX.Element => {
  const { data, loading, status, error } = useGetBlinkers();
  const { values, setFieldValue } = useFormikContext<FormState>();

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  return (
    <Step header="2. Blinkers" mt={5}>
      <Box
        display="flex"
        flexDirection={['column', , 'row']}
        horizontalSpacing={[, , 4]}
        verticalSpacing={[4, , 0]}
        sx={{
          label: {
            fontSize: 'text',
          },
        }}
      >
        {loading && <Loading />}
        {data &&
          data.map(item => (
            <Box key={item.code}>
              <Radio
                checked={values.blinkers.code === item.code}
                onChange={() => setFieldValue('blinkers', item)}
                label={item.code === 'N' ? 'Inga' : item.description}
              />
            </Box>
          ))}
      </Box>
    </Step>
  );
};

export default FormikBlinkers;

import {
  ActorResource,
  updateActor,
  UpdateActorRequest,
  UpdateActorTypeEnum,
} from '@generated/content/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from './utils/types';
import useApiMutate from './utils/useApiMutate';

type Result = ActorResource | undefined;

export interface Entities {
  actors?: { [key: string]: Result };
  url?: string;
}

type ActionParams = UpdateActorRequest['actorChangeableInformation'];

const useActorMutate = (
  type: UpdateActorTypeEnum,
  id: number,
): MutationResponse<Result, Entities, ActionParams> => {
  const key = `${type}-${id}`;

  const queryFunc = (
    actorChangeableInformation: ActionParams,
  ): QueryConfig<Entities> =>
    updateActor<Entities>(
      {
        type,
        id,
        actorChangeableInformation,
      },
      {
        transform: responseBody => ({ [key]: responseBody }),
        update: {
          actors: (prev, next): typeof next =>
            (prev && {
              ...prev,
              [key]: {
                ...prev[id],
                ...actorChangeableInformation,
              },
            }) ||
            undefined,
        },
      },
    );

  const selector = (state: State): Result => state.entities.actors?.[key];

  return useApiMutate<Result, ActionParams>({
    queryFunc,
    selector,
    basePath: 'contentapi',
    queryKey: key,
  });
};

export default useActorMutate;

import { useGetPersonalInformation } from '@apis';
import CardWithHeader from '@components/CardWithHeader';
import InfoBadge from '@components/InfoBadge';
import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import Box, { Container, Item } from '@primitives/Box';
import { Link } from '@primitives/Link';
import { CircleInfo as InfoCircle } from '@styled-icons/fa-solid';
import { CHANGE_SETTINGS_PATH } from '@utils/constants';
import hasRole from '@utils/hasRole';
import isActiveUser from '@utils/isActiveUser';
import roleEnum from '@utils/roleEnum';
import React from 'react';

import { FavoritesResult } from '../All/Favorites';
import { SavedChartResults } from '../All/SavedCharts';
import { StartMonitoringResults } from '../All/StartMonitoring';
import LicenseInfo from '../Settings/Personal/LicenseInfo';
import EntryCards from './EntryCards';
import Overview from './Overview';
import OwnerFaq from './OwnerFaq';
import RiderFaq from './RiderFaq';
import TrainerFaq from './TrainerFaq';
import UserInfo from './UserInfo';

interface Props {
  user: AuthenticatedUser;
}

function Home({ user }: Props): JSX.Element {
  const isActive = isActiveUser(user);
  const { data } = useGetPersonalInformation(user.licenseId);

  const infoBlocks = [
    data && !data.changeableUserInformation.email && (
      <InfoBadge
        color="blue"
        message={
          <>
            Du har inte angett någon e-postadress som vi kan kontakta dig på.
            Vänligen spara din e-post under{' '}
            <Link to={CHANGE_SETTINGS_PATH}>Inställningar</Link>
          </>
        }
        icon={InfoCircle}
      />
    ),
  ];

  const settingsBlock = (
    <CardWithHeader
      key="user"
      header="Har vi rätt uppgifter om dig?"
      ctaLink={{
        to: '/minasidor/installningar',
        text: 'Ändra uppgifter',
      }}
    >
      <UserInfo />
    </CardWithHeader>
  );

  const userBlocks = [
    hasRole(user, roleEnum.Licensee) && (
      <CardWithHeader
        key="licenseInfo"
        header="Mina licensuppgifter"
        ctaLink={{
          to: '/minasidor/installningar',
          text: 'Inställningar',
        }}
      >
        <LicenseInfo user={user} />
      </CardWithHeader>
    ),
    <CardWithHeader
      key="startMonitor"
      header="Startbevakningar"
      ctaLink={{
        to: '/minasidor/startbevakningar',
        text: 'Visa startbevakningar',
      }}
    >
      <StartMonitoringResults simpleList user={user} />
    </CardWithHeader>,
    <CardWithHeader
      key="favorites"
      header="Favoriter"
      ctaLink={{
        to: '/minasidor/favoriter',
        text: 'Visa favoriter',
      }}
    >
      <FavoritesResult simpleList user={user} />
    </CardWithHeader>,
    <CardWithHeader
      key="toplist"
      header="Sparade topplistor"
      ctaLink={{
        to: '/minasidor/sparade-topplistor',
        text: 'Visa sparade topplistor',
      }}
    >
      <SavedChartResults simpleList user={user} />
    </CardWithHeader>,

    hasRole(user, roleEnum.AccountHolder) && settingsBlock,
  ].filter(Boolean);

  return (
    <PageLayout
      noCard
      singleCard
      headline={`Välkommen ${user?.userInfo?.firstName}!`}
    >
      <Box px={[4, , 0]}>
        {infoBlocks.filter(Boolean).length > 0 && (
          <Box verticalSpacing={2} marginTop={2}>
            {infoBlocks.filter(Boolean).map((item, i) => (
              <Item key={i}>{item}</Item>
            ))}
          </Box>
        )}
        <Container verticalSpacing={0}>
          {isActive ? (
            <>
              <Item width={[1, , 1, 8 / 12]}>
                <Box verticalSpacing={15}>
                  <Overview />
                  <EntryCards />
                  <TrainerFaq />
                  <RiderFaq />
                  <OwnerFaq />
                </Box>
              </Item>
              <Item width={[1, , 1, 4 / 12]}>
                <Box verticalSpacing={15}>{userBlocks}</Box>
              </Item>
            </>
          ) : (
            userBlocks.map((item, i) => (
              <Item key={i} width={[1, , 1 / 2]}>
                {item}
              </Item>
            ))
          )}
        </Container>
      </Box>
    </PageLayout>
  );
}

export default withUser(Home);

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Driver,
    DriverFromJSON,
    DriverToJSON,
    LinkableHorse,
    LinkableHorseFromJSON,
    LinkableHorseToJSON,
    PropopsitionInfo,
    PropopsitionInfoFromJSON,
    PropopsitionInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface DeclaredEntry
 */
export interface DeclaredEntry  {
    /**
     * 
     * @type {LinkableHorse}
     * @memberof DeclaredEntry
     */
    horse: LinkableHorse;
    /**
     * 
     * @type {string}
     * @memberof DeclaredEntry
     */
    trackName: string;
    /**
     * 
     * @type {Date}
     * @memberof DeclaredEntry
     */
    raceDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeclaredEntry
     */
    registrationDate: Date;
    /**
     * 
     * @type {PropopsitionInfo}
     * @memberof DeclaredEntry
     */
    propopsitionInfo: PropopsitionInfo;
    /**
     * 
     * @type {string}
     * @memberof DeclaredEntry
     */
    driverChoice: DeclaredEntryDriverChoiceEnum;
    /**
     * 
     * @type {Array<Driver>}
     * @memberof DeclaredEntry
     */
    drivers: Array<Driver>;
    /**
     * 
     * @type {number}
     * @memberof DeclaredEntry
     */
    raceDayId: number;
}

export function DeclaredEntryFromJSON(json: any): DeclaredEntry {
    return {
        'horse': LinkableHorseFromJSON(json['horse']),
        'trackName': json['trackName'],
        'raceDate': parseDateTime(json['raceDate']),
        'registrationDate': parseDateTime(json['registrationDate']),
        'propopsitionInfo': PropopsitionInfoFromJSON(json['propopsitionInfo']),
        'driverChoice': json['driverChoice'],
        'drivers': (json['drivers'] as Array<any>).map(DriverFromJSON),
        'raceDayId': json['raceDayId'],
    };
}

export function DeclaredEntryToJSON(value?: DeclaredEntry): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horse': LinkableHorseToJSON(value.horse),
        'trackName': value.trackName,
        'raceDate': value.raceDate.toISOString(),
        'registrationDate': value.registrationDate.toISOString(),
        'propopsitionInfo': PropopsitionInfoToJSON(value.propopsitionInfo),
        'driverChoice': value.driverChoice,
        'drivers': (value.drivers as Array<any>).map(DriverToJSON),
        'raceDayId': value.raceDayId,
    };
}

/**
* @export
* @enum {string}
*/
export enum DeclaredEntryDriverChoiceEnum {
    SPECIFIED_DRIVER = 'SPECIFIED_DRIVER',
    SPECIFY_LATER = 'SPECIFY_LATER',
    FOREIGN_DRIVER = 'FOREIGN_DRIVER'
}



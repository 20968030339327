// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Owner,
    OwnerFromJSON,
    OwnerToJSON,
    Trainer,
    TrainerFromJSON,
    TrainerToJSON,
    Vaccination,
    VaccinationFromJSON,
    VaccinationToJSON,
} from './';

/**
 * 
 * @export
 * @interface HorseHistory
 */
export interface HorseHistory  {
    /**
     * The horse id.
     * @type {number}
     * @memberof HorseHistory
     */
    horseId: number;
    /**
     * All horse vaccinations. Only for owner/trainers own horse.
     * @type {Array<Vaccination>}
     * @memberof HorseHistory
     */
    vaccinations?: Array<Vaccination>;
    /**
     * All horse owners.
     * @type {Array<Owner>}
     * @memberof HorseHistory
     */
    owners: Array<Owner>;
    /**
     * All horse trainers.
     * @type {Array<Trainer>}
     * @memberof HorseHistory
     */
    trainers: Array<Trainer>;
}

export function HorseHistoryFromJSON(json: any): HorseHistory {
    return {
        'horseId': json['horseId'],
        'vaccinations': !exists(json, 'vaccinations') ? undefined : (json['vaccinations'] as Array<any>).map(VaccinationFromJSON),
        'owners': (json['owners'] as Array<any>).map(OwnerFromJSON),
        'trainers': (json['trainers'] as Array<any>).map(TrainerFromJSON),
    };
}

export function HorseHistoryToJSON(value?: HorseHistory): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'vaccinations': value.vaccinations === undefined ? undefined : (value.vaccinations as Array<any>).map(VaccinationToJSON),
        'owners': (value.owners as Array<any>).map(OwnerToJSON),
        'trainers': (value.trainers as Array<any>).map(TrainerToJSON),
    };
}



// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Track
 */
export interface Track  {
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    name: string;
}

export function TrackFromJSON(json: any): Track {
    return {
        'code': json['code'],
        'name': json['name'],
    };
}

export function TrackToJSON(value?: Track): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'code': value.code,
        'name': value.name,
    };
}



import config from '@config';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

interface Props {
  title?: string;
  description?: string;
  image?: string;
}

export default function SeoTags({
  title,
  description,
  image,
}: Props): JSX.Element {
  const { pathname } = useLocation();

  // Strip description of HTML tags and only use the first paragraph
  const strippedDescription = description
    ? description
        .replace(/<p[^>]+>/, ' ')
        .split(/<p[^>]+>/)[0]
        .replace(/<[^>]+>/g, '')
        .trim()
    : undefined;

  return (
    <Helmet>
      {title && <title>{title} - Svensk Galopp</title>}
      {title && (
        <meta property="og:title" content={`${title} - Svensk Galopp`} />
      )}

      {strippedDescription && (
        <meta name="description" content={strippedDescription} />
      )}
      {strippedDescription && (
        <meta property="og:description" content={strippedDescription} />
      )}

      <meta property="og:url" content={config.baseUrl + pathname} />

      {image && <meta property="og:image" content={image} />}
    </Helmet>
  );
}

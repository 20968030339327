// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Horse,
    HorseFromJSON,
    HorseToJSON,
    HorseSearchReference,
    HorseSearchReferenceFromJSON,
    HorseSearchReferenceToJSON,
    Selection,
    SelectionFromJSON,
    SelectionToJSON,
} from '../models';

export interface GetRaceHorsesRequest {
    query?: string;
    limit?: number;
}

export interface SearchHorsesRequest {
    breed: SearchHorsesBreedEnum;
    gender: SearchHorsesGenderEnum;
    age?: number;
    chipNumber?: string;
    horseName?: string;
    registrationNumber?: string;
}


/**
 * Get race horses matching the query or all if no query is specified. Limit number of results by using the limit parameter, otherwise default value applies.
 */
function getRaceHorsesRaw<T>(requestParameters: GetRaceHorsesRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Horse>> = {}): QueryConfig<T> {
    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.query !== undefined) {
        queryParameters['query'] = requestParameters.query;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(HorseFromJSON), text);
    }

    return config;
}

/**
* Get race horses matching the query or all if no query is specified. Limit number of results by using the limit parameter, otherwise default value applies.
*/
export function getRaceHorses<T>(requestParameters: GetRaceHorsesRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Horse>>): QueryConfig<T> {
    return getRaceHorsesRaw(requestParameters, requestConfig);
}

/**
 * Get available search selections and their corresponding options.
 */
function getSearchSelectionsRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Array<Selection>> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/search/selections`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(SelectionFromJSON), text);
    }

    return config;
}

/**
* Get available search selections and their corresponding options.
*/
export function getSearchSelections<T>( requestConfig?: runtime.TypedQueryConfig<T, Array<Selection>>): QueryConfig<T> {
    return getSearchSelectionsRaw( requestConfig);
}

/**
 * Searches for horses matching the given search criteria.
 */
function searchHorsesRaw<T>(requestParameters: SearchHorsesRequest, requestConfig: runtime.TypedQueryConfig<T, Array<HorseSearchReference>> = {}): QueryConfig<T> {
    if (requestParameters.breed === null || requestParameters.breed === undefined) {
        throw new runtime.RequiredError('breed','Required parameter requestParameters.breed was null or undefined when calling searchHorses.');
    }

    if (requestParameters.gender === null || requestParameters.gender === undefined) {
        throw new runtime.RequiredError('gender','Required parameter requestParameters.gender was null or undefined when calling searchHorses.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.age !== undefined) {
        queryParameters['age'] = requestParameters.age;
    }


    if (requestParameters.breed !== undefined) {
        queryParameters['breed'] = requestParameters.breed;
    }


    if (requestParameters.chipNumber !== undefined) {
        queryParameters['chipNumber'] = requestParameters.chipNumber;
    }


    if (requestParameters.gender !== undefined) {
        queryParameters['gender'] = requestParameters.gender;
    }


    if (requestParameters.horseName !== undefined) {
        queryParameters['horseName'] = requestParameters.horseName;
    }


    if (requestParameters.registrationNumber !== undefined) {
        queryParameters['registrationNumber'] = requestParameters.registrationNumber;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/search`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(HorseSearchReferenceFromJSON), text);
    }

    return config;
}

/**
* Searches for horses matching the given search criteria.
*/
export function searchHorses<T>(requestParameters: SearchHorsesRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<HorseSearchReference>>): QueryConfig<T> {
    return searchHorsesRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum SearchHorsesBreedEnum {
    ALL = 'ALL',
    OTHER_THOROUGHBRED = 'OTHER_THOROUGHBRED',
    THOROUGHBRED = 'THOROUGHBRED',
    ARABIC_THOROUGHBRED = 'ARABIC_THOROUGHBRED',
    OTHER_BREED = 'OTHER_BREED'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchHorsesGenderEnum {
    BOTH = 'BOTH',
    MARE = 'MARE',
    STALLION_GELDING = 'STALLION_GELDING'
}

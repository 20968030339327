import {
  getOwnershipInvoiceNotifications,
  OwnershipsInvoiceNotifications,
} from '@generated/sportactors/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = OwnershipsInvoiceNotifications | undefined;

export interface Entities {
  ownerInvoiceNotification?: {
    [id: number]: Result;
  };
}

const getQuery = (id: number): QueryConfig<Entities> =>
  getOwnershipInvoiceNotifications<Entities>(
    {
      ownerId: id,
    },
    {
      transform: responseBody => ({
        ownerInvoiceNotification: { [id]: responseBody },
      }),
      update: {
        ownerInvoiceNotification: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetOwnershipsInvoiceNotifications = (
  id: number,
): ApiResponse<Result, Entities> => {
  const query = getQuery(id);
  const selector = (state: State): Result =>
    state.entities?.ownerInvoiceNotification?.[id];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetOwnershipsInvoiceNotifications;

import {
  getNegativeDopingResults,
  GetNegativeDopingResultsRequest,
  NegativeDopingResult,
} from '@generated/raceinfo/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  negativeDopingResults?: { [key: string]: NegativeDopingResult };
}

const getQuery = (
  resultKey: string,
  request: GetNegativeDopingResultsRequest,
): QueryConfig<Entities> =>
  getNegativeDopingResults<Entities>(request, {
    transform: responseBody => ({
      negativeDopingResults: {
        [resultKey]: responseBody,
      },
    }),
    update: {
      negativeDopingResults: (prev, next) => ({
        ...prev,
        ...next,
      }),
    },
  });

const useGetNegativeDopingResults = (
  request: GetNegativeDopingResultsRequest,
): ApiResponse<NegativeDopingResult | undefined, Entities> => {
  const resultKey = `negativeDopingResults-${request.raceDayId}`;

  const query = getQuery(resultKey, request);

  const selector = (state: State): NegativeDopingResult | undefined =>
    state.entities['negativeDopingResults'] &&
    state.entities['negativeDopingResults'][resultKey];

  return useApi<NegativeDopingResult | undefined>({
    query,
    selector,
  });
};

export default useGetNegativeDopingResults;

import theme from '@main/theme';
import React, { ReactNode } from 'react';
import styled, { css, CSSProp } from 'styled-components';
import { typography, TypographyProps, variant } from 'styled-system';

import { base, BaseProps } from '../base';

interface Props extends BaseProps {
  itemGap?: number;
  variant?: 'vertical' | 'horizontal';
  markerType?: 'circle';
  markerColor?: 'deep-blue' | 'green' | 'red' | 'dim-gray';
}

const markerType = (): ReturnType<typeof variant> =>
  variant({
    prop: 'markerType',
    variants: {
      circle: {
        'li::before': {
          content: '"\\2022"',
          display: 'inline-block',
          width: '1em',
          marginLeft: '-1em',
        },
        li: {
          marginLeft: '1em',
        },
      },
    },
  });

const markerColor = (): ReturnType<typeof variant> =>
  variant({
    prop: 'markerColor',
    variants: {
      green: {
        'li::before': {
          color: 'green',
        },
      },
      'deep-blue': {
        'li::before': {
          color: 'deep-blue',
        },
      },
      red: {
        'li::before': {
          color: 'red',
        },
      },
      'dim-gray': {
        'li::before': {
          color: 'dim-gray',
        },
      },
    },
  });

const itemGap = (props: Props): CSSProp => ({
  '> li + li': {
    [props.variant === 'horizontal' ? 'ml' : 'mt']: props.itemGap,
  },
});

const List = styled.ul.attrs((props: Props & TypographyProps) => ({
  sx: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    ...(itemGap(props) as object),
    ...props.sx,
  },
}))<Props>`
  ${base(typography)}
  ${markerType}
  ${markerColor}
  ${variant({
    variants: {
      horizontal: {
        display: 'flex',
        alignItems: 'center',
        li: {
          display: 'flex',
        },
      },
    },
  })}
`;

List.defaultProps = {
  variant: 'vertical',
  itemGap: 0,
};

export const LI = styled.li<
  {
    markerColor?: 'green' | 'red' | 'dim-gray' | 'deep-blue';
  } & BaseProps &
    TypographyProps
>`
  ${({ markerColor }) =>
    markerColor &&
    css`
      &::before {
        color: ${theme.colors[markerColor]};
      }
    `}

  ${base(typography)}
`;

export default List;

type ListParameters = Parameters<typeof List>[0];

interface SimpleListProps {
  items: ReactNode[];
}

export const SimpleList = ({
  items,
  ...props
}: SimpleListProps & ListParameters): JSX.Element => (
  <List markerType="circle" itemGap={1} {...props}>
    {items.map((item, i) => (
      <LI key={i}>{item}</LI>
    ))}
  </List>
);

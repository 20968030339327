import { useIssueTemplate } from '@apis';
import InfoBadge from '@components/InfoBadge';
import { AuthenticatedUser, Role } from '@generated/account/src';
import { IssueTemplate } from '@generated/content/src';
import useAuthenticationMethod from '@hooks/useAuthenticationMethod';
import useIsMobile from '@hooks/useIsMobile';
import { AUTH_SWITCH_CONSTANT } from '@main/auth';
import theme from '@main/theme';
import Box, { FadedBox, Flex } from '@primitives/Box';
import { ButtonLink } from '@primitives/Button';
import { Link } from '@primitives/Link';
import { Text } from '@primitives/Typography';
import { CircleInfo as InfoCircle } from '@styled-icons/fa-solid';
import isLoggedIn from '@utils/isLoggedIn';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { templateData } from './New/definitions/_templateData';
import { IssueFormBuilder } from './New/formBuilder';
import { WithHorses } from './New/withHorses';

interface Props {
  user: AuthenticatedUser;
  template: (typeof templateData)[number];
}

const Header = ({
  issueTemplate,
  children,
}: Props & {
  issueTemplate: IssueTemplate;
  children: React.ReactNode;
}): JSX.Element => {
  const isMobile = useIsMobile();
  const [textOpen, setTextOpen] = useState(false);

  const toggleLimit = isMobile ? 60 : 80;

  return (
    <Box
      pt={0}
      px={2}
      display="table"
      mx={0}
      borderWidth={0}
      borderColor="#F6F4F1"
      borderStyle={'solid'}
      data-no-default
      flexGrow={1}
    >
      <FadedBox
        maxHeight={textOpen ? 'none' : `${toggleLimit}px`}
        overflowY="hidden"
        ml={0}
        pb={0}
        fadedBottom={!textOpen ? 50 : 0}
        fadedBg={theme.colors['white']}
        onClick={() => !textOpen && setTextOpen(!textOpen)}
      >
        <Text
          dangerouslySetInnerHTML={{
            __html: issueTemplate.description.replace(
              'a href=',
              `a target='_blank' href=`,
            ),
          }}
        />
      </FadedBox>
      <ButtonLink
        onClick={() => setTextOpen(!textOpen)}
        color="gray5"
        lineHeight="18px"
        fontSize="small"
        mt={textOpen ? 2 : 1}
        mb={2}
        sx={{
          borderBottom: 'solid 1px',
          textTransform: 'uppercase',
        }}
      >
        {textOpen ? 'Läs mindre' : 'Läs mer'}
      </ButtonLink>
      {children}
    </Box>
  );
};

export default function NewIssue({
  user,
  template,
}: Props): JSX.Element | null {
  const authMethod = useAuthenticationMethod();

  const history = useHistory();
  const {
    data: issueTemplate,
    loading: loadingIssueTemplate,
    error: errorIssueTemplate,
  } = useIssueTemplate(template?.id ?? 0);

  const { pathname, search } = history.location;

  const doLogout = useCallback(async () => {
    localStorage.setItem('loginMethodPassword', 'false');

    history.push(
      `/login?reason=${AUTH_SWITCH_CONSTANT}&dest=${encodeURIComponent(
        pathname + search,
      )}`,
    );
  }, [history, pathname, search]);

  /* No issue template, redirect to /tjanster */
  if (errorIssueTemplate) {
    location.href = '/tjanster';
    return null;
  }

  if (!issueTemplate || loadingIssueTemplate) {
    return null;
  }

  /* No user id, we should probably display something? */
  if (!isLoggedIn(user) && issueTemplate.loginType === 'password') {
    return (
      <>
        <Header user={user} template={template} issueTemplate={issueTemplate}>
          <br />

          <InfoBadge
            icon={InfoCircle}
            color={'blue'}
            message={
              <>
                Du måste vara inloggad för att använda den här tjänsten.
                <br />
                <br />
                <Link
                  to={`/login?dest=/tjanster/nytt?templateId=${template?.id}`}
                >
                  Klicka här för att logga in.
                </Link>
              </>
            }
          />
          <Flex py={4}>
            Har du inget konto?{' '}
            <Link to="/skapa-konto" ml={2}>
              Skapa konto
            </Link>
          </Flex>
        </Header>
      </>
    );
  } else if (!isLoggedIn(user) && issueTemplate.loginType === 'bankid') {
    return (
      <>
        <Header user={user} template={template} issueTemplate={issueTemplate}>
          <br />

          <InfoBadge
            icon={InfoCircle}
            color={'blue'}
            message={
              <>
                Du måste vara inloggad med BankID för att använda den här
                tjänsten.
                <br />
                <br />
                <ButtonLink onClick={doLogout}>
                  Klicka här för att logga in
                </ButtonLink>
              </>
            }
          />
          <Flex py={4}>
            Har du inget konto?{' '}
            <Link to="/skapa-konto" ml={2}>
              Skapa konto
            </Link>
          </Flex>
        </Header>
      </>
    );
  }

  // Check for matching roles if specified.
  if (
    template.roles &&
    !template.roles.find(role =>
      user.roles.find((userRole: Role) => userRole.role === role.role),
    )
  ) {
    return (
      <Header user={user} template={template} issueTemplate={issueTemplate}>
        <InfoBadge
          icon={InfoCircle}
          color={'blue'}
          message={<>Du är inte behörig att använda den här tjänsten.</>}
        />
      </Header>
    );
  }

  if (
    isLoggedIn(user) &&
    issueTemplate.loginType === 'bankid' &&
    authMethod !== 'BANKID'
  ) {
    return (
      <Header user={user} template={template} issueTemplate={issueTemplate}>
        <br />
        <Box verticalSpacing={4} pb={6}>
          <InfoBadge
            icon={InfoCircle}
            color="blue"
            message={`Du måste vara inloggad med BankId för att använda den här
            tjänsten.`}
          />
          <ButtonLink onClick={doLogout}>Logga in med BankID</ButtonLink>
        </Box>
      </Header>
    );
  }

  return isLoggedIn(user) ? (
    <WithHorses user={user}>
      <IssueFormBuilder
        user={user}
        template={template}
        issueTemplate={issueTemplate}
      />
    </WithHorses>
  ) : (
    <IssueFormBuilder
      user={user}
      template={template}
      issueTemplate={issueTemplate}
    />
  );
}

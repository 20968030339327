import { EmptyState } from '@components/InfoBlock';
import Pagination from '@components/Pagination';
import SortIcon from '@components/SortIcon';
import useIsMobile from '@hooks/useIsMobile';
import theme, { Theme } from '@main/theme';
import Alignment from '@primitives/Alignment';
import { base } from '@primitives/base';
import Box, { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import Icon from '@primitives/Icon';
import List from '@primitives/List';
import { TR } from '@primitives/Table';
import { Text } from '@primitives/Typography';
import { Minus, Plus } from '@styled-icons/fa-solid';
import clsx from 'clsx';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Column,
  Row,
  useExpanded,
  UseExpandedState,
  usePagination,
  useSortBy,
  UseSortByOptions,
  useTable,
  UseTableOptions,
} from 'react-table';
import styled from 'styled-components';
import { StyledIcon } from 'styled-icons/types';
import { typography, TypographyProps } from 'styled-system';

interface CursorInterface {
  cursor: string;
}

// Overflow visible can be used to not cut off multiselect options when table ends.
// Overflow auto causes a cut off with multiselect options when table ends.
// Overflow visible cause troubles with scrolling in mobile.
interface OverflowInterface {
  overflow?: 'auto' | 'visible';
}

export const OverFlow = styled.div<OverflowInterface>`
  max-width: 100%;
  width: 100%;
  overflow: ${props => (props.overflow ? props.overflow : 'auto')};
`;

const ShowMoreText = styled.p`
  text-align: center;
  width: 100%;
`;

export const TH = styled.th<Alignment & CursorInterface>`
  cursor: ${props => props.cursor};
  text-align: ${props => (props.alignment ? props.alignment : 'left')};
`;

export const TD = styled.td<Alignment & TypographyProps>`
  text-align: ${props => (props.alignment ? props.alignment : 'left')};
  ${base(typography)}
`;

interface ToggleIconProps {
  isExpanded: boolean;
  toggleFunc: () => void;
  isHeader?: boolean;
  isMobile?: boolean;
}

const ToggleIcon = ({
  isExpanded,
  toggleFunc,
  isMobile,
  isHeader = false,
}: ToggleIconProps): JSX.Element => (
  <Box
    onClick={event => {
      event.stopPropagation();
      toggleFunc();
    }}
    mr={2}
    sx={{
      cursor: 'pointer',
      textAlign: 'center',
    }}
    display="inline-block"
  >
    <Icon
      as={isExpanded ? Minus : Plus}
      color={isHeader ? '#404040' : 'blue'}
      size={isMobile ? 14 : 12}
      mb="2px"
    />
  </Box>
);

export interface NoContent {
  header?: string;
  icon?: StyledIcon;
  content?: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface TableProps<T extends object> {
  data: T[];
  columns: Column<T>[];
  sortable?: boolean;
  stickyOnMobile?: boolean;
  subRowWide?: boolean;
  unifiedSubRow?: boolean;
  subRowDoubleVerticalLine?: boolean;
  pagination?: boolean;
  twoStickyColumns?: boolean;
  openSubRows?: Row<T>[];
  showMore?: boolean;
  pageSize?: number;
  variant?: 'datagrid' | 'table';
  overflow?: 'auto' | 'visible';
  title?: ReactNode;
  defaultSort?: { id: string; desc: boolean }[];
  striped?: boolean;
  renderListItem?: (props: { row: Row<T> }) => JSX.Element;
  renderRowSubComponent?: (props: { row: Row<T> }) => JSX.Element;
  renderRowSubComponentOnClick?: (props: { row: Row<T> }) => JSX.Element;
  subRow?: (props: { row: Row<T> }) => JSX.Element;
  tfoot?: ReactNode;
  updatePageProps?: (pageCount: number, rows: number) => Promise<void>;
  expandableBackground?: keyof Theme['colors'];
  orderByFn?: UseSortByOptions<T>['orderByFn'];
  defaultExpanded?: UseExpandedState<T>['expanded'];
  allExpanded?: boolean;
  getRowId?: UseTableOptions<T>['getRowId'];
  noContent?: NoContent;
  emptyState?: boolean;
  emptyRow?: boolean;
  condensed?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function ReactTable<T extends Object>({
  data,
  columns,
  overflow,
  stickyOnMobile = true,
  unifiedSubRow = false,
  showMore = false,
  pagination,
  pageSize,
  twoStickyColumns = false,
  sortable,
  openSubRows,
  striped,
  title,
  variant = 'datagrid',
  defaultSort = [
    {
      id: columns[0]?.id || columns[0]?.accessor?.toString(),
      desc: false,
    },
  ],
  renderListItem,
  renderRowSubComponent,
  renderRowSubComponentOnClick,
  subRow,
  subRowWide,
  subRowDoubleVerticalLine,
  tfoot,
  updatePageProps,
  expandableBackground,
  orderByFn,
  getRowId,
  allExpanded,
  defaultExpanded = {} as UseExpandedState<T>['expanded'],
  noContent,
  emptyState = false,
  emptyRow = false,
}: TableProps<T>): JSX.Element {
  const isMobile = useIsMobile();

  const memorizedColumns = React.useMemo(
    () => columns.filter(col => !(isMobile && col?.hideMobile)),
    [columns, isMobile],
  );

  const memorizedData = React.useMemo(() => data, [data]);

  const initialState = useMemo(
    () => ({
      pageIndex: 0,
      pageSize: pageSize || 30,
      ...(sortable && { sortBy: defaultSort }),
      expanded: defaultExpanded,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageSize, sortable],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    nextPage,
    previousPage,
    prepareRow,
    //pagination
    page,
    canNextPage,
    canPreviousPage,
    setPageSize,
    gotoPage,
    pageCount,
    visibleColumns,
    state: { pageIndex, expanded },
    toggleAllRowsExpanded,
  } = useTable<T>(
    {
      columns: memorizedColumns,
      data: memorizedData,
      initialState,
      disableSortRemove: true,
      disableSortBy: !sortable,
      expandSubRows: false,
      orderByFn,
      getRowId,
    },

    useSortBy,
    useExpanded,
    usePagination,
  );

  const [count, setCount] = useState(1);

  const numOfRows = count > 1 ? rows.length : 30;
  const arrEnd = showMore ? count * numOfRows : rows.length;
  const showMoreButton: boolean =
    rows.length >= count * numOfRows ? true : false;

  const tableRefContainer = useRef(null);

  /**
   * Handle mount/resize and set if the table is scrollable
   * ******************************************************
   */
  const [hasScroll, setHasScroll] = useState(false);

  const updateScrollSettings = useCallback(() => {
    const hasScroll =
      tableRefContainer?.current?.scrollWidth >
      tableRefContainer?.current?.clientWidth;

    setHasScroll(hasScroll);
  }, []);

  useEffect(() => {
    if (tableRefContainer?.current?.scrollWidth) {
      updateScrollSettings();
    }

    window.addEventListener('resize', updateScrollSettings);

    return () => window.removeEventListener('resize', updateScrollSettings);
  }, [updateScrollSettings]);

  // Only run this once
  useEffect(() => {
    if (allExpanded) {
      toggleAllRowsExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handle scroll and set if the table can be scrolled in any direction
   * ******************************************************
   */
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);

  const updateScrollPositionSettings = useCallback(() => {
    const ref = tableRefContainer?.current;
    if (ref) {
      const canScrollLeft = ref.scrollLeft > 0;
      const canScrollRight = ref.scrollLeft + ref.clientWidth < ref.scrollWidth;

      setCanScrollRight(canScrollRight);
      setCanScrollLeft(canScrollLeft);
    }
  }, []);

  useEffect(() => {
    const ref = tableRefContainer?.current;
    if (ref?.scrollWidth) {
      updateScrollPositionSettings();

      ref.addEventListener('scroll', updateScrollPositionSettings);
    }

    return () =>
      ref?.removeEventListener('scroll', updateScrollPositionSettings);
  }, [updateScrollPositionSettings]);

  /**
   * Pagination
   */
  const updateRows = useCallback((): void => {
    setCount(prev => prev + 1);
  }, []);

  useEffect(() => {
    if (updatePageProps != null) {
      updatePageProps(page.length, rows.length);
    }
  }, [updatePageProps, page.length, rows.length]);

  if ((emptyState || noContent) && data.length === 0 && !emptyRow) {
    return <EmptyState {...(noContent || {})} />;
  }

  const isExpandable = renderRowSubComponent != null;
  const isExpanded = expanded && Object.keys(expanded).length > 0;

  const isSticky = stickyOnMobile && hasScroll;

  const finalRows = pagination ? page : rows.slice(0, arrEnd);
  const renderMobileAsList = isMobile && renderListItem;

  let rowCounter = 0;
  return (
    <>
      {title && (
        <Text color="dim-gray" mb={2} fontSize="dataGridHeader">
          {title}
        </Text>
      )}
      {renderMobileAsList && (
        <List itemGap={4}>
          {finalRows.map((row, index) => (
            <li key={index}>{renderListItem({ row })}</li>
          ))}
        </List>
      )}
      {!renderMobileAsList && (
        <Box position="relative" className="table-wrapper">
          <OverFlow
            overflow={overflow}
            className={clsx({
              'sticky-mobile-table': isSticky,
              striped: striped,
              'can-scroll-left': canScrollLeft,
              'can-scroll-right': canScrollRight,
            })}
            ref={tableRefContainer}
          >
            <table
              {...getTableProps()}
              className={clsx({
                [`${variant}-variant`]: true,
                'unified-sub-row': unifiedSubRow,
                'two-sticky-columns': twoStickyColumns,
              })}
            >
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                    {headerGroup.headers.map((column, ci) => {
                      const thStyle = column?.thStyle;

                      return (
                        // Add the sorting props to controlN sorting.For this example
                        // we can add them into the header props
                        <TH
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}
                          cursor={sortable ? 'pointer' : 'auto'}
                          key={`${ci}th`}
                          title={''}
                          alignment={column.alignment}
                          style={thStyle ? thStyle() : undefined}
                        >
                          {ci === 0 && isExpandable && (
                            <ToggleIcon
                              isHeader
                              isMobile={isMobile}
                              isExpanded={isExpanded}
                              toggleFunc={() =>
                                toggleAllRowsExpanded(!isExpanded)
                              }
                            />
                          )}
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          {sortable && column.canSort && (
                            <SortIcon
                              isSorted={column.isSorted}
                              isSortedDesc={column.isSortedDesc || false}
                            />
                          )}
                        </TH>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {emptyRow && data.length === 0 && (
                  <TR className="row">
                    <td colSpan={columns.length}>
                      {noContent?.content ||
                        'Just nu finns ingen information att visa'}
                    </td>
                  </TR>
                )}
                {finalRows.map((row, index) => {
                  prepareRow(row);

                  const rowProps = row.getRowProps();
                  if (row.isExpanded) {
                    rowProps.className = 'expanded';
                  }
                  const rowIsExpandedTable =
                    row.isExpanded && variant === 'table';

                  const rowStyle =
                    (striped && variant === 'datagrid' && index % 2) ||
                    (striped && variant === 'table' && (index % 2) + 1 === 1) ||
                    rowIsExpandedTable
                      ? {
                          backgroundColor: rowIsExpandedTable
                            ? (expandableBackground &&
                                theme.colors[expandableBackground]) ||
                              theme.colors['very-light-blue']
                            : theme.colors.snow,
                        }
                      : undefined;

                  const rowStyleDoubleLine = {
                    borderLeft: theme.colors['deep-blue'],
                    maxWidth: '150px !important',
                  };

                  // We use TR sx attribute to get hover color from global css working
                  rowCounter++;
                  return (
                    <React.Fragment key={rowProps.key}>
                      <TR
                        sx={rowStyle}
                        className={`row ${
                          rowCounter % 2 ? 'rowOdd' : 'rowEven'
                        }`}
                      >
                        {row.cells.map((cell, i) => {
                          const tdStyle = cell.column?.tdStyle;
                          const tdStyleFinal = tdStyle ? tdStyle(cell.row) : {};

                          if (rowIsExpandedTable) {
                            tdStyleFinal.borderBottomWidth = '0';
                          }

                          return (
                            <TD
                              {...cell.getCellProps()}
                              key={`${rowProps.key}-${i}`}
                              alignment={cell.column.alignment}
                              style={tdStyleFinal}
                              className={clsx({
                                doubleVertLine:
                                  subRowDoubleVerticalLine &&
                                  row.isExpanded &&
                                  i === 0,
                                parentRow:
                                  row.isExpanded && variant === 'table',
                              })}
                            >
                              {i === 0 && isExpandable && (
                                <ToggleIcon
                                  isMobile={isMobile}
                                  isExpanded={row.isExpanded}
                                  toggleFunc={row.toggleRowExpanded}
                                />
                              )}

                              {cell.render('Cell')}
                              {unifiedSubRow && row.isExpanded && i === 0
                                ? renderRowSubComponent?.({ row })
                                : null}
                            </TD>
                          );
                        })}
                      </TR>
                      {row.isExpanded && !unifiedSubRow ? (
                        <TR
                          sx={
                            subRowDoubleVerticalLine
                              ? rowStyleDoubleLine
                              : rowStyle
                          }
                          className="subRow"
                        >
                          {
                            // Add one extra column in mobile to avoid shadow problems
                            isSticky && !subRowDoubleVerticalLine && (
                              <td className="expanded-table-cell"></td>
                            )
                          }
                          <TD
                            colSpan={
                              isSticky
                                ? visibleColumns.length - 1
                                : visibleColumns.length
                            }
                            className={
                              !isSticky ? 'expanded-table-cell' : undefined
                            }
                            style={
                              subRowDoubleVerticalLine
                                ? {
                                    borderLeft: `3px solid ${theme.colors['deep-blue']}`,
                                  }
                                : undefined
                            }
                          >
                            {renderRowSubComponent?.({ row })}
                          </TD>
                        </TR>
                      ) : null}
                      {openSubRows &&
                        openSubRows.some(elem => elem.id === row.id) && (
                          <TR sx={rowStyle} className="subRow">
                            {
                              // Add one extra column in mobile to avoid shadow problems
                              isSticky && (
                                <td className="expanded-table-cell"></td>
                              )
                            }
                            <TD
                              colSpan={
                                isSticky
                                  ? visibleColumns.length - 1
                                  : visibleColumns.length
                              }
                              className={
                                !isSticky ? 'expanded-table-cell' : undefined
                              }
                            >
                              {renderRowSubComponentOnClick?.({ row })}
                            </TD>
                          </TR>
                        )}
                      {subRow?.({ row }) != null && (
                        <TR
                          sx={
                            subRowDoubleVerticalLine
                              ? rowStyleDoubleLine
                              : rowStyle
                          }
                          className={`subRow ${
                            rowCounter % 2 ? 'rowOdd' : 'rowEven'
                          }`}
                        >
                          {!subRowWide ? <TD /> : null}

                          <TD
                            colSpan={visibleColumns.length}
                            style={
                              !subRowWide
                                ? {
                                    borderLeft: `3px solid ${theme.colors['deep-blue']}`,
                                  }
                                : null
                            }
                          >
                            {subRow?.({ row })}
                          </TD>
                        </TR>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
              {tfoot && <tfoot>{tfoot}</tfoot>}
            </table>
          </OverFlow>
        </Box>
      )}
      {pagination && (
        <Pagination
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          pageSize={page.length}
          rowsSize={rows.length}
          pageCount={pageCount}
          gotoPage={gotoPage}
          pageIndex={pageIndex}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
        />
      )}
      {showMore && showMoreButton && (
        <Box>
          <Flex p={4}>
            <Button fontSize="small" onClick={updateRows}>
              Visa alla
            </Button>
          </Flex>
          <ShowMoreText>
            Visar {arrEnd} av {rows.length}
          </ShowMoreText>
        </Box>
      )}
    </>
  );
}

export default ReactTable;

import Card from '@components/Card';
import { WrapperWithToggle } from '@components/CollapsableWrapper';
import InfoTable from '@components/InfoTable';
import { UpcomingStakeRace } from '@generated/propositions/src';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import Icon from '@primitives/Icon';
import { Link } from '@primitives/Link';
import { RightToBracket as SignInAlt } from '@styled-icons/fa-solid';
import { formatTz } from '@utils/dateUtils';
import React, { useState } from 'react';
import { generatePath, useLocation, useRouteMatch } from 'react-router-dom';

import Infos from '../Infos';

interface Props {
  row: UpcomingStakeRace;
  accountPage: boolean;
}

export default function ListItem({ row, accountPage }: Props): JSX.Element {
  const [showInfo, setShowInfo] = useState(false);
  const { path } = useRouteMatch();
  const { search } = useLocation();

  const {
    track,
    raceName,
    ageText,
    distance,
    surface,
    genderText,
    firstStakeDate,
    raceDate,

    propositionId,
  } = row;

  return (
    <Card p={1} pb={0}>
      <WrapperWithToggle
        open={showInfo}
        onToggle={open => setShowInfo(open)}
        headline={raceName}
      >
        <InfoTable
          forceColumns={3}
          values={[
            {
              label: 'Bana',
              value: track.name,
              wrapHyphen: true,
            },
            {
              label: 'Ålder',
              value: ageText,
              wrapHyphen: true,
            },
            {
              label: 'Distans',
              value: `${distance} ${surface}`,
            },
            ...((showInfo && [
              {
                label: 'Kön',
                value: genderText,
              },
              {
                label: 'Tävlingsdag',
                value: formatTz(raceDate, 'yyyy-MM-dd'),
              },
              {
                label: 'Anmälningsdag första insats',
                value: formatTz(firstStakeDate, 'yyyy-MM-dd'),
              },
            ]) ||
              []),
          ]}
        />
        {showInfo && <Infos propositionId={propositionId} />}
      </WrapperWithToggle>
      {accountPage && (
        <Box mt={2} borderTop="solid 1px #E5DFD6" p={1} mx={-1}>
          <Box m={1}>
            <Button
              as={Link}
              to={
                generatePath(path, {
                  propositionId: propositionId.toString(),
                }) + search
              }
              block
              colorScheme={'saveAlt'}
            >
              Anmäl till insatslopp
              <Icon as={SignInAlt} size={12} ml={1} mb="1px" />
            </Button>
          </Box>
        </Box>
      )}
    </Card>
  );
}

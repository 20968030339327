// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface CoveringListRegistrationDeadlines
 */
export interface CoveringListRegistrationDeadlines  {
    /**
     * 
     * @type {Date}
     * @memberof CoveringListRegistrationDeadlines
     */
    covering: Date;
    /**
     * Helper flag based on provided covering deadline date
     * @type {boolean}
     * @memberof CoveringListRegistrationDeadlines
     */
    coveringOpen: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CoveringListRegistrationDeadlines
     */
    result: Date;
    /**
     * Helper flag based on provided result deadline date
     * @type {boolean}
     * @memberof CoveringListRegistrationDeadlines
     */
    resultOpen: boolean;
}

export function CoveringListRegistrationDeadlinesFromJSON(json: any): CoveringListRegistrationDeadlines {
    return {
        'covering': parseDateTime(json['covering']),
        'coveringOpen': json['coveringOpen'],
        'result': parseDateTime(json['result']),
        'resultOpen': json['resultOpen'],
    };
}

export function CoveringListRegistrationDeadlinesToJSON(value?: CoveringListRegistrationDeadlines): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'covering': value.covering.toLocalDate(),
        'coveringOpen': value.coveringOpen,
        'result': value.result.toLocalDate(),
        'resultOpen': value.resultOpen,
    };
}



import { Transaction } from '@generated/tds/src';
import Box from '@primitives/Box';
import Refresh from '@utils/Refresh';
import React from 'react';

import FilterValueEnum from '../utils/enum/FilterValue';
import Details from './Details';
import Total from './Total';

type BoxParameters = Parameters<typeof Box>;

type Props = {
  groupBy: FilterValueEnum;
  licenseId: number;
  refresh: Refresh;
  sort: FilterValueEnum;
  transactions: Transaction[];
  viewMode: FilterValueEnum;
} & BoxParameters[0];

export default function List({
  groupBy,
  licenseId,
  refresh,
  sort,
  transactions,
  viewMode,
  ...props
}: Props): JSX.Element {
  return (
    <Box {...props}>
      {viewMode === FilterValueEnum.Details && (
        <Details
          groupBy={groupBy}
          licenseId={licenseId}
          refresh={refresh}
          sort={sort}
          transactions={transactions}
        />
      )}
      {viewMode === FilterValueEnum.Total && (
        <Total groupBy={groupBy} transactions={transactions} />
      )}
    </Box>
  );
}

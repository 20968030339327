import { Input } from '@primitives/Form';
import { validateInputDate } from '@utils/dateUtils';
import { format } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';

import Error from './Error';

interface Props {
  autoComplete?: string;
  disabled?: boolean;
  endDate?: Date;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  name: string;
  startDate?: Date;
  selectsStart?: boolean;
  selectsEnd?: boolean;

  isClearable?: boolean;
  showMonthYearDropdown?: boolean;
}

function DatePicker({
  autoComplete,
  disabled,
  label,
  maxDate,
  minDate,
  name,
}: Props): JSX.Element {
  const { setFieldValue } = useFormikContext();
  const [value, setValue] = useState<string>('');

  const [field, meta] = useField({
    name,
    validate: () => {
      if (!value || validateInputDate(value)) {
        return;
      }

      return 'Fel format (ÅÅÅÅ-MM-DD)';
    },
  });

  useEffect(() => {
    if (field.value && !value) {
      setValue(format(field.value, 'yyyy-MM-dd'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, value]);

  const updateHandler = useCallback(
    ev => {
      const { value } = ev.target;

      setValue(value);

      if (validateInputDate(value)) {
        const [year, month, day] = value.split('-').map(str => parseInt(str));
        setFieldValue(field.name, new Date(year, month - 1, day));
      }

      if (!value) {
        setFieldValue(field.name, '');
      }
    },
    [field.name, setFieldValue],
  );

  return (
    <>
      {label && <label>{label}</label>}
      <Input
        type="date"
        autoComplete={autoComplete}
        disabled={disabled}
        max={maxDate ? format(maxDate, 'yyyy-MM-dd') : undefined}
        min={minDate ? format(minDate, 'yyyy-MM-dd') : undefined}
        {...field}
        value={value}
        onChange={updateHandler}
        pattern="\d{4}-\d{2}-\d{2}"
        placeholder="ÅÅÅÅ-MM-DD"
      />
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </>
  );
}

export default DatePicker;

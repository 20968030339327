import {
  getAvailableHorsesForStart,
  TrainedHorse,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Results = TrainedHorse[] | undefined;

export interface Entities {
  availableHorses?: {
    [key: number]: Results;
  };
}

export const QUERY_KEY_NAMESPACE = 'availableHorsesForStart';

export default function useGetAvailableHorses(
  propositionId: number,
  trainerId: number,
): ApiResponse<Results, Entities> {
  const key = `${QUERY_KEY_NAMESPACE}-${propositionId}-${trainerId}`;
  const query = getAvailableHorsesForStart<Entities>(
    {
      propositionId,
      trainerId,
    },
    {
      queryKey: key,
      transform: responseBody => ({
        availableHorses: {
          [key]: responseBody,
        },
      }),
      update: {
        availableHorses: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Results =>
    state.entities?.availableHorses?.[key];

  return useApi<Results>({
    query,
    selector,
  });
}

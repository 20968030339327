import {
  BankIdSignRequestCertificatePoliciesEnum,
  BankIdUserSigning,
  CollectedHorseSignStatus,
  CollectedHorseSignStatusStatusEnum,
  collectHorseSigningStatusAndQrCodeBankId,
  CollectHorseSigningStatusAndQrCodeBankIdServiceTypeEnum,
  ErrorResponse,
} from '@generated/ownership-transfers/src';
import useBankIdCollect, {
  ReturnValues,
  State as BaseState,
  wrapCollectAction,
} from '@hooks/useBankIdCollect';
import useUser from '@hooks/useUser';
import { OperatorEnum, resetQuery } from '@main/state/queriesReducer';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MutateAsyncAction } from 'redux-query';
import superagent from 'superagent';

import { request } from '../request';

interface State extends BaseState {
  orderReference?: string;
  autoStartToken?: string;
  qrCodePattern?: string;
  errorMessage?: string;
  qrTime?: number;
}

interface CollectResponse extends superagent.Response {
  body: CollectedHorseSignStatus & ErrorResponse;
}

interface Entities {
  horseTransferCollectStatus: CollectedHorseSignStatus;
}

const collectAction = (
  orderReference: string,
  serviceType: CollectHorseSigningStatusAndQrCodeBankIdServiceTypeEnum,
  qrTime: number,
): MutateAsyncAction<Entities> =>
  wrapCollectAction(
    collectHorseSigningStatusAndQrCodeBankId<Entities>(
      {
        orderReference,
        serviceType,
        qrTime,
      },
      {
        transform: responseBody => ({
          horseTransferCollectStatus: responseBody,
        }),
        update: {
          horseTransferCollectStatus: (_prev, next): typeof next => next,
        },
      },
    ),
  );

interface Props {
  horseId: number;
  transferDate: string;
  ownershipId: number;
  buyerRepresentativeId: number;
  serviceType?: 'OWNERSHIP_TRANSFERS' | 'LEASING';
}

const useBankIdTranferOwnership = ({
  horseId,
  transferDate,
  ownershipId,
  buyerRepresentativeId,
  serviceType = 'OWNERSHIP_TRANSFERS',
}: Props): ReturnValues<State> => {
  const user = useUser();
  const [state, setState] = useState<State>({
    orderReference: undefined,
    qrTime: undefined,
    qrCodePattern: undefined,
  });

  const dispatch = useDispatch();

  const history = useHistory();

  /**
   * Collect callback - polling for status
   */
  const runCollect = useCallback(async (): Promise<CollectResponse> => {
    if (state.orderReference) {
      const qrTime =
        Math.round((new Date().getTime() - state.qrTime) / 1000) || 1;
      if (qrTime > 30) {
        return {
          body: {
            errorMessage: 'Tiden för att signera har gått ut.',
            status: CollectedHorseSignStatusStatusEnum.FAILED,
          },
        } as unknown as CollectResponse;
      }

      const resp = (await dispatch(
        collectAction(
          state.orderReference,
          serviceType as CollectHorseSigningStatusAndQrCodeBankIdServiceTypeEnum,
          qrTime,
        ),
      )) as unknown as CollectResponse;

      if (resp.status === 200) {
        setState(prev => ({
          ...prev,
          qrCodePattern: resp.body.qrCodePattern,
        }));
        return resp;
      }

      // Dispatch usaly doesn't return a response
      return resp;
    }
  }, [dispatch, serviceType, state.orderReference, state.qrTime]);

  /**
   * Init callback - creates bankId orderReference
   */
  const onInit = useCallback(async () => {
    const resp = await request<BankIdUserSigning & ErrorResponse>({
      url: `/ownershiptransfers/bankid/initialize/${horseId}?serviceType=${serviceType}`,
      method: 'POST',
      body: {
        horseSignInitializeRequest: {
          transferDate: transferDate,
          ownershipId,
          buyerRepresentativeId,
        },
        certificatePolicies: [
          BankIdSignRequestCertificatePoliciesEnum.MOBILE_BANKID,
          BankIdSignRequestCertificatePoliciesEnum.BANKID_ON_FILE,
        ],
      },
    });

    if (!resp.ok) {
      setState(prev => ({
        ...prev,
        result: 'failed',
        errorMessage: resp.body?.errorMessage || 'Ett oväntat fel inträffade.',
      }));
      return;
    }

    const { autoStartToken, orderReference } = resp.body;

    setState(prev => ({
      ...prev,
      orderReference,
      autoStartToken,
      qrTime: new Date().getTime(),
    }));
  }, [buyerRepresentativeId, horseId, ownershipId, serviceType, transferDate]);

  const onCancel = useCallback(async () => {
    if (state.orderReference) {
      await request<BankIdUserSigning & ErrorResponse>({
        url: `/ownershiptransfers/bankid/cancel/${state.orderReference}?serviceType=${serviceType}`,
        method: 'DELETE',
      });
    }
  }, [serviceType, state.orderReference]);

  const onComplete = useCallback(
    ({ body }: CollectResponse) => {
      console.info(body);

      dispatch(
        resetQuery(
          `ongoingTransfers:${user.licenseId}`,
          OperatorEnum.STARTS_WITH,
        ),
      );

      dispatch(
        resetQuery(
          `representativeHorses:${user.licenseId}`,
          OperatorEnum.STARTS_WITH,
        ),
      );

      // Reset state
      setState({
        orderReference: undefined,
      });

      // Make sure the back history does not contain this specific page
      if (serviceType === 'LEASING') {
        history.replace('/minasidor/leasing/anmal');
      } else {
        history.replace('/minasidor/agarskiften/anmal');
      }

      history.push(
        serviceType === 'LEASING'
          ? '/minasidor/leasing/pagande?message=init'
          : '/minasidor/agarskiften/pagande?message=init',
      );
    },
    [dispatch, history, serviceType, user.licenseId],
  );

  const response = useBankIdCollect({
    onInit,
    collectCallback: runCollect,
    onComplete,
    onCancel,
  });

  return {
    ...state,
    ...response,
    result: state.result || response.result,
  };
};

export default useBankIdTranferOwnership;

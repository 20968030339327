// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Vaccine,
    VaccineFromJSON,
    VaccineToJSON,
} from './';

/**
 * Information about the last vaccine injection
 * @export
 * @interface VaccineInjection
 */
export interface VaccineInjection  {
    /**
     * 
     * @type {Vaccine}
     * @memberof VaccineInjection
     */
    vaccine?: Vaccine;
    /**
     * 
     * @type {string}
     * @memberof VaccineInjection
     */
    vaccineSequence?: VaccineInjectionVaccineSequenceEnum;
    /**
     * 
     * @type {Date}
     * @memberof VaccineInjection
     */
    injectionDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VaccineInjection
     */
    validTo?: Date;
}

export function VaccineInjectionFromJSON(json: any): VaccineInjection {
    return {
        'vaccine': !exists(json, 'vaccine') ? undefined : VaccineFromJSON(json['vaccine']),
        'vaccineSequence': !exists(json, 'vaccineSequence') ? undefined : json['vaccineSequence'],
        'injectionDate': !exists(json, 'injectionDate') ? undefined : parseDateTime(json['injectionDate']),
        'validTo': !exists(json, 'validTo') ? undefined : parseDateTime(json['validTo']),
    };
}

export function VaccineInjectionToJSON(value?: VaccineInjection): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'vaccine': VaccineToJSON(value.vaccine),
        'vaccineSequence': value.vaccineSequence,
        'injectionDate': value.injectionDate === undefined ? undefined : value.injectionDate.toLocalDate(),
        'validTo': value.validTo === undefined ? undefined : value.validTo.toLocalDate(),
    };
}

/**
* @export
* @enum {string}
*/
export enum VaccineInjectionVaccineSequenceEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    Y = 'Y',
    Z = 'Z',
    R = 'R',
    X = 'X',
    EMPTY = ''
}



import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import useAuthenticationMethod from '@hooks/useAuthenticationMethod';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import routes from './routes';

interface Props {
  user: AuthenticatedUser;
}

function Settings({ user }: Props): JSX.Element {
  const { url, path } = useRouteMatch();
  const authenticationMethod = useAuthenticationMethod();

  return (
    <PageLayout
      headline="Inställningar"
      singleCard
      tabs={routes
        .filter(
          item => !(item.path === 'losen' && authenticationMethod === 'BANKID'),
        )
        .map((item, i) => ({
          to: i === 0 ? url : `${url}/${item.path}`,
          exact: i === 0,
          text: item.label,
        }))}
    >
      <Switch>
        {routes.map((item, i) => (
          <Route
            key={item.path}
            path={i == 0 ? path : `${path}/${item.path}`}
            exact={i == 0}
          >
            <item.component user={user} />
          </Route>
        ))}
      </Switch>
    </PageLayout>
  );
}

export default withUser(Settings, { onlyAuthenticated: true });

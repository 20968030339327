import { getTransactions, Transaction } from '@generated/tds/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = Transaction[] | undefined;

export interface Entities {
  transactions?: {
    [licenseId: number]: Result;
  };
}

export const getQueryKey = (licenseId: number): string =>
  `transactions-${licenseId}`;

const useGetTransactions = (
  licenseId: number,
): ApiResponse<Result, Entities> => {
  const key = getQueryKey(licenseId);
  const query = getTransactions<Entities>(
    {
      licenseId,
    },
    {
      force: true,
      queryKey: key,
      transform: responseBody => ({
        transactions: {
          [key]: responseBody,
        },
      }),
      update: {
        transactions: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Result =>
    state.entities?.transactions?.[key];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetTransactions;

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    ExplicitUserShare,
    ExplicitUserShareFromJSON,
    ExplicitUserShareToJSON,
    OwnerTransactions,
    OwnerTransactionsFromJSON,
    OwnerTransactionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface InvoicedTransactions
 */
export interface InvoicedTransactions  {
    /**
     * 
     * @type {OwnerTransactions}
     * @memberof InvoicedTransactions
     */
    ownerTransactions: OwnerTransactions;
    /**
     * 
     * @type {ExplicitUserShare}
     * @memberof InvoicedTransactions
     */
    explicitUserShare?: ExplicitUserShare;
}

export function InvoicedTransactionsFromJSON(json: any): InvoicedTransactions {
    return {
        'ownerTransactions': OwnerTransactionsFromJSON(json['ownerTransactions']),
        'explicitUserShare': !exists(json, 'explicitUserShare') ? undefined : ExplicitUserShareFromJSON(json['explicitUserShare']),
    };
}

export function InvoicedTransactionsToJSON(value?: InvoicedTransactions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'ownerTransactions': OwnerTransactionsToJSON(value.ownerTransactions),
        'explicitUserShare': ExplicitUserShareToJSON(value.explicitUserShare),
    };
}



import Alert from '@components/Alert';
import { CircleInfo as InfoCircle } from '@styled-icons/fa-solid';
import Refresh from '@utils/Refresh';
import React, { useCallback, useEffect, useState } from 'react';

interface Props {
  date: Date;
  refresh: Refresh;
  title: string;
  description: string;
}

const RegDateHasPassedAlert = ({
  date,
  refresh,
  title,
  description,
}: Props): JSX.Element => {
  const [regDateHasPassed, setRegDateHasPassed] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<number>();

  const refreshCallback = useCallback(() => {
    refresh();
  }, [refresh]);

  const startInterval = useCallback(async () => {
    const interval = window.setInterval(async () => {
      if (new Date() > date) {
        setRegDateHasPassed(true);
      }
    }, 5000);

    setIntervalId(interval);
  }, [date, setIntervalId]);

  useEffect(() => {
    startInterval();
  }, [startInterval]);

  // Clear interval on unmount
  useEffect(() => () => window.clearInterval(intervalId), [intervalId]);

  return regDateHasPassed ? (
    <Alert
      title={title}
      description={description}
      action="Stäng meddelande"
      cancel=""
      icon={InfoCircle}
      iconProps={{ size: 12, mb: '0px' }}
      closeModal={refreshCallback}
      onAction={refreshCallback}
      open={true}
      colorScheme="delete"
      size="small"
    />
  ) : null;
};

export default RegDateHasPassedAlert;

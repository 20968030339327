import Box from '@primitives/Box';
import Button from '@primitives/Button';
import { Input as PInput } from '@primitives/Form';
import CSS from 'csstype';
import { FieldHookConfig, useField } from 'formik';
import React, { InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import Error from './Error';

type Props = {
  label?: string;
  button?: ReactNode;
  sx?: CSS.Properties;
} & FieldHookConfig<string> &
  InputHTMLAttributes<HTMLInputElement>;

const InlineButton = styled(Button)`
  min-width: 32px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 0;
  min-width: 32px;
  width: 32px;
  height: 32px;
  margin-left: -1px;
  padding: 0;
`;

function Input({
  label,
  pattern,
  button,
  sx,
  type,
  autoComplete,
  autoFocus,
  tabIndex,
  ...props
}: Props): JSX.Element {
  const [field, meta] = useField<string>({
    ...props,
  });

  if (props.onChange) {
    field.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange(e);
    };
  }

  const inputProps = {
    ...field,
    sx,
    max: props?.max,
    maxLength: props?.max ? Number.parseInt(props.max.toString()) : undefined,
    placeholder: props?.placeholder,
    disabled: props?.disabled,
    className: meta.touched && meta.error ? 'errorInput' : undefined,
    pattern,
    autoComplete,
    autoFocus,
    tabIndex,
  };

  return (
    <>
      {label && <label htmlFor={field.name}>{label}</label>}
      {button ? (
        <Box
          display="flex"
          sx={{
            [PInput]: {
              width: 0,
              flexGrow: 1,
            },
          }}
        >
          <PInput type={type} {...inputProps} />
          <InlineButton type="submit">{button}</InlineButton>
        </Box>
      ) : (
        <PInput type={type} {...inputProps} />
      )}

      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </>
  );
}

export default Input;

import {
  getBasicInformation,
  LicenseHolderBasicInformation,
} from '@generated/licenseholders/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type ReturnType = LicenseHolderBasicInformation | undefined;

export interface Entities {
  licenseHolder?: {
    [id: number]: ReturnType;
  };
}

const getQuery = (id: number): QueryConfig<Entities> =>
  getBasicInformation<Entities>(
    {
      licenseId: id,
    },
    {
      queryKey: `licenseholder-${id}`,
      transform: responseBody => ({
        licenseHolder: {
          [id]: responseBody,
        },
      }),
      update: {
        licenseHolder: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetLicenseHolder = (id: number): ApiResponse<ReturnType, Entities> => {
  const query = getQuery(id);
  const selector = (state: State): ReturnType =>
    state.entities?.licenseHolder?.[id];

  return useApi<ReturnType>({
    query,
    selector,
  });
};

export default useGetLicenseHolder;

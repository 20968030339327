import Icon from '@primitives/Icon';
import { FilePdf } from '@styled-icons/fa-solid';
import Refresh from '@utils/Refresh';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface LinkProps {
  fontWeight: string;
}

const Link = styled.a<LinkProps>`
  font-weight: ${props => props.fontWeight};
`;

interface Props {
  children: ReactNode;
  documentId: number;
  licenseId: number;
  read: boolean;
  refresh: Refresh;
}

export default function PdfLink({
  children,
  documentId,
  licenseId,
  read,
  refresh,
}: Props): JSX.Element {
  return (
    <Link
      href={`/storage/tds-list/${licenseId}/${documentId}`}
      target="_blank"
      rel="noreferrer noopener"
      fontWeight={read ? 'normal' : 'bold'}
      onClick={() => {
        setTimeout(() => {
          refresh();
        }, 3000);
      }}
    >
      {children}
      <Icon size={16} as={FilePdf} color="blue" ml={1} />
    </Link>
  );
}

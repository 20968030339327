import 'styled-components';

import { useGetStakeRaceWithStakes } from '@apis';
import InfoBadge from '@components/InfoBadge';
import useUser from '@hooks/useUser';
import Box, { Container, Flex, Item } from '@primitives/Box';
import Error from '@primitives/Error';
import { LinkButton } from '@primitives/Link';
import Loading from '@primitives/Loading';
import { CircleInfo as InfoCircle } from '@styled-icons/fa-solid';
import React from 'react';
import { generatePath, useLocation, useRouteMatch } from 'react-router-dom';

import InfoTexts from '../InfoTexts';
import StakeInfo from '../StakeInfo';
import ScratchForm from './ScratchForm';

interface Props {
  propositionId: number;
}

export default function Expanded({ propositionId }: Props): JSX.Element {
  const { data, loading, error, status } =
    useGetStakeRaceWithStakes(propositionId);

  const user = useUser();
  const { path } = useRouteMatch();
  const { search } = useLocation();

  if (loading) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return <Error>{error}</Error>;
  }

  const disabled = data?.enrollmentUpdatesAllowed === false;

  return (
    <Container gap={15} my="-7.5px">
      <Item width={[1, null, 0.6]} display="block">
        <Box verticalSpacing={4}>
          <ScratchForm user={user} simple propositionId={propositionId} />

          {disabled ? (
            <InfoBadge
              width={1}
              icon={InfoCircle}
              color="blue"
              message="Det går inte att stryka just nu. Kommande insatstermin är inte aktiverad."
            />
          ) : (
            <Flex width="1" justifyContent="flex-end">
              <LinkButton
                to={
                  generatePath(path, {
                    section: 'anmalda',
                    propositionId: propositionId.toString(),
                  }) + search
                }
              >
                Till sida för strykningar
              </LinkButton>
            </Flex>
          )}
        </Box>
      </Item>
      <Item width={[1, null, 0.4]} display="block">
        <Box verticalSpacing={2}>
          <StakeInfo stakes={data.stakes} />
          <InfoTexts infoTexts={data.infoTexts} />
        </Box>
      </Item>
    </Container>
  );
}

// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Proposition
 */
export interface Proposition  {
    /**
     * The unique id for the proposition.
     * @type {number}
     * @memberof Proposition
     */
    propositionId: number;
    /**
     * The proposition number.
     * @type {number}
     * @memberof Proposition
     */
    propositionNumber: number;
    /**
     * 
     * @type {string}
     * @memberof Proposition
     */
    propositionRow: string;
    /**
     * The name of the race (proposition).
     * @type {string}
     * @memberof Proposition
     */
    name: string;
    /**
     * The distance of the race.
     * @type {number}
     * @memberof Proposition
     */
    distance: number;
    /**
     * The type of surface of which the race is going to be held.
     * @type {string}
     * @memberof Proposition
     */
    surface: string;
    /**
     * The prize money of the race.
     * @type {number}
     * @memberof Proposition
     */
    totalPriceMoney: number;
    /**
     * The race type.
     * @type {string}
     * @memberof Proposition
     */
    raceType: string;
}

export function PropositionFromJSON(json: any): Proposition {
    return {
        'propositionId': json['propositionId'],
        'propositionNumber': json['propositionNumber'],
        'propositionRow': json['propositionRow'],
        'name': json['name'],
        'distance': json['distance'],
        'surface': json['surface'],
        'totalPriceMoney': json['totalPriceMoney'],
        'raceType': json['raceType'],
    };
}

export function PropositionToJSON(value?: Proposition): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'propositionId': value.propositionId,
        'propositionNumber': value.propositionNumber,
        'propositionRow': value.propositionRow,
        'name': value.name,
        'distance': value.distance,
        'surface': value.surface,
        'totalPriceMoney': value.totalPriceMoney,
        'raceType': value.raceType,
    };
}



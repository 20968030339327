// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Selection,
    SelectionFromJSON,
    SelectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface GeneralSearchSelectionModel
 */
export interface GeneralSearchSelectionModel  {
    /**
     * 
     * @type {Selection}
     * @memberof GeneralSearchSelectionModel
     */
    selectionBreed?: Selection;
    /**
     * 
     * @type {Selection}
     * @memberof GeneralSearchSelectionModel
     */
    selectionAge?: Selection;
    /**
     * 
     * @type {Selection}
     * @memberof GeneralSearchSelectionModel
     */
    selectionGender?: Selection;
    /**
     * 
     * @type {Selection}
     * @memberof GeneralSearchSelectionModel
     */
    selectionRaceType?: Selection;
    /**
     * 
     * @type {Selection}
     * @memberof GeneralSearchSelectionModel
     */
    selectionSurface?: Selection;
    /**
     * 
     * @type {Selection}
     * @memberof GeneralSearchSelectionModel
     */
    selectionRaised?: Selection;
    /**
     * 
     * @type {Date}
     * @memberof GeneralSearchSelectionModel
     */
    fromDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GeneralSearchSelectionModel
     */
    toDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GeneralSearchSelectionModel
     */
    horseId?: string;
    /**
     * 
     * @type {number}
     * @memberof GeneralSearchSelectionModel
     */
    distanceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralSearchSelectionModel
     */
    distanceTo?: number;
}

export function GeneralSearchSelectionModelFromJSON(json: any): GeneralSearchSelectionModel {
    return {
        'selectionBreed': !exists(json, 'selectionBreed') ? undefined : SelectionFromJSON(json['selectionBreed']),
        'selectionAge': !exists(json, 'selectionAge') ? undefined : SelectionFromJSON(json['selectionAge']),
        'selectionGender': !exists(json, 'selectionGender') ? undefined : SelectionFromJSON(json['selectionGender']),
        'selectionRaceType': !exists(json, 'selectionRaceType') ? undefined : SelectionFromJSON(json['selectionRaceType']),
        'selectionSurface': !exists(json, 'selectionSurface') ? undefined : SelectionFromJSON(json['selectionSurface']),
        'selectionRaised': !exists(json, 'selectionRaised') ? undefined : SelectionFromJSON(json['selectionRaised']),
        'fromDate': !exists(json, 'fromDate') ? undefined : parseDateTime(json['fromDate']),
        'toDate': !exists(json, 'toDate') ? undefined : parseDateTime(json['toDate']),
        'horseId': !exists(json, 'horseId') ? undefined : json['horseId'],
        'distanceFrom': !exists(json, 'distanceFrom') ? undefined : json['distanceFrom'],
        'distanceTo': !exists(json, 'distanceTo') ? undefined : json['distanceTo'],
    };
}

export function GeneralSearchSelectionModelToJSON(value?: GeneralSearchSelectionModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'selectionBreed': SelectionToJSON(value.selectionBreed),
        'selectionAge': SelectionToJSON(value.selectionAge),
        'selectionGender': SelectionToJSON(value.selectionGender),
        'selectionRaceType': SelectionToJSON(value.selectionRaceType),
        'selectionSurface': SelectionToJSON(value.selectionSurface),
        'selectionRaised': SelectionToJSON(value.selectionRaised),
        'fromDate': value.fromDate === undefined ? undefined : value.fromDate.toLocalDate(),
        'toDate': value.toDate === undefined ? undefined : value.toDate.toLocalDate(),
        'horseId': value.horseId,
        'distanceFrom': value.distanceFrom,
        'distanceTo': value.distanceTo,
    };
}



// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Horse,
    HorseFromJSON,
    HorseToJSON,
    HorseInTrainingGender,
    HorseInTrainingGenderFromJSON,
    HorseInTrainingGenderToJSON,
    Remark,
    RemarkFromJSON,
    RemarkToJSON,
} from './';

/**
 * 
 * @export
 * @interface HorseInTraining
 */
export interface HorseInTraining  {
    /**
     * 
     * @type {Horse}
     * @memberof HorseInTraining
     */
    horse: Horse;
    /**
     * 
     * @type {HorseInTrainingGender}
     * @memberof HorseInTraining
     */
    gender: HorseInTrainingGender;
    /**
     * The date of birth.
     * @type {Date}
     * @memberof HorseInTraining
     */
    dateOfBirth: Date;
    /**
     * The horse\'s age.
     * @type {number}
     * @memberof HorseInTraining
     */
    age?: number;
    /**
     * The horse winnings.
     * @type {number}
     * @memberof HorseInTraining
     */
    prizeMoney?: number;
    /**
     * Handicap
     * @type {number}
     * @memberof HorseInTraining
     */
    handicap: number;
    /**
     * 
     * @type {Horse}
     * @memberof HorseInTraining
     */
    fatherHorse: Horse;
    /**
     * 
     * @type {Horse}
     * @memberof HorseInTraining
     */
    motherHorse: Horse;
    /**
     * The bonus.
     * @type {number}
     * @memberof HorseInTraining
     */
    bonus: number;
    /**
     * Can the user change the registration status on the horse.
     * @type {boolean}
     * @memberof HorseInTraining
     */
    registrationStatusChangeable?: boolean;
    /**
     * 
     * @type {Remark}
     * @memberof HorseInTraining
     */
    remark?: Remark;
}

export function HorseInTrainingFromJSON(json: any): HorseInTraining {
    return {
        'horse': HorseFromJSON(json['horse']),
        'gender': HorseInTrainingGenderFromJSON(json['gender']),
        'dateOfBirth': parseDateTime(json['dateOfBirth']),
        'age': !exists(json, 'age') ? undefined : json['age'],
        'prizeMoney': !exists(json, 'prizeMoney') ? undefined : json['prizeMoney'],
        'handicap': json['handicap'],
        'fatherHorse': HorseFromJSON(json['fatherHorse']),
        'motherHorse': HorseFromJSON(json['motherHorse']),
        'bonus': json['bonus'],
        'registrationStatusChangeable': !exists(json, 'registrationStatusChangeable') ? undefined : json['registrationStatusChangeable'],
        'remark': !exists(json, 'remark') ? undefined : RemarkFromJSON(json['remark']),
    };
}

export function HorseInTrainingToJSON(value?: HorseInTraining): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horse': HorseToJSON(value.horse),
        'gender': HorseInTrainingGenderToJSON(value.gender),
        'dateOfBirth': value.dateOfBirth.toLocalDate(),
        'age': value.age,
        'prizeMoney': value.prizeMoney,
        'handicap': value.handicap,
        'fatherHorse': HorseToJSON(value.fatherHorse),
        'motherHorse': HorseToJSON(value.motherHorse),
        'bonus': value.bonus,
        'registrationStatusChangeable': value.registrationStatusChangeable,
        'remark': RemarkToJSON(value.remark),
    };
}



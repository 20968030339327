import { useGetStartDeclarationInformation } from '@apis';
import ReceiptWrapper from '@components/ReceiptWrapper';
import ListHeader from '@components/ReceiptWrapper/ListHeader';
import { DeclaredEntryDetailDriverChoiceEnum } from '@generated/propositions/src';
import Box from '@primitives/Box';
import List, { LI } from '@primitives/List';
import { Text } from '@primitives/Typography';
import { formatTz } from '@utils/dateUtils';
import sv from 'date-fns/locale/sv';
import React, { ReactNode } from 'react';

import DriverWeight from '../DriverWeight';

interface Props {
  licenseId?: number;
  horseId: number;
  propId: number;
  overlay?: boolean;
  propWeight: number;
  actions?: ReactNode[];
  force?: boolean;
}

const Receipt = ({
  licenseId,
  horseId,
  propId,
  overlay = false,
  propWeight,
  actions,
  force = false,
}: Props): JSX.Element => {
  const { data } = useGetStartDeclarationInformation(horseId, propId, force);

  if (!data) {
    return null;
  }

  const count = data?.drivers ? data.drivers.length : 0;

  return (
    <ReceiptWrapper
      overlay={overlay}
      variant="startDeclaration"
      horseId={horseId}
      raceDayId={data.raceDayId}
      licenseId={licenseId}
      propositionId={data.proposition.id}
      actions={actions}
    >
      <>
        <Text>
          {data.horse.name} har startanmälts till {data.trackName}{' '}
          {formatTz(data.startDateTime, 'EEEE d MMMM yyyy', {
            locale: sv,
          })}{' '}
          av {data.updateTrainer} (
          {formatTz(data.updateDateTime, 'yyyy-MM-dd HH:mm', {
            locale: sv,
          })}
          ) .
        </Text>

        <Box mt={4}>
          <ListHeader>Proposition</ListHeader>
          <Text>
            {data.proposition.propositionRow} ({propWeight} kg)
          </Text>
        </Box>
        <Box mt={4}>
          <ListHeader>Ryttare</ListHeader>
          {count > 0 ? (
            <List markerType="circle">
              {[...data.drivers]
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map(driver => (
                  <Box key={driver.id}>
                    <LI markerColor="green">
                      <span style={{ fontWeight: 'bold' }}>{driver.name}</span>
                      {driver.mountingPromise && (
                        <span style={{ fontStyle: 'italic' }}> Ridlöfte</span>
                      )}
                      <br />
                      {'Ridvikt: '}
                      {driver.updatedWeight ? (
                        ` ${driver.updatedWeight} kg (ändrad)`
                      ) : (
                        <>
                          {' '}
                          <DriverWeight
                            propositionId={data.proposition.id}
                            propositionWeight={data.proposition.weight}
                            driverId={driver.id}
                          />
                        </>
                      )}
                    </LI>
                  </Box>
                ))}
            </List>
          ) : (
            <Text>
              {data.driverChoice ===
              DeclaredEntryDetailDriverChoiceEnum.SPECIFY_LATER
                ? 'Ej avtalad ryttare'
                : data.driverChoice ===
                    DeclaredEntryDetailDriverChoiceEnum.FOREIGN_DRIVER
                  ? 'Ryttare saknas i söklista'
                  : ' Ingen vald ryttare'}
            </Text>
          )}
        </Box>
        {data.message && (
          <Box mt={4}>
            <ListHeader>
              Meddelande
              {data.driverChoice ===
                DeclaredEntryDetailDriverChoiceEnum.FOREIGN_DRIVER &&
                '/ryttarval'}
            </ListHeader>
            <Box>
              <Text pr={1} fontSize="text">
                {data.message}
              </Text>
            </Box>
          </Box>
        )}
        <Box mt={4}>
          {data?.blinkers.code !== 'N' && (
            <>
              <ListHeader>Blinkers</ListHeader>
              <Box>
                <Text pr={1} fontSize="text">
                  {data?.blinkers.code === 'B'
                    ? 'Blinkers'
                    : data?.blinkers.code === 'S'
                      ? 'Sidostycken (ludd)'
                      : '-'}
                </Text>
              </Box>
            </>
          )}
        </Box>
        {data.exemptions && data.exemptions.length > 0 && (
          <Box mt={4}>
            <ListHeader>Undantag</ListHeader>
            <Box display="flex" flexWrap="wrap" alignItems="flex-start">
              {data.exemptions?.map((exemption, index) => (
                <Text pr={1} key={exemption.description} fontSize="text">
                  {exemption.description}
                  {index < data.exemptions.length - 1 ? ', ' : ' '}
                </Text>
              ))}
              {data.exemptionMessage && (
                <Text
                  fontSize="text"
                  fontStyle="italic"
                  sx={{
                    whiteSpace: 'normal',
                  }}
                >
                  ({data.exemptionMessage})
                </Text>
              )}
            </Box>
          </Box>
        )}
      </>
    </ReceiptWrapper>
  );
};

export default Receipt;

// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImageResource,
    ImageResourceFromJSON,
    ImageResourceToJSON,
    NewsResourceMeta,
    NewsResourceMetaFromJSON,
    NewsResourceMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewsResource
 */
export interface NewsResource  {
    /**
     * 
     * @type {number}
     * @memberof NewsResource
     */
    id: number;
    /**
     * 
     * @type {NewsResourceMeta}
     * @memberof NewsResource
     */
    meta: NewsResourceMeta;
    /**
     * 
     * @type {string}
     * @memberof NewsResource
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NewsResource
     */
    preamble?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsResource
     */
    newsType: string;
    /**
     * 
     * @type {ImageResource}
     * @memberof NewsResource
     */
    image?: ImageResource;
}

export function NewsResourceFromJSON(json: any): NewsResource {
    return {
        'id': json['id'],
        'meta': NewsResourceMetaFromJSON(json['meta']),
        'title': json['title'],
        'preamble': !exists(json, 'preamble') ? undefined : json['preamble'],
        'newsType': json['news_type'],
        'image': !exists(json, 'image') ? undefined : ImageResourceFromJSON(json['image']),
    };
}

export function NewsResourceToJSON(value?: NewsResource): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'meta': NewsResourceMetaToJSON(value.meta),
        'title': value.title,
        'preamble': value.preamble,
        'news_type': value.newsType,
        'image': ImageResourceToJSON(value.image),
    };
}



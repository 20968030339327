// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface ChangePasswordCredentials
 */
export interface ChangePasswordCredentials  {
    /**
     * The users username
     * @type {string}
     * @memberof ChangePasswordCredentials
     */
    username: string;
    /**
     * The users password
     * @type {string}
     * @memberof ChangePasswordCredentials
     */
    password: string;
    /**
     * The new password to be set
     * @type {string}
     * @memberof ChangePasswordCredentials
     */
    newPassword: string;
}

export function ChangePasswordCredentialsFromJSON(json: any): ChangePasswordCredentials {
    return {
        'username': json['username'],
        'password': json['password'],
        'newPassword': json['newPassword'],
    };
}

export function ChangePasswordCredentialsToJSON(value?: ChangePasswordCredentials): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'username': value.username,
        'password': value.password,
        'newPassword': value.newPassword,
    };
}



// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Commissions,
    CommissionsFromJSON,
    CommissionsToJSON,
    LicenseHolderAdditionalInformation,
    LicenseHolderAdditionalInformationFromJSON,
    LicenseHolderAdditionalInformationToJSON,
    LicenseHolderBasicContactInfo,
    LicenseHolderBasicContactInfoFromJSON,
    LicenseHolderBasicContactInfoToJSON,
    LicenseHolderBasicLicenseInfo,
    LicenseHolderBasicLicenseInfoFromJSON,
    LicenseHolderBasicLicenseInfoToJSON,
    LicenseHolderDeputyInfo,
    LicenseHolderDeputyInfoFromJSON,
    LicenseHolderDeputyInfoToJSON,
    LicenseHolderTraineeInfo,
    LicenseHolderTraineeInfoFromJSON,
    LicenseHolderTraineeInfoToJSON,
    Suspension,
    SuspensionFromJSON,
    SuspensionToJSON,
} from './';

/**
 * This is a license holder.
 * @export
 * @interface LicenseHolderBasicInformation
 */
export interface LicenseHolderBasicInformation  {
    /**
     * 
     * @type {number}
     * @memberof LicenseHolderBasicInformation
     */
    licenseId: number;
    /**
     * The name of the license holder
     * @type {string}
     * @memberof LicenseHolderBasicInformation
     */
    name?: string;
    /**
     * The short name of the license holder
     * @type {string}
     * @memberof LicenseHolderBasicInformation
     */
    shortName?: string;
    /**
     * The year the license holder was born
     * @type {string}
     * @memberof LicenseHolderBasicInformation
     */
    yearBorn?: string;
    /**
     * The license holders home track
     * @type {string}
     * @memberof LicenseHolderBasicInformation
     */
    homeTrack?: string;
    /**
     * The place where the training takes place
     * @type {string}
     * @memberof LicenseHolderBasicInformation
     */
    trainingPlace?: string;
    /**
     * The lowest riding weight for a jockey
     * @type {number}
     * @memberof LicenseHolderBasicInformation
     */
    lowestRidingWeight?: number;
    /**
     * The weight reduction
     * @type {string}
     * @memberof LicenseHolderBasicInformation
     */
    weightReduction?: string;
    /**
     * 
     * @type {LicenseHolderBasicLicenseInfo}
     * @memberof LicenseHolderBasicInformation
     */
    licenseInfo?: LicenseHolderBasicLicenseInfo;
    /**
     * 
     * @type {LicenseHolderBasicContactInfo}
     * @memberof LicenseHolderBasicInformation
     */
    contactInfo?: LicenseHolderBasicContactInfo;
    /**
     * 
     * @type {LicenseHolderDeputyInfo}
     * @memberof LicenseHolderBasicInformation
     */
    deputyInfo?: LicenseHolderDeputyInfo;
    /**
     * 
     * @type {LicenseHolderTraineeInfo}
     * @memberof LicenseHolderBasicInformation
     */
    traineeInfo?: LicenseHolderTraineeInfo;
    /**
     * Message if suspended.
     * @type {string}
     * @memberof LicenseHolderBasicInformation
     */
    suspensionMessage?: string;
    /**
     * 
     * @type {LicenseHolderAdditionalInformation}
     * @memberof LicenseHolderBasicInformation
     */
    additionalInformation?: LicenseHolderAdditionalInformation;
    /**
     * The sport info type (used for saving as favorite).
     * @type {string}
     * @memberof LicenseHolderBasicInformation
     */
    sportInfoType?: LicenseHolderBasicInformationSportInfoTypeEnum;
    /**
     * 
     * @type {Commissions}
     * @memberof LicenseHolderBasicInformation
     */
    commissions?: Commissions;
    /**
     * True if licensholder has suspensions
     * @type {boolean}
     * @memberof LicenseHolderBasicInformation
     */
    hasSuspensions?: boolean;
    /**
     * Suspensions if licenseholder is suspended
     * @type {Array<Suspension>}
     * @memberof LicenseHolderBasicInformation
     */
    suspensions?: Array<Suspension>;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseHolderBasicInformation
     */
    rider?: boolean;
}

export function LicenseHolderBasicInformationFromJSON(json: any): LicenseHolderBasicInformation {
    return {
        'licenseId': json['licenseId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'yearBorn': !exists(json, 'yearBorn') ? undefined : json['yearBorn'],
        'homeTrack': !exists(json, 'homeTrack') ? undefined : json['homeTrack'],
        'trainingPlace': !exists(json, 'trainingPlace') ? undefined : json['trainingPlace'],
        'lowestRidingWeight': !exists(json, 'lowestRidingWeight') ? undefined : json['lowestRidingWeight'],
        'weightReduction': !exists(json, 'weightReduction') ? undefined : json['weightReduction'],
        'licenseInfo': !exists(json, 'licenseInfo') ? undefined : LicenseHolderBasicLicenseInfoFromJSON(json['licenseInfo']),
        'contactInfo': !exists(json, 'contactInfo') ? undefined : LicenseHolderBasicContactInfoFromJSON(json['contactInfo']),
        'deputyInfo': !exists(json, 'deputyInfo') ? undefined : LicenseHolderDeputyInfoFromJSON(json['deputyInfo']),
        'traineeInfo': !exists(json, 'traineeInfo') ? undefined : LicenseHolderTraineeInfoFromJSON(json['traineeInfo']),
        'suspensionMessage': !exists(json, 'suspensionMessage') ? undefined : json['suspensionMessage'],
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : LicenseHolderAdditionalInformationFromJSON(json['additionalInformation']),
        'sportInfoType': !exists(json, 'sportInfoType') ? undefined : json['sportInfoType'],
        'commissions': !exists(json, 'commissions') ? undefined : CommissionsFromJSON(json['commissions']),
        'hasSuspensions': !exists(json, 'hasSuspensions') ? undefined : json['hasSuspensions'],
        'suspensions': !exists(json, 'suspensions') ? undefined : (json['suspensions'] as Array<any>).map(SuspensionFromJSON),
        'rider': !exists(json, 'rider') ? undefined : json['rider'],
    };
}

export function LicenseHolderBasicInformationToJSON(value?: LicenseHolderBasicInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseId': value.licenseId,
        'name': value.name,
        'shortName': value.shortName,
        'yearBorn': value.yearBorn,
        'homeTrack': value.homeTrack,
        'trainingPlace': value.trainingPlace,
        'lowestRidingWeight': value.lowestRidingWeight,
        'weightReduction': value.weightReduction,
        'licenseInfo': LicenseHolderBasicLicenseInfoToJSON(value.licenseInfo),
        'contactInfo': LicenseHolderBasicContactInfoToJSON(value.contactInfo),
        'deputyInfo': LicenseHolderDeputyInfoToJSON(value.deputyInfo),
        'traineeInfo': LicenseHolderTraineeInfoToJSON(value.traineeInfo),
        'suspensionMessage': value.suspensionMessage,
        'additionalInformation': LicenseHolderAdditionalInformationToJSON(value.additionalInformation),
        'sportInfoType': value.sportInfoType,
        'commissions': CommissionsToJSON(value.commissions),
        'hasSuspensions': value.hasSuspensions,
        'suspensions': value.suspensions === undefined ? undefined : (value.suspensions as Array<any>).map(SuspensionToJSON),
        'rider': value.rider,
    };
}

/**
* @export
* @enum {string}
*/
export enum LicenseHolderBasicInformationSportInfoTypeEnum {
    HORSE = 'HORSE',
    LICENSE_HOLDER = 'LICENSE_HOLDER',
    OWNER = 'OWNER',
    BREEDER = 'BREEDER'
}



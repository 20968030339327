// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HorseBasicInformation,
    HorseBasicInformationFromJSON,
    HorseBasicInformationToJSON,
    HorseEnrollment,
    HorseEnrollmentFromJSON,
    HorseEnrollmentToJSON,
    HorseImportExport,
    HorseImportExportFromJSON,
    HorseImportExportToJSON,
} from '../models';

export interface GetBasicInformationRequest {
    horseId: number;
}

export interface GetHorseEnrollmentRequest {
    horseId: number;
}

export interface GetHorseImportExportRequest {
    isImport: boolean;
}


/**
 * Get horse basic information, primarily for use within sport info.
 */
function getBasicInformationRaw<T>(requestParameters: GetBasicInformationRequest, requestConfig: runtime.TypedQueryConfig<T, HorseBasicInformation> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling getBasicInformation.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{horseId}/basicinformation`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(HorseBasicInformationFromJSON(body), text);
    }

    return config;
}

/**
* Get horse basic information, primarily for use within sport info.
*/
export function getBasicInformation<T>(requestParameters: GetBasicInformationRequest, requestConfig?: runtime.TypedQueryConfig<T, HorseBasicInformation>): QueryConfig<T> {
    return getBasicInformationRaw(requestParameters, requestConfig);
}

/**
 * Get information about horse enrollment for race.
 */
function getHorseEnrollmentRaw<T>(requestParameters: GetHorseEnrollmentRequest, requestConfig: runtime.TypedQueryConfig<T, Array<HorseEnrollment>> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling getHorseEnrollment.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{horseId}/enrollments`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(HorseEnrollmentFromJSON), text);
    }

    return config;
}

/**
* Get information about horse enrollment for race.
*/
export function getHorseEnrollment<T>(requestParameters: GetHorseEnrollmentRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<HorseEnrollment>>): QueryConfig<T> {
    return getHorseEnrollmentRaw(requestParameters, requestConfig);
}

/**
 * Fetch horse import export list.
 */
function getHorseImportExportRaw<T>(requestParameters: GetHorseImportExportRequest, requestConfig: runtime.TypedQueryConfig<T, Array<HorseImportExport>> = {}): QueryConfig<T> {
    if (requestParameters.isImport === null || requestParameters.isImport === undefined) {
        throw new runtime.RequiredError('isImport','Required parameter requestParameters.isImport was null or undefined when calling getHorseImportExport.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{isImport}/importexport`.replace(`{${"isImport"}}`, encodeURIComponent(String(requestParameters.isImport))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(HorseImportExportFromJSON), text);
    }

    return config;
}

/**
* Fetch horse import export list.
*/
export function getHorseImportExport<T>(requestParameters: GetHorseImportExportRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<HorseImportExport>>): QueryConfig<T> {
    return getHorseImportExportRaw(requestParameters, requestConfig);
}


import React from 'react';
import { createGlobalStyle } from 'styled-components';

const normalizeBrowsers = `
  html {
    -webkit-text-size-adjust: 100%;
  }
  main, details {
    display: block;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  button {
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    user-select: none;
    touch-action: manipulation;
  }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  textarea {
    overflow: auto;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
`;

// Reset browser defaults
const resetStyles = `
  /* Border box on all elements */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  /* Remove default padding on styled lists. */
  ul,
  ol {
    padding: 0;
  }
  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  hr,
  p,
  ul,
  ol,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd,
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
  }
`;

const GlobalStyle = React.memo(createGlobalStyle`
  ${normalizeBrowsers}
  ${resetStyles}

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  body {
    scroll-behavior: smooth;
    font-family: ${props => props.theme.mainFont};
    margin: 0;
    padding: 0;
    font-size: ${props => props.theme.fontSizes.text}px;
    color: ${props => props.theme.colors.gray6};
    background: ${props => props.theme.bgColor};
    ${props => props.theme.mediaQueries.tablet} {
      background: ${props => props.theme.desktopBgColor};
    }
  }
  img {
    max-width: 100%;
    display: block;
  }
  ul,
  ol {
    list-style: none;
  }

  html, body, #root {
    width: 100%;
  }
  #root {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  button:focus {
    outline:0;
  }

  p {
    margin-bottom: 1em;
  }

  p:last-child {
    margin-bottom: 0;
  }
  
  a {
    border: none;
    color: #145ba0;
    text-decoration: none;
  }

  label {
    display: flex;
    font-size: ${props => props.theme.fontSizes.tiny}px;
    line-height: 13px;
    font-weight: bold;

  }

  .error {
    font-size: ${props => props.theme.fontSizes.small}px;
    line-height: 1.5;
    padding-top: 4px;
    text-align: right;
    color: ${props => props.theme.colors.red};
  }

  select,
  select:focus,
  textarea,
  input,
  .multi-select {
    font-size:  ${props => props.theme.fontSizes.lead}px !important;
  }

  ${props => props.theme.mediaQueries.tablet} {
    select,
    select:focus,
    textarea,
    input,
    .multi-select {
      font-size:  ${props => props.theme.fontSizes.text}px !important;
    }
  }

  @media print {
    .hidden-print {
      display: none !important;
    }

    body {
      background: none;
    }
  }

  .react-autosuggest__container {
    position: relative;

    .react-autosuggest__suggestions-container--open {
      display: block;
      position: absolute;
      top: 31px;
      width: 100%;
      background-color: #fff;
      z-index: 1000;
    }

    .react-autosuggest__input {
      background-size: 0 100%;
      -webkit-appearance: none;
      width: 100%;
      outline: none;
      border-radius: 0;
      padding: 0 25px 0 8px;
      font-size: 11px;
      font-weight: normal;
      line-height: 30px;
      padding-left: 30px;
      font-size: ${props => props.theme.fontSizes[0]}px;
      color: ${props => props.theme.colors['gray6']};
      border: 1px solid ${props => props.theme.colors.gray3};

      &:not(:disabled) {
        background: ${props => props.theme.colors.white};
      }
    }
    .react-autosuggest__suggestions-list {
      background: white;
      font-size: ${props => props.theme.fontSizes[0]}px;
      color: ${props => props.theme.colors['gray6']};
      border: 1px solid ${props => props.theme.colors.gray3};
      border-bottom: 1px solid ${props => props.theme.colors.gray3};
      border-left: 1px solid ${props => props.theme.colors.gray3};
      border-right: 1px solid ${props => props.theme.colors.gray3};

      .react-autosuggest__suggestion {
        cursor: pointer;
        border-top: 1px solid ${props => props.theme.colors.gray1};
        padding: ${props => props.theme.space[2]}px;

        &:first-child {
          border-top: 0;
        }
      }
      .react-autosuggest__suggestion--highlighted {
        background-color: ${props => props.theme.colors['light-blue']};
      }
    }
  }

  .multi-select {
    --rmsc-main: ${props => props.theme.colors.gray6} !important;
    --rmsc-hover: ${props => props.theme.colors['very-light-blue']} !important;
    --rmsc-selected: ${props => props.theme.colors.white} !important;
    --rmsc-border: ${props => props.theme.colors.gray3} !important;
    --rmsc-gray: ${props => props.theme.colors.gray6} !important;
    --rmsc-bg: ${props => props.theme.colors.white} !important;
    --rmsc-p: 10px !important; 
    --rmsc-radius: 0px !important;
    --rmsc-h: 30px !important;

    .clear-selected-button {
      display: none;
    }

    li {
      hyphens: auto;

        .item-renderer>div>div {
          width: 74%;
        }
    }

    .panel-content{
      box-shadow: 0px 6px 11px -5px ${props => props.theme.colors.black};
      margin-left: -1px;
      margin-right: -1px;
    }

    .select-panel{
      border: 1px solid ${props => props.theme.colors.gray3};  
      border-top: none;
    }

    .dropdown-container {
      box-shadow: none !important;
      border-color: ${props => props.theme.colors.gray3} !important;

      span {
        font-weight: normal;
      }

      .dropdown-content {
        padding-top: 1px;
        
        .select-item {
          border-bottom: 1px solid ${props =>
            props.theme.colors.gray2} !important;
          padding-top: 7px;
          padding-bottom: 7px;
        }

        .select-item:hover {
          background: ${props =>
            props.theme.colors['very-light-blue']} !important;
        }

        .select-item:focus { 
          background: none;
        }
        
        .select-item.selected {
          background: none;
        }
      }
    }
  }

  .ReactModal__Html--open,
  .ReactModal__Body--open {
    overflow: hidden !important; /* Prevents background page from scrolling when the modal is open */
  }

  .ReactModal__Overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    //align-items: safe center;
    justify-content: center;
    padding: 20px 0 60px;
    overflow-y: auto;
  }

  .ReactModal__Content {
    border: 0;
    outline: none;
    background: ${props => props.theme.colors.white};
    width: 90%;
    max-width: calc(100vw - 2rem);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
    margin: auto;
  }

  h2, h3, h4, h5, h6 {
    & + p {
      margin-top: 8px;
    }

    & + h3, & + h4,{
      margin-top: 8px;
    }
  }

  p + h2 {
    margin-top: 20px;
  }

  p + h3 {
    margin-top: 16px;
  }

  h2, h3, h4, h6 {
    color: ${props => props.theme.colors['deep-blue']};
    font-weight: normal;

    > b {
      font-weight: normal;
    }
  }

  h2 {
    font-size: ${props => props.theme.fontSizes.h2[0]};
  }

  h3 {
    font-size: ${props => props.theme.fontSizes.h3[0]};
  }

  h4 {
    font-size: ${props => props.theme.fontSizes.h4[0]}px;
    color: ${props => props.theme.colors['gray6']};
  }

  h5 {
    font-size: ${props => props.theme.fontSizes.h5[0]};
  }

  h6 {
    font-size: ${props => props.theme.fontSizes.h6[0]};
    color: ${props => props.theme.colors['dim-gray']};
  }


  //DEAD Code: all font-size below need a "px" added at the end to work
  // This is true for all in the file 
  ${props => props.theme.mediaQueries.tablet} {

    h2 {
      font-size: ${props => props.theme.fontSizes.h2[2]};
    }

    h3 {
      font-size: ${props => props.theme.fontSizes.h3[2]};
    }

    h4 {
      font-size: ${props => props.theme.fontSizes.h4[2]};
    }

    h5 {
      font-size: ${props => props.theme.fontSizes.h5[2]};
    }

    h6 {
      font-size: ${props => props.theme.fontSizes.h6[2]};
    }
  }
  

`);

export default GlobalStyle;

import {
  useGetComingStartsDriver,
  useGetComingStartsOwnerships,
  useGetComingStartsTrainer,
  useGetEnrolledRaceDays,
  useGetNumberOfHorseWithVaccineWarning,
  useGetOwnershipInvoiceNotifications,
  useGetOwnershipTransferNotifications,
  useGetSuspensionStatus,
} from '@apis';
import { AuthenticatedUser } from '@generated/account/src';
import {
  GetNotificationsServiceTypeEnum,
  NotificationServiceStateEnum,
} from '@generated/ownership-transfers/src';
import withUser from '@hoc/withUser';
import Box from '@primitives/Box';
import { Link } from '@primitives/Link';
import { OWNER_ROLES, OWNERSHIP_TRANSFER_ROLES } from '@utils/constants';
import { formatTz } from '@utils/dateUtils';
import { licenseholder, startregistration } from '@utils/links';
import roleEnum from '@utils/roleEnum';
import { groupBy } from 'lodash';
import React, { Fragment, ReactNode } from 'react';

interface Props {
  user: AuthenticatedUser;
}

interface NoteProps {
  color: 'green' | 'gray' | 'red';
  message?: ReactNode;
  to?: string;
}

function formatRaceDay(trackCode: string, raceDate: Date): string {
  return `${trackCode.toUpperCase()}-${formatTz(raceDate, 'yyMMdd')}`;
}

function Note({ color, message, to }: NoteProps): JSX.Element {
  const isLink = to && color !== 'gray';
  return (
    <Box
      mb={2}
      color={color === 'gray' ? 'dim-gray' : 'gray6'}
      display="flex"
      alignItems="center"
      sx={{
        textDecoration: color === 'gray' ? undefined : 'underline',
      }}
      as={isLink ? Link : undefined}
      to={isLink ? to : undefined}
    >
      <Box
        borderRadius="50%"
        bg={color}
        width="6px"
        height="6px"
        display="inline-block"
        mr={2}
        mb="-2px"
      />
      <div>{message}</div>
    </Box>
  );
}

const ops = {
  showError: false,
  showLoader: false,
  onlyAuthenticated: true,
};

interface GenericComingStartsProps {
  starts: {
    raceInformation: {
      raceDayId: number;
      date: Date;
    };
    track: {
      code: string;
    };
  }[];
  path: string;
  textFn: (qty: number, day: string) => string;
}

function GenericComingStarts({
  starts,
  path,
  textFn,
}: GenericComingStartsProps): JSX.Element {
  const hasStarts = starts?.length > 0;

  const raceDays = groupBy(
    starts || [],
    item => item.raceInformation.raceDayId,
  );

  return hasStarts ? (
    <Fragment>
      {Object.keys(raceDays).map(raceDayId => {
        const items = raceDays[raceDayId];
        const raceInfo = items[0];

        const raceDayString = formatRaceDay(
          raceInfo.track.code,
          raceInfo.raceInformation.date,
        );

        return (
          <Note
            key={raceDayId}
            color="green"
            to={path}
            message={textFn(items.length, raceDayString)}
          />
        );
      })}
    </Fragment>
  ) : (
    <Note color="gray" message={textFn(0, '')} />
  );
}

export const DriverComingStarts = withUser(
  ({ user }: Props): JSX.Element => {
    const { data } = useGetComingStartsDriver(user.licenseId);

    return (
      <GenericComingStarts
        starts={data}
        path={licenseholder(user.licenseId, '/ryttarekommande')}
        textFn={(nr, day) =>
          nr === 0
            ? 'Du har inga kommade uppsittningar.'
            : nr === 1
              ? `Du har 1 kommande uppsittning på ${day}.`
              : `Du har ${nr} kommande uppsittningar på ${day}.`
        }
      />
    );
  },
  {
    ...ops,
    roles: [roleEnum.Driver],
  },
);

export const SuspensionStatus = withUser(
  ({ user }: Props): JSX.Element | null => {
    const { data } = useGetSuspensionStatus(user.licenseId);

    return data?.suspensions ? (
      <Note
        color="red"
        message="Du har en eller flera avstängningar, se sportinfo."
        to={licenseholder(user.licenseId)}
      />
    ) : null;
  },
  { ...ops, roles: [roleEnum.Driver] },
);

export const TrainerEnrollments = withUser(
  ({ user }: Props): JSX.Element => {
    const { data } = useGetEnrolledRaceDays(user.licenseId);
    const hasEnrollments = data?.length > 0;
    return hasEnrollments ? (
      <>
        {data.map(item => (
          <Note
            key={item.raceDayId}
            color="green"
            to={startregistration(item.raceDayId)}
            message={`Du har kommande startanmälningar till ${item.track[
              'code'
            ].toUpperCase()}-${formatTz(item.raceDate, 'yyMMdd')}.`}
          />
        ))}
      </>
    ) : (
      <Note color="gray" message="Du har inga startanmälningar." />
    );
  },
  { ...ops, roles: [roleEnum.Trainer] },
);

export const TrainerComingStarts = withUser(
  ({ user }: Props): JSX.Element => {
    const { data } = useGetComingStartsTrainer(user.licenseId);

    return (
      <GenericComingStarts
        starts={data}
        path={licenseholder(user.licenseId, '/tranarekommande')}
        textFn={(nr, day) =>
          nr === 0
            ? 'Du har inga kommande starthästar som tränare.'
            : nr === 1
              ? `Du har 1 starthäst till ${day} som tränare.`
              : `Du har ${nr} starthästar till ${day} som tränare.`
        }
      />
    );
  },
  { ...ops, roles: [roleEnum.Trainer] },
);

export const VaccineWarnings = withUser(
  ({ user }: Props): JSX.Element => {
    const { data } = useGetNumberOfHorseWithVaccineWarning(user.licenseId);
    const hasWarnings = data > 0;

    return (
      <Note
        color={hasWarnings ? 'red' : 'gray'}
        to="/minasidor/vaccinationer"
        message={
          hasWarnings
            ? data === 1
              ? `Vaccination för ${data} häst är eller blir snart ogiltig.`
              : `Vaccination för ${data} hästar är eller blir snart ogiltig.`
            : 'Ingen häst saknar giltig vaccination.'
        }
      />
    );
  },
  { ...ops, roles: [roleEnum.Trainer] },
);

export const OwnerComingStarts = withUser(
  ({ user }: Props): JSX.Element => {
    const { data } = useGetComingStartsOwnerships(user.licenseId);

    return (
      <GenericComingStarts
        starts={data}
        path="/minasidor/kommande-starter"
        textFn={(nr, day) =>
          nr === 0
            ? 'Du har inga kommande starthästar som ägare.'
            : nr === 1
              ? `Du har 1 starthäst till ${day} som ägare.`
              : `Du har ${nr} starthästar till ${day} som ägare.`
        }
      />
    );
  },
  { ...ops, roles: OWNER_ROLES },
);

export const UnpaidInvoices = withUser(
  ({ user }: Props): JSX.Element => {
    const { data } = useGetOwnershipInvoiceNotifications(user.licenseId);

    return (
      <Note
        to="/minasidor/fakturor-och-transaktioner/fakturor"
        color={data?.numberOfUnpaidInvoices > 0 ? 'red' : 'gray'}
        message={
          data?.numberOfUnpaidInvoices > 0
            ? data.numberOfUnpaidInvoices === 1
              ? `Du har 1 obetald faktura.`
              : `Du har ${data.numberOfUnpaidInvoices} obetalda fakturor.`
            : 'Du har inga obetalda fakturor.'
        }
      />
    );
  },
  { ...ops, roles: [roleEnum.Representative] },
);

export const OwnershipTransfers = withUser(
  ({ user }: Props): JSX.Element => {
    const { data } = useGetOwnershipTransferNotifications(
      user.licenseId,
      GetNotificationsServiceTypeEnum.OWNERSHIP_TRANSFERS,
    );

    const transferNotes = data?.filter(
      i => i.serviceState === NotificationServiceStateEnum.OWNERSHIP_TRANSFERS,
    ).length;

    return (
      <>
        {transferNotes > 0 && (
          <Note
            color="green"
            to={'/minasidor/agarskiften/pagande'}
            message={`Du har ${transferNotes} ${
              transferNotes === 1
                ? 'aktuellt ägarskifte'
                : 'aktuella ägarskiften'
            }.`}
          />
        )}
      </>
    );
  },
  { ...ops, roles: OWNERSHIP_TRANSFER_ROLES },
);

export const LeasingNotes = withUser(
  ({ user }: Props): JSX.Element => {
    const { data } = useGetOwnershipTransferNotifications(
      user.licenseId,
      GetNotificationsServiceTypeEnum.LEASING,
    );

    const leasingStartNotes = data?.filter(
      i => i.serviceState === NotificationServiceStateEnum.LEASE_START,
    ).length;

    const leasingTerminationNotes = data?.filter(
      i => i.serviceState === NotificationServiceStateEnum.LEASE_TERMINATION,
    ).length;

    return (
      <>
        {leasingStartNotes > 0 && (
          <Note
            color="green"
            to={'/minasidor/leasing/pagande'}
            message={`Du har ${leasingStartNotes} ${
              leasingStartNotes === 1
                ? 'aktuellt leasingärende'
                : 'aktuella leasingärenden'
            }.`}
          />
        )}

        {leasingTerminationNotes > 0 && (
          <Note
            color="green"
            to={'/minasidor/leasing/uppsagningar'}
            message={`Du har ${leasingTerminationNotes} ${
              leasingTerminationNotes === 1
                ? 'aktuell uppsägning av leasing'
                : 'aktuella uppsägningar av leasing'
            }.`}
          />
        )}
      </>
    );
  },
  { ...ops, roles: OWNERSHIP_TRANSFER_ROLES },
);

import { Template } from './_templateData';

export const BidragsansokanUtlandsktDraktigtSto: Template = {
  id: 3,
  description: 'År då stoet importeras: 2024',
  actorType: 'horse',
  fields: [
    {
      name: 'Vilket sto och importland?',
      description:
        'Välj ett av dina ägda ston från listan med hästar eller ange uppgifter för en ej registrerad häst. Ange även det land stoet importeras ifrån.',
      fields: [
        'horseSwitchButton',
        'mareInfo',
        'mare',
        'horseName',
        'horseBirthYear',
        'horseFather',
        'horseMother',
        'horseGrandFather',
        'importCountry',
      ],
    },
    {
      name: 'Vilken hingst och uppstallningsland?',
      description:
        'Sök efter hingsten eller ange uppgifter för en ej registrerad häst. Ange även hingstens uppstallningsland.',
      fields: [
        'horseSwitchButtonStallion',
        'stallionInfo',
        'stallionSearch',
        'stallionName',
        'stallionBirthYear',
        'stallionFather',
        'stallionMother',
        'stallionGrandFather',
        'stallingCountry',
      ],
    },
  ],
  fieldOptions: [
    {
      horseSwitchButton: { hidden: 'values?.mare' },
      horseSwitchButtonStallion: { hidden: 'values?.stallion' },
      mareInfo: { hidden: 'values?.horseSwitchButton || !values.mare' },
      mare: { hidden: 'values?.horseSwitchButton || values?.mare' },
      stallion: {
        hidden: 'values?.horseSwitchButtonStallion || values?.stallion',
      },
      stallionSearch: {
        hidden: 'values?.horseSwitchButtonStallion || values?.stallion',
      },
      horseName: { hidden: '!values?.horseSwitchButton' },
      horseBirthYear: { hidden: '!values?.horseSwitchButton' },
      horseFather: { hidden: '!values?.horseSwitchButton' },
      horseMother: { hidden: '!values?.horseSwitchButton' },
      horseGrandFather: { hidden: '!values?.horseSwitchButton' },
      stallionName: { hidden: '!values?.horseSwitchButtonStallion' },
      stallionBirthYear: { hidden: '!values?.horseSwitchButtonStallion' },
      stallionFather: { hidden: '!values?.horseSwitchButtonStallion' },
      stallionMother: { hidden: '!values?.horseSwitchButtonStallion' },
      stallionGrandFather: { hidden: '!values?.horseSwitchButtonStallion' },
    },
  ],
  confirm: {
    link: 'tjanster-bidragsansokan-utlandskt-draktigt-sto-regler',
    linkText: 'regler för bidrag inköp av dräktigt sto.',
    text: 'Jag intygar härmed att jag läst',
  },
  provider: 'email',
};

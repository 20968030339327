// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    RaceResultGeneralInfo,
    RaceResultGeneralInfoFromJSON,
    RaceResultGeneralInfoToJSON,
    RaceWithBasicInfoAndResultStatus,
    RaceWithBasicInfoAndResultStatusFromJSON,
    RaceWithBasicInfoAndResultStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface RaceDayWithResults
 */
export interface RaceDayWithResults  {
    /**
     * 
     * @type {number}
     * @memberof RaceDayWithResults
     */
    raceDayId: number;
    /**
     * 
     * @type {string}
     * @memberof RaceDayWithResults
     */
    heading: string;
    /**
     * Different kind of info before/after 2019-01-01
     * @type {string}
     * @memberof RaceDayWithResults
     */
    turnover?: string;
    /**
     * Different kind of info before/after 2019-01-01: before = bet types, after = race standard (e.g. \"***STL, Harper Hanovers Lopp - STL-finaler\"
     * @type {string}
     * @memberof RaceDayWithResults
     */
    raceDayInfo?: string;
    /**
     * Different kind of info before/after 2019-01-01
     * @type {string}
     * @memberof RaceDayWithResults
     */
    attendance: string;
    /**
     * 
     * @type {string}
     * @memberof RaceDayWithResults
     */
    prizeMoney: string;
    /**
     * 
     * @type {string}
     * @memberof RaceDayWithResults
     */
    bonus?: string;
    /**
     * 
     * @type {string}
     * @memberof RaceDayWithResults
     */
    weather?: string;
    /**
     * 
     * @type {string}
     * @memberof RaceDayWithResults
     */
    courtName: string;
    /**
     * 
     * @type {string}
     * @memberof RaceDayWithResults
     */
    trackConditions?: string;
    /**
     * Next race day id on the same track as this race. Will be zero if no next race day is available
     * @type {number}
     * @memberof RaceDayWithResults
     */
    nextRaceDayId: number;
    /**
     * 
     * @type {string}
     * @memberof RaceDayWithResults
     */
    nextRaceDayText?: string;
    /**
     * Previous race day id on the same track as this race. Will be zero if no previous race day is available
     * @type {number}
     * @memberof RaceDayWithResults
     */
    previousRaceDayId: number;
    /**
     * 
     * @type {string}
     * @memberof RaceDayWithResults
     */
    previousRaceDayText?: string;
    /**
     * Will be zero if no next race day is available
     * @type {number}
     * @memberof RaceDayWithResults
     */
    nextRaceDayIdAnyTrack: number;
    /**
     * 
     * @type {string}
     * @memberof RaceDayWithResults
     */
    nextRaceDayTextAnyTrack?: string;
    /**
     * Will be zero if no previous race day is available
     * @type {number}
     * @memberof RaceDayWithResults
     */
    previousRaceDayIdAnyTrack: number;
    /**
     * 
     * @type {string}
     * @memberof RaceDayWithResults
     */
    previousRaceDayTextAnyTrack?: string;
    /**
     * 
     * @type {Date}
     * @memberof RaceDayWithResults
     */
    raceDayDate: Date;
    /**
     * 
     * @type {string}
     * @memberof RaceDayWithResults
     */
    trackName?: string;
    /**
     * All races with result status - is the result data ready or not?
     * @type {Array<RaceWithBasicInfoAndResultStatus>}
     * @memberof RaceDayWithResults
     */
    racesWithBasicInfoAndResultStatus: Array<RaceWithBasicInfoAndResultStatus>;
    /**
     * Races with results ready, might be empty
     * @type {Array<RaceResultGeneralInfo>}
     * @memberof RaceDayWithResults
     */
    raceResultGeneralInfo?: Array<RaceResultGeneralInfo>;
    /**
     * 
     * @type {Date}
     * @memberof RaceDayWithResults
     */
    firstStart?: Date;
}

export function RaceDayWithResultsFromJSON(json: any): RaceDayWithResults {
    return {
        'raceDayId': json['raceDayId'],
        'heading': json['heading'],
        'turnover': !exists(json, 'turnover') ? undefined : json['turnover'],
        'raceDayInfo': !exists(json, 'raceDayInfo') ? undefined : json['raceDayInfo'],
        'attendance': json['attendance'],
        'prizeMoney': json['prizeMoney'],
        'bonus': !exists(json, 'bonus') ? undefined : json['bonus'],
        'weather': !exists(json, 'weather') ? undefined : json['weather'],
        'courtName': json['courtName'],
        'trackConditions': !exists(json, 'trackConditions') ? undefined : json['trackConditions'],
        'nextRaceDayId': json['nextRaceDayId'],
        'nextRaceDayText': !exists(json, 'nextRaceDayText') ? undefined : json['nextRaceDayText'],
        'previousRaceDayId': json['previousRaceDayId'],
        'previousRaceDayText': !exists(json, 'previousRaceDayText') ? undefined : json['previousRaceDayText'],
        'nextRaceDayIdAnyTrack': json['nextRaceDayIdAnyTrack'],
        'nextRaceDayTextAnyTrack': !exists(json, 'nextRaceDayTextAnyTrack') ? undefined : json['nextRaceDayTextAnyTrack'],
        'previousRaceDayIdAnyTrack': json['previousRaceDayIdAnyTrack'],
        'previousRaceDayTextAnyTrack': !exists(json, 'previousRaceDayTextAnyTrack') ? undefined : json['previousRaceDayTextAnyTrack'],
        'raceDayDate': parseDateTime(json['raceDayDate']),
        'trackName': !exists(json, 'trackName') ? undefined : json['trackName'],
        'racesWithBasicInfoAndResultStatus': (json['racesWithBasicInfoAndResultStatus'] as Array<any>).map(RaceWithBasicInfoAndResultStatusFromJSON),
        'raceResultGeneralInfo': !exists(json, 'raceResultGeneralInfo') ? undefined : (json['raceResultGeneralInfo'] as Array<any>).map(RaceResultGeneralInfoFromJSON),
        'firstStart': !exists(json, 'firstStart') ? undefined : parseDateTime(json['firstStart']),
    };
}

export function RaceDayWithResultsToJSON(value?: RaceDayWithResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'raceDayId': value.raceDayId,
        'heading': value.heading,
        'turnover': value.turnover,
        'raceDayInfo': value.raceDayInfo,
        'attendance': value.attendance,
        'prizeMoney': value.prizeMoney,
        'bonus': value.bonus,
        'weather': value.weather,
        'courtName': value.courtName,
        'trackConditions': value.trackConditions,
        'nextRaceDayId': value.nextRaceDayId,
        'nextRaceDayText': value.nextRaceDayText,
        'previousRaceDayId': value.previousRaceDayId,
        'previousRaceDayText': value.previousRaceDayText,
        'nextRaceDayIdAnyTrack': value.nextRaceDayIdAnyTrack,
        'nextRaceDayTextAnyTrack': value.nextRaceDayTextAnyTrack,
        'previousRaceDayIdAnyTrack': value.previousRaceDayIdAnyTrack,
        'previousRaceDayTextAnyTrack': value.previousRaceDayTextAnyTrack,
        'raceDayDate': value.raceDayDate.toLocalDate(),
        'trackName': value.trackName,
        'racesWithBasicInfoAndResultStatus': (value.racesWithBasicInfoAndResultStatus as Array<any>).map(RaceWithBasicInfoAndResultStatusToJSON),
        'raceResultGeneralInfo': value.raceResultGeneralInfo === undefined ? undefined : (value.raceResultGeneralInfo as Array<any>).map(RaceResultGeneralInfoToJSON),
        'firstStart': value.firstStart === undefined ? undefined : value.firstStart.toISOString(),
    };
}



import styled from 'styled-components';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  shadow,
  ShadowProps,
  size,
  SizeProps,
  typography,
  TypographyProps,
} from 'styled-system';

import { base, BaseProps } from '../base';

export interface TableElementProps
  extends BaseProps,
    BorderProps,
    ShadowProps,
    SizeProps,
    BackgroundProps,
    TypographyProps {}

const composed = base(shadow, border, background, size, typography);

const TR = styled.tr<TableElementProps>`
  ${composed}
`;

export { TR };

import Refresh from '@utils/Refresh';
import { useEffect } from 'react';

interface RefetchActionProps {
  refresh: Refresh;
  startRegistrationEnd: number;
}

export default function RefetchTimer({
  refresh,
  startRegistrationEnd,
}: RefetchActionProps): null {
  useEffect(() => {
    const diff = startRegistrationEnd - new Date().getTime();

    let timeoutId;

    if (diff && diff > 0) {
      // Browsers store the delay as a 32-bit signed integer internally.
      // This causes an integer overflow when using delays larger than
      // 2 147 483 647 ms, resulting in the timeout being executed immediately.
      const maxInt = Math.pow(2, 31) - 1;
      timeoutId = setTimeout(
        () => {
          refresh();
        },
        Math.min(diff + 10 * 1000, maxInt),
      ); // 10s extra
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  return null;
}

import { useGetComingStartsOwnerships } from '@apis';
import { PageLayout } from '@components/PageLayouts';
import ComingStartsGeneric from '@features/SportInfo/utils/SportActors/ComingStartsGeneric';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import React from 'react';

interface Props {
  user: AuthenticatedUser;
}

function ComingStartsResults({ user }: Props): JSX.Element {
  const { data, loading, status, error } = useGetComingStartsOwnerships(
    user.licenseId,
  );

  if (loading) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  return <ComingStartsGeneric data={data || []} />;
}

function ComingStarts({ user }: Props): JSX.Element {
  return (
    <PageLayout headline="Kommande starter" singleCard>
      <ComingStartsResults user={user} />
    </PageLayout>
  );
}

export default withUser(ComingStarts);

import {
  LinkableBreeder,
  LinkableHorse,
  LinkableLicenseHolder,
  LinkableOwner,
} from '@generated/raceinfo/src';
import { StartListHorse } from '@generated/raceinfo/src';
import { Link } from '@primitives/Link';
import { breeder, horse, licenseholder, owner } from '@utils/links';
import React from 'react';

import NoLink from './NoLink';

interface Props {
  entity:
    | LinkableBreeder
    | LinkableHorse
    | LinkableLicenseHolder
    | LinkableOwner
    | StartListHorse;
  type:
    | 'LinkableBreeder'
    | 'LinkableHorse'
    | 'LinkableLicenseHolder'
    | 'LinkableOwner'
    | 'StartListHorse';
  fontWeight?: string;
  fontSize?: number | string;
  scratched?: boolean;
  tab?: string;
  forceLink?: boolean;
}

const Linkable = ({
  entity,
  fontSize,
  scratched = false,
  tab,
  type,
  forceLink = false,
  ...sx
}: Props): JSX.Element => {
  if (entity) {
    const textDecoration = scratched && 'line-through';
    const color = scratched && 'dim-gray';

    if (entity.linkable || forceLink) {
      const links = {
        LinkableBreeder: breeder,
        LinkableHorse: horse,
        LinkableLicenseHolder: licenseholder,
        LinkableOwner: owner,
        StartListHorse: horse,
      };

      return (
        <Link
          sx={{
            fontSize,
            textDecoration,
            color,
            ...sx,
          }}
          to={links[type](entity.id, tab)}
        >
          {entity.name}
        </Link>
      );
    }

    return (
      <NoLink sx={{ fontSize, textDecoration, color }}>{entity.name}</NoLink>
    );
  }

  return null;
};

export default Linkable;

import { useGetTerminableLeases } from '@apis';
import DataGrid from '@components/DataGrid';
import Table from '@components/Table';
import { AuthenticatedUser } from '@generated/account/src';
import { OwnershipTransfer } from '@generated/ownership-transfers/src';
import useIsMobile from '@hooks/useIsMobile';
import Box from '@primitives/Box';
import Button, { ButtonLink } from '@primitives/Button';
import Error from '@primitives/Error';
import Pulse from '@primitives/Loading';
import Message from '@primitives/Message';
import { H2, Small, Text } from '@primitives/Typography';
import scrollToTop from '@utils/scrollToTop';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { CellProps, Column } from 'react-table';

import Remarks from '../../OwnershipTransfers/Remarks';
import SMSNotice from '../../OwnershipTransfers/SMSNotice';
import ListItem from './ListItem';

interface Props {
  user: AuthenticatedUser;
}

interface Context {
  selectedHorse?: number;
  selectHorse: (v: number | undefined) => void;
}

export const Ctx = React.createContext<Partial<Context>>({});

const ActionCell = ({
  value,
  row,
}: CellProps<
  OwnershipTransfer,
  OwnershipTransfer['horse']['horseName']
>): JSX.Element => {
  const { selectHorse, selectedHorse } = useContext(Ctx);
  const isMobile = useIsMobile();

  return (
    <>
      <Text
        as="label"
        fontWeight="bold"
        fontSize="text"
        sx={{ cursor: 'pointer' }}
      >
        <Box
          as="input"
          type="radio"
          checked={selectedHorse === row.original.horse.horseId}
          onChange={event => {
            const { checked } = event.target;
            selectHorse(checked ? row.original.horse.horseId : undefined);
          }}
          sx={{ cursor: 'pointer' }}
          mr={2}
          mt="2px"
        />
        {value}
      </Text>

      {value === 'NN' && (
        <>
          <Small mt={1} color="gray4" display="block">
            Regnr: {row.original.horse.horseRegistrationNumber}
          </Small>
        </>
      )}
      {isMobile && (
        <>
          <Box mt={1}>
            <Remarks item={row.original.horse} list />
          </Box>
        </>
      )}
    </>
  );
};

const columns: Column<OwnershipTransfer>[] = [
  {
    id: 'name',
    Header: 'Välj häst',
    accessor: row => row.horse.horseName,
    Cell: ActionCell,
  },
  {
    id: 'age',
    Header: 'Ålder',
    accessor: row => row.horse.horseAge,
  },
  {
    id: 'gender',
    Header: 'Kön',
    accessor: row => row.horse.horseGender.code,
  },
  {
    id: 'owner',
    Header: 'Företrädare ägare',
    accessor: row => row.sellerRepresentative.name,
  },
  {
    id: 'lender',
    Header: 'Företrädare hyrestagare',
    accessor: row => row.buyerRepresentative.name,
  },
];

const notValidColumns: Column<OwnershipTransfer>[] = [
  {
    id: 'name',
    Header: 'Hästnamn',
    accessor: row => row.horse.horseName,
    Cell: ({ row, value }: CellProps<OwnershipTransfer>) => (
      <ButtonLink onClick={() => row.toggleRowExpanded()} fontWeight="bold">
        {value}
      </ButtonLink>
    ),
  },
  {
    id: 'age',
    Header: 'Ålder',
    accessor: row => row.horse.horseAge,
  },
  {
    id: 'gender',
    Header: 'Kön',
    accessor: row => row.horse.horseGender.code,
  },
  {
    id: 'regnr',
    Header: 'Regnr',
    accessor: row => row.horse.horseRegistrationNumber,
  },
  {
    id: 'owner',
    Header: 'Företrädare ägare',
    accessor: row => row.sellerRepresentative.name,
  },
  {
    id: 'lender',
    Header: 'Företrädare hyrestagare',
    accessor: row => row.buyerRepresentative.name,
  },
];

// Prio order
// missingBusinessRepresentative
// protectedIdentityBusinessRepresentative
// missingOver18BusinessRepresentative

// missingSwedishBusinessRepresentative
// missingAccountHolderBusinessRepresentative
// protectedIdentity
// underage
// noUserAccount

const messages = {
  missingBusinessRepresentative:
    'Uppsägningen kan inte registreras via användarkontot eftersom den andra avtalsparten saknar bolagsrepresentant.',
  protectedIdentityBusinessRepresentative:
    'Uppsägningen kan inte registreras via användarkontot. Vänligen anmäl via blankett istället.',
  missingOver18BusinessRepresentative:
    'Uppsägningen kan inte registreras via användarkontot eftersom den andra avtalsparten saknar myndig bolagsrepresentant. Vänligen anmäl via blankett istället.',
  missingSwedishBusinessRepresentative:
    'Den andra avtalsparten saknar bolagsrepresentant med svenskt personnummer.',
  missingAccountHolderBusinessRepresentative:
    'Den andra avtalspartens bolagsrepresentant har inte registrerat användarkonto.',
  protectedIdentity:
    'Uppsägningen kan inte registreras via användarkontot. Vänligen anmäl via blankett istället.',
  underage:
    'Uppsägningen kan inte registreras via användarkontot eftersom den andra avtalsparten är minderårig. Vänligen anmäl via blankett istället.',
  noUserAccount: 'Den andra avtalsparten har inte registrerat användarkonto.',
};

const showSMSButtonStatuses = [
  'missingAccountHolderBusinessRepresentative',
  'missingBusinessRepresentative',
  'missingSwedishBusinessRepresentative',
  'noUserAccount',
];

const TerminableLeasesList = ({ user }: Props): JSX.Element => {
  const { data, loading, error } = useGetTerminableLeases(user.licenseId, true);
  const mobile = useIsMobile();

  const [selectedHorse, selectHorse] = useState<number>();
  const history = useHistory();
  const [message, setMessage] = useState('');

  if (loading) {
    return <Pulse />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  const items = data.filter(
    (item, index, self) =>
      Object.values(item.counterParty).every(value => value === false) &&
      self.findIndex(v => v.id === item.id) === index,
  );

  const notValidItems = data.filter(
    item => !Object.values(item.counterParty).every(value => value === false),
  );

  return (
    <>
      <Ctx.Provider value={{ selectedHorse, selectHorse }}>
        <H2 mb="4" mt={[4, , 6]}>
          Välj häst för uppsägning av leasingavtal
        </H2>
        <DataGrid
          mt={2}
          columns={columns}
          data={items}
          sortable
          noContent={{ content: 'Du har inga leasingavtal som kan sägas upp.' }}
          emptyRow
          defaultSort={[
            {
              desc: false,
              id: 'name',
            },
          ]}
        />
      </Ctx.Provider>

      <Button
        width={[1, , 'auto']}
        colorScheme="save"
        onClick={() => {
          scrollToTop();
          history.push(`/minasidor/leasing/avsluta/${selectedHorse}`);
        }}
        disabled={!selectedHorse}
      >
        Påbörja uppsägning
      </Button>

      {notValidItems.length > 0 && (
        <>
          <H2 mb="4" mt={[4, , 6]}>
            Avtal som inte kan sägas upp via Galoppappen
          </H2>
          <Table
            mt={2}
            columns={notValidColumns}
            data={notValidItems}
            defaultSort={[
              {
                desc: false,
                id: 'name',
              },
            ]}
            allExpanded={true}
            expandableBackground="snow"
            renderListItem={
              mobile
                ? ({ row }) => (
                    <ListItem
                      defaultExpanded
                      row={row}
                      messages={messages}
                      showSMSButtonStatuses={showSMSButtonStatuses}
                      setMessage={setMessage}
                    />
                  )
                : undefined
            }
            renderRowSubComponent={({ row }) =>
              mobile ? undefined : (
                <Box verticalSpacing={2}>
                  {Object.entries(messages)
                    .filter(([key]) => row.original.counterParty[key])
                    .slice(0, 1)
                    .map(([key, msg]) => (
                      <Message variant="errorBox" key={key}>
                        {msg}
                      </Message>
                    ))}
                  {Object.entries(row.original.counterParty).filter(
                    ([key, value]) =>
                      value && showSMSButtonStatuses.includes(key),
                  ).length > 0 && <SMSNotice setMessage={setMessage} />}
                </Box>
              )
            }
          />
        </>
      )}

      {message && <Message variant="successBox">{message}</Message>}
    </>
  );
};

export default TerminableLeasesList;

import Content from '@components/ContentBlock/Content';
import ErrorBoundary from '@components/ErrorBoundary';
import ErrorPage from '@components/ErrorPage';
import { PageLayout } from '@components/PageLayouts';
import Box from '@primitives/Box';
import { Link } from '@primitives/Link';
import { MagnifyingGlass as SearchIcon } from '@styled-icons/fa-solid';
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Account from './Account';
import Issues from './Issues';
import Search from './Search';

const Login = React.lazy(() => import('./Login'));
const Breeding = React.lazy(() => import('./Breeding'));
const Charts = React.lazy(() => import('./Charts'));
const CreateAccount = React.lazy(() => import('./CreateAccount'));
const Home = React.lazy(() => import('./Home'));

const RaceDayPropositions = React.lazy(() => import('./RaceDayPropositions'));
const RaceInfo = React.lazy(() => import('./RaceInfo'));
const SportInfo = React.lazy(() => import('./SportInfo'));
const Settings = React.lazy(() => import('./Account/Settings'));
const EnrolledHorses = React.lazy(() => import('./EnrolledHorses'));
const EnrollHorseToProposition = React.lazy(
  () => import('./EnrollHorseToProposition'),
);

export default function Routes(): JSX.Element {
  return (
    <ErrorBoundary>
      <Suspense fallback={null}>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path={`/anvandarvillkor`} exact>
            <PageLayout singleCard pageSize="medium" headline="Användarvillkor">
              <Box maxWidth="732px">
                <Content slug="anvandarvillkor" />
              </Box>
            </PageLayout>
          </Route>
          <Route path="/avel">
            <Breeding />
          </Route>
          <Route path="/tjanster">
            <Issues />
          </Route>
          <Route path="/topplistor">
            <Charts />
          </Route>
          <Route path="/tavling">
            <RaceInfo />
          </Route>
          <Route path="/sportinfo">
            <SportInfo />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/skapa-konto">
            <CreateAccount />
          </Route>
          <Route path="/installningar">
            <Settings />
          </Route>
          <Route path={`/anmalda-hastar`}>
            <EnrolledHorses />
          </Route>
          <Route path={`/propositioner/:raceDayId`}>
            <RaceDayPropositions />
          </Route>
          <Route path={`/anmalan/:raceDayId`}>
            <EnrollHorseToProposition />
          </Route>
          <Route path={`/minasidor`}>
            <Account />
          </Route>
          <Route path={`/search`}>
            <Search />
          </Route>

          <Route path="*">
            <ErrorPage
              headline="Sidan hittades inte"
              header="Sidan kunde inte hittas"
              icon={SearchIcon}
              content={
                <>
                  Hej! Här var det tyvärr tomt.
                  <br />
                  Innehållet finns kanske inte längre eller så har det flyttats.
                  <br />
                  <br />
                  <Link to="/">Tillbaka till startsidan</Link>
                </>
              }
            />
          </Route>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
}

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * The horse being declared to start
 * @export
 * @interface DeclaredHorse
 */
export interface DeclaredHorse  {
    /**
     * Identification of the horse being declared
     * @type {number}
     * @memberof DeclaredHorse
     */
    id: number;
}

export function DeclaredHorseFromJSON(json: any): DeclaredHorse {
    return {
        'id': json['id'],
    };
}

export function DeclaredHorseToJSON(value?: DeclaredHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
    };
}



import { getStartMonitorings, StartMonitoring } from '@generated/user/src';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = StartMonitoring[] | undefined;

export interface Entities {
  getStartMonitorings?: { [key: string]: Result };
}

const useGetStartMonitorings = (
  licenseId: number,
): ApiResponse<Result, Entities> =>
  useApi({
    query: getStartMonitorings<Entities>(
      {
        licenseId,
      },
      {
        queryKey: `startMonitorings:${licenseId}`,
        transform: responseBody => ({
          getStartMonitorings: { [licenseId]: responseBody },
        }),
        update: {
          getStartMonitorings: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities.getStartMonitorings?.[licenseId],
  });

export default useGetStartMonitorings;

import { Driver, getPreselectedDrivers } from '../../generated/user/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = Driver[] | undefined;

export interface Entities {
  preSelectedDrivers?: {
    [key: string]: Result;
  };
}

const useGetPreSelectedDrivers = (
  licenseId: number,
  propositionid?: number,
): ApiResponse<Result, Entities> => {
  const key = propositionid
    ? `prop-${licenseId}-${propositionid}`
    : `${licenseId}`;

  return useApi<Result>({
    query: getPreselectedDrivers<Entities>(
      {
        licenseId,
        propositionid,
      },
      {
        transform: responseBody => ({
          preSelectedDrivers: {
            [key]: responseBody,
          },
        }),
        update: {
          preSelectedDrivers: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities?.preSelectedDrivers?.[key],
  });
};

export default useGetPreSelectedDrivers;

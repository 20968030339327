import {
  getHorsesWithVaccine,
  VaccinatedHorse,
} from '@generated/licenseholders/src';
import { State } from '@main/store';
import { format } from 'date-fns';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = VaccinatedHorse[] | undefined;

export interface Entities {
  horsesWithVaccine?: {
    [id: number]: Result;
  };
}

const getQuery = (
  licenseId: number,
  key: string,
  vaccinationdate?: Date,
): QueryConfig<Entities> =>
  getHorsesWithVaccine<Entities>(
    {
      licenseId,
      vaccinationdate,
    },
    {
      transform: responseBody => ({
        horsesWithVaccine: {
          [key]: responseBody,
        },
      }),
      update: {
        horsesWithVaccine: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
      queryKey: `horsesWithVaccine-${key}`,
    },
  );

const useGetHorsesWithVaccine = (
  licenseId: number,
  vaccinationdate?: Date,
): ApiResponse<Result, Entities> => {
  const key = `${licenseId}${
    vaccinationdate ? `-${format(vaccinationdate, 'yyyy-MM-dd')}` : ''
  }`;
  const query = getQuery(licenseId, key, vaccinationdate);
  const selector = (state: State): Result =>
    state.entities?.horsesWithVaccine?.[key];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetHorsesWithVaccine;

// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    ProductInformation,
    ProductInformationFromJSON,
    ProductInformationToJSON,
    Start,
    StartFromJSON,
    StartToJSON,
} from './';

/**
 * 
 * @export
 * @interface DriverCostProducts
 */
export interface DriverCostProducts  {
    /**
     * 
     * @type {Start}
     * @memberof DriverCostProducts
     */
    start: Start;
    /**
     * The products
     * @type {Array<ProductInformation>}
     * @memberof DriverCostProducts
     */
    productInformations: Array<ProductInformation>;
}

export function DriverCostProductsFromJSON(json: any): DriverCostProducts {
    return {
        'start': StartFromJSON(json['start']),
        'productInformations': (json['productInformations'] as Array<any>).map(ProductInformationFromJSON),
    };
}

export function DriverCostProductsToJSON(value?: DriverCostProducts): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'start': StartToJSON(value.start),
        'productInformations': (value.productInformations as Array<any>).map(ProductInformationToJSON),
    };
}



import styled from 'styled-components';

interface Props {
  isSorted: boolean;
  isSortedDesc: boolean;
}

const Icon = styled.span<Props>`
  position: relative;
  width: 13px;
  height: 10px;
  display: inline-block;

  &:before,
  &:after {
    border: 4px solid transparent;
    content: '';
    display: block;
    height: 0;
    left: 5px;
    top: 50%;
    position: absolute;
    width: 0;
  }

  &:before {
    border-bottom-color: ${props =>
      props.isSorted
        ? props.isSortedDesc
          ? props.theme.colors.gray3
          : props.theme.colors.blue
        : props.theme.colors.gray3};
    margin-top: -9px;
  }

  &:after {
    border-top-color: ${props =>
      props.isSorted
        ? props.isSortedDesc
          ? props.theme.colors.blue
          : props.theme.colors.gray3
        : props.theme.colors.gray3};
    margin-top: 1px;
  }
`;

export default Icon;

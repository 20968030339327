import pixelToInteger from '@utils/pixelToInteger';
import React, { ReactNode, useContext, useEffect, useState } from 'react';

import theme from './theme';

interface ScreenSizeContext {
  isMobile?: boolean;
  isTablet?: boolean;
}

export const ScreenSizeContext = React.createContext<ScreenSizeContext>({});

export function useScreenSize(): ScreenSizeContext {
  return useContext(ScreenSizeContext);
}

interface ScreenSizeProviderProps {
  children: ReactNode;
}

const getWidth = (): number => window.innerWidth;

const getIsMobile = (): boolean =>
  getWidth() < pixelToInteger(theme.breakpoints[1]);

const getIsTablet = (): boolean =>
  getWidth() < pixelToInteger(theme.breakpoints[2]) && !getIsMobile();

const getSizes = (): ScreenSizeContext => ({
  isMobile: getIsMobile(),
  isTablet: getIsTablet(),
});

export function ScreenSizeProvider({
  children,
}: ScreenSizeProviderProps): JSX.Element {
  const [state, setState] = useState<ScreenSizeContext>(getSizes());

  useEffect(() => {
    const handleResize = (): void => {
      setState(getSizes());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ScreenSizeContext.Provider value={state}>
      {children}
    </ScreenSizeContext.Provider>
  );
}

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface RaceInformation
 */
export interface RaceInformation  {
    /**
     * Race date
     * @type {Date}
     * @memberof RaceInformation
     */
    date: Date;
    /**
     * Race date plus race number (sometimes \'kv\' in gallop).
     * @type {string}
     * @memberof RaceInformation
     */
    displayDate: string;
    /**
     * 
     * @type {number}
     * @memberof RaceInformation
     */
    raceId: number;
    /**
     * 
     * @type {number}
     * @memberof RaceInformation
     */
    raceDayId: number;
    /**
     * The number that the race has on the current day.
     * @type {number}
     * @memberof RaceInformation
     */
    raceNumber: number;
    /**
     * If results are available.
     * @type {boolean}
     * @memberof RaceInformation
     */
    linkable: boolean;
}

export function RaceInformationFromJSON(json: any): RaceInformation {
    return {
        'date': parseDateTime(json['date']),
        'displayDate': json['displayDate'],
        'raceId': json['raceId'],
        'raceDayId': json['raceDayId'],
        'raceNumber': json['raceNumber'],
        'linkable': json['linkable'],
    };
}

export function RaceInformationToJSON(value?: RaceInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'date': value.date.toLocalDate(),
        'displayDate': value.displayDate,
        'raceId': value.raceId,
        'raceDayId': value.raceDayId,
        'raceNumber': value.raceNumber,
        'linkable': value.linkable,
    };
}



enum FilterLabelEnum {
  Horse = 'Häst',
  Representative = 'Företrädare',
  Date = 'Datum',
  Product = 'Produkt',
  Amount = 'Belopp',
  Details = 'Detaljer',
  Total = 'Totaler',
}

export default FilterLabelEnum;

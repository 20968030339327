import { useGetDriversRidingWeight } from '@apis';
import Error from '@primitives/Error';
import React from 'react';

interface Props {
  propositionId: number;
  driverId: number;
  propositionWeight: number;
}

const DriverWeight = ({
  propositionId,
  driverId,
  propositionWeight,
}: Props): JSX.Element => {
  const { data, loading, status, error } = useGetDriversRidingWeight(
    propositionId,
    driverId,
    propositionWeight,
  );

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return null;
  }

  return <>{`${data} kg`}</>;
};

export default React.memo(DriverWeight);

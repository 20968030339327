import { refreshAuthentication } from '@generated/authenticate/src';
import { useRefreshUser } from '@hooks/useUser';
import auth from '@main/auth';
import { resetState } from '@main/store';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from 'redux-query-react';

import setBaseUrl from '../utils/setBaseUrl';

const HTTP = {
  success: 200,
  noContent: 204,
  notAuthenticated: 401,
  notAuthorized: 403,
};

interface Callback {
  refresh: (force?: boolean) => void;
}

export const useRefreshAuthTokenCallback = (): Callback => {
  const dispatch = useDispatch();
  const refreshUser = useRefreshUser();
  const history = useHistory();
  const { pathname, search } = useLocation();
  // Add base url to the generated api
  const queryConfig = setBaseUrl(
    refreshAuthentication(
      {},
      {
        queryKey: 'refreshAuthentication',
      },
    ),
    'webapi',
  );
  const [, action] = useMutation(() => queryConfig);

  // Create memorized callback
  const refresh = useCallback(
    async (force = false): Promise<void> => {
      if (auth.isRefreshing) {
        return auth.isRefreshing;
      }

      // console.info(`Token age: ${auth.tokenAge / 1000}s`);
      if (auth.token && !auth.isRefreshing && (auth.needsRefresh || force)) {
        //Fire action
        const refreshPromise = action();

        // Prevent other api-calls to run during refresh
        const refreshAsync = async (): Promise<void> => {
          const { headers, status } = await refreshPromise;

          //Update the access token if request was successful
          if (status === HTTP.success || status === HTTP.noContent) {
            if (headers?.authorization) {
              auth.token = headers.authorization;
              console.info('Successfully updated auth token');
            }
          }
          if (
            status === HTTP.notAuthenticated ||
            status === HTTP.notAuthorized
          ) {
            const oldSession = auth.tokenAge > 60 * 60 * 1000;

            // Not authorized any more
            console.info('Unsuccessfull refresh, reseting');
            auth.token = null;

            dispatch(resetState());

            refreshUser?.();

            if (!oldSession) {
              // Navigate to /login
              history.push(
                `/login?dest=${encodeURIComponent(
                  pathname + search,
                )}&reason=session`,
              );
            }
          }
        };

        auth.isRefreshing = refreshAsync();

        await auth.isRefreshing.then(() => {
          // Reset  the refresh flag
          auth.isRefreshing = false;
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth.token, auth.isRefreshing, auth.needsRefresh],
  );

  return { refresh };
};

export const useRefreshAuthToken = (): void => {
  const { refresh } = useRefreshAuthTokenCallback();

  useEffect(() => {
    // Run immediately after init
    setTimeout(() => refresh(), 0);
    // Run every 10 seconds
    const interval = setInterval(refresh, 1000 * 10);

    return () => {
      // Stop refreshing on unmount
      clearInterval(interval);
    };
  }, [refresh]);
};

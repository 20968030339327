// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    Blinkers,
    BlinkersFromJSON,
    BlinkersToJSON,
    DeclaredEntry,
    DeclaredEntryFromJSON,
    DeclaredEntryToJSON,
    DeclaredEntryDetail,
    DeclaredEntryDetailFromJSON,
    DeclaredEntryDetailToJSON,
    Driver,
    DriverFromJSON,
    DriverToJSON,
    EmailResponseMessage,
    EmailResponseMessageFromJSON,
    EmailResponseMessageToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Exemption,
    ExemptionFromJSON,
    ExemptionToJSON,
    StartDeclaration,
    StartDeclarationFromJSON,
    StartDeclarationToJSON,
} from '../models';

export interface DeclareHorseForStartRequest {
    propositionId: number;
    trainerId: number;
    startDeclaration: StartDeclaration;
    xEndUserIp?: string;
}

export interface DeleteHorseDeclaredStartRequest {
    propositionId: number;
    horseId: number;
}

export interface GetDriversRidingWeightRequest {
    propositionId: number;
    driverId: number;
    propositionWeight: number;
}

export interface GetHorseDeclaredForStartRequest {
    trainerId: number;
}

export interface GetHorseStartDeclaredDetailRequest {
    propositionId: number;
    horseId: number;
}

export interface GetValidDriversRequest {
    propositionId: number;
}

export interface StartDeclaredDetailEmailReceiptRequest {
    propositionId: number;
    horseId: number;
}


/**
 * Declare a horse for a proposition with given driver, blinkers and exemptions.
 */
function declareHorseForStartRaw<T>(requestParameters: DeclareHorseForStartRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling declareHorseForStart.');
    }

    if (requestParameters.trainerId === null || requestParameters.trainerId === undefined) {
        throw new runtime.RequiredError('trainerId','Required parameter requestParameters.trainerId was null or undefined when calling declareHorseForStart.');
    }

    if (requestParameters.startDeclaration === null || requestParameters.startDeclaration === undefined) {
        throw new runtime.RequiredError('startDeclaration','Required parameter requestParameters.startDeclaration was null or undefined when calling declareHorseForStart.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters.xEndUserIp !== undefined && requestParameters.xEndUserIp !== null) {
        headerParameters['X-End-User-Ip'] = String(requestParameters.xEndUserIp);
    }


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/{propositionId}/startdeclarations/{trainerId}`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))).replace(`{${"trainerId"}}`, encodeURIComponent(String(requestParameters.trainerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || StartDeclarationToJSON(requestParameters.startDeclaration),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Declare a horse for a proposition with given driver, blinkers and exemptions.
*/
export function declareHorseForStart<T>(requestParameters: DeclareHorseForStartRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return declareHorseForStartRaw(requestParameters, requestConfig);
}

/**
 * Delete a horse declared for start in a open start declaration for a trainer.
 */
function deleteHorseDeclaredStartRaw<T>(requestParameters: DeleteHorseDeclaredStartRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling deleteHorseDeclaredStart.');
    }

    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling deleteHorseDeclaredStart.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/{propositionId}/startdeclarations/horses/{horseId}`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))).replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Delete a horse declared for start in a open start declaration for a trainer.
*/
export function deleteHorseDeclaredStart<T>(requestParameters: DeleteHorseDeclaredStartRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteHorseDeclaredStartRaw(requestParameters, requestConfig);
}

/**
 * Get list of blinkers choices for start declaration.
 */
function getBlinkersRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Array<Blinkers>> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/startdeclarations/choices/blinkers`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(BlinkersFromJSON), text);
    }

    return config;
}

/**
* Get list of blinkers choices for start declaration.
*/
export function getBlinkers<T>( requestConfig?: runtime.TypedQueryConfig<T, Array<Blinkers>>): QueryConfig<T> {
    return getBlinkersRaw( requestConfig);
}

/**
 * Get a driver\'s riding weight for a start declaration\'s proposition 
 */
function getDriversRidingWeightRaw<T>(requestParameters: GetDriversRidingWeightRequest, requestConfig: runtime.TypedQueryConfig<T, number> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling getDriversRidingWeight.');
    }

    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
        throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling getDriversRidingWeight.');
    }

    if (requestParameters.propositionWeight === null || requestParameters.propositionWeight === undefined) {
        throw new runtime.RequiredError('propositionWeight','Required parameter requestParameters.propositionWeight was null or undefined when calling getDriversRidingWeight.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/{propositionId}/startdeclarations/drivers/{driverId}/ridingweight/{propositionWeight}`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))).replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))).replace(`{${"propositionWeight"}}`, encodeURIComponent(String(requestParameters.propositionWeight))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        throw "OH NO";
    }

    return config;
}

/**
* Get a driver\'s riding weight for a start declaration\'s proposition 
*/
export function getDriversRidingWeight<T>(requestParameters: GetDriversRidingWeightRequest, requestConfig?: runtime.TypedQueryConfig<T, number>): QueryConfig<T> {
    return getDriversRidingWeightRaw(requestParameters, requestConfig);
}

/**
 * Get list of exemption choices for start declaration.
 */
function getExemptionsRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Array<Exemption>> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/startdeclarations/choices/exemptions`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(ExemptionFromJSON), text);
    }

    return config;
}

/**
* Get list of exemption choices for start declaration.
*/
export function getExemptions<T>( requestConfig?: runtime.TypedQueryConfig<T, Array<Exemption>>): QueryConfig<T> {
    return getExemptionsRaw( requestConfig);
}

/**
 * Fetch list of all horses declared for start for a trainer.
 */
function getHorseDeclaredForStartRaw<T>(requestParameters: GetHorseDeclaredForStartRequest, requestConfig: runtime.TypedQueryConfig<T, Array<DeclaredEntry>> = {}): QueryConfig<T> {
    if (requestParameters.trainerId === null || requestParameters.trainerId === undefined) {
        throw new runtime.RequiredError('trainerId','Required parameter requestParameters.trainerId was null or undefined when calling getHorseDeclaredForStart.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/startdeclarations/trainers/{trainerId}`.replace(`{${"trainerId"}}`, encodeURIComponent(String(requestParameters.trainerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(DeclaredEntryFromJSON), text);
    }

    return config;
}

/**
* Fetch list of all horses declared for start for a trainer.
*/
export function getHorseDeclaredForStart<T>(requestParameters: GetHorseDeclaredForStartRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<DeclaredEntry>>): QueryConfig<T> {
    return getHorseDeclaredForStartRaw(requestParameters, requestConfig);
}

/**
 * Information about a start declaration for a horse in a proposition, only for the trainer
 */
function getHorseStartDeclaredDetailRaw<T>(requestParameters: GetHorseStartDeclaredDetailRequest, requestConfig: runtime.TypedQueryConfig<T, DeclaredEntryDetail> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling getHorseStartDeclaredDetail.');
    }

    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling getHorseStartDeclaredDetail.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/{propositionId}/startdeclarations/horse/{horseId}`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))).replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(DeclaredEntryDetailFromJSON(body), text);
    }

    return config;
}

/**
* Information about a start declaration for a horse in a proposition, only for the trainer
*/
export function getHorseStartDeclaredDetail<T>(requestParameters: GetHorseStartDeclaredDetailRequest, requestConfig?: runtime.TypedQueryConfig<T, DeclaredEntryDetail>): QueryConfig<T> {
    return getHorseStartDeclaredDetailRaw(requestParameters, requestConfig);
}

/**
 * Get all drivers that are allowed to ride in the given proposition.
 */
function getValidDriversRaw<T>(requestParameters: GetValidDriversRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Driver>> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling getValidDrivers.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/{propositionId}/startdeclarations/validdrivers`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(DriverFromJSON), text);
    }

    return config;
}

/**
* Get all drivers that are allowed to ride in the given proposition.
*/
export function getValidDrivers<T>(requestParameters: GetValidDriversRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Driver>>): QueryConfig<T> {
    return getValidDriversRaw(requestParameters, requestConfig);
}

/**
 * Email receipt of Information about a start declaration to the trainer
 */
function startDeclaredDetailEmailReceiptRaw<T>(requestParameters: StartDeclaredDetailEmailReceiptRequest, requestConfig: runtime.TypedQueryConfig<T, EmailResponseMessage> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling startDeclaredDetailEmailReceipt.');
    }

    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling startDeclaredDetailEmailReceipt.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/{propositionId}/startdeclarations/horse/{horseId}/emailreceipt`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))).replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EmailResponseMessageFromJSON(body), text);
    }

    return config;
}

/**
* Email receipt of Information about a start declaration to the trainer
*/
export function startDeclaredDetailEmailReceipt<T>(requestParameters: StartDeclaredDetailEmailReceiptRequest, requestConfig?: runtime.TypedQueryConfig<T, EmailResponseMessage>): QueryConfig<T> {
    return startDeclaredDetailEmailReceiptRaw(requestParameters, requestConfig);
}


import { useGetHorse, useGetHorsePedigree } from '@apis';
import makeIterable from '@components/StudBook/makeIterable';
import { Pedigree } from '@generated/horses/src';
import { GetPedigreePedigreetreeEnum } from '@generated/horses/src/apis';
import Box, { Container } from '@primitives/Box';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import { Text } from '@primitives/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import logo from '../../components/PageHeader/logo.png';

interface AncestorBoxContentProps {
  firstColumn: boolean;
}

const columnFontSize = (index: number): string => {
  if (index === 0) {
    return 'lead';
  }
  if (index === 1) {
    return 'text';
  }
  if (index === 2) {
    return 'small';
  }
  if (index === 3) {
    return 'micro';
  }
  return 'nano';
};

/*
@media (orientation: landscape) {
    width: 410mm;
  }
  */

const PrintOther = styled.div`
  @media print {
    height: 100%;

    * {
      -webkit-print-color-adjust: exact;
    }

    @media (orientation: portrait) {
      width: 210mm;
    }

    @media (orientation: landscape) {
      width: 410mm;
    }
  }

  display: flex;
  flex-direction: column;
  font-family: lato;
`;

const PrintSafari = styled(PrintOther)`
  @media print {
    @media (orientation: landscape) {
      width: 210mm;
    }
  }
`;

const Logo = styled.div`
  width: 70px;
  height: 70px;
  background-color: ${props => props.theme.colors.white};
  background-image: url('${logo}');
  background-size: auto 50px;
  padding-right: ${props => props.theme.space[5]}px;
  background-position: 15px center;
  background-repeat: no-repeat;
`;

const Wrapper = styled.div`
  width: 100%;

  & > div {
    display: flex;
    height: 900px;
    width: 90%;

    min-width: 600px;
    border-bottom: 1px solid ${props => props.theme.colors.black};
    border-left: 1px solid ${props => props.theme.colors.black};
  }
`;

const Column = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  border-top: 1px solid ${props => props.theme.colors.black};
  border-right: 1px solid ${props => props.theme.colors.black};
`;

const AncestorBoxContent = styled.div<AncestorBoxContentProps>`
  height: 100%;
  width: 100%;
  font-size: ${props => props.theme.fontSizes.small}px;
  padding-left: ${props => props.theme.space[1]}px;
  align-items: center;
  display: flex;
`;

function Ancestors(data: Pedigree): JSX.Element {
  const columns = makeIterable(data, 'LARGE');

  return (
    <Wrapper>
      <div>
        {columns.map((column, cIndex) => (
          <Column key={cIndex}>
            {column.map((ancestor, rIndex) => {
              const fontSize = columnFontSize(cIndex);
              return (
                <Row key={rIndex}>
                  <AncestorBoxContent firstColumn={rIndex === 0}>
                    <Box width="100%">
                      <Box display="flex" flexWrap="nowrap">
                        <Text fontSize={fontSize}>
                          <b>{ancestor.name === '0' ? '' : ancestor.name}</b>{' '}
                          {ancestor.yearOfBirth}
                        </Text>
                      </Box>
                      {cIndex === 0 && (
                        <Text fontSize={fontSize}>
                          {ancestor.registrationNumber}
                        </Text>
                      )}
                    </Box>
                  </AncestorBoxContent>
                </Row>
              );
            })}
          </Column>
        ))}
      </div>
    </Wrapper>
  );
}

export default function PrintAncestorTree(): JSX.Element | null {
  const history = useHistory();
  const slug = history.location.pathname;
  slug.slice(0, slug.lastIndexOf('/') + 1);
  const horseId = parseInt(slug.slice(slug.lastIndexOf('/') + 1));

  const { data, loading, error, status } = useGetHorse(horseId);

  const {
    data: pedigreeData,
    loading: pedigreeLoading,
    error: pedigreeError,
    status: pedigreeStatus,
  } = useGetHorsePedigree(horseId, 'LARGE' as GetPedigreePedigreetreeEnum);

  if (loading) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  if (pedigreeLoading) {
    return <Loading />;
  }

  if (pedigreeStatus && pedigreeStatus !== 200) {
    return (
      <Error>
        ERROR! {pedigreeStatus} {pedigreeError}
      </Error>
    );
  }

  if (!data) {
    return null;
  }

  const PrintBox =
    navigator.userAgent.search('Safari') >= 0 &&
    navigator.userAgent.search('Chrome') < 0
      ? PrintSafari
      : PrintOther;

  return (
    <PrintBox>
      <Container width="100%">
        <Box
          mr={2}
          ml={2}
          border="1px black solid"
          display="flex"
          width="89%"
          minWidth="600px"
          height="80px"
        >
          <Box>
            <Logo />
          </Box>
          <Box>
            <Text pt={2} margin={0} fontSize="lead">
              <strong>{data.name}</strong> {data.registrationNumber}{' '}
              {data.color} {data.horseGender.text} f.{' '}
              {data.dateOfBirthDisplayValue.substring(0, 4)}
            </Text>
            <Text margin={0} fontSize="small">
              Uppfö. {data?.breeder?.name}. Max HCP: {data.maxHandicap}
            </Text>
            <Text margin={0} fontSize="small">
              {data.horseBreed.text.charAt(0).toUpperCase() +
                data.horseBreed.text.slice(1)}
            </Text>
          </Box>
          <Box
            width="15%"
            ml="auto"
            mt="auto"
            mb="auto"
            pl={1}
            border="1px black solid"
          >
            <Box display="flex" flexWrap="wrap">
              <Box>
                <Text fontSize="small">Far= </Text>
              </Box>
              <Box>
                <Text fontSize="small">Övre ruta</Text>
              </Box>
            </Box>
            <Box display="flex" flexWrap="wrap">
              <Box>
                <Text fontSize="small">Mor= </Text>
              </Box>
              <Box>
                <Text fontSize="small">Undre ruta</Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
      <Container pt={2}>
        <Box width="100%">{pedigreeData && Ancestors(pedigreeData)}</Box>
      </Container>
    </PrintBox>
  );
}

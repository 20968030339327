import { createRef, RefObject, useEffect } from 'react';

/**
 * Used to register a click outside of component.
 */
export default function useClickOutside(
  setClickOutside: (click: boolean) => void,
): RefObject<HTMLElement> {
  const ref = createRef<HTMLElement>();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      // "as Node" was added to make typescript happy.
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setClickOutside(true);
      } else {
        setClickOutside(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setClickOutside]);

  return ref;
}

import {
  getPropositionsByRaceDayId,
  Proposition,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Results = Proposition[] | undefined;

export interface Entities {
  propositionsByRaceDayId?: {
    [key: number]: Results;
  };
}

export default function useGetPropositionsByRaceDayId(
  raceDayId: number,
): ApiResponse<Results, Entities> {
  const query = getPropositionsByRaceDayId<Entities>(
    { raceDayId },
    {
      transform: responseBody => ({
        propositionsByRaceDayId: {
          [raceDayId]: responseBody,
        },
      }),
      force: true,
      update: {
        propositionsByRaceDayId: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Results =>
    state.entities?.propositionsByRaceDayId?.[raceDayId];

  return useApi<Results>({
    query,
    selector,
  });
}

import { base } from '@primitives/base';
import { Text } from '@primitives/Typography';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';

import { massScheme } from './variants';

interface StatProps {
  mass?: 'small' | 'medium' | 'large';
}

const Stat = styled.div<StatProps>`
  ${massScheme};
  color: ${props => props.theme.colors['deep-blue']};
  display: block;
`;

interface WrapperProps extends SpaceProps {
  center?: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  ${base()};
  padding: 12px;
  text-align: ${props => (props.center ? 'center' : 'left')};
`;

interface Props extends SpaceProps {
  mass?: 'small' | 'medium' | 'large';
  label: string;
  children: ReactNode;
  center?: boolean;
}

function Stats({
  mass,
  label,
  children,
  center,
  ...props
}: Props): JSX.Element {
  return (
    <Wrapper center={center} {...props}>
      <Text color="dim-gray" fontSize="notes" mb={1}>
        {label}
      </Text>
      <Stat mass={mass}>{children}</Stat>
    </Wrapper>
  );
}

export default Stats;

import { Entities } from '@apis';
import {
  deleteVatRegistrationOwnership,
  DeleteVatRegistrationOwnershipRequest,
  deleteVatRegistrationRepresentative,
  DeleteVatRegistrationRepresentativeRequest,
  insertVatRegistrationOwnership,
  InsertVatRegistrationOwnershipRequest,
  insertVatRegistrationRepresentative,
  InsertVatRegistrationRepresentativeRequest,
} from '@generated/sportactors/src';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../utils/types';
import { useApiMutateWithoutData } from '../utils/useApiMutate';

type DeleteArgs =
  | (DeleteVatRegistrationOwnershipRequest & { type: 'owner' })
  | (DeleteVatRegistrationRepresentativeRequest & { type: 'representative' });

export const useDeleteVatRegistration = (): MutationResponseWithoutData<
  Entities,
  DeleteArgs
> =>
  useApiMutateWithoutData({
    queryFunc: (args): QueryConfig =>
      args.type === 'owner'
        ? deleteVatRegistrationOwnership(args)
        : deleteVatRegistrationRepresentative(args),
    queryKey: `deleteVatRegistration`,
  });

type InsertArgs =
  | (InsertVatRegistrationOwnershipRequest & { type: 'owner' })
  | (InsertVatRegistrationRepresentativeRequest & { type: 'representative' });

export const useInsertVatRegistration = (): MutationResponseWithoutData<
  Entities,
  InsertArgs
> =>
  useApiMutateWithoutData({
    queryFunc: (args): QueryConfig =>
      args.type === 'owner'
        ? insertVatRegistrationOwnership(args)
        : insertVatRegistrationRepresentative(args),
    queryKey: `insertVatRegistration`,
  });

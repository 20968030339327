// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Offspring,
    OffspringFromJSON,
    OffspringToJSON,
    OffspringViewFilterValues,
    OffspringViewFilterValuesFromJSON,
    OffspringViewFilterValuesToJSON,
} from './';

/**
 * 
 * @export
 * @interface OffspringView
 */
export interface OffspringView  {
    /**
     * 
     * @type {number}
     * @memberof OffspringView
     */
    horseId: number;
    /**
     * 
     * @type {string}
     * @memberof OffspringView
     */
    classification: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringView
     */
    numberOfSwedishOffspring: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringView
     */
    numberOfStarts: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringView
     */
    numberOfWins: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringView
     */
    prizeMoney: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringView
     */
    winningRate: string;
    /**
     * The top 3 placement rate.
     * @type {string}
     * @memberof OffspringView
     */
    placementRate: string;
    /**
     * 
     * @type {string}
     * @memberof OffspringView
     */
    prizeMoneyOffspring: string;
    /**
     * 
     * @type {Array<Offspring>}
     * @memberof OffspringView
     */
    offspring: Array<Offspring>;
    /**
     * 
     * @type {OffspringViewFilterValues}
     * @memberof OffspringView
     */
    offspringViewFilterValues?: OffspringViewFilterValues;
}

export function OffspringViewFromJSON(json: any): OffspringView {
    return {
        'horseId': json['horseId'],
        'classification': json['classification'],
        'numberOfSwedishOffspring': json['numberOfSwedishOffspring'],
        'numberOfStarts': json['numberOfStarts'],
        'numberOfWins': json['numberOfWins'],
        'prizeMoney': json['prizeMoney'],
        'winningRate': json['winningRate'],
        'placementRate': json['placementRate'],
        'prizeMoneyOffspring': json['prizeMoneyOffspring'],
        'offspring': (json['offspring'] as Array<any>).map(OffspringFromJSON),
        'offspringViewFilterValues': !exists(json, 'offspringViewFilterValues') ? undefined : OffspringViewFilterValuesFromJSON(json['offspringViewFilterValues']),
    };
}

export function OffspringViewToJSON(value?: OffspringView): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'classification': value.classification,
        'numberOfSwedishOffspring': value.numberOfSwedishOffspring,
        'numberOfStarts': value.numberOfStarts,
        'numberOfWins': value.numberOfWins,
        'prizeMoney': value.prizeMoney,
        'winningRate': value.winningRate,
        'placementRate': value.placementRate,
        'prizeMoneyOffspring': value.prizeMoneyOffspring,
        'offspring': (value.offspring as Array<any>).map(OffspringToJSON),
        'offspringViewFilterValues': OffspringViewFilterValuesToJSON(value.offspringViewFilterValues),
    };
}



// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Proposition,
    PropositionFromJSON,
    PropositionToJSON,
    PropositionDetails,
    PropositionDetailsFromJSON,
    PropositionDetailsToJSON,
    TrainedHorse,
    TrainedHorseFromJSON,
    TrainedHorseToJSON,
} from '../models';

export interface GetAvailableHorsesForStartRequest {
    propositionId: number;
    trainerId: number;
}

export interface GetPropositionByRaceDayIdAndPropositionIdRequest {
    raceDayId: number;
    propositionId: number;
}

export interface GetPropositionsByRaceDayIdRequest {
    raceDayId: number;
}


/**
 * Fetch horses which can be registered for a start in a proposition.
 */
function getAvailableHorsesForStartRaw<T>(requestParameters: GetAvailableHorsesForStartRequest, requestConfig: runtime.TypedQueryConfig<T, Array<TrainedHorse>> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling getAvailableHorsesForStart.');
    }

    if (requestParameters.trainerId === null || requestParameters.trainerId === undefined) {
        throw new runtime.RequiredError('trainerId','Required parameter requestParameters.trainerId was null or undefined when calling getAvailableHorsesForStart.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/{propositionId}/availablehorses/{trainerId}`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))).replace(`{${"trainerId"}}`, encodeURIComponent(String(requestParameters.trainerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(TrainedHorseFromJSON), text);
    }

    return config;
}

/**
* Fetch horses which can be registered for a start in a proposition.
*/
export function getAvailableHorsesForStart<T>(requestParameters: GetAvailableHorsesForStartRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<TrainedHorse>>): QueryConfig<T> {
    return getAvailableHorsesForStartRaw(requestParameters, requestConfig);
}

/**
 * Fetch a proposition.
 */
function getPropositionByRaceDayIdAndPropositionIdRaw<T>(requestParameters: GetPropositionByRaceDayIdAndPropositionIdRequest, requestConfig: runtime.TypedQueryConfig<T, PropositionDetails> = {}): QueryConfig<T> {
    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling getPropositionByRaceDayIdAndPropositionId.');
    }

    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling getPropositionByRaceDayIdAndPropositionId.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/{raceDayId}/{propositionId}`.replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))).replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(PropositionDetailsFromJSON(body), text);
    }

    return config;
}

/**
* Fetch a proposition.
*/
export function getPropositionByRaceDayIdAndPropositionId<T>(requestParameters: GetPropositionByRaceDayIdAndPropositionIdRequest, requestConfig?: runtime.TypedQueryConfig<T, PropositionDetails>): QueryConfig<T> {
    return getPropositionByRaceDayIdAndPropositionIdRaw(requestParameters, requestConfig);
}

/**
 * Fetch a list of propositions for a race day.
 */
function getPropositionsByRaceDayIdRaw<T>(requestParameters: GetPropositionsByRaceDayIdRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Proposition>> = {}): QueryConfig<T> {
    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling getPropositionsByRaceDayId.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/{raceDayId}`.replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(PropositionFromJSON), text);
    }

    return config;
}

/**
* Fetch a list of propositions for a race day.
*/
export function getPropositionsByRaceDayId<T>(requestParameters: GetPropositionsByRaceDayIdRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Proposition>>): QueryConfig<T> {
    return getPropositionsByRaceDayIdRaw(requestParameters, requestConfig);
}


// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ActorChangeableInformation,
    ActorChangeableInformationFromJSON,
    ActorChangeableInformationToJSON,
    ActorResource,
    ActorResourceFromJSON,
    ActorResourceToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SearchResult,
    SearchResultFromJSON,
    SearchResultToJSON,
} from '../models';

export interface GetActorRequest {
    id: number;
    type: GetActorTypeEnum;
}

export interface RetrieveSportAppGetRaceDayAPIRequest {
    trackId: number;
    date: string;
}

export interface SearchSportInfoRequest {
    query: string;
    offset?: number;
    limit?: number;
    type?: SearchSportInfoTypeEnum;
    gender?: string;
    content?: boolean;
    facets?: boolean;
    onlyValid?: boolean;
    appMenu?: boolean;
    onlyRiders?: boolean;
}

export interface UpdateActorRequest {
    id: number;
    type: UpdateActorTypeEnum;
    actorChangeableInformation: ActorChangeableInformation;
}


/**
 * Get actor profile
 */
function getActorRaw<T>(requestParameters: GetActorRequest, requestConfig: runtime.TypedQueryConfig<T, ActorResource> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getActor.');
    }

    if (requestParameters.type === null || requestParameters.type === undefined) {
        throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getActor.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/api/get_actor/{type}/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ActorResourceFromJSON(body), text);
    }

    return config;
}

/**
* Get actor profile
*/
export function getActor<T>(requestParameters: GetActorRequest, requestConfig?: runtime.TypedQueryConfig<T, ActorResource>): QueryConfig<T> {
    return getActorRaw(requestParameters, requestConfig);
}

/**
 * Get race day information and dress images
 */
function retrieveSportAppGetRaceDayAPIRaw<T>(requestParameters: RetrieveSportAppGetRaceDayAPIRequest, requestConfig: runtime.TypedQueryConfig<T, any> = {}): QueryConfig<T> {
    if (requestParameters.trackId === null || requestParameters.trackId === undefined) {
        throw new runtime.RequiredError('trackId','Required parameter requestParameters.trackId was null or undefined when calling retrieveSportAppGetRaceDayAPI.');
    }

    if (requestParameters.date === null || requestParameters.date === undefined) {
        throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling retrieveSportAppGetRaceDayAPI.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/api/get_race_day/{track_id}/{date}/`.replace(`{${"track_id"}}`, encodeURIComponent(String(requestParameters.trackId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        throw "OH NO";
    }

    return config;
}

/**
* Get race day information and dress images
*/
export function retrieveSportAppGetRaceDayAPI<T>(requestParameters: RetrieveSportAppGetRaceDayAPIRequest, requestConfig?: runtime.TypedQueryConfig<T, any>): QueryConfig<T> {
    return retrieveSportAppGetRaceDayAPIRaw(requestParameters, requestConfig);
}

/**
 * Search sportinfo
 */
function searchSportInfoRaw<T>(requestParameters: SearchSportInfoRequest, requestConfig: runtime.TypedQueryConfig<T, SearchResult> = {}): QueryConfig<T> {
    if (requestParameters.query === null || requestParameters.query === undefined) {
        throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling searchSportInfo.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.query !== undefined) {
        queryParameters['query'] = requestParameters.query;
    }


    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }


    if (requestParameters.limit !== undefined) {
        queryParameters['limit'] = requestParameters.limit;
    }


    if (requestParameters.type !== undefined) {
        queryParameters['type'] = requestParameters.type;
    }


    if (requestParameters.content !== undefined) {
        queryParameters['content'] = requestParameters.content;
    }


    if (requestParameters.facets !== undefined) {
        queryParameters['facets'] = requestParameters.facets;
    }


    if (requestParameters.onlyValid !== undefined) {
        queryParameters['onlyValid'] = requestParameters.onlyValid;
    }


    if (requestParameters.appMenu !== undefined) {
        queryParameters['appMenu'] = requestParameters.appMenu;
    }


    if (requestParameters.onlyRiders !== undefined) {
        queryParameters['onlyRiders'] = requestParameters.onlyRiders;
    }

    if (requestParameters.gender !== undefined) {
        queryParameters['gender'] = requestParameters.gender;
    }


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/api/search/`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SearchResultFromJSON(body), text);
    }

    return config;
}

/**
* Search sportinfo
*/
export function searchSportInfo<T>(requestParameters: SearchSportInfoRequest, requestConfig?: runtime.TypedQueryConfig<T, SearchResult>): QueryConfig<T> {
    return searchSportInfoRaw(requestParameters, requestConfig);
}

/**
 * Update actor profile with provided parameters
 */
function updateActorRaw<T>(requestParameters: UpdateActorRequest, requestConfig: runtime.TypedQueryConfig<T, ActorResource> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateActor.');
    }

    if (requestParameters.type === null || requestParameters.type === undefined) {
        throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling updateActor.');
    }

    if (requestParameters.actorChangeableInformation === null || requestParameters.actorChangeableInformation === undefined) {
        throw new runtime.RequiredError('actorChangeableInformation','Required parameter requestParameters.actorChangeableInformation was null or undefined when calling updateActor.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/api/update_actor/{type}/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || ActorChangeableInformationToJSON(requestParameters.actorChangeableInformation),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ActorResourceFromJSON(body), text);
    }

    return config;
}

/**
* Update actor profile with provided parameters
*/
export function updateActor<T>(requestParameters: UpdateActorRequest, requestConfig?: runtime.TypedQueryConfig<T, ActorResource>): QueryConfig<T> {
    return updateActorRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum GetActorTypeEnum {
    Horse = 'horse',
    Rider = 'rider',
    Trainer = 'trainer',
    Owner = 'owner',
    Breeder = 'breeder'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchSportInfoTypeEnum {
    LicenseHolder = 'license_holder',
    Horse = 'horse',
    Breeder = 'breeder',
    Owner = 'owner'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateActorTypeEnum {
    Rider = 'rider',
    Trainer = 'trainer',
    Owner = 'owner',
    Breeder = 'breeder'
}

import { useDeleteStartMonitoring, useGetStartMonitorings } from '@apis';
import DataGrid from '@components/DataGrid';
import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import { StartMonitoring as SM } from '@generated/user/src';
import withUser from '@hoc/withUser';
import useIsMobile from '@hooks/useIsMobile';
import { Flex } from '@primitives/Box';
import { dataGridRemoveButtonMixin, RemoveButton } from '@primitives/Button';
import Error from '@primitives/Error';
import Icon from '@primitives/Icon';
import { Link } from '@primitives/Link';
import List from '@primitives/List';
import { Small } from '@primitives/Typography';
import { Flag, MagnifyingGlass as Search } from '@styled-icons/fa-solid';
import { formatTz } from '@utils/dateUtils';
import { horse, startlist } from '@utils/links';
import React from 'react';
import { CellProps } from 'react-table';

interface Props {
  user: AuthenticatedUser;
  simpleList?: boolean;
}

export function StartMonitoringResults({
  user,
  simpleList = false,
}: Props): JSX.Element {
  const { data, status, error, refresh } = useGetStartMonitorings(
    user.licenseId,
  );

  const { action: remove } = useDeleteStartMonitoring(user.licenseId);

  const isMobile = useIsMobile();

  if (status && status !== 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  if (simpleList) {
    return data?.length ? (
      <List itemGap={1}>
        {data.slice(0, 5).map((item, i) => (
          <li key={i}>
            <Icon as={Flag} color="yellow" size={14} mr={2}></Icon>{' '}
            <Link
              color="gray6"
              to={horse(item.horseId)}
              sx={{
                textDecoration: 'underline',
              }}
            >
              {item.horseName}
            </Link>
          </li>
        ))}
      </List>
    ) : (
      <Small color="dim-gray">Du har inga startbevakningar.</Small>
    );
  }

  return (
    <DataGrid
      title={`Du har ${data?.length || 0} startbevakningar`}
      noContent={{
        content: (
          <>
            <p>Du har inga startbevakningar</p>
          </>
        ),
      }}
      columns={[
        {
          Header: 'Häst',
          accessor: 'horseName',
          Cell: ({ row: { original: row }, value }: CellProps<SM, string>) => (
            <Flex justifyContent="flex-start" alignItems="flex-start">
              <Icon as={Flag} color="yellow" size={14} mr={2}></Icon>
              <div>
                <Link
                  color="gray6"
                  fontWeight="bold"
                  to={horse(row.horseId)}
                  sx={{
                    textDecoration: 'underline',
                  }}
                >
                  {value}
                </Link>
                {isMobile && row.hasActiveCompetition && (
                  <>
                    <br />
                    <Small>
                      <Link
                        mt={1}
                        color="gray5"
                        fontSize="small"
                        to={startlist(row.raceDayId, `/${row.raceNumber}`)}
                      >
                        {`${row.trackCode} ${formatTz(
                          row.raceDate,
                          'yyMMdd',
                        )}-${row.raceNumber}`}
                      </Link>
                    </Small>
                  </>
                )}
              </div>
            </Flex>
          ),
        },
        {
          Header: 'Nästa start',
          accessor: 'raceDate',
          hideMobile: true,
          Cell: ({ row: { original: row }, value }: CellProps<SM, Date>) =>
            row.hasActiveCompetition ? (
              <Link to={startlist(row.raceDayId, `/${row.raceNumber}`)}>
                {`${row.trackCode} ${formatTz(value, 'yyMMdd')}-${
                  row.raceNumber
                }`}
              </Link>
            ) : (
              <span>{'-'}</span>
            ),
        },
        {
          Header: 'Ta bort',
          id: 'delete',
          accessor: 'horseId',

          Cell: ({ value }: CellProps<SM, number>) => (
            <RemoveButton
              onClick={async () => {
                await remove(value);
                await refresh();
              }}
            />
          ),
          ...dataGridRemoveButtonMixin,
        },
      ]}
      data={data || []}
      noHover
    />
  );
}

function StartMonitoring({ user }: Props): JSX.Element {
  return (
    <PageLayout headline="Startbevakningar" singleCard>
      <StartMonitoringResults user={user} />
      <Flex justifyContent="flex-end" pt={3}>
        <Link to="/sportinfo/hastar">
          Sök häst för ny startbevakning <Search size={12} />
        </Link>
      </Flex>
    </PageLayout>
  );
}

export default withUser(StartMonitoring);

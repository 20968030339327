// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface EnrollmentRequest
 */
export interface EnrollmentRequest  {
    /**
     * 
     * @type {number}
     * @memberof EnrollmentRequest
     */
    raceDayId: number;
    /**
     * The horses to enroll for each propositionId, all propositions must belong to the race day id provided
     * @type {{ [key: string]: Array<number> | undefined; }}
     * @memberof EnrollmentRequest
     */
    horsesByProposition: { [key: string]: Array<number> | undefined; };
}

export function EnrollmentRequestFromJSON(json: any): EnrollmentRequest {
    return {
        'raceDayId': json['raceDayId'],
        'horsesByProposition': json['horsesByProposition'],
    };
}

export function EnrollmentRequestToJSON(value?: EnrollmentRequest): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'raceDayId': value.raceDayId,
        'horsesByProposition': value.horsesByProposition,
    };
}



import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import Box from '@primitives/Box';
import { OWNER_ROLES } from '@utils/constants';
import isRepresentative from '@utils/isRepresentative';
import React from 'react';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import InvoiceDetails from './InvoiceDetails';
import InvoicedTransactions from './InvoicedTransactions';
import Invoices from './Invoices';
import UninvoicedTransactions from './UninvoicedTransactions';

interface Props {
  user: AuthenticatedUser;
}

const InvoicesAndTransactions = ({ user }: Props): JSX.Element => {
  const { licenseId } = user;
  const { path } = useRouteMatch();

  const noTab = generatePath(path);

  const invoicesPath = generatePath(path, {
    tab: 'fakturor',
  });

  const invoicedTransactionsPath = generatePath(path, {
    tab: 'fakturerade-transaktioner',
  });

  const uninvoicedTransactionsPath = generatePath(path, {
    tab: 'ej-fakturerade-transaktioner',
  });

  const isRepr = isRepresentative(user);

  return (
    <PageLayout
      headline="Fakturor och transaktioner"
      singleCard
      tabs={[
        ...(isRepr
          ? [
              {
                to: invoicesPath,
                text: 'Fakturor',
              },
            ]
          : []),
        {
          to: invoicedTransactionsPath,
          text: 'Fakturerade transaktioner',
        },
        {
          to: uninvoicedTransactionsPath,
          text: 'Ej fakturerade transaktioner',
        },
      ]}
    >
      <Box mx={[-4, , 0]} mt={[-4, , 0]}>
        <Switch>
          {isRepr && (
            <Route path={`${invoicesPath}/:documentId`}>
              <InvoiceDetails
                licenseId={licenseId}
                invoicesPath={invoicesPath}
              />
            </Route>
          )}
          {isRepr && (
            <Route path={invoicesPath}>
              <Invoices licenseId={licenseId} />
            </Route>
          )}
          <Route path={`${invoicedTransactionsPath}/:year?/:month?`}>
            <InvoicedTransactions licenseId={licenseId} />
          </Route>
          <Route path={uninvoicedTransactionsPath}>
            <UninvoicedTransactions licenseId={licenseId} />
          </Route>
          <Route path={noTab}>
            <Redirect to={isRepr ? invoicesPath : invoicedTransactionsPath} />
          </Route>
        </Switch>
      </Box>
    </PageLayout>
  );
};

export default withUser(InvoicesAndTransactions, {
  roles: OWNER_ROLES,
});

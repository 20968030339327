// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Bucket,
    BucketFromJSON,
    BucketToJSON,
} from './';

/**
 * 
 * @export
 * @interface FacetItem
 */
export interface FacetItem  {
    /**
     * 
     * @type {string}
     * @memberof FacetItem
     */
    name: string;
    /**
     * 
     * @type {Array<Bucket>}
     * @memberof FacetItem
     */
    buckets: Array<Bucket>;
}

export function FacetItemFromJSON(json: any): FacetItem {
    return {
        'name': json['name'],
        'buckets': (json['buckets'] as Array<any>).map(BucketFromJSON),
    };
}

export function FacetItemToJSON(value?: FacetItem): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'buckets': (value.buckets as Array<any>).map(BucketToJSON),
    };
}



import { getLatestStartList } from '@generated/raceinfo/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  latestStartList?: number;
}

export default function useGetLatestStartListRaceDay(): ApiResponse<
  number,
  Entities
> {
  const query = {
    ...getLatestStartList<Entities>({
      update: {
        latestStartList: (prev, next) => next,
      },
    }),
    transform: body => ({
      latestStartList: body,
    }),
  };

  const selector = (state: State): number | undefined =>
    state.entities?.latestStartList;

  return useApi<number>({
    query,
    selector,
  });
}

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    VatRegistration,
    VatRegistrationFromJSON,
    VatRegistrationToJSON,
} from './';

/**
 * 
 * @export
 * @interface VatRegistrations
 */
export interface VatRegistrations  {
    /**
     * 
     * @type {Array<VatRegistration>}
     * @memberof VatRegistrations
     */
    vatRegistrationRepresentatives: Array<VatRegistration>;
    /**
     * 
     * @type {Array<VatRegistration>}
     * @memberof VatRegistrations
     */
    vatRegistrationOwnerships: Array<VatRegistration>;
}

export function VatRegistrationsFromJSON(json: any): VatRegistrations {
    return {
        'vatRegistrationRepresentatives': (json['vatRegistrationRepresentatives'] as Array<any>).map(VatRegistrationFromJSON),
        'vatRegistrationOwnerships': (json['vatRegistrationOwnerships'] as Array<any>).map(VatRegistrationFromJSON),
    };
}

export function VatRegistrationsToJSON(value?: VatRegistrations): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'vatRegistrationRepresentatives': (value.vatRegistrationRepresentatives as Array<any>).map(VatRegistrationToJSON),
        'vatRegistrationOwnerships': (value.vatRegistrationOwnerships as Array<any>).map(VatRegistrationToJSON),
    };
}



import { useContent } from '@apis';
import Content from '@components/ContentBlock/Content';
import theme from '@main/theme';
import Box from '@primitives/Box';
import Icon from '@primitives/Icon';
import { FilePdf } from '@styled-icons/fa-solid';
import React from 'react';
import styled from 'styled-components';

type BoxParameters = Parameters<typeof Box>;

const PdfLink = styled.a<{ usedFontSize: string }>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: ${props => props.usedFontSize};
`;

type Props = {
  slug: string;
  fontSize?: number;
  iconSize?: number;
  iconMB?: number;
  alignContent?: 'r' | 'm' | 'l';
} & BoxParameters[0];

function PDFLinkWithIconFromExternalSlugg({
  slug,
  fontSize,
  iconSize,
  iconMB = 0,
  alignContent,
  ...props
}: Props): JSX.Element {
  const { data: pdf } = useContent(slug);

  const chooseIconFontSize = (): {
    chosingFontSize: string;
    chosingIconSize: number | string;
  } => {
    let chosingFontSize: string;
    let chosingIconSize: number | string;

    if (fontSize) {
      if (fontSize >= 0) {
        chosingFontSize = `${fontSize}px`;
        chosingIconSize = fontSize + 1;
      } else {
        chosingFontSize = 'inherit';
        chosingIconSize = 'inherit';
      }
    } else {
      chosingFontSize = `${theme.fontSizes.small}px`;
      chosingIconSize = theme.fontSizes.small + 1;
    }

    if (iconSize && iconSize >= 0) {
      chosingIconSize = iconSize;
    }
    return {
      chosingFontSize: chosingFontSize,
      chosingIconSize: chosingIconSize,
    };
  };

  const { chosingFontSize: usedFontSize, chosingIconSize: usedIconSize } =
    chooseIconFontSize();

  return pdf?.document?.url ? (
    <Box
      {...props}
      sx={
        alignContent === 'l'
          ? {
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gridTemplateRows: '1fr',
            }
          : alignContent === 'm'
            ? {
                display: 'grid',
                gridTemplateColumns: '1fr auto 1fr',
                gridTemplateRows: '1fr',
              }
            : alignContent === 'r'
              ? {
                  display: 'grid',
                  gridTemplateColumns: '1fr auto',
                  gridTemplateRows: '1fr',
                }
              : undefined
      }
    >
      <Box
        sx={
          alignContent && {
            gridArea: '1 / 2 / 4 / 3',
          }
        }
      >
        <PdfLink
          usedFontSize={usedFontSize}
          href={`/storage/external?url=${encodeURI(pdf.document.url)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Content slug={slug} />
          <Icon
            size={usedIconSize}
            color="blue"
            ml={1}
            mb={iconMB}
            as={FilePdf}
          />
        </PdfLink>
      </Box>
    </Box>
  ) : null;
}
export default PDFLinkWithIconFromExternalSlugg;

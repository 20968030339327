import { Favorite, getFavorites } from '@generated/user/src';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = Favorite[] | undefined;

export interface Entities {
  favorites?: { [key: string]: Result };
}

const useGetFavorites = (licenseId: number): ApiResponse<Result, Entities> =>
  useApi({
    query: getFavorites<Entities>(
      {
        licenseId,
      },
      {
        transform: responseBody => ({
          favorites: { [licenseId]: responseBody },
        }),
        update: {
          favorites: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities.favorites?.[licenseId],
  });

export default useGetFavorites;

import { useGetHorse, useGetHorsePedigree } from '@apis';
import HorseInfoBase from '@components/HorseInfo';
import { GetPedigreePedigreetreeEnum } from '@generated/horses/src';
import Box from '@primitives/Box';
import { ButtonLink } from '@primitives/Button';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { FormField } from './definitions/_fields';

type Props = {
  def: FormField;
  horseId: number;
  onReset: () => void;
};

export function HorseInfo({ def, horseId, onReset }: Props): JSX.Element {
  const { setFieldValue } = useFormikContext();

  const { data: horseData, loading: loadingHorse } = useGetHorse(horseId ?? 0);
  const { data: pedigree, loading: loadingPedigree } = useGetHorsePedigree(
    horseId ?? 0,
    GetPedigreePedigreetreeEnum.SMALL,
  );

  useEffect(() => {
    if (horseId && def.name !== 'stallionInfo') {
      setFieldValue('trainer', horseData?.trainer);
    }
  }, [def.name, horseData, horseId, setFieldValue]);

  if (loadingHorse || !horseId || loadingPedigree) {
    return null;
  }

  const details = [];
  details.push({ label: 'Tränare', value: horseData?.trainer?.name });
  details.push({ label: 'Ägare', value: horseData?.owner?.name });
  details.push({ label: 'Far', value: pedigree?.father?.name });
  details.push({ label: 'Mor', value: pedigree?.mother?.name });

  return (
    <Box key={def.name} width={'100%'} sx={{ gridColumn: 'span 3' }}>
      <HorseInfoBase
        name={horseData?.name}
        text={`${horseData?.registrationNumber}, ${horseData?.horseGender?.text}, ${horseData?.dateOfBirthDisplayValue}`}
        details={details}
      />
      <ButtonLink
        sx={{ float: 'right' }}
        onClick={() => {
          if (def.name === 'horseInfo' || def.name === 'horseSearchInfo') {
            onReset();
            setFieldValue('trainer', undefined);
            setFieldValue('horse', null);
          } else if (def.name === 'mareInfo') {
            onReset();
            setFieldValue('mare', null);
          } else if (def.name === 'stallionInfo') {
            onReset();
            setFieldValue('stallion', null);
          }
        }}
      >
        Byt häst
      </ButtonLink>
    </Box>
  );
}

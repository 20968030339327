import {
  getSuspensionStatus,
  SuspensionStatus,
} from '@generated/licenseholders/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = SuspensionStatus | undefined;

export interface Entities {
  suspensionStatus?: {
    [id: number]: Result;
  };
}

const getQuery = (id: number): QueryConfig<Entities> =>
  getSuspensionStatus<Entities>(
    {
      licenseId: id,
    },
    {
      transform: responseBody => ({
        suspensionStatus: { [id]: responseBody },
      }),
      update: {
        suspensionStatus: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetSuspensionStatus = (id: number): ApiResponse<Result, Entities> => {
  const query = getQuery(id);
  const selector = (state: State): Result =>
    state.entities?.suspensionStatus?.[id];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetSuspensionStatus;

import { useGetOngoingTransfers } from '@apis';
import FadedContent from '@components/ContentBlock/Faded';
import InfoBadge from '@components/InfoBadge';
import { AuthenticatedUser } from '@generated/account/src';
import { OwnershipTransfer } from '@generated/ownership-transfers/src';
import useQuery from '@hooks/useQuery';
import { useOnWindowFocus } from '@hooks/useWindowFocus';
import Box, { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import { Link } from '@primitives/Link';
import Pulse from '@primitives/Loading';
import Message from '@primitives/Message';
import { Text } from '@primitives/Typography';
import {
  CircleCheck as CheckCircle,
  CircleInfo as InfoCircle,
  FileSignature,
  TriangleExclamation as ExclamationTriangle,
} from '@styled-icons/fa-solid';
import { sortByStatusAndExpiryDate } from '@utils/ownershipTransferSorting';
import { differenceInCalendarDays } from 'date-fns';
import React, { ReactNode } from 'react';

import { OngoingHorseInfo } from '../TransferHorseInfo';

interface Props {
  user: AuthenticatedUser;
}

interface TransferItemProps {
  item: OwnershipTransfer;
  duplicate?: OwnershipTransfer;
}

const signatoryRoleEnumMap = new Map(
  Object.entries({
    BUYER_REPRESENTATIVE: 'företrädare för köparen',
    SELLER_REPRESENTATIVE: 'företrädare för säljaren',
    BUYER_PART_OWNER: 'delägare, köparen',
    //OTHER: 'Annat'
  }),
);

const Action = ({ item, duplicate }: TransferItemProps): JSX.Element => {
  if (
    item.status.statusCode === 'INIT' &&
    (item.editable || duplicate?.editable)
  ) {
    return (
      <Button
        width={[1, , 'auto']}
        as={Link}
        to={`/minasidor/agarskiften/pagande/${item.id}`}
      >
        Ange hästens ägarskap
      </Button>
    );
  }

  if (item.status.statusCode === 'AKTIV' && item.contract) {
    return (
      <Flex flexDirection="column" verticalSpacing={3} alignItems="flex-start">
        {item.contract.signatories
          .filter(v => v.status !== 'SIGNING_NOT_NEEDED')
          .map((v, i) => (
            <Button
              key={i}
              as={v.status !== 'SIGNED' ? 'a' : undefined}
              target="_blank"
              rel="nofollow"
              href={v.contractLink}
              colorScheme="save"
              icon={v.status === 'SIGNED' ? CheckCircle : FileSignature}
              disabled={v.status === 'SIGNED'}
              type={v.status === 'SIGNED' ? 'button' : 'link'}
            >
              {v.status === 'SIGNED' ? (
                <>
                  Avtal signerat
                  {signatoryRoleEnumMap.has(v.role)
                    ? ` - ${signatoryRoleEnumMap.get(v.role)}`
                    : ''}
                </>
              ) : (
                <>
                  Signera avtal
                  {signatoryRoleEnumMap.has(v.role)
                    ? ` - ${signatoryRoleEnumMap.get(v.role)}`
                    : ''}
                </>
              )}
            </Button>
          ))}
      </Flex>
    );
  }

  return null;
};

const StatusMessage = ({
  item,
  duplicate,
}: TransferItemProps): JSX.Element | null => {
  const isBothRoles = duplicate != null;

  let info: ReactNode = '';

  if (item.status.statusCode == 'MAKKOP') {
    info = (
      <>
        Ägarskiftet har avvisats av köparen.
        {item.comment && (
          <>
            {' '}
            <b>Kommentar:</b> {item.comment}
          </>
        )}
      </>
    );
  } else if (item.status.statusCode == 'MAKST') {
    info = (
      <>
        Ägarskiftet har avvisats av Svensk Galopp.
        {item.comment && (
          <>
            {' '}
            <b>Kommentar:</b> {item.comment}
          </>
        )}
      </>
    );
  } else if (item.status.statusCode == 'MAKSELJ') {
    info = 'Ägarskiftet har avvisats av säljaren.';
  } else if (item.status.statusCode == 'MAKTID') {
    info = 'Ägarskiftet har avbrutits eftersom det inte slutfördes i tid.';
  } else if (item.status.statusCode == 'WEBKLAR') {
    info = 'Ärendet handläggs hos Svensk Galopp.';
  } else if (item.status.statusCode == 'GENOMFORD') {
    info = 'Ägarskiftet är genomfört.';
  } else if (isBothRoles && item.status.statusCode === 'INIT') {
    info = `Du är säljare och köpare av hästen.`;
  } else if (item.role === 'SELLER' && item.status.statusCode === 'INIT') {
    info = `Du är säljare av hästen. ${item.status.statusText}`;
  } else if (item.role === 'BUYER' && item.editable) {
    info = `Du är företrädare för köparen.`;
  } else if (item.status.statusCode === 'AKTIV' && item.contract) {
    info = `Signering återstår, ${item.contract.signatures.signedPersonsCount} av ${item.contract.signatures.totalSignatories} har signerat.`;
    if (
      item.contract.signatories.every(v => v.status === 'SIGNING_NOT_NEEDED')
    ) {
      info += ' Du behöver inte signera avtalet.';
    }
  } else {
    info = item.status.statusText;
  }

  return info ? (
    <InfoBadge message={info} color="blue" icon={InfoCircle} />
  ) : null;
};

const TransferItem = ({ item, duplicate }: TransferItemProps): JSX.Element => {
  const daysToDue = differenceInCalendarDays(item.expiryDate, Date.now());
  return (
    <Box verticalSpacing={3}>
      <OngoingHorseInfo item={item} />
      {['AKTIV', 'INIT'].includes(item.status.statusCode) &&
        daysToDue <= 20 && (
          <InfoBadge
            message={`Registreringen av ägarskiftet måste slutföras inom ${daysToDue} dagar`}
            color="red"
            icon={ExclamationTriangle}
          />
        )}

      {['AKTIV', 'INIT'].includes(item.status.statusCode) &&
        item.horse.inStartList && (
          <InfoBadge
            message={`Observera att ägarskap måste ha hästägardress när hästen ska starta.`}
            color="red"
            icon={ExclamationTriangle}
          />
        )}

      <StatusMessage item={item} duplicate={duplicate} />

      <Action item={item} duplicate={duplicate} />
    </Box>
  );
};

const OngoingTransfers = ({ user }: Props): JSX.Element => {
  const { data, loading, error, refresh } = useGetOngoingTransfers(
    user.licenseId,
  );

  const { message } = useQuery<{ message?: string }>(true);

  useOnWindowFocus(() => {
    refresh();
  }, []);

  if (loading && !data) {
    return <Pulse />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  const items = data.map((value, index, self) => ({
    duplicate: self.findIndex(v => v.id === value.id) !== index,
    ...value,
  }));

  return (
    <Box verticalSpacing={3} my={4}>
      {message === 'init' && (
        <Message variant="successBox">
          Registreringen av ägarskiftet har påbörjats. Kontakta företrädaren för
          köparen för att slutföra ägarskiftet. Du kan alltid följa processen
          under rubriken Pågående ägarskiften.
        </Message>
      )}

      {message === 'signing' && (
        <Message variant="successBox">
          Ägarskiftet har skickats till signering. Alla parter måste signera
          inom 21 dagar, annars förfaller avtalet. Du kan alltid följa processen
          under rubriken Pågående ägarskiften.
        </Message>
      )}

      {message === 'cancel' && (
        <Message variant="successBox">
          Ägarskiftet har avvisats, kontakta säljaren av hästen.
        </Message>
      )}

      {message === 'save' && (
        <Message variant="successBox">
          Ägarskiftet är sparat men inte skickat till signering. Det sparas i 21
          dagar från att säljaren har startat ägarskiftet. Klicka på Ange
          hästens ägarskap för att fortsätta registreringen av ägarskiftet.
        </Message>
      )}

      <FadedContent slug="agarskiften-pagaende-agarskiften" />

      {items
        .filter(item => !item.duplicate)
        .sort(sortByStatusAndExpiryDate)
        .map(item => (
          <React.Fragment key={item.id}>
            <TransferItem
              item={item}
              duplicate={items.find(i => i.duplicate && i.id === item.id)}
            />
            <Box as="hr" my="5" />
          </React.Fragment>
        ))}

      {items.length === 0 && <Text>Du har inga ägarskiften att hantera.</Text>}
    </Box>
  );
};

export default OngoingTransfers;

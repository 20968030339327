// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    PropositionTextRow,
    PropositionTextRowFromJSON,
    PropositionTextRowToJSON,
    RaceRaceType,
    RaceRaceTypeFromJSON,
    RaceRaceTypeToJSON,
    StartListHorse,
    StartListHorseFromJSON,
    StartListHorseToJSON,
} from './';

/**
 * 
 * @export
 * @interface Race
 */
export interface Race  {
    /**
     * 
     * @type {number}
     * @memberof Race
     */
    raceNumber: number;
    /**
     * 
     * @type {number}
     * @memberof Race
     */
    raceId: number;
    /**
     * 
     * @type {number}
     * @memberof Race
     */
    propositionId: number;
    /**
     * 
     * @type {number}
     * @memberof Race
     */
    distance: number;
    /**
     * 
     * @type {string}
     * @memberof Race
     */
    trackCondition: string;
    /**
     * 
     * @type {RaceRaceType}
     * @memberof Race
     */
    raceType: RaceRaceType;
    /**
     * 
     * @type {string}
     * @memberof Race
     */
    raceCharacter?: string;
    /**
     * 
     * @type {string}
     * @memberof Race
     */
    raceCharacterText?: string;
    /**
     * 
     * @type {Date}
     * @memberof Race
     */
    startDateTime?: Date;
    /**
     * 
     * @type {Array<PropositionTextRow>}
     * @memberof Race
     */
    propositionTextRow: Array<PropositionTextRow>;
    /**
     * 
     * @type {Array<StartListHorse>}
     * @memberof Race
     */
    horses: Array<StartListHorse>;
    /**
     * 
     * @type {boolean}
     * @memberof Race
     */
    resultReady?: boolean;
}

export function RaceFromJSON(json: any): Race {
    return {
        'raceNumber': json['raceNumber'],
        'raceId': json['raceId'],
        'propositionId': json['propositionId'],
        'distance': json['distance'],
        'trackCondition': json['trackCondition'],
        'raceType': RaceRaceTypeFromJSON(json['raceType']),
        'raceCharacter': !exists(json, 'raceCharacter') ? undefined : json['raceCharacter'],
        'raceCharacterText': !exists(json, 'raceCharacterText') ? undefined : json['raceCharacterText'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : parseDateTime(json['startDateTime']),
        'propositionTextRow': (json['propositionTextRow'] as Array<any>).map(PropositionTextRowFromJSON),
        'horses': (json['horses'] as Array<any>).map(StartListHorseFromJSON),
        'resultReady': !exists(json, 'resultReady') ? undefined : json['resultReady'],
    };
}

export function RaceToJSON(value?: Race): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'raceNumber': value.raceNumber,
        'raceId': value.raceId,
        'propositionId': value.propositionId,
        'distance': value.distance,
        'trackCondition': value.trackCondition,
        'raceType': RaceRaceTypeToJSON(value.raceType),
        'raceCharacter': value.raceCharacter,
        'raceCharacterText': value.raceCharacterText,
        'startDateTime': value.startDateTime === undefined ? undefined : value.startDateTime.toISOString(),
        'propositionTextRow': (value.propositionTextRow as Array<any>).map(PropositionTextRowToJSON),
        'horses': (value.horses as Array<any>).map(StartListHorseToJSON),
        'resultReady': value.resultReady,
    };
}



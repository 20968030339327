import { generateInvoicePdfLink, OTTLink } from '@generated/sportactors/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

export interface Entities {
  generateInvoicePdfLink?: OTTLink;
}

const useGenerateInvoicePdfLink = (
  ownerId: number,
  invoiceNumber: number,
  index: number,
): MutationResponse<Entities['generateInvoicePdfLink'], Entities, unknown> => {
  const key = `generateInvoicePdfLink-${ownerId}-${invoiceNumber}-${index}`;
  const queryFunc = (): QueryConfig<Entities> =>
    generateInvoicePdfLink({
      ownerId,
      invoiceNumber,
      index,
    });

  const selector = (state: State): OTTLink | undefined =>
    state.entities?.generateInvoicePdfLink?.[key];

  return useApiMutate<Entities['generateInvoicePdfLink'], unknown>({
    queryFunc,
    selector,
    queryKey: key,
  });
};

export default useGenerateInvoicePdfLink;

import { Entities, useGetRaceCardUrl, useGetStartListUrl } from '@apis';
import { BucketLink } from '@generated/raceinfo/src';
import useNumericParams from '@hooks/useNumericParams';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import { getExternalFileUrl } from '@utils/urls';
import React, { useEffect } from 'react';
import { ApiResponse } from 'src/apis/utils/types';

interface Props {
  useGetUrl: (raceDayId: number) => ApiResponse<BucketLink, Entities>;
}

function GetUrlAndRedirect({ useGetUrl }: Props): JSX.Element {
  const { raceDayId } = useNumericParams();

  const { data, loading, status, error } = useGetUrl(raceDayId);

  useEffect(() => {
    if (data && data.url) {
      window.location.href = getExternalFileUrl(data.url);
    }
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  return null;
}

export const GetUrlAndRedirectStartList = (): JSX.Element => (
  <GetUrlAndRedirect useGetUrl={useGetStartListUrl} />
);

export const GetUrlAndRedirectRaceCard = (): JSX.Element => (
  <GetUrlAndRedirect useGetUrl={useGetRaceCardUrl} />
);

import InfoBlock from '@components/InfoBlock';
import { PageLayout } from '@components/PageLayouts';
import Box from '@primitives/Box';
import React from 'react';

type InfoProps = Parameters<typeof InfoBlock>[0];

type Props = InfoProps & {
  headline?: string;
};

export default function ErrorPage({ headline, ...props }: Props): JSX.Element {
  return (
    <Box mt={[0, , '50px']}>
      <PageLayout singleCard pageSize="small" headline={headline} hideHeadline>
        <Box py={['20px', , '0']}>
          <InfoBlock {...props} />
        </Box>
      </PageLayout>
    </Box>
  );
}

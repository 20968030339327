import {
  enrollHorseToProposition,
  EnrollHorseToPropositionRequest,
  EnrollmentReceipt,
} from '@generated/propositions/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

export interface Entities {
  enrollmentReceipt?: EnrollmentReceipt;
}

type ActionParams = EnrollHorseToPropositionRequest['enrollmentRequest'];

const useEnrollHorseToProposition = (
  trainerId: number,
): MutationResponse<Entities['enrollmentReceipt'], Entities, ActionParams> => {
  const queryFunc = (enrollmentRequest: ActionParams): QueryConfig<Entities> =>
    enrollHorseToProposition({
      trainerId,
      enrollmentRequest,
    });
  return useApiMutate<Entities['enrollmentReceipt'], ActionParams>({
    queryFunc,
    selector: (state: State) => state.entities.enrollmentReceipt,
    queryKey: 'enrollHorseToProposition',
    resetKeys: [
      'availableHorsesForStart*',
      `enrollmentsForTrainer-${trainerId}`,
    ],
  });
};

export default useEnrollHorseToProposition;

// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface OTTLink
 */
export interface OTTLink  {
    /**
     * 
     * @type {string}
     * @memberof OTTLink
     */
    url?: string;
}

export function OTTLinkFromJSON(json: any): OTTLink {
    return {
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function OTTLinkToJSON(value?: OTTLink): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'url': value.url,
    };
}



// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface TrainedHorse
 */
export interface TrainedHorse  {
    /**
     * 
     * @type {number}
     * @memberof TrainedHorse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainedHorse
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainedHorse
     */
    handicap?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainedHorse
     */
    ownershipId?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainedHorse
     */
    ownerName?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainedHorse
     */
    motherId?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainedHorse
     */
    motherName?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainedHorse
     */
    fatherId?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainedHorse
     */
    fatherName?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainedHorse
     */
    propositionWeight?: number;
}

export function TrainedHorseFromJSON(json: any): TrainedHorse {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'handicap': !exists(json, 'handicap') ? undefined : json['handicap'],
        'ownershipId': !exists(json, 'ownershipId') ? undefined : json['ownershipId'],
        'ownerName': !exists(json, 'ownerName') ? undefined : json['ownerName'],
        'motherId': !exists(json, 'motherId') ? undefined : json['motherId'],
        'motherName': !exists(json, 'motherName') ? undefined : json['motherName'],
        'fatherId': !exists(json, 'fatherId') ? undefined : json['fatherId'],
        'fatherName': !exists(json, 'fatherName') ? undefined : json['fatherName'],
        'propositionWeight': !exists(json, 'propositionWeight') ? undefined : json['propositionWeight'],
    };
}

export function TrainedHorseToJSON(value?: TrainedHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'handicap': value.handicap,
        'ownershipId': value.ownershipId,
        'ownerName': value.ownerName,
        'motherId': value.motherId,
        'motherName': value.motherName,
        'fatherId': value.fatherId,
        'fatherName': value.fatherName,
        'propositionWeight': value.propositionWeight,
    };
}



import Alert from '@components/Alert';
import Card from '@components/Card';
import { AuthenticatedUser } from '@generated/account/src';
import { DeclaredEntry } from '@generated/propositions/src';
import withUser from '@hoc/withUser';
import { CloseButtom } from '@primitives/Button';
import Icon from '@primitives/Icon';
import { CircleCheck as CheckCircle } from '@styled-icons/fa-solid';
import React, { useState } from 'react';
import { CellProps } from 'react-table';

import Receipt from '../utils/Receipt';

const OverlayReceipt = ({
  row,
  user,
}: CellProps<DeclaredEntry> & { user: AuthenticatedUser }): JSX.Element => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <Alert
          open={open}
          size="medium"
          closeModal={() => setOpen(false)}
          showFooter={false}
          showHeader={false}
          showBorder={false}
        >
          <Card p={0} position="relative" minHeight="250px">
            <CloseButtom
              position="absolute"
              top="0"
              right="0"
              onClick={() => setOpen(false)}
              sx={{ zIndex: 2 }}
            />
            <Receipt
              overlay={true}
              licenseId={user.licenseId}
              propId={row.original.propopsitionInfo.id}
              horseId={row.original.horse?.id}
              propWeight={row.original.propopsitionInfo.weight}
              force
            />
          </Card>
        </Alert>
      )}
      <button onClick={() => setOpen(true)}>
        <Icon size={12} color="green" as={CheckCircle} />
      </button>
    </>
  );
};

export default withUser(OverlayReceipt);

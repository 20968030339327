// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * The horse\'s gender.
 * @export
 * @interface HorseInTrainingGender
 */
export interface HorseInTrainingGender  {
    /**
     * 
     * @type {string}
     * @memberof HorseInTrainingGender
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof HorseInTrainingGender
     */
    text?: string;
}

export function HorseInTrainingGenderFromJSON(json: any): HorseInTrainingGender {
    return {
        'code': !exists(json, 'code') ? undefined : json['code'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function HorseInTrainingGenderToJSON(value?: HorseInTrainingGender): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'code': value.code,
        'text': value.text,
    };
}



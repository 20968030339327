import {
  createDriverTravelFee,
  CreateDriverTravelFeeRequest,
  ErrorResponse,
} from '@generated/tds/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

type Entities = State['entities'];
type ActionParams = CreateDriverTravelFeeRequest['driverTravelFee'];

const useCreateDriverTravelFee = (
  licenseId: number,
): MutationResponse<ErrorResponse | undefined, Entities, ActionParams> => {
  const queryFunc = (driverTravelFee: ActionParams): QueryConfig<Entities> =>
    createDriverTravelFee<Entities>({
      licenseId,
      driverTravelFee,
    });

  const selector = (state: State): ErrorResponse | undefined =>
    state.entities?.createDriverTravelFee?.[licenseId];

  return useApiMutate<ErrorResponse | undefined, ActionParams>({
    queryFunc,
    selector,
    queryKey: 'createDriverTravelFee',
    resetKeys: [`licenseholder-${licenseId}`],
  });
};

export default useCreateDriverTravelFee;

import 'styled-components';

import { TD, TH } from '@components/ReactTable';
import { Styles as TableStyle } from '@components/Table';
import { Stake } from '@generated/propositions/src';
import { TR } from '@primitives/Table';
import { formatTz } from '@utils/dateUtils';
import React from 'react';

interface StakeInfoProps {
  stakes: Stake[];
}

export default function StakeInfo({ stakes }: StakeInfoProps): JSX.Element {
  return (
    <TableStyle>
      <table width="100%" className="table-variant">
        <thead>
          <tr>
            <th>Insats</th>
            <th>Insatstermin</th>
            <TH
              cursor="default"
              css={{
                textAlign: 'right',
                paddingRight: '10px',
              }}
            >
              Summa
            </TH>
          </tr>
        </thead>
        <tbody>
          {stakes.map(stake => (
            <TR
              key={stake.id}
              css={{
                fontWeight: stake.current ? 'bold' : 'normal',
              }}
            >
              <td>{stake.number}</td>
              <td>{formatTz(stake.dueDate, 'yyyy-MM-dd')}</td>
              <TD
                css={{
                  textAlign: 'right',
                }}
              >
                {stake.fee}
              </TD>
            </TR>
          ))}
        </tbody>
      </table>
    </TableStyle>
  );
}

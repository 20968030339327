// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    HorseBreed,
    HorseBreedFromJSON,
    HorseBreedToJSON,
    HorseGender,
    HorseGenderFromJSON,
    HorseGenderToJSON,
} from './';

/**
 * 
 * @export
 * @interface Horse
 */
export interface Horse  {
    /**
     * 
     * @type {number}
     * @memberof Horse
     */
    horseId: number;
    /**
     * The name of the horse.
     * @type {string}
     * @memberof Horse
     */
    horseName: string;
    /**
     * Registration number.
     * @type {string}
     * @memberof Horse
     */
    registrationNumber: string;
    /**
     * 
     * @type {HorseBreed}
     * @memberof Horse
     */
    horseBreed: HorseBreed;
    /**
     * 
     * @type {HorseGender}
     * @memberof Horse
     */
    gender: HorseGender;
    /**
     * The horse\'s birthdate.
     * @type {Date}
     * @memberof Horse
     */
    dateOfBirth: Date;
    /**
     * The horse\'s age.
     * @type {number}
     * @memberof Horse
     */
    age: number;
    /**
     * Horse points.
     * @type {number}
     * @memberof Horse
     */
    points?: number;
    /**
     * Horse winnings.
     * @type {number}
     * @memberof Horse
     */
    prizeMoney?: number;
}

export function HorseFromJSON(json: any): Horse {
    return {
        'horseId': json['horseId'],
        'horseName': json['horseName'],
        'registrationNumber': json['registrationNumber'],
        'horseBreed': HorseBreedFromJSON(json['horseBreed']),
        'gender': HorseGenderFromJSON(json['gender']),
        'dateOfBirth': parseDateTime(json['dateOfBirth']),
        'age': json['age'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'prizeMoney': !exists(json, 'prizeMoney') ? undefined : json['prizeMoney'],
    };
}

export function HorseToJSON(value?: Horse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'horseName': value.horseName,
        'registrationNumber': value.registrationNumber,
        'horseBreed': HorseBreedToJSON(value.horseBreed),
        'gender': HorseGenderToJSON(value.gender),
        'dateOfBirth': value.dateOfBirth.toLocalDate(),
        'age': value.age,
        'points': value.points,
        'prizeMoney': value.prizeMoney,
    };
}



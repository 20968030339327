import { BasicUserInformation, TDSInformation } from '@generated/user/src';
import Box from '@primitives/Box';
import { Text } from '@primitives/Typography';
import React from 'react';

interface Props {
  label: string;
  value:
    | BasicUserInformation[keyof BasicUserInformation]
    | TDSInformation[keyof TDSInformation];
}

export default function InformationEntry({ label, value }: Props): JSX.Element {
  return (
    <Box mb={2}>
      <Text fontWeight="bold" display="inline">
        {label}:{' '}
      </Text>
      <Text as="span">
        <>{value}</>
      </Text>
    </Box>
  );
}

import Box from '@primitives/Box';
import styled from 'styled-components';

const Separator = styled(Box)`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.colors['gray4']};

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${props => props.theme.colors['gray3']};
  }

  &:not(:empty)::before {
    margin-right: 1em;
  }

  &:not(:empty)::after {
    margin-left: 1em;
  }
`;

export default Separator;

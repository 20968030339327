import PersonInfoBase from '@components/HorseInfo';
import { AuthenticatedUser } from '@generated/authenticate/src';
import { PersonalInformation } from '@generated/user/src';
import Box from '@primitives/Box';
import React from 'react';
import { User } from 'styled-icons/fa-solid';

import { FormField } from './definitions/_fields';

type Props = {
  def: FormField;
  user: AuthenticatedUser;
  userData: PersonalInformation;
};

export function PersonInfo({ def, user, userData }: Props): JSX.Element {
  const details = [
    {
      label: 'Adress',
      value: `${userData?.basicUserInformation?.address.streetAddress}, ${userData?.basicUserInformation?.address.postCode} ${userData?.basicUserInformation?.address.city}`,
    },
    {
      label: 'Telefon',
      value:
        userData?.basicUserInformation.mobilePhoneNumber ??
        userData?.basicUserInformation.homePhoneNumber,
    },
    {
      label: 'E-post',
      value: userData?.changeableUserInformation.email,
    },
    {
      label: 'Kundnummer',
      value: user?.licenseId,
    },
  ];

  return (
    <Box key={def.name} width={'100%'} sx={{ gridColumn: 'span 3' }}>
      <PersonInfoBase
        name={`${user.userInfo.firstName} ${user.userInfo.lastName}`}
        text={user.userInfo.idNumber}
        icon={User}
        details={details}
      />
    </Box>
  );
}

// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    TravelFee,
    TravelFeeFromJSON,
    TravelFeeToJSON,
} from './';

/**
 * This contains a license holders commissions.
 * @export
 * @interface Commissions
 */
export interface Commissions  {
    /**
     * 
     * @type {number}
     * @memberof Commissions
     */
    flatRace?: number;
    /**
     * 
     * @type {number}
     * @memberof Commissions
     */
    steepleRace?: number;
    /**
     * 
     * @type {number}
     * @memberof Commissions
     */
    riderPercent?: number;
    /**
     * 
     * @type {Array<TravelFee>}
     * @memberof Commissions
     */
    travelFees?: Array<TravelFee>;
}

export function CommissionsFromJSON(json: any): Commissions {
    return {
        'flatRace': !exists(json, 'flatRace') ? undefined : json['flatRace'],
        'steepleRace': !exists(json, 'steepleRace') ? undefined : json['steepleRace'],
        'riderPercent': !exists(json, 'riderPercent') ? undefined : json['riderPercent'],
        'travelFees': !exists(json, 'travelFees') ? undefined : (json['travelFees'] as Array<any>).map(TravelFeeFromJSON),
    };
}

export function CommissionsToJSON(value?: Commissions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'flatRace': value.flatRace,
        'steepleRace': value.steepleRace,
        'riderPercent': value.riderPercent,
        'travelFees': value.travelFees === undefined ? undefined : (value.travelFees as Array<any>).map(TravelFeeToJSON),
    };
}



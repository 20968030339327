import 'styled-components';

import {
  useGetShareholdersDistribution,
  useUpdateShareholdersDistribution,
} from '@apis';
import Confirm from '@components/Confirm';
import { Styles as TableStyle } from '@components/DataGrid';
import { Input as FormikInput } from '@components/FormikComponents';
import { TD, TH } from '@components/ReactTable';
import { AuthenticatedUser } from '@generated/account/src';
import {
  HorseOwnershipSharesDistribution,
  Ownership,
} from '@generated/sportactors/src';
import Box, { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import { Input } from '@primitives/Form';
import Loading from '@primitives/Loading';
import Message from '@primitives/Message';
import { TR } from '@primitives/Table';
import { Lead, Text } from '@primitives/Typography';
import Refresh from '@utils/Refresh';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Row } from 'react-table';

interface TableFormProps {
  data: HorseOwnershipSharesDistribution;
  ownerId: number;
  ownershipId: number;
  refresh: Refresh;
}

type FormState = HorseOwnershipSharesDistribution & { all?: string };

function sumShares(
  shareholders: HorseOwnershipSharesDistribution['shareholders'],
): number {
  return shareholders.reduce((acc, obj) => acc + Number(obj.shares), 0);
}

function TableForm({
  data,
  refresh,
  ownerId,
  ownershipId,
}: TableFormProps): JSX.Element {
  const initialState: FormState = data;

  const { action: save } = useUpdateShareholdersDistribution();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmedSave, setConfirmedSave] = useState(false);

  const { allowedToUpdate } = data;

  return (
    <Formik<FormState>
      initialValues={initialState}
      onSubmit={async (values, { resetForm, setStatus, setSubmitting }) => {
        const unallocatedShares =
          values.sharesToDistribute - sumShares(values.shareholders);

        if (unallocatedShares == 0 || confirmedSave) {
          const resp = await save({
            ownerId,
            ownershipId,
            horseOwnershipSharesDistribution: values,
          });

          if (resp.status === 200) {
            resetForm({
              values,
              status: {
                success: 'Sparat!',
              },
            });

            await refresh();

            setTimeout(() => {
              setStatus({});
            }, 3000);
          } else {
            setSubmitting(false);
            setStatus({
              error: resp.body.localizedErrorMessage,
            });
          }
        } else {
          setConfirmOpen(true);
        }
        setConfirmedSave(false);
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validate={values => {
        const err: {
          all?: string;
        } = {};

        const unallocatedShares =
          values.sharesToDistribute - sumShares(values.shareholders);

        if (unallocatedShares < 0) {
          err.all = `Det går inte att fördela mer än de totala antalet andelar (${values.sharesToDistribute}).`;
        } else if (values.sharesToDistribute < 0) {
          err.all = `Det går inte att fördela negativa andelar (${values.sharesToDistribute}).`;
        } else if (
          values.shareholders.some(shareholder => shareholder.shares < 0)
        ) {
          err.all = `Det går inte att äga negativa andelar`;
        }

        return err;
      }}
    >
      {({
        isSubmitting,
        dirty,
        values,
        status,
        setFieldValue,
        submitForm,
        errors,
      }) => {
        const allocatedShares = sumShares(values.shareholders);
        const unallocatedShares = values.sharesToDistribute - allocatedShares;

        return (
          <Form>
            {allowedToUpdate && (
              <Lead fontSize={['text', , 'lead']} mb={3}>
                Fördela
              </Lead>
            )}

            {allowedToUpdate ? (
              <Box maxWidth="150px" mb={4}>
                <FormikInput
                  label="Totalt antal andelar"
                  name="sharesToDistribute"
                  type="number"
                />
              </Box>
            ) : (
              <Text color="dim-gray" mb={1}>
                Totalt antal andelar: {values.sharesToDistribute}
              </Text>
            )}

            <Text color="dim-gray" mb={2}>
              Fördelade andelar: {allocatedShares}, kvar att fördela:{' '}
              <span>{Math.max(unallocatedShares, 0)}</span>
            </Text>
            <Box width={1} maxWidth="420px" overflow="auto">
              <TableStyle bg="white" noHover>
                <table className="datagrid-variant">
                  <thead>
                    <TR
                      css={{
                        '&&': {
                          th: {
                            verticalAlign: 'middle',
                          },
                        },
                      }}
                    >
                      <th>Andelsinnehavare</th>
                      <TH
                        cursor="default"
                        css={{
                          '&&': {
                            textAlign: 'center',
                            whiteSpace: 'break-spaces',
                            width: '130px',
                          },
                        }}
                      >
                        {allowedToUpdate ? 'Fördela andelar' : 'Andelar'}
                      </TH>
                    </TR>
                  </thead>
                  <tbody>
                    {values.shareholders.map((shareholder, i) => (
                      <TR
                        key={shareholder.id}
                        css={{
                          fontWeight: shareholder.representative
                            ? 'bold'
                            : 'normal',
                        }}
                      >
                        <TD
                          css={{
                            '&&': {
                              whiteSpace: 'break-spaces',
                              fontSize: '14px',
                              verticalAlign: 'middle',
                            },
                          }}
                        >
                          {shareholder.fullName}
                        </TD>
                        <td align="center">
                          {allowedToUpdate ? (
                            <Input
                              type="number"
                              value={shareholder.shares}
                              onChange={e => {
                                const { value } = e.target;

                                setFieldValue(
                                  `shareholders[${i}].shares`,
                                  value,
                                );
                              }}
                            />
                          ) : (
                            shareholder.shares
                          )}
                        </td>
                      </TR>
                    ))}
                  </tbody>
                </table>
              </TableStyle>
            </Box>

            {allowedToUpdate && (
              <Flex justifyContent="flex-start" mt={2}>
                {confirmOpen && (
                  <Confirm
                    closeModal={() => setConfirmOpen(false)}
                    colorScheme="primary"
                    onAction={() => {
                      setConfirmedSave(true);
                      submitForm();
                      setConfirmOpen(false);
                    }}
                    open={confirmOpen}
                    title={'Är du säker?'}
                    description={`Alla andelarhar inte fördelats. ${unallocatedShares} andelar återstår att fördela. Vill du spara ändå?`}
                  ></Confirm>
                )}
                <Button
                  type="button"
                  onClick={() => {
                    submitForm();
                  }}
                  loading={isSubmitting}
                  disabled={!dirty}
                  width={[1, , 'auto']}
                >
                  Spara
                </Button>
                {(status?.error || errors?.all) && (
                  <Message variant="error" sx={{ ml: 3 }}>
                    {status?.error || errors?.all}
                  </Message>
                )}

                {status?.success && (
                  <Message variant="success" sx={{ ml: 3 }}>
                    {status.success}
                  </Message>
                )}
              </Flex>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

interface Props {
  row: Row<Ownership>;
  user: AuthenticatedUser;
}

function ShareHoldersForm({ row, user }: Props): JSX.Element {
  const { data, refresh } = useGetShareholdersDistribution(
    user.licenseId,
    row.original.owner.id,
  );

  if (!data) {
    return <Loading />;
  }

  return (
    <Box>
      <TableForm
        data={data}
        refresh={refresh}
        ownerId={user.licenseId}
        ownershipId={row.original.owner.id}
      />
    </Box>
  );
}

export default ShareHoldersForm;

import { useGetMare } from '@apis';
import Box from '@primitives/Box';
import { ButtonLink } from '@primitives/Button';
import Icon from '@primitives/Icon';
import Loading from '@primitives/Loading';
import { CircleCheck as CheckCircle } from '@styled-icons/fa-solid';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { FormState } from './CoveringForm';

interface Props {
  mareId: number;
}

export default function SelectedMare({ mareId }: Props): JSX.Element {
  const coveringDate = new Date();
  const { data: mare, loading } = useGetMare(mareId, coveringDate);
  const { values, setFieldValue } = useFormikContext<FormState>();

  useEffect(() => {
    if (mare) {
      setFieldValue('mare', mare);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mare?.id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box>
      {values.mare && (
        <>
          <Icon as={CheckCircle} color="green" size={14} mr={1} />{' '}
          <b>{mare.name}</b> {mare.importText}
          <ButtonLink
            ml={2}
            onClick={() => {
              setFieldValue('mare', undefined);
              setFieldValue('mareId', undefined, true);
            }}
          >
            Byt Sto
          </ButtonLink>
        </>
      )}
    </Box>
  );
}

// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface HorsePreCheckedForVaccineRegistration
 */
export interface HorsePreCheckedForVaccineRegistration  {
    /**
     * 
     * @type {number}
     * @memberof HorsePreCheckedForVaccineRegistration
     */
    horseId: number;
    /**
     * 
     * @type {string}
     * @memberof HorsePreCheckedForVaccineRegistration
     */
    horseName: string;
    /**
     * The horse name to display, may contain additional information like country code.
     * @type {string}
     * @memberof HorsePreCheckedForVaccineRegistration
     */
    horseDisplayName: string;
    /**
     * Comment on the vaccine warning, if any
     * @type {string}
     * @memberof HorsePreCheckedForVaccineRegistration
     */
    vaccineWarningComment?: string;
    /**
     * Horse has a warning for suggested vaccination
     * @type {boolean}
     * @memberof HorsePreCheckedForVaccineRegistration
     */
    vaccineWarning?: boolean;
}

export function HorsePreCheckedForVaccineRegistrationFromJSON(json: any): HorsePreCheckedForVaccineRegistration {
    return {
        'horseId': json['horseId'],
        'horseName': json['horseName'],
        'horseDisplayName': json['horseDisplayName'],
        'vaccineWarningComment': !exists(json, 'vaccineWarningComment') ? undefined : json['vaccineWarningComment'],
        'vaccineWarning': !exists(json, 'vaccineWarning') ? undefined : json['vaccineWarning'],
    };
}

export function HorsePreCheckedForVaccineRegistrationToJSON(value?: HorsePreCheckedForVaccineRegistration): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'horseName': value.horseName,
        'horseDisplayName': value.horseDisplayName,
        'vaccineWarningComment': value.vaccineWarningComment,
        'vaccineWarning': value.vaccineWarning,
    };
}



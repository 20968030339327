import Box from '@primitives/Box';
import React from 'react';

const TiltedBorder = (): JSX.Element => (
  <Box
    overflow="hidden"
    height="pageHeader"
    sx={{
      backfaceVisibility: 'hidden',
    }}
  >
    <Box
      bg="deep-blue"
      width="20px"
      position="relative"
      ml="6px"
      mr="-6px"
      height="100%"
      sx={{
        transform: 'skew(-10deg)',
        backfaceVisibility: 'hidden',
        borderLeftWidth: '8px',
        borderLeftColor: 'waikawa-grey',
        borderLeftStyle: 'solid',
      }}
    />
  </Box>
);

export default TiltedBorder;

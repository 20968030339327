import { useGetOngoingStakeRaces } from '@apis';
import Table from '@components/Table';
import { OngoingStakeRace } from '@generated/propositions/src';
import Error from '@primitives/Error';
import { Link } from '@primitives/Link';
import Loading from '@primitives/Loading';
import { formatTz } from '@utils/dateUtils';
import React from 'react';
import { generatePath, useRouteMatch } from 'react-router-dom';
import { CellProps } from 'react-table';

export default function List(): JSX.Element {
  const { data, loading, error, status } = useGetOngoingStakeRaces();
  const { path } = useRouteMatch();

  if (loading) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return <Error>{error}</Error>;
  }

  return (
    <Table
      pt={[4, , 0]}
      columns={[
        {
          Header: 'Loppnamn',
          accessor: 'title',
          Cell: ({
            value,
            row: { original: row },
          }: CellProps<OngoingStakeRace, string>) =>
            row.stakeMarkedAsReady ? (
              <Link
                to={generatePath(path, {
                  section: 'anmalningslistor',
                  propositionId: row.propositionId.toString(),
                })}
                fontWeight="bold"
              >
                {value}
              </Link>
            ) : (
              <>{value}</>
            ),
        },
        {
          Header: 'Bana',
          accessor: 'trackName',
        },
        {
          Header: 'Aktuell insats',
          accessor: 'stakeNumber',
          alignment: 'right',
        },
        {
          Header: 'Tävlingsdag',
          accessor: 'raceDate',
          Cell: ({ value }) => <>{formatTz(value, 'yyyy-MM-dd')}</>,
        },
      ]}
      data={data || []}
    />
  );
}

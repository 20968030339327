// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Distance,
    DistanceFromJSON,
    DistanceToJSON,
    Horse,
    HorseFromJSON,
    HorseToJSON,
    KilometerTime,
    KilometerTimeFromJSON,
    KilometerTimeToJSON,
    LicenseHolder,
    LicenseHolderFromJSON,
    LicenseHolderToJSON,
    Odds,
    OddsFromJSON,
    OddsToJSON,
    Owner,
    OwnerFromJSON,
    OwnerToJSON,
    Placement,
    PlacementFromJSON,
    PlacementToJSON,
    PrizeMoney,
    PrizeMoneyFromJSON,
    PrizeMoneyToJSON,
    RaceInformation,
    RaceInformationFromJSON,
    RaceInformationToJSON,
    RaceType,
    RaceTypeFromJSON,
    RaceTypeToJSON,
    StartPosition,
    StartPositionFromJSON,
    StartPositionToJSON,
} from './';

/**
 * 
 * @export
 * @interface SportActorResults
 */
export interface SportActorResults  {
    /**
     * 
     * @type {string}
     * @memberof SportActorResults
     */
    trackCode: string;
    /**
     * 
     * @type {RaceInformation}
     * @memberof SportActorResults
     */
    raceInformation: RaceInformation;
    /**
     * 
     * @type {RaceType}
     * @memberof SportActorResults
     */
    raceType: RaceType;
    /**
     * 
     * @type {StartPosition}
     * @memberof SportActorResults
     */
    startPosition: StartPosition;
    /**
     * 
     * @type {Distance}
     * @memberof SportActorResults
     */
    distance: Distance;
    /**
     * 
     * @type {string}
     * @memberof SportActorResults
     */
    trackCondition: string;
    /**
     * 
     * @type {Placement}
     * @memberof SportActorResults
     */
    placement: Placement;
    /**
     * 
     * @type {KilometerTime}
     * @memberof SportActorResults
     */
    kilometerTime?: KilometerTime;
    /**
     * Only for trot. Used for sorting
     * @type {string}
     * @memberof SportActorResults
     */
    startMethod?: string;
    /**
     * 
     * @type {Odds}
     * @memberof SportActorResults
     */
    odds: Odds;
    /**
     * 
     * @type {Horse}
     * @memberof SportActorResults
     */
    horse: Horse;
    /**
     * 
     * @type {LicenseHolder}
     * @memberof SportActorResults
     */
    driver?: LicenseHolder;
    /**
     * 
     * @type {LicenseHolder}
     * @memberof SportActorResults
     */
    trainer?: LicenseHolder;
    /**
     * 
     * @type {Owner}
     * @memberof SportActorResults
     */
    owner?: Owner;
    /**
     * 
     * @type {PrizeMoney}
     * @memberof SportActorResults
     */
    prizeMoney: PrizeMoney;
    /**
     * 
     * @type {string}
     * @memberof SportActorResults
     */
    trackSurface: string;
    /**
     * 
     * @type {string}
     * @memberof SportActorResults
     */
    weight: string;
    /**
     * 
     * @type {string}
     * @memberof SportActorResults
     */
    riderHandicap: string;
    /**
     * The prize money for finishing first in this race.
     * @type {number}
     * @memberof SportActorResults
     */
    winnerPrizeMoney: number;
    /**
     * 
     * @type {boolean}
     * @memberof SportActorResults
     */
    withdrawn?: boolean;
}

export function SportActorResultsFromJSON(json: any): SportActorResults {
    return {
        'trackCode': json['trackCode'],
        'raceInformation': RaceInformationFromJSON(json['raceInformation']),
        'raceType': RaceTypeFromJSON(json['raceType']),
        'startPosition': StartPositionFromJSON(json['startPosition']),
        'distance': DistanceFromJSON(json['distance']),
        'trackCondition': json['trackCondition'],
        'placement': PlacementFromJSON(json['placement']),
        'kilometerTime': !exists(json, 'kilometerTime') ? undefined : KilometerTimeFromJSON(json['kilometerTime']),
        'startMethod': !exists(json, 'startMethod') ? undefined : json['startMethod'],
        'odds': OddsFromJSON(json['odds']),
        'horse': HorseFromJSON(json['horse']),
        'driver': !exists(json, 'driver') ? undefined : LicenseHolderFromJSON(json['driver']),
        'trainer': !exists(json, 'trainer') ? undefined : LicenseHolderFromJSON(json['trainer']),
        'owner': !exists(json, 'owner') ? undefined : OwnerFromJSON(json['owner']),
        'prizeMoney': PrizeMoneyFromJSON(json['prizeMoney']),
        'trackSurface': json['trackSurface'],
        'weight': json['weight'],
        'riderHandicap': json['riderHandicap'],
        'winnerPrizeMoney': json['winnerPrizeMoney'],
        'withdrawn': !exists(json, 'withdrawn') ? undefined : json['withdrawn'],
    };
}

export function SportActorResultsToJSON(value?: SportActorResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'trackCode': value.trackCode,
        'raceInformation': RaceInformationToJSON(value.raceInformation),
        'raceType': RaceTypeToJSON(value.raceType),
        'startPosition': StartPositionToJSON(value.startPosition),
        'distance': DistanceToJSON(value.distance),
        'trackCondition': value.trackCondition,
        'placement': PlacementToJSON(value.placement),
        'kilometerTime': KilometerTimeToJSON(value.kilometerTime),
        'startMethod': value.startMethod,
        'odds': OddsToJSON(value.odds),
        'horse': HorseToJSON(value.horse),
        'driver': LicenseHolderToJSON(value.driver),
        'trainer': LicenseHolderToJSON(value.trainer),
        'owner': OwnerToJSON(value.owner),
        'prizeMoney': PrizeMoneyToJSON(value.prizeMoney),
        'trackSurface': value.trackSurface,
        'weight': value.weight,
        'riderHandicap': value.riderHandicap,
        'winnerPrizeMoney': value.winnerPrizeMoney,
        'withdrawn': value.withdrawn,
    };
}



import { Text } from '@primitives/Typography';
import React from 'react';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';

interface StyleProps {
  colorScheme: 'blue' | 'gray' | 'green';
}

const colorMap = {
  blue: 'deep-blue',
  green: 'green',
  gray: 'gray4',
};

const StyledRadio = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: white;
  border-radius: 50%;
  border: solid 1px #d5d2cf;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const Label = styled.label<StyleProps>`
  padding-left: 16px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  vertical-align: top;
  &:hover input ~ ${StyledRadio} {
    background-color: #eee;
  }

  input:checked ~ ${StyledRadio} {
    background-color: white;
  }

  ${StyledRadio}:after {
    top: 4px;
    left: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${p => p.theme.colors[colorMap[p.colorScheme]]};
  }
`;

const HiddenBox = styled.input`
  display: none;

  &:checked ~ ${StyledRadio}:after {
    display: block;
  }
`;

export interface Props
  extends SpaceProps,
    Partial<StyleProps>,
    React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export default function Rardio({
  colorScheme = 'gray',
  label,
  ...props
}: Props): JSX.Element {
  return (
    <Label colorScheme={colorScheme}>
      <HiddenBox {...props} type="radio" />
      <StyledRadio />
      {label && (
        <Text pl="6px" fontWeight="normal" as="span">
          {label}
        </Text>
      )}
    </Label>
  );
}

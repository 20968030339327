import { Template } from './_templateData';

export const EpostfakturaAnmalan: Template = {
  id: 5,
  actorType: 'licenseholder',
  fields: [
    {
      name: 'Fysisk eller juridisk person',
      description:
        'Välj om fakturorna gäller dig själv som fysisk person eller en juridisk person (bolag).',
      fields: [
        'personSwitchButton',
        'personInfo',
        'juridicalPersonName',
        'juridicalPersonOrgNumber',
        'juridicalPersonAddress',
        'juridicalPersonZipCode',
        'juridicalPersonCity',
        'juridicalPersonPhone',
        'juridicalPersonEmail',
      ],
    },
    {
      name: 'Till vilken e-postadress skall fakturorna skickas?',
      description:
        'Ange den e-postadress dit du vill att fakturorna ska skickas.',
      fields: ['emailInvoice'],
    },
  ],
  fieldOptions: [
    {
      actorId: { hidden: true },
      personInfo: { hidden: 'values?.personSwitchButton' },
      juridicalPersonName: {
        hidden: '!values?.personSwitchButton',
      },
      juridicalPersonOrgNumber: {
        hidden: '!values?.personSwitchButton',
      },
      juridicalPersonAddress: {
        hidden: '!values?.personSwitchButton',
      },
      juridicalPersonZipCode: {
        hidden: '!values?.personSwitchButton',
      },
      juridicalPersonCity: {
        hidden: '!values?.personSwitchButton',
      },
      juridicalPersonPhone: {
        hidden: '!values?.personSwitchButton',
      },
      juridicalPersonEmail: {
        hidden: '!values?.personSwitchButton',
      },
    },
  ],
  provider: 'email',
};

import { useTryToUnenrollFromProposition } from '@apis';
import Confirm from '@components/Confirm';
import Icon from '@primitives/Icon';
import { TrashCan as TrashAlt } from '@styled-icons/fa-solid';
import { formatTz } from '@utils/dateUtils';
import Refresh from '@utils/Refresh';
import React, { useCallback, useState } from 'react';

interface Props {
  horseId: number;
  horseName: string;
  licenseId: number;
  propositionDate: Date;
  propositionId: number;
  propositionNumber: number;
  refresh: Refresh;
  trackName: string;
}

export default function Disenroll({
  horseId,
  horseName,
  propositionDate,
  propositionId,
  propositionNumber,
  refresh,
  licenseId,
  trackName,
}: Props): JSX.Element {
  const [error, setError] = useState<string>();
  const [open, setOpen] = useState(false);

  const openModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const { action, loading } = useTryToUnenrollFromProposition(
    propositionId,
    horseId,
    licenseId,
  );

  const onAction = useCallback(async () => {
    setError('');

    const { body } = await action({});

    if (body && body.errorMessage) {
      let error = body.localizedErrorMessage || body.errorMessage;

      // https://svenskgalopp.atlassian.net/browse/APP-586
      if (body.errorCode === 20108) {
        error =
          'Propositionens anmälningstid har gått ut. Du kan inte längre göra några ändringar.';
      }

      setError(error);
    } else {
      refresh();
    }
  }, [action, refresh, setError]);

  return (
    <>
      <button onClick={openModal}>
        <Icon color="gray6" size={12} as={TrashAlt} />
      </button>
      <Confirm
        closeModal={closeModal}
        colorScheme="delete"
        error={error}
        loading={loading}
        onAction={onAction}
        open={open}
        title="Ta bort häst?"
        description={`Är du säker på att du vill ta bort ${horseName} från ${trackName} ${formatTz(
          propositionDate,
          'yyyy-MM-dd',
        )} Prop ${propositionNumber}?`}
      ></Confirm>
    </>
  );
}

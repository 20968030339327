import { useGetExemptions } from '@apis';
import Step from '@components/Step';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import { Checkbox, TextArea } from '@primitives/Form';
import Loading from '@primitives/Loading';
import { useFormikContext } from 'formik';
import React from 'react';

import { FormState } from '.';

const FormikExemptions = (): JSX.Element => {
  const { data, loading, status, error } = useGetExemptions();
  const { values, setFieldValue } = useFormikContext<FormState>();

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  return (
    <Step header="3. Undantag" mt={5}>
      <Box display="flex" width="100%" flexWrap="wrap">
        {loading && <Loading />}
        {data &&
          data.map(item => {
            let exemptionExists = false;
            if (
              values.exemptions &&
              values.exemptions.some(e => e.id === item.id)
            ) {
              exemptionExists = true;
            }

            return (
              <Box key={item.id} width={['100%', null, 1 / 3]} pb={4}>
                <Checkbox
                  checked={exemptionExists}
                  label={item.description}
                  onChange={() =>
                    setFieldValue(
                      'exemptions',
                      exemptionExists
                        ? values.exemptions.filter(e => e.id !== item.id)
                        : [...values.exemptions, item],
                    )
                  }
                />
              </Box>
            );
          })}
      </Box>
      <Box pb="6px">
        <label>
          Ange orsak till undantag
          {values.exemptions.length > 0 && ' *'}
        </label>
      </Box>
      <Box>
        <TextArea
          value={values.exemptionMessage}
          fontSize="small"
          onChange={e => setFieldValue('exemptionMessage', e.target.value)}
        />
      </Box>
    </Step>
  );
};

export default FormikExemptions;

import { useRegisterMounting, useUnregisterMounting } from '@apis';
import { AuthenticatedUser } from '@generated/account/src';
import Box from '@primitives/Box';
import Radio from '@primitives/Form/radio';
import Message from '@primitives/Message';
import React, { useCallback, useState } from 'react';

interface Props {
  user: AuthenticatedUser;
  raceDayId: number;
  available: boolean;
  onSuccess: () => void;
}

export default function Form({
  user,
  raceDayId,
  available: defaultAvailable,
  onSuccess,
}: Props): JSX.Element {
  const [available, setAvailable] = useState(defaultAvailable);
  const [status, setStatus] = useState<'success' | 'error'>();

  const { action: register } = useRegisterMounting(user.licenseId);
  const { action: unRegister } = useUnregisterMounting(user.licenseId);

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.target;

      const reg = value === 'true' && checked;

      setAvailable(reg);

      const action = reg ? register : unRegister;

      const resp = await action(raceDayId);

      if (resp.status >= 400) {
        setStatus('error');
      } else {
        setStatus('success');
        onSuccess();
      }

      // Todo debounce
      setTimeout(() => setStatus(undefined), 3000);
    },
    [raceDayId, register, unRegister, onSuccess],
  );

  return (
    <Box
      width={['100%', null, '70%']}
      display="flex"
      mt={[4, , 0]}
      mb={[1, , 0]}
    >
      <Box display="flex" horizontalSpacing={[20, , 50]}>
        <Radio
          checked={available}
          onChange={handleChange}
          colorScheme="gray"
          name={`available-${raceDayId}`}
          value="true"
          label="Tillgänglig"
        />

        <Radio
          checked={!available}
          onChange={handleChange}
          colorScheme="gray"
          name={`available-${raceDayId}`}
          value="false"
          label="Ej tillgänglig"
        />
      </Box>

      {status && (
        <Message
          ml={[2, , 4]}
          variant={status}
          lineHeight={1.2}
          sx={{
            fontSize: 'small',
          }}
        >
          {status === 'success' ? 'Sparad' : 'Något gick fel'}
        </Message>
      )}
    </Box>
  );
}

// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Deputy details
 * @export
 * @interface LicenseHolderDeputyInfo
 */
export interface LicenseHolderDeputyInfo  {
    /**
     * The deputy name
     * @type {string}
     * @memberof LicenseHolderDeputyInfo
     */
    name?: string;
    /**
     * The deputy phone number
     * @type {string}
     * @memberof LicenseHolderDeputyInfo
     */
    phoneNumber?: string;
}

export function LicenseHolderDeputyInfoFromJSON(json: any): LicenseHolderDeputyInfo {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
    };
}

export function LicenseHolderDeputyInfoToJSON(value?: LicenseHolderDeputyInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'phoneNumber': value.phoneNumber,
    };
}



// tslint:disable
/**
 * Web API Auth Service
 * Service for supporting user authentication (issuing JWTs) for accessing secured resources.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo  {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    lastLoginInfo?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserInfo
     */
    passwordValidityDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    temporaryPassword: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    smsNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    changeSmsNumber: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    licenseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    russLicenseCode?: string;
}

export function UserInfoFromJSON(json: any): UserInfo {
    return {
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'lastLoginInfo': !exists(json, 'lastLoginInfo') ? undefined : json['lastLoginInfo'],
        'passwordValidityDate': !exists(json, 'passwordValidityDate') ? undefined : parseDateTime(json['passwordValidityDate']),
        'temporaryPassword': json['temporaryPassword'],
        'smsNumber': !exists(json, 'smsNumber') ? undefined : json['smsNumber'],
        'changeSmsNumber': json['changeSmsNumber'],
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'licenseCode': !exists(json, 'licenseCode') ? undefined : json['licenseCode'],
        'russLicenseCode': !exists(json, 'russLicenseCode') ? undefined : json['russLicenseCode'],
    };
}

export function UserInfoToJSON(value?: UserInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'firstName': value.firstName,
        'lastName': value.lastName,
        'lastLoginInfo': value.lastLoginInfo,
        'passwordValidityDate': value.passwordValidityDate === undefined ? undefined : value.passwordValidityDate.toLocalDate(),
        'temporaryPassword': value.temporaryPassword,
        'smsNumber': value.smsNumber,
        'changeSmsNumber': value.changeSmsNumber,
        'idNumber': value.idNumber,
        'licenseCode': value.licenseCode,
        'russLicenseCode': value.russLicenseCode,
    };
}



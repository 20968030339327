// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Contains additional licenseholder information.
 * @export
 * @interface LicenseHolderAdditionalInformation
 */
export interface LicenseHolderAdditionalInformation  {
    /**
     * True if the license holder have trainer statistics.
     * @type {boolean}
     * @memberof LicenseHolderAdditionalInformation
     */
    hasTrainerStatistics?: boolean;
    /**
     * True if the license holder have coming start as a trainer.
     * @type {boolean}
     * @memberof LicenseHolderAdditionalInformation
     */
    hasTrainerComingStarts?: boolean;
    /**
     * Does the license holder have horses in training, ie has a training list.
     * @type {boolean}
     * @memberof LicenseHolderAdditionalInformation
     */
    hasTrainingList?: boolean;
    /**
     * True if the license holder has driver/rider statistics.
     * @type {boolean}
     * @memberof LicenseHolderAdditionalInformation
     */
    hasDriverStatistics?: boolean;
    /**
     * True if the license holder has coming start as a driver/rider.
     * @type {boolean}
     * @memberof LicenseHolderAdditionalInformation
     */
    hasDriverComingStarts?: boolean;
}

export function LicenseHolderAdditionalInformationFromJSON(json: any): LicenseHolderAdditionalInformation {
    return {
        'hasTrainerStatistics': !exists(json, 'hasTrainerStatistics') ? undefined : json['hasTrainerStatistics'],
        'hasTrainerComingStarts': !exists(json, 'hasTrainerComingStarts') ? undefined : json['hasTrainerComingStarts'],
        'hasTrainingList': !exists(json, 'hasTrainingList') ? undefined : json['hasTrainingList'],
        'hasDriverStatistics': !exists(json, 'hasDriverStatistics') ? undefined : json['hasDriverStatistics'],
        'hasDriverComingStarts': !exists(json, 'hasDriverComingStarts') ? undefined : json['hasDriverComingStarts'],
    };
}

export function LicenseHolderAdditionalInformationToJSON(value?: LicenseHolderAdditionalInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'hasTrainerStatistics': value.hasTrainerStatistics,
        'hasTrainerComingStarts': value.hasTrainerComingStarts,
        'hasTrainingList': value.hasTrainingList,
        'hasDriverStatistics': value.hasDriverStatistics,
        'hasDriverComingStarts': value.hasDriverComingStarts,
    };
}



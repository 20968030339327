// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    DetailedInvoice,
    DetailedInvoiceFromJSON,
    DetailedInvoiceToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Invoice,
    InvoiceFromJSON,
    InvoiceToJSON,
    InvoicedTransactions,
    InvoicedTransactionsFromJSON,
    InvoicedTransactionsToJSON,
    OTTLink,
    OTTLinkFromJSON,
    OTTLinkToJSON,
    OwnerTransactions,
    OwnerTransactionsFromJSON,
    OwnerTransactionsToJSON,
    OwnershipsInvoiceNotifications,
    OwnershipsInvoiceNotificationsFromJSON,
    OwnershipsInvoiceNotificationsToJSON,
} from '../models';

export interface GenerateInvoicePdfLinkRequest {
    ownerId: number;
    invoiceNumber: number;
    index: number;
}

export interface GetInvoicePdfRequest {
    ownerId: number;
    invoiceNumber: number;
    ott: string;
}

export interface GetInvoiceTransactionsLatestBilledMonthRequest {
    ownerId: number;
}

export interface GetInvoicesRequest {
    ownerId: number;
    from: string;
    to: string;
}

export interface GetOwnershipDetailedInvoiceRequest {
    ownerId: number;
    documentId: number;
}

export interface GetOwnershipFutureInvoiceTransactionsRequest {
    ownerId: number;
}

export interface GetOwnershipInvoiceNotificationsRequest {
    ownerId: number;
}

export interface GetOwnershipInvoiceTransactionsRequest {
    ownerId: number;
    date: Date;
}


/**
 * Generates url to an invoice PDF
 */
function generateInvoicePdfLinkRaw<T>(requestParameters: GenerateInvoicePdfLinkRequest, requestConfig: runtime.TypedQueryConfig<T, OTTLink> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling generateInvoicePdfLink.');
    }

    if (requestParameters.invoiceNumber === null || requestParameters.invoiceNumber === undefined) {
        throw new runtime.RequiredError('invoiceNumber','Required parameter requestParameters.invoiceNumber was null or undefined when calling generateInvoicePdfLink.');
    }

    if (requestParameters.index === null || requestParameters.index === undefined) {
        throw new runtime.RequiredError('index','Required parameter requestParameters.index was null or undefined when calling generateInvoicePdfLink.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/invoices/{invoiceNumber}/{index}/url`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))).replace(`{${"invoiceNumber"}}`, encodeURIComponent(String(requestParameters.invoiceNumber))).replace(`{${"index"}}`, encodeURIComponent(String(requestParameters.index))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OTTLinkFromJSON(body), text);
    }

    return config;
}

/**
* Generates url to an invoice PDF
*/
export function generateInvoicePdfLink<T>(requestParameters: GenerateInvoicePdfLinkRequest, requestConfig?: runtime.TypedQueryConfig<T, OTTLink>): QueryConfig<T> {
    return generateInvoicePdfLinkRaw(requestParameters, requestConfig);
}

/**
 * Fetch ownership invoice PDF
 */
function getInvoicePdfRaw<T>(requestParameters: GetInvoicePdfRequest, requestConfig: runtime.TypedQueryConfig<T, Blob> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getInvoicePdf.');
    }

    if (requestParameters.invoiceNumber === null || requestParameters.invoiceNumber === undefined) {
        throw new runtime.RequiredError('invoiceNumber','Required parameter requestParameters.invoiceNumber was null or undefined when calling getInvoicePdf.');
    }

    if (requestParameters.ott === null || requestParameters.ott === undefined) {
        throw new runtime.RequiredError('ott','Required parameter requestParameters.ott was null or undefined when calling getInvoicePdf.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.ott !== undefined) {
        queryParameters['ott'] = requestParameters.ott;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/invoices/{invoiceNumber}/pdf`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))).replace(`{${"invoiceNumber"}}`, encodeURIComponent(String(requestParameters.invoiceNumber))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        throw "OH NO";
    }

    return config;
}

/**
* Fetch ownership invoice PDF
*/
export function getInvoicePdf<T>(requestParameters: GetInvoicePdfRequest, requestConfig?: runtime.TypedQueryConfig<T, Blob>): QueryConfig<T> {
    return getInvoicePdfRaw(requestParameters, requestConfig);
}

/**
 * Retrieve the latest billed month for personal & ownership invoice transactions, in the format yyyy-MM-dd.
 */
function getInvoiceTransactionsLatestBilledMonthRaw<T>(requestParameters: GetInvoiceTransactionsLatestBilledMonthRequest, requestConfig: runtime.TypedQueryConfig<T, Date> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getInvoiceTransactionsLatestBilledMonth.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/invoicetransactions/latestmonth`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        throw "OH NO";
    }

    return config;
}

/**
* Retrieve the latest billed month for personal & ownership invoice transactions, in the format yyyy-MM-dd.
*/
export function getInvoiceTransactionsLatestBilledMonth<T>(requestParameters: GetInvoiceTransactionsLatestBilledMonthRequest, requestConfig?: runtime.TypedQueryConfig<T, Date>): QueryConfig<T> {
    return getInvoiceTransactionsLatestBilledMonthRaw(requestParameters, requestConfig);
}

/**
 * Get invoices for the associated owner id
 */
function getInvoicesRaw<T>(requestParameters: GetInvoicesRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Invoice>> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getInvoices.');
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
        throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling getInvoices.');
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
        throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling getInvoices.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/invoices/{from}/{to}`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))).replace(`{${"from"}}`, encodeURIComponent(String(requestParameters.from))).replace(`{${"to"}}`, encodeURIComponent(String(requestParameters.to))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(InvoiceFromJSON), text);
    }

    return config;
}

/**
* Get invoices for the associated owner id
*/
export function getInvoices<T>(requestParameters: GetInvoicesRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Invoice>>): QueryConfig<T> {
    return getInvoicesRaw(requestParameters, requestConfig);
}

/**
 * Fetch ownership detailed invoice.
 */
function getOwnershipDetailedInvoiceRaw<T>(requestParameters: GetOwnershipDetailedInvoiceRequest, requestConfig: runtime.TypedQueryConfig<T, DetailedInvoice> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getOwnershipDetailedInvoice.');
    }

    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
        throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getOwnershipDetailedInvoice.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/ownerships/{ownerId}/invoices/{documentId}`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))).replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(DetailedInvoiceFromJSON(body), text);
    }

    return config;
}

/**
* Fetch ownership detailed invoice.
*/
export function getOwnershipDetailedInvoice<T>(requestParameters: GetOwnershipDetailedInvoiceRequest, requestConfig?: runtime.TypedQueryConfig<T, DetailedInvoice>): QueryConfig<T> {
    return getOwnershipDetailedInvoiceRaw(requestParameters, requestConfig);
}

/**
 * Search and retrieve personal & ownership future invoice transactions.
 */
function getOwnershipFutureInvoiceTransactionsRaw<T>(requestParameters: GetOwnershipFutureInvoiceTransactionsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<OwnerTransactions>> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getOwnershipFutureInvoiceTransactions.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/ownerships/{ownerId}/invoicetransactions/coming`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(OwnerTransactionsFromJSON), text);
    }

    return config;
}

/**
* Search and retrieve personal & ownership future invoice transactions.
*/
export function getOwnershipFutureInvoiceTransactions<T>(requestParameters: GetOwnershipFutureInvoiceTransactionsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<OwnerTransactions>>): QueryConfig<T> {
    return getOwnershipFutureInvoiceTransactionsRaw(requestParameters, requestConfig);
}

/**
 * Get ownerships invoice notifications
 */
function getOwnershipInvoiceNotificationsRaw<T>(requestParameters: GetOwnershipInvoiceNotificationsRequest, requestConfig: runtime.TypedQueryConfig<T, OwnershipsInvoiceNotifications> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getOwnershipInvoiceNotifications.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/notifications`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OwnershipsInvoiceNotificationsFromJSON(body), text);
    }

    return config;
}

/**
* Get ownerships invoice notifications
*/
export function getOwnershipInvoiceNotifications<T>(requestParameters: GetOwnershipInvoiceNotificationsRequest, requestConfig?: runtime.TypedQueryConfig<T, OwnershipsInvoiceNotifications>): QueryConfig<T> {
    return getOwnershipInvoiceNotificationsRaw(requestParameters, requestConfig);
}

/**
 * Search and retrieve personal & ownership invoice transactions.
 */
function getOwnershipInvoiceTransactionsRaw<T>(requestParameters: GetOwnershipInvoiceTransactionsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<InvoicedTransactions>> = {}): QueryConfig<T> {
    if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
        throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getOwnershipInvoiceTransactions.');
    }

    if (requestParameters.date === null || requestParameters.date === undefined) {
        throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getOwnershipInvoiceTransactions.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/owners/{ownerId}/ownerships/invoicetransactions/{date}`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(InvoicedTransactionsFromJSON), text);
    }

    return config;
}

/**
* Search and retrieve personal & ownership invoice transactions.
*/
export function getOwnershipInvoiceTransactions<T>(requestParameters: GetOwnershipInvoiceTransactionsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<InvoicedTransactions>>): QueryConfig<T> {
    return getOwnershipInvoiceTransactionsRaw(requestParameters, requestConfig);
}


import { getMountings, Mounting } from '@generated/licenseholders/src';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = Mounting[] | undefined;

export interface Entities {
  mountings?: { [key: string]: Result };
}

const useGetFavorites = (licenseId: number): ApiResponse<Result, Entities> =>
  useApi({
    query: getMountings<Entities>(
      {
        licenseId,
      },
      {
        transform: responseBody => ({
          mountings: { [licenseId]: responseBody },
        }),
        update: {
          mountings: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities.mountings?.[licenseId],
  });

export default useGetFavorites;

// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Trainee details
 * @export
 * @interface LicenseHolderTraineeInfo
 */
export interface LicenseHolderTraineeInfo  {
    /**
     * The name of the trainer that the license holder is a trainee with
     * @type {string}
     * @memberof LicenseHolderTraineeInfo
     */
    traineeWithName?: string;
    /**
     * The id of the trainer that the license holder is a trainee with
     * @type {string}
     * @memberof LicenseHolderTraineeInfo
     */
    traineeWithId?: string;
}

export function LicenseHolderTraineeInfoFromJSON(json: any): LicenseHolderTraineeInfo {
    return {
        'traineeWithName': !exists(json, 'traineeWithName') ? undefined : json['traineeWithName'],
        'traineeWithId': !exists(json, 'traineeWithId') ? undefined : json['traineeWithId'],
    };
}

export function LicenseHolderTraineeInfoToJSON(value?: LicenseHolderTraineeInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'traineeWithName': value.traineeWithName,
        'traineeWithId': value.traineeWithId,
    };
}



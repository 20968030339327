import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import {
  getBasicInformation,
  HorseBasicInformation,
} from '../../generated/horses/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = HorseBasicInformation | undefined;

export interface Entities {
  horse?: {
    [id: number]: Result;
  };
}

const getQuery = (horseId: number): QueryConfig<Entities> =>
  getBasicInformation<Entities>(
    {
      horseId,
    },
    {
      queryKey: `horse:${horseId}`,
      transform: responseBody => ({
        horse: {
          [horseId]: responseBody,
        },
      }),
      update: {
        horse: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetHorse = (horseId: number): ApiResponse<Result, Entities> => {
  const query = getQuery(horseId);
  const selector = (state: State): Result => state.entities?.horse?.[horseId];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetHorse;

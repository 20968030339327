// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface HorseEnrollment
 */
export interface HorseEnrollment  {
    /**
     * 
     * @type {number}
     * @memberof HorseEnrollment
     */
    propositionNumber: number;
    /**
     * 
     * @type {string}
     * @memberof HorseEnrollment
     */
    trackCode: string;
    /**
     * 
     * @type {Date}
     * @memberof HorseEnrollment
     */
    raceDay: Date;
    /**
     * 
     * @type {string}
     * @memberof HorseEnrollment
     */
    raceName: string;
    /**
     * 
     * @type {string}
     * @memberof HorseEnrollment
     */
    surface: string;
    /**
     * 
     * @type {string}
     * @memberof HorseEnrollment
     */
    distance: string;
    /**
     * 
     * @type {number}
     * @memberof HorseEnrollment
     */
    priceSum: number;
}

export function HorseEnrollmentFromJSON(json: any): HorseEnrollment {
    return {
        'propositionNumber': json['propositionNumber'],
        'trackCode': json['trackCode'],
        'raceDay': parseDateTime(json['raceDay']),
        'raceName': json['raceName'],
        'surface': json['surface'],
        'distance': json['distance'],
        'priceSum': json['priceSum'],
    };
}

export function HorseEnrollmentToJSON(value?: HorseEnrollment): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'propositionNumber': value.propositionNumber,
        'trackCode': value.trackCode,
        'raceDay': value.raceDay.toLocalDate(),
        'raceName': value.raceName,
        'surface': value.surface,
        'distance': value.distance,
        'priceSum': value.priceSum,
    };
}



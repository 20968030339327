import { generatePath } from 'react-router-dom';

const getMonthUrl = (
  direction: 'current' | 'prev' | 'next',
  year: number,
  month: number,
  path: string,
): string =>
  generatePath(path, {
    year: (direction === 'current'
      ? year
      : direction === 'next'
        ? month === 12
          ? year + 1
          : year
        : month === 1
          ? year - 1
          : year
    ).toString(),
    month: (direction === 'current'
      ? month
      : direction === 'next'
        ? month === 12
          ? 1
          : month + 1
        : month === 1
          ? 12
          : month - 1
    ).toString(),
  });

export default getMonthUrl;

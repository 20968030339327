// eslint-disable-next-line @typescript-eslint/ban-types
export default function cleanObject<T extends object>(object: T): Partial<T> {
  const clean: Partial<T> = {};

  Object.keys(object)
    .filter(key => object[key] != null && object[key] !== '')
    .forEach(key => {
      clean[key] =
        typeof object[key] === 'string' ? object[key].trim() : object[key];
    });

  return clean;
}

// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    RegistrationStatusOptionsRegistrationStatuses,
    RegistrationStatusOptionsRegistrationStatusesFromJSON,
    RegistrationStatusOptionsRegistrationStatusesToJSON,
} from './';

/**
 * 
 * @export
 * @interface RegistrationStatusOptions
 */
export interface RegistrationStatusOptions  {
    /**
     * Id of the horse.
     * @type {number}
     * @memberof RegistrationStatusOptions
     */
    horseId: number;
    /**
     * Name of the horse.
     * @type {string}
     * @memberof RegistrationStatusOptions
     */
    horseName: string;
    /**
     * Horse\'s date of birth, i.e. earliest valid from date, format yyyy-MM-dd.
     * @type {Date}
     * @memberof RegistrationStatusOptions
     */
    dateOfBirth: Date;
    /**
     * The possible registration status changes for this horse.
     * @type {Array<RegistrationStatusOptionsRegistrationStatuses>}
     * @memberof RegistrationStatusOptions
     */
    registrationStatuses?: Array<RegistrationStatusOptionsRegistrationStatuses>;
}

export function RegistrationStatusOptionsFromJSON(json: any): RegistrationStatusOptions {
    return {
        'horseId': json['horseId'],
        'horseName': json['horseName'],
        'dateOfBirth': parseDateTime(json['dateOfBirth']),
        'registrationStatuses': !exists(json, 'registrationStatuses') ? undefined : (json['registrationStatuses'] as Array<any>).map(RegistrationStatusOptionsRegistrationStatusesFromJSON),
    };
}

export function RegistrationStatusOptionsToJSON(value?: RegistrationStatusOptions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'horseName': value.horseName,
        'dateOfBirth': value.dateOfBirth.toLocalDate(),
        'registrationStatuses': value.registrationStatuses === undefined ? undefined : (value.registrationStatuses as Array<any>).map(RegistrationStatusOptionsRegistrationStatusesToJSON),
    };
}



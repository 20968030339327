import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import {
  getPersonalInformation,
  PersonalInformation,
} from '../../generated/user/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  personalInformation?: {
    [id: number]: PersonalInformation;
  };
}

const getQuery = (licenseId: number): QueryConfig<Entities> =>
  getPersonalInformation<Entities>(
    {
      licenseId,
    },
    {
      transform: responseBody => ({
        personalInformation: {
          [licenseId]: responseBody,
        },
      }),
      update: {
        personalInformation: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetPersonalInformation = (
  id: number,
): ApiResponse<PersonalInformation | undefined, Entities> => {
  const query = getQuery(id);
  const selector = (state: State): PersonalInformation | undefined =>
    state.entities?.personalInformation?.[id];

  return useApi<PersonalInformation | undefined>({
    query,
    selector,
  });
};

export default useGetPersonalInformation;

import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import {
  DetailedInvoice,
  getOwnershipDetailedInvoice,
} from '../../generated/sportactors/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = DetailedInvoice | undefined;

export interface Entities {
  ownershipDetailedInvoice?: {
    [key: string]: Result;
  };
}

const getQuery = (
  ownerId: number,
  documentId: number,
  key: string,
): QueryConfig<Entities> =>
  getOwnershipDetailedInvoice<Entities>(
    {
      ownerId,
      documentId,
    },
    {
      force: true,
      transform: responseBody => ({
        ownershipDetailedInvoice: {
          [key]: responseBody,
        },
      }),
      update: {
        ownershipDetailedInvoice: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetOwnershipDetailedInvoice = (
  ownerId: number,
  documentId: number,
): ApiResponse<Result, Entities> => {
  const key = `ownershipDetailedInvoice-${ownerId}-${documentId.toString()}`;
  const query = getQuery(ownerId, documentId, key);
  const selector = (state: State): Result =>
    state.entities?.ownershipDetailedInvoice?.[key];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetOwnershipDetailedInvoice;

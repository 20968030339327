import { useGetStartDeclarationInformation } from '@apis';
import Card from '@components/Card';
import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import useNumericParams from '@hooks/useNumericParams';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import Icon from '@primitives/Icon';
import { Link } from '@primitives/Link';
import Loading from '@primitives/Loading';
import { RightToBracket as SignInAlt } from '@styled-icons/fa-solid';
import React from 'react';
import { useParams } from 'react-router-dom';

import Receipt from '../utils/Receipt';

interface Props {
  user: AuthenticatedUser;
}

function ReceiptPage({ user }: Props): JSX.Element {
  const { action } = useParams<{ action: string }>();

  const { horseId, propId } = useNumericParams<{
    horseId: number;
    propId: number;
  }>();

  const { data, loading, status, error } = useGetStartDeclarationInformation(
    horseId,
    propId,
    true,
  );

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <PageLayout
      singleCard
      headline="Ny startanmälan"
      pageSize="medium"
      noCard
      backLink={{
        to:
          action === 'uppdatera'
            ? '/minasidor/startanmalan/mina-startanmalningar'
            : `/minasidor/startanmalan/tavlingsdagar/${data.raceDayId}`,
      }}
    >
      <Card p={0}>
        <Receipt
          horseId={horseId}
          propId={propId}
          licenseId={user.licenseId}
          propWeight={data.proposition.weight}
          actions={[
            <Button
              key="new"
              as={Link}
              to={`/minasidor/startanmalan/tavlingsdagar/${data.raceDayId}`}
              colorScheme="saveAlt"
            >
              Ny startanmälan
              <Icon as={SignInAlt} size={14} mt="-2px" ml={2} />
            </Button>,
            <Button
              key="mine"
              as={Link}
              to="/minasidor/startanmalan/mina-startanmalningar"
              colorScheme="secondary"
            >
              Mina startanmälda hästar
            </Button>,
          ]}
        />
      </Card>
    </PageLayout>
  );
}
export default ReceiptPage;

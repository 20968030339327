// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Used for drivers/riders/trainers
 * @export
 * @interface SportInfoStatistics
 */
export interface SportInfoStatistics  {
    /**
     * 
     * @type {number}
     * @memberof SportInfoStatistics
     */
    licenseId: number;
    /**
     * 
     * @type {string}
     * @memberof SportInfoStatistics
     */
    year: string;
    /**
     * 
     * @type {number}
     * @memberof SportInfoStatistics
     */
    numberOfStarts: number;
    /**
     * 
     * @type {number}
     * @memberof SportInfoStatistics
     */
    firstPlaces: number;
    /**
     * 
     * @type {number}
     * @memberof SportInfoStatistics
     */
    secondPlaces: number;
    /**
     * 
     * @type {number}
     * @memberof SportInfoStatistics
     */
    thirdPlaces: number;
    /**
     * 
     * @type {number}
     * @memberof SportInfoStatistics
     */
    winningRates: number;
    /**
     * 
     * @type {number}
     * @memberof SportInfoStatistics
     */
    prizeMoney: number;
    /**
     * 
     * @type {number}
     * @memberof SportInfoStatistics
     */
    prizeMoneyPerStart: number;
    /**
     * 
     * @type {number}
     * @memberof SportInfoStatistics
     */
    bonus: number;
    /**
     * 
     * @type {number}
     * @memberof SportInfoStatistics
     */
    prizeMoneyIncludingBonus: number;
}

export function SportInfoStatisticsFromJSON(json: any): SportInfoStatistics {
    return {
        'licenseId': json['licenseId'],
        'year': json['year'],
        'numberOfStarts': json['numberOfStarts'],
        'firstPlaces': json['firstPlaces'],
        'secondPlaces': json['secondPlaces'],
        'thirdPlaces': json['thirdPlaces'],
        'winningRates': json['winningRates'],
        'prizeMoney': json['prizeMoney'],
        'prizeMoneyPerStart': json['prizeMoneyPerStart'],
        'bonus': json['bonus'],
        'prizeMoneyIncludingBonus': json['prizeMoneyIncludingBonus'],
    };
}

export function SportInfoStatisticsToJSON(value?: SportInfoStatistics): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseId': value.licenseId,
        'year': value.year,
        'numberOfStarts': value.numberOfStarts,
        'firstPlaces': value.firstPlaces,
        'secondPlaces': value.secondPlaces,
        'thirdPlaces': value.thirdPlaces,
        'winningRates': value.winningRates,
        'prizeMoney': value.prizeMoney,
        'prizeMoneyPerStart': value.prizeMoneyPerStart,
        'bonus': value.bonus,
        'prizeMoneyIncludingBonus': value.prizeMoneyIncludingBonus,
    };
}



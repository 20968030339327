// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    GenderGroupGenders,
    GenderGroupGendersFromJSON,
    GenderGroupGendersToJSON,
    HorseBreed,
    HorseBreedFromJSON,
    HorseBreedToJSON,
} from './';

/**
 * 
 * @export
 * @interface HorseSearchReference
 */
export interface HorseSearchReference  {
    /**
     * 
     * @type {number}
     * @memberof HorseSearchReference
     */
    horseId: number;
    /**
     * 
     * @type {string}
     * @memberof HorseSearchReference
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof HorseSearchReference
     */
    yearOfBirth: string;
    /**
     * 
     * @type {GenderGroupGenders}
     * @memberof HorseSearchReference
     */
    horseGender: GenderGroupGenders;
    /**
     * 
     * @type {HorseBreed}
     * @memberof HorseSearchReference
     */
    horseBreed: HorseBreed;
}

export function HorseSearchReferenceFromJSON(json: any): HorseSearchReference {
    return {
        'horseId': json['horseId'],
        'name': json['name'],
        'yearOfBirth': json['yearOfBirth'],
        'horseGender': GenderGroupGendersFromJSON(json['horseGender']),
        'horseBreed': HorseBreedFromJSON(json['horseBreed']),
    };
}

export function HorseSearchReferenceToJSON(value?: HorseSearchReference): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'name': value.name,
        'yearOfBirth': value.yearOfBirth,
        'horseGender': GenderGroupGendersToJSON(value.horseGender),
        'horseBreed': HorseBreedToJSON(value.horseBreed),
    };
}



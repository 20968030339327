import {
  insertPreselectedDriver,
  InsertPreselectedDriverRequest,
} from '@generated/user/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../utils/types';
import { useApiMutateWithoutData } from '../utils/useApiMutate';

type Entities = State['entities'];
type ActionParams = InsertPreselectedDriverRequest['driver'];

const useInsertPreSelectedDriver = (
  licenseId: number,
): MutationResponseWithoutData<Entities, ActionParams> =>
  useApiMutateWithoutData({
    queryFunc: (driver: ActionParams): QueryConfig<Entities> => {
      const key = `${licenseId}`;
      return insertPreselectedDriver<Entities>(
        {
          licenseId,
          driver,
        },
        {
          optimisticUpdate: {
            preSelectedDrivers: prev => ({
              ...prev,
              [key]: prev[key].concat([driver]),
            }),
          },
        },
      );
    },

    queryKey: 'insertPreselectedDriver',
  });

export default useInsertPreSelectedDriver;

// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * The representative address. Only for trainers/owners own horse
 * @export
 * @interface Address
 */
export interface Address  {
    /**
     * The address name.
     * @type {string}
     * @memberof Address
     */
    name: string;
    /**
     * The street address.
     * @type {string}
     * @memberof Address
     */
    streetAddress: string;
    /**
     * The post address.
     * @type {string}
     * @memberof Address
     */
    postAddress: string;
}

export function AddressFromJSON(json: any): Address {
    return {
        'name': json['name'],
        'streetAddress': json['streetAddress'],
        'postAddress': json['postAddress'],
    };
}

export function AddressToJSON(value?: Address): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'streetAddress': value.streetAddress,
        'postAddress': value.postAddress,
    };
}



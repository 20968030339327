// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface PropopsitionInfo
 */
export interface PropopsitionInfo  {
    /**
     * 
     * @type {number}
     * @memberof PropopsitionInfo
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PropopsitionInfo
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof PropopsitionInfo
     */
    weight: number;
}

export function PropopsitionInfoFromJSON(json: any): PropopsitionInfo {
    return {
        'id': json['id'],
        'number': json['number'],
        'weight': json['weight'],
    };
}

export function PropopsitionInfoToJSON(value?: PropopsitionInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'number': value.number,
        'weight': value.weight,
    };
}



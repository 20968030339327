// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface HorseStatistic
 */
export interface HorseStatistic  {
    /**
     * The horse id.
     * @type {number}
     * @memberof HorseStatistic
     */
    horseId?: number;
    /**
     * The horse statistics year.
     * @type {string}
     * @memberof HorseStatistic
     */
    year?: string;
    /**
     * The number of starts.
     * @type {string}
     * @memberof HorseStatistic
     */
    numberOfStarts?: string;
    /**
     * The placements.
     * @type {string}
     * @memberof HorseStatistic
     */
    placements?: string;
    /**
     * The prize money.
     * @type {number}
     * @memberof HorseStatistic
     */
    prizeMoney?: number;
    /**
     * The bonus money.
     * @type {number}
     * @memberof HorseStatistic
     */
    bonusMoney?: number;
    /**
     * The total money, prize money + bonus money.
     * @type {number}
     * @memberof HorseStatistic
     */
    totalMoney?: number;
    /**
     * General handicap.
     * @type {string}
     * @memberof HorseStatistic
     */
    generalHandicap?: string;
}

export function HorseStatisticFromJSON(json: any): HorseStatistic {
    return {
        'horseId': !exists(json, 'horseId') ? undefined : json['horseId'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'numberOfStarts': !exists(json, 'numberOfStarts') ? undefined : json['numberOfStarts'],
        'placements': !exists(json, 'placements') ? undefined : json['placements'],
        'prizeMoney': !exists(json, 'prizeMoney') ? undefined : json['prizeMoney'],
        'bonusMoney': !exists(json, 'bonusMoney') ? undefined : json['bonusMoney'],
        'totalMoney': !exists(json, 'totalMoney') ? undefined : json['totalMoney'],
        'generalHandicap': !exists(json, 'generalHandicap') ? undefined : json['generalHandicap'],
    };
}

export function HorseStatisticToJSON(value?: HorseStatistic): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'year': value.year,
        'numberOfStarts': value.numberOfStarts,
        'placements': value.placements,
        'prizeMoney': value.prizeMoney,
        'bonusMoney': value.bonusMoney,
        'totalMoney': value.totalMoney,
        'generalHandicap': value.generalHandicap,
    };
}



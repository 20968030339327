import styled from 'styled-components';
import { typography, TypographyProps } from 'styled-system';

import { base, BaseProps } from '../base';

interface ButtonLinkProps extends BaseProps, TypographyProps {}

const ButtonLink = styled.button.attrs({ type: 'button' })<ButtonLinkProps>`
  ${base(typography)}
  &:focus {
    outline: none;
  }
`;

ButtonLink.defaultProps = {
  color: 'blue',
};

export default ButtonLink;

import theme from '@main/theme';
import { ArrowDownSVG } from '@primitives/Arrow';
import { BaseProps } from '@primitives/base';
import { base } from '@primitives/base';
import Box from '@primitives/Box';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { DisplayProps, PositionProps, typography } from 'styled-system';

interface SelectProps extends BaseProps, PositionProps, DisplayProps {}

export const ArrowDown = styled(ArrowDownSVG)`
  color: ${props => props.color};
  position: absolute;
  right: 10px;
  top: 12px;
  pointer-events: none;
`;

export const StyledDiv = styled.div<BaseProps>`
  position: relative;
  ${base()}
`;

export const StyledSelect = styled.select<SelectProps>`
  -webkit-appearance: none;
  width: 100%;
  outline: none;
  border-radius: 0;
  padding: 0 25px 0 8px;
  font-weight: normal;
  font-size: ${props => props.theme.fontSizes.small}px;
  line-height: 30px;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.gray3};
  color: ${props => props.theme.colors.gray6};
  ${base(typography)};
  &::-ms-expand {
    display: none;
  }
  &:disabled {
    background: ${props => props.theme.colors.gray2};
  }
  height: 32px;
`;

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Label = styled.label`
  margin-bottom: 4px;
  margin-left: 3px;
  letter-spacing: -0.07px;
  font-size: ${props => props.theme.fontSizes.tiny}px;
  line-height: 13px;
  font-weight: bold;
`;

type Opts = {
  options?: { value: string | number; label: string; disabled?: boolean }[];
  children?: ReactNode;
};

export const Option = styled.option``;

type Props = React.SelectHTMLAttributes<HTMLSelectElement> &
  Omit<SelectProps, 'theme'> & {
    width?: string;
    color?: string;
    label?: string;
  } & Opts;

const Select = ({
  label,
  options,
  children,
  color,
  width,
  ...props
}: Props): JSX.Element => (
  <Wrapper>
    {label && <Label>{label}</Label>}
    <StyledDiv width={width ? width : '100%'}>
      <StyledSelect {...props}>
        {options?.map(({ disabled, value, label: option }, index) => (
          <Option value={value} key={`${index}_${option}`} disabled={disabled}>
            {option}
          </Option>
        ))}
        {children}
      </StyledSelect>
      <ArrowDown
        color={color ? color : theme.colors.blue}
        size="8"
        className="hidden-print"
      />
    </StyledDiv>
  </Wrapper>
);

export default Select;

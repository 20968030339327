import { useCreateLeaseTermination, useGetRepresentativeHorses } from '@apis';
import Confirm from '@components/Confirm';
import { AuthenticatedUser } from '@generated/account/src';
import { OwnershipTransfer } from '@generated/ownership-transfers/src';
import { OperatorEnum, resetQuery } from '@main/state/queriesReducer';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

interface Props {
  user: AuthenticatedUser;
  transfer: OwnershipTransfer;
  onClose: () => void;
  endDate: string;
}

const CancelLeasingConfirm = ({
  user,
  transfer,
  onClose,
  endDate,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const {
    error: contractError,
    fetch: createTermination,
    loading: isSigning,
  } = useCreateLeaseTermination(user.licenseId);

  const { refresh } = useGetRepresentativeHorses(user.licenseId);

  const history = useHistory();

  const onSign = useCallback(async () => {
    const { success } = await createTermination({
      transferId: transfer.id,
      terminationDate: endDate,
    });

    if (success) {
      dispatch(
        resetQuery(`ongoingTerminations:${user.licenseId}`, OperatorEnum.EQUAL),
      );
      dispatch(
        resetQuery(`terminableLeases:${user.licenseId}`, OperatorEnum.EQUAL),
      );

      refresh();

      history.push('/minasidor/leasing/uppsagningar?message=signing');
    }
  }, [
    createTermination,
    dispatch,
    endDate,
    history,
    transfer.id,
    user.licenseId,
    refresh,
  ]);

  return (
    <Confirm
      open
      closeModal={onClose}
      loading={isSigning}
      colorScheme="primary"
      onAction={onSign}
      error={contractError}
      title={'Säga upp leasing?'}
      action="Skicka"
      description={
        <>
          <p>
            Är du säker på att du vill säga upp leasingavtalet för hästen{' '}
            <b>{transfer.horse.horseName}</b>?
          </p>
          <p>
            <b>Uppsägningsdatum</b>
            <br />
            {endDate}
          </p>
        </>
      }
    />
  );
};

export default CancelLeasingConfirm;

import { useAddPartOwners, useGetPartOwners, useRemovePartOwner } from '@apis';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import Remove from '@primitives/Button/Remove';
import { Input } from '@primitives/Form';
import { Label, Small } from '@primitives/Typography';
import { CircleNotch } from '@styled-icons/fa-solid';
import React, { useState } from 'react';
import { useCallback } from 'react';
import styled, { keyframes } from 'styled-components';

import PersonError, { combineErrors } from '../PersonError';

type Props = {
  licenseId: number;
  transferId: number;
  setMessage: (message: string) => void;
  whenAdded: () => Promise<void>;
  representative: string;
  isLeasing?: boolean;
};

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(CircleNotch)`
  animation: ${rotation} 1.5s linear infinite;
  color: ${p => p.theme.colors.gray5};
`;

const AddPartOwners = ({
  licenseId,
  transferId,
  setMessage,
  whenAdded,
  representative,
  isLeasing = false,
}: Props): JSX.Element => {
  const serviceType = isLeasing ? 'LEASING' : 'OWNERSHIP_TRANSFERS';

  const [idNumber, setIdNumber] = useState<string>('');
  const { data: partOwners, refresh } = useGetPartOwners(licenseId, transferId);
  const {
    loading,
    fetch: addPartOwners,
    status,
    errors,
    errorCode,
    error: errorMessage,
  } = useAddPartOwners(licenseId, serviceType);

  const { fetch: removePartOwner } = useRemovePartOwner(licenseId, serviceType);

  const [deleting, setDeleting] = useState<number | undefined>();

  const deletePartowner = useCallback(
    async partOwnerId => {
      setDeleting(partOwnerId);
      await removePartOwner({
        transferId,
        partOwnerId,
      });
      await refresh();

      await whenAdded();
      setDeleting(undefined);
    },
    [refresh, removePartOwner, transferId, whenAdded],
  );

  const idNumberIsValid = /^(19|20)?\d{10}$/.test(
    idNumber.replace(/[^\d]/g, ''),
  );

  const combinedErrors = errors || {};

  if (errorCode) {
    combinedErrors[errorCode] = [{}];
  }

  if (status === 404) {
    combinedErrors[status] = [{}];
  }

  return (
    <Box verticalSpacing={4}>
      <Box
        display="flex"
        alignItems="flex-start"
        mt={4}
        horizontalSpacing={[0, , 4]}
        flexDirection={['column', , 'row']}
      >
        <Label fontSize="small" lineHeight="32px">
          Ange person-/organisationsnummer
        </Label>
        <Box>
          <Input
            type="text"
            name="idNumber"
            onChange={e => {
              const { value } = e.target;
              if (value === '' || /[\d+\s-]+/.test(value.slice(-1))) {
                setIdNumber(value);
              }
            }}
            value={idNumber}
            width="auto"
            placeholder="NNNNNN-NNNN"
          />
          <Small color="dim-gray" mt={1} fontStyle="italic">
            Ange med 10 siffror
          </Small>
        </Box>
        <Button
          variant="primary"
          disabled={!idNumberIsValid}
          loading={loading}
          width={['100%', , 'auto']}
          onClick={async () => {
            await addPartOwners({
              partOwnerPersonalNumbers: [idNumber.replace(/\s/g, '')],
              transferId,
            });
            setIdNumber('');
            await refresh();

            await whenAdded();
          }}
        >
          {isLeasing ? 'Hämta hyrestagare' : 'Hämta delägare'}
        </Button>
      </Box>

      <PersonError
        setMessage={setMessage}
        errors={combineErrors({
          errors,
          status,
          errorCode,
          idNumber,
          errorMessage,
        })}
      />
      <Box verticalSpacing={1}>
        <Label as="div" fontSize="small">
          {isLeasing
            ? 'Företrädare för hyrestagare'
            : 'Företrädare för ägarskap'}
        </Label>
        <Box>{representative}</Box>
      </Box>

      {partOwners?.length > 0 && (
        <Box verticalSpacing={1}>
          <Label as="div" fontSize="small">
            {isLeasing ? 'Övriga hyrestagare' : 'Delägare'}
          </Label>
          <Box verticalSpacing={3}>
            {partOwners.map(person => (
              <Box
                display="flex"
                alignItems="center"
                horizontalSpacing={4}
                key={person.id}
              >
                <span>{person.name}</span>
                {deleting === person.id ? (
                  <Spinner size="14" />
                ) : (
                  <Remove
                    confirm={false}
                    onClick={async () => {
                      await deletePartowner(person.id);
                    }}
                  />
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AddPartOwners;

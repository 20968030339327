// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    NegativeDopingResult,
    NegativeDopingResultFromJSON,
    NegativeDopingResultToJSON,
    NegativeDopingResultsCalendar,
    NegativeDopingResultsCalendarFromJSON,
    NegativeDopingResultsCalendarToJSON,
    NegativeDopingResultsWithoutRace,
    NegativeDopingResultsWithoutRaceFromJSON,
    NegativeDopingResultsWithoutRaceToJSON,
} from '../models';

export interface GetNegativeDopingResultsRequest {
    raceDayId: number;
}

export interface GetNegativeDopingResultsWithoutRaceRequest {
    fromdate: Date;
    todate: Date;
}

export interface GetRacesWithNegativeDopingRequest {
    fromdate: Date;
    todate: Date;
}


/**
 * Fetch list of horses with negative doping results for a raceday.
 */
function getNegativeDopingResultsRaw<T>(requestParameters: GetNegativeDopingResultsRequest, requestConfig: runtime.TypedQueryConfig<T, NegativeDopingResult> = {}): QueryConfig<T> {
    if (requestParameters.raceDayId === null || requestParameters.raceDayId === undefined) {
        throw new runtime.RequiredError('raceDayId','Required parameter requestParameters.raceDayId was null or undefined when calling getNegativeDopingResults.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/raceinfo/dopingtests/results/{raceDayId}/racedays`.replace(`{${"raceDayId"}}`, encodeURIComponent(String(requestParameters.raceDayId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(NegativeDopingResultFromJSON(body), text);
    }

    return config;
}

/**
* Fetch list of horses with negative doping results for a raceday.
*/
export function getNegativeDopingResults<T>(requestParameters: GetNegativeDopingResultsRequest, requestConfig?: runtime.TypedQueryConfig<T, NegativeDopingResult>): QueryConfig<T> {
    return getNegativeDopingResultsRaw(requestParameters, requestConfig);
}

/**
 * Fetches a list of horses with negative doping results without connection to a race for the specified fromdate and todate.
 */
function getNegativeDopingResultsWithoutRaceRaw<T>(requestParameters: GetNegativeDopingResultsWithoutRaceRequest, requestConfig: runtime.TypedQueryConfig<T, NegativeDopingResultsWithoutRace> = {}): QueryConfig<T> {
    if (requestParameters.fromdate === null || requestParameters.fromdate === undefined) {
        throw new runtime.RequiredError('fromdate','Required parameter requestParameters.fromdate was null or undefined when calling getNegativeDopingResultsWithoutRace.');
    }

    if (requestParameters.todate === null || requestParameters.todate === undefined) {
        throw new runtime.RequiredError('todate','Required parameter requestParameters.todate was null or undefined when calling getNegativeDopingResultsWithoutRace.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.fromdate !== undefined) {
        queryParameters['fromdate'] = (requestParameters.fromdate as any).toLocalDate();
    }


    if (requestParameters.todate !== undefined) {
        queryParameters['todate'] = (requestParameters.todate as any).toLocalDate();
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/raceinfo/dopingtests/results/noncompetitive`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(NegativeDopingResultsWithoutRaceFromJSON(body), text);
    }

    return config;
}

/**
* Fetches a list of horses with negative doping results without connection to a race for the specified fromdate and todate.
*/
export function getNegativeDopingResultsWithoutRace<T>(requestParameters: GetNegativeDopingResultsWithoutRaceRequest, requestConfig?: runtime.TypedQueryConfig<T, NegativeDopingResultsWithoutRace>): QueryConfig<T> {
    return getNegativeDopingResultsWithoutRaceRaw(requestParameters, requestConfig);
}

/**
 * Fetch list of racedays with and indicator of existing negative doping results for the specified fromdate and todate.
 */
function getRacesWithNegativeDopingRaw<T>(requestParameters: GetRacesWithNegativeDopingRequest, requestConfig: runtime.TypedQueryConfig<T, NegativeDopingResultsCalendar> = {}): QueryConfig<T> {
    if (requestParameters.fromdate === null || requestParameters.fromdate === undefined) {
        throw new runtime.RequiredError('fromdate','Required parameter requestParameters.fromdate was null or undefined when calling getRacesWithNegativeDoping.');
    }

    if (requestParameters.todate === null || requestParameters.todate === undefined) {
        throw new runtime.RequiredError('todate','Required parameter requestParameters.todate was null or undefined when calling getRacesWithNegativeDoping.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.fromdate !== undefined) {
        queryParameters['fromdate'] = (requestParameters.fromdate as any).toLocalDate();
    }


    if (requestParameters.todate !== undefined) {
        queryParameters['todate'] = (requestParameters.todate as any).toLocalDate();
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/raceinfo/dopingtests/results/racedays`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(NegativeDopingResultsCalendarFromJSON(body), text);
    }

    return config;
}

/**
* Fetch list of racedays with and indicator of existing negative doping results for the specified fromdate and todate.
*/
export function getRacesWithNegativeDoping<T>(requestParameters: GetRacesWithNegativeDopingRequest, requestConfig?: runtime.TypedQueryConfig<T, NegativeDopingResultsCalendar>): QueryConfig<T> {
    return getRacesWithNegativeDopingRaw(requestParameters, requestConfig);
}


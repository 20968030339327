import React from 'react';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';

import { getQueries, store } from '../main/store';

const withRedux =
  () =>
  (Component: React.ComponentType): React.ComponentType =>
  (): JSX.Element => (
    <Provider store={store}>
      <ReduxQueryProvider queriesSelector={getQueries}>
        <Component />
      </ReduxQueryProvider>
    </Provider>
  );

export default withRedux;

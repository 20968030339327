// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    GenderGroupGenders,
    GenderGroupGendersFromJSON,
    GenderGroupGendersToJSON,
} from './';

/**
 * All possible genders on a horse, NOT exclusive for this current view
 * @export
 * @interface GenderGroup
 */
export interface GenderGroup  {
    /**
     * 
     * @type {string}
     * @memberof GenderGroup
     */
    name: string;
    /**
     * 
     * @type {Array<GenderGroupGenders>}
     * @memberof GenderGroup
     */
    genders: Array<GenderGroupGenders>;
}

export function GenderGroupFromJSON(json: any): GenderGroup {
    return {
        'name': json['name'],
        'genders': (json['genders'] as Array<any>).map(GenderGroupGendersFromJSON),
    };
}

export function GenderGroupToJSON(value?: GenderGroup): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'genders': (value.genders as Array<any>).map(GenderGroupGendersToJSON),
    };
}



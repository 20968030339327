import { Entities } from '@apis';
import { deleteStartMonitoring } from '@generated/user/src';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../utils/types';
import { useApiMutateWithoutData } from '../utils/useApiMutate';

const useDeleteStartMonitoring = (
  licenseId: number,
): MutationResponseWithoutData<Entities, number> =>
  useApiMutateWithoutData({
    queryFunc: (horseId: number): QueryConfig<Entities> =>
      deleteStartMonitoring(
        {
          licenseId,
          horseId,
        },
        {
          optimisticUpdate: {
            getStartMonitorings: prev => ({
              ...prev,
              [licenseId]: prev[licenseId].filter(
                item => item.horseId !== horseId,
              ),
            }),
          },
        },
      ),
    queryKey: 'deleteStartMonitoring',
  });

export default useDeleteStartMonitoring;

// tslint:disable
/**
 * Web API Track Service
 * Service for fetching tracks
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Track,
    TrackFromJSON,
    TrackToJSON,
} from '../models';

export interface GetTrackByCodeRequest {
    trackCode: string;
}

export interface GetTracksRequest {
    licenseTracks?: boolean;
}


/**
 * Fetch track by track code.
 */
function getTrackByCodeRaw<T>(requestParameters: GetTrackByCodeRequest, requestConfig: runtime.TypedQueryConfig<T, Track> = {}): QueryConfig<T> {
    if (requestParameters.trackCode === null || requestParameters.trackCode === undefined) {
        throw new runtime.RequiredError('trackCode','Required parameter requestParameters.trackCode was null or undefined when calling getTrackByCode.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tracks/{trackCode}`.replace(`{${"trackCode"}}`, encodeURIComponent(String(requestParameters.trackCode))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(TrackFromJSON(body), text);
    }

    return config;
}

/**
* Fetch track by track code.
*/
export function getTrackByCode<T>(requestParameters: GetTrackByCodeRequest, requestConfig?: runtime.TypedQueryConfig<T, Track>): QueryConfig<T> {
    return getTrackByCodeRaw(requestParameters, requestConfig);
}

/**
 * Fetch a list of license tracks or all tracks based on the input parameter licenseTracks value true or false respectively.
 */
function getTracksRaw<T>(requestParameters: GetTracksRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Track>> = {}): QueryConfig<T> {
    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.licenseTracks !== undefined) {
        queryParameters['licenseTracks'] = requestParameters.licenseTracks;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tracks`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(TrackFromJSON), text);
    }

    return config;
}

/**
* Fetch a list of license tracks or all tracks based on the input parameter licenseTracks value true or false respectively.
*/
export function getTracks<T>(requestParameters: GetTracksRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Track>>): QueryConfig<T> {
    return getTracksRaw(requestParameters, requestConfig);
}


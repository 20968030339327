// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface StartsRaceInformation
 */
export interface StartsRaceInformation  {
    /**
     * Race date
     * @type {Date}
     * @memberof StartsRaceInformation
     */
    date: Date;
    /**
     * Display date
     * @type {string}
     * @memberof StartsRaceInformation
     */
    displayDate: string;
    /**
     * The race day id for the proposition.
     * @type {number}
     * @memberof StartsRaceInformation
     */
    raceDayId: number;
    /**
     * The number that the race has on the current day.
     * @type {number}
     * @memberof StartsRaceInformation
     */
    raceNumber: number;
    /**
     * Whether or not a link to the race day should be presented
     * @type {boolean}
     * @memberof StartsRaceInformation
     */
    linkable: boolean;
}

export function StartsRaceInformationFromJSON(json: any): StartsRaceInformation {
    return {
        'date': parseDateTime(json['date']),
        'displayDate': json['displayDate'],
        'raceDayId': json['raceDayId'],
        'raceNumber': json['raceNumber'],
        'linkable': json['linkable'],
    };
}

export function StartsRaceInformationToJSON(value?: StartsRaceInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'date': value.date.toLocalDate(),
        'displayDate': value.displayDate,
        'raceDayId': value.raceDayId,
        'raceNumber': value.raceNumber,
        'linkable': value.linkable,
    };
}



import {
  HttpMethods,
  httpMethods,
  NetworkHandler,
  NetworkInterface,
  NetworkOptions,
  Url,
} from 'redux-query';
import superagent from 'superagent';

import auth from './auth';

export const createRequest = (
  url: Url,
  method: HttpMethods,
  body: NetworkOptions['body'],
): superagent.SuperAgentRequest => {
  switch (method) {
    case httpMethods.HEAD:
      return superagent.head(url, body);
    case httpMethods.GET:
      return superagent.get(url, body);
    case httpMethods.POST:
      return superagent.post(url, body);
    case httpMethods.PUT:
      return superagent.put(url, body);
    case httpMethods.PATCH:
      return superagent.patch(url, body);
    case httpMethods.DELETE:
      return superagent.delete(url, body);
    default:
      throw new Error(`Unsupported HTTP method: ${method}`);
  }
};

const superagentNetworkInterface: NetworkInterface = (
  url,
  method,
  { body, headers = {}, credentials } = {},
) => {
  const request = createRequest(url, method, body).retry(3);

  if (credentials === 'include') {
    request.withCredentials();
  }

  const execute: NetworkHandler['execute'] = async cb => {
    await auth.isRefreshing;

    const accessToken = auth.token || null;

    headers.Authorization = accessToken;

    if (headers) {
      request.set(headers);
    }

    request.end((err, response) => {
      const resStatus = (response && response.status) || 0;
      const resBody = (response && response.body) || undefined;
      const resText = (response && response.text) || undefined;
      const resHeaders = (response && response.header) || undefined;

      cb(err, resStatus, resBody, resText, resHeaders);
    });
  };

  const abort: NetworkHandler['abort'] = () => request.abort();

  return {
    abort,
    execute,
  };
};

export default superagentNetworkInterface;

import isNumeric from '@utils/isNumeric';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  items: Yup.array().of(
    Yup.object().shape({
      productInformations: Yup.array().of(
        Yup.object().shape({
          text: Yup.string()
            .nullable()
            .test({
              name: 'textValidation',
              message: 'Obligatorisk',
              test: function (value: string) {
                return !(this.parent.amount && !value);
              },
            }),
          // Using string instead of number to avoid validation issues
          amount: Yup.string()
            .nullable()
            // .test({
            //   name: 'amountRequired',
            //   message: 'Obligatorisk',
            //   test: function (value: string) {
            //     return !(this.parent.text && !value);
            //   },
            // })
            .test({
              name: 'amountNumeric',
              message: 'Ange ett tal',
              test: function (value: string) {
                return !(this.parent.text && value && !isNumeric(value));
              },
            }),
        }),
      ),
    }),
  ),
});

export default validationSchema;

export const DEFAULT_FROM_YEAR = 1995;

const getYears = (
  fromYear = DEFAULT_FROM_YEAR,
  untilYear = new Date().getFullYear() + 1,
): number[] => {
  let pointer = fromYear;
  const yearArr: number[] = [];

  while (pointer <= untilYear) {
    yearArr.push(pointer);
    pointer++;
  }

  return yearArr;
};

export default getYears;

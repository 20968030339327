import {
  getResultsByOwnerships,
  SportActorResults,
} from '../../generated/sportactors/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = SportActorResults[] | undefined;

export interface Entities {
  ownershipResults?: {
    [id: number]: Result;
  };
}

const useGetOwnershipResults = (
  ownerId: number,
): ApiResponse<Result, Entities> =>
  useApi<Result>({
    query: getResultsByOwnerships<Entities>(
      {
        ownerId,
      },
      {
        transform: responseBody => ({
          ownershipResults: {
            [ownerId]: responseBody,
          },
        }),
        update: {
          ownershipResults: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities?.ownershipResults?.[ownerId],
  });

export default useGetOwnershipResults;

import { useGetLicenseHolder } from '@apis';
import { AuthenticatedUser } from '@generated/account/src';
import Box from '@primitives/Box';
import isDriver from '@utils/isDriver';
import isTrainer from '@utils/isTrainer';
import React from 'react';

import InformationEntry from './InformationEntry';

interface Props {
  user: AuthenticatedUser;
}

export default function LicenseInfo({ user }: Props): JSX.Element | null {
  const { data, loading } = useGetLicenseHolder(user.licenseId);

  if (loading || !data) {
    return null;
  }

  const {
    homeTrack,
    trainingPlace,
    licenseInfo,
    lowestRidingWeight,
    deputyInfo,
  } = data;

  return (
    <Box>
      <InformationEntry
        label="Licens"
        value={`${licenseInfo.code + licenseInfo.category}, ${
          licenseInfo.description
        }`}
      />

      {isDriver(user) && lowestRidingWeight && (
        <InformationEntry label="Ridvikt" value={`${lowestRidingWeight} kg`} />
      )}

      {isTrainer(user) && (
        <>
          <InformationEntry label="Hemmabana" value={homeTrack} />
          <InformationEntry label="Träningsort" value={trainingPlace} />
        </>
      )}

      {deputyInfo && (
        <InformationEntry
          label="Ställföreträdare"
          value={deputyInfo?.name || ''}
        />
      )}
    </Box>
  );
}

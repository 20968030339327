// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    AddedHorse,
    AddedHorseFromJSON,
    AddedHorseToJSON,
    Breeder,
    BreederFromJSON,
    BreederToJSON,
    BreedingAndResult,
    BreedingAndResultFromJSON,
    BreedingAndResultToJSON,
    BreedingResultsStatistics,
    BreedingResultsStatisticsFromJSON,
    BreedingResultsStatisticsToJSON,
    BreedingStatistics,
    BreedingStatisticsFromJSON,
    BreedingStatisticsToJSON,
    BreedingSummaryStatistics,
    BreedingSummaryStatisticsFromJSON,
    BreedingSummaryStatisticsToJSON,
    DeceasedHorse,
    DeceasedHorseFromJSON,
    DeceasedHorseToJSON,
} from './';

/**
 * The search results, only one entry will be populated depending on the given search category
 * @export
 * @interface StudbookSearchResult
 */
export interface StudbookSearchResult  {
    /**
     * The breeding and breeding results, populated for search category: STAMBOK_BETECKNINGAR
     * @type {Array<BreedingAndResult>}
     * @memberof StudbookSearchResult
     */
    breedingAndResults?: Array<BreedingAndResult>;
    /**
     * Added horses, populated for search category: STAMBOK_NYTILLKOMNA
     * @type {Array<AddedHorse>}
     * @memberof StudbookSearchResult
     */
    addedHorses?: Array<AddedHorse>;
    /**
     * Deceased horses, populated for search category: STAMBOK_AVGAENDE
     * @type {Array<DeceasedHorse>}
     * @memberof StudbookSearchResult
     */
    deceasedHorses?: Array<DeceasedHorse>;
    /**
     * Breeding statistics, populated for search category: STAMBOK_STAT_BETECKNINGAR
     * @type {Array<BreedingStatistics>}
     * @memberof StudbookSearchResult
     */
    breedingStatistics?: Array<BreedingStatistics>;
    /**
     * Breeding summary statistics, populated for search category: STAMBOK_SAM_BETECKNINGAR
     * @type {Array<BreedingSummaryStatistics>}
     * @memberof StudbookSearchResult
     */
    breedingSummaryStatistics?: Array<BreedingSummaryStatistics>;
    /**
     * 
     * @type {BreedingResultsStatistics}
     * @memberof StudbookSearchResult
     */
    breedingResultsStatistics?: BreedingResultsStatistics;
    /**
     * Contains list of breeders and their horses, populated for search category: STAMBOK_UPPFODARE
     * @type {Array<Breeder>}
     * @memberof StudbookSearchResult
     */
    breeders?: Array<Breeder>;
}

export function StudbookSearchResultFromJSON(json: any): StudbookSearchResult {
    return {
        'breedingAndResults': !exists(json, 'breedingAndResults') ? undefined : (json['breedingAndResults'] as Array<any>).map(BreedingAndResultFromJSON),
        'addedHorses': !exists(json, 'addedHorses') ? undefined : (json['addedHorses'] as Array<any>).map(AddedHorseFromJSON),
        'deceasedHorses': !exists(json, 'deceasedHorses') ? undefined : (json['deceasedHorses'] as Array<any>).map(DeceasedHorseFromJSON),
        'breedingStatistics': !exists(json, 'breedingStatistics') ? undefined : (json['breedingStatistics'] as Array<any>).map(BreedingStatisticsFromJSON),
        'breedingSummaryStatistics': !exists(json, 'breedingSummaryStatistics') ? undefined : (json['breedingSummaryStatistics'] as Array<any>).map(BreedingSummaryStatisticsFromJSON),
        'breedingResultsStatistics': !exists(json, 'breedingResultsStatistics') ? undefined : BreedingResultsStatisticsFromJSON(json['breedingResultsStatistics']),
        'breeders': !exists(json, 'breeders') ? undefined : (json['breeders'] as Array<any>).map(BreederFromJSON),
    };
}

export function StudbookSearchResultToJSON(value?: StudbookSearchResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'breedingAndResults': value.breedingAndResults === undefined ? undefined : (value.breedingAndResults as Array<any>).map(BreedingAndResultToJSON),
        'addedHorses': value.addedHorses === undefined ? undefined : (value.addedHorses as Array<any>).map(AddedHorseToJSON),
        'deceasedHorses': value.deceasedHorses === undefined ? undefined : (value.deceasedHorses as Array<any>).map(DeceasedHorseToJSON),
        'breedingStatistics': value.breedingStatistics === undefined ? undefined : (value.breedingStatistics as Array<any>).map(BreedingStatisticsToJSON),
        'breedingSummaryStatistics': value.breedingSummaryStatistics === undefined ? undefined : (value.breedingSummaryStatistics as Array<any>).map(BreedingSummaryStatisticsToJSON),
        'breedingResultsStatistics': BreedingResultsStatisticsToJSON(value.breedingResultsStatistics),
        'breeders': value.breeders === undefined ? undefined : (value.breeders as Array<any>).map(BreederToJSON),
    };
}



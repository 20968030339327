// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Signatory,
    SignatoryFromJSON,
    SignatoryToJSON,
    Signatures,
    SignaturesFromJSON,
    SignaturesToJSON,
} from './';

/**
 * 
 * @export
 * @interface OwnershipTransferContract
 */
export interface OwnershipTransferContract  {
    /**
     * 
     * @type {Array<Signatory>}
     * @memberof OwnershipTransferContract
     */
    signatories?: Array<Signatory>;
    /**
     * 
     * @type {Signatures}
     * @memberof OwnershipTransferContract
     */
    signatures?: Signatures;
}

export function OwnershipTransferContractFromJSON(json: any): OwnershipTransferContract {
    return {
        'signatories': !exists(json, 'signatories') ? undefined : (json['signatories'] as Array<any>).map(SignatoryFromJSON),
        'signatures': !exists(json, 'signatures') ? undefined : SignaturesFromJSON(json['signatures']),
    };
}

export function OwnershipTransferContractToJSON(value?: OwnershipTransferContract): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'signatories': value.signatories === undefined ? undefined : (value.signatories as Array<any>).map(SignatoryToJSON),
        'signatures': SignaturesToJSON(value.signatures),
    };
}



// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface PedigreeHorse
 */
export interface PedigreeHorse  {
    /**
     * 
     * @type {number}
     * @memberof PedigreeHorse
     */
    horseId: number;
    /**
     * 
     * @type {string}
     * @memberof PedigreeHorse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PedigreeHorse
     */
    registrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PedigreeHorse
     */
    yearOfBirth?: string;
    /**
     * 
     * @type {PedigreeHorse}
     * @memberof PedigreeHorse
     */
    father?: PedigreeHorse;
    /**
     * 
     * @type {PedigreeHorse}
     * @memberof PedigreeHorse
     */
    mother?: PedigreeHorse;
    /**
     * 
     * @type {boolean}
     * @memberof PedigreeHorse
     */
    linkable: boolean;
}

export function PedigreeHorseFromJSON(json: any): PedigreeHorse {
    return {
        'horseId': json['horseId'],
        'name': json['name'],
        'registrationNumber': !exists(json, 'registrationNumber') ? undefined : json['registrationNumber'],
        'yearOfBirth': !exists(json, 'yearOfBirth') ? undefined : json['yearOfBirth'],
        'father': !exists(json, 'father') ? undefined : PedigreeHorseFromJSON(json['father']),
        'mother': !exists(json, 'mother') ? undefined : PedigreeHorseFromJSON(json['mother']),
        'linkable': json['linkable'],
    };
}

export function PedigreeHorseToJSON(value?: PedigreeHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'name': value.name,
        'registrationNumber': value.registrationNumber,
        'yearOfBirth': value.yearOfBirth,
        'father': PedigreeHorseToJSON(value.father),
        'mother': PedigreeHorseToJSON(value.mother),
        'linkable': value.linkable,
    };
}



// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    DriverTravelFee,
    DriverTravelFeeFromJSON,
    DriverTravelFeeToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface CreateDriverTravelFeeRequest {
    licenseId: number;
    driverTravelFee: DriverTravelFee;
}

export interface DeleteDriverTravelFeeRequest {
    licenseId: number;
    trackId: string;
}

export interface GetDriverTravelFeesRequest {
    licenseId: number;
}

export interface UpdateDriverTravelFeeRequest {
    licenseId: number;
    driverTravelFee: DriverTravelFee;
}


/**
 * Create a driver travel fee for the license holder
 */
function createDriverTravelFeeRaw<T>(requestParameters: CreateDriverTravelFeeRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling createDriverTravelFee.');
    }

    if (requestParameters.driverTravelFee === null || requestParameters.driverTravelFee === undefined) {
        throw new runtime.RequiredError('driverTravelFee','Required parameter requestParameters.driverTravelFee was null or undefined when calling createDriverTravelFee.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/drivertravelfees`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || DriverTravelFeeToJSON(requestParameters.driverTravelFee),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Create a driver travel fee for the license holder
*/
export function createDriverTravelFee<T>(requestParameters: CreateDriverTravelFeeRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return createDriverTravelFeeRaw(requestParameters, requestConfig);
}

/**
 * Delete (reset to zero) a driver travel fee for the license holder
 */
function deleteDriverTravelFeeRaw<T>(requestParameters: DeleteDriverTravelFeeRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling deleteDriverTravelFee.');
    }

    if (requestParameters.trackId === null || requestParameters.trackId === undefined) {
        throw new runtime.RequiredError('trackId','Required parameter requestParameters.trackId was null or undefined when calling deleteDriverTravelFee.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/drivertravelfees/{trackId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"trackId"}}`, encodeURIComponent(String(requestParameters.trackId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Delete (reset to zero) a driver travel fee for the license holder
*/
export function deleteDriverTravelFee<T>(requestParameters: DeleteDriverTravelFeeRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteDriverTravelFeeRaw(requestParameters, requestConfig);
}

/**
 * Fetches a list of driver travel fee for the license holder
 */
function getDriverTravelFeesRaw<T>(requestParameters: GetDriverTravelFeesRequest, requestConfig: runtime.TypedQueryConfig<T, Array<DriverTravelFee>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getDriverTravelFees.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/drivertravelfees`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(DriverTravelFeeFromJSON), text);
    }

    return config;
}

/**
* Fetches a list of driver travel fee for the license holder
*/
export function getDriverTravelFees<T>(requestParameters: GetDriverTravelFeesRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<DriverTravelFee>>): QueryConfig<T> {
    return getDriverTravelFeesRaw(requestParameters, requestConfig);
}

/**
 * Updates a driver travel fee for the license holder
 */
function updateDriverTravelFeeRaw<T>(requestParameters: UpdateDriverTravelFeeRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling updateDriverTravelFee.');
    }

    if (requestParameters.driverTravelFee === null || requestParameters.driverTravelFee === undefined) {
        throw new runtime.RequiredError('driverTravelFee','Required parameter requestParameters.driverTravelFee was null or undefined when calling updateDriverTravelFee.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/tds/{licenseId}/drivertravelfees`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || DriverTravelFeeToJSON(requestParameters.driverTravelFee),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Updates a driver travel fee for the license holder
*/
export function updateDriverTravelFee<T>(requestParameters: UpdateDriverTravelFeeRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateDriverTravelFeeRaw(requestParameters, requestConfig);
}


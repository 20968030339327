// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Address,
    AddressFromJSON,
    AddressToJSON,
} from './';

/**
 * All horse owners.
 * @export
 * @interface Owner
 */
export interface Owner  {
    /**
     * The owner id.
     * @type {number}
     * @memberof Owner
     */
    id: number;
    /**
     * The owner name.
     * @type {string}
     * @memberof Owner
     */
    name: string;
    /**
     * The owner form.
     * @type {string}
     * @memberof Owner
     */
    ownerForm?: string;
    /**
     * 
     * @type {Address}
     * @memberof Owner
     */
    representativeAddress?: Address;
    /**
     * The from date.
     * @type {Date}
     * @memberof Owner
     */
    from: Date;
    /**
     * The to date.
     * @type {Date}
     * @memberof Owner
     */
    to?: Date;
}

export function OwnerFromJSON(json: any): Owner {
    return {
        'id': json['id'],
        'name': json['name'],
        'ownerForm': !exists(json, 'ownerForm') ? undefined : json['ownerForm'],
        'representativeAddress': !exists(json, 'representativeAddress') ? undefined : AddressFromJSON(json['representativeAddress']),
        'from': parseDateTime(json['from']),
        'to': !exists(json, 'to') ? undefined : parseDateTime(json['to']),
    };
}

export function OwnerToJSON(value?: Owner): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'ownerForm': value.ownerForm,
        'representativeAddress': AddressToJSON(value.representativeAddress),
        'from': value.from.toLocalDate(),
        'to': value.to === undefined ? undefined : value.to.toLocalDate(),
    };
}



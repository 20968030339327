// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    BankIdSignRequest,
    BankIdSignRequestFromJSON,
    BankIdSignRequestToJSON,
    BankIdUserSigning,
    BankIdUserSigningFromJSON,
    BankIdUserSigningToJSON,
    CollectedRegistrationStatus,
    CollectedRegistrationStatusFromJSON,
    CollectedRegistrationStatusToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface CancelRegistrationStatusUpdateRequest {
    horseId: number;
    orderReference: string;
}

export interface CollectRegistrationStatusUpdateRequest {
    horseId: number;
    orderReference: string;
}

export interface CollectWithAnimatedQrCodeRegistrationStatusUpdateRequest {
    horseId: number;
    orderReference: string;
    qrTime: number;
}

export interface InitializeRegistrationStatusUpdateRequest {
    horseId: number;
    status: InitializeRegistrationStatusUpdateStatusEnum;
    fromdate: Date;
    bankIdSignRequest: BankIdSignRequest;
}


/**
 * Cancel an ongoing bankid signing request.
 */
function cancelRegistrationStatusUpdateRaw<T>(requestParameters: CancelRegistrationStatusUpdateRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling cancelRegistrationStatusUpdate.');
    }

    if (requestParameters.orderReference === null || requestParameters.orderReference === undefined) {
        throw new runtime.RequiredError('orderReference','Required parameter requestParameters.orderReference was null or undefined when calling cancelRegistrationStatusUpdate.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{horseId}/registrationstatus/bankid/cancel/{orderReference}`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))).replace(`{${"orderReference"}}`, encodeURIComponent(String(requestParameters.orderReference))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Cancel an ongoing bankid signing request.
*/
export function cancelRegistrationStatusUpdate<T>(requestParameters: CancelRegistrationStatusUpdateRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return cancelRegistrationStatusUpdateRaw(requestParameters, requestConfig);
}

/**
 * The client needs to call this endpoint approximately 1 time per second as long as HTTP 200 is returned. When HTTP 201 is returned the update was successfully completed while any other HTTP status means the update was unsuccessful and the client should stop calling the endpoint.
 * Collect a pending registration status update, for a horse, which has been initialized with BankID.
 */
function collectRegistrationStatusUpdateRaw<T>(requestParameters: CollectRegistrationStatusUpdateRequest, requestConfig: runtime.TypedQueryConfig<T, CollectedRegistrationStatus> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling collectRegistrationStatusUpdate.');
    }

    if (requestParameters.orderReference === null || requestParameters.orderReference === undefined) {
        throw new runtime.RequiredError('orderReference','Required parameter requestParameters.orderReference was null or undefined when calling collectRegistrationStatusUpdate.');
    }

    let queryParameters = null;

    const headerParameters : runtime.HttpHeaders = {};

    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{horseId}/registrationstatus/bankid/collect/{orderReference}`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))).replace(`{${"orderReference"}}`, encodeURIComponent(String(requestParameters.orderReference))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CollectedRegistrationStatusFromJSON(body), text);
    }

    return config;
}

/**
* The client needs to call this endpoint approximately 1 time per second as long as HTTP 200 is returned. When HTTP 201 is returned the update was successfully completed while any other HTTP status means the update was unsuccessful and the client should stop calling the endpoint.
* Collect a pending registration status update, for a horse, which has been initialized with BankID.
*/
export function collectWithAnimatedQrCodeRegistrationStatusUpdate<T>(requestParameters: CollectWithAnimatedQrCodeRegistrationStatusUpdateRequest, requestConfig?: runtime.TypedQueryConfig<T, CollectedRegistrationStatus>): QueryConfig<T> {
    return collectWithAnimatedQrCodeRegistrationStatusUpdateRaw(requestParameters, requestConfig);
}

/**
 * The client needs to call this endpoint approximately 1 time per second as long as HTTP 200 is returned. When HTTP 201 is returned the update was successfully completed while any other HTTP status means the update was unsuccessful and the client should stop calling the endpoint.
 * Collect a pending registration status update, for a horse, which has been initialized with BankID.
 */
function collectWithAnimatedQrCodeRegistrationStatusUpdateRaw<T>(requestParameters: CollectWithAnimatedQrCodeRegistrationStatusUpdateRequest, requestConfig: runtime.TypedQueryConfig<T, CollectedRegistrationStatus> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling collectRegistrationStatusUpdate.');
    }

    if (requestParameters.orderReference === null || requestParameters.orderReference === undefined) {
        throw new runtime.RequiredError('orderReference','Required parameter requestParameters.orderReference was null or undefined when calling collectRegistrationStatusUpdate.');
    }

    if (requestParameters.qrTime === null || requestParameters.qrTime === undefined) {
        throw new runtime.RequiredError('qrTime','Required parameter requestParameters.qrTime was null or undefined when calling collectWithAnimatedQrCcodeRegistrationStatusUpdateRaw.');
    }

    let queryParameters = null;

    const headerParameters : runtime.HttpHeaders = {};

    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{horseId}/registrationstatus/bankid/collect/{orderReference}/{qrTime}`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))).replace(`{${"orderReference"}}`, encodeURIComponent(String(requestParameters.orderReference))).replace(`{${"qrTime"}}`, encodeURIComponent(String(requestParameters.qrTime))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CollectedRegistrationStatusFromJSON(body), text);
    }

    return config;
}

/**
* The client needs to call this endpoint approximately 1 time per second as long as HTTP 200 is returned. When HTTP 201 is returned the update was successfully completed while any other HTTP status means the update was unsuccessful and the client should stop calling the endpoint.
* Collect a pending registration status update, for a horse, which has been initialized with BankID.
*/
export function collectRegistrationStatusUpdate<T>(requestParameters: CollectRegistrationStatusUpdateRequest, requestConfig?: runtime.TypedQueryConfig<T, CollectedRegistrationStatus>): QueryConfig<T> {
    return collectRegistrationStatusUpdateRaw(requestParameters, requestConfig);
}

/**
 * When 200 OK is returned the client needs to use the information in the response body to collect the pending BankID transaction.
 * Initializes an update of registration status for a horse with BankID.
 */
function initializeRegistrationStatusUpdateRaw<T>(requestParameters: InitializeRegistrationStatusUpdateRequest, requestConfig: runtime.TypedQueryConfig<T, BankIdUserSigning> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling initializeRegistrationStatusUpdate.');
    }

    if (requestParameters.status === null || requestParameters.status === undefined) {
        throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling initializeRegistrationStatusUpdate.');
    }

    if (requestParameters.fromdate === null || requestParameters.fromdate === undefined) {
        throw new runtime.RequiredError('fromdate','Required parameter requestParameters.fromdate was null or undefined when calling initializeRegistrationStatusUpdate.');
    }

    if (requestParameters.bankIdSignRequest === null || requestParameters.bankIdSignRequest === undefined) {
        throw new runtime.RequiredError('bankIdSignRequest','Required parameter requestParameters.bankIdSignRequest was null or undefined when calling initializeRegistrationStatusUpdate.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.status !== undefined) {
        queryParameters['status'] = requestParameters.status;
    }


    if (requestParameters.fromdate !== undefined) {
        queryParameters['fromdate'] = (requestParameters.fromdate as any).toLocalDate();
    }

    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{horseId}/registrationstatus/bankid/initialize`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || BankIdSignRequestToJSON(requestParameters.bankIdSignRequest),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(BankIdUserSigningFromJSON(body), text);
    }

    return config;
}

/**
* When 200 OK is returned the client needs to use the information in the response body to collect the pending BankID transaction.
* Initializes an update of registration status for a horse with BankID.
*/
export function initializeRegistrationStatusUpdate<T>(requestParameters: InitializeRegistrationStatusUpdateRequest, requestConfig?: runtime.TypedQueryConfig<T, BankIdUserSigning>): QueryConfig<T> {
    return initializeRegistrationStatusUpdateRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum InitializeRegistrationStatusUpdateStatusEnum {
    DEAD = 'DEAD',
    CASTRATE = 'CASTRATE',
    NO_LONGER_IN_FOOD_PRODUCTION = 'NO_LONGER_IN_FOOD_PRODUCTION'
}

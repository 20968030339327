// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    CategorySelection,
    CategorySelectionFromJSON,
    CategorySelectionToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Selection,
    SelectionFromJSON,
    SelectionToJSON,
} from '../models';

export interface GetGenderSellectionsRequest {
    categoryid: string;
}

export interface GetSireLocationsRequest {
    categoryid: string;
}

export interface GetSireSelectionsRequest {
    categoryid: GetSireSelectionsCategoryidEnum;
    registryid: string;
    year: string;
    siretype?: string;
    sirelocation?: string;
}

export interface GetSireTypesRequest {
    categoryid: string;
}

export interface GetStudbookYearsRequest {
    categoryid: string;
}


/**
 * Get category selection options
 */
function getCategoriesRaw<T>( requestConfig: runtime.TypedQueryConfig<T, CategorySelection> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/studbook/categories`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CategorySelectionFromJSON(body), text);
    }

    return config;
}

/**
* Get category selection options
*/
export function getCategories<T>( requestConfig?: runtime.TypedQueryConfig<T, CategorySelection>): QueryConfig<T> {
    return getCategoriesRaw( requestConfig);
}

/**
 * Get gender selection options
 */
function getGenderSellectionsRaw<T>(requestParameters: GetGenderSellectionsRequest, requestConfig: runtime.TypedQueryConfig<T, Selection> = {}): QueryConfig<T> {
    if (requestParameters.categoryid === null || requestParameters.categoryid === undefined) {
        throw new runtime.RequiredError('categoryid','Required parameter requestParameters.categoryid was null or undefined when calling getGenderSellections.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.categoryid !== undefined) {
        queryParameters['categoryid'] = requestParameters.categoryid;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/studbook/genders`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SelectionFromJSON(body), text);
    }

    return config;
}

/**
* Get gender selection options
*/
export function getGenderSellections<T>(requestParameters: GetGenderSellectionsRequest, requestConfig?: runtime.TypedQueryConfig<T, Selection>): QueryConfig<T> {
    return getGenderSellectionsRaw(requestParameters, requestConfig);
}

/**
 * Get registry selection options
 */
function getRegistriesRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Selection> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/studbook/registries`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SelectionFromJSON(body), text);
    }

    return config;
}

/**
* Get registry selection options
*/
export function getRegistries<T>( requestConfig?: runtime.TypedQueryConfig<T, Selection>): QueryConfig<T> {
    return getRegistriesRaw( requestConfig);
}

/**
 * Get sire location selection options
 */
function getSireLocationsRaw<T>(requestParameters: GetSireLocationsRequest, requestConfig: runtime.TypedQueryConfig<T, Selection> = {}): QueryConfig<T> {
    if (requestParameters.categoryid === null || requestParameters.categoryid === undefined) {
        throw new runtime.RequiredError('categoryid','Required parameter requestParameters.categoryid was null or undefined when calling getSireLocations.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.categoryid !== undefined) {
        queryParameters['categoryid'] = requestParameters.categoryid;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/studbook/sirelocations`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SelectionFromJSON(body), text);
    }

    return config;
}

/**
* Get sire location selection options
*/
export function getSireLocations<T>(requestParameters: GetSireLocationsRequest, requestConfig?: runtime.TypedQueryConfig<T, Selection>): QueryConfig<T> {
    return getSireLocationsRaw(requestParameters, requestConfig);
}

/**
 * Get sire selection options for valid categories
 */
function getSireSelectionsRaw<T>(requestParameters: GetSireSelectionsRequest, requestConfig: runtime.TypedQueryConfig<T, Selection> = {}): QueryConfig<T> {
    if (requestParameters.categoryid === null || requestParameters.categoryid === undefined) {
        throw new runtime.RequiredError('categoryid','Required parameter requestParameters.categoryid was null or undefined when calling getSireSelections.');
    }

    if (requestParameters.registryid === null || requestParameters.registryid === undefined) {
        throw new runtime.RequiredError('registryid','Required parameter requestParameters.registryid was null or undefined when calling getSireSelections.');
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
        throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getSireSelections.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.categoryid !== undefined) {
        queryParameters['categoryid'] = requestParameters.categoryid;
    }


    if (requestParameters.registryid !== undefined) {
        queryParameters['registryid'] = requestParameters.registryid;
    }


    if (requestParameters.year !== undefined) {
        queryParameters['year'] = requestParameters.year;
    }


    if (requestParameters.siretype !== undefined) {
        queryParameters['siretype'] = requestParameters.siretype;
    }


    if (requestParameters.sirelocation !== undefined) {
        queryParameters['sirelocation'] = requestParameters.sirelocation;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/studbook/sires`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SelectionFromJSON(body), text);
    }

    return config;
}

/**
* Get sire selection options for valid categories
*/
export function getSireSelections<T>(requestParameters: GetSireSelectionsRequest, requestConfig?: runtime.TypedQueryConfig<T, Selection>): QueryConfig<T> {
    return getSireSelectionsRaw(requestParameters, requestConfig);
}

/**
 * Get sire type selection options
 */
function getSireTypesRaw<T>(requestParameters: GetSireTypesRequest, requestConfig: runtime.TypedQueryConfig<T, Selection> = {}): QueryConfig<T> {
    if (requestParameters.categoryid === null || requestParameters.categoryid === undefined) {
        throw new runtime.RequiredError('categoryid','Required parameter requestParameters.categoryid was null or undefined when calling getSireTypes.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.categoryid !== undefined) {
        queryParameters['categoryid'] = requestParameters.categoryid;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/studbook/siretypes`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SelectionFromJSON(body), text);
    }

    return config;
}

/**
* Get sire type selection options
*/
export function getSireTypes<T>(requestParameters: GetSireTypesRequest, requestConfig?: runtime.TypedQueryConfig<T, Selection>): QueryConfig<T> {
    return getSireTypesRaw(requestParameters, requestConfig);
}

/**
 * Get year selections for the studbook search option - category.
 */
function getStudbookYearsRaw<T>(requestParameters: GetStudbookYearsRequest, requestConfig: runtime.TypedQueryConfig<T, Selection> = {}): QueryConfig<T> {
    if (requestParameters.categoryid === null || requestParameters.categoryid === undefined) {
        throw new runtime.RequiredError('categoryid','Required parameter requestParameters.categoryid was null or undefined when calling getStudbookYears.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.categoryid !== undefined) {
        queryParameters['categoryid'] = requestParameters.categoryid;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/studbook/years`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SelectionFromJSON(body), text);
    }

    return config;
}

/**
* Get year selections for the studbook search option - category.
*/
export function getStudbookYears<T>(requestParameters: GetStudbookYearsRequest, requestConfig?: runtime.TypedQueryConfig<T, Selection>): QueryConfig<T> {
    return getStudbookYearsRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum GetSireSelectionsCategoryidEnum {
    BETECKNINGAR = 'STAMBOK_BETECKNINGAR',
    SAMSTAT_BETECKNINGAR = 'STAMBOK_SAMSTAT_BETECKNINGAR'
}

import {
  EnrolledHorse,
  getTrainerHorsesEnrolledToSpecificStakeRace,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../../utils/types';
import useApi from '../../utils/useApi';

type Results = EnrolledHorse[] | undefined;

export interface Entities {
  trainerHorsesEnrolledToStakeRace?: {
    [key: string]: Results;
  };
}

export default function useGetTrainerHorsesEnrolledToStakeRace(
  trainerId: number,
  propositionId: number,
): ApiResponse<Results, Entities> {
  const key = `${propositionId}-${trainerId}`;
  const query = getTrainerHorsesEnrolledToSpecificStakeRace<Entities>(
    {
      trainerId,
      propositionId,
    },
    {
      queryKey: `horsesEnrolledToStakeRace:${key}`,
      transform: responseBody => ({
        trainerHorsesEnrolledToStakeRace: {
          [key]: responseBody,
        },
      }),
      update: {
        trainerHorsesEnrolledToStakeRace: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): Results =>
    state.entities?.trainerHorsesEnrolledToStakeRace?.[key];

  return useApi<Results>({
    query,
    selector,
  });
}

import { ChartEntry, getChart, GetChartRequest } from '@generated/charts/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  jockeyCharts?: { [key: string]: ChartEntry[] };
  trainerCharts?: { [key: string]: ChartEntry[] };
  horseCharts?: { [key: string]: ChartEntry[] };
  ownerCharts?: { [key: string]: ChartEntry[] };
  breederCharts?: { [key: string]: ChartEntry[] };
  breedingCharts?: { [key: string]: ChartEntry[] };
}

const useGetChart = (
  actorType: string,
  request: GetChartRequest,
): ApiResponse<ChartEntry[], Entities> => {
  const chartKey = JSON.stringify(request);
  const actorKey: keyof Entities = `${actorType}Charts` as keyof Entities;

  const query = getChart<Entities>(request, {
    transform: responseBody => ({
      [actorKey]: responseBody,
    }),
    update: {
      [actorKey]: (
        prev: Entities['jockeyCharts'] | undefined,
        next: Entities['jockeyCharts'],
      ) => {
        if (prev === undefined) {
          return { [chartKey]: next };
        }
        return { ...prev, [chartKey]: next };
      },
    },
  });

  const selector = (state: State): ChartEntry[] =>
    state.entities?.[`${actorType}Charts`]?.[chartKey];

  return useApi<ChartEntry[]>({
    query,
    selector,
  });
};

export default useGetChart;

// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface RaceRaceType
 */
export interface RaceRaceType  {
    /**
     * 
     * @type {string}
     * @memberof RaceRaceType
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof RaceRaceType
     */
    text?: string;
}

export function RaceRaceTypeFromJSON(json: any): RaceRaceType {
    return {
        'code': !exists(json, 'code') ? undefined : json['code'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function RaceRaceTypeToJSON(value?: RaceRaceType): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'code': value.code,
        'text': value.text,
    };
}



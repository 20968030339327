import React from 'react';

import DriverCommission from './DriverCommission';
import DriverTravelFees from './DriverTravelFees';
import Info from './Info';

interface Props {
  licenseId: number;
}

export default function InfoAndFees({ licenseId }: Props): JSX.Element {
  return (
    <>
      <Info licenseId={licenseId} />
      <DriverCommission licenseId={licenseId} />
      <DriverTravelFees licenseId={licenseId} />
    </>
  );
}

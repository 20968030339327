import config from '@config';
import { SearchResult } from '@generated/content/src';
import useIsMobile from '@hooks/useIsMobile';
import Box from '@primitives/Box';
import Icon from '@primitives/Icon';
import { Text } from '@primitives/Typography';
import { UpRightFromSquare as ExternalLinkAlt } from '@styled-icons/fa-solid';
import React from 'react';
import styled from 'styled-components';

const ExternalLink = styled(Text).attrs({
  display: 'flex',
  pl: 2,
  pb: 4,

  height: 'fit-content',
  width: 'fit-content',
  textAlign: 'right',
})``;

interface Props {
  data: SearchResult;
  amountOfHitsOnMainPAge: number;
  query: string;
}

export default function AboveSearchResult({
  data,
  amountOfHitsOnMainPAge,
  query,
}: Props): JSX.Element {
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gridTemplateRows: 'auto',
      }}
    >
      <Box sx={{ gridArea: '1 / 1 / 2 / 2' }}>
        <p>
          {`${data?.total || 0}
        ${data?.total == 1 ? 'träff' : 'träffar'}`}
        </p>
      </Box>
      {
        <Box sx={{ gridArea: '1 / 3 / 2 / 4' }}>
          <ExternalLink
            rel="noopener noreferrer"
            as="a"
            href={`${config.publicWebUrl}/search/?query=${query}`}
          >
            {amountOfHitsOnMainPAge == 0
              ? 'Sök på svenskgalopp.se'
              : isMobile
                ? `${amountOfHitsOnMainPAge || '__'} träffar på svenskgalopp.se`
                : `Din sökning ger även ${
                    amountOfHitsOnMainPAge || '__'
                  } träffar på svenskgalopp.se`}
            <Box>
              <Icon size={12} ml={2} color="deep-blue" as={ExternalLinkAlt} />
            </Box>
          </ExternalLink>
        </Box>
      }
    </Box>
  );
}

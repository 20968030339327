import { CoveringResult } from '@generated/breedings/src';
import Box from '@primitives/Box';
import { ButtonLink } from '@primitives/Button';
import Icon from '@primitives/Icon';
import { Small, Text } from '@primitives/Typography';
import { RightToBracket as SignInAlt } from '@styled-icons/fa-solid';
import React, { useState } from 'react';
import { CellProps } from 'react-table';

import { useCoveringContext, useGetStud } from '../context';
import ResultsForm from './ResultsForm';

export default function EditCell({
  row: { original: row },
}: CellProps<CoveringResult, string>): JSX.Element {
  const [showForm, setShowForm] = useState(false);
  const { studId } = useCoveringContext();
  const stud = useGetStud(studId);

  const hasOutcome = Boolean(row.outcome?.code);

  // "row.registrationStarted" True when a registration is already started in the Sport system, then no further external modifications are allowed
  const regIsClosed =
    !stud || stud.resultRegistrationClosed || row.registrationStarted;

  return (
    <Box>
      {showForm && (
        <ResultsForm
          onClose={() => {
            setShowForm(false);
          }}
          coveringResult={row}
        />
      )}

      {hasOutcome && <Text mb={1}>{row.outcome?.description}</Text>}
      {!regIsClosed &&
        (hasOutcome ? (
          <ButtonLink
            fontSize="small"
            color="gray5"
            sx={{
              textDecoration: 'underline',
            }}
            onClick={() => {
              setShowForm(true);
            }}
          >
            Ändra
          </ButtonLink>
        ) : (
          <ButtonLink
            onClick={() => {
              setShowForm(true);
            }}
          >
            <Icon as={SignInAlt} size={16} mb="2px" mr="2px" /> Registrera
            resultat
          </ButtonLink>
        ))}
      {regIsClosed && <Small color="gray5">Kan inte ändra</Small>}
    </Box>
  );
}

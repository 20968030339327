import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import {
  getPedigree,
  GetPedigreePedigreetreeEnum,
  Pedigree,
} from '../../generated/horses/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type HorsePedigree = Pedigree | undefined;

export interface Entities {
  horsePedigree?: {
    [id: number]: HorsePedigree;
  };
}

const getQuery = (
  horseId: number,
  size: GetPedigreePedigreetreeEnum,
): QueryConfig<Entities> =>
  getPedigree<Entities>(
    {
      horseId,
      pedigreetree: size,
    },
    {
      transform: responseBody => ({
        horsePedigree: {
          [horseId]: responseBody,
        },
      }),
      update: {
        horsePedigree: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetHorsePedigree = (
  horseId: number,
  size: GetPedigreePedigreetreeEnum,
): ApiResponse<HorsePedigree, Entities> => {
  const query = getQuery(horseId, size);
  const selector = (state: State): HorsePedigree =>
    state.entities?.horsePedigree?.[horseId];

  return useApi<HorsePedigree>({
    query,
    selector,
  });
};

export default useGetHorsePedigree;

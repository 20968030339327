import { Entities, useSaveVaccineRegistration } from '@apis';
import { SaveVaccineRegistrationRequest } from '@generated/licenseholders/src';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionPromiseValue } from 'redux-query';

import SetSaveRegistrationData from './utils/type/SetSaveRegistrationData';

interface Props {
  licenseId: number;
  vaccinationDate: Date;
  vaccineId: number;
  horseIds: number[];
  setSaveRegistrationData: SetSaveRegistrationData;
}

const save = async (
  licenseId: number,
  vaccinationDate: Date,
  vaccineId: number,
  horseIds: Array<number>,
  action: (
    args: SaveVaccineRegistrationRequest,
  ) => Promise<ActionPromiseValue<Entities>>,
  setSaveRegistrationData: SetSaveRegistrationData,
): Promise<void> => {
  setSaveRegistrationData({ loading: true, error: null });

  const { body } = await action({
    licenseId,
    vaccination: {
      vaccinationDate,
      vaccineId,
      horseIds,
    },
  });

  setSaveRegistrationData({
    loading: false,
    error: (body && body.errorMessage) || null,
  });
};

export default function SaveRegistration({
  licenseId,
  vaccinationDate,
  vaccineId,
  horseIds,
  setSaveRegistrationData,
}: Props): JSX.Element {
  const [runAction, setRunAction] = useState<boolean>(true);
  const dispatch = useDispatch();

  const { action } = useSaveVaccineRegistration(
    licenseId,
    vaccinationDate,
    vaccineId,
    horseIds,
  );

  useEffect((): void => {
    if (runAction) {
      save(
        licenseId,
        vaccinationDate,
        vaccineId,
        horseIds,
        action,
        setSaveRegistrationData,
      );
    }
    setRunAction(false);
  }, [
    licenseId,
    vaccinationDate,
    vaccineId,
    horseIds,
    action,
    setSaveRegistrationData,
    runAction,
    setRunAction,
    dispatch,
  ]);

  return null;
}

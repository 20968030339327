import { useGetInvoices } from '@apis';
import ContentBlock from '@components/ContentBlock';
import DataGrid from '@components/DataGrid';
import { Invoice } from '@generated/sportactors/src';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import Icon from '@primitives/Icon';
import { Link } from '@primitives/Link';
import Loading from '@primitives/Loading';
import { Text } from '@primitives/Typography';
import { FilePdf } from '@styled-icons/fa-solid';
import { formatTz } from '@utils/dateUtils';
import { formatNumber } from '@utils/formatting';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { CellProps, Column } from 'react-table';

const columns = (licenseId: number, path: string): Column<Invoice>[] => [
  {
    Header: 'Faktura-nummer',
    accessor: row => row.invoiceNumber,
    thStyle: () => ({
      whiteSpace: 'normal',
    }),
    Cell: ({ row, value }: CellProps<Invoice, Date>): JSX.Element => (
      <Link to={`${path}/${row.original?.documentId}`}>{value}</Link>
    ),
  },
  {
    Header: 'Hämta PDF',
    accessor: 'index',
    alignment: 'center',
    thStyle: () => ({
      whiteSpace: 'normal',
    }),
    Cell: ({ row, value }: CellProps<Invoice, number>): JSX.Element =>
      (row.original?.pdfExists && value != null && (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`/storage/invoice/${licenseId}/${row.original.invoiceNumber}/${value}`}
        >
          <Icon size={16} as={FilePdf} color="blue" />
        </a>
      )) ||
      null,
  },
  {
    Header: 'Faktura-datum',
    accessor: 'date',
    sortType: 'datetime',
    id: 'date',
    thStyle: () => ({
      whiteSpace: 'normal',
    }),
    Cell: ({ value }: CellProps<Invoice, Date>) => (
      <>{formatTz(value, 'yyyy-MM-dd')}</>
    ),
  },
  {
    Header: 'Förfallo-datum',
    accessor: 'expirationDate',
    sortType: 'datetime',
    thStyle: () => ({
      whiteSpace: 'normal',
    }),
    Cell: ({ value }: CellProps<Invoice, Date>) => (
      <>{formatTz(value, 'yyyy-MM-dd')}</>
    ),
  },
  {
    Header: 'Belopp',
    accessor: 'amount',
    Cell: ({ value }: CellProps<Invoice, number>): JSX.Element => (
      <>{formatNumber(value)} kr</>
    ),
    alignment: 'right',
  },
  {
    Header: 'Restbelopp',
    accessor: 'residualAmount',
    Cell: ({ value }: CellProps<Invoice, number>): JSX.Element => (
      <>{value ? formatNumber(value) : 0} kr</>
    ),
    alignment: 'right',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Leverantör',
    accessor: 'supplierName',
  },
];

interface Props {
  licenseId: number;
}

const Invoices = ({ licenseId }: Props): JSX.Element => {
  const { data, loading, status, error } = useGetInvoices(licenseId, 1, 1000);
  const { path } = useRouteMatch();

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <Box px={[4, null, 0]} pt={[4, null, 0]}>
      <ContentBlock
        slug="fakturor-och-transaktioner-fakturor"
        alignment="left"
        justifyContent="flex-start"
      />
      {data.length === 0 && <Text>Fann inga fakturor.</Text>}
      <DataGrid
        columns={columns(licenseId, path)}
        data={data}
        defaultSort={[{ id: 'date', desc: true }]}
        showMore
        sortable
        mt={4}
      />
    </Box>
  );
};

export default Invoices;

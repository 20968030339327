import {
  authenticateCredentials,
  AuthenticateCredentialsRequest,
  AuthenticatedUser,
} from '@generated/authenticate/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

type ActionParams = AuthenticateCredentialsRequest['userCredentials'];

export interface Entities {
  user?: AuthenticatedUser;
  authenticationMethod?: string;
  forceChangeOfPassword?: boolean;
}

const useAuthenticateCredentials = (): MutationResponse<
  Entities['user'],
  Entities,
  ActionParams
> => {
  const selector = (state: State): Entities['user'] => state.entities.user;
  const queryFunc = (userCredentials: ActionParams): QueryConfig<Entities> =>
    authenticateCredentials<Entities>(
      {
        useCookie: false,
        userCredentials,
      },
      {
        transform: responseBody => ({
          user: responseBody.authenticatedUser,
          authenticationMethod: responseBody.authenticationMethod,
          forceChangeOfPassword: responseBody.forceChangeOfPassword,
        }),
        update: {
          user: (prev, next): typeof next => next,
          authenticationMethod: (prev, next): typeof next => next,
          forceChangeOfPassword: (prev, next): typeof next => {
            if (next) {
              localStorage.setItem('forceChangeOfPassword', 'true');
            } else {
              localStorage.removeItem('forceChangeOfPassword');
            }

            return next;
          },
        },
      },
    );

  return useApiMutate<Entities['user'], ActionParams>({
    queryFunc,
    selector,
    queryKey: 'authenticateCredentials',
  });
};

export default useAuthenticateCredentials;

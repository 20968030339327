import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { fetchHorseHistory, HorseHistory } from '../../generated/horses/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type History = HorseHistory | undefined;

export interface Entities {
  horseHistory?: {
    [id: number]: History;
  };
}

const getQuery = (horseId: number): QueryConfig<Entities> =>
  fetchHorseHistory<Entities>(
    {
      horseId,
    },
    {
      transform: responseBody => ({
        horseHistory: {
          [horseId]: responseBody,
        },
      }),
      update: {
        horseHistory: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetHorsePedigree = (
  horseId: number,
): ApiResponse<History, Entities> => {
  const query = getQuery(horseId);
  const selector = (state: State): History =>
    state.entities?.horseHistory?.[horseId];

  return useApi<History>({
    query,
    selector,
  });
};

export default useGetHorsePedigree;

import { Blinkers, getBlinkers } from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = Blinkers[];

export interface Entities {
  blinkers?: Result;
}

export default function useGetBlinkers(): ApiResponse<Result, Entities> {
  const query = getBlinkers<Entities>({
    transform: responseBody => ({
      blinkers: responseBody,
    }),
    update: {
      blinkers: (prev, next) => next,
    },
  });

  const selector = (state: State): Result | undefined =>
    state.entities?.blinkers;

  return useApi<Result>({
    query,
    selector,
  });
}

// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface LicenseHolder
 */
export interface LicenseHolder  {
    /**
     * 
     * @type {number}
     * @memberof LicenseHolder
     */
    licenseId: number;
    /**
     * The name of the license holder
     * @type {string}
     * @memberof LicenseHolder
     */
    name?: string;
    /**
     * True if the license holder has statistics.
     * @type {boolean}
     * @memberof LicenseHolder
     */
    hasStatistics?: boolean;
}

export function LicenseHolderFromJSON(json: any): LicenseHolder {
    return {
        'licenseId': json['licenseId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'hasStatistics': !exists(json, 'hasStatistics') ? undefined : json['hasStatistics'],
    };
}

export function LicenseHolderToJSON(value?: LicenseHolder): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'licenseId': value.licenseId,
        'name': value.name,
        'hasStatistics': value.hasStatistics,
    };
}



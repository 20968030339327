import {
  getRaceDayWithResults,
  RaceDayWithResults,
} from '@generated/raceinfo/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  raceDayWithResults?: {
    [key: string]: RaceDayWithResults;
  };
}

interface Props {
  raceDayId: number;
}

export default function useGetRaceDayWithResults({
  raceDayId,
}: Props): ApiResponse<RaceDayWithResults, Entities> {
  const query = getRaceDayWithResults<Entities>(
    {
      raceDayId,
    },
    {
      transform: responseBody => ({
        raceDayWithResults: {
          [raceDayId]: responseBody,
        },
      }),
      force: true,
      update: {
        raceDayWithResults: (prev, next) => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  const selector = (state: State): RaceDayWithResults | undefined =>
    state.entities.raceDayWithResults?.[raceDayId];

  return useApi<RaceDayWithResults>({
    query,
    selector,
  });
}

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    EnrollmentProposition,
    EnrollmentPropositionFromJSON,
    EnrollmentPropositionToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnrollmentRaceDayInformation
 */
export interface EnrollmentRaceDayInformation  {
    /**
     * 
     * @type {string}
     * @memberof EnrollmentRaceDayInformation
     */
    trackName: string;
    /**
     * 
     * @type {Date}
     * @memberof EnrollmentRaceDayInformation
     */
    raceDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof EnrollmentRaceDayInformation
     */
    latestStartRegistrationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof EnrollmentRaceDayInformation
     */
    registrationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof EnrollmentRaceDayInformation
     */
    changeWeightDate: Date;
    /**
     * 
     * @type {Array<EnrollmentProposition>}
     * @memberof EnrollmentRaceDayInformation
     */
    propositions: Array<EnrollmentProposition>;
}

export function EnrollmentRaceDayInformationFromJSON(json: any): EnrollmentRaceDayInformation {
    return {
        'trackName': json['trackName'],
        'raceDate': parseDateTime(json['raceDate']),
        'latestStartRegistrationDate': parseDateTime(json['latestStartRegistrationDate']),
        'registrationDate': parseDateTime(json['registrationDate']),
        'changeWeightDate': parseDateTime(json['changeWeightDate']),
        'propositions': (json['propositions'] as Array<any>).map(EnrollmentPropositionFromJSON),
    };
}

export function EnrollmentRaceDayInformationToJSON(value?: EnrollmentRaceDayInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'trackName': value.trackName,
        'raceDate': value.raceDate.toISOString(),
        'latestStartRegistrationDate': value.latestStartRegistrationDate.toISOString(),
        'registrationDate': value.registrationDate.toISOString(),
        'changeWeightDate': value.changeWeightDate.toISOString(),
        'propositions': (value.propositions as Array<any>).map(EnrollmentPropositionToJSON),
    };
}



// tslint:disable
/**
 * Web API Auth Service
 * Service for supporting user authentication (issuing JWTs) for accessing secured resources.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Role,
    RoleFromJSON,
    RoleToJSON,
    UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './';

/**
 * The authenticated users info, only present if the authentication was successful
 * @export
 * @interface AuthenticatedUser
 */
export interface AuthenticatedUser  {
    /**
     * The organisation that the user belongs to
     * @type {string}
     * @memberof AuthenticatedUser
     */
    organisation: AuthenticatedUserOrganisationEnum;
    /**
     * The source of data
     * @type {string}
     * @memberof AuthenticatedUser
     */
    sourceOfData: AuthenticatedUserSourceOfDataEnum;
    /**
     * The license id (user id)
     * @type {number}
     * @memberof AuthenticatedUser
     */
    licenseId: number;
    /**
     * The license id of russ (user id)
     * @type {number}
     * @memberof AuthenticatedUser
     */
    russLicenseId?: number;
    /**
     * The user name (user alias)
     * @type {string}
     * @memberof AuthenticatedUser
     */
    username: string;
    /**
     * The user roles
     * @type {Array<Role>}
     * @memberof AuthenticatedUser
     */
    roles: Array<Role>;
    /**
     * 
     * @type {UserInfo}
     * @memberof AuthenticatedUser
     */
    userInfo?: UserInfo;
}

export function AuthenticatedUserFromJSON(json: any): AuthenticatedUser {
    return {
        'organisation': json['organisation'],
        'sourceOfData': json['sourceOfData'],
        'licenseId': json['licenseId'],
        'russLicenseId': !exists(json, 'russLicenseId') ? undefined : json['russLicenseId'],
        'username': json['username'],
        'roles': (json['roles'] as Array<any>).map(RoleFromJSON),
        'userInfo': !exists(json, 'userInfo') ? undefined : UserInfoFromJSON(json['userInfo']),
    };
}

export function AuthenticatedUserToJSON(value?: AuthenticatedUser): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'organisation': value.organisation,
        'sourceOfData': value.sourceOfData,
        'licenseId': value.licenseId,
        'russLicenseId': value.russLicenseId,
        'username': value.username,
        'roles': (value.roles as Array<any>).map(RoleToJSON),
        'userInfo': UserInfoToJSON(value.userInfo),
    };
}

/**
* @export
* @enum {string}
*/
export enum AuthenticatedUserOrganisationEnum {
    TROT = 'TROT',
    GALLOP = 'GALLOP'
}
/**
* @export
* @enum {string}
*/
export enum AuthenticatedUserSourceOfDataEnum {
    RUSS = 'RUSS',
    SPORT = 'SPORT'
}



// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    VaccineInjection,
    VaccineInjectionFromJSON,
    VaccineInjectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface VaccinatedHorse
 */
export interface VaccinatedHorse  {
    /**
     * 
     * @type {number}
     * @memberof VaccinatedHorse
     */
    horseId?: number;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedHorse
     */
    horseName?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedHorse
     */
    horseRegistrationNumber?: string;
    /**
     * The horse name to display, may contain additional information like country code.
     * @type {string}
     * @memberof VaccinatedHorse
     */
    horseDisplayName?: string;
    /**
     * 
     * @type {VaccineInjection}
     * @memberof VaccinatedHorse
     */
    vaccineInjection?: VaccineInjection;
    /**
     * 
     * @type {number}
     * @memberof VaccinatedHorse
     */
    age?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VaccinatedHorse
     */
    newVaccinationAllowed?: boolean;
    /**
     * true if any remarks, implies there will be a remarkText also
     * @type {boolean}
     * @memberof VaccinatedHorse
     */
    hasRemark?: boolean;
    /**
     * text with description of the remark, present if hasRemarks is true
     * @type {string}
     * @memberof VaccinatedHorse
     */
    remarkText?: string;
}

export function VaccinatedHorseFromJSON(json: any): VaccinatedHorse {
    return {
        'horseId': !exists(json, 'horseId') ? undefined : json['horseId'],
        'horseName': !exists(json, 'horseName') ? undefined : json['horseName'],
        'horseRegistrationNumber': !exists(json, 'horseRegistrationNumber') ? undefined : json['horseRegistrationNumber'],
        'horseDisplayName': !exists(json, 'horseDisplayName') ? undefined : json['horseDisplayName'],
        'vaccineInjection': !exists(json, 'vaccineInjection') ? undefined : VaccineInjectionFromJSON(json['vaccineInjection']),
        'age': !exists(json, 'age') ? undefined : json['age'],
        'newVaccinationAllowed': !exists(json, 'newVaccinationAllowed') ? undefined : json['newVaccinationAllowed'],
        'hasRemark': !exists(json, 'hasRemark') ? undefined : json['hasRemark'],
        'remarkText': !exists(json, 'remarkText') ? undefined : json['remarkText'],
    };
}

export function VaccinatedHorseToJSON(value?: VaccinatedHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'horseName': value.horseName,
        'horseRegistrationNumber': value.horseRegistrationNumber,
        'horseDisplayName': value.horseDisplayName,
        'vaccineInjection': VaccineInjectionToJSON(value.vaccineInjection),
        'age': value.age,
        'newVaccinationAllowed': value.newVaccinationAllowed,
        'hasRemark': value.hasRemark,
        'remarkText': value.remarkText,
    };
}



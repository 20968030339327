import { useLocation } from 'react-router-dom';

interface Return {
  [key: string]: string | boolean | undefined;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function useQuery<T extends Return = {}>(object: true | undefined): T;

function useQuery(object: false): URLSearchParams;

// eslint-disable-next-line @typescript-eslint/ban-types
function useQuery<T extends Return = {}>(object = true): T | URLSearchParams {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  if (object) {
    const obj = {};
    for (const [key, value] of params.entries()) {
      // Cast boolean values
      if (value === 'true' || value === 'false') {
        obj[key] = value === 'true';
      } else {
        obj[key] = value;
      }
    }
    return obj as T;
  }

  return params;
}

export default useQuery;

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface OngoingStakeRace
 */
export interface OngoingStakeRace  {
    /**
     * 
     * @type {string}
     * @memberof OngoingStakeRace
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof OngoingStakeRace
     */
    propositionId: number;
    /**
     * 
     * @type {number}
     * @memberof OngoingStakeRace
     */
    stakeNumber: number;
    /**
     * 
     * @type {string}
     * @memberof OngoingStakeRace
     */
    trackName: string;
    /**
     * 
     * @type {Date}
     * @memberof OngoingStakeRace
     */
    raceDate: Date;
    /**
     * True when stake is marked as ready
     * @type {boolean}
     * @memberof OngoingStakeRace
     */
    stakeMarkedAsReady: boolean;
}

export function OngoingStakeRaceFromJSON(json: any): OngoingStakeRace {
    return {
        'title': json['title'],
        'propositionId': json['propositionId'],
        'stakeNumber': json['stakeNumber'],
        'trackName': json['trackName'],
        'raceDate': parseDateTime(json['raceDate']),
        'stakeMarkedAsReady': json['stakeMarkedAsReady'],
    };
}

export function OngoingStakeRaceToJSON(value?: OngoingStakeRace): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'propositionId': value.propositionId,
        'stakeNumber': value.stakeNumber,
        'trackName': value.trackName,
        'raceDate': value.raceDate.toLocalDate(),
        'stakeMarkedAsReady': value.stakeMarkedAsReady,
    };
}



import ReactTable, { TableProps } from '@components/ReactTable';
import { Theme } from '@main/theme';
import { base, BaseProps } from '@primitives/base';
import React from 'react';
import { Row } from 'react-table';
import styled from 'styled-components';

interface StyleProps extends Omit<BaseProps, 'theme' | 'color'> {
  wrap?: boolean;
  noHover?: boolean;
  renderRowSubComponentBorder?: boolean;
  renderRowSubComponentNoPadding?: boolean;
  expandableBackground?: keyof Theme['colors'];
  condensed?: boolean;
}

export const Styles = styled.div<StyleProps>`
  font-family: ${props => props.theme.mainFont};

  .datagrid-variant {
    border-spacing: 0;
    border-collapse: none;
    width: 100%;
    overflow-x: auto;
    background-color: ${props => props.theme.colors.white};

    > thead > tr {
      > th {
        border-right: 1px solid ${props => props.theme.colors.vistaWhite};
        border-top: 1px solid ${props => props.theme.colors.vistaWhite};
        border-bottom: 1px solid ${props => props.theme.colors.vistaWhite};
        font-weight: bold;
        background-color: ${props => props.theme.colors.gray1};
        font-size: ${props => props.theme.fontSizes.dataGridHeader}px;
        line-height: 16px;
        white-space: nowrap;
        padding: 12px 10px 10px 10px;
        height: 44px;
        vertical-align: middle;
        padding-left: 5px;
      }
      > th:first-of-type {
        border-left: 1px solid ${props => props.theme.colors.vistaWhite};
        z-index: 3;
      }
      > th:not(:first-of-type) {
        position: relative;
        z-index: 2;
      }
    }
    > tfoot > tr,
    > tbody > tr {
      > td {
        white-space: nowrap;
        border: none;
        box-shadow: none;
        font-size: ${props =>
          props.condensed
            ? props.theme.fontSizes.small
            : props.theme.fontSizes.dataGridCell}px;
        line-height: 16px;
        padding: ${props => (props.condensed ? '10px 6px' : '10px')};
        border-right: 1px solid ${props => props.theme.colors.vistaWhite};
        vertical-align: top;

        &:first-of-type {
          border-left: 1px solid ${props => props.theme.colors.vistaWhite};
        }
      }

      &:first-child > td {
        border-top: none;
      }

      &:not(:last-of-type) > td {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: ${props => props.theme.colors.vistaWhite};
      }

      &:last-of-type > td {
        border-bottom: 1px solid ${props => props.theme.colors.vistaWhite};
      }

      &:hover {
        background: ${props => props.theme.colors['very-light-blue']};
        ${p => p.noHover && `background: transparent`};
      }

      &.expanded > td {
        background: ${props =>
          (props?.expandableBackground &&
            props.theme.colors[props.expandableBackground]) ||
          props.theme.colors['light-blue']};
      }
    }

    .subRow {
      height: 31px !important;
      font-size: 12px !important;
      border-bottom: 1px solid ${props => props.theme.colors.vistaWhite};

      td {
        font-size: 12px !important;
        position: relative;
        z-index: 1;
        padding: 5px 10px 7px 7px;
        max-width: 100%;
        white-space: break-spaces;
      }

      td:first-child {
        box-shadow: none !important;
        width: 100%;
      }
    }
  }

  .sticky-mobile-table {
    border-right: 1px solid ${props => props.theme.colors.vistaWhite};

    .datagrid-variant > tbody > tr > td:first-child,
    .datagrid-variant > thead > tr > th:first-child {
      position: sticky;
      z-index: 5;
      left: 0;
      max-width: 150px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;

      ${p => p.wrap && `white-space: normal;`}
    }

    .datagrid-variant > tbody > tr > td:first-child,
    .two-sticky-columns > tbody > tr > td:nth-child(2) {
      background: white;
    }

    &.can-scroll-left {
      .datagrid-variant > tbody > tr > td:first-child {
        box-shadow: 0 0 10px 4px #0000003b;
        clip-path: inset(0px -14px 0px 0px);
      }

      .two-sticky-columns > tbody > tr > td:nth-child(2) {
        box-shadow: 0 0 10px 4px #0000003b;
        clip-path: inset(0px -14px 0px 0px);
      }
    }

    &:after {
      position: absolute;
      z-index: 1;
      opacity: 0;
      transition: opacity 0.3s;
      content: '';
      pointer-events: none;
      right: 1px;
      top: 0;
      bottom: 0;
      width: 30px;
      box-shadow: inset -14px 0 10px -10px #0000003b;
    }

    &.can-scroll-right {
      &:after {
        opacity: 1;
      }
    }

    .datagrid-variant > thead > tr > th:last-of-type,
    .datagrid-variant > tbody > tr > td:last-of-type {
      border-right: none !important;
    }
  }

  .doubleVertLine {
    border-left: 3px solid ${props => props.theme.colors['deep-blue']} !important;
    padding: 8px 10px 10px 8px;
  }

  .datagrid-variant.unified-sub-row > tbody > tr > td:first-child {
    position: sticky;
    left: 0px;
    z-index: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    background: white;

    ${p => p.wrap && `white-space: normal;`}
  }

  .two-sticky-columns {
    > tbody > tr > td:first-child,
    > thead > tr > th:first-child {
      width: 40px;
    }

    > tbody > tr > td:nth-child(2),
    > thead > tr > th:nth-child(2) {
      position: sticky;
      left: 39px;
      z-index: 5;
      max-width: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      ${p => p.wrap && `white-space: normal;`}
    }
  }

  .striped .rowOdd {
    background-color: ${props => props.theme.colors.white};
  }
  .striped .rowOdd > td:first-child {
    /* !important used here because otherwise the color is overwritten in DataGrid/index:
    .datagrid-variant > tbody > tr > td:first-child,
   .two-sticky-columns > tbody > tr > td:nth-child(2){}
   */
    background-color: ${props => props.theme.colors.white} !important;
  }

  .striped .rowEven {
    background-color: ${props => props.theme.colors.snow};
  }
  .striped .rowEven > td:first-child {
    /* !important used here because otherwise the color is overwritten in DataGrid/index:
     .datagrid-variant > tbody > tr > td:first-child,
    .two-sticky-columns > tbody > tr > td:nth-child(2){}
    */
    background-color: ${props => props.theme.colors.snow} !important;
  }

  && {
    .expanded-table-cell {
      white-space: normal;
      ${p =>
        p.renderRowSubComponentBorder &&
        `border-left: 3px solid ${p => p.theme.colors['deep-blue']};`}
      box-shadow: none!important;
      ${p =>
        p.renderRowSubComponentNoPadding
          ? `padding-left: 5px;`
          : `padding-left: 50px;`}
    }

    .sticky-mobile-table {
      .expanded-table-cell {
        padding-left: 0;
      }
    }
  }

  ${base()}
`;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DataGridProps<T extends object> = TableProps<T> &
  StyleProps & {
    updatePageProps?: (pageCount: number, rows: number) => Promise<void>;
    openSubRows?: Row<T>[];
  };

// eslint-disable-next-line @typescript-eslint/ban-types
function DataGrid<T extends object>({
  data,
  columns,
  overflow = 'auto',
  stickyOnMobile = true,
  pagination = false,
  showMore = false,
  sortable = false,
  striped = false,
  subRowWide = false,
  subRowDoubleVerticalLine = false,
  unifiedSubRow = false,
  twoStickyColumns = false,
  title,
  openSubRows,
  defaultSort,
  renderListItem,
  renderRowSubComponent,
  renderRowSubComponentOnClick,
  subRow,
  tfoot,
  updatePageProps,
  orderByFn,
  defaultExpanded,
  getRowId,
  emptyState,
  emptyRow,
  noContent,
  condensed = false,
  ...props
}: DataGridProps<T>): JSX.Element {
  return (
    <Styles condensed={condensed} {...props}>
      <ReactTable<T>
        data={data || []}
        columns={columns}
        overflow={overflow}
        pagination={pagination}
        showMore={showMore}
        stickyOnMobile={stickyOnMobile}
        sortable={sortable}
        striped={striped}
        openSubRows={openSubRows}
        unifiedSubRow={unifiedSubRow}
        subRowDoubleVerticalLine={subRowDoubleVerticalLine}
        title={title}
        twoStickyColumns={twoStickyColumns}
        renderListItem={renderListItem}
        renderRowSubComponentOnClick={renderRowSubComponentOnClick}
        renderRowSubComponent={renderRowSubComponent}
        defaultSort={defaultSort}
        subRow={subRow}
        subRowWide={subRowWide}
        tfoot={tfoot}
        updatePageProps={updatePageProps}
        expandableBackground={props?.expandableBackground}
        orderByFn={orderByFn}
        defaultExpanded={defaultExpanded}
        getRowId={getRowId}
        emptyState={emptyState}
        emptyRow={emptyRow}
        noContent={noContent}
        condensed={condensed}
      />
    </Styles>
  );
}

export default DataGrid;

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Horse
 */
export interface Horse  {
    /**
     * 
     * @type {number}
     * @memberof Horse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Horse
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Horse
     */
    weight?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Horse
     */
    succeeded: boolean;
    /**
     * 
     * @type {string}
     * @memberof Horse
     */
    errorMessage?: string;
}

export function HorseFromJSON(json: any): Horse {
    return {
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'succeeded': json['succeeded'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function HorseToJSON(value?: Horse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'weight': value.weight,
        'succeeded': value.succeeded,
        'errorMessage': value.errorMessage,
    };
}



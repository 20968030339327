import theme from '@main/theme';
import { base, BaseProps } from '@primitives/base';
import Box from '@primitives/Box';
import Icon from '@primitives/Icon';
import { Link } from '@primitives/Link';
import List from '@primitives/List';
import { Text } from '@primitives/Typography';
import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { StyledIcon } from 'styled-icons/types';
import { variant } from 'styled-system';

interface Props {
  items: {
    to: string;
    icon?: StyledIcon;
    text: string;
    onClick?: (event: MouseEvent) => void;
    exact?: boolean;
  }[];
  label?: string;
  background?: boolean;
  thin?: boolean;
  eventTrackingTag?: string;
}

const markerSize = 8;

export const Marker = styled.div<BaseProps>`
  width: 0;
  height: 0;
  border-left: ${markerSize * 2 + 3}px solid transparent;
  border-right: ${markerSize * 2 + 3}px solid transparent;
  border-bottom: 14px solid ${props => props.theme.colors.white};
  position: absolute;
  top: ${props => props.theme.sizes.pageHeader - markerSize + 1}px;
  left: 50%;
  margin-left: -19px;
  ${base()}
`;

const MenuItem = styled(Link)<{
  variant: 'normal' | 'background';
}>`
  ${variant({
    prop: 'variant',
    variants: {
      normal: {
        '&:hover:not(.active)': {
          bg: 'very-light-blue',
        },
      },
      background: {
        '&:hover:not(.active)': {
          bg: transparentize(0.9, theme.colors['deep-blue']),
        },
      },
    },
  })}
`;

MenuItem.defaultProps = {
  variant: 'normal',
  lineHeight: '36px',
  pl: 3,
  fontSize: 'text',
  display: 'block',
  borderRadius: '4px',
  color: 'gray6',
  bg: 'white',
  width: 1,
  sx: {
    '&:hover:not(.active)': {
      color: 'gray6',
      [Icon]: {
        color: 'gray6',
      },
    },
    '&.active': {
      bg: 'deep-blue',
      color: 'light-blue',
      [Icon]: {
        color: 'white',
      },
    },
  },
};

export default function Menu({
  label,
  items,
  background = false,
  thin = false,
  eventTrackingTag,
}: Props): JSX.Element {
  return (
    <>
      {label && (
        <Text fontSize="small" fontWeight="bold" color="gray6" mb={2}>
          {label}
        </Text>
      )}
      <List width="100%" itemGap={1}>
        {items.map(item => (
          <Box
            as="li"
            key={item.to}
            data-eventtracking-region={eventTrackingTag}
          >
            <MenuItem
              to={item.to}
              variant={background ? 'background' : 'normal'}
              onClick={item?.onClick}
              fontWeight={thin ? 'normal' : 'bold'}
              exact={item.exact}
            >
              {item.icon && (
                <Icon as={item.icon} size={12} color="deep-blue" mr={3} />
              )}

              {item.text}
            </MenuItem>
          </Box>
        ))}
      </List>
    </>
  );
}

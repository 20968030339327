import config from '@config';
import { IncomingMessage } from 'http';

export function getBaseUrl(req?: IncomingMessage): string {
  return req != null
    ? `${req.headers['x-forwarded-proto'] || 'http'}://${
        req.headers['x-forwarded-host'] || req.headers['host']
      }`
    : window.location.origin
      ? window.location.origin
      : `${window.location.protocol}/${window.location.host}`;
}

export function getExternalFileUrl(orgUrl: string): string {
  // Do nothing in production
  if (config.isProduction) {
    return orgUrl;
  }

  const url = new URL(orgUrl);
  const apiUrl = new URL(config.qaBaseUrl);
  url.hostname = apiUrl.hostname;
  url.port = '';

  return url.href;
}

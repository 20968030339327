// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Optional list containing exemption requests
 * @export
 * @interface Exemption
 */
export interface Exemption  {
    /**
     * Exemption id
     * @type {number}
     * @memberof Exemption
     */
    id: number;
    /**
     * Exemption description
     * @type {string}
     * @memberof Exemption
     */
    description?: string;
}

export function ExemptionFromJSON(json: any): Exemption {
    return {
        'id': json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ExemptionToJSON(value?: Exemption): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'description': value.description,
    };
}



// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContentPageResourceDocument,
    ContentPageResourceDocumentFromJSON,
    ContentPageResourceDocumentToJSON,
} from '.';

/**
 * 
 * @export
 * @interface IssueTemplate
 */
export interface IssueTemplate  {
    /**
     * 
     * @type {number}
     * @memberof IssueTemplate
     */
    id?: number;
    title?: string;
    description?: string;
    loginType?: 'none' | 'password' | 'bankid';
}

export function IssueTemplateFromJSON(json: any): IssueTemplate {
    return {
        'title': !exists(json.issue_template , 'title') ? undefined : json.issue_template ['title'],
        'description': !exists(json.issue_template , 'description') ? undefined : json.issue_template ['description'],
        'loginType': !exists(json.issue_template , 'login_type') ? undefined : json.issue_template ['login_type'],
    };
}

export function IssueTemplateToJSON(value?: IssueTemplate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'description': value.description,
        'login_type': value.loginType,
    };
}



import { getLatestRaceDayWithResults } from '@generated/raceinfo/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  latestRaceDayWithResults?: number;
}

export default function useGetLatestRaceDayWithResults(): ApiResponse<
  number,
  Entities
> {
  const query = {
    ...getLatestRaceDayWithResults<Entities>({
      update: {
        latestRaceDayWithResults: (prev, next) => next,
      },
    }),
    transform: body => ({
      latestRaceDayWithResults: body,
    }),
    force: true,
  };

  const selector = (state: State): number | undefined =>
    state.entities?.latestRaceDayWithResults;

  return useApi<number>({
    query,
    selector,
  });
}

import { useGetUpcomingStakeRaces } from '@apis';
import Content from '@components/ContentBlock/Content';
import Table from '@components/Table';
import { UpcomingStakeRace } from '@generated/propositions/src';
import useNumericQueryParams from '@hooks/useNumericQueryParams';
import Box from '@primitives/Box';
import { ButtonLink } from '@primitives/Button';
import Error from '@primitives/Error';
import { ActionLink } from '@primitives/Link';
import Loading from '@primitives/Loading';
import { MagnifyingGlass as Search } from '@styled-icons/fa-solid';
import { formatTz } from '@utils/dateUtils';
import React, { useCallback } from 'react';
import { generatePath, useLocation, useRouteMatch } from 'react-router-dom';
import { CellProps, Column, UseTableOptions } from 'react-table';

import Expanded from './Expanded';
import ListItem from './ListItem';

interface Props {
  accountPage?: boolean;
}

export default function UpcomingStakeRacesList({
  accountPage = false,
}: Props): JSX.Element {
  const { year, horseid } = useNumericQueryParams();
  const { hash, search } = useLocation();
  const { path } = useRouteMatch();

  const { data, loading, error, status } = useGetUpcomingStakeRaces(
    year,
    horseid,
  );

  const getRowId = useCallback<UseTableOptions<UpcomingStakeRace>['getRowId']>(
    row => row.propositionId.toString(),
    [],
  );

  if (loading) {
    return <Loading />;
  }
  if (status && status !== 200) {
    return <Error>{error}</Error>;
  }

  const columns: Column<UpcomingStakeRace>[] = [
    {
      Header: 'Loppnamn',
      accessor: row => row.raceName,
      Cell: ({ value, row }: CellProps<UpcomingStakeRace, string>) => (
        <ButtonLink
          fontWeight="bold"
          sx={{
            whiteSpace: 'normal',
            textAlign: 'left',
          }}
          pr={2}
          onClick={() => row.toggleRowExpanded()}
        >
          {value}
        </ButtonLink>
      ),
    },
    {
      Header: 'Bana',
      accessor: row => row.track.name,
      id: 'track',
    },
    {
      Header: 'Ålder',
      accessor: 'ageText',
      tdStyle: () => ({
        whiteSpace: 'normal',
        minWidth: '140px',
      }),
    },
    {
      Header: 'Distans',
      accessor: row => row.distance,
      Cell: ({ value, row }) => <>{`${value} ${row.original.surface}`}</>,
    },
    {
      Header: 'Kön',
      accessor: 'genderText',
    },
    {
      Header: 'Anmälningsdag första insats',
      accessor: row => row.firstStakeDate,
      Cell: ({ value }) => <>{formatTz(value, 'yyyy-MM-dd')}</>,
    },
  ];

  if (accountPage) {
    columns.push({
      Header: ' ',
      accessor: row => row.propositionId,
      Cell: ({ value }: CellProps<UpcomingStakeRace, string>) => (
        <ActionLink
          to={
            generatePath(path, {
              propositionId: value,
            }) + search
          }
        >
          Anmäl
        </ActionLink>
      ),
      tdStyle: () => ({
        verticalAlign: 'top',
      }),
    });
  }
  return (
    <>
      {accountPage && (
        <Content
          as="div"
          color="dim-gray"
          slug="mina-insatslopp-anmal-till-insatslopp"
          pb={[4, , 0]}
        />
      )}
      <Table
        noContent={{
          icon: Search,
          header: 'Det finns inga lopp att anmäla till',
          content: '',
        }}
        columns={columns}
        data={data}
        getRowId={getRowId}
        defaultExpanded={
          hash
            ? {
                [hash.slice(1)]: true,
              }
            : undefined
        }
        expandableBackground={accountPage ? 'snow' : undefined}
        renderListItem={({ row }) => (
          <ListItem row={row.original} accountPage={accountPage} />
        )}
        renderRowSubComponent={({ row }) => (
          <Box>
            <Expanded
              propositionId={row.original.propositionId}
              accountPage={accountPage}
            />
          </Box>
        )}
      />
    </>
  );
}

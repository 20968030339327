// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface AdditionalInformation
 */
export interface AdditionalInformation  {
    /**
     * The name of the race (e.g. sometimes a sponsor name).
     * @type {string}
     * @memberof AdditionalInformation
     */
    raceName?: string;
    /**
     * The distance and the track surface.
     * @type {string}
     * @memberof AdditionalInformation
     */
    distance?: string;
    /**
     * The prize money for the winner of the race.
     * @type {number}
     * @memberof AdditionalInformation
     */
    winnerPrizeMoney?: number;
}

export function AdditionalInformationFromJSON(json: any): AdditionalInformation {
    return {
        'raceName': !exists(json, 'raceName') ? undefined : json['raceName'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'winnerPrizeMoney': !exists(json, 'winnerPrizeMoney') ? undefined : json['winnerPrizeMoney'],
    };
}

export function AdditionalInformationToJSON(value?: AdditionalInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'raceName': value.raceName,
        'distance': value.distance,
        'winnerPrizeMoney': value.winnerPrizeMoney,
    };
}



// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Horse,
    HorseFromJSON,
    HorseToJSON,
} from './';

/**
 * 
 * @export
 * @interface NegativeDopingResultsWithoutRace
 */
export interface NegativeDopingResultsWithoutRace  {
    /**
     * The day can be neglected. testDate is showing the month when the list of horses was tested for doping.
     * @type {Date}
     * @memberof NegativeDopingResultsWithoutRace
     */
    testDate: Date;
    /**
     * 
     * @type {Array<Horse>}
     * @memberof NegativeDopingResultsWithoutRace
     */
    horses: Array<Horse>;
}

export function NegativeDopingResultsWithoutRaceFromJSON(json: any): NegativeDopingResultsWithoutRace {
    return {
        'testDate': parseDateTime(json['testDate']),
        'horses': (json['horses'] as Array<any>).map(HorseFromJSON),
    };
}

export function NegativeDopingResultsWithoutRaceToJSON(value?: NegativeDopingResultsWithoutRace): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'testDate': value.testDate.toLocalDate(),
        'horses': (value.horses as Array<any>).map(HorseToJSON),
    };
}



// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice  {
    /**
     * Used to link invoice number to info page
     * @type {number}
     * @memberof Invoice
     */
    documentId?: number;
    /**
     * The invoice number
     * @type {number}
     * @memberof Invoice
     */
    invoiceNumber: number;
    /**
     * Date the invoice was issued
     * @type {Date}
     * @memberof Invoice
     */
    date: Date;
    /**
     * Date of expiration
     * @type {Date}
     * @memberof Invoice
     */
    expirationDate: Date;
    /**
     * Amount due
     * @type {number}
     * @memberof Invoice
     */
    amount: number;
    /**
     * Residual amount due
     * @type {number}
     * @memberof Invoice
     */
    residualAmount: number;
    /**
     * Status of invoice
     * @type {string}
     * @memberof Invoice
     */
    status?: string;
    /**
     * Name of supplier
     * @type {string}
     * @memberof Invoice
     */
    supplierName: string;
    /**
     * Only returned when collecting the list of invoices. The index is used when creating link for getting invoice pdf.
     * @type {number}
     * @memberof Invoice
     */
    index?: number;
    /**
     * Indicates if there is a PDF to download.
     * @type {boolean}
     * @memberof Invoice
     */
    pdfExists?: boolean;
}

export function InvoiceFromJSON(json: any): Invoice {
    return {
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'invoiceNumber': json['invoiceNumber'],
        'date': parseDateTime(json['date']),
        'expirationDate': parseDateTime(json['expirationDate']),
        'amount': json['amount'],
        'residualAmount': json['residualAmount'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'supplierName': json['supplierName'],
        'index': !exists(json, 'index') ? undefined : json['index'],
        'pdfExists': !exists(json, 'pdfExists') ? undefined : json['pdfExists'],
    };
}

export function InvoiceToJSON(value?: Invoice): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'documentId': value.documentId,
        'invoiceNumber': value.invoiceNumber,
        'date': value.date.toLocalDate(),
        'expirationDate': value.expirationDate.toLocalDate(),
        'amount': value.amount,
        'residualAmount': value.residualAmount,
        'status': value.status,
        'supplierName': value.supplierName,
        'index': value.index,
        'pdfExists': value.pdfExists,
    };
}



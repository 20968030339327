import IconCircle from '@components/RoundIcon';
import Alignment from '@primitives/Alignment';
import Box, { Container, Item } from '@primitives/Box';
import { Text } from '@primitives/Typography';
import { SystemCssProperties } from '@styled-system/css';
import React from 'react';
import styled from 'styled-components';
import { StyledIcon } from 'styled-icons/types';

import Content from './Content';

const Wrapper = styled(Container)<Alignment>`
  text-align: ${props => (props.alignment ? props.alignment : 'center')};
`;

type WrapperProps = Parameters<typeof Wrapper>[0];

type Props = Alignment &
  WrapperProps & {
    slug?: string;
    text?: string;
    icon?: StyledIcon | ((size: string, color: string) => JSX.Element);
    sx?: SystemCssProperties;
    gray?: boolean;
  };

export default function ContentBlock({
  sx,
  slug,
  text,
  icon,
  alignment,
  gray = false,
  ...props
}: Props): React.ReactElement {
  return (
    <Wrapper
      justifyContent="center"
      alignment={alignment}
      sx={sx}
      bg={gray ? 'gray2' : undefined}
      {...props}
    >
      {icon && (
        <Box width={[1]} display="flex" justifyContent="center">
          <IconCircle icon={icon} gray={gray} />
        </Box>
      )}
      {slug ? (
        <Item width={1}>
          <Content slug={slug} />
        </Item>
      ) : (
        text && (
          <Item width={[1, 1 / 2]}>
            <Text>{text}</Text>
          </Item>
        )
      )}
    </Wrapper>
  );
}

import { Covering, CoveringResult } from '@generated/breedings/src';
import Box from '@primitives/Box';
import { Link } from '@primitives/Link';
import { Label, Small } from '@primitives/Typography';
import { horse } from '@utils/links';
import React from 'react';
import { CellProps } from 'react-table';

type Item = Covering | CoveringResult;

export default function MareTableCell({
  value: mare,
}: CellProps<Item, Item['mare']>): JSX.Element {
  return (
    <Box>
      <Link to={horse(mare.id)}>{mare.name}</Link>
      <Small>{mare.registrationNumber}</Small>
      <Label as="span">Ägare: {mare.ownerName}</Label>
      {mare.importText && (
        <Small
          sx={{
            whiteSpace: 'normal',
          }}
        >
          • {mare.importText}
        </Small>
      )}
    </Box>
  );
}

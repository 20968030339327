// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Enrollment
 */
export interface Enrollment  {
    /**
     * 
     * @type {string}
     * @memberof Enrollment
     */
    horseName: string;
    /**
     * 
     * @type {string}
     * @memberof Enrollment
     */
    trackName: string;
    /**
     * 
     * @type {number}
     * @memberof Enrollment
     */
    propositionNumber: number;
    /**
     * 
     * @type {number}
     * @memberof Enrollment
     */
    weight: number;
    /**
     * 
     * @type {boolean}
     * @memberof Enrollment
     */
    modifiable: boolean;
    /**
     * Prop/Competition Date
     * @type {Date}
     * @memberof Enrollment
     */
    propositionDate: Date;
    /**
     * 
     * @type {number}
     * @memberof Enrollment
     */
    propositionId?: number;
    /**
     * 
     * @type {number}
     * @memberof Enrollment
     */
    horseId?: number;
}

export function EnrollmentFromJSON(json: any): Enrollment {
    return {
        'horseName': json['horseName'],
        'trackName': json['trackName'],
        'propositionNumber': json['propositionNumber'],
        'weight': json['weight'],
        'modifiable': json['modifiable'],
        'propositionDate': parseDateTime(json['propositionDate']),
        'propositionId': !exists(json, 'propositionId') ? undefined : json['propositionId'],
        'horseId': !exists(json, 'horseId') ? undefined : json['horseId'],
    };
}

export function EnrollmentToJSON(value?: Enrollment): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseName': value.horseName,
        'trackName': value.trackName,
        'propositionNumber': value.propositionNumber,
        'weight': value.weight,
        'modifiable': value.modifiable,
        'propositionDate': value.propositionDate.toLocalDate(),
        'propositionId': value.propositionId,
        'horseId': value.horseId,
    };
}



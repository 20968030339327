// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    Breeding,
    BreedingFromJSON,
    BreedingToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface GetBreedingsRequest {
    horseId: number;
    registry: GetBreedingsRegistryEnum;
}


/**
 * Gets breedings and results for a given horse and breeding registry.
 */
function getBreedingsRaw<T>(requestParameters: GetBreedingsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Breeding>> = {}): QueryConfig<T> {
    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling getBreedings.');
    }

    if (requestParameters.registry === null || requestParameters.registry === undefined) {
        throw new runtime.RequiredError('registry','Required parameter requestParameters.registry was null or undefined when calling getBreedings.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/horses/{horseId}/breedings/{registry}`.replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))).replace(`{${"registry"}}`, encodeURIComponent(String(requestParameters.registry))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(BreedingFromJSON), text);
    }

    return config;
}

/**
* Gets breedings and results for a given horse and breeding registry.
*/
export function getBreedings<T>(requestParameters: GetBreedingsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Breeding>>): QueryConfig<T> {
    return getBreedingsRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum GetBreedingsRegistryEnum {
    SV_SF = 'SvSF',
    NTB = 'NTB'
}

import Box from '@primitives/Box';
import Message from '@primitives/Message';
import React, { ReactNode } from 'react';

type BoxParameters = Parameters<typeof Box>;

type Props = {
  children: ReactNode;
} & BoxParameters[0];

export default function Error({
  children,
  ...props
}: Props): React.ReactElement {
  return (
    <Message
      variant="error"
      mt={1}
      position="absolute"
      bottom="-4px"
      right="8px"
      width="100%"
      sx={{ fontSize: 'tiny', textAlign: 'right' }}
      className="errBox"
      {...props}
    >
      {children}
    </Message>
  );
}

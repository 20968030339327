import { useGetTdsLists } from '@apis';
import ContentBlock from '@components/ContentBlock';
import Table from '@components/Table';
import { TdsList } from '@generated/tds/src';
import theme from '@main/theme';
import Error from '@primitives/Error';
import List, { LI } from '@primitives/List';
import Loading from '@primitives/Loading';
import Message from '@primitives/Message';
import { Text } from '@primitives/Typography';
import Refresh from '@utils/Refresh';
import CSS from 'csstype';
import React, { useMemo } from 'react';
import { CellProps, Column } from 'react-table';

import PdfLink from './PdfLink';

const getTdStyle = (item: TdsList): CSS.Properties => {
  const style = { width: '50%' };
  return item.read ? { ...style, backgroundColor: theme.colors.gray1 } : style;
};

const columns = (
  title: string,
  licenseId: number,
  refresh: Refresh,
): Column<TdsList>[] => [
  {
    accessor: 'text',
    Header: title,
    tdStyle: row => getTdStyle(row.original),
    Cell: ({ row, value }: CellProps<TdsList, TdsList['text']>) => (
      <PdfLink
        documentId={row.original.id}
        licenseId={licenseId}
        read={row.original.read}
        refresh={refresh}
      >
        {value}
      </PdfLink>
    ),
  },
  {
    accessor: 'read',
    tdStyle: row => getTdStyle(row.original),
    Cell: ({ value }: CellProps<TdsList, TdsList['read']>) => (
      <List markerType="circle" markerColor="dim-gray">
        <LI>
          <Message variant="info" as="span">
            {value ? 'Visad' : 'Ej visad'}
          </Message>
        </LI>
      </List>
    ),
  },
];

interface Props {
  licenseId: number;
}

export default function Lists({ licenseId }: Props): JSX.Element {
  const { data, loading, error, status, refresh } = useGetTdsLists(licenseId);

  const items = useMemo(
    () => (data ? data.filter(item => item.tdsLists.length > 0) : null),
    [data],
  );

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <>
      <ContentBlock
        justifyContent="flex-start"
        slug="tds-hamta-tds-listor"
        alignment="left"
      />
      {!items && <Text mt={4}>Fann inga TDS-listor.</Text>}
      {items && (
        <List>
          {items.map((item, index) => (
            <LI key={item.name}>
              <Table
                mt={index === 0 ? 0 : 4}
                columns={columns(item.name, licenseId, refresh)}
                data={item.tdsLists}
              />
            </LI>
          ))}
        </List>
      )}
    </>
  );
}

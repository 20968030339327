// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface EnrollmentHorse
 */
export interface EnrollmentHorse  {
    /**
     * 
     * @type {number}
     * @memberof EnrollmentHorse
     */
    horseId: number;
    /**
     * 
     * @type {string}
     * @memberof EnrollmentHorse
     */
    horseName: string;
    /**
     * 
     * @type {number}
     * @memberof EnrollmentHorse
     */
    weight: number;
    /**
     * Number of enrollments for this horse on the same race day
     * @type {number}
     * @memberof EnrollmentHorse
     */
    numberOfEnrollments?: number;
}

export function EnrollmentHorseFromJSON(json: any): EnrollmentHorse {
    return {
        'horseId': json['horseId'],
        'horseName': json['horseName'],
        'weight': json['weight'],
        'numberOfEnrollments': !exists(json, 'numberOfEnrollments') ? undefined : json['numberOfEnrollments'],
    };
}

export function EnrollmentHorseToJSON(value?: EnrollmentHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'horseName': value.horseName,
        'weight': value.weight,
        'numberOfEnrollments': value.numberOfEnrollments,
    };
}



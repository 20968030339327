import DesktopMobileSwitch from '@components/DesktopMobileSwitch';
import useIsMobile from '@hooks/useIsMobile';
import Box, { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import ButtonLink from '@primitives/Button/Link';
import Error from '@primitives/Error';
import List from '@primitives/List';
import Loading from '@primitives/Loading';
import { Label, Text } from '@primitives/Typography';
import React, { useCallback, useState } from 'react';
import { QRCode } from 'react-qr-svg';
import styled from 'styled-components';

type FlexParameters = Parameters<typeof Flex>;

type Props = {
  autoStartToken: string;
  qrCodePattern: string;
  error?: boolean;
  errorMessage?: string;
  retry?: () => void;
  login?: boolean;
} & FlexParameters[0];

const QR = styled(QRCode)`
  width: 180px;
`;

export default function BankID({
  autoStartToken,
  qrCodePattern,
  error,
  errorMessage,
  retry,
  ...props
}: Props): JSX.Element {
  const isMobile = useIsMobile();

  const [showQR, setShowQR] = useState(!isMobile);

  const openBankIdApp = useCallback((): void => {
    window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
  }, [autoStartToken]);

  if (error) {
    return (
      <Box mt={6} sx={{ textAlign: 'center' }} p={3}>
        <Text textAlign="center">
          <Label>Ett fel inträffade</Label>
          <Error>{errorMessage || 'Oväntat fel.'}</Error>
        </Text>
        {retry && (
          <Button my={2} onClick={() => retry()}>
            PROVA IGEN
          </Button>
        )}
      </Box>
    );
  }

  if (!showQR) {
    return (
      <Box mt={6}>
        {<Loading my={'50px'} size={60} />}
        {autoStartToken && (
          <Box mb={2} sx={{ textAlign: 'center' }}>
            <Text mt={3} textAlign="center">
              Väntar på autentisering. Klicka på knappen nedan för att öppna
              BankID-appen.
            </Text>
            <Button mt={3} onClick={() => openBankIdApp()}>
              ÖPPNA BANKID-APPEN
            </Button>
          </Box>
        )}
        <Box sx={{ textAlign: 'center' }}>
          <ButtonLink mt={3} onClick={() => setShowQR(true)}>
            Visa QR-kod
          </ButtonLink>
        </Box>
      </Box>
    );
  }

  return (
    <Flex
      flexDirection="column"
      py={[4, , 0]}
      justifyContent="flex-start"
      {...props}
    >
      <Flex
        flexDirection="column"
        justifyContent={'flex-end'}
        padding="6px"
        width={'250px'} //todo!
        minHeight={'228px'}
        sx={{ flexShrink: 0 }}
      >
        <>
          {autoStartToken !== undefined && qrCodePattern && (
            <div
              style={{
                paddingBottom: '1rem',
              }}
            >
              <QR
                bgColor="white"
                fgColor="black"
                value={qrCodePattern}
                level="L"
              />
            </div>
          )}
          <ButtonLink onClick={() => setShowQR(false)}>
            Använd BankID på samma enhet
          </ButtonLink>
        </>
      </Flex>

      <DesktopMobileSwitch
        desktopElement={
          <List p={3} itemGap={2}>
            <li>
              <Text fontWeight="bold">Så här gör du:</Text>
            </li>
            <li>1. Öppna BankID-appen i mobilen.</li>
            <li>2. Tryck på QR-symbolen i BankID-appen.</li>
            <li>3. Rikta kameran mot QR-koden i denna ruta.</li>
            <li>4. Legitimera dig själv i BankID-appen.</li>
          </List>
        }
      />
    </Flex>
  );
}

import AlertFooter from '@components/Alert/Footer';
import AlertHeader from '@components/Alert/Header';
import { DatePicker, Input, Radios } from '@components/FormikComponents';
import FormLayout, {
  FormError,
  FormItem,
  FormItemSeparator,
} from '@components/FormLayout';
import BankID from '@features/BankID';
import {
  BankIdUserSigning,
  RegistrationStatusOptionsRegistrationStatuses,
} from '@generated/horses/src';
import { PenToSquare as Edit } from '@styled-icons/fa-solid';
import getToday from '@utils/getToday';
import { Form as FormikForm, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';

import BankIdInitialize from './utils/BankIdInitialize';
import TaskEnum from './utils/enum/Task';
import getValidationSchema from './utils/getValidationSchema';
import FormState from './utils/interface/FormState';
import SetTask from './utils/type/SetTask';
import Task from './utils/type/Task';
import UpdateStatusByPassword from './utils/UpdateStatusByPassword';

interface Props {
  authenticationMethod: string;
  closeModal: () => void;
  colorScheme: 'primary' | 'delete';
  horseId: number;
  horseName: string;
  registrationStatuses: Array<RegistrationStatusOptionsRegistrationStatuses>;
  setTask: SetTask;
  task: Task;
  wideMobileButtons?: boolean;
}

const Form = ({
  authenticationMethod,
  closeModal,
  colorScheme,
  horseId,
  horseName,
  registrationStatuses,
  setTask,
  task,
  wideMobileButtons,
}: Props): JSX.Element => {
  const initialValues: FormState = {
    registrationStatus: null,
    registrationDate: new Date(new Date().setHours(0, 0, 0, 0)),
    ...(authenticationMethod === 'PASSWORD' && { password: '' }),
  };

  const disabled = [
    TaskEnum.RunBankIdInitialize,
    TaskEnum.RunUpdateStatusByPassword,
    TaskEnum.RunBankIdCollect,
    TaskEnum.BankIdCollectQrCode,
    TaskEnum.BankIdCollectPending,
  ].includes(task?.type);

  const radios = useMemo(
    () =>
      registrationStatuses.map(registrationStatus => ({
        disabled,
        label: `${horseName}: ${registrationStatus.description}`,
        value: registrationStatus.status,
      })),
    [registrationStatuses, disabled, horseName],
  );

  const onSubmit = useCallback(() => {
    setTask({
      type:
        authenticationMethod === 'PASSWORD'
          ? TaskEnum.RunUpdateStatusByPassword
          : TaskEnum.RunBankIdInitialize,
    });
  }, [authenticationMethod, setTask]);

  if (
    task?.type === TaskEnum.RunBankIdCollect ||
    task?.type === TaskEnum.BankIdCollectQrCode
  ) {
    const bankIdUserSigning = task.value as unknown as BankIdUserSigning;

    // autoStartToken is missing when reading orderReference from from http query parameter.
    // Becuase we don't want to show the BankID component after a redirect has been made.
    // Just wait for a successfull/unsuccessful collect action.
    if (
      bankIdUserSigning.orderReference &&
      (bankIdUserSigning.autoStartToken || bankIdUserSigning.qrCodePattern)
    ) {
      return (
        <BankID
          autoStartToken={bankIdUserSigning.autoStartToken || ''}
          qrCodePattern={bankIdUserSigning.qrCodePattern}
          m={4}
        />
      );
    }
  }

  if (task?.type === TaskEnum.BankIdCollectPending) {
    return (
      <AlertHeader
        colorScheme={colorScheme}
        icon={Edit}
        iconProps={{
          ml: '2px',
        }}
        size="medium"
        title="Ändra registreringstatus"
        description={`Väntar på signering...`}
      />
    );
  }

  return (
    <Formik<FormState>
      initialValues={initialValues}
      validationSchema={getValidationSchema(authenticationMethod)}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <FormikForm>
          <input
            autoComplete="off"
            name="hidden"
            type="text"
            style={{ display: 'none' }}
          ></input>
          <AlertHeader
            colorScheme={colorScheme}
            icon={Edit}
            iconProps={{
              ml: '2px',
            }}
            size="medium"
            title="Ändra registreringstatus"
            description={`Här kan du ändra registreringstatus för ${horseName}.`}
          />
          {task?.type === TaskEnum.RunUpdateStatusByPassword && (
            <UpdateStatusByPassword
              horseId={horseId}
              status={values.registrationStatus}
              fromdate={values.registrationDate}
              password={values.password}
              setTask={setTask}
            />
          )}
          {task?.type === TaskEnum.RunBankIdInitialize && (
            <BankIdInitialize
              horseId={horseId}
              status={values.registrationStatus}
              fromdate={values.registrationDate}
              setTask={setTask}
            />
          )}
          <FormLayout as="div" boxScheme="borderTopAndBottom">
            <FormItem>
              <Radios
                label="Välj ändring"
                name="registrationStatus"
                radios={radios}
              />
            </FormItem>
            <FormItemSeparator />
            <FormItem maxWidth="155px">
              <label>Datum</label>
              <DatePicker
                autoComplete="anyrandomstring"
                disabled={disabled}
                maxDate={getToday()}
                name="registrationDate"
              />
            </FormItem>
            {authenticationMethod === 'PASSWORD' && (
              <FormItem maxWidth="155px">
                <Input
                  disabled={disabled}
                  name="password"
                  label="Lösenord"
                  type="password"
                  placeholder="Lösenord"
                  autoComplete="current-password"
                />
              </FormItem>
            )}
            {[
              TaskEnum.UpdateStatusByPasswordFailure,
              TaskEnum.BankIdInitializeFailure,
              TaskEnum.BankIdCollectFailure,
            ].includes(task?.type) && (
              <FormError>
                <>{task.value}</>
              </FormError>
            )}
          </FormLayout>
          <AlertFooter
            action={
              authenticationMethod === 'PASSWORD'
                ? 'Spara'
                : 'Bekräfta med BankID'
            }
            buttonType="submit"
            borderTopWidth={0}
            closeModal={closeModal}
            colorScheme={colorScheme}
            disabled={disabled}
            loading={disabled}
            wideMobileButtons={wideMobileButtons}
          />
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;

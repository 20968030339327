import {
  useDeleteVatRegistration,
  useGetVatRegistrations,
  useInsertVatRegistration,
} from '@apis';
import ContentBlock from '@components/ContentBlock';
import DataGrid from '@components/DataGrid';
import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import { VatRegistration as VR } from '@generated/sportactors/src';
import withUser from '@hoc/withUser';
import Box from '@primitives/Box';
import { H3 } from '@primitives/Typography';
import { OWNER_ROLES } from '@utils/constants';
import React, { useCallback, useMemo } from 'react';
import { CellProps, Column } from 'react-table';

import VatRegistrationForm from './VatRegistrationForm';

interface VatRegistrationProps {
  user: AuthenticatedUser;
}

function VatRegistration({ user }: VatRegistrationProps): JSX.Element | null {
  const { data, refresh } = useGetVatRegistrations(user.licenseId);

  const { action: insert } = useInsertVatRegistration();
  const { action: remove } = useDeleteVatRegistration();

  const mutateHandler = useCallback(
    (type: 'owner' | 'representative') => (id: number, vatNumber: string) => {
      const commonArgs = {
        ownerId: user.licenseId,
        ownershipId: id,
        representativeId: id,
        type,
      };

      if (vatNumber) {
        return insert({
          ...commonArgs,
          vatRegistrationInsertUpdate: {
            id,
            vatNumber,
          },
        });
      }
      return remove(commonArgs);
    },
    [insert, remove, user.licenseId],
  );

  const createColumns = useCallback(
    (type: 'owner' | 'representative'): Column<VR>[] => [
      {
        Header: 'Namn',
        accessor: 'name',
        tdStyle: () => ({
          width: '250px',
        }),
      },
      {
        Header: 'Momsnummer',
        accessor: 'vatNumber',
        Cell: ({ row: { original: row } }: CellProps<VR>) => (
          <VatRegistrationForm
            action={mutateHandler(type)}
            row={row}
            refresh={refresh}
          />
        ),
      },
    ],
    [mutateHandler, refresh],
  );

  const representativeColumns = useMemo(
    () => createColumns('representative'),
    [createColumns],
  );

  const ownershipColumns = useMemo(
    () => createColumns('owner'),
    [createColumns],
  );

  return (
    <PageLayout headline="Momsregistreringsnummer" singleCard>
      <Box verticalSpacing={4} mt={[4, , 0]}>
        <ContentBlock slug="agare-momsregistreringsnummer" alignment="left" />
        <H3>Företrädare</H3>
        <DataGrid
          data={data?.vatRegistrationRepresentatives || []}
          columns={representativeColumns}
          noHover
          stickyOnMobile={false}
        />
        <H3>Ägarskap</H3>
        <DataGrid
          data={data?.vatRegistrationOwnerships || []}
          columns={ownershipColumns}
          noHover
          stickyOnMobile={false}
        />
      </Box>
    </PageLayout>
  );
}

export default withUser(VatRegistration, {
  roles: OWNER_ROLES,
  header: 'Momsregistreringsnummer',
});

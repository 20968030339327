enum TaskEnum {
  RetrieveOptions = 'RetrieveOptions',
  ShowOptionsAlert = 'ShowOptionsAlert',
  ShowNoOptionsAlert = 'ShowNoOptionsAlert',
  FinishedWithoutOptions = 'FinishedWithoutOptions',
  RunUpdateStatusByPassword = 'RunUpdateStatusByPassword',
  UpdateStatusByPasswordSuccess = 'UpdateStatusByPasswordSuccess',
  UpdateStatusByPasswordFailure = 'UpdateStatusByPasswordFailure',
  RunBankIdInitialize = 'RunBankIdInitialize',
  BankIdInitializeFailure = 'BankIdInitializeFailure',
  RunBankIdCollect = 'RunBankIdCollect',
  BankIdCollectSuccess = 'BankIdCollectSuccess',
  BankIdCollectFailure = 'BankIdCollectFailure',
  BankIdCollectQrCode = 'BankIdCollectQrCode',
  BankIdCollectPending = 'BankIdCollectPending',
}

export default TaskEnum;

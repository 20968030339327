import Confirm from '@components/Confirm';
import { TrashCan as TrashAlt } from '@styled-icons/fa-solid';
import React, { useState } from 'react';

import ButtonLink from './Link';

type RemoveProps = Parameters<typeof ButtonLink>[0] & {
  confirm?: boolean;
  confirmTitle?: string;
  confirmDescription?: string;
};

export default function Remove({
  confirm = true,
  confirmTitle = 'Är du säker?',
  confirmDescription = 'Du kan inte ångra dig.',
  ...props
}: RemoveProps): JSX.Element {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && (
        <Confirm
          closeModal={() => setOpen(false)}
          colorScheme="delete"
          onAction={async () => {
            await props.onClick();
          }}
          open={open}
          title={confirmTitle}
          description={confirmDescription}
        ></Confirm>
      )}
      <ButtonLink
        {...props}
        onClick={confirm ? () => setOpen(true) : props.onClick}
      >
        <TrashAlt size={15} />
      </ButtonLink>
    </>
  );
}

Remove.defaultProps = {
  color: 'gray5',
  sx: {
    ':hover': {
      color: 'gray4',
    },
  },
};

export const dataGridRemoveButtonMixin = {
  alignment: 'center',
  thStyle: () => ({
    width: '90px',
  }),
  tdStyle: () => ({
    verticalAlign: 'middle',
  }),
} as const;

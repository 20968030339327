import useIsMobile from '@hooks/useIsMobile';
import Box, { Flex } from '@primitives/Box';
import { Option } from '@primitives/Form';
import Icon from '@primitives/Icon';
import { ArrowIosBackOutline as LeftArrow } from '@styled-icons/evaicons-outline/ArrowIosBackOutline';
import { ArrowIosForwardOutline as RightArrow } from '@styled-icons/evaicons-outline/ArrowIosForwardOutline';
import React from 'react';
import { useParams } from 'react-router-dom';

import { max, min, pageRange } from './util';
import {
  PageSelector,
  PageSelectorWrapper,
  ShowTotal,
  StyledSelect,
  StyledText,
} from './Wrappers';

interface Props {
  nextPage: () => void;
  previousPage: () => void;
  pageCount: number;
  gotoPage: (page: number) => void;
  setPageSize: (Toggle: number) => void;
  pageIndex: number;
  canNextPage: boolean;
  canPreviousPage: boolean;
  pageSize: number;
  rowsSize: number;
}

function Pagination({
  nextPage,
  previousPage,
  pageCount,
  gotoPage,
  setPageSize,
  pageSize,
  rowsSize,
  pageIndex,
  canNextPage,
  canPreviousPage,
}: Props): JSX.Element | null {
  const { start, end } = pageRange(pageIndex, pageCount);
  const pages = [...Array(min(pageCount, 5)).keys()].map(num => num + start);
  const isMobile = useIsMobile();

  const { lang } = useParams<{ lang?: 'en' }>();

  if (rowsSize <= 10) {
    return null;
  }

  return (
    <Flex justifyContent="flex-start" height="30px">
      {rowsSize > 30 && (
        <Box pt="10px">
          <ShowTotal>
            <StyledText>
              {lang === 'en' ? 'Number of rows' : 'Visa antal rader'}:{' '}
            </StyledText>
            <StyledSelect
              color="black"
              backgroundColor="inherit"
              onChange={e => setPageSize(parseInt(e.target.value))}
              defaultValue={pageSize}
            >
              <Option value={30}>30</Option>
              {rowsSize > 31 && <Option value={50}>50</Option>}
              {rowsSize > 50 && <Option value={100}>100</Option>}
            </StyledSelect>
          </ShowTotal>
        </Box>
      )}
      {pageCount > 1 && (
        <PageSelectorWrapper>
          <PageSelector onClick={() => canPreviousPage && previousPage()}>
            <Icon
              size={18}
              as={LeftArrow}
              color={canPreviousPage ? 'inherit' : 'gray2'}
            />
          </PageSelector>
          {!isMobile && (
            <>
              {start !== 1 && (
                <>
                  <PageSelector
                    value={0}
                    onClick={e => gotoPage(parseInt(e.currentTarget.value))}
                    $active={pageIndex === 0}
                  >
                    1
                  </PageSelector>
                  <PageSelector onClick={() => gotoPage(max(pageIndex - 4, 1))}>
                    ...
                  </PageSelector>
                </>
              )}
              {pages.map(page => (
                <PageSelector
                  key={page}
                  value={page - 1}
                  onClick={e => gotoPage(parseInt(e.currentTarget.value))}
                  $active={pageIndex === page - 1}
                >
                  {page}
                </PageSelector>
              ))}
              {end !== pageCount && (
                <>
                  <PageSelector
                    onClick={() => gotoPage(min(pageIndex + 6, pageCount))}
                  >
                    ...
                  </PageSelector>
                  <PageSelector
                    value={pageCount - 1}
                    onClick={e => gotoPage(parseInt(e.currentTarget.value))}
                    $active={pageIndex === pageCount - 1}
                  >
                    {pageCount}
                  </PageSelector>
                </>
              )}
            </>
          )}
          <PageSelector onClick={() => canNextPage && nextPage()}>
            <Icon
              size={18}
              as={RightArrow}
              color={canNextPage ? 'inherit' : 'gray2'}
            />
          </PageSelector>
        </PageSelectorWrapper>
      )}
    </Flex>
  );
}

export default Pagination;

import { useGetUser, useRefreshAuthToken } from '@apis';
import ChangePasswordInfo from '@components/ChangePasswordInfo';
import ErrorBoundary from '@components/ErrorBoundary';
import GTMPageView from '@components/GTM';
import ImportantMessage from '@components/ImportantMessage';
import PageHeader from '@components/PageHeader';
import ScrollToTop from '@components/ScrollToTop';
import Toast from '@components/Toast';
import config from '@config';
import withRedux from '@hoc/withRedux';
import { UserContext } from '@hooks/useUser';
import Box from '@primitives/Box';
import React, { Suspense, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Routes from '../features';
import * as serviceWorker from '../serviceWorker';
import Footer from './Footer';
import GlobalStyle from './globalStyle';
import { rootRoutes } from './rootRoutes';
import { ScreenSizeProvider } from './ScreenSizeContext';
import theme from './theme';

function Refresh(): null {
  // Start refreshing the auth token
  useRefreshAuthToken();
  return null;
}

const App = (): JSX.Element => {
  // Get current user
  const { data: user, refresh: refreshUser, loading } = useGetUser();

  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  // A new version is available
  const onServiceWorkerUpdate = (
    registration: ServiceWorkerRegistration,
  ): void => {
    setNewVersionAvailable(true);

    const waitingWorker: ServiceWorker | undefined =
      registration && registration.waiting;

    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  };

  // Poll for updates to avoid problem when user has browser tab open for a long time
  const onServiceWorkerSuccess = (
    registration: ServiceWorkerRegistration,
  ): void => {
    setInterval(async () => {
      await registration.update();
    }, 60000);
  };

  useEffect(() => {
    if (config.useServiceWorker) {
      serviceWorker.register({
        onUpdate: registration => onServiceWorkerUpdate(registration),
        onSuccess: registration => onServiceWorkerSuccess(registration),
      });
    } else {
      serviceWorker.unregister();
    }
  });

  if (loading) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <ScreenSizeProvider>
          <UserContext.Provider
            value={{
              user,
              refreshUser,
            }}
          >
            <HelmetProvider>
              <Suspense fallback={null}>
                <ScrollToTop />
                <GTMPageView />
                <Helmet>
                  <title>Galoppappen - Svensk Galopp</title>
                </Helmet>
                <Switch>
                  {rootRoutes.map(item => (
                    <Route key={item.path} path={item.path} exact>
                      <item.component />
                    </Route>
                  ))}
                  <Route path="/">
                    <Refresh />
                    <GlobalStyle />
                    <PageHeader />
                    <ImportantMessage />
                    <ChangePasswordInfo />
                    <Box
                      flexGrow={1}
                      display="flex"
                      flexDirection="column"
                      width={1}
                    >
                      <Box
                        // maxWidth="container"
                        mx="auto"
                        as="main"
                        // p={[0, null, '15px']}
                        width={1}
                      >
                        <Routes />
                      </Box>
                      <Box flex="1" />
                      <Footer />
                    </Box>
                    <Toast
                      hidden={!newVersionAvailable}
                      onClick={() => window.location.reload()}
                    />
                  </Route>
                </Switch>
              </Suspense>
            </HelmetProvider>
          </UserContext.Provider>
        </ScreenSizeProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default withRedux()(App);

import { Entities } from '@apis';
import { unEnrollFromUpcomingStakeRace } from '@generated/propositions/src';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../../utils/types';
import { useApiMutateWithoutData } from '../../utils/useApiMutate';

export default function useUnEnrollFromUpcomingStakeRace(
  propositionId: number,
  trainerId: number,
): MutationResponseWithoutData<Entities, number> {
  const key = `${propositionId}-${trainerId}`;
  const queryFunc = (horseId: number): QueryConfig =>
    unEnrollFromUpcomingStakeRace(
      {
        propositionId,
        horseId,
        xEndUserIp: '0.0.0.0',
      },
      {
        update: {
          trainerHorsesEnrolledToStakeRace: prev => ({
            ...prev,
            [key]: prev?.[key]?.filter(item => item.id !== horseId),
          }),
          upcomingStakeRaceHorses: prev => ({
            ...prev,
            [key]: prev?.[key]?.map(item => ({
              ...item,
              enrolled: horseId === item.id ? false : item.enrolled,
            })),
          }),
        },
      },
    );

  return useApiMutateWithoutData({
    queryFunc,
    queryKey: 'unEnrollHorseToUpcomingStakeRace',
    resetKeys: ['horsesEnrolledToStakeRace:*'],
  });
}

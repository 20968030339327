// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SportActorSearchReference,
    SportActorSearchReferenceFromJSON,
    SportActorSearchReferenceToJSON,
} from '../models';

export interface SearchBreederByLabelRequest {
    query: string;
}


/**
 * Search for breeders by label. At least 2 characters. A trailing wildcard will be added to the query.
 */
function searchBreederByLabelRaw<T>(requestParameters: SearchBreederByLabelRequest, requestConfig: runtime.TypedQueryConfig<T, Array<SportActorSearchReference>> = {}): QueryConfig<T> {
    if (requestParameters.query === null || requestParameters.query === undefined) {
        throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling searchBreederByLabel.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.query !== undefined) {
        queryParameters['query'] = requestParameters.query;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/sportactors/breeders/search`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(SportActorSearchReferenceFromJSON), text);
    }

    return config;
}

/**
* Search for breeders by label. At least 2 characters. A trailing wildcard will be added to the query.
*/
export function searchBreederByLabel<T>(requestParameters: SearchBreederByLabelRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<SportActorSearchReference>>): QueryConfig<T> {
    return searchBreederByLabelRaw(requestParameters, requestConfig);
}


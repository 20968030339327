import { ReactNode } from 'react';

interface Props {
  condition: boolean;
  children: ReactNode;
  wrapperTrue: (node: ReactNode) => JSX.Element;
  wrapperFalse: (node: ReactNode) => JSX.Element;
}

const ConditionalWrapper = ({
  condition,
  wrapperTrue,
  wrapperFalse,
  children,
}: Props): JSX.Element =>
  condition ? wrapperTrue(children) : wrapperFalse(children);

export default ConditionalWrapper;

import { useGetStakeRaceWithStakes } from '@apis';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import React from 'react';

import InfoTexts from './InfoTexts';
import StakeInfo from './StakeInfo';

interface Props {
  propositionId: number;
}

export default function Infos({ propositionId }: Props): JSX.Element {
  const { data, loading, error, status } =
    useGetStakeRaceWithStakes(propositionId);

  if (loading) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return <Error>{error}</Error>;
  }
  return (
    <Box mt={2} verticalSpacing={2} sx={{ fontSize: ['small', , 'text'] }}>
      <StakeInfo stakes={data.stakes} />
      <InfoTexts infoTexts={data.infoTexts} />
    </Box>
  );
}

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GeneralSearchSelectionModel,
    GeneralSearchSelectionModelFromJSON,
    GeneralSearchSelectionModelToJSON,
    Proposition,
    PropositionFromJSON,
    PropositionToJSON,
} from '../models';

export interface SearchPropositionsRequest {
    distanceFrom: number;
    distanceTo: number;
    fromDate: Date;
    selectionAge: SearchPropositionsSelectionAgeEnum;
    selectionBreed: SearchPropositionsSelectionBreedEnum;
    selectionGender: SearchPropositionsSelectionGenderEnum;
    selectionRaceTypes: Array<SearchPropositionsSelectionRaceTypesEnum>;
    selectionRaised: SearchPropositionsSelectionRaisedEnum;
    selectionSurfaces: Array<SearchPropositionsSelectionSurfacesEnum>;
    toDate: Date;
    horseId?: number;
    trackCodes?: Array<string>;
}


/**
 * Fetch search selections
 */
function fetchSearchSelectionsRaw<T>( requestConfig: runtime.TypedQueryConfig<T, GeneralSearchSelectionModel> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/search/selections`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(GeneralSearchSelectionModelFromJSON(body), text);
    }

    return config;
}

/**
* Fetch search selections
*/
export function fetchSearchSelections<T>( requestConfig?: runtime.TypedQueryConfig<T, GeneralSearchSelectionModel>): QueryConfig<T> {
    return fetchSearchSelectionsRaw( requestConfig);
}

/**
 * Search for propositions using different parameters. Either for a horse by horseId or for a general search matching the provided parameters.
 */
function searchPropositionsRaw<T>(requestParameters: SearchPropositionsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Proposition>> = {}): QueryConfig<T> {
    if (requestParameters.distanceFrom === null || requestParameters.distanceFrom === undefined) {
        throw new runtime.RequiredError('distanceFrom','Required parameter requestParameters.distanceFrom was null or undefined when calling searchPropositions.');
    }

    if (requestParameters.distanceTo === null || requestParameters.distanceTo === undefined) {
        throw new runtime.RequiredError('distanceTo','Required parameter requestParameters.distanceTo was null or undefined when calling searchPropositions.');
    }

    if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
        throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling searchPropositions.');
    }

    if (requestParameters.selectionAge === null || requestParameters.selectionAge === undefined) {
        throw new runtime.RequiredError('selectionAge','Required parameter requestParameters.selectionAge was null or undefined when calling searchPropositions.');
    }

    if (requestParameters.selectionBreed === null || requestParameters.selectionBreed === undefined) {
        throw new runtime.RequiredError('selectionBreed','Required parameter requestParameters.selectionBreed was null or undefined when calling searchPropositions.');
    }

    if (requestParameters.selectionGender === null || requestParameters.selectionGender === undefined) {
        throw new runtime.RequiredError('selectionGender','Required parameter requestParameters.selectionGender was null or undefined when calling searchPropositions.');
    }

    if (requestParameters.selectionRaceTypes === null || requestParameters.selectionRaceTypes === undefined) {
        throw new runtime.RequiredError('selectionRaceTypes','Required parameter requestParameters.selectionRaceTypes was null or undefined when calling searchPropositions.');
    }

    if (requestParameters.selectionRaised === null || requestParameters.selectionRaised === undefined) {
        throw new runtime.RequiredError('selectionRaised','Required parameter requestParameters.selectionRaised was null or undefined when calling searchPropositions.');
    }

    if (requestParameters.selectionSurfaces === null || requestParameters.selectionSurfaces === undefined) {
        throw new runtime.RequiredError('selectionSurfaces','Required parameter requestParameters.selectionSurfaces was null or undefined when calling searchPropositions.');
    }

    if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
        throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling searchPropositions.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.distanceFrom !== undefined) {
        queryParameters['distanceFrom'] = requestParameters.distanceFrom;
    }


    if (requestParameters.distanceTo !== undefined) {
        queryParameters['distanceTo'] = requestParameters.distanceTo;
    }


    if (requestParameters.fromDate !== undefined) {
        queryParameters['fromDate'] = (requestParameters.fromDate as any).toLocalDate();
    }


    if (requestParameters.horseId !== undefined) {
        queryParameters['horseId'] = requestParameters.horseId;
    }


    if (requestParameters.selectionAge !== undefined) {
        queryParameters['selectionAge'] = requestParameters.selectionAge;
    }


    if (requestParameters.selectionBreed !== undefined) {
        queryParameters['selectionBreed'] = requestParameters.selectionBreed;
    }


    if (requestParameters.selectionGender !== undefined) {
        queryParameters['selectionGender'] = requestParameters.selectionGender;
    }


    if (requestParameters.selectionRaceTypes) {
        queryParameters['selectionRaceTypes'] = requestParameters.selectionRaceTypes;
    }


    if (requestParameters.selectionRaised !== undefined) {
        queryParameters['selectionRaised'] = requestParameters.selectionRaised;
    }


    if (requestParameters.selectionSurfaces) {
        queryParameters['selectionSurfaces'] = requestParameters.selectionSurfaces;
    }


    if (requestParameters.toDate !== undefined) {
        queryParameters['toDate'] = (requestParameters.toDate as any).toLocalDate();
    }


    if (requestParameters.trackCodes) {
        queryParameters['trackCodes'] = requestParameters.trackCodes;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/search`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(PropositionFromJSON), text);
    }

    return config;
}

/**
* Search for propositions using different parameters. Either for a horse by horseId or for a general search matching the provided parameters.
*/
export function searchPropositions<T>(requestParameters: SearchPropositionsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Proposition>>): QueryConfig<T> {
    return searchPropositionsRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum SearchPropositionsSelectionAgeEnum {
    ALL = 'ALL',
    TWO = 'TWO',
    THREE = 'THREE',
    THREE_AND_OLDER = 'THREE_AND_OLDER',
    FOUR = 'FOUR',
    FOUR_AND_OLDER = 'FOUR_AND_OLDER'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchPropositionsSelectionBreedEnum {
    ALL = 'ALL',
    ARABIC_THOROUGHBRED = 'ARABIC_THOROUGHBRED'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchPropositionsSelectionGenderEnum {
    BOTH = 'BOTH',
    MARE = 'MARE',
    STALLION_GELDING = 'STALLION_GELDING'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchPropositionsSelectionRaceTypesEnum {
    AGEWEIGHT = 'AGEWEIGHT',
    MAIDEN = 'MAIDEN',
    HANDICAP = 'HANDICAP'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchPropositionsSelectionRaisedEnum {
    ALL = 'ALL',
    SWEDEN = 'SWEDEN',
    SCANDINAVIA = 'SCANDINAVIA'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchPropositionsSelectionSurfacesEnum {
    ALL = 'ALL',
    DIRT = 'DIRT',
    GRASS = 'GRASS',
    HURDLE = 'HURDLE',
    STEEPLECHASE = 'STEEPLECHASE'
}

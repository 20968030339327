import { useSelector } from 'react-redux';
import { errorSelectors, QueryConfig } from 'redux-query';

import { State } from '../../main/store';
import { Entities } from '..';
import { ApiResponse } from './types';

interface Props<TQuery, TSelector> {
  query: TQuery;
  selector: TSelector;
}

interface ErrorResponse {
  message?: string;
  errorMessage?: string;
  localizedErrorMessage?: string;
  fieldErrors?: { [key: string]: string };
  errorCode?: number;
}

const useApiCommon = <
  TResp,
  TQuery extends QueryConfig = QueryConfig,
  TSelector extends (state: State) => TResp = (state: State) => TResp,
>({
  query,
  selector,
}: Props<TQuery, TSelector>): Pick<
  ApiResponse<TResp, Entities>,
  'data' | 'error' | 'errorCode'
> => {
  // Select the data entities
  const data = useSelector(selector);

  // Select the error entity
  const error: ErrorResponse | undefined = useSelector((state: State) =>
    errorSelectors.responseBody(state.errors, query),
  );

  // Select Network error
  const netWorkError: string | undefined = useSelector((state: State) =>
    errorSelectors.responseText(state.errors, query),
  );

  const errorMessage =
    error?.localizedErrorMessage ||
    error?.errorMessage ||
    error?.message ||
    netWorkError;

  return {
    data,
    error: errorMessage,
    errorCode: error?.errorCode,
  };
};

export default useApiCommon;

import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import {
  getHorseImportExport,
  HorseImportExport,
} from '../../generated/horses/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  horseImport?: HorseImportExport[];
  horseExport?: HorseImportExport[];
}

const getQuery = (isImport: boolean): QueryConfig<Entities> =>
  getHorseImportExport<Entities>(
    {
      isImport,
    },
    {
      transform: responseBody => ({
        [isImport ? 'horseImport' : 'horseExport']: responseBody,
      }),
      update: {
        [isImport ? 'horseImport' : 'horseExport']: (
          prev: HorseImportExport[] | undefined,
          next: HorseImportExport[],
        ): typeof next => next,
      },
    },
  );

const useGetHorseImportExport = (
  isImport: boolean,
): ApiResponse<HorseImportExport[] | undefined, Entities> => {
  const query = getQuery(isImport);
  const selector = (state: State): HorseImportExport[] | undefined =>
    isImport ? state.entities.horseImport : state.entities.horseExport;

  return useApi<HorseImportExport[] | undefined>({
    query,
    selector,
  });
};

export default useGetHorseImportExport;

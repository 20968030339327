// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    LicenseHolderBasicInformation,
    LicenseHolderBasicInformationFromJSON,
    LicenseHolderBasicInformationToJSON,
} from '../models';

export interface GetBasicInformationRequest {
    licenseId: number;
}


/**
 * Get license holder basic information, primarily for use within sport info.
 */
function getBasicInformationRaw<T>(requestParameters: GetBasicInformationRequest, requestConfig: runtime.TypedQueryConfig<T, LicenseHolderBasicInformation> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getBasicInformation.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/licenseholders/{licenseId}/basicinformation`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(LicenseHolderBasicInformationFromJSON(body), text);
    }

    return config;
}

/**
* Get license holder basic information, primarily for use within sport info.
*/
export function getBasicInformation<T>(requestParameters: GetBasicInformationRequest, requestConfig?: runtime.TypedQueryConfig<T, LicenseHolderBasicInformation>): QueryConfig<T> {
    return getBasicInformationRaw(requestParameters, requestConfig);
}


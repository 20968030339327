import { fetchHorseStatistics, HorseStatistics } from '@generated/horses/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = HorseStatistics | undefined;

export interface Entities {
  horseStatistics?: {
    [id: number]: Result;
  };
}

const getQuery = (horseId: number): QueryConfig<Entities> =>
  fetchHorseStatistics<Entities>(
    {
      horseId,
    },
    {
      transform: responseBody => ({
        horseStatistics: {
          [horseId]: responseBody,
        },
      }),
      update: {
        horseStatistics: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetHorseStatistics = (
  horseId: number,
): ApiResponse<Result, Entities> => {
  const query = getQuery(horseId);
  const selector = (state: State): Result =>
    state.entities?.horseStatistics?.[horseId];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetHorseStatistics;

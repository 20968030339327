// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    LinkableBreeder,
    LinkableBreederFromJSON,
    LinkableBreederToJSON,
    LinkableHorse,
    LinkableHorseFromJSON,
    LinkableHorseToJSON,
    LinkableOwner,
    LinkableOwnerFromJSON,
    LinkableOwnerToJSON,
} from './';

/**
 * No winner in qualification or prize races
 * @export
 * @interface WinnerHorse
 */
export interface WinnerHorse  {
    /**
     * 
     * @type {number}
     * @memberof WinnerHorse
     */
    placing: number;
    /**
     * 
     * @type {number}
     * @memberof WinnerHorse
     */
    age: number;
    /**
     * 
     * @type {string}
     * @memberof WinnerHorse
     */
    colorAndGenderCode: string;
    /**
     * 
     * @type {LinkableHorse}
     * @memberof WinnerHorse
     */
    horse: LinkableHorse;
    /**
     * 
     * @type {LinkableHorse}
     * @memberof WinnerHorse
     */
    father: LinkableHorse;
    /**
     * 
     * @type {LinkableHorse}
     * @memberof WinnerHorse
     */
    mother: LinkableHorse;
    /**
     * 
     * @type {LinkableHorse}
     * @memberof WinnerHorse
     */
    mothersFather: LinkableHorse;
    /**
     * 
     * @type {LinkableOwner}
     * @memberof WinnerHorse
     */
    owner: LinkableOwner;
    /**
     * 
     * @type {LinkableBreeder}
     * @memberof WinnerHorse
     */
    breeder: LinkableBreeder;
}

export function WinnerHorseFromJSON(json: any): WinnerHorse {
    return {
        'placing': json['placing'],
        'age': json['age'],
        'colorAndGenderCode': json['colorAndGenderCode'],
        'horse': LinkableHorseFromJSON(json['horse']),
        'father': LinkableHorseFromJSON(json['father']),
        'mother': LinkableHorseFromJSON(json['mother']),
        'mothersFather': LinkableHorseFromJSON(json['mothersFather']),
        'owner': LinkableOwnerFromJSON(json['owner']),
        'breeder': LinkableBreederFromJSON(json['breeder']),
    };
}

export function WinnerHorseToJSON(value?: WinnerHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'placing': value.placing,
        'age': value.age,
        'colorAndGenderCode': value.colorAndGenderCode,
        'horse': LinkableHorseToJSON(value.horse),
        'father': LinkableHorseToJSON(value.father),
        'mother': LinkableHorseToJSON(value.mother),
        'mothersFather': LinkableHorseToJSON(value.mothersFather),
        'owner': LinkableOwnerToJSON(value.owner),
        'breeder': LinkableBreederToJSON(value.breeder),
    };
}



import { useGetDriverCommission, useUpdateDriverCommission } from '@apis';
import { Input } from '@components/FormikComponents';
import FormLayout, { FormError, FormItem } from '@components/FormLayout';
import { DriverCommission as DriverCommissionType } from '@generated/tds/src';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import Message from '@primitives/Message';
import { H3 } from '@primitives/Typography';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

interface Props {
  licenseId: number;
}

export default function DriverCommission({ licenseId }: Props): JSX.Element {
  const {
    data: commission,
    loading: commissionLoading,
    error: commissionError,
    status: commissionStatus,
    refresh,
  } = useGetDriverCommission(licenseId);
  const { action } = useUpdateDriverCommission(licenseId);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
  }, [success, setSuccess]);

  if (commissionStatus && commissionStatus !== 200) {
    return (
      <Error mt={4}>
        {commissionStatus} {commissionError}
      </Error>
    );
  }

  if (commissionLoading && !commission) {
    return <Loading mt={4} />;
  }

  return (
    <Box mt={5}>
      <H3 mb={4}>Ridarvoden</H3>
      {success && (
        <Message variant="successBox" mb={4}>
          Sparat!
        </Message>
      )}
      <Formik<DriverCommissionType>
        initialValues={commission.driverCommission}
        validationSchema={Yup.object().shape({
          plainFee: Yup.number().typeError('Ange ett tal').min(0, 'För lågt'),
          obstacleFee: Yup.number()
            .typeError('Ange ett tal')
            .min(0, 'För lågt'),
          commissionRate: Yup.number()
            .typeError('Ange ett tal')
            .min(0, 'För lågt')
            .max(100, 'För högt'),
        })}
        onSubmit={async values => {
          setError('');
          setSuccess(false);

          const { body } = await action({
            licenseId,
            driverCommission: values,
          });

          if (body && body.errorMessage) {
            setError(body.errorMessage);
          } else {
            await refresh();

            setSuccess(true);
          }
        }}
      >
        {({ isSubmitting }) => (
          <FormLayout>
            <FormItem width={[1, null, 1 / 4]}>
              <Input
                name="plainFee"
                label="Arvode slätt"
                type="text"
                disabled={isSubmitting}
              />
            </FormItem>
            <FormItem width={[1, null, 1 / 4]}>
              <Input
                name="obstacleFee"
                label="Arvode hinder"
                type="text"
                disabled={isSubmitting}
              />
            </FormItem>
            <FormItem width={[1, null, 1 / 4]}>
              <Input
                name="commissionRate"
                label="Procent av vinst"
                type="text"
                disabled={isSubmitting}
              />
            </FormItem>
            <FormItem width={[1, null, 1 / 4]} noLabel>
              <Button type="submit" loading={isSubmitting}>
                Spara
              </Button>
            </FormItem>
            {error && <FormError>{error}</FormError>}
          </FormLayout>
        )}
      </Formik>
    </Box>
  );
}

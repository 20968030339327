import Card from '@components/Card';
import PageWrapper from '@components/PageWrapper';
import { Container, Item } from '@primitives/Box';
import Button from '@primitives/Button';
import { H1, Text } from '@primitives/Typography';
import * as Sentry from '@sentry/react';
import React from 'react';

interface ErrorViewProps {
  error: Error;
}

function ErrorView({ error }: ErrorViewProps): JSX.Element {
  // useEffect(() => {
  //   if (error.name === 'ChunkLoadError') {
  //     window.location.reload();
  //   }
  // }, [error]);

  if (error.name === 'ChunkLoadError') {
    return null;
  }

  return (
    <PageWrapper variant="small">
      <H1>Något gick visst fel</H1>
      <Container justifyContent="center">
        <Item width={[1]} mt={1}>
          <Card>
            <Text>
              Ett oväntat fel har tyvärr inträffat, prova gärna att ladda om
              sidan. Om problemet kvarstår kan du kontakta oss på{' '}
              <a href="mailto:info@svenskgalopp.se">info@svenskgalopp.se</a>{' '}
              eller 08-466 86 00.
            </Text>
            <Button
              mt={3}
              mb={3}
              type="button"
              onClick={() => window.location.reload()}
            >
              Ladda om sidan
            </Button>
          </Card>
        </Item>
      </Container>
    </PageWrapper>
  );
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export default function ErrorBoundary({
  children,
}: ErrorBoundaryProps): JSX.Element {
  return (
    <Sentry.ErrorBoundary fallback={ErrorView}>{children}</Sentry.ErrorBoundary>
  );
}

// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmailResponseMessage
 */
export interface EmailResponseMessage  {
    /**
     * 
     * @type {string}
     * @memberof EmailResponseMessage
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailResponseMessage
     */
    customId?: string;
    /**
     * 
     * @type {Array<ResponseError>}
     * @memberof EmailResponseMessage
     */
    errors?: Array<ResponseError>;
}

export function EmailResponseMessageFromJSON(json: any): EmailResponseMessage {
    return {
        'status': !exists(json, 'status') ? undefined : json['status'],
        'customId': !exists(json, 'customId') ? undefined : json['customId'],
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] as Array<any>).map(ResponseErrorFromJSON),
    };
}

export function EmailResponseMessageToJSON(value?: EmailResponseMessage): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'status': value.status,
        'customId': value.customId,
        'errors': value.errors === undefined ? undefined : (value.errors as Array<any>).map(ResponseErrorToJSON),
    };
}



import {
  getOwnershipsHorsesSummary,
  OwnershipsHorsesSummary,
} from '@generated/sportactors/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = OwnershipsHorsesSummary | undefined;

export interface Entities {
  ownershipsHorsesSummary?: {
    [id: number]: Result;
  };
}

const getQuery = (id: number): QueryConfig<Entities> =>
  getOwnershipsHorsesSummary<Entities>(
    {
      ownerId: id,
    },
    {
      transform: responseBody => ({
        ownershipsHorsesSummary: { [id]: responseBody },
      }),
      update: {
        ownershipsHorsesSummary: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetOwnershipHorsesSummary = (
  id: number,
): ApiResponse<Result, Entities> => {
  const query = getQuery(id);
  const selector = (state: State): Result =>
    state.entities?.ownershipsHorsesSummary?.[id];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetOwnershipHorsesSummary;

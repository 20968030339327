// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    PropositionDetails,
    PropositionDetailsFromJSON,
    PropositionDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface Proposition
 */
export interface Proposition  {
    /**
     * The race day id for the proposition.
     * @type {number}
     * @memberof Proposition
     */
    raceDayId: number;
    /**
     * The unique id for the proposition.
     * @type {number}
     * @memberof Proposition
     */
    propositionId: number;
    /**
     * The proposition number.
     * @type {number}
     * @memberof Proposition
     */
    propositionNumber: number;
    /**
     * The name of the race (proposition).
     * @type {string}
     * @memberof Proposition
     */
    name: string;
    /**
     * The name of the track of which the race is going to be held.
     * @type {string}
     * @memberof Proposition
     */
    trackName: string;
    /**
     * The race date.
     * @type {Date}
     * @memberof Proposition
     */
    raceDate: Date;
    /**
     * The distance of the race.
     * @type {number}
     * @memberof Proposition
     */
    distance: number;
    /**
     * The type of surface of which the race is going to be held.
     * @type {string}
     * @memberof Proposition
     */
    surface: string;
    /**
     * The prize money of the race.
     * @type {number}
     * @memberof Proposition
     */
    totalPriceMoney: number;
    /**
     * The race type.
     * @type {string}
     * @memberof Proposition
     */
    raceType: string;
    /**
     * The proposition type.
     * @type {string}
     * @memberof Proposition
     */
    propositionType: string;
    /**
     * An indicator of weight race.
     * @type {boolean}
     * @memberof Proposition
     */
    weight: boolean;
    /**
     * 
     * @type {PropositionDetails}
     * @memberof Proposition
     */
    propositionDetails?: PropositionDetails;
}

export function PropositionFromJSON(json: any): Proposition {
    return {
        'raceDayId': json['raceDayId'],
        'propositionId': json['propositionId'],
        'propositionNumber': json['propositionNumber'],
        'name': json['name'],
        'trackName': json['trackName'],
        'raceDate': parseDateTime(json['raceDate']),
        'distance': json['distance'],
        'surface': json['surface'],
        'totalPriceMoney': json['totalPriceMoney'],
        'raceType': json['raceType'],
        'propositionType': json['propositionType'],
        'weight': json['weight'],
        'propositionDetails': !exists(json, 'propositionDetails') ? undefined : PropositionDetailsFromJSON(json['propositionDetails']),
    };
}

export function PropositionToJSON(value?: Proposition): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'raceDayId': value.raceDayId,
        'propositionId': value.propositionId,
        'propositionNumber': value.propositionNumber,
        'name': value.name,
        'trackName': value.trackName,
        'raceDate': value.raceDate.toLocalDate(),
        'distance': value.distance,
        'surface': value.surface,
        'totalPriceMoney': value.totalPriceMoney,
        'raceType': value.raceType,
        'propositionType': value.propositionType,
        'weight': value.weight,
        'propositionDetails': PropositionDetailsToJSON(value.propositionDetails),
    };
}



// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * A list of the license holders phonenumbers
 * @export
 * @interface Phonenumber
 */
export interface Phonenumber  {
    /**
     * 
     * @type {string}
     * @memberof Phonenumber
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof Phonenumber
     */
    type?: PhonenumberTypeEnum;
}

export function PhonenumberFromJSON(json: any): Phonenumber {
    return {
        'number': !exists(json, 'number') ? undefined : json['number'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function PhonenumberToJSON(value?: Phonenumber): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'number': value.number,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum PhonenumberTypeEnum {
    SMS_CODE_SMS_DESCRIPTION_SMS = 'PhoneType.SMS(code=SMS, description=Sms)',
    CELL_CODE_MB_DESCRIPTION_MOBILTELEFON = 'PhoneType.CELL(code=MB, description=Mobiltelefon)',
    HOME_CODE_BO_DESCRIPTION_BOSTAD = 'PhoneType.HOME(code=BO, description=Bostad)',
    WORK_CODE_A_DESCRIPTION_ARBETE = 'PhoneType.WORK(code=A, description=Arbete)',
    FACSIMILE_CODE_FX_DESCRIPTION_TELEFAX = 'PhoneType.FACSIMILE(code=FX, description=Telefax)',
    SUMMER_RESIDENCE_CODE_SBO_DESCRIPTION_SOMMARBOSTAD = 'PhoneType.SUMMER_RESIDENCE(code=SBO, description=Sommarbostad)',
    STABLE_CODE_S_DESCRIPTION_STALL = 'PhoneType.STABLE(code=S, description=Stall)',
    BEEPER_CODE_PS_DESCRIPTION_PERSONSKARE = 'PhoneType.BEEPER(code=PS, description=Personsökare)',
    OFFICE_CODE_K_DESCRIPTION_KONTOR = 'PhoneType.OFFICE(code=K, description=Kontor)',
    BANKRUPTCY_TRUSTEE_CODE_KK_DESCRIPTION_KONKURSFRVALTARE = 'PhoneType.BANKRUPTCY_TRUSTEE(code=KK, description=Konkursförvaltare)',
    TEXT_TELEPHONE_CODE_TXT_DESCRIPTION_TEXTTELEFON = 'PhoneType.TEXT_TELEPHONE(code=TXT, description=Texttelefon)',
    LIQUIDATOR_CODE_LIKV_DESCRIPTION_LIKVIDATOR = 'PhoneType.LIQUIDATOR(code=LIKV, description=Likvidator)',
    UNKNOWN_CODE_ZZ_DESCRIPTION_OKNT = 'PhoneType.UNKNOWN(code=ZZ, description=Okänt)'
}



// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Transaction,
    TransactionFromJSON,
    TransactionToJSON,
} from './';

/**
 * 
 * @export
 * @interface OwnerTransactions
 */
export interface OwnerTransactions  {
    /**
     * 
     * @type {number}
     * @memberof OwnerTransactions
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OwnerTransactions
     */
    ownerName?: string;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof OwnerTransactions
     */
    transactions?: Array<Transaction>;
    /**
     * 
     * @type {number}
     * @memberof OwnerTransactions
     */
    amountSum?: number;
    /**
     * 
     * @type {number}
     * @memberof OwnerTransactions
     */
    vatAmountSum?: number;
    /**
     * 
     * @type {number}
     * @memberof OwnerTransactions
     */
    totalAmountSum?: number;
}

export function OwnerTransactionsFromJSON(json: any): OwnerTransactions {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ownerName': !exists(json, 'ownerName') ? undefined : json['ownerName'],
        'transactions': !exists(json, 'transactions') ? undefined : (json['transactions'] as Array<any>).map(TransactionFromJSON),
        'amountSum': !exists(json, 'amountSum') ? undefined : json['amountSum'],
        'vatAmountSum': !exists(json, 'vatAmountSum') ? undefined : json['vatAmountSum'],
        'totalAmountSum': !exists(json, 'totalAmountSum') ? undefined : json['totalAmountSum'],
    };
}

export function OwnerTransactionsToJSON(value?: OwnerTransactions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'ownerName': value.ownerName,
        'transactions': value.transactions === undefined ? undefined : (value.transactions as Array<any>).map(TransactionToJSON),
        'amountSum': value.amountSum,
        'vatAmountSum': value.vatAmountSum,
        'totalAmountSum': value.totalAmountSum,
    };
}



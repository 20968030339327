// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    Horse,
    HorseFromJSON,
    HorseToJSON,
    LicenseHolder,
    LicenseHolderFromJSON,
    LicenseHolderToJSON,
    SportActorHorseGender,
    SportActorHorseGenderFromJSON,
    SportActorHorseGenderToJSON,
} from './';

/**
 * 
 * @export
 * @interface SportActorHorse
 */
export interface SportActorHorse  {
    /**
     * 
     * @type {Horse}
     * @memberof SportActorHorse
     */
    horse: Horse;
    /**
     * 
     * @type {SportActorHorseGender}
     * @memberof SportActorHorse
     */
    gender: SportActorHorseGender;
    /**
     * 
     * @type {Date}
     * @memberof SportActorHorse
     */
    dateOfBirth: Date;
    /**
     * 
     * @type {number}
     * @memberof SportActorHorse
     */
    age: number;
    /**
     * 
     * @type {boolean}
     * @memberof SportActorHorse
     */
    markedAsDead: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SportActorHorse
     */
    registrationStatusChangeable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SportActorHorse
     */
    ownershipForm?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof SportActorHorse
     */
    ownerRepresentativeIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof SportActorHorse
     */
    prizeMoney: number;
    /**
     * 
     * @type {LicenseHolder}
     * @memberof SportActorHorse
     */
    trainer?: LicenseHolder;
    /**
     * 
     * @type {number}
     * @memberof SportActorHorse
     */
    generalHandicap: number;
    /**
     * 
     * @type {string}
     * @memberof SportActorHorse
     */
    countryCode?: string;
}

export function SportActorHorseFromJSON(json: any): SportActorHorse {
    return {
        'horse': HorseFromJSON(json['horse']),
        'gender': SportActorHorseGenderFromJSON(json['gender']),
        'dateOfBirth': parseDateTime(json['dateOfBirth']),
        'age': json['age'],
        'markedAsDead': json['markedAsDead'],
        'registrationStatusChangeable': !exists(json, 'registrationStatusChangeable') ? undefined : json['registrationStatusChangeable'],
        'ownershipForm': !exists(json, 'ownershipForm') ? undefined : json['ownershipForm'],
        'ownerRepresentativeIds': !exists(json, 'ownerRepresentativeIds') ? undefined : json['ownerRepresentativeIds'],
        'prizeMoney': json['prizeMoney'],
        'trainer': !exists(json, 'trainer') ? undefined : LicenseHolderFromJSON(json['trainer']),
        'generalHandicap': json['generalHandicap'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
    };
}

export function SportActorHorseToJSON(value?: SportActorHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horse': HorseToJSON(value.horse),
        'gender': SportActorHorseGenderToJSON(value.gender),
        'dateOfBirth': value.dateOfBirth.toLocalDate(),
        'age': value.age,
        'markedAsDead': value.markedAsDead,
        'registrationStatusChangeable': value.registrationStatusChangeable,
        'ownershipForm': value.ownershipForm,
        'ownerRepresentativeIds': value.ownerRepresentativeIds,
        'prizeMoney': value.prizeMoney,
        'trainer': LicenseHolderToJSON(value.trainer),
        'generalHandicap': value.generalHandicap,
        'countryCode': value.countryCode,
    };
}



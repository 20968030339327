import Box from '@primitives/Box';
import List from '@primitives/List';
import { Text } from '@primitives/Typography';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { ShortcutMenuSection } from '../menus';

interface Props {
  menu: ShortcutMenuSection[];
  hideMenu: () => void;
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 234px;
  padding-top: 19px;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.gray2};
  color: ${props => props.theme.colors.gray4};
`;

const Section = styled(List)``;

const SectionTitle = styled(Text)<SpaceProps>`
  ${space}
  font-weight: bold;
`;

const Shortcut = styled.li`
  width: 100%;
  height: 30px;
`;

const Link = styled(NavLink)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.gray4};
  padding-left: 6px;
  border-radius: 5px;
  text-decoration: none;

  &:hover {
    background-color: ${props => props.theme.colors.white};
  }
`;

export default function Shortcuts({ menu, hideMenu }: Props): JSX.Element {
  const items = menu.map(section => (
    <Box key={section.title} flexGrow={1}>
      <SectionTitle mb={1}>{section.title}</SectionTitle>
      <Section>
        {section.shortcuts.map(shortcut => (
          <Shortcut key={shortcut.label}>
            <Link to={shortcut.href} onClick={hideMenu}>
              {shortcut.label}
            </Link>
          </Shortcut>
        ))}
      </Section>
    </Box>
  ));

  return <Wrapper p={3}>{items}</Wrapper>;
}

// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Breeding summary statistics, populated for search category: STAMBOK_SAM_BETECKNINGAR
 * @export
 * @interface BreedingSummaryStatistics
 */
export interface BreedingSummaryStatistics  {
    /**
     * The sire type descriptive text, not to be confused with the sire type for filtering.
     * @type {string}
     * @memberof BreedingSummaryStatistics
     */
    sireTypeText?: string;
    /**
     * 
     * @type {number}
     * @memberof BreedingSummaryStatistics
     */
    numberOfSires?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingSummaryStatistics
     */
    numberOfMares?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingSummaryStatistics
     */
    numberOfDoubleBreedings?: number;
}

export function BreedingSummaryStatisticsFromJSON(json: any): BreedingSummaryStatistics {
    return {
        'sireTypeText': !exists(json, 'sireTypeText') ? undefined : json['sireTypeText'],
        'numberOfSires': !exists(json, 'numberOfSires') ? undefined : json['numberOfSires'],
        'numberOfMares': !exists(json, 'numberOfMares') ? undefined : json['numberOfMares'],
        'numberOfDoubleBreedings': !exists(json, 'numberOfDoubleBreedings') ? undefined : json['numberOfDoubleBreedings'],
    };
}

export function BreedingSummaryStatisticsToJSON(value?: BreedingSummaryStatistics): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'sireTypeText': value.sireTypeText,
        'numberOfSires': value.numberOfSires,
        'numberOfMares': value.numberOfMares,
        'numberOfDoubleBreedings': value.numberOfDoubleBreedings,
    };
}



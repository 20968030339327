// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    GenderGroupGenders,
    GenderGroupGendersFromJSON,
    GenderGroupGendersToJSON,
} from './';

/**
 * 
 * @export
 * @interface FoalHorse
 */
export interface FoalHorse  {
    /**
     * 
     * @type {number}
     * @memberof FoalHorse
     */
    horseId: number;
    /**
     * 
     * @type {string}
     * @memberof FoalHorse
     */
    name: string;
    /**
     * 
     * @type {GenderGroupGenders}
     * @memberof FoalHorse
     */
    gender: GenderGroupGenders;
    /**
     * 
     * @type {string}
     * @memberof FoalHorse
     */
    registrationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof FoalHorse
     */
    registrationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof FoalHorse
     */
    country?: string;
}

export function FoalHorseFromJSON(json: any): FoalHorse {
    return {
        'horseId': json['horseId'],
        'name': json['name'],
        'gender': GenderGroupGendersFromJSON(json['gender']),
        'registrationNumber': json['registrationNumber'],
        'registrationCode': !exists(json, 'registrationCode') ? undefined : json['registrationCode'],
        'country': !exists(json, 'country') ? undefined : json['country'],
    };
}

export function FoalHorseToJSON(value?: FoalHorse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horseId': value.horseId,
        'name': value.name,
        'gender': GenderGroupGendersToJSON(value.gender),
        'registrationNumber': value.registrationNumber,
        'registrationCode': value.registrationCode,
        'country': value.country,
    };
}



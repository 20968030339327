import Box from '@primitives/Box';
import React, { Fragment, ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled(Box)`
  display: flex;
  overflow: hidden;
  border-radius: 4px;
  background: ${props => props.theme.colors.whiteSmoke};
  height: auto;
  min-height: 32px;
  padding: 1px;
`;

const Input = styled.input`
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;

  &:checked + label {
    background-color: #fff;
    color: #404040;
    font-weight: bold;
  }
`;

const Label = styled.label`
  flex: 1 1 0px;
  color: #707070;
  font-size: 13px;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  padding: 6px 4px;
  transition: all 0.1s ease-in-out;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  options: {
    value: string;
    label: ReactNode;
  }[];
  type?: string;
};

function SwitchRadios({ options, value, onChange }: Props): JSX.Element {
  return (
    <Container>
      {options.map(opt => (
        <Fragment key={opt.value}>
          <Input
            id={opt.value}
            name={opt.value}
            value={opt.value}
            type="radio"
            onChange={onChange}
            checked={opt.value === value}
          />
          <Label htmlFor={opt.value}>{opt.label}</Label>
        </Fragment>
      ))}
    </Container>
  );
}

export default SwitchRadios;

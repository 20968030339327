import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { getInvoices, Invoice } from '../../generated/sportactors/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = Invoice[] | undefined;

export interface Entities {
  invoices?: {
    [key: string]: Result;
  };
}

const getQuery = (
  ownerId: number,
  from: number,
  to: number,
  key: string,
): QueryConfig<Entities> =>
  getInvoices<Entities>(
    {
      ownerId,
      from: from.toString(),
      to: to.toString(),
    },
    {
      force: true,
      transform: responseBody => ({
        invoices: {
          [key]: responseBody,
        },
      }),
      update: {
        invoices: (prev, next): typeof next => ({ ...prev, ...next }),
      },
    },
  );

const useGetInvoices = (
  ownerId: number,
  from: number,
  to: number,
): ApiResponse<Result, Entities> => {
  const key = `invoices-${ownerId}-${from.toString()}-${to.toString()}`;
  const query = getQuery(ownerId, from, to, key);
  const selector = (state: State): Result => state.entities?.invoices?.[key];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetInvoices;

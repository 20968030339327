import {
  search,
  SearchRequest,
  StudbookSearchResult,
} from '@generated/breedings/src';
import { State } from '@main/store';
import cleanObject from '@utils/cleanObject';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  searchBreedings?: {
    [key: string]: StudbookSearchResult;
  };
}

type Values = StudbookSearchResult | undefined;

export default function useSearchBreedings(
  raw: SearchRequest,
): ApiResponse<Values, Entities> {
  // Remove emty params
  const params = cleanObject(raw) as SearchRequest;

  const key = JSON.stringify(params);

  const query = search<Entities>(params, {
    transform: responseBody => ({
      searchBreedings: {
        [key]: responseBody,
      },
    }),
    update: {
      searchBreedings: (prev, next) => ({
        ...prev,
        ...next,
      }),
    },
  });

  const selector = (state: State): Values =>
    state.entities?.searchBreedings?.[key];

  return useApi<Values>({
    query,
    selector,
  });
}

import { useContent } from '@apis';
import InfoBadge from '@components/InfoBadge';
import Box from '@primitives/Box';
import { CircleInfo as InfoCircle } from '@styled-icons/fa-solid';
import React from 'react';

type BoxParameters = Parameters<typeof Box>;

type Props = {
  slug: string;
} & BoxParameters[0];

function ImportantMessageFromExternalSlugg({
  slug,
  ...props
}: Props): JSX.Element {
  const { data: content } = useContent(slug);

  return content?.text ? (
    <Box {...props}>
      <InfoBadge
        icon={InfoCircle}
        message={
          <span
            dangerouslySetInnerHTML={{
              __html: content.text,
            }}
          />
        }
        color="blue"
      />
    </Box>
  ) : null;
}
export default ImportantMessageFromExternalSlugg;

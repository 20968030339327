import Box from '@primitives/Box';
import React from 'react';
import { useParams } from 'react-router-dom';

import Form from './Form';
import List from './List';

export default function ScratchFromStakeRaces(): JSX.Element {
  const { propositionId } = useParams<{ propositionId?: string }>();

  if (propositionId) {
    return (
      <Box mt={[0, , 4]}>
        <Form propositionId={parseInt(propositionId)} />
      </Box>
    );
  }

  return (
    <Box mt={4}>
      <List />
    </Box>
  );
}

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Details from './Details';
import List from './List';

interface Props {
  costsPath: string;
  licenseId: number;
}

export default function Costs({ costsPath, licenseId }: Props): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:raceDayId`}>
        <Details costsPath={costsPath} licenseId={licenseId} />
      </Route>
      <Route path={path}>
        <List licenseId={licenseId} />
      </Route>
    </Switch>
  );
}

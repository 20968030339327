import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import {
  getOwnershipStatistics,
  Statistics,
} from '../../generated/sportactors/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = Statistics[] | undefined;

export interface Entities {
  ownerStatisics?: {
    [id: number]: Result;
  };
}

const getQuery = (ownerId: number): QueryConfig<Entities> =>
  getOwnershipStatistics<Entities>(
    {
      ownerId,
    },
    {
      transform: responseBody => ({
        ownerStatisics: {
          [ownerId]: responseBody,
        },
      }),
      update: {
        ownerStatisics: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetOwnerStatistics = (id: number): ApiResponse<Result, Entities> => {
  const query = getQuery(id);
  const selector = (state: State): Result =>
    state.entities?.ownerStatisics?.[id];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetOwnerStatistics;

import { useGetTrainerHorses } from '@apis';
import ContentBlock from '@components/ContentBlock';
import DataGrid from '@components/DataGrid';
import { PageLayout } from '@components/PageLayouts';
import ChangeRegistrationStatus from '@features/ChangeRegistrationStatus';
import { AuthenticatedUser } from '@generated/account/src';
import { HorseInTraining } from '@generated/licenseholders/src';
import withUser from '@hoc/withUser';
import useAuthenticationMethod from '@hooks/useAuthenticationMethod';
import useNumericQueryParams from '@hooks/useNumericQueryParams';
import ErrorComponent from '@primitives/Error';
import { Link } from '@primitives/Link';
import List, { LI } from '@primitives/List';
import Loading from '@primitives/Loading';
import Message from '@primitives/Message';
import { Text } from '@primitives/Typography';
import { formatNumber } from '@utils/formatting';
import { horse } from '@utils/links';
import roleEnum from '@utils/roleEnum';
import React, { useCallback, useEffect, useState } from 'react';
import { CellProps, Column } from 'react-table';

const columns = (
  authenticationMethod: string,
  horseId: number,
  onSuccess: (msg: string) => void,
): Column<HorseInTraining>[] => [
  {
    id: 'name',
    Header: 'Namn',
    accessor: row => row.horse.name,
    Cell: ({
      value,
      row,
    }: CellProps<HorseInTraining, HorseInTraining['horse']['name']>) => (
      <>
        <Link to={horse(row.original.horse.id)}>{value}</Link>
        {value.startsWith('NN') && (
          <Text as="span" fontStyle="italic">
            {' '}
            Mor: {row.original.motherHorse.name}
          </Text>
        )}
      </>
    ),
  },
  {
    Header: 'Ändra regstatus',
    thStyle: () => ({
      whiteSpace: 'normal',
    }),
    accessor: row => row.horse.id,
    alignment: 'center',
    disableSortBy: true,
    Cell: ({
      row,
      value,
    }: CellProps<HorseInTraining, HorseInTraining['horse']['id']>) => (
      <ChangeRegistrationStatus
        authenticationMethod={authenticationMethod}
        horseId={value}
        horseIdParam={horseId}
        horseName={row.original.horse.name}
        registrationStatusChangeable={
          row.original?.registrationStatusChangeable
        }
        onSuccess={onSuccess}
      />
    ),
  },
  {
    Header: 'Ålder',
    accessor: 'age',
    alignment: 'center',
  },
  {
    Header: 'Kön',
    accessor: row => row.gender?.code,
    alignment: 'center',
  },
  {
    Header: 'HCP',
    accessor: 'handicap',
    alignment: 'center',
  },
  {
    Header: 'Vinstsumma inkl hästägarpremie',
    accessor: 'prizeMoney',
    alignment: 'right',
    thStyle: () => ({ whiteSpace: 'normal', textAlign: 'center' }),
    Cell: ({
      value,
      row,
    }: CellProps<HorseInTraining, HorseInTraining['prizeMoney']>) => (
      <>{formatNumber(value + row.original.bonus)}</>
    ),
  },
  {
    Header: 'Anmärkning',
    accessor: 'remark',
    sortType: function (rowA, rowB) {
      const baseSort = (a: string, b: string): number => {
        if (a === undefined) {
          return 1;
        }
        if (b === undefined) {
          return -1;
        }
        const A = a.toUpperCase();
        const B = b.toUpperCase();

        if (A > B) {
          return 1; //1 here for A->Z
        }
        if (B > A) {
          return -1;
        }
      };

      const a = rowA.original.remark;
      const b = rowB.original.remark;

      if (a.latestStart !== b.latestStart) {
        return baseSort(a.latestStart, b.latestStart);
      }
      if (a.vaccine !== b.vaccine) {
        return baseSort(a.vaccine, b.vaccine);
      }
      return baseSort(a.blocked, b.blocked);
    },
    Cell: ({
      value,
    }: CellProps<HorseInTraining, HorseInTraining['remark']>): JSX.Element =>
      value && (
        <List markerType="circle">
          {value?.blocked && (
            <LI markerColor="red" lineHeight="20px" fontSize="small">
              <Message as="span" variant="error">
                {value.blocked}
              </Message>
            </LI>
          )}
          {value?.vaccine && (
            <LI markerColor="red" lineHeight="20px" fontSize="small">
              <Message as="span" variant="error">
                {value.vaccine}
              </Message>
            </LI>
          )}
          {value?.latestStart && (
            <LI markerColor="dim-gray" lineHeight="20px" fontSize="small">
              <Message as="span" variant="info">
                {value.latestStart}
              </Message>
            </LI>
          )}
        </List>
      ),
  },
];

interface HorsesInTrainingResultsProps {
  user: AuthenticatedUser;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
}

const HorsesInTrainingResults = ({
  user: { licenseId },
  setSuccessMessage,
}: HorsesInTrainingResultsProps): JSX.Element => {
  const { horseId } = useNumericQueryParams();
  const { data, loading, status, error, refresh } =
    useGetTrainerHorses(licenseId);
  const authenticationMethod = useAuthenticationMethod();

  const onSuccess = useCallback(
    (message: string) => {
      setSuccessMessage(message);
      refresh();
    },
    [refresh, setSuccessMessage],
  );

  if (status && status !== 200) {
    return (
      <ErrorComponent>
        {status} {error}
      </ErrorComponent>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <>
      <ContentBlock
        slug="tranare-hastar-i-traning"
        alignment="left"
        justifyContent="flex-start"
      />
      <DataGrid
        title={
          data.length > 0
            ? `Du har ${data.length} häst${data.length > 1 ? 'ar' : ''} i
            träning:`
            : 'Fann inga ägda hästar.'
        }
        mt={2}
        columns={columns(authenticationMethod, horseId, onSuccess)}
        data={data}
        showMore
        emptyRow
        sortable
        defaultSort={[
          {
            desc: false,
            id: 'name',
          },
        ]}
      />
    </>
  );
};

interface HorsesInTrainingProps {
  user: AuthenticatedUser;
}

function HorsesInTraining(props: HorsesInTrainingProps): JSX.Element {
  const [successMessage, setSuccessMessage] = useState<string>();

  useEffect(() => {
    let timer;
    if (successMessage) {
      timer = setInterval(() => {
        setSuccessMessage(null);
      }, 5000);
    }
    return () => timer && clearInterval(timer);
  }, [successMessage]);

  return (
    <PageLayout headline="Hästar i träning" singleCard>
      {successMessage && (
        <Message variant="successBox" mb={2}>
          {successMessage}
        </Message>
      )}
      <HorsesInTrainingResults
        {...props}
        setSuccessMessage={setSuccessMessage}
      />
    </PageLayout>
  );
}

export default withUser(HorsesInTraining, {
  roles: [roleEnum.Trainer],
  showLoader: false,
  header: 'Hästar i träning',
});

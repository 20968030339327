// tslint:disable
/**
 * Web API TDS Service
 * TDS Service (trainer debit system) offers services for handling, among other things, costs and invoices for trainers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    TdsList,
    TdsListFromJSON,
    TdsListToJSON,
} from './';

/**
 * 
 * @export
 * @interface TdsListCategory
 */
export interface TdsListCategory  {
    /**
     * The TDS-list category name, human readable
     * @type {string}
     * @memberof TdsListCategory
     */
    name: string;
    /**
     * The TDS-lists of this category
     * @type {Array<TdsList>}
     * @memberof TdsListCategory
     */
    tdsLists: Array<TdsList>;
}

export function TdsListCategoryFromJSON(json: any): TdsListCategory {
    return {
        'name': json['name'],
        'tdsLists': (json['tdsLists'] as Array<any>).map(TdsListFromJSON),
    };
}

export function TdsListCategoryToJSON(value?: TdsListCategory): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'tdsLists': (value.tdsLists as Array<any>).map(TdsListToJSON),
    };
}



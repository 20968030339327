// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    HorseDetailGender,
    HorseDetailGenderFromJSON,
    HorseDetailGenderToJSON,
    Start,
    StartFromJSON,
    StartToJSON,
} from './';

/**
 * The horse being declared to start
 * @export
 * @interface HorseDetail
 */
export interface HorseDetail  {
    /**
     * Identification of the horse being declared
     * @type {number}
     * @memberof HorseDetail
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof HorseDetail
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof HorseDetail
     */
    age?: number;
    /**
     * 
     * @type {HorseDetailGender}
     * @memberof HorseDetail
     */
    gender?: HorseDetailGender;
    /**
     * 
     * @type {number}
     * @memberof HorseDetail
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof HorseDetail
     */
    currentHCP?: number;
    /**
     * 
     * @type {string}
     * @memberof HorseDetail
     */
    owner?: string;
    /**
     * 
     * @type {Start}
     * @memberof HorseDetail
     */
    previousStart?: Start;
    /**
     * 
     * @type {Start}
     * @memberof HorseDetail
     */
    comingStart?: Start;
    /**
     * Number of enrollments for this horse on the same race day
     * @type {number}
     * @memberof HorseDetail
     */
    numberOfEnrollments?: number;
}

export function HorseDetailFromJSON(json: any): HorseDetail {
    return {
        'id': json['id'],
        'name': json['name'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'gender': !exists(json, 'gender') ? undefined : HorseDetailGenderFromJSON(json['gender']),
        'points': !exists(json, 'points') ? undefined : json['points'],
        'currentHCP': !exists(json, 'currentHCP') ? undefined : json['currentHCP'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'previousStart': !exists(json, 'previousStart') ? undefined : StartFromJSON(json['previousStart']),
        'comingStart': !exists(json, 'comingStart') ? undefined : StartFromJSON(json['comingStart']),
        'numberOfEnrollments': !exists(json, 'numberOfEnrollments') ? undefined : json['numberOfEnrollments'],
    };
}

export function HorseDetailToJSON(value?: HorseDetail): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'age': value.age,
        'gender': HorseDetailGenderToJSON(value.gender),
        'points': value.points,
        'currentHCP': value.currentHCP,
        'owner': value.owner,
        'previousStart': StartToJSON(value.previousStart),
        'comingStart': StartToJSON(value.comingStart),
        'numberOfEnrollments': value.numberOfEnrollments,
    };
}



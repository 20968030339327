import { useCallback, useState } from 'react';

import { ErrorResponse, Person } from '../../generated/ownership-transfers/src';
import { request } from '../request';

const initialState = {
  loading: false,
};

interface Errors {
  errors?: {
    [key: number]: {
      id: number;
      name: string;
      personType: string;
      personalNumber: string;
    }[];
  };
}

interface State extends Errors {
  loading: boolean;
  error?: string;
  status?: number;
  errorCode?: number;
}

interface Response extends State {
  fetch: (idNumber: string) => void;
}

const useGetPersonByIdNumber = (onChange: (Person) => void): Response => {
  const [state, setState] = useState<State>(initialState);

  const fetchPerson = useCallback(
    async (idNumber: string) => {
      setState(prev => ({
        ...prev,
        error: undefined,
        errors: undefined,
        status: undefined,
        errorCode: undefined,
        loading: true,
      }));

      const resp = await request<Person & ErrorResponse & Errors>({
        url: `/ownershiptransfers/persons?idNumber=${idNumber}`,
      });

      if (resp.ok) {
        setState({
          loading: false,
          status: resp.statusCode,
          errors: resp.body?.errors,
        });
        onChange(resp.body);
      } else {
        setState({
          error: resp.body?.errorMessage || 'Oväntat fel',
          loading: false,
          status: resp.statusCode,
          errors: resp.body?.errors,
          errorCode: resp.body?.errorCode,
        });
      }
    },
    [onChange],
  );

  return {
    ...state,
    fetch: fetchPerson,
  };
};

export default useGetPersonByIdNumber;

import Card from '@components/Card';
import { base, BaseProps } from '@primitives/base';
import Box, { Flex } from '@primitives/Box';
import HR from '@primitives/HR';
import { Form } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

export interface FormLayoutProps extends BaseProps {
  variant?: 'vertical' | 'inline';
  boxScheme?: 'normal' | 'onlyDesktop' | 'borderTopAndBottom' | 'clean';
}

export const FormItem = styled.div<
  BaseProps & { noLabel?: boolean; variant?: 'normal' | 'clean' | 'dense' }
>`
  margin-top: ${props => (props.noLabel ? '17px' : 0)};
  position: relative;

  > label {
    margin-bottom: 4px;
  }

  .errorInput {
    border: 1px solid ${props => props.theme.colors.red};
  }

  &[data-no-grow] {
    flex-grow: 0;
  }

  ${variant({
    prop: 'variant',
    variants: {
      normal: {
        pr: '8px',
        pl: '8px',
        pb: '10px',
        mb: '6px',
      },
      dense: {
        pr: '8px',
        pl: '8px',
        pb: '0',
        mb: '4px',
      },
      clean: {},
    },
  })}
  ${base()}
`;

export const FormItemSeparator = (): JSX.Element => (
  <HR borderTopWidth={2} borderTopColor="gray2" mx={2} mt={0} mb={4} />
);

export const FormItemRangeIndicator = (): JSX.Element => (
  <Flex alignItems="center" justifyContent="center">
    -
  </Flex>
);

FormItem.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  variant: 'normal',
};

const boxProps = {
  px: 2,
  pt: 4,
  pb: '12px',
  bg: 'gray1',
  border: '1px solid #ede9e3',
};

// Sometimes only desktop needs the surrounding gray box.
// Because on mobile other wrapper element may already have the gray box layout.
const boxScheme = (): ReturnType<typeof variant> =>
  variant({
    prop: 'boxScheme',
    variants: {
      normal: boxProps,
      clean: {},

      onlyDesktop: {
        ...boxProps,
        px: [2, , boxProps.px],
        mx: [`-${Card.mobilePadding}`, , 0],
        //pt: [0, , boxProps.pt],
        //border: [0, null, boxProps.border],
      },
      borderTopAndBottom: {
        ...boxProps,
        borderLeftWidth: '0px',
        borderRightWidth: '0px',
      },
    },
  });

export const FormLayout = styled(({ className, children }) => (
  <Form {...{ className }}>{children}</Form>
))<FormLayoutProps>`
  ${variant({
    variants: {
      inline: {
        width: '100%',
        [FormItem]: {
          flexGrow: 1,
          flexDirection: 'row',
          alignItems: 'center',
          '> label': {
            mr: 2,
            letterSpacing: '-0.07px',
            fontSize: 'micro',
          },
          '> div:not(.errBox)': {
            // TODO
            width: '0',
            flexGrow: 1,
          },
        },
      },
      vertical: {
        alignIitems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
        '> div': {
          width: '100%',
        },
      },
    },
  })};
  position: relative;
  ${base()}
  ${boxScheme}
`;

FormLayout.defaultProps = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  boxScheme: 'normal',
};

export const FormError = styled(Box)`
  font-size: ${props => props.theme.fontSizes.micro}px;
  line-height: 1.5;
  position: absolute;
  width: 100%;
  bottom: 2px;
  left: 16px;
`;

FormError.defaultProps = {
  mt: 2,
  color: 'red',
};

export default FormLayout;

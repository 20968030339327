import CardWithHeader from '@components/CardWithHeader';
import Content from '@components/ContentBlock/Content';
import withUser from '@hoc/withUser';
import roleEnum from '@utils/roleEnum';
import React from 'react';

function RiderFaq(): JSX.Element {
  return (
    <CardWithHeader header="Vilka ryttarfunktioner finns här?">
      <Content slug="faq-ryttarfunktioner" />
    </CardWithHeader>
  );
}

export default withUser(RiderFaq, {
  roles: [roleEnum.Driver],
  showError: false,
  showLoader: false,
});

import { useGetStuds } from '@apis';
import { CoveringListStud } from '@generated/breedings/src';
import cleanObject from '@utils/cleanObject';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

interface State {
  onSuccess?: () => Promise<void> | undefined;
  studId?: number | undefined;
  year?: number | undefined;
  licenseId?: number | undefined;
  coveringId?: 'new' | number;
}

interface Context extends State {
  setYear?: (year: State['year']) => void;
  setStudId?: (studId: State['studId']) => void;
  setOnSuccess?: (stud: State['onSuccess']) => void;
}

interface Params {
  studId?: string;
  year?: string;
  coveringId?: string | 'new';
}

const Ctx = React.createContext<Context>({});

export function useCoveringContext(): Context {
  return useContext<Context>(Ctx);
}

export function useGetStud(studId: number): CoveringListStud | undefined {
  const { year, licenseId } = useCoveringContext();
  const { data: studs } = useGetStuds(licenseId, year);

  return studs?.find(stud => stud.id === studId);
}

interface BaseProps {
  licenseId: number;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function withContext(BaseComponent: FC): FC<BaseProps> {
  return ({ licenseId, ...props }: BaseProps) => {
    const { year, studId, coveringId: covId } = useParams<Params>();

    const [context, setContext] = useState<State>({
      year: parseInt(year) || undefined,
      studId: parseInt(studId) || undefined,
    });

    const coveringId = covId
      ? covId === 'new'
        ? covId
        : parseInt(covId)
      : undefined;

    useEffect(() => {
      setContext(prev => ({
        ...prev,
        coveringId,
      }));
    }, [coveringId]);

    const history = useHistory();
    const { path } = useRouteMatch();

    const setYear = useCallback(
      (year: Context['year']) => {
        setContext(prev => ({ ...prev, studId: undefined, year }));
        history.push(generatePath(path, { year: `${year}` }));
      },
      [history, path],
    );

    const setStudId = useCallback(
      (studId: Context['studId']) => {
        setContext(prev => ({ ...prev, studId }));
        history.push(
          generatePath(path, cleanObject({ year: context.year, studId })),
        );
      },
      [context.year, history, path],
    );

    const setOnSuccess = useCallback((onSuccess: Context['onSuccess']) => {
      setContext(prev => ({ ...prev, onSuccess }));
    }, []);

    return (
      <Ctx.Provider
        value={{
          ...context,
          setYear,
          setStudId,
          setOnSuccess,
          licenseId,
        }}
      >
        <BaseComponent {...props} />
      </Ctx.Provider>
    );
  };
}

import SearchForm from '@features/Search/SearchForm';
import theme from '@main/theme';
import Box, { Flex } from '@primitives/Box';
import React from 'react';

export default function NavigationSearchForm(): React.ReactElement {
  return (
    <>
      <Flex
        alignItems="center"
        position="fixed"
        left={0}
        top={theme.sizes.pageHeader}
        width={1}
        height={['searchFormMobileHeight', , 'searchFormHeight']}
        bg="white"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.16)"
        sx={{ zIndex: -1 }}
      >
        <Box maxWidth="mediumMaxWidth" width={1} mx="auto">
          <SearchForm focusOnSeachField={true} />
        </Box>
      </Flex>
    </>
  );
}

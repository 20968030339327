// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FacetItem,
    FacetItemFromJSON,
    FacetItemToJSON,
    SearchResultItem,
    SearchResultItemFromJSON,
    SearchResultItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult  {
    /**
     * 
     * @type {number}
     * @memberof SearchResult
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResult
     */
    totalContent?: number;
    /**
     * 
     * @type {Array<SearchResultItem>}
     * @memberof SearchResult
     */
    results: Array<SearchResultItem>;
    /**
     * 
     * @type {Array<FacetItem>}
     * @memberof SearchResult
     */
    facets?: Array<FacetItem>;
}

export function SearchResultFromJSON(json: any): SearchResult {
    return {
        'total': json['total'],
        'totalContent': !exists(json, 'total_content') ? undefined : json['total_content'],
        'results': (json['results'] as Array<any>).map(SearchResultItemFromJSON),
        'facets': !exists(json, 'facets') ? undefined : (json['facets'] as Array<any>).map(FacetItemFromJSON),
    };
}

export function SearchResultToJSON(value?: SearchResult): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'total': value.total,
        'total_content': value.totalContent,
        'results': (value.results as Array<any>).map(SearchResultItemToJSON),
        'facets': value.facets === undefined ? undefined : (value.facets as Array<any>).map(FacetItemToJSON),
    };
}



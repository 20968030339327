import { Marker } from '@components/Menu';
import useClickOutside from '@hooks/useClickOutside';
import useIsMobile from '@hooks/useIsMobile';
import useIsTablet from '@hooks/useIsTablet';
import useSearchIsOpen from '@hooks/useSearchIsOpen';
import { toggleSearchForm } from '@main/state/slice';
import Box, { Flex } from '@primitives/Box';
import Icon from '@primitives/Icon';
import { Text } from '@primitives/Typography';
import { MagnifyingGlass as Search } from '@styled-icons/fa-solid';
import { transparentize } from 'polished';
import React, { RefObject } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import SearchForm from './SearchForm';

interface LinkItemProps {
  setHoverItem?: (item: string | undefined) => void;
  hoverItem?: string;
}

export default function SearchItem({
  setHoverItem,
  hoverItem,
}: LinkItemProps): React.ReactElement {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const open = useSearchIsOpen();

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const containerRef = useClickOutside(outside => {
    if (outside) {
      dispatch(toggleSearchForm(false));
    }
  });

  return (
    <Flex
      height="100%"
      mr={5}
      onMouseEnter={() => setHoverItem && setHoverItem('search')}
      onMouseLeave={() => setHoverItem && setHoverItem(undefined)}
      position="relative"
      ref={containerRef as RefObject<HTMLDivElement>}
    >
      <Flex
        as="button"
        flexDirection="row"
        color={
          hoverItem === 'search' || hoverItem === undefined
            ? 'white'
            : transparentize(0.4, 'white')
        }
        onClick={() => {
          dispatch(toggleSearchForm(!open));
        }}
        disabled={pathname === '/search'}
      >
        {!isMobile && !isTablet ? <Text>Sök</Text> : null}{' '}
        <Icon
          ml={[0, , 2]}
          size={16}
          as={Search}
          sx={{ transition: 'color 0.2s' }}
        />
        {(open || pathname === '/search') && <Marker />}
      </Flex>
      {open && (
        <>
          <Box
            width={1}
            bg="rgba(0,0,0,0.6)"
            position="fixed"
            top="0"
            left="0"
            bottom="0"
            sx={{
              zIndex: -2,
            }}
            onClick={() => {
              dispatch(toggleSearchForm(false));
            }}
          />
          <SearchForm />
        </>
      )}
    </Flex>
  );
}

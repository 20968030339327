import { ArrowDownSVG } from '@primitives/Arrow';
import { Flex } from '@primitives/Box';
import { Text } from '@primitives/Typography';
import React from 'react';
import { MultiSelect, Option } from 'react-multi-select-component';
import styled from 'styled-components';

import Checkbox from './checkbox';

export const ArrowDown = styled(ArrowDownSVG)`
  color: ${props => props.theme.colors.blue};
`;

interface ItemRendererProps {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick: () => void;
}

const ItemRenderer = ({
  checked,
  option,
  onClick,
  disabled,
}: ItemRendererProps): JSX.Element => (
  <div className={`item-renderer ${disabled && 'disabled'}`}>
    <Flex alignItems="top" justifyContent="left">
      <Checkbox
        checked={checked}
        onChange={onClick}
        disabled={disabled}
        tabIndex={-1}
        mt={1}
      />
      <Text
        as="div"
        fontWeight="normal"
        fontSize={['lead', null, 'text']}
        color={disabled ? 'gray4' : 'gray6'}
      >
        {option.label}
      </Text>
    </Flex>
  </div>
);

interface CustomSelectProps {
  disabled?: boolean;
  hasSelectAll?: boolean;
  onChange?: (selectedOptions: Array<Option>) => void;
  options: Array<Option>;
  selectSomeItems?: string;
  value: Array<Option>;
  valueRenderer?: (selected: Option[], options: Option[]) => string;
}

export const CustomMultiSelect = ({
  disabled = false,
  hasSelectAll = true,
  onChange,
  options,
  selectSomeItems,
  value,
  valueRenderer,
}: CustomSelectProps): JSX.Element => (
  <MultiSelect
    valueRenderer={valueRenderer}
    ItemRenderer={ItemRenderer}
    disabled={disabled}
    disableSearch
    hasSelectAll={hasSelectAll}
    options={options}
    value={value}
    onChange={onChange}
    labelledBy="Select"
    ArrowRenderer={() => <ArrowDown size="9.5" />}
    overrideStrings={{
      selectSomeItems: selectSomeItems ? selectSomeItems : 'Välj...',
      allItemsAreSelected:
        value.length === 1 ? value[0].label : 'Alla är valda',
      selectAll: 'Alla',
      search: 'Sök',
      clearSearch: 'Rensa sökning',
    }}
  />
);

export default CustomMultiSelect;

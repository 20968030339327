import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import 'core-js/features/string/repeat';
import './index.css';

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './main/App';

if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({
    dsn: 'https://c2b98d9cc066197086e22b13450bd56e@o4505827107930112.ingest.sentry.io/4505940956413952',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          process.env.NODE_ENV === 'production'
            ? 'https://app.svenskgalopp.se'
            : 'https://app-test.svenskgalopp.se',
        ],
      }),
    ],
    environment: process.env.NODE_ENV,
    // Performance Monitoring
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0, // Capture 100% of the transactions, reduce to 10% in production
  });
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

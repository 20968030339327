import { useGetMountings, useRaceDays } from '@apis';
import {
  CalendarDay,
  DayInfo,
  dayOfWeek,
  getDays,
  getRaceInfo,
  useParamValues,
} from '@components/Calendar';
import { AuthenticatedUser } from '@generated/authenticate/src';
import withUser from '@hoc/withUser';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import getYears from '@utils/getYears';
import { numToMonth } from '@utils/numToMonth';
import React, { useCallback } from 'react';

import MountingForm from './Form';

interface RaceCalendarContentProps {
  isMobile: boolean;
  user: AuthenticatedUser;
}

function RaceCalendarContent({
  isMobile,
  user,
}: RaceCalendarContentProps): JSX.Element {
  const { year, month } = useParamValues();
  const years = getYears();

  const { data, loading, error } = useRaceDays({
    year,
    month,
  });

  const {
    data: mountings,
    loading: loading2,
    error: error2,
    refresh,
  } = useGetMountings(user.licenseId);

  const onSuccess = useCallback(() => {
    refresh();
  }, [refresh]);

  if (year && !years.includes(year)) {
    return <Error>Angivet år ({year}) existerar ej på tävlingskalendern</Error>;
  }

  if (month && (month < 1 || month > 12)) {
    return (
      <Error>Angiven måndad ({month}) existerar ej på tävlingskalendern</Error>
    );
  }

  if (error || error2) {
    return <Error>{error || error2}</Error>;
  }

  if (loading || (!mountings && loading2)) {
    return <Loading />;
  }

  const now = new Date();

  const raceDays = getRaceInfo({
    raceDays: data,
    month,
    onlyRaces: true,
  });

  const days = getDays(year, month).filter(item => raceDays[item]?.length > 0);

  return (
    <Box verticalSpacing={2}>
      {days
        .map(item => {
          const isToday =
            now.getFullYear() === year &&
            now.getMonth() === month - 1 &&
            now.getDate() === item;

          const raceDay = raceDays[item][0];

          return (
            <CalendarDay
              isMobile={isMobile}
              key={item}
              isToday={isToday}
              num={item}
              weekDay={dayOfWeek(year, month, item, !isMobile)}
              month={numToMonth(month)}
              color="white"
              opacity={1}
            >
              <Box width={1} py={[, , 4]} display={[, , 'flex']}>
                <DayInfo
                  item={raceDay}
                  mobile={isMobile}
                  type="mountingcalendar"
                />
                <Box flexGrow={1} />
                <MountingForm
                  user={user}
                  raceDayId={raceDay.raceDayId}
                  available={mountings.some(
                    item => item.raceDayId === raceDay.raceDayId,
                  )}
                  onSuccess={onSuccess}
                />
              </Box>
            </CalendarDay>
          );
        })
        .filter(Boolean)}
    </Box>
  );
}

export default withUser(RaceCalendarContent);

import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import useIsMobile from '@hooks/useIsMobile';
import roleEnum from '@utils/roleEnum';
import React from 'react';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import MyStartDeclarations from './MyStartDeclarations';
import RaceDaysList from './RaceDaysList';

interface Props {
  user: AuthenticatedUser;
}

const StartDeclarationPage = ({ user }: Props): JSX.Element => {
  const { path } = useRouteMatch();
  const isMobile = useIsMobile();
  const startDeclarationPath = generatePath(path, {
    tab: 'tavlingsdagar',
  });

  return (
    <PageLayout
      singleCard
      headline="Startanmälan"
      tabs={[
        {
          to: generatePath(path, {
            tab: 'tavlingsdagar',
          }),
          text: 'Ny startanmälan',
        },
        {
          to: generatePath(path, {
            tab: 'mina-startanmalningar',
          }),
          text: 'Startanmälda hästar',
        },
      ]}
    >
      <Switch>
        <Route path={`${path}/tavlingsdagar`}>
          <RaceDaysList user={user} />
        </Route>
        <Route path={`${path}/mina-startanmalningar`}>
          <MyStartDeclarations isMobile={isMobile} user={user} />
        </Route>
        <Route path={path} exact>
          <Redirect to={startDeclarationPath} />
        </Route>
      </Switch>
    </PageLayout>
  );
};

export default withUser(StartDeclarationPage, { roles: [roleEnum.Trainer] });

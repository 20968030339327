import { Entities } from '@apis';
import { deletePreselectedDriver } from '@generated/user/src';
import { QueryConfig } from 'redux-query';

import { MutationResponseWithoutData } from '../utils/types';
import { useApiMutateWithoutData } from '../utils/useApiMutate';

const useDeletePreSelectedDriver = (
  licenseId: number,
): MutationResponseWithoutData<Entities, number> =>
  useApiMutateWithoutData({
    queryFunc: (driverId: number): QueryConfig<Entities> => {
      const key = `${licenseId}`;
      return deletePreselectedDriver<Entities>(
        {
          licenseId,
          driverId,
        },
        {
          optimisticUpdate: {
            preSelectedDrivers: prev => ({
              ...prev,
              [key]: prev[key].filter(item => item.id !== driverId),
            }),
          },
        },
      );
    },
    queryKey: 'deletePreselectedDriver',
  });

export default useDeletePreSelectedDriver;

import { transparentize } from 'polished';
import styled from 'styled-components';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  size,
  SizeProps,
  system,
} from 'styled-system';

import { base, BaseProps } from '../base';

export interface BoxProps
  extends BaseProps,
    BorderProps,
    ShadowProps,
    SizeProps,
    PositionProps,
    BackgroundProps {
  verticalSpacing?: number | number[];
  horizontalSpacing?: number | number[];
}

const composed = base(shadow, position, border, background, size);

const Box = styled.div<BoxProps>`
  > * + * {
    ${system({
      verticalSpacing: {
        property: 'marginTop',
        scale: 'space',
      },
      horizontalSpacing: {
        property: 'marginLeft',
        scale: 'space',
      },
    })}
  }

  ${composed}
`;

const Flex = styled(Box)<BoxProps>``;

Flex.defaultProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
};

const Container = styled(Box)<
  BoxProps & {
    gap?: number | Array<number | undefined>;
    verticalGap?: number | Array<number | undefined>;
    nested?: boolean;
  }
>`
  ${system({
    gap: {
      properties: ['marginLeft', 'marginRight'],
      scale: 'space',
      transform: v => v * -1,
    },
  })}

  > *:not([data-no-default]) {
    ${system({
      gap: {
        properties: ['paddingLeft', 'paddingRight'],
        scale: 'space',
      },
    })}

    ${system({
      verticalGap: {
        properties: ['paddingTop', 'paddingBottom'],
        scale: 'space',
      },
    })}
  }
`;

Container.defaultProps = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 7.5,
  verticalGap: 7.5,
};

const Item = styled(Box)`
  > * {
    flex: 1;
  }
`;

Item.defaultProps = {
  display: 'flex',
  flexShrink: 0,
};

export const FadedBox = styled(Box)<{
  fadedBg?: string;
  fadedBottom?: number;
  fadedTop?: number;
}>`
  position: relative;
  &::before,
  &::after {
    content: '';
    z-index: 1;
    position: absolute;
    width: 100%;
    pointer-events: none;
  }

  &::before {
    top: 0;
    height: ${p => p.fadedTop}px;
    background: linear-gradient(
      to top,
      ${p => transparentize(1, p.fadedBg)} 0%,
      ${p => p.fadedBg} 100%
    );
  }

  &::after {
    bottom: 0;
    height: ${p => p.fadedBottom}px;
    background: linear-gradient(
      to top,
      ${p => p.fadedBg} 0%,
      ${p => transparentize(1, p.fadedBg)} 100%
    );
  }
`;

FadedBox.defaultProps = {
  fadedBg: '#fbfaf9',
  fadedBottom: 0,
  fadedTop: 0,
};

export { Container, Flex, Item };

export default Box;

// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    Driver,
    DriverFromJSON,
    DriverToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface DeletePreselectedDriverRequest {
    licenseId: number;
    driverId: number;
}

export interface GetPreselectedDriversRequest {
    licenseId: number;
    propositionid?: number;
}

export interface InsertPreselectedDriverRequest {
    licenseId: number;
    driver: Driver;
}


/**
 * Deletes a preselected driver/rider for a trainer.
 */
function deletePreselectedDriverRaw<T>(requestParameters: DeletePreselectedDriverRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling deletePreselectedDriver.');
    }

    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
        throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling deletePreselectedDriver.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/settings/preselecteddrivers/{licenseId}/{driverId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Deletes a preselected driver/rider for a trainer.
*/
export function deletePreselectedDriver<T>(requestParameters: DeletePreselectedDriverRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deletePreselectedDriverRaw(requestParameters, requestConfig);
}

/**
 * Fetch all preselected drivers/riders for a license holder.
 */
function getPreselectedDriversRaw<T>(requestParameters: GetPreselectedDriversRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Driver>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getPreselectedDrivers.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.propositionid !== undefined) {
        queryParameters['propositionid'] = requestParameters.propositionid;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/settings/preselecteddrivers/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(DriverFromJSON), text);
    }

    return config;
}

/**
* Fetch all preselected drivers/riders for a license holder.
*/
export function getPreselectedDrivers<T>(requestParameters: GetPreselectedDriversRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Driver>>): QueryConfig<T> {
    return getPreselectedDriversRaw(requestParameters, requestConfig);
}

/**
 * Insert a preselected driver/rider for a trainer.
 */
function insertPreselectedDriverRaw<T>(requestParameters: InsertPreselectedDriverRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling insertPreselectedDriver.');
    }

    if (requestParameters.driver === null || requestParameters.driver === undefined) {
        throw new runtime.RequiredError('driver','Required parameter requestParameters.driver was null or undefined when calling insertPreselectedDriver.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/settings/preselecteddrivers/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || DriverToJSON(requestParameters.driver),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Insert a preselected driver/rider for a trainer.
*/
export function insertPreselectedDriver<T>(requestParameters: InsertPreselectedDriverRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return insertPreselectedDriverRaw(requestParameters, requestConfig);
}


// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    StartMonitoring,
    StartMonitoringFromJSON,
    StartMonitoringToJSON,
} from '../models';

export interface DeleteStartMonitoringRequest {
    licenseId: number;
    horseId: number;
}

export interface GetStartMonitoringsRequest {
    licenseId: number;
}

export interface SaveStartMonitoringRequest {
    licenseId: number;
    horseId: number;
}


/**
 * Delete a start monitoring on a horse for the given user
 */
function deleteStartMonitoringRaw<T>(requestParameters: DeleteStartMonitoringRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling deleteStartMonitoring.');
    }

    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling deleteStartMonitoring.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/{licenseId}/startmonitoring/{horseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Delete a start monitoring on a horse for the given user
*/
export function deleteStartMonitoring<T>(requestParameters: DeleteStartMonitoringRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteStartMonitoringRaw(requestParameters, requestConfig);
}

/**
 * Get a list of start monitored horses for the given user.
 */
function getStartMonitoringsRaw<T>(requestParameters: GetStartMonitoringsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<StartMonitoring>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getStartMonitorings.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/{licenseId}/startmonitoring`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(StartMonitoringFromJSON), text);
    }

    return config;
}

/**
* Get a list of start monitored horses for the given user.
*/
export function getStartMonitorings<T>(requestParameters: GetStartMonitoringsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<StartMonitoring>>): QueryConfig<T> {
    return getStartMonitoringsRaw(requestParameters, requestConfig);
}

/**
 * Save start monitoring on a horse for the given user
 */
function saveStartMonitoringRaw<T>(requestParameters: SaveStartMonitoringRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling saveStartMonitoring.');
    }

    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling saveStartMonitoring.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/users/{licenseId}/startmonitoring/{horseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))).replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Save start monitoring on a horse for the given user
*/
export function saveStartMonitoring<T>(requestParameters: SaveStartMonitoringRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return saveStartMonitoringRaw(requestParameters, requestConfig);
}


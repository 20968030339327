import { useCancelTransfer, useGetRepresentativeHorses } from '@apis';
import Confirm from '@components/Confirm';
import { AuthenticatedUser } from '@generated/account/src';
import { OwnershipTransfer } from '@generated/ownership-transfers/src';
import { OperatorEnum, resetQuery } from '@main/state/queriesReducer';
import Box from '@primitives/Box';
import { TextArea } from '@primitives/Form';
import { Text } from '@primitives/Typography';
import { formatTz } from '@utils/dateUtils';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

interface Props {
  user: AuthenticatedUser;
  transfer: OwnershipTransfer;
  onClose: () => Promise<void>;
  isLeasing?: boolean;
}

const CancelTransferConfirm = ({
  user,
  transfer,
  onClose,
  isLeasing = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const type = isLeasing ? 'LEASING' : 'OWNERSHIP_TRANSFERS';

  const {
    fetch: cancelTransfer,
    loading: isCanceling,
    error,
  } = useCancelTransfer(user.licenseId, type);

  const { refresh } = useGetRepresentativeHorses(user.licenseId);

  const [message, setMessage] = useState('');

  const history = useHistory();

  const onCancel = useCallback(async () => {
    const { success } = await cancelTransfer({
      transferId: transfer.id,
      message,
    });

    if (success) {
      dispatch(
        resetQuery(
          `ongoingTransfers:${user.licenseId}`,
          OperatorEnum.STARTS_WITH,
        ),
      );

      // Refresh horse list
      refresh();

      // Make sure the back button doesn't take us back to this page
      if (isLeasing) {
        history.replace('/minasidor/leasing/pagaende');
      } else {
        history.replace('/minasidor/agarskiften/pagaende');
      }

      history.push(
        isLeasing
          ? '/minasidor/leasing/pagande?message=cancel'
          : '/minasidor/agarskiften/pagande?message=cancel',
      );
    }
  }, [
    cancelTransfer,
    transfer.id,
    message,
    dispatch,
    user.licenseId,
    history,
    isLeasing,
    refresh,
  ]);

  const onChange = useCallback(event => {
    const { value } = event.target;
    setMessage(value);
  }, []);

  return (
    <Confirm
      open
      closeModal={onClose}
      loading={isCanceling}
      colorScheme="primary"
      onAction={onCancel}
      action={isLeasing ? 'Avvisa leasingavtal' : 'Avvisa ägarskifte'}
      error={error}
      title={isLeasing ? 'Avvisa leasingavtal?' : 'Avvisa ägarskifte?'}
      wideButtons={true}
      wideMobileButtons={true}
      description={
        <>
          <p>
            {isLeasing
              ? 'Är du säker på att du vill avvisa leasingavtal för hästen'
              : 'Är du säker på att du vill avvisa ägarskiftet för hästen'}{' '}
            <b>{transfer.horse.horseName}</b>?
          </p>
          <p>
            <b>{isLeasing ? 'Hyrestagare' : 'Köpare'}</b>
            <br />
            {transfer.buyerRepresentative.name}
          </p>
          <p>
            <b>{isLeasing ? 'Leasingdatum' : 'Försäljningsdatum'}</b>
            <br />
            {formatTz(transfer.effectiveDate, 'yyyy-MM-dd')}
          </p>
        </>
      }
    >
      <Box bg="gray1" p={4}>
        <Text as="label" fontSize="notes" mb={1}>
          Kommentar
        </Text>
        <TextArea value={message} onChange={onChange} />
        <Text fontSize="small" fontStyle="italic">
          {isLeasing
            ? 'Anledning till att leasingavtalet avvisas'
            : 'Anledning till att ägarskiftet avvisas'}
        </Text>
      </Box>
    </Confirm>
  );
};

export default CancelTransferConfirm;

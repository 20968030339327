// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * A list of text models.
 * @export
 * @interface Text
 */
export interface Text  {
    /**
     * The type of the text.
     * @type {string}
     * @memberof Text
     */
    type: string;
    /**
     * Display value (the text to show).
     * @type {string}
     * @memberof Text
     */
    value: string;
    /**
     * The order number of the text.
     * @type {number}
     * @memberof Text
     */
    rowNumber: number;
}

export function TextFromJSON(json: any): Text {
    return {
        'type': json['type'],
        'value': json['value'],
        'rowNumber': json['rowNumber'],
    };
}

export function TextToJSON(value?: Text): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': value.type,
        'value': value.value,
        'rowNumber': value.rowNumber,
    };
}



import DataGrid from '@components/DataGrid';
import { HorseResult } from '@generated/horses/src';
import { LicenseHolderResults } from '@generated/licenseholders/src';
import { SportActorResults } from '@generated/sportactors/src';
import { horse, licenseholder } from '@utils/links';
import { thousandSeparator } from '@utils/thousandSeparator';
import Unpacked from '@utils/unpacked';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CellProps, Column, SortByFn } from 'react-table';

type Horse = {
  data: HorseResult[];
  type: 'horse';
};

type SportActor = {
  data: SportActorResults[];
  type: 'sportActor';
};

type LicenseHolder = {
  data: LicenseHolderResults[];
  type: 'driver' | 'trainer';
};

type Props = Horse | SportActor | LicenseHolder;

type NonHorse = LicenseHolderResults | SportActorResults;
type Types = LicenseHolderResults | SportActorResults | HorseResult;

type SortType = {
  sortValue: number;
};

function isSortType(data): data is SortType {
  return {}.hasOwnProperty.call(data, 'sortValue');
}

export default function Results(props: Props): JSX.Element {
  const sortHelper = useCallback(
    <T extends Types>(
      property: keyof T,
      type: 'numeric' | 'string' | 'name',
    ): SortByFn<T> =>
      ({ original: a }, { original: b }, id, desc) => {
        const va = a[property] || '';
        const vb = b[property] || '';

        const valueA = isSortType(va) ? va.sortValue : va;
        const valueB = isSortType(vb) ? vb.sortValue : vb;

        let sortValue: number;

        if (type === 'string' && !isSortType(va)) {
          sortValue = ((valueA || '') as string).localeCompare(
            (valueB || '') as string,
          );
        }

        if (type === 'name') {
          sortValue = ((valueA['name'] || '') as string).localeCompare(
            (valueB['name'] || '') as string,
          );
        }

        if (typeof sortValue === 'undefined') {
          const na = (valueA || 0) as number;
          const nb = (valueB || 0) as number;

          sortValue = na === nb ? 0 : na > nb ? 1 : -1;
        }

        if (sortValue === 0) {
          // Handle secondary sort
          const diff =
            b.raceInformation.date.getTime() - a.raceInformation.date.getTime();
          return desc ? diff * -1 : diff;
        }

        return sortValue;
      },
    [],
  );

  function getBaseColumns<Type extends Types>(): Column<Type>[] {
    let columns: Column<Type>[] = [
      {
        Header: 'Datum',
        id: 'date',
        accessor: row => row.raceInformation,
        tdStyle: () => ({ whiteSpace: 'nowrap' }),
        thStyle: () => ({ whiteSpace: 'nowrap' }),
        sortType: ({ original: a }, { original: b }) =>
          a.raceInformation.date.getTime() - b.raceInformation.date.getTime(),
        Cell: ({ row, value }: CellProps<Type, Type['raceInformation']>) => {
          const date = new Date();

          return value.linkable ? (
            <Link
              to={`/tavling/resultat/${value['raceDayId']}/${value.raceNumber}`}
            >
              {value.displayDate}
            </Link>
          ) : props.type === 'horse' &&
            date < row.original.raceInformation.date &&
            ['Bl', 'Bp', 'Gd', 'Gg', 'Jä', 'St'].includes(
              row.original.trackCode,
            ) ? (
            <Link
              to={`/tavling/startlistor/${value.raceDayId}/${value.raceNumber}`}
            >
              {value.displayDate}
            </Link>
          ) : (
            <>{value.displayDate}</>
          );
        },
      },
      {
        Header: 'Bana',
        accessor: row => row.trackCode,
        sortType: sortHelper<Type>('trackCode', 'string'),
      },
      {
        Header: 'Lopptyp',
        accessor: row => row.raceType,
        Cell: ({ value }: CellProps<Type, Type['raceType']>) => (
          <>{value.displayValue}</>
        ),
        sortType: sortHelper<Type>('raceType', 'string'),
      },
      {
        Header: 'Dist',
        accessor: row => row.distance,
        alignment: 'right',
        sortType: sortHelper<Type>('distance', 'numeric'),
        Cell: ({ value }: CellProps<Type, Type['distance']>) => (
          <>{value?.sortValue == 9999 ? '' : value?.sortValue}</>
        ),
      },
      {
        Header: 'Underlag',
        accessor: 'trackSurface',
        sortType: sortHelper<Type>('trackSurface', 'string'),
      },
      {
        Header: 'Banförh',
        accessor: row => row.trackCondition,
        Cell: ({ value }: CellProps<Type, Type['trackCondition']>) => (
          <>
            {typeof value === 'string'
              ? value
              : value?.['displayValue'] || null}
          </>
        ),
        sortType: sortHelper<Type>('trackCondition', 'string'),
      },
      {
        Header: 'Resultat',
        accessor: row => row.placement,
        Cell: ({ value }: CellProps<Type, Type['placement']>) => (
          <>{value.displayValue}</>
        ),
        tdStyle: () => ({ whiteSpace: 'normal' }),
        sortType: sortHelper<Type>('placement', 'numeric'),
      },
      {
        Header: 'Prissumma',
        accessor: row => row.prizeMoney,
        alignment: 'right',
        sortType: sortHelper<Type>('prizeMoney', 'numeric'),
        Cell: ({ value }: CellProps<Type, Type['prizeMoney']>) => (
          <>{value.displayValue}</>
        ),
      },
      {
        Header: 'Segerpris',
        accessor: row => row.winnerPrizeMoney,
        alignment: 'right',
        sortType: sortHelper<Type>('winnerPrizeMoney', 'numeric'),
        Cell: ({ value }: CellProps<Type, Type['winnerPrizeMoney']>) => (
          <>
            {typeof value === 'number'
              ? thousandSeparator(value)
              : thousandSeparator(value['displayValue'])}
          </>
        ),
      },
      {
        Header: 'Vikt',
        accessor: row => row.weight,
        alignment: 'right',
        sortType: sortHelper<Type>('weight', 'string'),
        Cell: ({ value }: CellProps<Type, Type['weight']>) => (
          <>
            {typeof value === 'string'
              ? value
              : value?.['displayValue'] || null}
          </>
        ),
      },
      {
        Header: 'HCP',
        accessor: row => row.riderHandicap,
        alignment: 'center',
        Cell: ({ value }: CellProps<Type, Type['riderHandicap']>) => (
          <>{value}</>
        ),
        sortType: sortHelper<Type>('riderHandicap', 'numeric'),
      },
    ];

    if (props.type === 'sportActor') {
      columns = columns.filter(item => item.Header !== 'Banförh');
    }

    return columns;
  }

  function getOddsColumn<Type extends Types>(): Column<Type> {
    return {
      Header: 'Odds',
      accessor: row => row.odds,
      alignment: 'right',
      sortType: sortHelper<Type>('odds', 'numeric'),
      Cell: ({ value }: CellProps<Type, Types['odds']>) => (
        <>{value['displayValue']}</>
      ),
    };
  }

  function getHorseColumn<Type extends NonHorse>(): Column<Type> {
    return {
      Header: 'Häst',
      accessor: row => row.horse,
      Cell: ({ value }: CellProps<Type, Type['horse']>) => (
        <Link to={horse(value.id, '/resultat')}>{value.name}</Link>
      ),
      tdStyle: () => ({ whiteSpace: 'normal' }),
      sortType: sortHelper<Type>('horse', 'name'),
    };
  }

  function getStartPositionColumn<
    Type extends SportActorResults | HorseResult,
  >(): Column<Type> {
    return {
      Header: 'Spår',
      accessor: row => row.startPosition,
      alignment: 'right',
      Cell: ({ value }: CellProps<Type, Type['startPosition']>) => (
        <>{value.displayValue}</>
      ),
      sortType: sortHelper<Type>('startPosition', 'numeric'),
    };
  }

  function getRiderColumn<Type extends LicenseHolderResults>(): Column<Type> {
    return {
      Header: 'Ryttare',
      accessor: row => row.rider,
      Cell: ({ value }: CellProps<Type, Type['rider']>) => (
        <>
          {value?.linkable ? (
            <Link to={licenseholder(value?.id, '/ryttarstatistik')}>
              {value.name}
            </Link>
          ) : (
            value?.name || null
          )}
        </>
      ),
      sortType: sortHelper<Type>('rider', 'name'),
    };
  }

  function getTrainerColumn<Type extends Types>(): Column<Type> {
    return {
      Header: 'Tränare',
      accessor: row => row.trainer,
      Cell: ({ value }: CellProps<Type, Type['trainer']>) => (
        <>
          {value?.linkable ? (
            <Link to={licenseholder(value?.id, '/tranarstatistik')}>
              {value.name}
            </Link>
          ) : (
            value?.name || null
          )}
        </>
      ),
      sortType: sortHelper<Type>('trainer', 'name'),
    };
  }

  function getDriverColumn<
    Type extends HorseResult | SportActorResults,
  >(): Column<Type> {
    return {
      Header: 'Ryttare',
      accessor: row => row.driver,
      Cell: ({ value }: CellProps<Type, Type['driver']>) => (
        <>
          {value?.linkable ? (
            <Link to={licenseholder(value?.id, '/ryttarstatistik')}>
              {value.name}
            </Link>
          ) : (
            value?.name || null
          )}
        </>
      ),
      sortType: sortHelper<Type>('driver', 'name'),
    };
  }

  const { data } = props;
  type Type = Unpacked<typeof props.data>;

  let columns;

  const isNotEmpty = data.some(a => a.odds.displayValue !== '');

  if (props.type === 'driver') {
    type Type = Unpacked<typeof props.data>;
    columns = [
      ...getBaseColumns<Type>(),
      getTrainerColumn<Type>(),
      getHorseColumn<Type>(),
    ];
  }

  if (props.type === 'trainer') {
    type Type = Unpacked<typeof props.data>;
    columns = [
      ...getBaseColumns<Type>(),
      getRiderColumn<Type>(),
      getHorseColumn<Type>(),
    ];
  }

  if (props.type === 'sportActor') {
    type Type = Unpacked<typeof props.data>;

    const baseCols = getBaseColumns<Type>();

    columns = [
      ...baseCols.slice(0, 2),
      getStartPositionColumn<Type>(),
      ...baseCols.slice(2),
      getDriverColumn<Type>(),
      getTrainerColumn<Type>(),
      getHorseColumn<Type>(),
    ];
  }

  if (props.type === 'horse') {
    type Type = Unpacked<typeof props.data>;

    const baseCols = getBaseColumns<Type>();

    columns = [
      ...baseCols.slice(0, 2),
      getStartPositionColumn<Type>(),
      ...baseCols.slice(2),
      getDriverColumn<Type>(),
      getTrainerColumn<Type>(),
    ];
  }

  if (isNotEmpty && props.type === 'horse') {
    columns.push(getOddsColumn<Type>());
  }

  return (
    <DataGrid<Type>
      data={data}
      sortable
      condensed
      showMore={true}
      columns={columns}
      defaultSort={[{ id: 'date', desc: true }]}
      emptyRow
    />
  );
}

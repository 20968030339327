import styled from 'styled-components';

import { base, BaseProps } from '../base';

export { default as HorseIcon } from './HorseIcon';
export { default as RiderIcon } from './RiderIcon';

const Icon = styled.i<BaseProps>`
  ${base()};
  vertical-align: middle;
  transform-origin: center center;
`;

export default Icon;

import 'styled-components';

import { useGetStakeRaceWithStakes } from '@apis';
import { PageLayout } from '@components/PageLayouts';
import useUser from '@hooks/useUser';
import Box, { Container, Item } from '@primitives/Box';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import { Lead } from '@primitives/Typography';
import isTrainer from '@utils/isTrainer';
import React from 'react';
import { generatePath, useLocation, useRouteMatch } from 'react-router-dom';

import InfoTexts from '../InfoTexts';
import StakeInfo from '../StakeInfo';
import EnrollForm from './EnrollForm';

interface Props {
  propositionId: number;
  accountPage: boolean;
}

export default function UpcomingStakeRacesForm({
  propositionId,
  accountPage,
}: Props): JSX.Element {
  const { data, loading, error, status } =
    useGetStakeRaceWithStakes(propositionId);

  const user = useUser();
  const { path } = useRouteMatch();
  const { search } = useLocation();

  if (loading) {
    return <Loading />;
  }

  if (status && status !== 200) {
    return <Error>{error}</Error>;
  }

  const header = data.infoTexts.find(item => item.header);

  return (
    <PageLayout
      headline={'Anmäl till insatslopp'}
      singleCard
      backLink={{
        to: generatePath(path) + search,
      }}
    >
      <Box my={[-4, , 0]} py={[4, , 0]}>
        {header && (
          <Lead mb={[0, , 2]} mt={[4, , 0]} fontWeight="bold">
            {header.text}
          </Lead>
        )}
        <Container gap={accountPage ? 7.5 : 32}>
          {accountPage && user && isTrainer(user) ? (
            <>
              <Item width={[1, null, 0.6]} display="block">
                <EnrollForm user={user} propositionId={propositionId} />
              </Item>
              <Item width={[1, null, 0.4]} display="block">
                <Box verticalSpacing={3} mt={3}>
                  <StakeInfo stakes={data.stakes} />
                  <InfoTexts infoTexts={data.infoTexts} />
                </Box>
              </Item>
            </>
          ) : (
            <>
              <Item width={[1, null, 1 / 2]} display="block">
                <StakeInfo stakes={data.stakes} />
              </Item>
              <Item width={[1, null, 1 / 2]} display="block">
                <InfoTexts infoTexts={data.infoTexts} />
              </Item>
            </>
          )}
        </Container>
      </Box>
    </PageLayout>
  );
}

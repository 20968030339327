import theme from '@main/theme';
import Icon from '@primitives/Icon';
import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { StyledIcon } from 'styled-icons/types';

interface Props {
  icon?: StyledIcon | ((size: string, color: string) => JSX.Element);
  image?: string;
  gray?: boolean;
  size?: number;
}

interface ImageCircleProps {
  image: string;
  circleInnerDiameter: number;
  circleBorderWidth: number;
}

const ImageCircle = styled.div<ImageCircleProps>`
  border-radius: 50%;
  width: ${props => props.circleInnerDiameter + props.circleBorderWidth}px;
  height: ${props => props.circleInnerDiameter + props.circleBorderWidth}px;
  background-image: url('${props => props.image}');
  background-size: cover;
  background-position: center;
`;

const IconCircle = styled.div<{
  gray: boolean;
  circleInnerDiameter: number;
  circleBorderWidth: number;
}>`
  border-radius: 50%;
  width: ${props => props.circleInnerDiameter}px;
  height: ${props => props.circleInnerDiameter}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.theme.colors[props.gray ? 'gray3' : 'light-blue']};
  box-shadow: 0 0 0 ${props => props.circleBorderWidth}px
    ${props =>
      transparentize(
        0.5,
        props.theme.colors[props.gray ? 'gray3' : 'light-blue'],
      )};
  margin: ${props => props.circleBorderWidth}px;
`;

export default function RoundIcon({
  icon,
  image,
  gray = false,
  size = 32,
}: Props): React.ReactElement {
  const circleInnerDiameter = size * 2 + 6;
  const circleBorderWidth = size / 4;

  return (
    <>
      {image ? (
        <ImageCircle
          image={image}
          circleBorderWidth={circleBorderWidth}
          circleInnerDiameter={circleInnerDiameter}
        />
      ) : (
        icon && (
          <IconCircle
            gray={gray}
            circleBorderWidth={circleBorderWidth}
            circleInnerDiameter={circleInnerDiameter}
          >
            {typeof icon == 'object' ? (
              <Icon
                size={size}
                color={gray ? 'gray4' : 'deep-blue'}
                as={icon}
              />
            ) : (
              icon('32px', theme.colors[gray ? 'gray4' : 'deep-blue'])
            )}
          </IconCircle>
        )
      )}
    </>
  );
}

import FormLayout, { FormItem } from '@components/FormLayout';
import { SearchSportInfoTypeEnum } from '@generated/content/src';
import useQuery from '@hooks/useQuery';
import { toggleSearchForm } from '@main/state/slice';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import { Option, Select } from '@primitives/Form';
import qs from 'qs';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SearchField, { typeMap } from './SearchField';

interface FormState {
  query: string;
  type?: SearchSportInfoTypeEnum;
}

interface Props {
  isSearchPage?: boolean;
  focusOnSeachField?: boolean;
}

export default function SearchForm({
  isSearchPage = false,
  focusOnSeachField,
}: Props): JSX.Element {
  const params = useQuery<Partial<FormState>>(true);

  const [state, setState] = useState<FormState>({
    query: '',
    ...params,
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const handleSearch = useCallback(() => {
    dispatch(toggleSearchForm(false));
    history.push({
      pathname: '/search',
      search: qs.stringify({ query: state.query, type: state.type, page: 1 }),
    });
  }, [dispatch, history, state]);

  return (
    <FormLayout
      onSubmit={e => {
        e.preventDefault();
        handleSearch();
      }}
      boxScheme={isSearchPage ? 'onlyDesktop' : 'clean'}
      as="form"
    >
      <FormItem width={[1, , 1 / 3]} variant="dense">
        <SearchField
          focusOnSeachField={focusOnSeachField}
          onChange={val => {
            setState(prev => ({ ...prev, query: val }));
          }}
          value={state.query}
          type={state.type}
        />
      </FormItem>
      <FormItem width={[1, , '150px']} variant="dense" data-no-grow>
        <Box mt={[2, , 0]}>
          <Select
            name="type"
            value={state.type}
            onChange={e => {
              setState(prev => ({
                ...prev,
                type: (e.target.value as FormState['type']) || undefined,
              }));
            }}
          >
            <Option value="">Allt innehåll</Option>
            {Object.keys(typeMap).map(key => (
              <Option key={key} value={key}>
                {typeMap[key]}
              </Option>
            ))}
          </Select>
        </Box>
      </FormItem>
      <FormItem width={[1, , 'auto']} data-no-grow variant="dense">
        <Button
          onClick={handleSearch}
          type="button"
          width={[1, , 'auto']}
          mt={[2, , 0]}
        >
          Sök
        </Button>
      </FormItem>
    </FormLayout>
  );
}

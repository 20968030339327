// tslint:disable
/**
 * Web API Account Service
 * Service for perform account operations, such as creating accounts or recovering accounts.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface BankIdUserAccount
 */
export interface BankIdUserAccount  {
    /**
     * Swedish id number (personnummer)
     * @type {string}
     * @memberof BankIdUserAccount
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BankIdUserAccount
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BankIdUserAccount
     */
    mobilePhone?: string;
    /**
     * The user ip address
     * @type {string}
     * @memberof BankIdUserAccount
     */
    endUserIp?: string;
    /**
     * A set of certificate policies applied to the requested operation
     * @type {Array<string>}
     * @memberof BankIdUserAccount
     */
    certificatePolicies?: Array<BankIdUserAccountCertificatePoliciesEnum>;
}

export function BankIdUserAccountFromJSON(json: any): BankIdUserAccount {
    return {
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'email': json['email'],
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'endUserIp': !exists(json, 'endUserIp') ? undefined : json['endUserIp'],
        'certificatePolicies': !exists(json, 'certificatePolicies') ? undefined : json['certificatePolicies'],
    };
}

export function BankIdUserAccountToJSON(value?: BankIdUserAccount): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'idNumber': value.idNumber,
        'email': value.email,
        'mobilePhone': value.mobilePhone,
        'endUserIp': value.endUserIp,
        'certificatePolicies': value.certificatePolicies,
    };
}

/**
* @export
* @enum {string}
*/
export enum BankIdUserAccountCertificatePoliciesEnum {
    MOBILE_BANKID = 'MOBILE_BANKID',
    BANKID_ON_FILE = 'BANKID_ON_FILE',
    BANK_ID_ON_SMART_CARD = 'BANK_ID_ON_SMART_CARD',
    NORDEA_E_ID = 'NORDEA_E_ID'
}



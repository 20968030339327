import { useGetPersonalInformation } from '@apis';
import Content from '@components/ContentBlock/Content';
import { AuthenticatedUser } from '@generated/account/src';
import theme from '@main/theme';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import hasRole from '@utils/hasRole';
import roleEnum from '@utils/roleEnum';
import React from 'react';

import Form from './Form';
import FormWithLicensee from './FormWithLicensee';
import InformationEntry from './InformationEntry';
import LicenseInfo from './LicenseInfo';
import { translate } from './util';

interface Props {
  user: AuthenticatedUser;
}

export default function Personal({ user }: Props): JSX.Element | null {
  const { data, loading, error, status } = useGetPersonalInformation(
    user.licenseId,
  );

  if (loading) {
    return <Loading mt={5} mb={5} />;
  }

  if (status && status != 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  if (!data) {
    return null;
  }

  const { basicUserInformation, changeableUserInformation } = data;

  const { firstName, lastName } = basicUserInformation;

  return (
    <>
      <Content
        slug="installningar-kontaktuppgifter"
        as="div"
        mb={4}
        p={4}
        border={`solid 1px ${theme.colors.gray2}`}
        maxWidth="580px"
        bg="gray1"
      />
      <Box mb={4} width={[1, , 1 / 2]}>
        <InformationEntry label="Namn" value={`${firstName} ${lastName}`} />
        {basicUserInformation &&
          Object.entries(basicUserInformation)
            .filter(([, value]) => Boolean(value))
            .map(([key, value]) => translate(key, value))}

        {hasRole(user, roleEnum.Licensee) && (
          <Box mt={6}>
            <LicenseInfo user={user} />
          </Box>
        )}
      </Box>

      {hasRole(user, roleEnum.Licensee) ? (
        <FormWithLicensee
          initialValues={changeableUserInformation}
          licenseId={user.licenseId}
          //changeSmsNumber={user?.userInfo?.changeSmsNumber || false}
          changeSmsNumber={true}
          user={user}
        />
      ) : (
        <Form
          initialValues={changeableUserInformation}
          licenseId={user.licenseId}
          //changeSmsNumber={user?.userInfo?.changeSmsNumber || false}
          changeSmsNumber={true}
          user={user}
        />
      )}
    </>
  );
}

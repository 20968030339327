// tslint:disable
/**
 * Web API Sport Actor Service
 * Actors in the Sport system that are not License Holders, e.g. Breeders & Owners.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Representative of the ownership
 * @export
 * @interface Representative
 */
export interface Representative  {
    /**
     * Representative id
     * @type {number}
     * @memberof Representative
     */
    id?: number;
    /**
     * Representative name
     * @type {string}
     * @memberof Representative
     */
    name?: string;
    /**
     * Representative is a company or not
     * @type {boolean}
     * @memberof Representative
     */
    company?: boolean;
}

export function RepresentativeFromJSON(json: any): Representative {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'company': !exists(json, 'company') ? undefined : json['company'],
    };
}

export function RepresentativeToJSON(value?: Representative): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'company': value.company,
    };
}



import { base, BaseProps } from '@primitives/base';
import React from 'react';
import styled from 'styled-components';

const Button = styled.button.attrs({ type: 'button' })<BaseProps>`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  margin-left: 8px;
  margin-right: 8px;
  ${base()}
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid gray;
  position: absolute;
  top: 16;
  left: 0;
`;

const Text = styled.div`
  position: absolute;
  top: 8px;
  left: 50%;
  padding-left: 10px;
  padding-right: 10px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
  color: ${props => props.theme.colors.blue};
  background-color: ${props => props.theme.colors.gray1};
`;

interface Props {
  active: boolean;
  activateText: string;
  inactivateText: string;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FormToggle({
  active,
  activateText,
  inactivateText,
  setActive,
}: Props): JSX.Element {
  return (
    <Button onClick={() => setActive(!active)} display={[, , 'none']}>
      <Line />
      <Text>{active ? inactivateText : activateText}</Text>
    </Button>
  );
}

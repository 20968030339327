// tslint:disable
/**
 * Web API Auth Service
 * Service for supporting user authentication (issuing JWTs) for accessing secured resources.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    BankIdCollectedAuthentication,
    BankIdCollectedAuthenticationFromJSON,
    BankIdCollectedAuthenticationToJSON,
    BankIdUserAuthentication,
    BankIdUserAuthenticationFromJSON,
    BankIdUserAuthenticationToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    UserIdentification,
    UserIdentificationFromJSON,
    UserIdentificationToJSON,
} from '../models';

export interface CancelRequest {
    orderReference: string;
}

export interface CollectRequest {
    orderReference: string;
    useCookie?: boolean;
}

export interface CollectWithAnimatedQrCodeRequest {
    orderReference: string;
    qrTime: number;
    useCookie?: boolean;
}

export interface InitializeRequest {
    userIdentification: UserIdentification;
}


/**
 * Cancels an ongoing Bank ID authentication. Fails if there is no existing authentication with {orderreference}.
 * Cancel an ongoing BankID authentication
 */
function cancelRaw<T>(requestParameters: CancelRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.orderReference === null || requestParameters.orderReference === undefined) {
        throw new runtime.RequiredError('orderReference','Required parameter requestParameters.orderReference was null or undefined when calling cancel.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/authenticate/bankid/cancel/{orderReference}`.replace(`{${"orderReference"}}`, encodeURIComponent(String(requestParameters.orderReference))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Cancels an ongoing Bank ID authentication. Fails if there is no existing authentication with {orderreference}.
* Cancel an ongoing BankID authentication
*/
export function cancel<T>(requestParameters: CancelRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return cancelRaw(requestParameters, requestConfig);
}

/**
 * Collects the BankId order for the given {orderreference}.
 * Collect an ongoing BankID authentication
 */
function collectRaw<T>(requestParameters: CollectRequest, requestConfig: runtime.TypedQueryConfig<T, BankIdCollectedAuthentication> = {}): QueryConfig<T> {
    if (requestParameters.orderReference === null || requestParameters.orderReference === undefined) {
        throw new runtime.RequiredError('orderReference','Required parameter requestParameters.orderReference was null or undefined when calling collect.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    if (requestParameters.useCookie !== undefined && requestParameters.useCookie !== null) {
        headerParameters['Use-Cookie'] = String(requestParameters.useCookie);
    }


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/authenticate/bankid/collect/{orderReference}`.replace(`{${"orderReference"}}`, encodeURIComponent(String(requestParameters.orderReference))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(BankIdCollectedAuthenticationFromJSON(body), text);
    }

    return config;
}

/**
* Collects the BankId order for the given {orderreference}.
* Collect an ongoing BankID authentication
*/
export function collect<T>(requestParameters: CollectRequest, requestConfig?: runtime.TypedQueryConfig<T, BankIdCollectedAuthentication>): QueryConfig<T> {
    return collectRaw(requestParameters, requestConfig);
}

/**
 * Collects the BankId order for the given {orderreference} and {qrTime}.
 * Collect an ongoing BankID authentication
 */
function collectWithAnimatedQrCodeRaw<T>(requestParameters: CollectWithAnimatedQrCodeRequest, requestConfig: runtime.TypedQueryConfig<T, BankIdCollectedAuthentication> = {}): QueryConfig<T> {
    if (requestParameters.orderReference === null || requestParameters.orderReference === undefined) {
        throw new runtime.RequiredError('orderReference','Required parameter requestParameters.orderReference was null or undefined when calling collectWithAnimatedQrCode.');
    }

    if (requestParameters.qrTime === null || requestParameters.qrTime === undefined) {
        throw new runtime.RequiredError('qrTime','Required parameter requestParameters.qrTime was null or undefined when calling collectWithAnimatedQrCode.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    if (requestParameters.useCookie !== undefined && requestParameters.useCookie !== null) {
        headerParameters['Use-Cookie'] = String(requestParameters.useCookie);
    }


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/authenticate/bankid/collect/{orderReference}/{qrTime}`.replace(`{${"orderReference"}}`, encodeURIComponent(String(requestParameters.orderReference))).replace(`{${"qrTime"}}`, encodeURIComponent(String(requestParameters.qrTime))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(BankIdCollectedAuthenticationFromJSON(body), text);
    }

    return config;
}

/**
* Collects the BankId order for the given {orderreference} and {qrTime}.
* Collect an ongoing BankID authentication
*/
export function collectWithAnimatedQrCode<T>(requestParameters: CollectWithAnimatedQrCodeRequest, requestConfig?: runtime.TypedQueryConfig<T, BankIdCollectedAuthentication>): QueryConfig<T> {
    return collectWithAnimatedQrCodeRaw(requestParameters, requestConfig);
}

/**
 * The {idnumber}, part of the request payload, is optional in the scenario when the BankID App is on the same device as the host App/Webpage but required otherwise.
 * Initialize a BankID authentication
 */
function initializeRaw<T>(requestParameters: InitializeRequest, requestConfig: runtime.TypedQueryConfig<T, BankIdUserAuthentication> = {}): QueryConfig<T> {
    if (requestParameters.userIdentification === null || requestParameters.userIdentification === undefined) {
        throw new runtime.RequiredError('userIdentification','Required parameter requestParameters.userIdentification was null or undefined when calling initialize.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/authenticate/bankid/initialize`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || UserIdentificationToJSON(requestParameters.userIdentification),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(BankIdUserAuthenticationFromJSON(body), text);
    }

    return config;
}

/**
* The {idnumber}, part of the request payload, is optional in the scenario when the BankID App is on the same device as the host App/Webpage but required otherwise.
* Initialize a BankID authentication
*/
export function initialize<T>(requestParameters: InitializeRequest, requestConfig?: runtime.TypedQueryConfig<T, BankIdUserAuthentication>): QueryConfig<T> {
    return initializeRaw(requestParameters, requestConfig);
}


// tslint:disable
/**
 * Web API RaceInfo Service
 * Service for fetching all horse competitions
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    DopingTestedHorse,
    DopingTestedHorseFromJSON,
    DopingTestedHorseToJSON,
    LinkableHorseWithProgramNumberAndCause,
    LinkableHorseWithProgramNumberAndCauseFromJSON,
    LinkableHorseWithProgramNumberAndCauseToJSON,
    Penalty,
    PenaltyFromJSON,
    PenaltyToJSON,
    PropositionDetailRow,
    PropositionDetailRowFromJSON,
    PropositionDetailRowToJSON,
    RaceResultRow,
    RaceResultRowFromJSON,
    RaceResultRowToJSON,
    WinnerHorse,
    WinnerHorseFromJSON,
    WinnerHorseToJSON,
} from './';

/**
 * Races with results ready, might be empty
 * @export
 * @interface RaceResultGeneralInfo
 */
export interface RaceResultGeneralInfo  {
    /**
     * 
     * @type {number}
     * @memberof RaceResultGeneralInfo
     */
    raceId: number;
    /**
     * 
     * @type {string}
     * @memberof RaceResultGeneralInfo
     */
    heading: string;
    /**
     * 
     * @type {string}
     * @memberof RaceResultGeneralInfo
     */
    trackConditions: string;
    /**
     * 
     * @type {string}
     * @memberof RaceResultGeneralInfo
     */
    winningMargin: string;
    /**
     * Different kind of info before/after 2019-01-01
     * @type {string}
     * @memberof RaceResultGeneralInfo
     */
    startTime?: string;
    /**
     * Different kind of info before/after 2019-01-01
     * @type {string}
     * @memberof RaceResultGeneralInfo
     */
    turnoverVinnare?: string;
    /**
     * Different kind of info before/after 2019-01-01
     * @type {string}
     * @memberof RaceResultGeneralInfo
     */
    turnoverPlats?: string;
    /**
     * Different kind of info before/after 2019-01-01
     * @type {string}
     * @memberof RaceResultGeneralInfo
     */
    resultAndOddsTrio?: string;
    /**
     * Different kind of info before/after 2019-01-01
     * @type {string}
     * @memberof RaceResultGeneralInfo
     */
    turnoverTrio?: string;
    /**
     * Different kind of info before/after 2019-01-01
     * @type {string}
     * @memberof RaceResultGeneralInfo
     */
    oddsKomb?: string;
    /**
     * Different kind of info before/after 2019-01-01
     * @type {string}
     * @memberof RaceResultGeneralInfo
     */
    omsKomb?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RaceResultGeneralInfo
     */
    infoTextRows: Array<string>;
    /**
     * Different kind of info before/after 2019-01-01: before = bet types, after = race standard (e.g. \"***STL, Harper Hanovers Lopp - STL-finaler\"
     * @type {Array<string>}
     * @memberof RaceResultGeneralInfo
     */
    raceDayInfo?: Array<string>;
    /**
     * 
     * @type {Array<RaceResultRow>}
     * @memberof RaceResultGeneralInfo
     */
    raceResultRows: Array<RaceResultRow>;
    /**
     * 
     * @type {Array<LinkableHorseWithProgramNumberAndCause>}
     * @memberof RaceResultGeneralInfo
     */
    withdrawnHorses?: Array<LinkableHorseWithProgramNumberAndCause>;
    /**
     * 
     * @type {Array<LinkableHorseWithProgramNumberAndCause>}
     * @memberof RaceResultGeneralInfo
     */
    prohibitedHorses?: Array<LinkableHorseWithProgramNumberAndCause>;
    /**
     * 
     * @type {Array<PropositionDetailRow>}
     * @memberof RaceResultGeneralInfo
     */
    propositionDetailRows?: Array<PropositionDetailRow>;
    /**
     * No winner in qualification or prize races
     * @type {Array<WinnerHorse>}
     * @memberof RaceResultGeneralInfo
     */
    winnerHorses?: Array<WinnerHorse>;
    /**
     * 
     * @type {Array<Penalty>}
     * @memberof RaceResultGeneralInfo
     */
    penalties?: Array<Penalty>;
    /**
     * 
     * @type {Array<DopingTestedHorse>}
     * @memberof RaceResultGeneralInfo
     */
    dopingTestedHorses?: Array<DopingTestedHorse>;
}

export function RaceResultGeneralInfoFromJSON(json: any): RaceResultGeneralInfo {
    return {
        'raceId': json['raceId'],
        'heading': json['heading'],
        'trackConditions': json['trackConditions'],
        'winningMargin': json['winningMargin'],
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'turnoverVinnare': !exists(json, 'turnoverVinnare') ? undefined : json['turnoverVinnare'],
        'turnoverPlats': !exists(json, 'turnoverPlats') ? undefined : json['turnoverPlats'],
        'resultAndOddsTrio': !exists(json, 'resultAndOddsTrio') ? undefined : json['resultAndOddsTrio'],
        'turnoverTrio': !exists(json, 'turnoverTrio') ? undefined : json['turnoverTrio'],
        'oddsKomb': !exists(json, 'oddsKomb') ? undefined : json['oddsKomb'],
        'omsKomb': !exists(json, 'omsKomb') ? undefined : json['omsKomb'],
        'infoTextRows': json['infoTextRows'],
        'raceDayInfo': !exists(json, 'raceDayInfo') ? undefined : json['raceDayInfo'],
        'raceResultRows': (json['raceResultRows'] as Array<any>).map(RaceResultRowFromJSON),
        'withdrawnHorses': !exists(json, 'withdrawnHorses') ? undefined : (json['withdrawnHorses'] as Array<any>).map(LinkableHorseWithProgramNumberAndCauseFromJSON),
        'prohibitedHorses': !exists(json, 'prohibitedHorses') ? undefined : (json['prohibitedHorses'] as Array<any>).map(LinkableHorseWithProgramNumberAndCauseFromJSON),
        'propositionDetailRows': !exists(json, 'propositionDetailRows') ? undefined : (json['propositionDetailRows'] as Array<any>).map(PropositionDetailRowFromJSON),
        'winnerHorses': !exists(json, 'winnerHorses') ? undefined : (json['winnerHorses'] as Array<any>).map(WinnerHorseFromJSON),
        'penalties': !exists(json, 'penalties') ? undefined : (json['penalties'] as Array<any>).map(PenaltyFromJSON),
        'dopingTestedHorses': !exists(json, 'dopingTestedHorses') ? undefined : (json['dopingTestedHorses'] as Array<any>).map(DopingTestedHorseFromJSON),
    };
}

export function RaceResultGeneralInfoToJSON(value?: RaceResultGeneralInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'raceId': value.raceId,
        'heading': value.heading,
        'trackConditions': value.trackConditions,
        'winningMargin': value.winningMargin,
        'startTime': value.startTime,
        'turnoverVinnare': value.turnoverVinnare,
        'turnoverPlats': value.turnoverPlats,
        'resultAndOddsTrio': value.resultAndOddsTrio,
        'turnoverTrio': value.turnoverTrio,
        'oddsKomb': value.oddsKomb,
        'omsKomb': value.omsKomb,
        'infoTextRows': value.infoTextRows,
        'raceDayInfo': value.raceDayInfo,
        'raceResultRows': (value.raceResultRows as Array<any>).map(RaceResultRowToJSON),
        'withdrawnHorses': value.withdrawnHorses === undefined ? undefined : (value.withdrawnHorses as Array<any>).map(LinkableHorseWithProgramNumberAndCauseToJSON),
        'prohibitedHorses': value.prohibitedHorses === undefined ? undefined : (value.prohibitedHorses as Array<any>).map(LinkableHorseWithProgramNumberAndCauseToJSON),
        'propositionDetailRows': value.propositionDetailRows === undefined ? undefined : (value.propositionDetailRows as Array<any>).map(PropositionDetailRowToJSON),
        'winnerHorses': value.winnerHorses === undefined ? undefined : (value.winnerHorses as Array<any>).map(WinnerHorseToJSON),
        'penalties': value.penalties === undefined ? undefined : (value.penalties as Array<any>).map(PenaltyToJSON),
        'dopingTestedHorses': value.dopingTestedHorses === undefined ? undefined : (value.dopingTestedHorses as Array<any>).map(DopingTestedHorseToJSON),
    };
}



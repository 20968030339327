import {
  useEmailEnrollmentReceipt,
  useStartDeclaredDetailEmailReceipt,
} from '@apis';
import Box, { Flex } from '@primitives/Box';
import ButtonLink from '@primitives/Button/Link';
import Error from '@primitives/Error';
import Icon from '@primitives/Icon';
import { H3 } from '@primitives/Typography';
import { CircleCheck as CheckCircle } from '@styled-icons/fa-solid';
import { Print } from '@styled-icons/fa-solid';
import React, { ReactNode, useState } from 'react';

import EmailReceipt from './EmailReceipt';

interface Props {
  children: ReactNode;
  horseId?: number;
  variant: 'enrollHorseToProposition' | 'startDeclaration';
  overlay?: boolean;
  raceDayId?: number;
  licenseId?: number;
  propositionId?: number;
  actions?: ReactNode[];
}

export default function ReceiptWrapper({
  children,
  horseId,
  raceDayId,
  licenseId,
  propositionId,
  variant,
  overlay,
  actions,
}: Props): JSX.Element {
  const [error, setError] = useState<string>('');

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        height="52px"
        borderBottomColor="gray2"
        borderBottomStyle="solid"
        borderBottomWidth="1px"
        px={2}
        // bg="white"
        // position="sticky"
        // top="0"
      >
        <H3 display="flex" alignItems="center">
          <Icon size={16} color="green" as={CheckCircle} mr={1} mb="-1px" />
          Kvittens
        </H3>
        <Box horizontalSpacing={2} display={['none', , 'block']}>
          {actions}
        </Box>
      </Flex>
      <Box p={4} m={2} backgroundColor="gray1">
        <Box maxWidth="500px" mx="auto">
          <Box
            backgroundColor="white"
            borderStyle="solid"
            borderWidth={1}
            borderColor="gray3"
            padding={[4, 0, overlay ? '20px' : '60px']}
          >
            {error && (
              <Error className="hidden-print" mb={4}>
                {error}
              </Error>
            )}
            {children}
          </Box>
          <Flex
            mt={4}
            className="hidden-print"
            width="100%"
            justifyContent="space-between"
            flexDirection={['column', , 'row']}
            verticalSpacing={[4, , 0]}
          >
            <EmailReceipt
              ml={[0, null, '60px']}
              setError={setError}
              {...(variant === 'enrollHorseToProposition'
                ? { param1: licenseId, param2: raceDayId }
                : { param1: propositionId, param2: horseId })}
              useEmailReceipt={
                variant === 'enrollHorseToProposition'
                  ? useEmailEnrollmentReceipt
                  : useStartDeclaredDetailEmailReceipt
              }
            />
            <ButtonLink
              mr={[0, null, '60px']}
              display="flex"
              alignItems="center"
              onClick={() => {
                window.print();
              }}
            >
              Skriv ut kvittens
              <Icon size={16} as={Print} color="blue" ml={1} />
            </ButtonLink>
          </Flex>
        </Box>
      </Box>
      {actions && (
        <Box
          verticalSpacing={2}
          display={['flex', , 'none']}
          px={2}
          pb={2}
          flexDirection="column"
          className="hidden-print"
        >
          {actions}
        </Box>
      )}
    </Box>
  );
}

import { useChangePassword } from '@apis';
import { Input } from '@components/FormikComponents';
import FormLayout, { FormItem } from '@components/FormLayout';
import { AuthenticatedUser } from '@generated/account/src';
import { useRefreshUser } from '@hooks/useUser';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import Message from '@primitives/Message';
import { Lead, Text } from '@primitives/Typography';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateEntities } from 'redux-query';

interface FormProps {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
  all: string;
}

interface Props {
  user: AuthenticatedUser;
}

function ChangePassword({ user }: Props): JSX.Element {
  const dispatch = useDispatch();
  const { action } = useChangePassword(user.licenseId);
  const refresh = useRefreshUser();
  const passwordChanged = localStorage.getItem('passwordChanged');

  if (passwordChanged) {
    setTimeout(() => {
      localStorage.removeItem('passwordChanged');
    }, 1000);
  }

  return (
    <Box>
      <Formik<FormProps>
        initialValues={{
          all: '',
          password: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
        onSubmit={async (values, helpers): Promise<void> => {
          const { password, newPassword, confirmNewPassword } = values;
          if (newPassword !== confirmNewPassword) {
            helpers.setFieldError('newPassword', 'Lösenorden matchar inte.');
            helpers.setFieldError(
              'confirmNewPassword',
              'Lösenorden matchar inte.',
            );
            return;
          }

          const { body, status } = await action({
            username: user.username,
            password,
            newPassword,
          });

          if (status === 204) {
            helpers.resetForm({
              status: 'success',
            });

            // Hide change password info message
            dispatch(
              updateEntities({
                forceChangeOfPassword: () => false,
              }),
            );

            localStorage.removeItem('forceChangeOfPassword');
            localStorage.setItem('passwordChanged', 'true');

            // Reloads user, causes page reload, should be improved
            await refresh();
          } else {
            helpers.setStatus('error');
            helpers.setSubmitting(false);
          }

          body.errorMessage &&
            helpers.setFieldError('all', body.localizedErrorMessage);
        }}
        validate={values => {
          const errors: Partial<FormProps> = {};
          const { newPassword, password, confirmNewPassword } = values;

          if (newPassword.length < 8) {
            errors.newPassword = 'Lösenordet ska vara minst 8 tecken långt.';
          }

          const digits = newPassword.replace(/[^0-9]/g, '').length;
          if (digits < 2 || digits > 6) {
            errors.newPassword =
              'Lösenordet ska innehålla minst 2 och högst 6 siffror.';
          }

          if (password == '') {
            errors.password = 'Det här fältet är obligatoriskt';
          }
          if (newPassword == '') {
            errors.newPassword = 'Det här fältet är obligatoriskt';
          }
          if (confirmNewPassword == '') {
            errors.confirmNewPassword = 'Det här fältet är obligatoriskt';
          }
          if (newPassword !== confirmNewPassword) {
            errors.confirmNewPassword = 'Lösenorden stämmer inte överens  ';
          }
          return errors;
        }}
      >
        {({ errors, dirty, isSubmitting, isValid }) => (
          <FormLayout variant="vertical">
            <FormItem>
              <Input
                type="password"
                name="password"
                label="Nuvarande lösenord"
              />
            </FormItem>
            <FormItem>
              <Input type="password" name="newPassword" label="Nytt lösenord" />
            </FormItem>
            <FormItem>
              <Input
                name="confirmNewPassword"
                label="Nytt lösenord (upprepa)"
                type="password"
              />
            </FormItem>
            <FormItem display="block">
              <Box>
                {passwordChanged ? (
                  <Message variant="successBox" mb={4} width={1}>
                    Lösenord sparat!{' '}
                    <Link to="/minasidor">Gå till mina sidor</Link>
                  </Message>
                ) : null}
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  Spara
                </Button>
              </Box>
            </FormItem>
            {errors.all && <Error>{errors.all}</Error>}
          </FormLayout>
        )}
      </Formik>
      <Lead marginTop="20px" fontWeight="bold">
        Regler för lösenord:
      </Lead>
      <Text marginTop="8px"> - Lösenordet ska vara minst 8 tecken långt</Text>
      <Text> - Lösenordet ska innehålla minst 2 och högst 6 siffror.</Text>
      <Text>
        - Lösenordet ska innehålla minst 2 bokstäver. Tillåtna bokstäver är A-Z
        (Versaler) och a-z (gemener). Blanda gärna versaler och gemener. Tänk på
        att förvara ditt användarnamn och lösenord åtskilda och på ett
        betryggande sätt.
      </Text>
    </Box>
  );
}

export default ChangePassword;

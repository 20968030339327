// tslint:disable
/**
 * Web API Horse Service
 * Service for fetching horses
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    GenderGroupGenders,
    GenderGroupGendersFromJSON,
    GenderGroupGendersToJSON,
    NumberOfStarts,
    NumberOfStartsFromJSON,
    NumberOfStartsToJSON,
    OffspringHorse,
    OffspringHorseFromJSON,
    OffspringHorseToJSON,
    PrizeMoney,
    PrizeMoneyFromJSON,
    PrizeMoneyToJSON,
} from './';

/**
 * 
 * @export
 * @interface Offspring
 */
export interface Offspring  {
    /**
     * 
     * @type {OffspringHorse}
     * @memberof Offspring
     */
    horse: OffspringHorse;
    /**
     * Registration number.
     * @type {string}
     * @memberof Offspring
     */
    registrationNumber: string;
    /**
     * 
     * @type {GenderGroupGenders}
     * @memberof Offspring
     */
    gender: GenderGroupGenders;
    /**
     * 
     * @type {number}
     * @memberof Offspring
     */
    yearBorn: number;
    /**
     * 
     * @type {PrizeMoney}
     * @memberof Offspring
     */
    prizeMoney: PrizeMoney;
    /**
     * 
     * @type {boolean}
     * @memberof Offspring
     */
    deadMarked: boolean;
    /**
     * Not implemented for gallop
     * @type {boolean}
     * @memberof Offspring
     */
    registrationDone: boolean;
    /**
     * 
     * @type {OffspringHorse}
     * @memberof Offspring
     */
    horsesParent: OffspringHorse;
    /**
     * 
     * @type {OffspringHorse}
     * @memberof Offspring
     */
    horsesParentsFather: OffspringHorse;
    /**
     * 
     * @type {string}
     * @memberof Offspring
     */
    remark?: string;
    /**
     * Placements to display (1-3-2), but is not always known -> empty string instead of 0-0-0)
     * @type {string}
     * @memberof Offspring
     */
    placements: string;
    /**
     * 
     * @type {number}
     * @memberof Offspring
     */
    firstPlaces: number;
    /**
     * 
     * @type {number}
     * @memberof Offspring
     */
    secondPlaces: number;
    /**
     * 
     * @type {number}
     * @memberof Offspring
     */
    thirdPlaces: number;
    /**
     * 
     * @type {NumberOfStarts}
     * @memberof Offspring
     */
    numberOfStarts: NumberOfStarts;
    /**
     * 
     * @type {number}
     * @memberof Offspring
     */
    bonus: number;
    /**
     * 
     * @type {number}
     * @memberof Offspring
     */
    maxHandicap: number;
    /**
     * 
     * @type {number}
     * @memberof Offspring
     */
    prizeMoneyIncludingBonus: number;
}

export function OffspringFromJSON(json: any): Offspring {
    return {
        'horse': OffspringHorseFromJSON(json['horse']),
        'registrationNumber': json['registrationNumber'],
        'gender': GenderGroupGendersFromJSON(json['gender']),
        'yearBorn': json['yearBorn'],
        'prizeMoney': PrizeMoneyFromJSON(json['prizeMoney']),
        'deadMarked': json['deadMarked'],
        'registrationDone': json['registrationDone'],
        'horsesParent': OffspringHorseFromJSON(json['horsesParent']),
        'horsesParentsFather': OffspringHorseFromJSON(json['horsesParentsFather']),
        'remark': !exists(json, 'remark') ? undefined : json['remark'],
        'placements': json['placements'],
        'firstPlaces': json['firstPlaces'],
        'secondPlaces': json['secondPlaces'],
        'thirdPlaces': json['thirdPlaces'],
        'numberOfStarts': NumberOfStartsFromJSON(json['numberOfStarts']),
        'bonus': json['bonus'],
        'maxHandicap': json['maxHandicap'],
        'prizeMoneyIncludingBonus': json['prizeMoneyIncludingBonus'],
    };
}

export function OffspringToJSON(value?: Offspring): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'horse': OffspringHorseToJSON(value.horse),
        'registrationNumber': value.registrationNumber,
        'gender': GenderGroupGendersToJSON(value.gender),
        'yearBorn': value.yearBorn,
        'prizeMoney': PrizeMoneyToJSON(value.prizeMoney),
        'deadMarked': value.deadMarked,
        'registrationDone': value.registrationDone,
        'horsesParent': OffspringHorseToJSON(value.horsesParent),
        'horsesParentsFather': OffspringHorseToJSON(value.horsesParentsFather),
        'remark': value.remark,
        'placements': value.placements,
        'firstPlaces': value.firstPlaces,
        'secondPlaces': value.secondPlaces,
        'thirdPlaces': value.thirdPlaces,
        'numberOfStarts': NumberOfStartsToJSON(value.numberOfStarts),
        'bonus': value.bonus,
        'maxHandicap': value.maxHandicap,
        'prizeMoneyIncludingBonus': value.prizeMoneyIncludingBonus,
    };
}



import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { fetchHorseStakeRaces, StakeRace } from '../../generated/horses/src';
import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type StakeRaces = StakeRace[] | undefined;

export interface Entities {
  stakeRaces?: {
    [id: number]: StakeRaces;
  };
}

const getQuery = (horseId: number): QueryConfig<Entities> =>
  fetchHorseStakeRaces<Entities>(
    {
      horseId,
    },
    {
      transform: responseBody => ({
        stakeRaces: {
          [horseId]: responseBody,
        },
      }),
      update: {
        stakeRaces: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetHorseStakeRaces = (
  horseId: number,
): ApiResponse<StakeRaces, Entities> => {
  const query = getQuery(horseId);
  const selector = (state: State): StakeRaces =>
    state.entities?.stakeRaces?.[horseId];

  return useApi<StakeRaces>({
    query,
    selector,
  });
};

export default useGetHorseStakeRaces;

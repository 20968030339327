import ExpandableHeadline from '@components/ExpandableHeadline';
import Table from '@components/Table';
import { Start } from '@generated/tds/src';
import { Text } from '@primitives/Typography';
import { formatNumber } from '@utils/formatting';
import React from 'react';
import { CellProps, Column } from 'react-table';

const columns: Column<Start>[] = [
  {
    accessor: 'horseName',
    Header: 'Häst',
    Cell: ({ value, row }: CellProps<Start, Start['horseName']>) => (
      <Text
        as="span"
        {...(row.original.withdrawn && {
          sx: { textDecoration: 'line-through' },
        })}
      >
        {value}
      </Text>
    ),
  },
  {
    accessor: 'representativeName',
    Header: 'Företrädare',
  },
  {
    accessor: 'trackCode',
    Header: 'Bana',
  },
  {
    accessor: 'raceNumber',
    Header: 'Lopp',
  },
  {
    accessor: 'placement',
    Header: 'Placering',
  },
  {
    accessor: 'prizeMoney',
    Header: 'Prissumma',
    Cell: ({ value }) => <>{formatNumber(value)}</>,
  },
];

interface Props {
  starts: Start[];
}

export default function Starts({ starts }: Props): JSX.Element {
  return (
    <ExpandableHeadline
      headline={starts[0].trackName}
      date={starts[0].raceDate}
      expandedDefault={true}
    >
      <Table mt={2} columns={columns} data={starts} />
    </ExpandableHeadline>
  );
}

import HorseInfo from '@components/HorseInfo';
import { Horse, OwnershipTransfer } from '@generated/ownership-transfers/src';
import { formatTz } from '@utils/dateUtils';
import React, { ReactNode, useMemo } from 'react';

import Remarks, { hasRemarks } from './Remarks';

interface Props {
  horse: Horse;
  details: {
    label: string;
    value: ReactNode;
  }[];
}

const TransferHorseInfo = ({ horse, details }: Props): JSX.Element => (
  <HorseInfo
    defaultOpen
    name={horse.horseName}
    details={details}
    text={
      <>
        {horse.horseRegistrationNumber}, {horse.horseGender.text},{' '}
        {horse.horseAge} år
      </>
    }
  />
);

export const SimpleTransferHorseInfo = ({
  horse,
}: Pick<Props, 'horse'>): JSX.Element => (
  <TransferHorseInfo
    horse={horse}
    details={[
      {
        label: 'Ägare',
        value: horse.ownershipName,
      },
      hasRemarks(horse) && {
        label: 'Anmärkning',
        value: <Remarks item={horse} />,
      },
    ]}
  />
);

interface OngoingHorseInfoProps {
  item: OwnershipTransfer;
  isLeasing?: boolean;
  isTermination?: boolean;
  hideExpiryDate?: boolean;
}

export const OngoingHorseInfo = ({
  isLeasing = false,
  isTermination = false,
  hideExpiryDate = false,
  item,
}: OngoingHorseInfoProps): JSX.Element => {
  const details = useMemo(
    () => [
      {
        label: 'Anmärkning',
        value: hasRemarks(item.horse) && <Remarks item={item.horse} />,
      },
      {
        label: isLeasing
          ? isTermination
            ? 'Företrädare ägare'
            : 'Ägare'
          : 'Säljare',
        value:
          isLeasing && isTermination
            ? item.sellerRepresentative.name
            : item.horse.ownershipName,
      },
      {
        label: isLeasing ? 'Företrädare hyrestagare' : 'Företrädare köpare',
        value: item.buyerRepresentative.name,
      },
      !hideExpiryDate && {
        label: isLeasing
          ? isTermination
            ? 'Uppsägningsdatum'
            : 'Leasingdatum'
          : 'Försäljningsdatum',
        value: formatTz(item.effectiveDate, 'yyyy-MM-dd'),
      },
      {
        label: 'Förfallodatum',
        value: formatTz(item.expiryDate, 'yyyy-MM-dd'),
      },
    ],
    [
      item.horse,
      item.sellerRepresentative.name,
      item.buyerRepresentative.name,
      item.effectiveDate,
      item.expiryDate,
      isLeasing,
      isTermination,
      hideExpiryDate,
    ],
  );

  return <TransferHorseInfo horse={item.horse} details={details} />;
};

export default TransferHorseInfo;

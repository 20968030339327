import Box, { Flex } from '@primitives/Box';
import Icon from '@primitives/Icon';
import { H2, H3 } from '@primitives/Typography';
import { Text } from '@primitives/Typography';
import React from 'react';
import { StyledIcon } from 'styled-icons/types';

type IconParameters = Parameters<typeof Icon>;

export interface Props {
  colorScheme: 'primary' | 'delete';
  description?: React.ReactNode;
  icon: StyledIcon;
  iconProps?: IconParameters[0];
  size: 'small' | 'medium';
  title: string;
}

const schemeColor = {
  primary: 'deep-blue',
  delete: 'red',
};

const sizes = {
  small: 280,
  medium: 546,
};

export default function Header({
  colorScheme,
  description = '',
  icon,
  iconProps,
  size,
  title,
}: Props): JSX.Element {
  const Heading = size === 'medium' ? H2 : H3;

  return (
    <Box padding={4}>
      <Flex maxWidth={`${sizes[size] - 90}px`} mx="auto">
        {icon && (
          <Box width={[1]} display="flex" justifyContent="center" mb={2}>
            <Flex
              alignItems="center"
              backgroundColor={schemeColor[colorScheme]}
              borderRadius="50%"
              width="20px"
              height="20px"
              justifyContent="center"
            >
              <Icon
                mb="2px"
                size="12px"
                color="white"
                as={icon}
                {...iconProps}
              />
            </Flex>
          </Box>
        )}
        <Heading
          fontWeight="bold"
          color="gray6"
          width="100%"
          textAlign="center"
        >
          {title}
        </Heading>
        {description && (
          <Text color="dim-gray" textAlign="center" mt={2}>
            {description}
          </Text>
        )}
      </Flex>
    </Box>
  );
}

import { getRaceDays, RaceDay } from '@generated/raceinfo/src';
import { State } from '@main/store';
import { utcToZonedTime } from 'date-fns-tz';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  raceDays?: {
    [key: string]: RaceDay[];
  };
}

interface Props {
  year: number;
  month: number;
}

export default function useCalendarDays({
  year,
  month,
}: Props): ApiResponse<RaceDay[] | undefined, Entities> {
  const from = new Date(year, month - 1, 1);
  const to = new Date(year, month, 1);

  const query = getRaceDays<Entities>(
    {
      fromracedate: from,
      tosubmissiondate: to,
    },
    {
      force: true,
      transform: responseBody => {
        const data = responseBody.map(raceDay => {
          const showOnDate = utcToZonedTime(
            raceDay.showOnDate,
            'Europe/Stockholm',
          );
          let raceDate = raceDay.raceDate;
          try {
            raceDate = utcToZonedTime(raceDay.raceDate, 'Europe/Stockholm');
          } catch (e) {
            console.error(e);
          }
          return {
            ...raceDay,
            showOnDate,
            raceDate,
          };
        });
        return {
          raceDays: {
            [`${year}-${month}`]: data,
          },
        };
      },
      update: {
        raceDays: (prev, next) => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  const selector = (state: State): RaceDay[] | undefined =>
    state.entities.raceDays?.[`${year}-${month}`];

  return useApi<RaceDay[] | undefined>({
    query,
    selector,
  });
}

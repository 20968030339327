import config from '@config';
import Settings from '@features/Account/Settings';
import CoveringLists from '@features/CoveringLists';
import EnrollOverview from '@features/EnrollOverview';
import HorsesInTraining from '@features/HorsesInTraining';
import InvoicesAndTransactions from '@features/InvoicesAndTransactions';
import MountingCalendar from '@features/MountingCalendar';
import StakeRaces from '@features/RaceInfo/StakeRaces/Account';
import StartDeclaration from '@features/StartDeclaration';
import TDS from '@features/TDS';
import Vaccinations from '@features/Vaccinations';
import { HorseIcon } from '@primitives/Icon';
import {
  Bookmark,
  CalendarDays as CalendarAlt,
  ChartLine,
  Flag,
  FlagCheckered,
  Gear as Cog,
  Horse,
  PaperPlane,
  RectangleList as ListAlt,
  RightFromBracket as SignOutAlt,
  RightLeft as ExchangeAlt,
  RightToBracket as SignInAlt,
  Star,
  Syringe,
  User,
  Users,
} from '@styled-icons/fa-solid';
import {
  OWNER_ROLES,
  OWNERSHIP_TRANSFER_ROLES,
  TDS_ROLES,
} from '@utils/constants';
import roleEnum from '@utils/roleEnum';
import React from 'react';
import { StyledIcon } from 'styled-icons/types';

import Favorites from './All/Favorites';
import SavedCharts from './All/SavedCharts';
import StartMonitoring from './All/StartMonitoring';
import Issues from './Issues';
import ComingStarts from './Owner/ComingStarts';
import Leasing from './Owner/Leasing';
import MyOwnedHorses from './Owner/MyOwnedHorses';
import MyOwnerships from './Owner/MyOwnerships';
import OwnershipTransfers from './Owner/OwnershipTransfers';
import OwnershipStatistics from './Owner/Statistics';
import VatRegistration from './Owner/VatRegistration';
import PreSelectedDrivers from './Trainer/PreSelectedDrivers';

type Navigation = Array<{
  label: string;
  key: string;
  items: Array<{
    label: string;
    path: string;
    component?: React.ComponentType;
    icon?: StyledIcon;
    roles: Array<roleEnum>;
    to?: string;
    hidden?: boolean;
    requireEveryRole?: boolean;
  }>;
}>;

export const navigation: Navigation = [
  {
    label: 'Tränarfunktioner',
    key: 'trainer',
    items: [
      {
        label: 'Hästar i träning',
        path: 'hastar-i-traning/:horseId?',
        component: HorsesInTraining,
        icon: HorseIcon,
        roles: [roleEnum.Trainer],
      },
      {
        label: 'Anmälan',
        path: 'anmalan/:tab(ny-anmalan|anmalda-hastar)?',
        component: EnrollOverview,
        icon: SignInAlt,
        roles: [roleEnum.Trainer],
      },
      {
        label: 'Startanmälan',
        path: 'startanmalan/:tab(tavlingsdagar|mina-startanmalningar)?',
        component: StartDeclaration,
        icon: SignInAlt,
        roles: [roleEnum.Trainer],
      },
      {
        label: 'Förvalda ryttare',
        path: 'forvalda-ryttare',
        component: PreSelectedDrivers,
        icon: User,
        roles: [roleEnum.Trainer],
      },
      {
        label: 'Insatslopp',
        path: 'insatslopp/:section(anmalningslistor|anmalda)?/:propositionId?',
        component: StakeRaces,
        icon: SignInAlt,
        roles: [roleEnum.Trainer],
      },
      {
        label: 'Vaccinationer',
        path: 'vaccinationer/:tab?/:horseId?',
        component: Vaccinations,
        icon: Syringe,
        roles: [roleEnum.Trainer],
      },
    ],
  },
  {
    label: 'Ryttarfunktioner',
    key: 'driver',
    items: [
      {
        label: 'Uppsittningskalender',
        path: 'uppsittningskalender/:year?/:month?',
        component: MountingCalendar,
        icon: CalendarAlt,
        roles: [roleEnum.Driver],
      },
      {
        label: 'TDS',
        path: 'tds/:tab?/:param1?',
        component: TDS,
        icon: ListAlt,
        roles: TDS_ROLES,
        requireEveryRole: true,
      },
    ],
  },
  {
    label: 'Ägarfunktioner',
    key: 'owner',
    items: [
      {
        label: 'Mina ägda hästar',
        path: 'mina-agda-hastar/:horseId?',
        component: MyOwnedHorses,
        icon: Horse,
        roles: OWNER_ROLES,
      },
      {
        label: 'Kommande starter',
        path: 'kommande-starter',
        component: ComingStarts,
        icon: FlagCheckered,
        roles: OWNER_ROLES,
      },
      {
        label: 'Ägarskap och andelar',
        path: 'mina-agarskap/:tab(agarskap|andelsfordelning)?',
        component: MyOwnerships,
        icon: Users,
        roles: OWNER_ROLES,
      },
      {
        label: 'Ägarskiften',
        path: 'agarskiften/:tab(anmal|pagande)?',
        component: OwnershipTransfers,
        icon: ExchangeAlt,
        roles: OWNERSHIP_TRANSFER_ROLES,
      },
      {
        label: 'Leasing',
        path: 'leasing/:tab(anmal|pagande|uppsagningar|avsluta)?',
        component: Leasing,
        icon: ExchangeAlt,
        roles: OWNERSHIP_TRANSFER_ROLES,
      },

      // Not ready for production
      ...(!config.isProduction
        ? [
            {
              label: 'Ägarstatistik',
              path: 'agarstatistik',
              component: OwnershipStatistics,
              icon: ChartLine,
              roles: OWNER_ROLES,
            },
          ]
        : []),
      {
        label: 'Fakturor & transaktioner',
        path: 'fakturor-och-transaktioner/:tab?/:param1?/:param2?',
        component: InvoicesAndTransactions,
        icon: ListAlt,
        roles: OWNER_ROLES,
      },
      {
        label: 'Momsregistreringsnummer',
        path: 'momsregistrering',
        component: VatRegistration,
        icon: Cog,
        roles: OWNER_ROLES,
      },
    ],
  },
  {
    label: 'Hingshållare',
    key: 'breeder',
    items: [
      {
        label: 'Språngrulla',
        path: 'sprangrulla',
        component: CoveringLists,
        icon: HorseIcon,
        roles: [roleEnum.Hingsthallare],
      },
    ],
  },
  {
    label: 'Mina listor',
    key: 'lists',
    items: [
      {
        label: 'Startbevakningar',
        path: 'startbevakningar',
        component: StartMonitoring,
        icon: Flag,
        roles: [],
      },
      {
        label: 'Favoriter',
        path: 'favoriter',
        component: Favorites,
        icon: Star,
        roles: [],
      },
      {
        label: 'Sparade topplistor',
        path: 'sparade-topplistor',
        component: SavedCharts,
        icon: Bookmark,
        roles: [],
      },
    ],
  },
  {
    label: 'Mitt konto',
    key: 'account',
    items: [
      {
        label: 'Ärenden',
        path: 'arenden',
        component: Issues,
        icon: PaperPlane,
        roles: [],
      },
      {
        label: 'Inställningar',
        path: 'installningar',
        component: Settings,
        icon: Cog,
        roles: [],
      },
      {
        label: 'Logga ut',
        path: 'log-out',
        icon: SignOutAlt,
        roles: [],
      },
    ],
  },
];

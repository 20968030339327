import { useDeleteTransaction } from '@apis';
import Confirm from '@components/Confirm';
import { resetQuery } from '@main/state/queriesReducer';
import Icon from '@primitives/Icon';
import { TrashCan as TrashAlt } from '@styled-icons/fa-solid';
import { formatTz } from '@utils/dateUtils';
import Refresh from '@utils/Refresh';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getQueryKey as getDriverStartsQueryKey } from '../../../../../apis/tds/useGetDriverStarts';

interface Props {
  id: number;
  licenseId: number;
  horseName: string;
  transactionDate: Date;
  productDescription: string;
  refresh: Refresh;
}

export default function Delete({
  id,
  licenseId,
  horseName,
  transactionDate,
  productDescription,
  refresh,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const { action, error, loading } = useDeleteTransaction(licenseId);
  const [open, setOpen] = useState(false);

  const openModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onAction = useCallback(async () => {
    const { status } = await action(id);

    if (status && status === 200) {
      dispatch(resetQuery(getDriverStartsQueryKey(licenseId)));
      refresh();
    }
  }, [action, dispatch, id, licenseId, refresh]);

  return (
    <>
      <button onClick={openModal}>
        <Icon color="gray6" size={12} as={TrashAlt} />
      </button>
      <Confirm
        closeModal={closeModal}
        colorScheme="delete"
        error={error}
        loading={loading}
        onAction={onAction}
        open={open}
        title="Ta bort transaktion?"
        description={`Är du säker på att du vill ta bort transaktionen för ${horseName} med produkten ${productDescription} och datum ${formatTz(
          transactionDate,
          'yyyy-MM-dd',
        )}?`}
      ></Confirm>
    </>
  );
}

import { EntryWeightList, getEntryWeightList } from '@generated/raceinfo/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  entryWeightList?: {
    [key: number]: EntryWeightList;
  };
}

const useGetEntryWeightList = (
  raceDayId: number,
  force = false,
): ApiResponse<EntryWeightList, Entities> => {
  const query = getEntryWeightList<Entities>(
    {
      raceDayId,
    },
    {
      force,
      transform: responseBody => ({
        entryWeightList: {
          [raceDayId]: responseBody,
        },
      }),
      update: {
        entryWeightList: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );
  const selector = (state: State): EntryWeightList =>
    state.entities?.entryWeightList?.[raceDayId];

  return useApi<EntryWeightList>({
    query,
    selector,
  });
};

export default useGetEntryWeightList;

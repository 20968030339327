// tslint:disable
/**
 * Web API Licenseholder Service
 * Service for reading licenseholder data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface Vaccination
 */
export interface Vaccination  {
    /**
     * 
     * @type {Date}
     * @memberof Vaccination
     */
    vaccinationDate: Date;
    /**
     * 
     * @type {number}
     * @memberof Vaccination
     */
    vaccineId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Vaccination
     */
    horseIds: Array<number>;
}

export function VaccinationFromJSON(json: any): Vaccination {
    return {
        'vaccinationDate': parseDateTime(json['vaccinationDate']),
        'vaccineId': !exists(json, 'vaccineId') ? undefined : json['vaccineId'],
        'horseIds': json['horseIds'],
    };
}

export function VaccinationToJSON(value?: Vaccination): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'vaccinationDate': value.vaccinationDate.toLocalDate(),
        'vaccineId': value.vaccineId,
        'horseIds': value.horseIds,
    };
}



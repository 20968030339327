// tslint:disable
/**
 * Content API
 * Retrieve content created with wagtail
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@snippets.local
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActorResourceImage
 */
export interface ActorResourceImage  {
    /**
     * 
     * @type {string}
     * @memberof ActorResourceImage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ActorResourceImage
     */
    ogImage?: string;
    /**
     * 
     * @type {string}
     * @memberof ActorResourceImage
     */
    original?: string;
    /**
     * 
     * @type {number}
     * @memberof ActorResourceImage
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof ActorResourceImage
     */
    height?: number;
}

export function ActorResourceImageFromJSON(json: any): ActorResourceImage {
    return {
        'url': !exists(json, 'url') ? undefined : json['url'],
        'ogImage': !exists(json, 'og_image') ? undefined : json['og_image'],
        'original': !exists(json, 'original') ? undefined : json['original'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
    };
}

export function ActorResourceImageToJSON(value?: ActorResourceImage): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'url': value.url,
        'og_image': value.ogImage,
        'original': value.original,
        'width': value.width,
        'height': value.height,
    };
}



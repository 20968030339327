import {
  searchBreederByLabel,
  SearchBreederByLabelRequest,
  SportActorSearchReference,
} from '@generated/sportactors/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

export interface Entities {
  breeders?: {
    [key: string]: SportActorSearchReference[];
  };
}

export const useSearchBreeder = (
  request: SearchBreederByLabelRequest,
): ApiResponse<SportActorSearchReference[] | undefined, Entities> => {
  // 1. Query
  const query = searchBreederByLabel<Entities>(request, {
    transform: responseBody => ({
      breeders: {
        [JSON.stringify(request)]: responseBody,
      },
    }),
    update: {
      breeders: (prev, next): typeof next => ({ ...prev, ...next }),
    },
  });

  // 2. Selecctor
  const selector = (state: State): SportActorSearchReference[] | undefined =>
    state.entities.breeders?.[JSON.stringify(request)];

  return useApi<SportActorSearchReference[] | undefined>({
    query,
    selector,
  });
};

import {
  getOngoingStakeRaces,
  OngoingStakeRace,
} from '@generated/propositions/src';
import { State } from '@main/store';

import { ApiResponse } from '../../utils/types';
import useApi from '../../utils/useApi';

type Results = OngoingStakeRace[] | undefined;

export interface Entities {
  ongoingStakeRaces?: Results;
}

export default function useGetOngoingStakeRaces(): ApiResponse<
  Results,
  Entities
> {
  const query = getOngoingStakeRaces<Entities>({
    transform: responseBody => ({
      ongoingStakeRaces: responseBody,
    }),
    update: {
      ongoingStakeRaces: (prev, next): typeof next => next,
    },
  });
  const selector = (state: State): Results => state.entities?.ongoingStakeRaces;

  return useApi<Results>({
    query,
    selector,
  });
}

import { Theme } from '@main/theme';
import { Flex } from '@primitives/Box';
import { Link } from '@primitives/Link';
import { Text } from '@primitives/Typography';
import React from 'react';

interface Props {
  setHoverItem?: (item: string | undefined) => void;
  bg?: keyof Theme['colors'];
}

function HomeLink({ setHoverItem, bg }: Props): JSX.Element {
  return (
    <Flex width={[, , '120px']} bg={bg} position="relative">
      <Link
        px="4px"
        fontSize="pageHeader"
        position="relative"
        to="/"
        onMouseEnter={() => setHoverItem && setHoverItem('/')}
        onMouseLeave={() => setHoverItem && setHoverItem(undefined)}
        sx={{
          zIndex: '3',
          fontWeight: 'bold',
        }}
      >
        <Text
          as="span"
          sx={{
            color: ['white', , 'yellow'],
          }}
        >
          GALOPP
        </Text>
        <Text
          as="span"
          sx={{
            color: ['white', , 'deep-blue'],
          }}
        >
          APP
        </Text>
      </Link>
    </Flex>
  );
}

export default HomeLink;

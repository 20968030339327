import { useDeleteSavedChart, useGetSavedChartFilters } from '@apis';
import DataGrid from '@components/DataGrid';
import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import { SavedFilter as SF, SearchFilter } from '@generated/user/src';
import withUser from '@hoc/withUser';
import { Flex } from '@primitives/Box';
import { dataGridRemoveButtonMixin, RemoveButton } from '@primitives/Button';
import Error from '@primitives/Error';
import Icon from '@primitives/Icon';
import { Link } from '@primitives/Link';
import List from '@primitives/List';
import { Small } from '@primitives/Typography';
import { Bookmark, MagnifyingGlass as Search } from '@styled-icons/fa-solid';
import qs from 'qs';
import React from 'react';
import { CellProps } from 'react-table';

interface Props {
  user: AuthenticatedUser;
  simpleList?: boolean;
}

const mapTypeToPath = {
  A: 'avel',
  U: 'uppfodare',
  H: 'hastar',
  T: 'tranare',
  R: 'ryttare',
  E: 'agare',
};

function searchFilterToQs(searchFilter: SearchFilter): string {
  const query: Record<string, string | number> = {};

  Object.keys(searchFilter).forEach(key => {
    if (searchFilter[key]?.searchParameter) {
      query[key] = searchFilter[key]?.searchParameter;
    }
  });

  return qs.stringify(query);
}

export function SavedChartResults({ user, simpleList }: Props): JSX.Element {
  const categoryId = 3;
  const { data, status, error, refresh } = useGetSavedChartFilters(
    categoryId,
    user.licenseId,
  );

  const { action: remove } = useDeleteSavedChart(user.licenseId, categoryId);

  if (status && status !== 200) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  if (simpleList) {
    return data?.length ? (
      <List itemGap={1}>
        {data.slice(0, 5).map((item, i) => (
          <li key={i}>
            <Icon as={Bookmark} color="yellow" size={14} mr={2}></Icon>{' '}
            <Link
              color="gray6"
              to={`/topplistor/${
                mapTypeToPath[item.chartType.code]
              }?${searchFilterToQs(item.searchFilter)}`}
              sx={{
                textDecoration: 'underline',
              }}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </List>
    ) : (
      <Small color="dim-gray">Du har inga sparade topplistor.</Small>
    );
  }

  return (
    <DataGrid
      title={`Du har ${data?.length || 0} sparade topplistor`}
      noContent={{
        content: 'Du har inte sparat någon topplista',
      }}
      columns={[
        {
          Header: 'Namn',
          accessor: 'name',
          Cell: ({
            row: {
              original: { chartType, searchFilter },
            },
            value,
          }: CellProps<SF, string>) => (
            <>
              <Icon as={Bookmark} color="yellow" size={14} mr={2}></Icon>
              <Link
                color="gray6"
                fontWeight="bold"
                to={`/topplistor/${
                  mapTypeToPath[chartType.code]
                }?${searchFilterToQs(searchFilter)}`}
                sx={{
                  textDecoration: 'underline',
                }}
              >
                {value}
              </Link>
            </>
          ),
        },
        {
          Header: 'Ta bort',
          id: 'delete',
          accessor: 'chartId',
          Cell: ({
            row: {
              original: { chartId, chartType },
            },
          }: CellProps<SF>) => (
            <RemoveButton
              onClick={async () => {
                await remove({
                  chartId,
                  typeId: chartType.id,
                });
                await refresh();
              }}
            />
          ),
          ...dataGridRemoveButtonMixin,
        },
      ]}
      data={data || []}
      noHover
    />
  );
}

function SavedCharts({ user }: Props): JSX.Element {
  return (
    <PageLayout headline="Sparade topplistor" singleCard>
      <SavedChartResults user={user} />
      <Flex justifyContent="flex-end" pt={3}>
        <Link to="/topplistor">
          Sök och spara topplistor <Search size={12} />
        </Link>
      </Flex>
    </PageLayout>
  );
}

export default withUser(SavedCharts);

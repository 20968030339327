import { OperatorEnum, resetQuery } from '@main/state/queriesReducer';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorSelectors, QueryConfig } from 'redux-query';
import { useMutation } from 'redux-query-react';

import { ErrorResponse } from '../../generated/raceinfo/src';
import { State } from '../../main/store';
import { Entities } from '..';
import setBaseUrl, { apiTypes } from './setBaseUrl';
import { MutationResponse, MutationResponseWithoutData } from './types';

interface BaseProps<TQuery, RequestArgs> {
  queryFunc: (args: RequestArgs) => TQuery;
  queryKey: string;
  basePath?: apiTypes;
  onSuccess?: () => void;
  resetKeys?: string[];
}

/**
 * useApiMutateWithoutData should be used for mutations not returning any data
 */
export const useApiMutateWithoutData = <
  RequestArgs,
  TQuery extends QueryConfig = QueryConfig,
>({
  queryFunc: baseQueryFunc,
  queryKey,
  basePath = 'webapi',
  onSuccess,
  resetKeys,
}: BaseProps<TQuery, RequestArgs>): MutationResponseWithoutData<
  Entities,
  RequestArgs
> => {
  const dispatch = useDispatch();
  const queryFunc = (args: RequestArgs): TQuery =>
    // Add absolute api base url to the query
    ({ ...setBaseUrl(baseQueryFunc(args), basePath), queryKey });

  // Get mutation
  const [{ isFinished, isPending, status }, baseAction] =
    useMutation(queryFunc);

  useEffect(() => {
    if (isFinished && status >= 200 && status <= 300) {
      if (onSuccess) {
        onSuccess();
      }
      if (resetKeys) {
        resetKeys.forEach(rawKey => {
          const key = rawKey.replace(/(\*$)/g, '');
          dispatch(
            resetQuery(
              key,
              rawKey.endsWith('*')
                ? OperatorEnum.STARTS_WITH
                : OperatorEnum.EQUAL,
            ),
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const action = useMemo(() => baseAction, []);

  // Select the error entity
  const respError: ErrorResponse | undefined = useSelector((state: State) =>
    errorSelectors.responseBody(state.errors, {
      queryKey,
      url: '',
    }),
  );

  // Select Network error
  const netWorkError: string | undefined = useSelector((state: State) =>
    errorSelectors.responseText(state.errors, {
      queryKey,
      url: '',
    }),
  );

  const error =
    respError?.localizedErrorMessage || respError?.errorMessage || netWorkError;

  return {
    loading: !isFinished && isPending,
    action,
    error,
  };
};

interface Props<TQuery, TSelector, RequestArgs> {
  queryFunc: (args: RequestArgs) => TQuery;
  selector: TSelector;
  queryKey: string;
  basePath?: apiTypes;
  onSuccess?: () => void;
  resetKeys?: string[];
}

/**
 * useApiMutate extends useApiMutateWithoutData
 */
const useApiMutate = <
  TResp,
  RequestArgs,
  TQuery extends QueryConfig = QueryConfig,
  TSelector extends (state: State) => TResp = (state: State) => TResp,
>({
  queryFunc,
  selector,
  queryKey,
  basePath = 'webapi',
  onSuccess,
  resetKeys,
}: Props<TQuery, TSelector, RequestArgs>): MutationResponse<
  TResp,
  Entities,
  RequestArgs
> => {
  const { loading, action, error } = useApiMutateWithoutData<
    RequestArgs,
    TQuery
  >({
    queryFunc,
    queryKey,
    basePath,
    onSuccess,
    resetKeys,
  });

  // Select the data entities
  const data = useSelector(selector);

  return {
    data,
    loading,
    action,
    error,
  };
};

export default useApiMutate;

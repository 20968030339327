import {
  ComingStart,
  getComingStartsForOwner,
} from '@generated/sportactors/src';
import { State } from '@main/store';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type OwnerComingStarts = ComingStart[] | undefined;

export interface Entities {
  ownerComingStarts?: {
    [id: number]: OwnerComingStarts;
  };
}

const useGetOwner = (
  ownerId: number,
): ApiResponse<OwnerComingStarts, Entities> => {
  // 1. Query
  const query = getComingStartsForOwner<Entities>(
    {
      ownerId,
    },
    {
      transform: responseBody => ({
        ownerComingStarts: {
          [ownerId]: responseBody,
        },
      }),
      update: {
        ownerComingStarts: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

  // 2. Selecctor
  const selector = (state: State): OwnerComingStarts =>
    state.entities?.ownerComingStarts?.[ownerId];

  return useApi<OwnerComingStarts>({
    query,
    selector,
  });
};

export default useGetOwner;

import {
  getOwnershipsStatisticsSummary,
  OwnershipsStatisticsSummary,
} from '@generated/sportactors/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = OwnershipsStatisticsSummary | undefined;

export interface Entities {
  ownershipsStatisticsSummary?: {
    [ownerId: number]: Result;
  };
}

const getQuery = (ownerId: number): QueryConfig<Entities> =>
  getOwnershipsStatisticsSummary<Entities>(
    {
      ownerId,
    },
    {
      transform: responseBody => ({
        ownershipsStatisticsSummary: { [ownerId]: responseBody },
      }),
      update: {
        ownershipsStatisticsSummary: (prev, next): typeof next => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetOwnershipsStatisticsSummary = (
  ownerId: number,
): ApiResponse<Result, Entities> => {
  const query = getQuery(ownerId);
  const selector = (state: State): Result =>
    state.entities?.ownershipsStatisticsSummary?.[ownerId];

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetOwnershipsStatisticsSummary;

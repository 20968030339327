// tslint:disable
/**
 * SportInfoApi Gallop Proposition Service
 * Service for reading proposition data.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    EnrolledHorse,
    EnrolledHorseFromJSON,
    EnrolledHorseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    OngoingStakeRace,
    OngoingStakeRaceFromJSON,
    OngoingStakeRaceToJSON,
    StakeRaceEnrolled,
    StakeRaceEnrolledFromJSON,
    StakeRaceEnrolledToJSON,
    StakeRaceOngoingHorses,
    StakeRaceOngoingHorsesFromJSON,
    StakeRaceOngoingHorsesToJSON,
    StakeRaceWithStakes,
    StakeRaceWithStakesFromJSON,
    StakeRaceWithStakesToJSON,
    UpcomingStakeRace,
    UpcomingStakeRaceFromJSON,
    UpcomingStakeRaceToJSON,
    UpcomingStakeRaceHorse,
    UpcomingStakeRaceHorseFromJSON,
    UpcomingStakeRaceHorseToJSON,
} from '../models';

export interface EnrollHorseToUpcomingStakeRaceRequest {
    propositionId: number;
    trainerId: number;
    body: number;
    xEndUserIp?: string;
}

export interface GetHorsesInOngoingStakeRaceRequest {
    propositionId: number;
    stakeid?: number;
}

export interface GetRacesWithEnrolledHorsesRequest {
    trainerId: number;
    year?: number;
    horseid?: number;
}

export interface GetStakeRaceWithStakesRequest {
    propositionId: number;
}

export interface GetTrainerHorsesEnrolledToAnyStakeRaceRequest {
    trainerId: number;
    year?: number;
}

export interface GetTrainerHorsesEnrolledToSpecificStakeRaceRequest {
    propositionId: number;
    trainerId: number;
}

export interface GetUpcomingStakeRaceHorsesRequest {
    propositionId: number;
    trainerId: number;
}

export interface GetUpcomingStakeRacesRequest {
    year?: number;
    horseid?: number;
}

export interface UnEnrollFromUpcomingStakeRaceRequest {
    propositionId: number;
    horseId: number;
    xEndUserIp?: string;
}


/**
 * Enroll a trained horse to an upcoming stake race
 */
function enrollHorseToUpcomingStakeRaceRaw<T>(requestParameters: EnrollHorseToUpcomingStakeRaceRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling enrollHorseToUpcomingStakeRace.');
    }

    if (requestParameters.trainerId === null || requestParameters.trainerId === undefined) {
        throw new runtime.RequiredError('trainerId','Required parameter requestParameters.trainerId was null or undefined when calling enrollHorseToUpcomingStakeRace.');
    }

    if (requestParameters.body === null || requestParameters.body === undefined) {
        throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling enrollHorseToUpcomingStakeRace.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters.xEndUserIp !== undefined && requestParameters.xEndUserIp !== null) {
        headerParameters['X-End-User-Ip'] = String(requestParameters.xEndUserIp);
    }


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/stakeraces/upcoming/{propositionId}/horses/{trainerId}`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))).replace(`{${"trainerId"}}`, encodeURIComponent(String(requestParameters.trainerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || requestParameters.body as any,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Enroll a trained horse to an upcoming stake race
*/
export function enrollHorseToUpcomingStakeRace<T>(requestParameters: EnrollHorseToUpcomingStakeRaceRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return enrollHorseToUpcomingStakeRaceRaw(requestParameters, requestConfig);
}

/**
 * Get all horses in an ongoing stake race
 */
function getHorsesInOngoingStakeRaceRaw<T>(requestParameters: GetHorsesInOngoingStakeRaceRequest, requestConfig: runtime.TypedQueryConfig<T, StakeRaceOngoingHorses> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling getHorsesInOngoingStakeRace.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.stakeid !== undefined) {
        queryParameters['stakeid'] = requestParameters.stakeid;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/stakeraces/ongoing/{propositionId}/horses`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(StakeRaceOngoingHorsesFromJSON(body), text);
    }

    return config;
}

/**
* Get all horses in an ongoing stake race
*/
export function getHorsesInOngoingStakeRace<T>(requestParameters: GetHorsesInOngoingStakeRaceRequest, requestConfig?: runtime.TypedQueryConfig<T, StakeRaceOngoingHorses>): QueryConfig<T> {
    return getHorsesInOngoingStakeRaceRaw(requestParameters, requestConfig);
}

/**
 * Get all ongoing stake races
 */
function getOngoingStakeRacesRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Array<OngoingStakeRace>> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/stakeraces/ongoing`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(OngoingStakeRaceFromJSON), text);
    }

    return config;
}

/**
* Get all ongoing stake races
*/
export function getOngoingStakeRaces<T>( requestConfig?: runtime.TypedQueryConfig<T, Array<OngoingStakeRace>>): QueryConfig<T> {
    return getOngoingStakeRacesRaw( requestConfig);
}

/**
 * Get all trainer\'s races with enrolled horses
 */
function getRacesWithEnrolledHorsesRaw<T>(requestParameters: GetRacesWithEnrolledHorsesRequest, requestConfig: runtime.TypedQueryConfig<T, Array<StakeRaceEnrolled>> = {}): QueryConfig<T> {
    if (requestParameters.trainerId === null || requestParameters.trainerId === undefined) {
        throw new runtime.RequiredError('trainerId','Required parameter requestParameters.trainerId was null or undefined when calling getRacesWithEnrolledHorses.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.year !== undefined) {
        queryParameters['year'] = requestParameters.year;
    }


    if (requestParameters.horseid !== undefined) {
        queryParameters['horseid'] = requestParameters.horseid;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/stakeraces/enrollments/{trainerId}`.replace(`{${"trainerId"}}`, encodeURIComponent(String(requestParameters.trainerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(StakeRaceEnrolledFromJSON), text);
    }

    return config;
}

/**
* Get all trainer\'s races with enrolled horses
*/
export function getRacesWithEnrolledHorses<T>(requestParameters: GetRacesWithEnrolledHorsesRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<StakeRaceEnrolled>>): QueryConfig<T> {
    return getRacesWithEnrolledHorsesRaw(requestParameters, requestConfig);
}

/**
 * Get a stake race including stakes info
 */
function getStakeRaceWithStakesRaw<T>(requestParameters: GetStakeRaceWithStakesRequest, requestConfig: runtime.TypedQueryConfig<T, StakeRaceWithStakes> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling getStakeRaceWithStakes.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/stakeraces/{propositionId}`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(StakeRaceWithStakesFromJSON(body), text);
    }

    return config;
}

/**
* Get a stake race including stakes info
*/
export function getStakeRaceWithStakes<T>(requestParameters: GetStakeRaceWithStakesRequest, requestConfig?: runtime.TypedQueryConfig<T, StakeRaceWithStakes>): QueryConfig<T> {
    return getStakeRaceWithStakesRaw(requestParameters, requestConfig);
}

/**
 * Get enrolled horses for any race
 */
function getTrainerHorsesEnrolledToAnyStakeRaceRaw<T>(requestParameters: GetTrainerHorsesEnrolledToAnyStakeRaceRequest, requestConfig: runtime.TypedQueryConfig<T, Array<EnrolledHorse>> = {}): QueryConfig<T> {
    if (requestParameters.trainerId === null || requestParameters.trainerId === undefined) {
        throw new runtime.RequiredError('trainerId','Required parameter requestParameters.trainerId was null or undefined when calling getTrainerHorsesEnrolledToAnyStakeRace.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.year !== undefined) {
        queryParameters['year'] = requestParameters.year;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/stakeraces/enrollments/{trainerId}/horses`.replace(`{${"trainerId"}}`, encodeURIComponent(String(requestParameters.trainerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(EnrolledHorseFromJSON), text);
    }

    return config;
}

/**
* Get enrolled horses for any race
*/
export function getTrainerHorsesEnrolledToAnyStakeRace<T>(requestParameters: GetTrainerHorsesEnrolledToAnyStakeRaceRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<EnrolledHorse>>): QueryConfig<T> {
    return getTrainerHorsesEnrolledToAnyStakeRaceRaw(requestParameters, requestConfig);
}

/**
 * Get trainers enrolled horses for a specific race
 */
function getTrainerHorsesEnrolledToSpecificStakeRaceRaw<T>(requestParameters: GetTrainerHorsesEnrolledToSpecificStakeRaceRequest, requestConfig: runtime.TypedQueryConfig<T, Array<EnrolledHorse>> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling getTrainerHorsesEnrolledToSpecificStakeRace.');
    }

    if (requestParameters.trainerId === null || requestParameters.trainerId === undefined) {
        throw new runtime.RequiredError('trainerId','Required parameter requestParameters.trainerId was null or undefined when calling getTrainerHorsesEnrolledToSpecificStakeRace.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/stakeraces/{propositionId}/enrollments/{trainerId}/horses`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))).replace(`{${"trainerId"}}`, encodeURIComponent(String(requestParameters.trainerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(EnrolledHorseFromJSON), text);
    }

    return config;
}

/**
* Get trainers enrolled horses for a specific race
*/
export function getTrainerHorsesEnrolledToSpecificStakeRace<T>(requestParameters: GetTrainerHorsesEnrolledToSpecificStakeRaceRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<EnrolledHorse>>): QueryConfig<T> {
    return getTrainerHorsesEnrolledToSpecificStakeRaceRaw(requestParameters, requestConfig);
}

/**
 * Get all trainer\'s horses valid for an upcoming stake race
 */
function getUpcomingStakeRaceHorsesRaw<T>(requestParameters: GetUpcomingStakeRaceHorsesRequest, requestConfig: runtime.TypedQueryConfig<T, Array<UpcomingStakeRaceHorse>> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling getUpcomingStakeRaceHorses.');
    }

    if (requestParameters.trainerId === null || requestParameters.trainerId === undefined) {
        throw new runtime.RequiredError('trainerId','Required parameter requestParameters.trainerId was null or undefined when calling getUpcomingStakeRaceHorses.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/stakeraces/upcoming/{propositionId}/horses/{trainerId}`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))).replace(`{${"trainerId"}}`, encodeURIComponent(String(requestParameters.trainerId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(UpcomingStakeRaceHorseFromJSON), text);
    }

    return config;
}

/**
* Get all trainer\'s horses valid for an upcoming stake race
*/
export function getUpcomingStakeRaceHorses<T>(requestParameters: GetUpcomingStakeRaceHorsesRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<UpcomingStakeRaceHorse>>): QueryConfig<T> {
    return getUpcomingStakeRaceHorsesRaw(requestParameters, requestConfig);
}

/**
 * To filter on a horse, the user must train the horse
 * Get all upcoming stake races
 */
function getUpcomingStakeRacesRaw<T>(requestParameters: GetUpcomingStakeRacesRequest, requestConfig: runtime.TypedQueryConfig<T, Array<UpcomingStakeRace>> = {}): QueryConfig<T> {
    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.year !== undefined) {
        queryParameters['year'] = requestParameters.year;
    }


    if (requestParameters.horseid !== undefined) {
        queryParameters['horseid'] = requestParameters.horseid;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/stakeraces/upcoming`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(UpcomingStakeRaceFromJSON), text);
    }

    return config;
}

/**
* To filter on a horse, the user must train the horse
* Get all upcoming stake races
*/
export function getUpcomingStakeRaces<T>(requestParameters: GetUpcomingStakeRacesRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<UpcomingStakeRace>>): QueryConfig<T> {
    return getUpcomingStakeRacesRaw(requestParameters, requestConfig);
}

/**
 * Un enroll a trained horse from a stake race
 */
function unEnrollFromUpcomingStakeRaceRaw<T>(requestParameters: UnEnrollFromUpcomingStakeRaceRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.propositionId === null || requestParameters.propositionId === undefined) {
        throw new runtime.RequiredError('propositionId','Required parameter requestParameters.propositionId was null or undefined when calling unEnrollFromUpcomingStakeRace.');
    }

    if (requestParameters.horseId === null || requestParameters.horseId === undefined) {
        throw new runtime.RequiredError('horseId','Required parameter requestParameters.horseId was null or undefined when calling unEnrollFromUpcomingStakeRace.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    if (requestParameters.xEndUserIp !== undefined && requestParameters.xEndUserIp !== null) {
        headerParameters['X-End-User-Ip'] = String(requestParameters.xEndUserIp);
    }


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/propositions/stakeraces/{propositionId}/enrollments/{horseId}`.replace(`{${"propositionId"}}`, encodeURIComponent(String(requestParameters.propositionId))).replace(`{${"horseId"}}`, encodeURIComponent(String(requestParameters.horseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Un enroll a trained horse from a stake race
*/
export function unEnrollFromUpcomingStakeRace<T>(requestParameters: UnEnrollFromUpcomingStakeRaceRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return unEnrollFromUpcomingStakeRaceRaw(requestParameters, requestConfig);
}


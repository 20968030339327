import { base } from '@primitives/base';
import styled from 'styled-components';
import { typography, TypographyProps } from 'styled-system';

const StyledDate = styled.div<TypographyProps>`
  display: inline-block;
  color: ${props => props.theme.colors.gray5};
  font-size: ${props => props.theme.fontSizes.lead}px;
  padding-left: 5px;
  ${base(typography)}
`;

export default StyledDate;

import { base, BaseProps } from '@primitives/base';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { typography, TypographyProps } from 'styled-system';

interface Props extends BaseProps, TypographyProps {}

const Link = styled(
  ({
    to,
    className,
    exact,
    strict,
    activeClassName,
    children,
    onClick,
    isActive,
  }) => (
    <NavLink
      {...{ to, className, exact, strict, activeClassName, onClick, isActive }}
    >
      {children}
    </NavLink>
  ),
)<Props>`
  ${base(typography)}
`;

export default Link;

import { useGetOngoingTerminations } from '@apis';
import FadedContent from '@components/ContentBlock/Faded';
import InfoBadge from '@components/InfoBadge';
import { AuthenticatedUser } from '@generated/account/src';
import { OwnershipTransfer } from '@generated/ownership-transfers/src';
import useQuery from '@hooks/useQuery';
import { useOnWindowFocus } from '@hooks/useWindowFocus';
import Box, { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import Pulse from '@primitives/Loading';
import Message from '@primitives/Message';
import { Text } from '@primitives/Typography';
import {
  CircleCheck as CheckCircle,
  CircleInfo as InfoCircle,
  FileSignature,
  TriangleExclamation as ExclamationTriangle,
} from '@styled-icons/fa-solid';
import { sortByStatusAndExpiryDate } from '@utils/ownershipTransferSorting';
import { differenceInCalendarDays } from 'date-fns';
import React, { ReactNode } from 'react';

import { OngoingHorseInfo } from '../../OwnershipTransfers/TransferHorseInfo';

interface Props {
  user: AuthenticatedUser;
}

interface TransferItemProps {
  item: OwnershipTransfer;
  duplicate?: OwnershipTransfer;
}

const signatoryRoleEnumMap = new Map(
  Object.entries({
    BUYER_REPRESENTATIVE: 'företrädare för hyrestagare',
    SELLER_REPRESENTATIVE: 'företrädare för ägare',
    BUYER_PART_OWNER: 'hyrestagare',
    //OTHER: 'Annat'
  }),
);

const Action = ({ item }: TransferItemProps): JSX.Element => {
  if (item.status.statusCode === 'AKTIV' && item.contract) {
    return (
      <Flex flexDirection="column" verticalSpacing={3} alignItems="flex-start">
        {item.contract.signatories
          .filter(v => v.status !== 'SIGNING_NOT_NEEDED')
          .map((v, i) => (
            <Button
              key={i}
              as={v.status !== 'SIGNED' ? 'a' : undefined}
              target="_blank"
              rel="nofollow"
              href={v.contractLink}
              colorScheme="save"
              icon={v.status === 'SIGNED' ? CheckCircle : FileSignature}
              disabled={v.status === 'SIGNED'}
              type={v.status === 'SIGNED' ? 'button' : 'link'}
            >
              {v.status === 'SIGNED' ? (
                <>
                  Avtal signerat
                  {signatoryRoleEnumMap.has(v.role)
                    ? ` - ${signatoryRoleEnumMap.get(v.role)}`
                    : ''}
                </>
              ) : (
                <>
                  Signera avtal
                  {signatoryRoleEnumMap.has(v.role)
                    ? ` - ${signatoryRoleEnumMap.get(v.role)}`
                    : ''}
                </>
              )}
            </Button>
          ))}
      </Flex>
    );
  }

  return null;
};

const StatusMessage = ({
  item,
  duplicate,
}: TransferItemProps): JSX.Element | null => {
  const isBothRoles = duplicate != null;

  let info: ReactNode = '';

  if (item.status.statusCode == 'MAKKOP') {
    info = (
      <>
        Uppsägningen har avvisats av hyrestagaren.
        {item.comment && (
          <>
            {' '}
            <b>Kommentar:</b> {item.comment}
          </>
        )}
      </>
    );
  } else if (item.status.statusCode == 'MAKST') {
    info = (
      <>
        Uppsägningen har avvisats av Svensk Galopp.
        {item.comment && (
          <>
            {' '}
            <b>Kommentar:</b> {item.comment}
          </>
        )}
      </>
    );
  } else if (item.status.statusCode == 'MAKSELJ') {
    info = 'Uppsägningen har avvisat av ägaren.';
  } else if (item.status.statusCode == 'MAKTID') {
    info = 'Uppsägningen har avbrutits eftersom den inte slutfördes i tid.';
  } else if (item.status.statusCode == 'WEBKLAR') {
    info = 'Ärendet handläggs hos Svensk Galopp.';
  } else if (item.status.statusCode == 'GENOMFORD') {
    info = 'Uppsägningen har registrerats.';
  } else if (isBothRoles && item.status.statusCode === 'INIT') {
    info = `Du är ägare och hyrestagare av hästen.`;
  } else if (item.role === 'SELLER' && item.status.statusCode === 'INIT') {
    info = `Du är ägare av hästen. Uppgifter fylls i av hyrestagaren.`;
  } else if (item.role === 'BUYER' && item.editable) {
    info = `Du är företrädare för hyrestagaren.`;
  } else if (item.status.statusCode === 'AKTIV' && item.contract) {
    info = `Signering återstår, ${item.contract.signatures.signedPersonsCount} av ${item.contract.signatures.totalSignatories} har signerat.`;
    if (
      item.contract.signatories.every(v => v.status === 'SIGNING_NOT_NEEDED')
    ) {
      info += ' Du behöver inte signera avtalet.';
    }
  } else {
    info = item.status.statusText;
  }

  return info ? (
    <InfoBadge message={info} color="blue" icon={InfoCircle} />
  ) : null;
};

const TransferItem = ({ item, duplicate }: TransferItemProps): JSX.Element => {
  const daysToDue = differenceInCalendarDays(item.expiryDate, Date.now());

  return (
    <Box verticalSpacing={3}>
      <OngoingHorseInfo item={item} isLeasing isTermination />
      {['AKTIV', 'INIT'].includes(item.status.statusCode) &&
        daysToDue <= 20 && (
          <InfoBadge
            message={`Uppsägningen måste ha signerats av båda parter inom ${daysToDue} dagar`}
            color="red"
            icon={ExclamationTriangle}
          />
        )}

      <StatusMessage item={item} duplicate={duplicate} />

      <Action item={item} duplicate={duplicate} />
    </Box>
  );
};

const LeasingTerminationsList = ({ user }: Props): JSX.Element => {
  const { data, error, loading, refresh } = useGetOngoingTerminations(
    user.licenseId,
    true,
  );

  const { message } = useQuery<{ message?: string }>(true);

  useOnWindowFocus(() => {
    refresh();
  }, []);

  if (loading && !data) {
    return <Pulse />;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  const items = data.map((value, index, self) => ({
    duplicate: self.findIndex(v => v.id === value.id) !== index,
    ...value,
  }));

  return (
    <Box verticalSpacing={3} my={4}>
      {message === 'signing' && (
        <Message variant="successBox">
          Uppsägningen av leasingavtalet har skickats till signering. Båda
          parter måste signera inom 21 dagar från att uppsägningen initierades,
          för att uppsägningen av leasingavtalet ska registreras. Du kan alltid
          följa processen här under Påbörjade uppsägningar. Kontakta den andra
          avtalsparten för att slutföra uppsägningen.
        </Message>
      )}

      <FadedContent slug="leasing-paborjade-uppsagningar" />

      {items
        .filter(item => !item.duplicate)
        .sort(sortByStatusAndExpiryDate)
        .map(item => (
          <React.Fragment key={item.id}>
            <TransferItem
              item={item}
              duplicate={items.find(i => i.duplicate && i.id === item.id)}
            />
            <Box as="hr" my="5" />
          </React.Fragment>
        ))}

      {items.length === 0 ? (
        <Text>Du har inga uppsägningar att hantera.</Text>
      ) : (
        <InfoBadge
          message="Observera att häst anses överlåten med de tävlingsengagemang som hästen hade vid överlåtelsetillfället. Förhyraren är dock skyldig att upplysa ägaren om vilka tävlingsengagemang hästen har vid tillfället för uppsägningen av förhyrningen. Vid underlåtelse att skriftligen upplysa ägaren kommer eventuella kostnader att debiteras den tidigare förhyraren."
          color="blue"
          icon={InfoCircle}
        />
      )}
    </Box>
  );
};

export default LeasingTerminationsList;

// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Notification,
    NotificationFromJSON,
    NotificationToJSON,
    OwnershipTransfer,
    OwnershipTransferFromJSON,
    OwnershipTransferToJSON,
} from '../models';

export interface GetNotificationsRequest {
    licenseId: number;
    serviceType?: GetNotificationsServiceTypeEnum;
}

export interface GetOngoingTransfersRequest {
    licenseId: number;
    serviceType?: GetOngoingTransfersServiceTypeEnum;
}


/**
 * Get notifications for ongoing transfers for representatives.
 */
function getNotificationsRaw<T>(requestParameters: GetNotificationsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Notification>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getNotifications.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.serviceType !== undefined) {
        queryParameters['serviceType'] = requestParameters.serviceType;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/{licenseId}/notifications`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(NotificationFromJSON), text);
    }

    return config;
}

/**
* Get notifications for ongoing transfers for representatives.
*/
export function getNotifications<T>(requestParameters: GetNotificationsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Notification>>): QueryConfig<T> {
    return getNotificationsRaw(requestParameters, requestConfig);
}

/**
 * Show all the representative\'s or part-owner\'s ongoing ownership transfers
 */
function getOngoingTransfersRaw<T>(requestParameters: GetOngoingTransfersRequest, requestConfig: runtime.TypedQueryConfig<T, Array<OwnershipTransfer>> = {}): QueryConfig<T> {
    if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
        throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling getOngoingTransfers.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.serviceType !== undefined) {
        queryParameters['serviceType'] = requestParameters.serviceType;
    }

    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/ownershiptransfers/{licenseId}/ongoing`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(OwnershipTransferFromJSON), text);
    }

    return config;
}

/**
* Show all the representative\'s or part-owner\'s ongoing ownership transfers
*/
export function getOngoingTransfers<T>(requestParameters: GetOngoingTransfersRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<OwnershipTransfer>>): QueryConfig<T> {
    return getOngoingTransfersRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum GetNotificationsServiceTypeEnum {
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS',
    LEASING = 'LEASING'
}
/**
    * @export
    * @enum {string}
    */
export enum GetOngoingTransfersServiceTypeEnum {
    OWNERSHIP_TRANSFERS = 'OWNERSHIP_TRANSFERS',
    LEASING = 'LEASING'
}

import Icon from '@primitives/Icon';
import { Xmark as Times } from '@styled-icons/fa-solid';
import React from 'react';

import ButtonLink from './Link';

type RemoveProps = Parameters<typeof ButtonLink>[0];

export default function Close({ ...props }: RemoveProps): JSX.Element {
  return (
    <ButtonLink
      display="inline-block"
      bg="white"
      color="dim-gray"
      p={2}
      m={2}
      sx={{
        '&:hover': {
          bg: 'whiteSmoke',
        },
        transition: 'background-color .2s ease',
      }}
      {...props}
    >
      <Icon as={Times} size={16} color={props?.color || 'dim-gray'} />
    </ButtonLink>
  );
}

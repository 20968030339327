// tslint:disable
/**
 * Web API Ownership Transfer Service
 * Service for transferring horse ownerships
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

import {
    CounterParty,
    CounterPartyFromJSON,
    CounterPartyToJSON,
    Horse,
    HorseFromJSON,
    HorseToJSON,
    OwnershipTransferContract,
    OwnershipTransferContractFromJSON,
    OwnershipTransferContractToJSON,
    Person,
    PersonFromJSON,
    PersonToJSON,
    Status,
    StatusFromJSON,
    StatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface OwnershipTransfer
 */
export interface OwnershipTransfer  {
    /**
     * 
     * @type {number}
     * @memberof OwnershipTransfer
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof OwnershipTransfer
     */
    effectiveDate: Date;
    /**
     * 
     * @type {Horse}
     * @memberof OwnershipTransfer
     */
    horse: Horse;
    /**
     * 
     * @type {Person}
     * @memberof OwnershipTransfer
     */
    sellerRepresentative: Person;
    /**
     * 
     * @type {Person}
     * @memberof OwnershipTransfer
     */
    buyerRepresentative: Person;
    /**
     * 
     * @type {Status}
     * @memberof OwnershipTransfer
     */
    status: Status;
    /**
     * 
     * @type {OwnershipTransferContract}
     * @memberof OwnershipTransfer
     */
    contract?: OwnershipTransferContract;
    /**
     * 
     * @type {Date}
     * @memberof OwnershipTransfer
     */
    expiryDate: Date;
    /**
     * User role in the transaction, either buyer or seller
     * @type {string}
     * @memberof OwnershipTransfer
     */
    role: OwnershipTransferRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof OwnershipTransfer
     */
    comment: string;
    /**
     * 
     * @type {boolean}
     * @memberof OwnershipTransfer
     */
    editable: boolean;
    /**
     * 
     * @type {CounterParty}
     * @memberof OwnershipTransfer
     */
    counterParty?: CounterParty;
}

export function OwnershipTransferFromJSON(json: any): OwnershipTransfer {
    return {
        'id': json['id'],
        'effectiveDate': parseDateTime(json['effectiveDate']),
        'horse': HorseFromJSON(json['horse']),
        'sellerRepresentative': PersonFromJSON(json['sellerRepresentative']),
        'buyerRepresentative': PersonFromJSON(json['buyerRepresentative']),
        'status': StatusFromJSON(json['status']),
        'contract': !exists(json, 'contract') ? undefined : OwnershipTransferContractFromJSON(json['contract']),
        'expiryDate': parseDateTime(json['expiryDate']),
        'role': json['role'],
        'comment': json['comment'],
        'editable': json['editable'],
        'counterParty': !exists(json, 'counterParty') ? undefined : CounterPartyFromJSON(json['counterParty']),
    };
}

export function OwnershipTransferToJSON(value?: OwnershipTransfer): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'effectiveDate': value.effectiveDate.toLocalDate(),
        'horse': HorseToJSON(value.horse),
        'sellerRepresentative': PersonToJSON(value.sellerRepresentative),
        'buyerRepresentative': PersonToJSON(value.buyerRepresentative),
        'status': StatusToJSON(value.status),
        'contract': OwnershipTransferContractToJSON(value.contract),
        'expiryDate': value.expiryDate.toLocalDate(),
        'role': value.role,
        'comment': value.comment,
        'editable': value.editable,
        'counterParty': CounterPartyToJSON(value.counterParty),
    };
}

/**
* @export
* @enum {string}
*/
export enum OwnershipTransferRoleEnum {
    BUYER = 'BUYER',
    SELLER = 'SELLER',
    PART_OWNER_SELLER = 'PART_OWNER_SELLER',
    PART_OWNER_BUYER = 'PART_OWNER_BUYER',
    LEGAL_SELLER_REPRESENTATIVE = 'LEGAL_SELLER_REPRESENTATIVE',
    LEGAL_BUYER_REPRESENTATIVE = 'LEGAL_BUYER_REPRESENTATIVE',
    BUYER_OR_SELLER = 'BUYER_OR_SELLER'
}



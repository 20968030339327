// tslint:disable
/**
 * Web API User Service
 * Service for performing various user related tasks, such as account settings, entry notifications and so on.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * 
 * @export
 * @interface SelectionParameter
 */
export interface SelectionParameter  {
    /**
     * 
     * @type {string}
     * @memberof SelectionParameter
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectionParameter
     */
    searchParameter?: string;
}

export function SelectionParameterFromJSON(json: any): SelectionParameter {
    return {
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'searchParameter': !exists(json, 'searchParameter') ? undefined : json['searchParameter'],
    };
}

export function SelectionParameterToJSON(value?: SelectionParameter): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'alias': value.alias,
        'searchParameter': value.searchParameter,
    };
}



import config from '@config';
import Box from '@primitives/Box';
import React, { useState } from 'react';
import styled from 'styled-components';

import HomeLink from '../HomeLink';
import logo from '../logo.png';
import devLogo from '../logo-test.png';
import RightMenu from '../RightMenu';
import TiltedBorder from '../TiltedBorder';
import Sidebar from './Sidebar';

const Logo = styled.a`
  width: 60px;
  background-image: url('${config.isProduction ? logo : devLogo}');
  background-size: 28px auto;
  background-position: 10px center;
  background-repeat: no-repeat;
  margin-right: -16px;
  background-color: ${p => p.theme.colors.white};
`;

const PageHeaderMobile = (): JSX.Element | null => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <Box
      width="100%"
      height="pageHeader"
      as="header"
      overflowY="hidden"
      className="hidden-print"
    >
      <Box
        height="pageHeader"
        position="fixed"
        top={0}
        width="100%"
        sx={{ zIndex: 1000 }}
      >
        <Box
          height="pageHeader"
          display="flex"
          color="white"
          boxShadow="0 2px 4px -2px rgba(0, 0, 0, 0.3)"
          position="relative"
        >
          <Box
            overflowY="hidden"
            overflowX="hidden"
            height="pageHeader"
            display="flex"
          >
            <Logo href="/" />
            <TiltedBorder />
            <HomeLink bg="deep-blue" />
          </Box>
          <RightMenu
            setSidebarOpen={setSidebarOpen}
            sidebarOpen={sidebarOpen}
          />
          <Sidebar
            open={sidebarOpen}
            setSidebarClosed={() => setSidebarOpen(false)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PageHeaderMobile;

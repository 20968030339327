const getDays = (year: number, month: number): number[] => {
  const currentDate = new Date(year, month, 0);
  const amountDays = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  ).getDate();

  const dateArray = Array.from(Array(amountDays), (_, i) => i + 1);

  return dateArray;
};

export default getDays;

import InfoBadge from '@components/InfoBadge';
import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import useAuthenticationMethod from '@hooks/useAuthenticationMethod';
import useForceChangeOfPassword from '@hooks/useForceChangeOfPassword';
import useUser, { useRefreshUser } from '@hooks/useUser';
import { resetState } from '@main/store';
import Error from '@primitives/Error';
import { Pulse } from '@primitives/Loading';
import { CHANGE_PASSWORD_PATH } from '@utils/constants';
import isLoggedIn from '@utils/isLoggedIn';
import roleEnum from '@utils/roleEnum';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { InfoCircle } from 'styled-icons/fa-solid';

import auth from '../main/auth';

type Subtract<T, V> = Pick<T, Exclude<keyof T, keyof V>>;

interface Props {
  user: AuthenticatedUser;
}

interface Options {
  roles?: roleEnum[];
  onlyAuthenticated?: boolean;
  showError?: boolean;
  showLoader?: boolean;
  header?: string;
}

//eslint-disable-next-line @typescript-eslint/ban-types
const withUser =
  <T extends Props>(
    Component: FC<T>,
    {
      roles,
      onlyAuthenticated = false,
      showError = true,
      showLoader = true,
      header,
    }: Options | undefined = {},
  ): FC<Subtract<T, Props>> =>
  props => {
    const user = useUser();
    const refreshUser = useRefreshUser();
    const dispatch = useDispatch();
    const forceChangeOfPassword = useForceChangeOfPassword();
    const authenticationMethod = useAuthenticationMethod();
    const { search, pathname } = useLocation();

    // If the jwtToken is missing the user has probably logged out in aother tab
    // or the session has expired.
    // Dispatch a resetState action to clear the store and refresh the user.
    if (
      showError &&
      !auth.isRefreshing &&
      !auth.token &&
      isLoggedIn(user) &&
      pathname !== '/login'
    ) {
      (async () => {
        await dispatch(resetState());
        refreshUser();
      })();
    }

    const response = showError ? (
      header ? (
        <PageLayout headline={header} singleCard>
          <InfoBadge
            icon={InfoCircle}
            color={'blue'}
            message={<>Du är inte behörig att använda den här tjänsten.</>}
          />
        </PageLayout>
      ) : (
        <Error>Du har inte behörighet till denna sida.</Error>
      )
    ) : null;

    if (!user) {
      return showLoader ? <Pulse /> : null;
    }

    // Redirect users that are not logged in,
    // however sometimes this component is used for hiding elements (showError == false)
    if (showError && !isLoggedIn(user) && pathname !== '/login') {
      return (
        <Redirect
          to={`/login?dest=${encodeURI(pathname + search)}&reason=unauthorized`}
        />
      );
    }

    if (
      roles &&
      !user?.roles?.some(item => roles.includes(item.role as roleEnum))
    ) {
      return response;
    }

    if (onlyAuthenticated) {
      if (
        forceChangeOfPassword &&
        pathname !== CHANGE_PASSWORD_PATH &&
        authenticationMethod === 'PASSWORD'
      ) {
        return null;
      } else if (!isLoggedIn(user)) {
        return response;
      }
    }

    return <Component user={user} {...(props as T)} />;
  };

export default withUser;

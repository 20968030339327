import { useRegisterMissingMare } from '@apis';
import Alert from '@components/Alert';
import { Checkbox, Input, TextArea } from '@components/FormikComponents';
import FormLayout, { FormItem } from '@components/FormLayout';
import {
  MissingMare,
  RegisterMissingMareRequest,
} from '@generated/breedings/src';
import Box from '@primitives/Box';
import { HorseIcon } from '@primitives/Icon';
import { SimpleList } from '@primitives/List';
import Message from '@primitives/Message';
import { Formik } from 'formik';
import React from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';

import CoveringDatePicker from './CoveringDatePicker';
import CoveringDatesTable from './CoveringDatesTable';

interface FormState extends Partial<MissingMare> {
  ccuser: boolean;
  all?: string;
}

interface Props {
  studId: number;
  open: boolean;
  onClose: () => void;
}

const MissingMareSchema = Yup.object().shape({
  yearOfBirth: Yup.string().required('Födelseår måste anges'),
  name: Yup.string().required('Namn på stoet måste anges'),
  owner: Yup.string().required('Ägare måste anges'),
  coveringDates: Yup.array()
    .min(1, 'Minst ett betäckningsdatum måste anges')
    .required('Minst ett betäckningsdatum måste anges'),
});

export default function MissingMareForm({
  studId,
  onClose,
  open,
}: Props): JSX.Element {
  const initialValues = {
    ccuser: true,
    coveringDates: [],
    name: '',
    owner: '',
    yearOfBirth: '',
  };

  const { action: insert } = useRegisterMissingMare();

  const { path } = useRouteMatch();
  const history = useHistory();

  return (
    <Formik<FormState>
      initialValues={initialValues}
      validationSchema={MissingMareSchema}
      onSubmit={async (values, { setSubmitting, setFieldError, resetForm }) => {
        const { ccuser, ...missingMare } = values;

        const resp = await insert({
          studId,
          ccuser,
          missingMare,
        } as RegisterMissingMareRequest);

        if (resp.status < 400) {
          resetForm({});
          history.push(generatePath(path, { studId: studId.toString() }));
        } else {
          setFieldError(
            'all',
            resp.body?.localizedErrorMessage ||
              resp.body?.errorMessage ||
              resp.text,
          );
        }

        setSubmitting(false);
      }}
    >
      {({ errors, isSubmitting, handleSubmit, touched, resetForm }) => {
        const errorList = Object.keys(errors)
          .filter(key => touched[key])
          .map(key => errors[key].toString());

        return (
          <Alert
            shouldCloseOnOverlayClick={false}
            closeModal={() => {
              resetForm({});
              onClose();
            }}
            open={open}
            size="medium"
            action="Skicka"
            title="Meddela betäckning sto"
            icon={HorseIcon}
            loading={isSubmitting}
            iconProps={{
              size: 10,
              mb: '0px',
            }}
            onAction={handleSubmit}
            description="Fyll i uppgifterna här nedan så skickas informationen till Svensk Galopp. De tar emot och behandlar ärendet manuellt. Språnget kommer att läggas till under hingstens betäckningar när erforderliga handlingar för stoets uppehälle i Sverige inkommit till SG."
            colorScheme="primary"
          >
            <FormLayout>
              <FormItem width={1} data-no-grow>
                <Input label="Stoets namn" name="name" />
              </FormItem>

              <FormItem width={[1, , 1 / 2]} data-no-grow>
                <Input label="Namn ägare" name="owner" />
              </FormItem>

              <FormItem width={[1, , 1 / 2]} data-no-grow>
                <Input label="Adress ägare" name="ownerAddress" />
              </FormItem>

              <FormItem width={[1, , 1 / 2]} data-no-grow>
                <Input label="Födelseår" name="yearOfBirth" />
              </FormItem>

              <FormItem width={[1, , 1 / 2]} data-no-grow>
                <Input
                  label="Registerland (om utländskt sto)"
                  name="countryOfOrigin"
                />
              </FormItem>

              <FormItem width={[1, , 1 / 2]} data-no-grow>
                <Input
                  label="Far-Mor/Morfar"
                  name="fatherMotherGrandFatherName"
                />
              </FormItem>

              <FormItem width={[1, , 1 / 2]} data-no-grow>
                <Input
                  label="Införelsedatum (om utländskt sto)"
                  name="importDate"
                />
              </FormItem>

              <FormItem width={[1, , 1 / 2]} data-no-grow>
                <label>Betäckningsdatum (alla)</label>
                <CoveringDatePicker name="coveringDates" />
              </FormItem>
              <FormItem width={[1, , 1]} data-no-grow variant="dense" mb={4}>
                <Box mt={2}>
                  <CoveringDatesTable />
                </Box>
              </FormItem>

              <FormItem width={1}>
                <TextArea
                  label="Meddelande till Svensk Galopp"
                  name="message"
                />
              </FormItem>

              <FormItem width={1}>
                <Checkbox name="ccuser" label="Skicka kopia till mig" />
              </FormItem>

              {errors?.all && (
                <FormItem width={1}>
                  <Message variant="errorBox">{errors.all.toString()}</Message>
                </FormItem>
              )}

              {errorList.length > 0 && (
                <FormItem width={1}>
                  <Message variant="errorBox">
                    <SimpleList items={errorList} />
                  </Message>
                </FormItem>
              )}
            </FormLayout>
          </Alert>
        );
      }}
    </Formik>
  );
}

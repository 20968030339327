// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, parseDateTime } from '../runtime';

/**
 * Breeding results summary, populated for search category: STAMBOK_SAMSTAT_BETECKNINGAR
 * @export
 * @interface BreedingResultsStatistics
 */
export interface BreedingResultsStatistics  {
    /**
     * 
     * @type {number}
     * @memberof BreedingResultsStatistics
     */
    numberOfLivingColts?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingResultsStatistics
     */
    numberOfLivingMareFoals?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingResultsStatistics
     */
    numberOfLivingTwins?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingResultsStatistics
     */
    totalNumberOfLivingFoals?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingResultsStatistics
     */
    numberOfDeadAtBirth?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingResultsStatistics
     */
    numberOfNoResult?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingResultsStatistics
     */
    numberOfDeadMares?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingResultsStatistics
     */
    numberOfExportedMares?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingResultsStatistics
     */
    numberOfDataMissing?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingResultsStatistics
     */
    numberOfDoubleBreedings?: number;
    /**
     * 
     * @type {number}
     * @memberof BreedingResultsStatistics
     */
    totalNumberOfMares?: number;
}

export function BreedingResultsStatisticsFromJSON(json: any): BreedingResultsStatistics {
    return {
        'numberOfLivingColts': !exists(json, 'numberOfLivingColts') ? undefined : json['numberOfLivingColts'],
        'numberOfLivingMareFoals': !exists(json, 'numberOfLivingMareFoals') ? undefined : json['numberOfLivingMareFoals'],
        'numberOfLivingTwins': !exists(json, 'numberOfLivingTwins') ? undefined : json['numberOfLivingTwins'],
        'totalNumberOfLivingFoals': !exists(json, 'totalNumberOfLivingFoals') ? undefined : json['totalNumberOfLivingFoals'],
        'numberOfDeadAtBirth': !exists(json, 'numberOfDeadAtBirth') ? undefined : json['numberOfDeadAtBirth'],
        'numberOfNoResult': !exists(json, 'numberOfNoResult') ? undefined : json['numberOfNoResult'],
        'numberOfDeadMares': !exists(json, 'numberOfDeadMares') ? undefined : json['numberOfDeadMares'],
        'numberOfExportedMares': !exists(json, 'numberOfExportedMares') ? undefined : json['numberOfExportedMares'],
        'numberOfDataMissing': !exists(json, 'numberOfDataMissing') ? undefined : json['numberOfDataMissing'],
        'numberOfDoubleBreedings': !exists(json, 'numberOfDoubleBreedings') ? undefined : json['numberOfDoubleBreedings'],
        'totalNumberOfMares': !exists(json, 'totalNumberOfMares') ? undefined : json['totalNumberOfMares'],
    };
}

export function BreedingResultsStatisticsToJSON(value?: BreedingResultsStatistics): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'numberOfLivingColts': value.numberOfLivingColts,
        'numberOfLivingMareFoals': value.numberOfLivingMareFoals,
        'numberOfLivingTwins': value.numberOfLivingTwins,
        'totalNumberOfLivingFoals': value.totalNumberOfLivingFoals,
        'numberOfDeadAtBirth': value.numberOfDeadAtBirth,
        'numberOfNoResult': value.numberOfNoResult,
        'numberOfDeadMares': value.numberOfDeadMares,
        'numberOfExportedMares': value.numberOfExportedMares,
        'numberOfDataMissing': value.numberOfDataMissing,
        'numberOfDoubleBreedings': value.numberOfDoubleBreedings,
        'totalNumberOfMares': value.totalNumberOfMares,
    };
}



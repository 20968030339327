import { getVaccines, Vaccine } from '@generated/licenseholders/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { ApiResponse } from '../utils/types';
import useApi from '../utils/useApi';

type Result = Vaccine[] | undefined;

export interface Entities {
  vaccines?: Result;
}

const getQuery = (): QueryConfig<Entities> =>
  getVaccines<Entities>({
    transform: responseBody => ({
      vaccines: responseBody,
    }),
    update: {
      vaccines: (prev, next): typeof next => next,
    },
  });

const useGetVaccines = (): ApiResponse<Result, Entities> => {
  const query = getQuery();
  const selector = (state: State): Result => state.entities?.vaccines;

  return useApi<Result>({
    query,
    selector,
  });
};

export default useGetVaccines;

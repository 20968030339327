import Box from '@primitives/Box';
import { Checkbox as PrimitiveCheckbox } from '@primitives/Form';
import { useField } from 'formik';
import React, { ReactNode } from 'react';

import Error from './Error';

interface Props {
  disabled?: boolean;
  label: ReactNode;
  name: string;
  children?: React.ReactNode;
  noError?: boolean;
  style?: React.CSSProperties;
}

function Checkbox({
  disabled,
  label,
  name,
  children,
  noError = false,
  style,
}: Props): JSX.Element {
  const fieldProps = { name, type: 'checkbox' };
  const [field, meta] = useField(fieldProps);

  return (
    <Box
      position="relative"
      sx={{
        '.errBox': {
          left: '24px',
          right: 'initial',
          top: '12px',
          textAlign: 'left',
        },
      }}
    >
      <PrimitiveCheckbox
        disabled={disabled}
        label={label}
        name={name}
        {...field}
        style={style}
      />
      {children}
      {!noError && meta.touched && meta.error && <Error>{meta.error}</Error>}
    </Box>
  );
}

export default Checkbox;

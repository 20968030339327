import { useGetOwnershipDetailedInvoice } from '@apis';
import DataGrid from '@components/DataGrid';
import InfoTable from '@components/InfoTable';
import { TD } from '@components/ReactTable';
import { Transaction } from '@generated/sportactors/src';
import useNumericParams from '@hooks/useNumericParams';
import Box from '@primitives/Box';
import Error from '@primitives/Error';
import { BackLink } from '@primitives/Link';
import List, { LI } from '@primitives/List';
import Loading from '@primitives/Loading';
import { H3 } from '@primitives/Typography';
import { formatTz } from '@utils/dateUtils';
import { formatNumber } from '@utils/formatting';
import React from 'react';
import { CellProps, Column } from 'react-table';

const columns: Column<Transaction>[] = [
  {
    Header: 'Datum',
    accessor: 'date',
    sortType: 'datetime',
    Cell: ({ value }: CellProps<Transaction, Date>) => (
      <>{formatTz(value, 'yyyy-MM-dd')}</>
    ),
  },
  {
    Header: 'Produkt',
    accessor: 'productDescription',
  },
  {
    Header: 'Belopp',
    accessor: 'amount',
    Cell: ({ value }: CellProps<Transaction, number>): JSX.Element => (
      <>{value ? formatNumber(value) : value} kr</>
    ),
    alignment: 'right',
  },
  {
    Header: 'Moms',
    accessor: 'vatAmount',
    Cell: ({ value }: CellProps<Transaction, number>): JSX.Element => (
      <>{value ? formatNumber(value) : value} kr</>
    ),
    alignment: 'right',
  },
  {
    Header: 'Häst',
    accessor: row => row.horse?.name,
  },
  {
    Header: 'Bana',
    accessor: 'trackCode',
  },
  {
    Header: 'Lopp',
    accessor: 'raceNumber',
  },
  {
    Header: 'Plac.',
    accessor: 'place',
  },
  {
    Header: 'Leverantör',
    accessor: 'supplierName',
  },
];

interface Props {
  invoicesPath: string;
  licenseId: number;
}

const InvoiceDetails = ({ invoicesPath, licenseId }: Props): JSX.Element => {
  const { documentId } = useNumericParams();

  const { data, loading, status, error } = useGetOwnershipDetailedInvoice(
    licenseId,
    documentId,
  );

  if (status && status !== 200) {
    return (
      <Error>
        {status} {error}
      </Error>
    );
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <Box px={[4, null, 0]} pt={[4, null, 0]}>
      <BackLink to={invoicesPath} mb={4}>
        Till fakturor
      </BackLink>
      <Box mb={4}>
        <H3>Faktura {data.invoice.invoiceNumber}</H3>
      </Box>
      <InfoTable
        values={[
          {
            label: 'Fakturadatum',
            value: formatTz(data.invoice.date, 'yyyy-MM-dd'),
          },
          {
            label: 'Förfallodatum',
            value: formatTz(data.invoice.expirationDate, 'yyyy-MM-dd'),
          },
          {
            label: 'Belopp',
            value: `${formatNumber(data.invoice.amount)} kr`,
          },
          {
            label: 'Restbelopp',
            value: (
              <>
                {data.invoice.residualAmount
                  ? formatNumber(data.invoice.residualAmount)
                  : 0}{' '}
                kr
              </>
            ),
          },
        ]}
      />
      <List mt={4}>
        {data.ownerTransactions.map((ownerTransaction, index) => (
          <LI key={ownerTransaction.ownerName}>
            <Box mt={index === 0 ? 0 : 4}>
              <H3>{ownerTransaction.ownerName}</H3>
              <DataGrid
                columns={columns}
                data={ownerTransaction.transactions}
                sortable
                stickyOnMobile={false}
                mt={4}
                condensed
                tfoot={
                  <tr>
                    <TD colSpan={2} alignment="right" fontWeight="bold">
                      Summa:
                    </TD>
                    <TD alignment="right">
                      {ownerTransaction?.amountSum
                        ? formatNumber(ownerTransaction.amountSum)
                        : 0}{' '}
                      kr
                    </TD>
                    <TD alignment="right">
                      {ownerTransaction?.vatAmountSum
                        ? formatNumber(ownerTransaction.vatAmountSum)
                        : 0}{' '}
                      kr
                    </TD>

                    <TD colSpan={columns.length - 4} />
                  </tr>
                }
              />
            </Box>
          </LI>
        ))}
      </List>
    </Box>
  );
};

export default InvoiceDetails;

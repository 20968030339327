import {
  useGetDriverStatisticsSummary,
  useGetOwnershipHorsesSummary,
  useGetOwnershipStatisticsSummary,
  useGetTrainerHorsesSummary,
  useGetTrainerStatisticsSummary,
} from '@apis';
import Stats from '@components/Stats';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import { OWNER_ROLES } from '@utils/constants';
import roleEnum from '@utils/roleEnum';
import React from 'react';

interface Props {
  user: AuthenticatedUser;
}

const ops = {
  showError: false,
  showLoader: false,
  onlyAuthenticated: true,
};

export const HorsesInTraining = withUser(
  ({ user }: Props): JSX.Element => {
    const { data } = useGetTrainerHorsesSummary(user.licenseId);

    return (
      <Stats center mass="medium" label="Hästar i träning">
        {data?.numberOfHorsesInTraining}
      </Stats>
    );
  },
  { ...ops, roles: [roleEnum.Trainer] },
);

export const WinsAsTrainer = withUser(
  ({ user }: Props): JSX.Element => {
    const { data } = useGetTrainerStatisticsSummary(user.licenseId);

    return (
      <Stats center mass="medium" label="Segrar som tränare">
        {data?.numberOfVictoriesThisYear}
      </Stats>
    );
  },
  { ...ops, roles: [roleEnum.Trainer] },
);

export const OwnedHorses = withUser(
  ({ user }: Props): JSX.Element => {
    const { data } = useGetOwnershipHorsesSummary(user.licenseId);
    return (
      <Stats center mass="medium" label="Ägda hästar">
        {data?.numberOfOwnedHorses}
      </Stats>
    );
  },
  { ...ops, roles: OWNER_ROLES },
);

export const WinsAsOwner = withUser(
  ({ user }: Props): JSX.Element => {
    const { data } = useGetOwnershipStatisticsSummary(user.licenseId);

    return (
      <Stats center mass="medium" label="Segrar som ägare">
        {data?.numberOfVictoriesThisYear}
      </Stats>
    );
  },
  { ...ops, roles: OWNER_ROLES },
);

export const WinsAsRider = withUser(
  ({ user }: Props): JSX.Element => {
    const { data } = useGetDriverStatisticsSummary(user.licenseId);
    return (
      <Stats center mass="medium" label="Segrar som ryttare">
        {data?.numberOfVictoriesThisYear}
      </Stats>
    );
  },
  { ...ops, roles: [roleEnum.Driver] },
);

import { Arrow } from '@primitives/Arrow';
import { base, BaseProps } from '@primitives/base';
import Box from '@primitives/Box';
import { Toggle } from '@primitives/Button';
import Icon from '@primitives/Icon';
import { H4 } from '@primitives/Typography';
import { formatTz } from '@utils/dateUtils';
import sv from 'date-fns/locale/sv';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { StyledIcon } from 'styled-icons/types';

export const StyledDate = styled.span<BaseProps>`
  font-weight: normal;
  ${base()}
`;

export const BoldText = styled.span<BaseProps>`
  font-weight: bold;
  ${base()}
`;

export const ThinText = styled.span<BaseProps>`
  font-weight: normal;
  ${base()}
`;

type BoxParameters = Parameters<typeof Box>;

type Props = {
  children: ReactNode;
  date?: Date;
  icon?: StyledIcon;
  expandedDefault: boolean;
  headline: string;
  headlineThinText?: string;
} & BoxParameters[0];

export default function ExpandableHeadline({
  children,
  date,
  expandedDefault,
  headline,
  headlineThinText,
  icon,
  ...props
}: Props): JSX.Element {
  const [expanded, setExpanded] = useState(expandedDefault);

  return (
    <Box
      pt={[4, , 0]}
      pb={3}
      mx={-4}
      px={4}
      borderBottomColor="gray2"
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      {...props}
    >
      <Toggle onClick={() => setExpanded(!expanded)}>
        <H4 color="black">
          <Icon as={icon} size={16} color="deep-blue" mr={2} mb={1} />
          <BoldText>{headline}</BoldText>
          {headlineThinText && <ThinText>{headlineThinText}</ThinText>}
          {date && (
            <>
              {' '}
              <StyledDate display={['inline', null, 'none']}>
                {formatTz(date, 'yyyy-MM-dd', {
                  locale: sv,
                })}
              </StyledDate>
              <StyledDate display={['none', null, 'inline']}>
                {formatTz(date, 'EEEE d MMMM yyyy', {
                  locale: sv,
                })}
              </StyledDate>
            </>
          )}
          <Arrow ml={2} up={expanded} size={12} />
        </H4>
      </Toggle>
      {expanded && (
        <Box mt={2} sx={{ fontSize: 'small' }}>
          {children}
        </Box>
      )}
    </Box>
  );
}

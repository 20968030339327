// tslint:disable
/**
 * Web API Breedings Service
 * Service for finding horse breeding information
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kundtjanst@travsport.se
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';
import {
    Covering,
    CoveringFromJSON,
    CoveringToJSON,
    CoveringListRegistrationDeadlines,
    CoveringListRegistrationDeadlinesFromJSON,
    CoveringListRegistrationDeadlinesToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Mare,
    MareFromJSON,
    MareToJSON,
    MissingMare,
    MissingMareFromJSON,
    MissingMareToJSON,
} from '../models';

export interface DeleteCoveringRequest {
    studId: number;
    coveringId: number;
}

export interface GetCoveringListRequest {
    studId: number;
}

export interface GetMareRequest {
    id: number;
    coveringDate: Date;
}

export interface RegisterMissingMareRequest {
    studId: number;
    missingMare: MissingMare;
    ccuser?: boolean;
}

export interface SaveCoveringRequest {
    studId: number;
    covering: Covering;
}

export interface UpdateCoveringRequest {
    studId: number;
    covering: Covering;
}


/**
 * Delete a covering.
 */
function deleteCoveringRaw<T>(requestParameters: DeleteCoveringRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.studId === null || requestParameters.studId === undefined) {
        throw new runtime.RequiredError('studId','Required parameter requestParameters.studId was null or undefined when calling deleteCovering.');
    }

    if (requestParameters.coveringId === null || requestParameters.coveringId === undefined) {
        throw new runtime.RequiredError('coveringId','Required parameter requestParameters.coveringId was null or undefined when calling deleteCovering.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlists/{studId}/covering/{coveringId}`.replace(`{${"studId"}}`, encodeURIComponent(String(requestParameters.studId))).replace(`{${"coveringId"}}`, encodeURIComponent(String(requestParameters.coveringId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Delete a covering.
*/
export function deleteCovering<T>(requestParameters: DeleteCoveringRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteCoveringRaw(requestParameters, requestConfig);
}

/**
 * Get coverings for the stud the current year.
 */
function getCoveringListRaw<T>(requestParameters: GetCoveringListRequest, requestConfig: runtime.TypedQueryConfig<T, Array<Covering>> = {}): QueryConfig<T> {
    if (requestParameters.studId === null || requestParameters.studId === undefined) {
        throw new runtime.RequiredError('studId','Required parameter requestParameters.studId was null or undefined when calling getCoveringList.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlists/{studId}/coverings`.replace(`{${"studId"}}`, encodeURIComponent(String(requestParameters.studId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(CoveringFromJSON), text);
    }

    return config;
}

/**
* Get coverings for the stud the current year.
*/
export function getCoveringList<T>(requestParameters: GetCoveringListRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<Covering>>): QueryConfig<T> {
    return getCoveringListRaw(requestParameters, requestConfig);
}

/**
 * Get deadlines for covering list registrations.
 */
function getCoveringListRegistrationDeadlinesRaw<T>( requestConfig: runtime.TypedQueryConfig<T, CoveringListRegistrationDeadlines> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlists/deadlines`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CoveringListRegistrationDeadlinesFromJSON(body), text);
    }

    return config;
}

/**
* Get deadlines for covering list registrations.
*/
export function getCoveringListRegistrationDeadlines<T>( requestConfig?: runtime.TypedQueryConfig<T, CoveringListRegistrationDeadlines>): QueryConfig<T> {
    return getCoveringListRegistrationDeadlinesRaw( requestConfig);
}

/**
 * Get info about a mare
 */
function getMareRaw<T>(requestParameters: GetMareRequest, requestConfig: runtime.TypedQueryConfig<T, Mare> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMare.');
    }

    if (requestParameters.coveringDate === null || requestParameters.coveringDate === undefined) {
        throw new runtime.RequiredError('coveringDate','Required parameter requestParameters.coveringDate was null or undefined when calling getMare.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlists/mares/{id}/{coveringDate}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"coveringDate"}}`, encodeURIComponent(String(requestParameters.coveringDate))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(MareFromJSON(body), text);
    }

    return config;
}

/**
* Get info about a mare
*/
export function getMare<T>(requestParameters: GetMareRequest, requestConfig?: runtime.TypedQueryConfig<T, Mare>): QueryConfig<T> {
    return getMareRaw(requestParameters, requestConfig);
}

/**
 * Submit covering of a mare that is not present in the sportDb.
 */
function registerMissingMareRaw<T>(requestParameters: RegisterMissingMareRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.studId === null || requestParameters.studId === undefined) {
        throw new runtime.RequiredError('studId','Required parameter requestParameters.studId was null or undefined when calling registerMissingMare.');
    }

    if (requestParameters.missingMare === null || requestParameters.missingMare === undefined) {
        throw new runtime.RequiredError('missingMare','Required parameter requestParameters.missingMare was null or undefined when calling registerMissingMare.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.ccuser !== undefined) {
        queryParameters['ccuser'] = requestParameters.ccuser;
    }

    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlists/{studId}/coverings/missingmare`.replace(`{${"studId"}}`, encodeURIComponent(String(requestParameters.studId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || MissingMareToJSON(requestParameters.missingMare),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Submit covering of a mare that is not present in the sportDb.
*/
export function registerMissingMare<T>(requestParameters: RegisterMissingMareRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return registerMissingMareRaw(requestParameters, requestConfig);
}

/**
 * Insert a covering.
 */
function saveCoveringRaw<T>(requestParameters: SaveCoveringRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.studId === null || requestParameters.studId === undefined) {
        throw new runtime.RequiredError('studId','Required parameter requestParameters.studId was null or undefined when calling saveCovering.');
    }

    if (requestParameters.covering === null || requestParameters.covering === undefined) {
        throw new runtime.RequiredError('covering','Required parameter requestParameters.covering was null or undefined when calling saveCovering.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlists/{studId}/coverings`.replace(`{${"studId"}}`, encodeURIComponent(String(requestParameters.studId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CoveringToJSON(requestParameters.covering),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Insert a covering.
*/
export function saveCovering<T>(requestParameters: SaveCoveringRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return saveCoveringRaw(requestParameters, requestConfig);
}

/**
 * Update a covering
 */
function updateCoveringRaw<T>(requestParameters: UpdateCoveringRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.studId === null || requestParameters.studId === undefined) {
        throw new runtime.RequiredError('studId','Required parameter requestParameters.studId was null or undefined when calling updateCovering.');
    }

    if (requestParameters.covering === null || requestParameters.covering === undefined) {
        throw new runtime.RequiredError('covering','Required parameter requestParameters.covering was null or undefined when calling updateCovering.');
    }

    let queryParameters = null;


    const headerParameters : runtime.HttpHeaders = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['bearer'];
    const config: QueryConfig<T> = {
        url: `${runtime.Configuration.basePath}/breedings/coveringlists/{studId}/coverings`.replace(`{${"studId"}}`, encodeURIComponent(String(requestParameters.studId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || CoveringToJSON(requestParameters.covering),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Update a covering
*/
export function updateCovering<T>(requestParameters: UpdateCoveringRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateCoveringRaw(requestParameters, requestConfig);
}


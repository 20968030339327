import config from '@config';
import { QueryConfig } from 'redux-query';

export type apiTypes = 'webapi' | 'contentapi';

export function getBaseUrl(api: apiTypes): string {
  return config[`${api}BaseUrl`];
}

export default <TQuery extends QueryConfig>(
  query: TQuery,
  api: apiTypes = 'webapi',
): TQuery => ({
  ...query,
  url: `${getBaseUrl(api)}${
    config.isProduction ? query.url : query.url.replace('/api/', '/api-test/')
  }`,
});

import { State } from '@main/store';
import { format } from 'date-fns';
import { QueryConfig } from 'redux-query';

import {
  BankIdUserSigning,
  initializeRegistrationStatusUpdate,
  InitializeRegistrationStatusUpdateRequest,
} from '../../generated/horses/src';
import { MutationResponse } from '../utils/types';
import useApiMutate from '../utils/useApiMutate';

export interface Entities {
  bankIdHorseInitialize?: BankIdUserSigning;
}

type ActionParams =
  InitializeRegistrationStatusUpdateRequest['bankIdSignRequest'];

const useBankIdHorseInitialize = (
  fromdate: InitializeRegistrationStatusUpdateRequest['fromdate'],
  horseId: InitializeRegistrationStatusUpdateRequest['horseId'],
  status: InitializeRegistrationStatusUpdateRequest['status'],
): MutationResponse<
  Entities['bankIdHorseInitialize'],
  Entities,
  ActionParams
> => {
  const selector = (state: State): Entities['bankIdHorseInitialize'] =>
    state.entities.bankIdHorseInitialize;

  const queryFunc = (
    bankIdSignRequest: ActionParams,
  ): QueryConfig<Entities> => {
    const query = initializeRegistrationStatusUpdate({
      bankIdSignRequest,
      fromdate,
      horseId,
      status,
    });

    // A hack to make it work when backend are combining query and body parameters :(
    return {
      ...query,
      body: bankIdSignRequest,
      url: `/horses/{horseId}/registrationstatus/bankid/initialize?status={status}&fromdate={fromdate}`
        .replace(`{${'horseId'}}`, horseId.toString())
        .replace(`{${'status'}}`, status)
        .replace(`{${'fromdate'}}`, format(fromdate, 'yyyy-MM-dd')),
    };
  };

  return useApiMutate<Entities['bankIdHorseInitialize'], ActionParams>({
    queryFunc,
    selector,
    queryKey: 'bankIdHorseInitialize',
  });
};

export default useBankIdHorseInitialize;

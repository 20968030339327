import FormLayout, { FormItem } from '@components/FormLayout';
import useIsMobile from '@hooks/useIsMobile';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import { Small } from '@primitives/Typography';
import React from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';

import { useCoveringContext, useGetStud, withContext } from '../context';
import SelectStud from '../SelectStud';
import CoveringForm from './CoveringForm';
import List from './CoveringList';

function Covering(): JSX.Element {
  const { coveringId, studId } = useCoveringContext();
  const selected = useGetStud(studId);
  const showForm = Boolean(coveringId);

  const history = useHistory();
  const isMobile = useIsMobile();
  const { path } = useRouteMatch();

  return (
    <Box verticalSpacing={4}>
      <FormLayout as="form" mx={[-4, , 0]} mt={[-4, , 0]}>
        <FormItem width={[1, , 1 / 3]} data-no-grow>
          <SelectStud />
        </FormItem>
        {selected && (
          <FormItem width={[1, , 'auto']} data-no-grow noLabel={!isMobile}>
            <Button
              disabled={selected.coveringRegistrationClosed || showForm}
              onClick={() => {
                history.push(generatePath(path, { studId, coveringId: 'new' }));
              }}
              width={[1, , 'auto']}
            >
              Ny betäckning
            </Button>
          </FormItem>
        )}
        <FormItem width={1} data-no-grow variant="dense">
          <Small mt={-2}>Betäckningsår: {new Date().getFullYear()}</Small>
        </FormItem>
      </FormLayout>
      {showForm ? (
        <CoveringForm studId={studId} coveringId={coveringId} />
      ) : (
        studId && <List studId={studId} />
      )}
    </Box>
  );
}

export default withContext(Covering);

import { PageLayout } from '@components/PageLayouts';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import React from 'react';

import Results from './Results';
import Statistics from './Statistics';

interface Props {
  user: AuthenticatedUser;
}

function OwnershipStatistics({ user }: Props): JSX.Element {
  return (
    <PageLayout headline="Ägarstatistik" singleCard>
      <Statistics user={user} />
      <Results user={user} />
    </PageLayout>
  );
}

export default withUser(OwnershipStatistics, {
  showLoader: false,
});

import 'styled-components';

import { SidebarLayoutSimpleGrid } from '@components/PageLayouts';
import HorseStartDeclaration from '@features/StartDeclaration/HorseStartDeclaration';
import RaceDay from '@features/StartDeclaration/RaceDay';
import { AuthenticatedUser } from '@generated/account/src';
import withUser from '@hoc/withUser';
import useIsMobile from '@hooks/useIsMobile';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Home from './Home';
import { navigation } from './navigation';
import Sidebar from './Sidebar';

interface Props {
  user: AuthenticatedUser;
}

const Account = ({ user }: Props): JSX.Element => {
  const { path } = useRouteMatch();
  const isMobile = useIsMobile();

  return (
    <Switch>
      <Route path={`${path}/startanmalan/tavlingsdagar/:raceDayId(\\d+)`}>
        <RaceDay user={user} />
      </Route>

      <Route path={`${path}/startanmalan/:horseId/:propId`}>
        <HorseStartDeclaration user={user} />
      </Route>

      <Route path={path}>
        <SidebarLayoutSimpleGrid
          leftSidebar={!isMobile && <Sidebar user={user} />}
        >
          <Switch>
            {navigation.map(group =>
              group.items
                .filter(item => item?.component != null)
                .map(item => (
                  <Route key={item.path} path={`${path}/${item.path}`}>
                    <item.component />
                  </Route>
                )),
            )}

            <Route path={path} exact>
              <Home />
            </Route>
          </Switch>
        </SidebarLayoutSimpleGrid>
      </Route>
    </Switch>
  );
};

export default withUser(Account, { onlyAuthenticated: true });

import {
  Covering,
  CoveringListRegistrationDeadlines,
  CoveringListStud,
  CoveringMare,
  CoveringResult,
  getAllMaresByName,
  getCoveringList,
  getCoveringListRegistrationDeadlines,
  getCoveringResult,
  getMare,
  getStuds,
  Mare,
} from '@generated/breedings/src';

import { ApiResponse, LazyApiResponse } from '../utils/types';
import useApi from '../utils/useApi';
import useApiLazy from '../utils/useApiLazy';

export interface Entities {
  getAllMaresByName?: { [key: string]: CoveringMare[] };
  getCoveringList?: { [key: string]: Covering[] };
  getCoveringListRegistrationDeadlines?: CoveringListRegistrationDeadlines;
  getCoveringResult?: { [key: string]: CoveringResult[] };
  getMare?: { [key: string]: Mare };
  getStuds?: { [key: string]: CoveringListStud[] };
}

export const useGetAllMaresByName = (
  namequery: string,
  limit: number | undefined = 0,
): LazyApiResponse<CoveringMare[] | undefined, Entities> => {
  const key = `${namequery}-${limit}`;

  return useApiLazy({
    query: getAllMaresByName<Entities>(
      {
        namequery,
        limitenabled: limit > 0,
        limit,
      },
      {
        transform: responseBody => ({
          getAllMaresByName: { [key]: responseBody },
        }),
        update: {
          getAllMaresByName: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities.getAllMaresByName?.[key],
  });
};

export const useGetCoveringList = (
  studId: number,
): ApiResponse<Covering[] | undefined, Entities> =>
  useApi({
    query: getCoveringList<Entities>(
      {
        studId,
      },
      {
        queryKey: `coveringList:${studId}`,
        transform: responseBody => ({
          getCoveringList: { [studId]: responseBody },
        }),
        update: {
          getCoveringList: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities.getCoveringList?.[studId],
  });

export const useGetCoveringListRegistrationDeadlines = (): ApiResponse<
  CoveringListRegistrationDeadlines | undefined,
  Entities
> =>
  useApi({
    query: getCoveringListRegistrationDeadlines<Entities>({
      transform: responseBody => ({
        getCoveringListRegistrationDeadlines: responseBody,
      }),
      update: {
        getCoveringListRegistrationDeadlines: (prev, next): typeof next => next,
      },
    }),
    selector: state => state.entities.getCoveringListRegistrationDeadlines,
  });

export const useGetCoveringResult = (
  year: number,
  studId: number,
): ApiResponse<CoveringResult[] | undefined, Entities> => {
  const key = `${year}-${studId}`;
  return useApi({
    query: getCoveringResult<Entities>(
      {
        year,
        studId,
      },
      {
        transform: responseBody => ({
          getCoveringResult: { [key]: responseBody },
        }),
        update: {
          getCoveringResult: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities.getCoveringResult?.[key],
  });
};

export const useGetMare = (
  id: number,
  coveringDate: Date,
): ApiResponse<Mare | undefined, Entities> => {
  const dateString = coveringDate.toISOString().substr(0, 10);
  const key = `${id}-${dateString}`;
  return useApi({
    query: {
      ...getMare<Entities>(
        {
          id,
          coveringDate,
        },
        {
          transform: responseBody => ({
            getMare: { [key]: responseBody },
          }),
          update: {
            getMare: (prev, next): typeof next => ({
              ...prev,
              ...next,
            }),
          },
        },
      ),
      // OpenApiGenerate does not support date url parameters
      url: `/breedings/coveringlists/mares/${id}/${dateString}`,
    },
    selector: state => state.entities.getMare?.[key],
  });
};

export const useGetStuds = (
  licenseId: number,
  year: number | undefined = undefined,
): ApiResponse<CoveringListStud[] | undefined, Entities> => {
  const key = `${licenseId}-${year}`;
  return useApi({
    query: getStuds<Entities>(
      {
        licenseId,
        year,
      },
      {
        transform: responseBody => ({
          getStuds: { [key]: responseBody },
        }),
        update: {
          getStuds: (prev, next): typeof next => ({
            ...prev,
            ...next,
          }),
        },
      },
    ),
    selector: state => state.entities.getStuds?.[key],
  });
};

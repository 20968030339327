import { InfoText } from '@generated/propositions/src';
import React from 'react';
import styled from 'styled-components';

interface Props {
  infoTexts: InfoText[];
}

const LI = styled.li`
  padding-right: 5px;
  line-height: 1.4;
  &::before {
    content: '•';
    font-size: 15px;
    padding-right: 3px;
    color: ${props => props.theme.colors['deep-blue']};
  }
`;

export default function InfoTexts({ infoTexts }: Props): JSX.Element {
  return (
    <div>
      <ul style={{ width: '100%' }}>
        {infoTexts
          .filter(item => !item.header)
          .map(({ text }, i) => (
            <LI key={i}>{text}</LI>
          ))}
      </ul>
    </div>
  );
}
